import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { smartviewManager } from '../recoil/selectors';
import { ISmartview } from '../types';

/**
 * Smartview manager provides utilty functions for managing a smartview.
 * @param name - name of the smartview to manage
 * @param singleton - specifies whether the smartview clears all previous views, so that only one view can be open
 */
export default function useSmartviewManager(
    name: string,
    position: ISmartview['position'] = 'left',
    singleton = false
) {
    const setSmartview = useSetRecoilState(smartviewManager(name));
    const reset = useResetRecoilState(smartviewManager(name));

    const open = () =>
        new Promise<void>((resolve) => {
            setSmartview({
                name,
                isOpen: true,
                position,
                instanceType: singleton ? 'single' : 'multi'
            });

            return resolve();
        });

    const close = () =>
        new Promise<void>((resolve) => {
            reset();

            return resolve();
        });

    const toggle = () =>
        new Promise<void>((resolve) => {
            setSmartview((prevValue) => {
                if (prevValue.isOpen) {
                    return {
                        name,
                        isOpen: false,
                        position,
                        instanceType: singleton ? 'single' : 'multi'
                    };
                }

                return {
                    name,
                    isOpen: true,
                    position,
                    instanceType: singleton ? 'single' : 'multi'
                };
            });

            return resolve();
        });

    return {
        toggle,
        open,
        close
    };
}
