import React from 'react';
import { TooltipModel } from 'autogen/swagger/menu';
import { zIndex } from 'variables';
import StorageTooltipPopup from './StorageTooltipPopup';

export default function RebuyTooltip({ model, children }: { model?: TooltipModel; children: JSX.Element }) {
    if (!model) return children;

    return (
        <StorageTooltipPopup
            name={model.tooltipType}
            size="sm"
            content={{ description: model.content, dismissButtonTitle: model.dismissText }}
            config={{ screenTimeLimit: model.screenTimeLimitSeconds }}
            zIndex={zIndex.fixed + 1}
        >
            {children}
        </StorageTooltipPopup>
    );
}
