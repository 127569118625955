
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './wrapping';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type SwapCertificateModel = components['schemas']['SwapCertificateModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type BasketLineWrapping = components['schemas']['BasketLineWrapping'];
                    export type BasketLineWrappingUpdateViewModel = components['schemas']['BasketLineWrappingUpdateViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type BasketLineGiveAsGiftUpdateViewModel = components['schemas']['BasketLineGiveAsGiftUpdateViewModel'];
                    

        
        

        export type WrappingIndexParams = paths['/internal/Wrapping/Index']['get']['parameters']['query'];

        export type WrappingIndexSuccess = paths['/internal/Wrapping/Index']['get']['responses']['200']['content']['text/plain'];

        export const WrappingIndexUrl = '/internal/Wrapping/Index';

        
        export function WrappingIndex(params: WrappingIndexParams): Promise<WrappingIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<WrappingIndexSuccess>(`/internal/Wrapping/Index${formatParams(params)}`)
        }
    
    
        

        export type DeleteAllParams = paths['/internal/Wrapping/DeleteAll']['post']['parameters']['query'];

        export type DeleteAllSuccess = paths['/internal/Wrapping/DeleteAll']['post']['responses']['200']['content']['text/plain'];

        export const DeleteAllUrl = '/internal/Wrapping/DeleteAll';

        
        export function DeleteAll(params: DeleteAllParams): Promise<DeleteAllSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeleteAllSuccess>(`/internal/Wrapping/DeleteAll${formatParams(params)}`)
        }
    
    
        export type _UpdateRequestBody = paths['/internal/Wrapping/_Update']['post']['requestBody']['content']['application/json'];

        

        export type _UpdateSuccess = paths['/internal/Wrapping/_Update']['post']['responses']['200']['content']['text/plain'];

        export const _UpdateUrl = '/internal/Wrapping/_Update';

        
        export function _Update(requestBody: _UpdateRequestBody): Promise<_UpdateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<_UpdateSuccess>(`/internal/Wrapping/_Update`, requestBody)
        }
    
    

        export * from './discriminators';
    