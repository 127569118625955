export const OPEN_INTERESTS_MODAL = 'OPEN_INTERESTS_MODAL';
export const SET_INTERESTS = 'SET_INTERESTS';

export function openInterestsModal(openModal, props) {
    return {
        type: OPEN_INTERESTS_MODAL,
        openModal,
        props
    };
}

export function setInterests(interests) {
    return {
        type: SET_INTERESTS,
        interests
    };
}
