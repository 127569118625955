/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as ChooseStoreQuickView from './trans-website-myprofile-store-choosestorequickview';

export const UpdateSuccess = 'Website.MyProfile.Store.UpdateSuccess';
export const SearchAddress = 'Website.MyProfile.Store.SearchAddress';
export const FindStore = 'Website.MyProfile.Store.FindStore';
export const ShopsNearSearch = 'Website.MyProfile.Store.ShopsNearSearch';
export const ShopsNearSearchNew = 'Website.MyProfile.Store.ShopsNearSearchNew';
export const MoreAboutStore = 'Website.MyProfile.Store.MoreAboutStore';
export const YourFavoriteStore = 'Website.MyProfile.Store.YourFavoriteStore';
export const FindWay = 'Website.MyProfile.Store.FindWay';
export const UseMyLocation = 'Website.MyProfile.Store.UseMyLocation';
export const OpeningHours = 'Website.MyProfile.Store.OpeningHours';
export const FollowOnFacebook = 'Website.MyProfile.Store.FollowOnFacebook';
export const FindProductInStore = 'Website.MyProfile.Store.FindProductInStore';
export const StockInStore = 'Website.MyProfile.Store.StockInStore';
export const InStock = 'Website.MyProfile.Store.InStock';
export const FewInStock = 'Website.MyProfile.Store.FewInStock';
export const SoldOut = 'Website.MyProfile.Store.SoldOut';
export const CouldNotGetLocation = 'Website.MyProfile.Store.CouldNotGetLocation';
export const NoSearchText = 'Website.MyProfile.Store.NoSearchText';
export const NoResultText = 'Website.MyProfile.Store.NoResultText';
export const EstimatedTime = 'Website.MyProfile.Store.EstimatedTime';
export const InstaboxDescription = 'Website.MyProfile.Store.InstaboxDescription';
export const MyMatasStoreImageAltText = 'Website.MyProfile.Store.MyMatasStoreImageAltText';
