import { sizes } from 'variables/breakpoint';
import isClient from 'helpers/isClient';

const isMobile = (): boolean => {
    if (!isClient) return false;

    return window.matchMedia(`(max-width: ${sizes.md - 1}px)`).matches;
};

const isTablet = (): boolean => {
    if (!isClient) return false;

    return window.matchMedia(`(min-width: ${sizes.lg}px)`).matches;
};

const isDesktop = (): boolean => {
    if (!isClient) return false;

    return window.matchMedia(`(min-width: ${sizes.xl}px)`).matches;
};

const isLargestDesktop = (): boolean => {
    if (!isClient) return false;

    return window.matchMedia(`(min-width: ${sizes.xxxl}px)`).matches;
};

export default { isMobile, isTablet, isDesktop, isLargestDesktop };
