
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './term';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type ClubMatasTermsViewModel = components['schemas']['ClubMatasTermsViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    

        
        

        

        export type TermIndexSuccess = paths['/internal/Term/Index']['get']['responses']['200']['content']['text/plain'];

        export const TermIndexUrl = '/internal/Term/Index';

        
        export function TermIndex(): Promise<TermIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<TermIndexSuccess>(`/internal/Term/Index`)
        }
    
    
        

        

        export type HideSuccess = paths['/internal/Term/Hide']['post']['responses']['200']['content']['text/plain'];

        export const HideUrl = '/internal/Term/Hide';

        
        export function Hide(): Promise<HideSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<HideSuccess>(`/internal/Term/Hide`)
        }
    
    

        export * from './discriminators';
    