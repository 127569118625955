import { ColorSpot1, Spacing8 } from 'autogen/design-tokens/tokens';
import type { PrizeCertificateModel } from 'autogen/swagger/checkout';
import { SummaryLineText2 } from 'autogen/translation-keys/trans-website-basket-prizecertificate';
import { ButtonWithIcon } from 'components/Button';
import Layout from 'components/Layout';
import { Price } from 'components/generic/productPrice';
import { Typography } from 'components/generic/typography';
import dynamic from 'helpers/dynamic';
import { format } from 'modules/helpers/strings';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import type { RemoveActivePrizeCertificate } from './ActivePrizeCertificateSummaryRenderer';

const StyledBody = styled(Typography.Body)`
    font-style: italic;
    color: ${ColorSpot1};
    line-height: normal;
`;

const StyledPrice = styled(Price)`
    font-style: italic;
    color: ${ColorSpot1};
`;

const ActionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${Spacing8};
`;

const Cross = dynamic(() => import('icons/Cross.svg?react'));

type ActivePrizeCertificateLineProps = Record<string, any> & {
    model: PrizeCertificateModel;
    removeActivePrizeCertificateFunc: RemoveActivePrizeCertificate;
};

export default function ActivePrizeCertificateLine({
    model: { isReadOnly, pointPrice, id, valueText },
    removeActivePrizeCertificateFunc,
    ...restProps
}: ActivePrizeCertificateLineProps) {
    const { t } = useTranslation();
    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <StyledBody forwardedAs="span" themeTypes="mediumSemiBold">
                {format(t(SummaryLineText2))(pointPrice)}
            </StyledBody>
            <ActionContainer>
                <StyledPrice price={`-${valueText}`} />

                {!isReadOnly && (
                    <ButtonWithIcon
                        type="button"
                        variant="ghostOnLight"
                        size="sm"
                        icon={<Cross width={12} height={12} />}
                        onClick={() => removeActivePrizeCertificateFunc({ params: { prizeId: id } })}
                    />
                )}
            </ActionContainer>
        </Layout.Flex.Row>
    );
}
