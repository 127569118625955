import useIsomorphicLayoutEffect from 'hooks/globals/useIsomorphicLayoutEffect';
import { startTransition, useEffect, useState } from 'react';

export default function useMediaQuery(query: string): boolean | undefined {
    const [matches, setMatches] = useState<boolean>();

    // This hook is ssr friendly because useEffect won't run on the server

    useIsomorphicLayoutEffect(() => {
        const mediaQuery = window.matchMedia(query);

        startTransition(() => {
            setMatches(mediaQuery.matches);
        });

        const handler = (event) => setMatches(event.matches);

        // NOTE addListener is deprecated, but addEventListener is not supported below Safari 14

        // TODO update when Safari 14 is no longer on support list

        if (mediaQuery?.addEventListener) {
            mediaQuery.addEventListener('change', handler);
        } else {
            mediaQuery.addListener(handler);
        }

        return () => {
            if (mediaQuery?.removeEventListener) {
                mediaQuery.removeEventListener('change', handler);
            } else {
                mediaQuery.removeListener(handler);
            }
        };
    }, []);

    return matches;
}

export function useMediaQueries(queries, values, defaultValue) {
    // State and setter for matched value
    const [value, setValue] = useState();
    useIsomorphicLayoutEffect(
        () => {
            // Array containing a media query list for each query
            const mediaQueryLists = queries.map((q) => window.matchMedia(q));
            // Function that gets value based on matching media query
            const getValue = () => {
                // Get index of first media query that matches
                const index = mediaQueryLists.findIndex((mql) => mql.matches);
                // Return related value or defaultValue if none
                return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
            };

            startTransition(() => {
                setValue(getValue);
            });

            const handler = () => {
                startTransition(() => {
                    setValue(getValue);
                });
            };
            // Set a listener for each media query with above handler as callback.
            if (mediaQueryLists[0]?.addEventListener) {
                mediaQueryLists.forEach((mql) => mql.addEventListener('change', handler));
            } else {
                mediaQueryLists.forEach((mql) => mql.addListener(handler));
            }

            // Remove listeners on cleanup
            return () => {
                if (mediaQueryLists[0]?.removeEventListener) {
                    mediaQueryLists.forEach((mql) => mql.removeEventListener('change', handler));
                } else {
                    mediaQueryLists.forEach((mql) => mql.removeListener(handler));
                }
            };
        },
        [] // Empty array ensures effect is only run on mount and unmount
    );
    return value;
}
