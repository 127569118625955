/* eslint-disable */
/// This file is auto generated - do not edit manually
export const DefaultIntervals = 'Website.Product.Subscription.DefaultIntervals';
export const Frequency = 'Website.Product.Subscription.Frequency';
export const MissingFrequencySelect = 'Website.Product.Subscription.MissingFrequencySelect';
export const ChooseDelivery = 'Website.Product.Subscription.ChooseDelivery';
export const SelectGroup = 'Website.Product.Subscription.SelectGroup';
export const Every = 'Website.Product.Subscription.Every';
export const BuyOnce = 'Website.Product.Subscription.BuyOnce';
export const BuyOnSubscription = 'Website.Product.Subscription.BuyOnSubscription';
export const About = 'Website.Product.Subscription.About';
export const PriceHereafter = 'Website.Product.Subscription.PriceHereafter';
export const CustomInterval = 'Website.Product.Subscription.CustomInterval';
export const SubscriptionMinimumQuantityReminderPDPWarningText = 'Website.Product.Subscription.SubscriptionMinimumQuantityReminderPDPWarningText';
export const FrequencyExplanation = 'Website.Product.Subscription.FrequencyExplanation';
export const SubscriptionCombinedUsp = 'Website.Product.Subscription.SubscriptionCombinedUsp';
export const SubscriptionCombinedUspNoSaving = 'Website.Product.Subscription.SubscriptionCombinedUspNoSaving';
