import { useEffect, useRef } from 'react';
import { mediatorAtom, useMediatorValue } from './useMediatorAtom';

export interface ILegacyModalInformation {
    id: string;
}

export const mediatorLegacyModalOpenedAtom = mediatorAtom<ILegacyModalInformation | undefined>(undefined);

/**
 * useOnLegacyModalOpenEffect. Can be used to detect when a legacy modal has currently been opened and create logic based on that effect.
 *
 * Should only be used as a temporary solution while legacy modals exists (Legacy modals include, modal, htmlModal etc.).
 * @param effectCallback a function that is invoked when a legacy modal is opened, information about the modal is passed as an argument to the callback.
 * @returns null
 */

let isInitialMount = true;
export default function useOnLegacyModalOpenEffect(effectCallback: (value?: ILegacyModalInformation) => void) {
    const value = useMediatorValue(mediatorLegacyModalOpenedAtom);
    const prevValue = useRef<ILegacyModalInformation>();

    useEffect(() => {
        if (value && value.id !== prevValue.current?.id) {
            prevValue.current = value;
            if (isInitialMount) {
                isInitialMount = false;
            } else {
                effectCallback(value);
            }
        }
    }, [effectCallback, value]);

    return null;
}
