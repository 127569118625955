
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './page';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type InPageNotificationViewModel = components['schemas']['InPageNotificationViewModel'];
                    export type CertificationCustomAction = components['schemas']['CertificationCustomAction'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type JavascriptHookCustomAction = components['schemas']['JavascriptHookCustomAction'];
                    export type LiveEventCustomAction = components['schemas']['LiveEventCustomAction'];
                    export type SurveyCustomAction = components['schemas']['SurveyCustomAction'];
                    export type VimeoCustomAction = components['schemas']['VimeoCustomAction'];
                    export type CustomActionType = components['schemas']['CustomActionType'];
                    export type LinkActionType = components['schemas']['LinkActionType'];
                    export type PageActionMaybe = components['schemas']['PageActionMaybe'];
                    export type DisclaimerButtonLocation = components['schemas']['DisclaimerButtonLocation'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type StripeDTO = components['schemas']['StripeDTO'];
                    export type StripeDTOMaybe = components['schemas']['StripeDTOMaybe'];
                    export type ImageWithDisclaimerPlaceholderModel = components['schemas']['ImageWithDisclaimerPlaceholderModel'];
                    export type ConnectedContentFullwidthLocationType = components['schemas']['ConnectedContentFullwidthLocationType'];
                    export type ConnectedContentAdformTrackingModel = components['schemas']['ConnectedContentAdformTrackingModel'];
                    export type ConnectedContentAdformTrackingModelMaybe = components['schemas']['ConnectedContentAdformTrackingModelMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentFullwidthModel = components['schemas']['ConnectedContentFullwidthModel'];
                    export type ConnectedContentWidthType = components['schemas']['ConnectedContentWidthType'];
                    export type ConnectedContentInlineModel = components['schemas']['ConnectedContentInlineModel'];
                    export type ConnectedContentInventoryLaneBlockModel = components['schemas']['ConnectedContentInventoryLaneBlockModel'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type ConnectedContentProductDisplayModel = components['schemas']['ConnectedContentProductDisplayModel'];
                    export type ListItem = components['schemas']['ListItem'];
                    export type ListRowAlignment = components['schemas']['ListRowAlignment'];
                    export type ListRowItem = components['schemas']['ListRowItem'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelModel = components['schemas']['PriceLabelModel'];
                    export type PriceLabelModelMaybe = components['schemas']['PriceLabelModelMaybe'];
                    export type ProductListPriceInfo = components['schemas']['ProductListPriceInfo'];
                    export type SplashPlacement = components['schemas']['SplashPlacement'];
                    export type SplashDisplayType = components['schemas']['SplashDisplayType'];
                    export type SplashType = components['schemas']['SplashType'];
                    export type Splash = components['schemas']['Splash'];
                    export type SplashMaybe = components['schemas']['SplashMaybe'];
                    export type SplashModel = components['schemas']['SplashModel'];
                    export type VariantDisplayType = components['schemas']['VariantDisplayType'];
                    export type VariantListItem = components['schemas']['VariantListItem'];
                    export type ProductListVariantsModel = components['schemas']['ProductListVariantsModel'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type RecommendationType = components['schemas']['RecommendationType'];
                    export type RecommendationTrackingModelDTO = components['schemas']['RecommendationTrackingModelDTO'];
                    export type SearchEventModelDTO = components['schemas']['SearchEventModelDTO'];
                    export type SearchOrigin = components['schemas']['SearchOrigin'];
                    export type SuggestedSearchEventModelDTO = components['schemas']['SuggestedSearchEventModelDTO'];
                    export type SearchTrackingModelDTO = components['schemas']['SearchTrackingModelDTO'];
                    export type PowerstepTrackingModelDTO = components['schemas']['PowerstepTrackingModelDTO'];
                    export type AdformProductTrackingModelDTO = components['schemas']['AdformProductTrackingModelDTO'];
                    export type TrackingModelDTO = components['schemas']['TrackingModelDTO'];
                    export type TrackingModelDTOMaybe = components['schemas']['TrackingModelDTOMaybe'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type IBundleInfo = components['schemas']['IBundleInfo'];
                    export type IBundleInfoMaybe = components['schemas']['IBundleInfoMaybe'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type UATrackingCategory = components['schemas']['UATrackingCategory'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type ProductListItemModel = components['schemas']['ProductListItemModel'];
                    export type ProductCardSizeType = components['schemas']['ProductCardSizeType'];
                    export type ProductRecommendationModel = components['schemas']['ProductRecommendationModel'];
                    export type ProductRecommendationListModel = components['schemas']['ProductRecommendationListModel'];
                    export type ProductRecommendationListModelMaybe = components['schemas']['ProductRecommendationListModelMaybe'];
                    export type ProductListBonusGiftHeaderModel = components['schemas']['ProductListBonusGiftHeaderModel'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type HyperlinkMaybe = components['schemas']['HyperlinkMaybe'];
                    export type ResponsiveImageLinkModel = components['schemas']['ResponsiveImageLinkModel'];
                    export type ResponsiveImageLinkModelMaybe = components['schemas']['ResponsiveImageLinkModelMaybe'];
                    export type ProductListBrandHeaderModel = components['schemas']['ProductListBrandHeaderModel'];
                    export type CountdownSizeType = components['schemas']['CountdownSizeType'];
                    export type CountdownModel = components['schemas']['CountdownModel'];
                    export type CountdownModelMaybe = components['schemas']['CountdownModelMaybe'];
                    export type ExtendedPlaceholderHeaderModel = components['schemas']['ExtendedPlaceholderHeaderModel'];
                    export type ExtendedPlaceholderHeaderModelMaybe = components['schemas']['ExtendedPlaceholderHeaderModelMaybe'];
                    export type ExtendedPlaceholderContentItemModel = components['schemas']['ExtendedPlaceholderContentItemModel'];
                    export type ExtendedPlaceholderContentModel = components['schemas']['ExtendedPlaceholderContentModel'];
                    export type ExtendedPlaceholderContentModelMaybe = components['schemas']['ExtendedPlaceholderContentModelMaybe'];
                    export type ExtendedPlaceholderShopByItemModel = components['schemas']['ExtendedPlaceholderShopByItemModel'];
                    export type ExtendedPlaceholderShopByModel = components['schemas']['ExtendedPlaceholderShopByModel'];
                    export type ExtendedPlaceholderShopByModelMaybe = components['schemas']['ExtendedPlaceholderShopByModelMaybe'];
                    export type ExtendedPlaceholderProductsModel = components['schemas']['ExtendedPlaceholderProductsModel'];
                    export type ExtendedPlaceholderProductsModelMaybe = components['schemas']['ExtendedPlaceholderProductsModelMaybe'];
                    export type InventoryLaneModel = components['schemas']['InventoryLaneModel'];
                    export type InventoryLaneModelMaybe = components['schemas']['InventoryLaneModelMaybe'];
                    export type ExtendedPlaceholderInventoryModel = components['schemas']['ExtendedPlaceholderInventoryModel'];
                    export type ExtendedPlaceholderInventoryModelMaybe = components['schemas']['ExtendedPlaceholderInventoryModelMaybe'];
                    export type ExtendedPlaceholderModel = components['schemas']['ExtendedPlaceholderModel'];
                    export type ProductListOfferHeaderModel = components['schemas']['ProductListOfferHeaderModel'];
                    export type ProductListRebuyHeaderModel = components['schemas']['ProductListRebuyHeaderModel'];
                    export type ProductListTextHeaderModel = components['schemas']['ProductListTextHeaderModel'];
                    export type IProductListHeaderMaybe = components['schemas']['IProductListHeaderMaybe'];
                    export type NoRebuyAvailableModel = components['schemas']['NoRebuyAvailableModel'];
                    export type MultiBuyProduct = components['schemas']['MultiBuyProduct'];
                    export type MiniSplash = components['schemas']['MiniSplash'];
                    export type MultiBuyModel = components['schemas']['MultiBuyModel'];
                    export type StripedVerticalSpacerModel = components['schemas']['StripedVerticalSpacerModel'];
                    export type CmsButtonVariant = components['schemas']['CmsButtonVariant'];
                    export type AdformButtonModel = components['schemas']['AdformButtonModel'];
                    export type LinkButtonModel = components['schemas']['LinkButtonModel'];
                    export type SignupButtonModel = components['schemas']['SignupButtonModel'];
                    export type LoginButtonModel = components['schemas']['LoginButtonModel'];
                    export type NoButtonModel = components['schemas']['NoButtonModel'];
                    export type CustomButtonModel = components['schemas']['CustomButtonModel'];
                    export type CmsButtonSize = components['schemas']['CmsButtonSize'];
                    export type CmsButtonAlignment = components['schemas']['CmsButtonAlignment'];
                    export type CmsButtonGrid = components['schemas']['CmsButtonGrid'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type LoginInfoType = components['schemas']['LoginInfoType'];
                    export type LoginMode = components['schemas']['LoginMode'];
                    export type LoginState = components['schemas']['LoginState'];
                    export type LoginModalRequest = components['schemas']['LoginModalRequest'];
                    export type WriteReviewButton = components['schemas']['WriteReviewButton'];
                    export type ProductPrelaunchBannerModel = components['schemas']['ProductPrelaunchBannerModel'];
                    export type VimeoVideoFileModel = components['schemas']['VimeoVideoFileModel'];
                    export type VideoViewPresentation = components['schemas']['VideoViewPresentation'];
                    export type VimeoBlockDTO = components['schemas']['VimeoBlockDTO'];
                    export type HorizontalAlignment = components['schemas']['HorizontalAlignment'];
                    export type FloatBehaviorCmsModel = components['schemas']['FloatBehaviorCmsModel'];
                    export type YouTubeBlockDTO = components['schemas']['YouTubeBlockDTO'];
                    export type VerticalSpacerContentBlockValueCmsModel = components['schemas']['VerticalSpacerContentBlockValueCmsModel'];
                    export type HorizontalSpaceBlockDTO = components['schemas']['HorizontalSpaceBlockDTO'];
                    export type AdvisorDTO = components['schemas']['AdvisorDTO'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type IMedium = components['schemas']['IMedium'];
                    export type IMediumMaybe = components['schemas']['IMediumMaybe'];
                    export type ListDisplayType = components['schemas']['ListDisplayType'];
                    export type BrandDetailedModel = components['schemas']['BrandDetailedModel'];
                    export type BrandDetailedModelMaybe = components['schemas']['BrandDetailedModelMaybe'];
                    export type AdvisorBlockDTO = components['schemas']['AdvisorBlockDTO'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type StockStatusViewModel = components['schemas']['StockStatusViewModel'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type StockStatusWithPreciseDeliveryAndModalViewModel = components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel'];
                    export type StockStatusWithPreciseDeliveryViewModel = components['schemas']['StockStatusWithPreciseDeliveryViewModel'];
                    export type ImageBannerButtonModel = components['schemas']['ImageBannerButtonModel'];
                    export type ImageBannerButtonModelMaybe = components['schemas']['ImageBannerButtonModelMaybe'];
                    export type ImageBannerElementModel = components['schemas']['ImageBannerElementModel'];
                    export type ImageBannerModel = components['schemas']['ImageBannerModel'];
                    export type QuestionnaireState = components['schemas']['QuestionnaireState'];
                    export type QuestionnaireBrand = components['schemas']['QuestionnaireBrand'];
                    export type AcceptedTermType = components['schemas']['AcceptedTermType'];
                    export type QuestionnaireContentBlockDTO = components['schemas']['QuestionnaireContentBlockDTO'];
                    export type RevieveMakeupAdvisorViewModel = components['schemas']['RevieveMakeupAdvisorViewModel'];
                    export type ShippingOption = components['schemas']['ShippingOption'];
                    export type ShippingOptionGroup = components['schemas']['ShippingOptionGroup'];
                    export type ShippingOptionsBlock = components['schemas']['ShippingOptionsBlock'];
                    export type StaticImageType = components['schemas']['StaticImageType'];
                    

        
        

        

        export type InPageNotificationViewModelSuccess = paths['/internal/ModelOnly/InPageNotificationViewModel']['get']['responses']['200']['content']['text/plain'];

        export const InPageNotificationViewModelUrl = '/internal/ModelOnly/InPageNotificationViewModel';

        
        export function InPageNotificationViewModel(): Promise<InPageNotificationViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InPageNotificationViewModelSuccess>(`/internal/ModelOnly/InPageNotificationViewModel`)
        }
    
    
        

        

        export type ImageWithDisclaimerPlaceholderModelSuccess = paths['/internal/ModelOnly/ImageWithDisclaimerPlaceholderModel']['get']['responses']['200']['content']['text/plain'];

        export const ImageWithDisclaimerPlaceholderModelUrl = '/internal/ModelOnly/ImageWithDisclaimerPlaceholderModel';

        
        export function ImageWithDisclaimerPlaceholderModel(): Promise<ImageWithDisclaimerPlaceholderModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ImageWithDisclaimerPlaceholderModelSuccess>(`/internal/ModelOnly/ImageWithDisclaimerPlaceholderModel`)
        }
    
    
        

        

        export type NoRebuysAvailableViewModelSuccess = paths['/internal/ModelOnly/NoRebuysAvailableViewModel']['get']['responses']['200']['content']['text/plain'];

        export const NoRebuysAvailableViewModelUrl = '/internal/ModelOnly/NoRebuysAvailableViewModel';

        
        export function NoRebuysAvailableViewModel(): Promise<NoRebuysAvailableViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<NoRebuysAvailableViewModelSuccess>(`/internal/ModelOnly/NoRebuysAvailableViewModel`)
        }
    
    
        

        

        export type ExtendedPlaceholderModelSuccess = paths['/internal/ModelOnly/ExtendedPlaceholderModel']['get']['responses']['200']['content']['text/plain'];

        export const ExtendedPlaceholderModelUrl = '/internal/ModelOnly/ExtendedPlaceholderModel';

        
        export function ExtendedPlaceholderModel(): Promise<ExtendedPlaceholderModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ExtendedPlaceholderModelSuccess>(`/internal/ModelOnly/ExtendedPlaceholderModel`)
        }
    
    
        

        

        export type CountdownModelSuccess = paths['/internal/ModelOnly/CountdownModel']['get']['responses']['200']['content']['text/plain'];

        export const CountdownModelUrl = '/internal/ModelOnly/CountdownModel';

        
        export function CountdownModel(): Promise<CountdownModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CountdownModelSuccess>(`/internal/ModelOnly/CountdownModel`)
        }
    
    
        

        

        export type MultiBuyModelSuccess = paths['/internal/ModelOnly/MultiBuyModel']['get']['responses']['200']['content']['text/plain'];

        export const MultiBuyModelUrl = '/internal/ModelOnly/MultiBuyModel';

        
        export function MultiBuyModel(): Promise<MultiBuyModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MultiBuyModelSuccess>(`/internal/ModelOnly/MultiBuyModel`)
        }
    
    
        

        

        export type StripedVerticalSpacerSuccess = paths['/internal/ModelOnly/StripedVerticalSpacer']['get']['responses']['200']['content']['text/plain'];

        export const StripedVerticalSpacerUrl = '/internal/ModelOnly/StripedVerticalSpacer';

        
        export function StripedVerticalSpacer(): Promise<StripedVerticalSpacerSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<StripedVerticalSpacerSuccess>(`/internal/ModelOnly/StripedVerticalSpacer`)
        }
    
    
        

        

        export type CmsButtonModelSuccess = paths['/internal/ModelOnly/CmsButtonModel']['get']['responses']['200']['content']['text/plain'];

        export const CmsButtonModelUrl = '/internal/ModelOnly/CmsButtonModel';

        
        export function CmsButtonModel(): Promise<CmsButtonModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CmsButtonModelSuccess>(`/internal/ModelOnly/CmsButtonModel`)
        }
    
    
        

        

        export type CmsButtonGridModelSuccess = paths['/internal/ModelOnly/CmsButtonGridModel']['get']['responses']['200']['content']['text/plain'];

        export const CmsButtonGridModelUrl = '/internal/ModelOnly/CmsButtonGridModel';

        
        export function CmsButtonGridModel(): Promise<CmsButtonGridModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CmsButtonGridModelSuccess>(`/internal/ModelOnly/CmsButtonGridModel`)
        }
    
    
        

        

        export type ProductPrelaunchBannerModelSuccess = paths['/internal/ModelOnly/ProductPrelaunchBannerModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductPrelaunchBannerModelUrl = '/internal/ModelOnly/ProductPrelaunchBannerModel';

        
        export function ProductPrelaunchBannerModel(): Promise<ProductPrelaunchBannerModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductPrelaunchBannerModelSuccess>(`/internal/ModelOnly/ProductPrelaunchBannerModel`)
        }
    
    
        

        

        export type VimeoBlockDTOModelSuccess = paths['/internal/ModelOnly/VimeoBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const VimeoBlockDTOModelUrl = '/internal/ModelOnly/VimeoBlockDTOModel';

        
        export function VimeoBlockDTOModel(): Promise<VimeoBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<VimeoBlockDTOModelSuccess>(`/internal/ModelOnly/VimeoBlockDTOModel`)
        }
    
    
        

        

        export type YouTubeBlockDTOModelSuccess = paths['/internal/ModelOnly/YouTubeBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const YouTubeBlockDTOModelUrl = '/internal/ModelOnly/YouTubeBlockDTOModel';

        
        export function YouTubeBlockDTOModel(): Promise<YouTubeBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<YouTubeBlockDTOModelSuccess>(`/internal/ModelOnly/YouTubeBlockDTOModel`)
        }
    
    
        

        

        export type VerticalSpacerContentBlockModelSuccess = paths['/internal/ModelOnly/VerticalSpacerContentBlockModel']['get']['responses']['200']['content']['text/plain'];

        export const VerticalSpacerContentBlockModelUrl = '/internal/ModelOnly/VerticalSpacerContentBlockModel';

        
        export function VerticalSpacerContentBlockModel(): Promise<VerticalSpacerContentBlockModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<VerticalSpacerContentBlockModelSuccess>(`/internal/ModelOnly/VerticalSpacerContentBlockModel`)
        }
    
    
        

        

        export type HorizontalSpaceBlockDTOModelSuccess = paths['/internal/ModelOnly/HorizontalSpaceBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const HorizontalSpaceBlockDTOModelUrl = '/internal/ModelOnly/HorizontalSpaceBlockDTOModel';

        
        export function HorizontalSpaceBlockDTOModel(): Promise<HorizontalSpaceBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<HorizontalSpaceBlockDTOModelSuccess>(`/internal/ModelOnly/HorizontalSpaceBlockDTOModel`)
        }
    
    
        

        

        export type AdvisorBlockDTOModelSuccess = paths['/internal/ModelOnly/AdvisorBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorBlockDTOModelUrl = '/internal/ModelOnly/AdvisorBlockDTOModel';

        
        export function AdvisorBlockDTOModel(): Promise<AdvisorBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorBlockDTOModelSuccess>(`/internal/ModelOnly/AdvisorBlockDTOModel`)
        }
    
    
        

        

        export type IStockStatusViewModelSuccess = paths['/internal/ModelOnly/IStockStatusViewModel']['get']['responses']['200']['content']['text/plain'];

        export const IStockStatusViewModelUrl = '/internal/ModelOnly/IStockStatusViewModel';

        
        export function IStockStatusViewModel(): Promise<IStockStatusViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<IStockStatusViewModelSuccess>(`/internal/ModelOnly/IStockStatusViewModel`)
        }
    
    
        

        

        export type ImageBannerModelSuccess = paths['/internal/ModelOnly/ImageBannerModel']['get']['responses']['200']['content']['text/plain'];

        export const ImageBannerModelUrl = '/internal/ModelOnly/ImageBannerModel';

        
        export function ImageBannerModel(): Promise<ImageBannerModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ImageBannerModelSuccess>(`/internal/ModelOnly/ImageBannerModel`)
        }
    
    
        

        

        export type QuestionnaireContentBlockDTOSuccess = paths['/internal/ModelOnly/QuestionnaireContentBlockDTO']['get']['responses']['200']['content']['text/plain'];

        export const QuestionnaireContentBlockDTOUrl = '/internal/ModelOnly/QuestionnaireContentBlockDTO';

        
        export function QuestionnaireContentBlockDTO(): Promise<QuestionnaireContentBlockDTOSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<QuestionnaireContentBlockDTOSuccess>(`/internal/ModelOnly/QuestionnaireContentBlockDTO`)
        }
    
    
        

        

        export type PageActionModelSuccess = paths['/internal/ModelOnly/PageActionModel']['get']['responses']['200']['content']['text/plain'];

        export const PageActionModelUrl = '/internal/ModelOnly/PageActionModel';

        
        export function PageActionModel(): Promise<PageActionModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PageActionModelSuccess>(`/internal/ModelOnly/PageActionModel`)
        }
    
    
        

        

        export type RevieveMakeupAdvisorViewModelSuccess = paths['/internal/ModelOnly/RevieveMakeupAdvisorViewModel']['get']['responses']['200']['content']['text/plain'];

        export const RevieveMakeupAdvisorViewModelUrl = '/internal/ModelOnly/RevieveMakeupAdvisorViewModel';

        
        export function RevieveMakeupAdvisorViewModel(): Promise<RevieveMakeupAdvisorViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<RevieveMakeupAdvisorViewModelSuccess>(`/internal/ModelOnly/RevieveMakeupAdvisorViewModel`)
        }
    
    
        

        

        export type ShippingOptionsModelSuccess = paths['/internal/ModelOnly/ShippingOptionsModel']['get']['responses']['200']['content']['text/plain'];

        export const ShippingOptionsModelUrl = '/internal/ModelOnly/ShippingOptionsModel';

        
        export function ShippingOptionsModel(): Promise<ShippingOptionsModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ShippingOptionsModelSuccess>(`/internal/ModelOnly/ShippingOptionsModel`)
        }
    
    
        

        export type RenderSubPageParams = paths['/internal/Page/RenderSubPage']['get']['parameters']['query'];

        export type RenderSubPageSuccess = paths['/internal/Page/RenderSubPage']['get']['responses']['200']['content']['text/html'];

        export const RenderSubPageUrl = '/internal/Page/RenderSubPage';

        
        export function RenderSubPage(params: RenderSubPageParams): Promise<RenderSubPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchHTML(`/internal/Page/RenderSubPage${formatParams(params)}`)
        }
    
    
        

        export type GetStaticImageParams = paths['/internal/StaticImage/GetStaticImage']['get']['parameters']['query'];

        export type GetStaticImageSuccess = paths['/internal/StaticImage/GetStaticImage']['get']['responses']['200']['content']['text/plain'];

        export const GetStaticImageUrl = '/internal/StaticImage/GetStaticImage';

        
        export function GetStaticImage(params: GetStaticImageParams): Promise<GetStaticImageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetStaticImageSuccess>(`/internal/StaticImage/GetStaticImage${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    