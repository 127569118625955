import { VideoPlaceholderText } from 'autogen/translation-keys/trans-website-product';
import { Video } from 'autogen/translation-keys/trans-website-shared';
import useAsync from 'hooks/globals/useAsync';
import { getVimeoVideo } from 'modules/vimeoInfo';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { requestDataState } from 'recoilState/atoms/requestDataState';
import { VideoPlatformType } from '../shared/types';

interface IVideoDetails {
    title: string;
    duration: string;
    videoUrl: string;
    thumbnail: string;
}

function isVimeoVideo(video: vimeoVideo | IVideoDetails): video is vimeoVideo {
    return (video as vimeoVideo).player_embed_url !== undefined;
}

interface vimeoVideo {
    name: string;
    duration: number;
    // eslint-disable-next-line camelcase
    player_embed_url: string;
    pictures: any;
}

export type VideoDetailsProps = {
    id: string;
    platform: VideoPlatformType;
    subtitles?: boolean;
};

export default function useVideoDetails({ id, platform, subtitles }: VideoDetailsProps): IVideoDetails | null {
    const { t } = useTranslation();
    const {
        segmentation: { culture }
    } = useRecoilValue(requestDataState);

    function getYoutubeVideo(youtubeId: string | number): IVideoDetails {
        const videoUrl = `https://www.youtube.com/watch?v=${youtubeId}`;
        const thumbnail = `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`;

        return {
            title: t(VideoPlaceholderText), // we need matas youtube account api token to access these values from youtube api
            duration: t(Video), // we need matas youtube account api token to access these values from youtube api
            videoUrl,
            thumbnail
        };
    }
    /* 
        The getVimeoVideo fetches a video from the vimeo api, we need to map that obj to fit our 
        ArticleCardVideo object, compared to getYoutube which just returns an obj that already fits d'
        
    */
    const videoFetcher = platform === 'Vimeo' ? getVimeoVideo : getYoutubeVideo;

    const asyncFunction = React.useCallback(async () => videoFetcher(id), [id]);

    const { value: video } = useAsync<IVideoDetails | vimeoVideo>(asyncFunction, true);

    const formatVideoDuration = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;

        return `${minutes || 0}:${seconds}`;
    };

    if (!video) return null;

    if (isVimeoVideo(video)) {
        const videoUrl = new URL(video.player_embed_url);

        if (subtitles) {
            const { language } = new Intl.Locale(culture);
            videoUrl.searchParams.set('texttrack', language);
        }

        return {
            title: video.name,
            duration: formatVideoDuration(video.duration),
            videoUrl: videoUrl.href,
            thumbnail: video.pictures.sizes[1].link
        };
    }

    return video;
}
