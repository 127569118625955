import dynamic from 'helpers/dynamic';
import { memo, useMemo, type HTMLAttributes } from 'react';

export type IconSizes = {
    Alert: 16 | 24;
    ArrowDown: 16 | 24;
    ArrowLeft: 16 | 24;
    ArrowRight: 16 | 24;
    ArrowUp: 16 | 24;
    Basket: 16 | 24;
    BasketPlus: 16 | 24;
    Bell: 16 | 24;
    Calendar: 16 | 24;
    Camera: 16 | 24;
    Checkmark: 16 | 24;
    Clock: 16 | 24;
    Close: 16 | 24;
    Edit: 16 | 24;
    Filter: 16 | 24;
    GoBack: 16 | 24;
    GoTo: 16 | 24;
    Heart: 24;
    HeartFilled: 24;
    Indeterminate: 16 | 24;
    Info: 16 | 24;
    LockOpen: 24;
    matasAI: 16 | 24;
    Menu: 16 | 24;
    OpenInView: 16 | 24;
    Pin: 16 | 24;
    Plus: 16 | 24;
    Recurrent: 16 | 24;
    Search: 16 | 24;
    Settings: 16 | 24;
    Share: 16 | 24;
    ThumbsDown: 16 | 24;
    ThumbsUp: 16 | 24;
    Trash: 16 | 24;
    User: 16 | 24;
    Visibility: 16 | 24;
    VisibilityOff: 16 | 24;
};

export type IconProps = HTMLAttributes<HTMLOrSVGElement> &
    {
        [K in keyof IconSizes]: {
            type: K;
            size: IconSizes[K];
        };
    }[keyof IconSizes];

function getIcon({ type, size }: Pick<IconProps, 'type' | 'size'>) {
    return dynamic(() => import(`../../../svg/svgr/icons/design-system/${size}px/${type}.svg?react`));
}

function Icon(props: IconProps) {
    const { type, size, ...restProps } = props;

    const IconComponent = useMemo(() => getIcon({ type, size }), [type, size]);

    return <IconComponent {...restProps} />;
}

export default Icon;
