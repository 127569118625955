import { Spacing16, Spacing56, Spacing72, Spacing8 } from 'autogen/design-tokens/tokens';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';
import dynamic from 'helpers/dynamic';
import { Close } from 'autogen/translation-keys/trans-website-shared';
import { Typography } from 'components/generic/typography';
import { useTranslation } from 'react-i18next';
import { HeaderButton } from '../../shared/style';
import type { QuickViewHeaderProps } from '../../shared/types';

const Cross = dynamic(() => import('icons/Cross.svg?react'));

export const QuickViewHeaderContainer = styled.div`
    flex: 0 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: ${Spacing8};
    height: ${Spacing56};

    ${breakpoint.up(sizes.md)} {
        padding: ${Spacing16};
        height: ${Spacing72};
    }
`;

const CloseButton = styled(HeaderButton)`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CloseButtonText = styled(Typography.Body)`
    margin-right: ${Spacing8};
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

export function QuickViewCloseButton({
    onClose,
    rounded,
    showCloseText
}: {
    onClose: QuickViewHeaderProps['onClose'];
    rounded?: boolean;
    showCloseText?: boolean;
}) {
    const { t } = useTranslation();

    return (
        <CloseButton onClick={onClose} rounded={rounded}>
            {showCloseText && (
                <CloseButtonText forwardedAs="span" themeTypes="mediumSemiBold">
                    {t(Close)}
                </CloseButtonText>
            )}
            <Cross />
        </CloseButton>
    );
}

export function DefaultQuickViewHeader({
    backButtonRenderer,
    userCanClose,
    onClose,
    closeButtonRenderer = (onCloseCallback) => <QuickViewCloseButton onClose={onCloseCallback} />,
    ...restProps
}: QuickViewHeaderProps) {
    return (
        <QuickViewHeaderContainer {...restProps}>
            <div>{backButtonRenderer?.()}</div>
            {userCanClose && closeButtonRenderer(onClose)}
        </QuickViewHeaderContainer>
    );
}

function DefaultQuickViewHeaderWithCloseButtonText(props: QuickViewHeaderProps) {
    return (
        <DefaultQuickViewHeader
            {...props}
            closeButtonRenderer={(onCloseCallback) => <QuickViewCloseButton onClose={onCloseCallback} showCloseText />}
        />
    );
}

function DefaultQuickViewHeaderWithMessage({
    userCanClose,
    backButtonRenderer,
    onClose,
    closeButtonRenderer = (onCloseCallback) => <QuickViewCloseButton onClose={onCloseCallback} />,
    message,
    ...restProps
}: QuickViewHeaderProps) {
    return (
        <QuickViewHeaderContainer {...restProps}>
            <FlexContainer>
                {backButtonRenderer?.()}
                {message}
            </FlexContainer>
            {userCanClose && closeButtonRenderer(onClose)}
        </QuickViewHeaderContainer>
    );
}

export default Object.assign(DefaultQuickViewHeader, {
    WithCloseButtonText: DefaultQuickViewHeaderWithCloseButtonText,
    WithMessage: DefaultQuickViewHeaderWithMessage
});
