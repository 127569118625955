import { RefObject, useEffect, useState } from 'react';

/*
    The useIntersectionObserver hook can be used to track the intersection of a single element.
    Information about the properties https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver#properties
    Custom property "pauseOnceVisible" will stop the intersection observer once the item has intersected.
*/
interface Args extends IntersectionObserverInit {
    pauseOnceVisible?: boolean;
}

export default function useIntersectionObserver(
    elementRef: RefObject<Element>,
    { threshold = 0, root = null, rootMargin = '0%', pauseOnceVisible = false }: Args
): IntersectionObserverEntry | undefined {
    const [entry, setEntry] = useState<IntersectionObserverEntry>();

    const paused = entry?.isIntersecting && pauseOnceVisible;

    const updateEntry = ([entryNode]: IntersectionObserverEntry[]): void => {
        setEntry(entryNode);
    };

    useEffect(() => {
        const node = elementRef?.current; // DOM Ref
        const hasIOSupport = !!window.IntersectionObserver;

        if (!hasIOSupport || paused || !node) return;

        const observerParams = { threshold, root, rootMargin };
        const observer = new IntersectionObserver(updateEntry, observerParams);

        observer.observe(node);

        // eslint-disable-next-line consistent-return
        return () => {
            observer.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef, JSON.stringify(threshold), root, rootMargin, paused]);

    return entry;
}
