import { SessionMenuItem, SessionMenuAnchorItem } from 'components/SessionMenu/components/SessionMenuItem';
import useIsAuthenticated from 'hooks/user/useIsAuthenticated';
import { FavoriteIconUrlViewModel } from 'autogen/swagger/layout';
import { _LoginInfo, _LoginInfoParams } from 'autogen/swagger/login';
import { useTranslation } from 'react-i18next';
import { Favorites } from 'autogen/translation-keys/trans-website-shared';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';
import Heart from 'icons/Heart.svg?react';
import dynamic from 'helpers/dynamic';
import useLoginInfoQuickView from 'components/login/shared/hooks/useLoginInfoQuickView';
import { HeaderItemText } from './shared/styles';

const FavoriteQuantityBadge = dynamic(() => import('components/generic/badge/badges/FavoriteQuantityBadge'), {
    ssr: false
});

const StyledFavoriteQuantityBadge = styled(FavoriteQuantityBadge)`
    position: absolute;
    top: -6px;
    right: -2px;

    ${breakpoint.up(sizes.lg)} {
        right: 4px;
    }
`;

export default function Favorite({
    model: { loginRequest, myFavoritesLinkAction }
}: {
    model: FavoriteIconUrlViewModel;
}) {
    const isAuthenticated = useIsAuthenticated();
    const [, toggle] = useLoginInfoQuickView();
    const { t } = useTranslation();

    const loginParams: _LoginInfoParams = {
        productId: null,
        infoType: loginRequest.infoType,
        level: 'Soft',
        mode: loginRequest.mode,
        returnUrl: loginRequest.returnUrl
    };

    if (!isAuthenticated) {
        return (
            <>
                <SessionMenuItem
                    icon={<Heart fill="transparent" stroke="currentColor" aria-hidden />}
                    onClick={() => {
                        toggle({ isOpen: true }); // open login modal immediately with no data
                        _LoginInfo(loginParams).then((loginInfo) => toggle({ isOpen: true, loginInfo })); // update login modal with fetched login data
                    }}
                >
                    <HeaderItemText>{t(Favorites)}</HeaderItemText>
                </SessionMenuItem>
            </>
        );
    }

    return (
        <SessionMenuAnchorItem icon={<Heart fill="transparent" stroke="currentColor" />} action={myFavoritesLinkAction}>
            <StyledFavoriteQuantityBadge />
            <HeaderItemText>{t(Favorites)}</HeaderItemText>
        </SessionMenuAnchorItem>
    );
}
