import React from 'react';
import styled, { css } from 'styled-components';
import { ColorNeutralDarkGrey, ColorSpot1, FontWeightNormal, Spacing8 } from 'autogen/design-tokens/tokens';
import { Typography } from 'components/generic/typography';
import { PossibleAdditionalDeliveryCost } from 'autogen/translation-keys/trans-website-shared-productitem';
import { useTranslation } from 'react-i18next';
import type { HorizontalPriceModuleType } from './shared/types';
import PriceLabelRenderer from './PriceLabelRenderer';
import Price from './Price';
import fontSizeDecreaser from './shared/fontSizeDecreaser';

// #region Style
const Wrapper = styled.div``;

const StyledPrice = styled(Price)<{ $onDiscount?: boolean }>`
    ${({ $onDiscount }) =>
        $onDiscount &&
        css`
            color: ${ColorSpot1};
        `}
`;

const StyledPriceBefore = styled(Price)`
    font-weight: ${FontWeightNormal};
    color: ${ColorNeutralDarkGrey};
    text-decoration: line-through;
    line-height: normal;
    margin-left: ${Spacing8};
`;

const StyledPriceSaving = styled(Price)`
    color: ${ColorSpot1};
    font-weight: ${FontWeightNormal};
    margin-left: ${Spacing8};
`;

const AdditionalDeliveryCost = styled(Typography.Body)`
    color: ${ColorNeutralDarkGrey};
`;
// #endregion Style

export default function HorizontalPriceModule({
    onDiscount = false,
    price,
    priceBefore,
    priceSaving,
    showPossibleAdditionalDeliveryCost,
    size = 18,
    priceLabel,
    ...restProps
}: HorizontalPriceModuleType): JSX.Element {
    const { t } = useTranslation();

    /* Top Title */
    const getPriceTopTitle = () => {
        if (priceLabel) {
            return <PriceLabelRenderer priceLabel={priceLabel} onDiscount={onDiscount} size={14} />;
        }

        return null;
    };

    /* Price */
    const getPrice = () => (
        <div>
            <StyledPrice $onDiscount={onDiscount} price={price} size={size} />
            {onDiscount && priceBefore && <StyledPriceBefore price={priceBefore} size={fontSizeDecreaser(size, 4)} />}
            {onDiscount && priceSaving && <StyledPriceSaving price={priceSaving} size={fontSizeDecreaser(size, 4)} />}
        </div>
    );
    return (
        <Wrapper {...restProps}>
            {getPriceTopTitle()}
            {getPrice()}
            {showPossibleAdditionalDeliveryCost && (
                <AdditionalDeliveryCost>{t(PossibleAdditionalDeliveryCost)}</AdditionalDeliveryCost>
            )}
        </Wrapper>
    );
}
