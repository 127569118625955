import React from 'react';
import styled from 'styled-components';

const Dialog = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
`;

export default () => {
    return <Dialog />;
};
