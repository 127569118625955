/* eslint-disable */
/// This file is auto generated - do not edit manually
export const BookTime = 'Website.Booking.ChooseTime.BookTime';
export const BuyTicketButton = 'Website.Booking.ChooseTime.BuyTicketButton';
export const NoAvailableSlots = 'Website.Booking.ChooseTime.NoAvailableSlots';
export const NextAvailableSlots = 'Website.Booking.ChooseTime.NextAvailableSlots';
export const AvailableSlots = 'Website.Booking.ChooseTime.AvailableSlots';
export const ShowSlots = 'Website.Booking.ChooseTime.ShowSlots';
export const HideSlots = 'Website.Booking.ChooseTime.HideSlots';
export const BookingSlotsLeft = 'Website.Booking.ChooseTime.BookingSlotsLeft';
export const MaxChosableSpacesDisclaimer = 'Website.Booking.ChooseTime.MaxChosableSpacesDisclaimer';
export const SelectTicketAmount = 'Website.Booking.ChooseTime.SelectTicketAmount';
export const TotalPrice = 'Website.Booking.ChooseTime.TotalPrice';
export const AddressDisclaimer = 'Website.Booking.ChooseTime.AddressDisclaimer';
export const FullyBookedEvent = 'Website.Booking.ChooseTime.FullyBookedEvent';
