import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'modules/breakpoint';
import Translations from 'modules/translations';
import MainContext from 'components/QuickSearch/components/context';
import { layoutEnum } from '../../enums';
import LinkAction from 'components/shared/Routing/components/LinkAction';

const Button = styled(LinkAction)`
    text-decoration: underline;
    white-space: nowrap;
    font-weight: ${(props) => (props.layout === layoutEnum.row ? 400 : 700)};
    text-transform: ${(props) => (props.layout === layoutEnum.row ? 'initial' : 'uppercase')};

    ${(props) =>
        props.isHandheld &&
        props.layout === layoutEnum.tab &&
        css`
            background-color: #ececec;
            padding: 7px 17px;
            text-decoration: none;
        `}
    @media (min-width: ${breakpoint.sizes.xl}px) {
        text-transform: uppercase;
        font-weight: 700;
    }
`;

export default (props) => {
    const { buttonLinkAction, text } = props;
    const mainContext = useContext(MainContext);
    const { isHandheld, layout } = mainContext;

    return (
        <Button className="btn" action={buttonLinkAction} layout={layout} isHandheld={isHandheld}>
            {text || Translations.Website.SearchDialog.ShowMore}
        </Button>
    );
};
