import React, { useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Translations from 'modules/translations';
import { format } from 'modules/helpers/strings';
import MainContext from 'components/QuickSearch/components/context';
import ResultSection, { DefaultSection } from '../../components/resultSection/resultSection';
import { buttonEnum, listTypeEnum, trackingActionEnum } from '../../enums';

const DialogWrapper = styled.div`
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`;

const Dialog = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

const Column = styled.div`
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
`;

const ResultSectionWithBorder = styled(DefaultSection)`
    border-left: 1px solid #b8b8b8;
`;

export default props => {
    const { articles, brands, featuredBrands, products, suggestions, sectionCoordsCallback, nextQueries } = props;
    const hasFeaturedBrands = featuredBrands !== undefined && featuredBrands.items.length !== 0;
    const hasArticles = articles !== undefined && articles.items.length !== 0;
    const hasProducts = products !== undefined && products.items.length !== 0;
    const hasSuggestions = suggestions !== undefined && suggestions.items.length !== 0;
    const hasNextQueries = nextQueries && nextQueries.items.length > 0;

    const mainContext = useContext(MainContext);
    const { isHandheld } = mainContext;

    const dialogRef = useRef(null);

    useEffect(() => {
        if (!isHandheld) {
            requestAnimationFrame(() => {
                const rect = dialogRef.current.getBoundingClientRect();
                dialogRef.current.style.maxHeight = `calc(100vh - 1rem - ${rect.top}px)`;
            });
        }
    }, []);

    return (
        <DialogWrapper ref={dialogRef}>
            <Dialog>
                {(hasSuggestions || hasArticles || hasNextQueries) && (
                    <Column>
                        {hasSuggestions && (
                            <ResultSection
                                headline={Translations.Website.SearchDialog.SearchSuggestions}
                                listType={listTypeEnum.link}
                                items={suggestions}
                            />
                        )}
                        {hasNextQueries && (
                            <ResultSection
                                headline={Translations.Website.SearchDialog.RelatedToLatestSearch}
                                buttonType={buttonEnum.link}
                                listType={listTypeEnum.link}
                                items={nextQueries}
                                trackingAction={trackingActionEnum.nextQuery}
                            />
                        )}
                        {hasArticles && !hasNextQueries && (
                            <ResultSection
                                headline={Translations.Website.SearchDialog.Articles}
                                buttonType={buttonEnum.link}
                                listType={listTypeEnum.article}
                                items={articles}
                            />
                        )}
                    </Column>
                )}
                {(hasProducts || hasFeaturedBrands) && (
                    <Column>
                        {hasProducts && (
                            <ResultSection
                                sectionCoordsCallback={sectionCoordsCallback}
                                headline={Translations.Website.SearchDialog.Products}
                                captionText={
                                    products.actualSearchText &&
                                    format(Translations.Website.SearchDialog.ResultSectionCaption)(
                                        products.actualSearchText
                                    )
                                }
                                buttonType={buttonEnum.link}
                                listType={listTypeEnum.product}
                                styles={{ Section: ResultSectionWithBorder }}
                                items={products}
                            />
                        )}
                        {hasFeaturedBrands && (
                            <ResultSection
                                headline={Translations.Website.SearchDialog.Brands}
                                buttonType={buttonEnum.link}
                                listType={listTypeEnum.featuredBrand}
                                styles={{ Section: ResultSectionWithBorder }}
                                items={{ brands, featuredBrands }}
                            />
                        )}
                    </Column>
                )}
            </Dialog>
        </DialogWrapper>
    );
};
