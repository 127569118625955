import { css, type RuleSet } from 'styled-components';
import { breakpoint, type BreakPointsType, sizes } from 'variables';

type FunctionParamType<T> = (p: T) => RuleSet;

const mediaQueryStyling =
    <T>(fn: FunctionParamType<T>) =>
    (mqObject: BreakPointsType<T>) =>
        Object.entries(mqObject).map(([key, val]) =>
            key === 'xs' || key === '0px'
                ? fn(val)
                : css`
                      ${breakpoint.up(sizes[key] || key)} {
                          ${fn(val)}
                      }
                  `
        );

const getTypeStyling =
    <T extends string | number | undefined>(fn: FunctionParamType<T>) =>
    (param?: T | BreakPointsType<T>) => {
        if ((param && typeof param === 'string') || typeof param === 'number') {
            return fn(param);
        }

        if (param && typeof param === 'object') {
            return mediaQueryStyling(fn)(param);
        }

        return null;
    };

export default getTypeStyling;
