import { Spacing12, Spacing16, Spacing24, Spacing32, Spacing6, Spacing8 } from 'autogen/design-tokens/tokens';
import getTypeStyling from 'helpers/getTypeStyling';
import React from 'react';
import styled, { css } from 'styled-components';
import type { CardProps, CardSize } from '../shared/types';

const CardContentSizes = {
    xs: css`
        padding: ${Spacing8};
    `,
    sm: css`
        padding: ${Spacing12};
    `,
    md: css`
        padding: ${Spacing16};
    `,
    lg: css`
        padding: ${Spacing24};
    `,
    xl: css`
        padding: ${Spacing32};
    `
};

const StyledCardContent = styled.div<{ size?: CardProps['size'] }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    ${({ size, theme }) =>
        size
            ? getTypeStyling((resolvedSize: CardSize) => CardContentSizes[resolvedSize])(size as CardSize)
            : getTypeStyling((resolvedSize: CardSize) => CardContentSizes[resolvedSize])(theme.size)}
`;

export default function CardContent({ children, ...restProps }: CardProps) {
    return <StyledCardContent {...restProps}>{children}</StyledCardContent>;
}
