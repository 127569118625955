/* eslint-disable prefer-object-spread */
import React, { ReactNode } from 'react';

type Child = React.ReactElement<any, any>;

export function NoSSR({ children }: { children: Child }): Child {
    if (typeof window === 'undefined') {
        throw Error('DYNAMIC_NO_SSR');
    }

    return children;
}

function Loadable(options) {
    const opts = Object.assign(
        {
            loader: null,
            loading: null,
            ssr: true,
            fallback: null
        },
        options
    );

    opts.lazy = React.lazy(opts.loader);

    function LoadableComponent(props: any) {
        const Wrap = opts.ssr ? React.Fragment : NoSSR;
        const Lazy = opts.lazy;

        return (
            <React.Suspense fallback={opts.fallback}>
                <Wrap>
                    <Lazy {...props} />
                </Wrap>
            </React.Suspense>
        );
    }

    LoadableComponent.displayName = 'LoadableComponent';

    return LoadableComponent;
}

type ComponentModule<P = Record<string, unknown>> = { default: React.ComponentType<P> };

export declare type LoaderComponent<P = Record<string, unknown>> = Promise<React.ComponentType<P> | ComponentModule<P>>;

export declare type Loader<P = Record<string, unknown>> = () => LoaderComponent<P>;

export type LoaderMap = { [module: string]: () => Loader<any> };

export type LoadableGeneratedOptions = {
    webpack?(): any;
    modules?(): LoaderMap;
};

type DynamicOptionsLoadingProps = {
    error?: Error | null;
    isLoading?: boolean;
    pastDelay?: boolean;
    retry?: () => void;
    timedOut?: boolean;
};

// Normalize loader to return the module as form { default: Component } for `React.lazy`.
// Also for backward compatible since next/dynamic allows to resolve a component directly with loader
// Client component reference proxy need to be converted to a module.
function convertModule<P>(mod: React.ComponentType<P> | ComponentModule<P>) {
    return { default: (mod as ComponentModule<P>)?.default || mod };
}

export type DynamicOptions<P = Record<string, unknown>> = {
    loading?: (loadingProps: DynamicOptionsLoadingProps) => JSX.Element | null;
    loader?: Loader<P>;
    ssr?: boolean;
    fallback?: ReactNode;
};

export type LoadableOptions<P = Record<string, unknown>> = DynamicOptions<P>;

export type LoadableFn<P = Record<string, unknown>> = (opts: LoadableOptions<P>) => React.ComponentType<P>;

export type LoadableComponent<P = Record<string, unknown>> = React.ComponentType<P>;

export default function dynamic<P = Record<string, unknown>>(
    dynamicOptions: DynamicOptions<P> | Loader<P>,
    options?: DynamicOptions<P>
): React.ComponentType<P> {
    const loadableFn: LoadableFn<P> = Loadable;

    const loadableOptions: LoadableOptions<P> = {
        // A loading component is not required, so we default it
        loading: ({ error, isLoading, pastDelay }) => {
            if (!pastDelay) return null;
            if (process.env.NODE_ENV !== 'production') {
                if (isLoading) {
                    return null;
                }
                if (error) {
                    return (
                        <p>
                            {error.message}
                            <br />
                            {error.stack}
                        </p>
                    );
                }
            }
            return null;
        }
    };

    if (typeof dynamicOptions === 'function') {
        loadableOptions.loader = dynamicOptions;
    }

    Object.assign(loadableOptions, options);

    const loaderFn = loadableOptions.loader as () => LoaderComponent<P>;
    const loader = () =>
        loaderFn != null ? loaderFn().then(convertModule) : Promise.resolve(convertModule(() => null));

    return loadableFn({ ...loadableOptions, loader: loader as Loader<P> });
}
