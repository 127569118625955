import {
    FontSize12,
    FontSize14,
    FontSize16,
    Spacing12,
    Spacing16,
    Spacing24,
    Spacing32,
    Spacing40,
    Spacing8
} from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';
import getTypeStyling from 'helpers/getTypeStyling';
import type { ListItemSizesType, ListItemType, Size } from './types';

export const ListItemSizes: ListItemSizesType = {
    sm: {
        item: css`
            font-size: ${FontSize14};
            min-height: 40px;
        `,
        actionWrapper: css`
            padding: ${Spacing12};
        `,
        beforeSlot: css`
            margin-right: ${Spacing12};
        `,
        afterSlot: css`
            margin-left: ${Spacing12};
        `
    },
    md: {
        item: css`
            font-size: ${FontSize16};
            min-height: 48px;
        `,
        actionWrapper: css`
            padding: ${Spacing16};
        `,
        beforeSlot: css`
            margin-right: ${Spacing16};
        `,
        afterSlot: css`
            margin-left: ${Spacing16};
        `
    },
    lg: {
        item: css`
            font-size: ${FontSize16};
            min-height: 56px;
        `,
        actionWrapper: css`
            padding: ${Spacing16} ${Spacing24};
        `,
        beforeSlot: css`
            margin-right: ${Spacing16};
        `,
        afterSlot: css`
            margin-left: ${Spacing16};
        `
    },
    xl: {
        item: css`
            font-size: ${FontSize16};
            min-height: 64px;
        `,
        actionWrapper: css`
            padding: ${Spacing16} ${Spacing32};
        `,
        beforeSlot: css`
            margin-right: ${Spacing16};
        `,
        afterSlot: css`
            margin-left: ${Spacing16};
        `
    },
    xxl: {
        item: css`
            font-size: ${FontSize16};
            min-height: 48px;
        `,
        actionWrapper: css`
            padding: ${Spacing40};
        `,
        beforeSlot: css`
            margin-right: ${Spacing16};
        `,
        afterSlot: css`
            margin-left: ${Spacing16};
        `
    }
};

// eslint-disable-next-line import/prefer-default-export
export const actionWrapperStyling = css<Pick<ListItemType, 'size'>>`
    display: flex;
    align-items: center;
    text-align: left;
    padding: ${Spacing12};
    width: 100%;
    border: 0;
    background-color: transparent;
    ${({ size, theme }) => {
        if (size)
            return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].actionWrapper)(size as Size);
        if (theme.size)
            return getTypeStyling((resolvedSize: Size) => ListItemSizes[resolvedSize].actionWrapper)(theme.size);
        return ListItemSizes.md.actionWrapper;
    }}
    &:focus,
    &:active {
        outline: 0;
    }
`;
