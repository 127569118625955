import {
    Spacing8,
    ColorNeutralBlack,
    Spacing4,
    ColorNordicGrey3,
    BorderRadiusSmall
} from 'autogen/design-tokens/tokens';
import { useDeleteFromBasketMutation } from 'autogen/swagger/basket/hooks';
import type { MiniBasketLineViewModel } from 'autogen/swagger/minibasket';
import { ButtonWithIcon } from 'components/Button';
import Flex from 'components/Layout/components/Flex';
import NumberInput from 'components/basket/BasketItem/components/Actions/components/NumberInput';
import SubscriptionInformation from 'components/basket/BasketItem/components/Subscription/components/SubscriptionInformation';
import { PriceContainer } from 'components/basket/BasketItem/shared/styles';
import { updateBasket } from 'components/basket/shared/basket';
import HighlightedText from 'components/generic/HighlightedText';
import Card from 'components/generic/card';
import ProductImage from 'components/generic/productImage';
import { ProductImageContainerLink } from 'components/generic/productImage/shared/styles';
import ProductName from 'components/generic/productName';
import { Price } from 'components/generic/productPrice';
import PriceWithDiscount from 'components/generic/productPrice/components/PriceWithDiscount';
import { OTCSplash } from 'components/product/components/OTCNotification/OTCNotification';
import dynamic from 'helpers/dynamic';
import styled, { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';
import MiniBasketDiscountInfo from './MiniBasketDiscountInfo';

/* #region styles */
export const Grid = styled.div`
    display: grid;
    gap: ${Spacing8};
    grid-template-columns: auto 64px 1fr;
    align-items: center;

    ${breakpoint.up(sizes.lg)} {
        grid-template-columns: auto 64px 1fr;
    }
`;

const StyledOTCSplash = styled(OTCSplash)`
    position: absolute;
    right: 0;
    bottom: ${Spacing4};
`;

const StyledNumberInput = styled(NumberInput)`
    background-color: white;
    border: 1px solid ${ColorNordicGrey3};
    border-radius: ${BorderRadiusSmall};
    width: 64px;
    min-width: 64px;
`;
/* #endregion */

const Cross = dynamic(() => import('icons/Cross.svg?react'));

export default function MiniBasketItem({ model }: { model: MiniBasketLineViewModel }) {
    const {
        productLinkAction,
        productName,
        otcWarning,
        image,
        priceInfo,
        productId,
        quantity,
        maximumSalesQuantity,
        minimumSalesQuantity,
        discountInfo,
        subscriptionInfo
    } = model;

    const { mutateAsync: deleteFromBasket } = useDeleteFromBasketMutation({
        onSuccess: updateBasket
    });

    return (
        <Card size={null}>
            <Card.Content>
                <Grid>
                    <ButtonWithIcon
                        css={css`
                            align-self: center;
                        `}
                        size="sm"
                        variant="ghostOnLight"
                        icon={<Cross />}
                        onClick={() => deleteFromBasket({ params: { productId } })}
                    />

                    <ProductImageContainerLink action={productLinkAction}>
                        <ProductImage width={64} image={image} transformType="SquareScale" />
                        {otcWarning && <StyledOTCSplash model={otcWarning} size="sm" />}
                    </ProductImageContainerLink>

                    <Flex.Row justifyContent="space-between" alignItems="center">
                        <Flex.Column gap={Spacing8}>
                            {discountInfo && <MiniBasketDiscountInfo model={discountInfo} />}
                            <ProductName
                                productName={productName}
                                size="sm"
                                customTheme={{
                                    ProductTitle: css`
                                        color: ${ColorNeutralBlack};
                                    `
                                }}
                            />
                            {subscriptionInfo && (
                                <HighlightedText withBorder={false}>
                                    <Flex.Column gap={Spacing4}>
                                        <SubscriptionInformation model={subscriptionInfo} productId={productId} />
                                    </Flex.Column>
                                </HighlightedText>
                            )}
                        </Flex.Column>

                        <StyledNumberInput
                            size="sm"
                            productId={productId}
                            maximumSalesQuantity={maximumSalesQuantity}
                            minimumSalesQuantity={minimumSalesQuantity}
                            quantity={quantity}
                            autoFocus={false}
                        />

                        <PriceContainer gap={Spacing8} alignItems="end">
                            {priceInfo.hasDiscount ? (
                                <PriceWithDiscount
                                    css={css`
                                        white-space: nowrap;
                                        width: 76px;
                                    `}
                                    size={12}
                                    price={priceInfo.subtotal}
                                    priceBefore={priceInfo.subtotalBefore}
                                />
                            ) : (
                                <Price
                                    css={css`
                                        white-space: nowrap;
                                        width: 76px;
                                    `}
                                    size={12}
                                    price={priceInfo.subtotal}
                                />
                            )}

                            {/* <LinkedItemPrices model={linkedItems} /> */}
                        </PriceContainer>
                    </Flex.Row>
                </Grid>
            </Card.Content>
        </Card>
    );
}
