import { miniBasketActiveAtom } from 'components/MiniBasket/recoil/miniBasketAtoms';
import { searchActiveAtom } from 'components/QuickSearch/atoms/quickViewAtoms';
import useIsProductListIntersecting from 'components/shared/productList/shared/hooks/useIsProductListIntersecting';
import useScrollEffect from 'hooks/globals/useScrollEffect';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

export default function useShouldHeaderCollapse() {
    const [shouldCollapse, setShouldCollapse] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const isProductListIntersecting = useIsProductListIntersecting();
    const isQuickSearchActive = useRecoilValue(searchActiveAtom);
    const isMiniBasketActive = useRecoilValue(miniBasketActiveAtom);
    const buffer = 124; // Header should be locked within this buffer, this value is height of the header.

    useScrollEffect(
        ({ direction, scrollTop }) => {
            const isHeaderLocked = isMiniBasketActive || isQuickSearchActive || scrollTop < buffer;

            if (!isHeaderLocked) {
                const nextShouldCollapseValue = direction === 'down' || isProductListIntersecting;
                setShouldCollapse(nextShouldCollapseValue);
            } else {
                setShouldCollapse(false);
            }

            setHasScrolled(scrollTop !== 0);
        },
        [setShouldCollapse, setHasScrolled, isProductListIntersecting, isMiniBasketActive, isQuickSearchActive]
    );

    return [shouldCollapse, hasScrolled];
}
