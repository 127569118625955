import { SHOW_NOTIFICATION_FORM_MESSAGE, SET_NOTICIATION_FORM_MESSAGE } from '../actions/notificationFormActions';

const initialState = {
    showSuccessMessage: false,
    confirmMessage: undefined
};

const actions = {};

actions[SHOW_NOTIFICATION_FORM_MESSAGE] = (state, action) => ({
    ...state,
    showSuccessMessage: action.bool
});

actions[SET_NOTICIATION_FORM_MESSAGE] = (state, action) => ({
    ...state,
    confirmMessage: action.message
});

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
