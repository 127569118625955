import Spinner from 'modules/shared/circleSpinner';
import React from 'react';
import styled from 'styled-components';
import ButtonWithStatus from './ButtonWithStatus';
import type IButtonWithSpinner from './shared/types/buttonWithSpinner';

const Pending = styled.div`
    position: relative;
`;

const ChildrenWrapper = styled.span<any>`
    visibility: hidden;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ButtonWithSpinner = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IButtonWithSpinner>(
    ({ spinner = <StyledSpinner size={16} />, children, ...restProps }, ref) => (
        <ButtonWithStatus
            ref={ref}
            onIdle={children}
            onPending={
                <Pending>
                    {spinner}
                    <ChildrenWrapper>{children}</ChildrenWrapper>
                </Pending>
            }
            {...restProps}
        />
    )
);

export default ButtonWithSpinner;
