import { TrackUAEvent } from 'modules/tracking/UAEventTracking';

function getPosition(target) {
    const parents = target.parents('.js-navItem');
    const indexArray = [];

    parents.each((index, element) => {
        indexArray.push($(element).index() + 1);
    });

    return indexArray.reverse().join('.');
}

function tracking(event) {
    const target = $(event.currentTarget);

    TrackUAEvent('SubMenu', getPosition(target), target.text());
}

export default class SubMenu {
    constructor(item) {
        this.wrapper = $(item);
        this.expandBtn = this.wrapper.find('[data-js="icon"]');
        this.navItems = this.wrapper.find('.nav__item');
        this.activeClass = 'state--active';
        this.openMenuButton = this.wrapper.parent().find('.js-submenuMenuButton');
        this.backButton = this.wrapper.parent().find('.js-subMenuBackButton');
        this.links = this.wrapper.find('.js-navLink');

        this.openSubMenu = this.openSubMenu.bind(this);
    }

    init() {
        if (this.wrapper.length >= 1) {
            this.bindings();
        }
        if ($('[data-js-menuitem="current"]')) {
            const $currentNavItem = $('[data-js-menuitem="current"]');
            this.expandMenuHierarchy($currentNavItem);
        }
    }

    bindings() {
        this.expandBtn.on('click', e => this.toggleMenus($(e.target)));
        this.openMenuButton.on('click', this.openSubMenu);
        this.links.on('click', event => {
            tracking(event, this.wrapper);
        });
    }

    toggleMenus(target) {
        const $target = $(target);
        const $listItem = $target.closest('.nav__item');

        if ($listItem.hasClass(this.activeClass)) {
            $listItem.removeClass(this.activeClass);
        } else {
            // Close all menuitems
            const $activeItems = this.wrapper.find(`.${this.activeClass}`);
            $activeItems.removeClass(this.activeClass);

            // Open this item and it's hierarki
            $listItem.addClass(this.activeClass);
            this.expandMenuHierarchy($listItem);
        }
    }

    expandMenuHierarchy(navItem) {
        const $navItem = $(navItem);
        const $parents = $navItem.parents('.nav__item');

        $parents.addClass(this.activeClass);
    }

    openSubMenu() {
        this.wrapper.toggle();
    }
}
