/* eslint-disable react/no-array-index-key */
import {
    type MenuSectionViewModel,
    MenuItemViewModelDiscriminator as MenuSectionViewModelitemsDiscriminator
} from 'autogen/swagger/menu';
import { memo } from 'react';
import SideMenuItem from '../Item/SideMenuItem';
import SectionStyleRenderer from './components/SectionStyleRenderer';
import SideMenuArticleItem from '../Item/SideMenuArticleItem';
import SectionHeadlineRenderer from './components/SectionHeadlineRenderer';

const SideMenuSection = ({
    section: { items, styling, headline, headlineStyling }
}: {
    section: MenuSectionViewModel;
}) => (
    <SectionStyleRenderer
        styling={styling}
        headline={headline}
        headlineOutsideSection={headlineStyling === 'GreyCapital'}
        headlineRenderer={(headlineProps) => (
            <SectionHeadlineRenderer {...headlineProps} headlineStyling={headlineStyling} />
        )}
    >
        {items.map((itemModel, index) =>
            MenuSectionViewModelitemsDiscriminator(
                itemModel,
                (item) => <SideMenuItem key={item.id} item={item} />, // A normal sideMenu item
                (item) => <SideMenuArticleItem key={index} item={item} />, // A connectedContent item
                () => {
                    console.error('Invalid MenuSectionViewModelitem');
                    return null;
                }
            )
        )}
    </SectionStyleRenderer>
);

export default memo(SideMenuSection);
