let _resolve;

const trackingIdPromise = ((): Promise<any> =>
    new Promise((resolve) => {
        _resolve = resolve;
    }))();

const googleAnalytics = {
    setupTrackingId(id): void {
        _resolve(id);
    },

    async getClientId(): Promise<string> {
        const trackingId = await trackingIdPromise;
        const clientIdPromise = new Promise<string>((resolve) => {
            try {
                window.ga(() => {
                    const trackers = window.ga.getAll();
                    const trackerId = trackers.find((tracker) => tracker.get('trackingId') === trackingId);

                    if (!trackerId) {
                        resolve('');
                    }

                    const clientId = trackerId.get('clientId');

                    resolve(clientId);
                });
            } catch (err) {
                resolve('');
            }
        });

        return clientIdPromise;
    }
};

export default googleAnalytics;
