export function aspectRatioCalculateMissingSide(sideLength: number, aspectRatio: number) {
    // If the aspect ratio is width:height
    if (aspectRatio >= 1) {
        // Then the missing side is the height, which is width / aspect ratio
        return sideLength / aspectRatio;
    }

    // If the aspect ratio is height:width
    // Then the missing side is the width, which is height * aspect ratio
    return sideLength * aspectRatio;
}

export default function calculateAspectRatio(width: number, height: number) {
    return width / height;
}
