import { availableFontSizeArray, fontSizeTypes } from 'components/generic/typography/types';

export default function fontSizeDecreaser(defaultSize: fontSizeTypes, decreaseAmount: number) {
    if (typeof defaultSize === 'object') {
        const discountSizes = {};

        Object.keys(defaultSize).forEach((key) => {
            const index = availableFontSizeArray.indexOf(defaultSize[key]);

            discountSizes[key] = availableFontSizeArray[index ? index - decreaseAmount : index];
        });

        return discountSizes;
    }

    const index = availableFontSizeArray.indexOf(defaultSize);

    return availableFontSizeArray[index ? index - decreaseAmount : index];
}
