import { type RefObject, useCallback, useEffect } from 'react';

export default function useOnNestedFocusoutEffect(onFocusout: () => void, ref: RefObject<HTMLElement>) {
    const target = ref.current;

    const handleFocusout = useCallback(
        (event) => {
            // Check if element is a child of ref
            if (!(target as HTMLElement).contains(event.relatedTarget) && event.relatedTarget !== null) {
                onFocusout();
            }
        },
        [target, onFocusout]
    );

    useEffect(() => {
        if (target) {
            target.addEventListener('focusout', handleFocusout, false);
        }

        return () => {
            if (target) {
                target.removeEventListener('focusout', handleFocusout, false);
            }
        };
    }, [handleFocusout, target]);
}
