import { useIsDesktop } from 'hooks/responsive';
import { useEffect } from 'react';

let scrollHeightFromTop = 0;

export default function useBodyScrollLock(shouldLockBody) {
    const isDesktop = useIsDesktop();

    useEffect(() => {
        if (isDesktop) {
            if (shouldLockBody) {
                requestAnimationFrame(() => {
                    scrollHeightFromTop = window.scrollY;

                    const { offsetWidth } = document.body;
                    document.body.style.overflow = 'hidden';
                    document.body.style.paddingRight = `${document.body.offsetWidth - offsetWidth}px`;
                });
            }

            if (!shouldLockBody) {
                requestAnimationFrame(() => {
                    document.body.style.overflow = '';
                    document.body.style.paddingRight = '';
                });
            }
        }
    }, [isDesktop, shouldLockBody]);
}
