import { FontFontFamilyPrimary, FontLineHeightNone, FontWeightSemiBold } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

const ButtonTextStyling = css`
    font-family: ${FontFontFamilyPrimary};
    line-height: ${FontLineHeightNone};
    font-weight: ${FontWeightSemiBold};
    text-align: center;
`;

export default ButtonTextStyling;
