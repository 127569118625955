import useIntersectionObserver from 'hooks/globals/useIntersectionObserver';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { merge } from 'lodash-es';
import { useRecoilState } from 'recoil';
import { CookieCategory } from 'modules/helpers/cookieInformationHelper';
import useHasCookieBeenAccepted from 'hooks/user/useHasInformationCookieBeenAccepted';
import TooltipNotificationAtom from '../recoil/atom';
import useTimer from '../../../../../hooks/globals/useTimer';

export interface ITooltipPopupConfig {
    onAfterOpenCallback?: () => void;
    onAfterCloseCallback?: () => void;
    screenTimeLimit?: number | false;
}

export type ITooltipPopupConfigMaybe = Partial<ITooltipPopupConfig>;

const defaultConfig: ITooltipPopupConfig = { screenTimeLimit: false };

/**
 * useTooltip encapsulates logic revolving around displaying a tooltipPopup, such as screenTimeLimit, intersection, open/close state etc.
 *
 * @param name - identifier for tooltip, used to access the global state of the tooltip anywhere.
 * @param shouldDisplay - a bool that determines whether the tooltip should possibly display.
 * @param rootElementRef  - RootElementRef refers to the item that invokes the tooltip and is used for detecting when the element is intersecting
 * @param config configuration object
 * - onAfterOpenCallback fires after tooltip opens
 * - onAfterCloseCallback fires after tooltip closes
 * - screenTimeLimit timer in seconds for how long the tooltipPopup should display when it's intersecting
 * @returns
 */

export default function useTooltipPopup(
    name: string,
    shouldDisplay = true,
    rootElementRef: RefObject<Element>,
    config?: ITooltipPopupConfigMaybe
) {
    const { onAfterOpenCallback, onAfterCloseCallback, screenTimeLimit } = merge({}, defaultConfig, config);

    /* Global State for whether the tooltip is open/active or not */
    const [isOpen, setIsOpen] = useRecoilState(TooltipNotificationAtom(name));

    /* State for whether the tooltip has been shown one time in this instance */
    const [hasBeenShownOneTime, setHasBeenShownOneTime] = useState(!shouldDisplay);

    /* State for intersectionObserving */
    const rootElementEntry = useIntersectionObserver(rootElementRef, {
        threshold: 0,
        rootMargin: '0%'
    });

    const isCookieAccepted = useHasCookieBeenAccepted(CookieCategory.functional);

    const isRootElementInView = !!rootElementEntry?.isIntersecting;

    /* State for onScreenTime */
    const isScreenTimerPaused =
        !isRootElementInView || !screenTimeLimit || !isCookieAccepted || (hasBeenShownOneTime && !isOpen);

    const currentOnScreenTimeMilliSeconds = useTimer(1000, isScreenTimerPaused);
    const currentOnScreenTimeSeconds = currentOnScreenTimeMilliSeconds / 1000;

    const isOverScreenTimeLimit = screenTimeLimit && currentOnScreenTimeSeconds >= screenTimeLimit;

    /* Derived state for handling close/open */
    const shouldClose = (isOpen && !isRootElementInView) || (isOpen && isOverScreenTimeLimit);
    const shouldOpen = !isOpen && isRootElementInView && !hasBeenShownOneTime && isCookieAccepted;

    /* Methods */
    const open = useCallback(
        () =>
            new Promise<void>((resolve) => {
                setIsOpen(true);
                setHasBeenShownOneTime(true);
                resolve();
            }).then(() => onAfterOpenCallback && onAfterOpenCallback()),
        [onAfterOpenCallback, setIsOpen]
    );

    const close = useCallback(
        () =>
            new Promise<void>((resolve) => {
                setIsOpen(false);
                resolve();
            }).then(() => onAfterCloseCallback && onAfterCloseCallback()),
        [onAfterCloseCallback, setIsOpen]
    );

    const handleShouldDisplayTooltipPopup = useCallback(() => {
        if (shouldClose) return close();

        if (shouldOpen) return open();

        return null;
    }, [close, open, shouldClose, shouldOpen]);

    useEffect(() => {
        handleShouldDisplayTooltipPopup();
    }, [handleShouldDisplayTooltipPopup]);

    return { isOpen, rootElementRef, close };
}
