/* eslint-disable @typescript-eslint/no-shadow */
import { RecoilRoot } from 'recoil';
import { requestDataState } from 'recoilState/atoms/requestDataState';
import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'setup/styled-components/shouldForwardProp';

// serverside
export const WrapToRecoil =
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
        (Component, stateData = {}, Router, queryClient) =>
        (props) => {
            function initializeState({ set }) {
                set(requestDataState, stateData);
            }

            return (
                <Router basename="/">
                    <QueryClientProvider client={queryClient}>
                        <RecoilRoot initializeState={initializeState}>
                            <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                                <Component {...props} />
                            </StyleSheetManager>
                        </RecoilRoot>
                    </QueryClientProvider>
                </Router>
            );
        };

// clientside
export const WrapAllComponents = (compObj, stateData, Router, queryClient) => {
    const wrappedObj = {};

    function wrapperRecoil(key) {
        wrappedObj[key] = WrapToRecoil(compObj[key], stateData, Router, queryClient);
    }

    Object.keys(compObj).map(wrapperRecoil);

    return wrappedObj;
};
