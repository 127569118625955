import { firstCharacterToLower } from 'modules/helpers/strings';
import { hasParamByKey } from 'modules/helpers/urlParams';
import { trackEvent } from 'modules/tracking/dataLayer';
import type { TrackingAction, TrackingCategory } from './UAEventTracking';

function trackClick(target: HTMLElement): void {
    if (!window.dataLayer) return;

    if (!target.classList.value) {
        return;
    }

    const classes = target.classList.value.split(' ');
    const trackingClasses = classes.filter((cl): boolean => /tracking[_-\w]+/i.test(cl));

    trackingClasses.forEach((trackingClass): void => {
        const list = trackingClass.split('_');

        const [, eventCategory, eventAction, label] = list;
        if (!eventCategory || !eventAction) return;
        let eventLabel = label;
        if (target.matches('input[type="checkbox"]')) {
            const checkbox = target as HTMLInputElement;
            eventLabel = `${eventLabel ? `${eventLabel}-` : ''}${checkbox.checked}`;
        }
        if (target.matches('.js-trackTextAsLabel')) {
            eventLabel = target.textContent.replace(/\([0-9]+\)/g, '').trim();
        }

        trackEvent({
            event: 'uaevent',
            eventCategory,
            eventAction,
            ...(eventLabel && { eventLabel })
        });
    });
}

export default (): void => {
    if (!window.dataLayer) return;

    document.addEventListener('click', (event): void => {
        window.requestIdleCallback((): void => {
            const target = event.target as HTMLElement;
            const container = target.closest('[class*="tracking_"]') as HTMLElement;
            const isSubscription = hasParamByKey('subscription');

            // isSubscription === false prevents 2x addToBasket tracking event, as tracking is handled elsewhere
            if (container && isSubscription === false) {
                trackClick(container);
            }
        });
    });
};

export function getTrackingClass(eventCategory: TrackingCategory, eventAction: TrackingAction, label?: string): string {
    const eventCategoryToLower = firstCharacterToLower(eventCategory);
    const eventActionToLower = firstCharacterToLower(eventAction);

    return `tracking_${eventCategoryToLower}_${eventActionToLower}${label ? `_${label}` : ''}`;
}
