import device from 'modules/helpers/device';
import breakpoint from 'modules/breakpoint';

const { isIOS, isSafari } = device;

const showIOSNavigation = (enable: boolean): void => {
    if (breakpoint.max('sm')) {
        if (enable === false) {
            const previousDistanceFromTop = parseInt(window.sessionStorage.getItem('pageYOffset'));
            requestAnimationFrame((): void => {
                document.body.style.top = '';
                document.body.style.position = '';
                document.body.style.maxWidth = '';
                window.scrollTo(0, previousDistanceFromTop);
            });
            window.sessionStorage.removeItem('pageYOffset');
        } else {
            const { pageYOffset } = window;
            if (pageYOffset !== 0) {
                window.sessionStorage.setItem('pageYOffset', `${pageYOffset}`);
            }
            requestAnimationFrame((): void => {
                document.body.style.maxWidth = '100vw';
                document.body.style.top = `-${pageYOffset}px`;
                document.body.style.position = 'fixed';
            });
        }
    }
};

export default showIOSNavigation;
