/* eslint-disable */
/// This file is auto generated - do not edit manually
export const SendEmail = 'Website.Shared.Buttons.SendEmail';
export const Preorder = 'Website.Shared.Buttons.Preorder';
export const AddToCart = 'Website.Shared.Buttons.AddToCart';
export const Choose = 'Website.Shared.Buttons.Choose';
export const OpenVariantPicker = 'Website.Shared.Buttons.OpenVariantPicker';
export const FindInStore = 'Website.Shared.Buttons.FindInStore';
export const SoldOut = 'Website.Shared.Buttons.SoldOut';
export const BackInStockNotification = 'Website.Shared.Buttons.BackInStockNotification';
export const BuyNow = 'Website.Shared.Buttons.BuyNow';
export const BuyNowDigitalProduct = 'Website.Shared.Buttons.BuyNowDigitalProduct';
export const CustomizeProduct = 'Website.Shared.Buttons.CustomizeProduct';
export const BuyNowCustomProduct = 'Website.Shared.Buttons.BuyNowCustomProduct';
export const BuyBundleNow = 'Website.Shared.Buttons.BuyBundleNow';
export const More = 'Website.Shared.Buttons.More';
export const ReadMore = 'Website.Shared.Buttons.ReadMore';
export const Subscribe = 'Website.Shared.Buttons.Subscribe';
export const MedicalReadMore = 'Website.Shared.Buttons.MedicalReadMore';
export const ReadLess = 'Website.Shared.Buttons.ReadLess';
export const SeeMore = 'Website.Shared.Buttons.SeeMore';
export const SeeLess = 'Website.Shared.Buttons.SeeLess';
export const Okay = 'Website.Shared.Buttons.Okay';
export const AddToFavorites = 'Website.Shared.Buttons.AddToFavorites';
export const RemoveAsFavorite = 'Website.Shared.Buttons.RemoveAsFavorite';
export const WriteReview = 'Website.Shared.Buttons.WriteReview';
