import { useDynamicStorage } from 'hooks/storage';
import { RefObject } from 'react';
import { merge } from 'lodash-es';
import useTooltipPopup, { ITooltipPopupConfig } from './useTooltipPopup';

export interface IStorageTooltipPopupConfig extends ITooltipPopupConfig {
    storageType: 'localStorage' | 'sessionStorage';
}
export type IStorageTooltipPopupConfigMaybe = Partial<IStorageTooltipPopupConfig>;
const defaultConfig: IStorageTooltipPopupConfig = { storageType: 'localStorage' };

/**
 * useStorageTooltipPopup builds upon the useTooltipPopup hook, but introduces storage options for determining when a tooltip has been shown
 *
 * @param name - Identifier/key for storage & global-state for tooltip
 * @param rootElementRef  - RootElementRef refers to the item that invokes the tooltip and is used for detecting when the element is intersecting
 * @param config configuration object
 * - storageType which type of storage to use
 * - onAfterOpenCallback fires after tooltip opens
 * - onAfterCloseCallback fires after tooltip closes
 * - screenTimeLimit a timer in seconds for how long the tooltip should display when it's intersecting
 * @returns
 */
export default function useStorageTooltipPopup(
    name: string,
    rootElementRef: RefObject<Element>,
    config?: IStorageTooltipPopupConfigMaybe
) {
    const { storageType } = merge({}, defaultConfig, config);

    const [hasBeenShown, setHasBeenShown] = useDynamicStorage(storageType, `${name}-tooltip-shown`, false);

    const tooltipConfig = {
        ...config,
        onAfterOpenCallback: () => setHasBeenShown(true)
    };

    const { isOpen, close } = useTooltipPopup(name, !hasBeenShown, rootElementRef, tooltipConfig);

    return { isOpen, close };
}
