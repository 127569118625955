/* eslint-disable import/prefer-default-export */
import BasketMessageRenderer from './BasketMessageRenderer';
import GwpMessage from './components/GwpMessage';
import MatasPlusMessage from './components/MatasPlusMessage';
import SameDayDeliveryMessage from './components/SameDayDeliveryMessage';
import SampleMessage from './components/SampleMessage';
import ShippingMessage from './components/ShippingMessage';

export const BasketMessages = {
    Gwp: GwpMessage,
    MatasPlus: MatasPlusMessage,
    SameDayDelivery: SameDayDeliveryMessage,
    Sample: SampleMessage,
    Shipping: ShippingMessage
};

export default BasketMessageRenderer;
