export { debounce } from 'debounce';

export function isAnyPartOfElementInViewport(el) {
    if ($(el) === undefined) return;

    const rect = $(el)[0].getBoundingClientRect();
    // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

    return vertInView && horInView;
}

export function areAllImagesLoaded(el, func, maxTime) {
    const unloadedImages = [];
    let complete = false;
    let timesUp = false;

    // run function if it takes too long, unless it has already completed
    if (typeof maxTime === 'number') {
        setTimeout(() => {
            timesUp = true;
            if (!complete) {
                func();
                complete = true;
            }
        }, maxTime);
    }

    // get all unloaded images
    $(el).each((i, item) => {
        $(item)
            .find('img')
            .each((i, ele) => {
                if (!ele.complete) {
                    unloadedImages.push(ele);
                }
            });
    });

    // count down when images load until all are loaded.
    if (unloadedImages.length > 0) {
        let count = unloadedImages.length;
        $(unloadedImages).on('load', () => {
            --count;
            if (count <= 0 && !timesUp) {
                complete = true;
                func();
            }
        });
    } else {
        complete = true;
        func();
    }
}

export function replaceUrlParam(url, parameter, newValue) {
    const [urlWithoutParams, params] = url.split('?');

    let found = false;
    const paramsArray = params
        ? params.split('&').map(keyValString => {
              const [stringParameter] = keyValString.split('=');
              if (stringParameter === parameter) {
                  found = true;
                  return `${stringParameter}=${newValue}`;
              }
              return keyValString;
          })
        : [];

    if (!found) {
        paramsArray.push(`${parameter}=${newValue}`);
    }

    return `${urlWithoutParams}?${paramsArray.join('&')}`;
}

export function getUrlParam(url, parameter) {
    const [, params] = url.split('?');

    if (!params) return [];

    const values = params
        .split('&')
        .filter(keyValString => {
            const [stringParameter, val] = keyValString.split('=');
            return stringParameter === parameter && val !== undefined;
        })
        .map(keyValString => decodeURIComponent(keyValString.split('=')[1]));

    return values;
}
