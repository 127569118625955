import { useEffect, useState, useCallback } from 'react';

export default function useIsPathnameActive(pathname?: string, level?: number) {
    const [isActive, setIsActive] = useState<boolean>(false);

    const checkPathname = useCallback(() => {
        if (!pathname) return null;

        const { pathname: currentPathname } = window.location;

        /* 
            if level is specified, only search in that part of the path example. level 2 /makeup/ansigt/pudder, 
            /makeup is lvl 0
            /ansigt is lvl 1
            /pudder is lvl 2
            
            therefore we will only search for a match in /pudder string
        */
        if (level !== undefined) {
            const currentPathnameArray = currentPathname.substring(1).split('/'); // substring(1) just selects the string without the first character which is the initial forward slash
            const pathnameArray = pathname.substring(1).split('/');

            if (!currentPathnameArray[level]) return setIsActive(false);

            return setIsActive(currentPathnameArray[level] === pathnameArray[level]);
        }

        return setIsActive(currentPathname === pathname);
    }, [level, pathname]);

    useEffect(() => {
        checkPathname();
    }, [checkPathname, pathname]);

    return isActive;
}
