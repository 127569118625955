/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import UAEventTracking, { ITrackUAEvent } from 'modules/tracking/UAEventTracking';
import styled from 'styled-components';

const Button = styled.button``;

export interface IUaEventButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    uaEvent?: ITrackUAEvent;
}

const UAEventButton = React.forwardRef<HTMLButtonElement, IUaEventButton>((props, ref): JSX.Element => {
    const { onClick, uaEvent, ...restProps } = props;

    function clickHandler(event): void {
        if (onClick) {
            onClick(event);
        }

        if (uaEvent) {
            UAEventTracking(uaEvent);
        }
    }

    return <Button {...restProps} onClick={clickHandler} ref={ref} />;
});

export default UAEventButton;
