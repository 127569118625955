export enum zIndex {
    // the document layer
    document = 0,
    // Things placed in a fixed position on the page
    fixed = 1000,
    // Elements that cover the site to have other things in focus
    backdrop = 2000,
    // Elements that are placed over the page relative to some content of the page
    dialog = 3000,
    // Menus and other things placed out side the canvas and slides in
    offCanvas = 4000,
    // Modals placed over all the content of the page
    modal = 5000,
    // Confirmation modals that can open over the normal modals
    confirm = 6000,
    // used for messages that can be shown on top of the modals, mostly used for messages such as successfull or failed requests.
    growl = 7000
}

export default (name: string): zIndex => zIndex[name];
