import React, { useState, useEffect, useRef, type HTMLAttributes } from 'react';
import { useSpring, animated, type SpringConfig } from '@react-spring/web';
import styled from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';

const AccordionContainer = styled.div`
    overflow: hidden;
    position: relative;
`;
const AnimatedAccordionContainer = animated<any>(AccordionContainer);

const RefElement = styled.div`
    overflow: hidden;
`;

interface IAccordion extends HTMLAttributes<HTMLDivElement> {
    collapsed: boolean;
    collapsedHeight?: number;
    springConfig?: SpringConfig;
}

function AccordionAnimation({
    collapsed,
    collapsedHeight = 0,
    springConfig,
    children,
    ...rest
}: IAccordion): JSX.Element {
    const [contentHeight, setContentHeight] = useState<number>();
    const ref = useRef<HTMLDivElement>(null);
    const { className } = rest;
    const [styles, api] = useSpring(() => ({
        height: collapsed ? collapsedHeight : null,
        config: springConfig
    }));

    useEffect(() => {
        if (contentHeight) {
            api.start({ height: collapsed ? collapsedHeight : contentHeight, config: springConfig });
        }

        return () => {
            if (contentHeight) {
                api.stop();
            }
        };
    }, [collapsed, contentHeight, collapsedHeight, api, springConfig]);

    useEffect(() => {
        const element = ref.current;

        if (!element) return;

        function setContentHeightFunction(): void {
            if (element) {
                setContentHeight(element.scrollHeight);
            }
        }

        const observer = new ResizeObserver(() => setContentHeightFunction());

        setContentHeightFunction();

        if (element) {
            observer.observe(element);
        }

        // eslint-disable-next-line consistent-return
        return (): void => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, []);

    return (
        <AnimatedAccordionContainer style={styles} className={className}>
            <RefElement ref={ref}>{children}</RefElement>
        </AnimatedAccordionContainer>
    );
}

export default AccordionAnimation;
