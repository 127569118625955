export default function isIntersecting(element: Element, observerOptions?: IntersectionObserverInit): Promise<boolean> {
    return new Promise((resolve) => {
        const options = {
            ...observerOptions,
            ...{
                rootMargin: '0%',
                threshold: 1,
                root: null
            }
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    resolve(true);
                }
            });

            resolve(false);
        }, options);

        observer.observe(element);
    });
}
