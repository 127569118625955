import { BoxShadowSmall, ColorNeutralWhite, Spacing12, Spacing16, Spacing8 } from 'autogen/design-tokens/tokens';
import styled, { css } from 'styled-components';
import { breakpoint, sizes, zIndex } from 'variables';
import useShouldHeaderCollapse from '../shared/hooks/useShouldHeaderCollapse';

/* #region  styles */
const HeaderComponent = styled.header<{
    $shouldCollapse: boolean;
    $hasScrolled: boolean;
}>`
    position: sticky;
    top: 0;
    background-color: ${ColorNeutralWhite};
    z-index: ${zIndex.fixed};
    transition: transform 0.2s ease;
    transform: none;

    ${({ $shouldCollapse }) =>
        $shouldCollapse &&
        css`
            transform: translateY(-100%);
        `}

    ${({ $hasScrolled }) =>
        $hasScrolled &&
        css`
            box-shadow: ${BoxShadowSmall};
        `}
`;

const LayoutContainer = styled.div`
    display: grid;
    grid-template-columns: auto minmax(auto, 188px);
    justify-content: space-between;
    align-items: center;
    row-gap: ${Spacing12};
    column-gap: ${Spacing8};
    max-width: 100%;
    margin: auto;
    padding: ${Spacing16};

    ${breakpoint.up(sizes.sm)} {
        grid-template-columns: auto minmax(auto, 280px);
    }

    ${breakpoint.up(sizes.lg)} {
        grid-template-columns: minmax(140px, auto) 1fr minmax(364px, auto);
        column-gap: 32px;
        position: relative;
    }

    ${breakpoint.up(sizes.xl)} {
        grid-template-columns: minmax(auto, 364px) minmax(auto, 640px) minmax(auto, 364px);
        max-width: 1230px;
    }
    ${breakpoint.up(sizes.xxl)} {
        max-width: 1400px;
    }

    ${breakpoint.up(sizes.xxxl)} {
        max-width: 1680px;
    }
`;

const LeftColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column: 1;
    grid-row: 1;

    ${breakpoint.up(sizes.md)} {
        grid-column: auto;
    }
`;

const CenterColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / -1;
    grid-row: 2;

    ${breakpoint.up(sizes.lg)} {
        grid-column: auto;
        grid-row: 1;
    }
`;

const RightColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-column: 2;
    grid-row: 1;

    ${breakpoint.up(sizes.lg)} {
        grid-column: auto;
    }
`;
/* #endregion */

export default function Header({ leftSlot, centerSlot, rightSlot }) {
    const [shouldCollapse, hasScrolled] = useShouldHeaderCollapse();

    return (
        <HeaderComponent $shouldCollapse={shouldCollapse} $hasScrolled={hasScrolled}>
            <LayoutContainer>
                <LeftColumn>{leftSlot}</LeftColumn>
                <CenterColumn>{centerSlot}</CenterColumn>
                <RightColumn>{rightSlot}</RightColumn>
            </LayoutContainer>
        </HeaderComponent>
    );
}
