import React from 'react';
import styled, { keyframes } from 'styled-components';

const animateSize = keyframes`
	0% {
		r: 14.1505;
		opacity: 1;
	}
	50% {
		r: 9.84946;
		opacity: 0.5;
	}
	100% {
		r: 14.1505;
		opacity: 1;
	}
`;

const Spinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 300px;
    min-height: 200px;
    background-color: rgba(255, 255, 255, 0.5);
`;

const Svg = styled.svg`
    width: 75px;
`;

const Circle = styled.circle<{ middle?: boolean }>`
    opacity: 1;
    animation-name: ${animateSize};
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 0.8s;
    animation-delay: ${({ middle }): string => (middle ? '-0.4s' : 'none')};
`;

export const ReactLoadingSpinner = (props): JSX.Element => (
    <Spinner {...props}>
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 30">
            <Circle cx="15" cy="15" r="14.1505" />
            <Circle middle cx="60" cy="15" r="14.1505" />
            <Circle cx="105" cy="15" r="14.1505" />
        </Svg>
    </Spinner>
);
export default ReactLoadingSpinner;

export class LoadingSpinner {
    body: JQuery<HTMLElement>;

    loadingSpinnerClass: string;

    loadingOverlayClass: string;

    constructor() {
        this.body = $('body');
        this.loadingSpinnerClass = 'loading-spinner';
        this.loadingOverlayClass = 'loading-overlay';
    }

    init(): void {
        this.loadingSpinner();
    }

    loadingSpinner(): void {
        jQuery.loadingSpinner = (element, action, timeOut): void => {
            const $this = $(element);

            if (element === window) {
                this.toggleOverlay(action);
            }

            if (action) {
                requestAnimationFrame(() => {
                    $this.addClass(this.loadingSpinnerClass);
                });

                if (typeof timeOut === 'number') {
                    setTimeout(() => {
                        requestAnimationFrame(() => {
                            $this.removeClass(this.loadingSpinnerClass);
                        });
                    }, timeOut);
                }
            } else if (!action || $this.hasClass(this.loadingSpinnerClass)) {
                requestAnimationFrame(() => {
                    $this.removeClass(this.loadingSpinnerClass);
                });
            } else {
                console.error(`Error in spinner - element: ${$this} action: ${action}`);
            }
        };
    }

    toggleOverlay(action): void {
        requestAnimationFrame(() => {
            if (action) {
                this.body.append(`<div class="${this.loadingOverlayClass} ${this.loadingSpinnerClass}"></div>`);
            } else {
                this.body.children(`.${this.loadingOverlayClass}`).remove();
            }
        });
    }
}
