import React, { HTMLAttributes, useContext } from 'react';
import styled from 'styled-components';
import UAEventButton from 'modules/react-button/uaEventButton';
import { format } from 'modules/helpers/strings';
import Translations from 'modules/translations';
import { SvgSprite } from 'modules/svgSprite';
import { rem } from 'modules/helpers/style';
import MainContext from 'components/QuickSearch/components/context';
import { GA4Tracking } from 'modules/tracking/GA4';
import { layoutEnum, listTypeEnum } from '../../enums';

const Inner = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Spacer = styled.span`
    flex: 0 1 100%;
`;

const Content = styled.span`
    display: flex;
    flex: 0 0 auto;
`;

const SvgArrow = styled(SvgSprite)`
    width: ${rem(15)};
    height: ${rem(15)};
    margin-left: ${rem(5)};
`;

interface IHitsTotalLink extends HTMLAttributes<HTMLButtonElement> {
    hitsTotal: number;
    url: string;
    listType: listTypeEnum;
    searchTerm?: string;
}

export default function HitsTotalLink({
    hitsTotal,
    url,
    listType,
    searchTerm,
    ...restProps
}: IHitsTotalLink): JSX.Element {
    const { layout } = useContext<any>(MainContext);
    const { className } = restProps;
    const listTypeEnumKey = Object.keys(listTypeEnum)[listType - 1];
    const layoutEnumKey = Object.keys(layoutEnum)[layout - 1];

    return (
        <UAEventButton
            {...restProps}
            className={`btn ${className}`}
            onClick={(): void => {
                window.location.href = url;
                GA4Tracking({
                    eventName: 'see_all_results',
                    context: 'search',
                    category: 'ecommerce',
                    eventParams: [
                        { param_name: 'search_type', param_value: 'quick_search' },
                        { param_name: 'search_term', param_value: searchTerm }
                    ]
                });
            }}
            uaEvent={{
                eventCategory: 'quickSearch',
                eventAction: 'see all results',
                eventLabel: `${layoutEnumKey}_${listTypeEnumKey}`
            }}
        >
            <Inner>
                <Spacer />
                <Content>{format(Translations.Website.SearchDialog.TotalHitsLink)(hitsTotal)}</Content>
                <Spacer>
                    <SvgArrow sprite="ArrowRight" />
                </Spacer>
            </Inner>
        </UAEventButton>
    );
}
