import { Spacing16 } from 'autogen/design-tokens/tokens';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import Flex, { type FlexElementProps, type FlexProperties } from '../Flex';

type FlexRow = { children: ReactNode } & Omit<FlexProperties, 'direction'> & FlexElementProps;

const FlexRowComponent = React.forwardRef<HTMLDivElement, FlexRow>(
    ({ gap = Spacing16, children, ...restProps }, ref) => (
        <Flex ref={ref} {...restProps} gap={gap} direction="row">
            {children}
        </Flex>
    )
);

export default styled(FlexRowComponent)``;
