import { POST } from 'modules/helpers/fetch';

export default class DeleteElement {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.buttons = this.wrapper.getElementsByClassName('js-delete-button');
        this.action = this.wrapper.dataset.jsDeleteElement;
    }

    init() {
        this.bindings();
    }

    bindings() {
        [...this.buttons].forEach(element => {
            element.addEventListener('click', () => {
                this.deleteWrapper();
            });
        });
    }

    deleteWrapper() {
        this.wrapper.outerHTML = null;

        if (this.action) {
            POST(this.action);
        }
    }
}

