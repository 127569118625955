import { trackEvent } from 'modules/tracking/dataLayer';
import { useContext } from 'react';
import type { EventParams, UATrackingHelpers, UATrackingProps } from './types/types';
import getProductListParams from './helpers/getProductListParams';
import eventCategoryToContext from './helpers/eventCategoryToContext';
import { Context } from './helpers/TrackingContext';

type GA4TrackingProps = UATrackingProps | ((helpers: UATrackingHelpers) => UATrackingProps);

/**
 * If you need parent context, you need to use the following hook:
 * @see {@link useGA4Tracking}
 */

export function GA4Tracking(props: GA4TrackingProps) {
    const {
        eventName,
        category,
        context,
        eventParams: eventParamsDecorated = []
    } = typeof props === 'function' ? props({ getProductListParams, eventCategoryToContext }) : props;

    if (!category || !context) {
        console.error('GA4 Tracking is missing "category" or "context" value');
        return;
    }

    if (!window.dataLayer) {
        window.dataLayer = [];
    }

    const eventParams: EventParams = [];
    eventParams.push({ param_name: 'context', param_value: context });
    eventParams.push({ param_name: 'category', param_value: category });

    trackEvent({
        event: 'gaevent',
        event_name: eventName,
        event_params: [...eventParams, ...eventParamsDecorated]
    });
}

export default function useGA4Tracking() {
    const parentContext = useContext(Context);

    return (props: GA4TrackingProps) => {
        const {
            eventName,
            category,
            context,
            eventParams = []
        } = typeof props === 'function' ? props({ getProductListParams, eventCategoryToContext }) : props;

        const {
            context: contextFromParent,
            category: categoryFromParent,
            eventParams: eventParamsFromParent = []
        } = parentContext || {};

        GA4Tracking({
            eventName,
            context: context || contextFromParent,
            category: category || categoryFromParent,
            eventParams: [...eventParams, ...eventParamsFromParent]
        });
    };
}
