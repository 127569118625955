import {
    BorderRadiusMedium,
    BoxShadowSmall,
    ColorNeutralLightGrey,
    ColorNeutralBlack,
    ColorNeutralWhite,
    ColorNordicGrey1
} from 'autogen/design-tokens/tokens';
import { Button } from 'components/Button';
import type IButton from 'components/Button/shared/types/button';
import React from 'react';
import styled, { css } from 'styled-components';

const SelectButtonElement = styled(Button)`
    justify-content: space-between;
    background-color: ${ColorNeutralWhite};
    color: ${ColorNeutralBlack};
    border-width: 1px;
    border-style: solid;
    border-color: ${ColorNordicGrey1};
    border-radius: ${BorderRadiusMedium};

    &:hover {
        border-color: ${ColorNeutralLightGrey};
    }

    &:focus {
        box-shadow: ${BoxShadowSmall};
        outline: ${ColorNeutralLightGrey};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

function SelectButton(props: IButton, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) {
    return <SelectButtonElement ref={ref} variant={null} type="button" {...props} />;
}

export default React.forwardRef(SelectButton);
