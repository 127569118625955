import { FontFontFamilyPrimary, FontLineHeightTight, FontWeightSemiBold } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

const LinkText = css`
    font-family: ${FontFontFamilyPrimary};
    line-height: ${FontLineHeightTight};
    font-weight: ${FontWeightSemiBold};
    text-decoration: underline;
`;

export default LinkText;
