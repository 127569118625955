import SideMenuOpener from 'components/sideMenu/SideMenuOpener';
import React from 'react';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';
import QuickSearch from 'components/QuickSearch';
import type { PrimaryHeaderViewModel } from 'autogen/swagger/layout';
import { Provider } from 'react-redux';
import store from 'store';
import SmartviewSideDrawer from 'components/generic/smartview/smartviewSideDrawer/SmartviewSideDrawer';
import { ColorBrandBlue1 } from 'autogen/design-tokens/tokens';
import MatasLogoSVG from 'icons/logos/MatasLogo.svg?react';
import { useTranslation } from 'react-i18next';
import { HeaderLogoAltText } from 'autogen/translation-keys/trans-website-imagealttext';
import Header from './Header';
import SessionMenu from '../../SessionMenu/SessionMenu';

/* #region  styles */
const MatasLogoIcon = styled(MatasLogoSVG)`
    width: 72px;

    ${breakpoint.up(sizes.sm)} {
        width: 80px;
        height: 40px;
    }

    ${breakpoint.up(sizes.lg)} {
        width: 88px;
        height: 40px;
    }
`;

const MatasLogo = styled.a`
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 12px;

    ${breakpoint.up(sizes.sm)} {
        gap: 16px;
    }
`;
/* #endregion */

function LeftContent() {
    const { t } = useTranslation();
    return (
        <Container>
            <SideMenuOpener />
            <MatasLogo href="/" aria-label={t(HeaderLogoAltText)}>
                <MatasLogoIcon fill={ColorBrandBlue1} />
            </MatasLogo>
        </Container>
    );
}

export default function PrimaryHeader({ model: { sessionMenu } }: { model: PrimaryHeaderViewModel }) {
    const { searchText } = sessionMenu;

    return (
        <Provider store={store}>
            <SmartviewSideDrawer />

            <Header
                leftSlot={<LeftContent />}
                centerSlot={<QuickSearch searchText={searchText} />}
                rightSlot={<SessionMenu model={sessionMenu} />}
            />
        </Provider>
    );
}
