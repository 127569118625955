import type { Splash, SplashType } from 'autogen/swagger/product';
import type { HTMLAttributes } from 'react';
import type { BreakPointsType } from 'variables';

type splashesWithImagesTypes = Extract<SplashType, 'ProHairCare' | 'Modiface' | 'Derma' | 'Dba' | 'Taenk'>;
export const splashesWithImagesTypesArray: Array<splashesWithImagesTypes> = [
    'ProHairCare',
    'Modiface',
    'Derma',
    'Dba',
    'Taenk'
];

type SplashSizes = 'sm' | 'md' | 'xl';
export type SplashSizesObject = BreakPointsType<SplashSizes>;

export interface ISplash extends Splash, HTMLAttributes<HTMLDivElement> {
    size?: SplashSizes | SplashSizesObject;
}
export interface ISplashes {
    splashes: Splash[];
}
