import React, { lazy } from 'react';
import breakpoint from 'modules/breakpoint';
import { colors } from 'variables';
import ModalPrint from 'modules/modalPrint';
import { fetchModule, loadModule } from 'modules/helpers/moduleLoaders';
import { hasParamByKey } from 'modules/helpers/urlParams';

const Default = lazy(() => import('modules/modal/layouts/defaultLayout'));

export function bindComponents({ wrapper, close }) {
    wrapper.addEventListener('click', e => {
        const { target: t } = e;
        const closeButton = t.closest('.js-modalClose');
        if (closeButton) {
            close();
        }
    });

    ModalPrint(wrapper);
    const wrapperClasses = Array.prototype.join.call(wrapper.classList, '.');

    const onFormRebind = () => {};

    fetchModule(`.${wrapperClasses} .js-form-submit`, () =>
        import(/* webpackChunkName: "formSubmit" */ 'modules/formSubmit')
    ).then(({ nodes, Module: FormSubmit }) => {
        nodes.forEach(node => {
            const form = new FormSubmit(node, onFormRebind, onFormRebind);
            form.init();
            onFormRebind();
        });
    });
}

async function openModal(event) {
    const { target } = event;

    const modalLink =
        target.closest('[data-js-modal-url]') ||
        target.closest('[data-js*="genericLoginModal"]') ||
        target.closest('[data-js-modal-html]');

    if (!modalLink) {
        return;
    }
    event.preventDefault();

    const size = modalLink.dataset.jsModalSize;
    let js;
    let url;
    let html;
    let modifiers = '';
    let theme = {};
    if (modalLink.matches('[data-js-modal-url]')) {
        const {
            dataset: { jsModalUrl }
        } = modalLink;
        [url, modifiers = ''] = jsModalUrl.split(':');
    } else if (modalLink.matches('[data-js*="genericLoginModal"]')) {
        ({
            dataset: { js, url }
        } = modalLink);
        [, modifiers] = js.split(':');
    } else if (modalLink.matches('[data-js-modal-html]')) {
        const {
            dataset: { jsModalHtml }
        } = modalLink;
        html = jsModalHtml;
    }

    if (size) {
        switch (size) {
            case 'sm':
                theme = { width: '500px', minWidth: '500px' };
                break;
            case 'md':
                theme = { width: '700px' };
                break;
            case 'xl':
                theme = { width: '1200px' };
                break;
            default:
        }
    }

    if (document.body.classList.contains('myprofile') && breakpoint.max('lg') && theme.backgroundColor === undefined) {
        theme.backgroundColor = colors.myProfileBackground;
    }

    const isLoginModal = modifiers.includes('loginModal');

    let modal;
    if (isLoginModal) {
        const { default: LoginModal } = await import(/* webpackChunkName: "loginModal" */ 'modules/modal/loginModal');
        modal = LoginModal();
    } else {
        const { default: HtmlModal } = await import(/* webpackChunkName: "htmlModal" */ 'modules/modal/htmlModal');
        modal = HtmlModal({ layout: { component: <Default className={modifiers} />, theme } });
    }

    const contentObject = html ? { html } : { url };
    modal({
        ...contentObject,
        onAfterContent: bindComponents
    });
}

export default openModal;

export function openModalFromQuery(nodes) {
    document.querySelectorAll(nodes).forEach(node => {
        const modalQuery = node.dataset.jsModalQuery;

        if (!modalQuery) {
            return;
        }

        if (hasParamByKey(modalQuery)) {
            node.click();
        }
    });
}
