import React from 'react';
import QuickView from './quickView';
import type { IControlledQuickView, IUncontrolledQuickView } from './shared/types';
import { QuickViewAbsoluteHeader, QuickViewAbsoluteContent } from './components/QuickViewWithAbsoluteHeader';

const QuickViewWithAbsoluteHeader: React.FC<React.PropsWithChildren<IUncontrolledQuickView>> = (props) => {
    const { children } = props;

    return (
        <QuickView
            {...props}
            headerRenderer={({ ...headerProps }) => <QuickViewAbsoluteHeader {...headerProps} />}
            contentRenderer={({ ...contentProps }) => <QuickViewAbsoluteContent {...contentProps} />}
        >
            {children}
        </QuickView>
    );
};

const ControlledQuickViewWithAbsoluteHeader: React.FC<React.PropsWithChildren<IControlledQuickView>> = (props) => {
    const { children } = props;

    return (
        <QuickView.Controlled
            {...props}
            headerRenderer={({ ...headerProps }) => <QuickViewAbsoluteHeader {...headerProps} />}
            contentRenderer={({ ...contentProps }) => <QuickViewAbsoluteContent {...contentProps} />}
        >
            {children}
        </QuickView.Controlled>
    );
};

export default Object.assign(QuickViewWithAbsoluteHeader, {
    Controlled: ControlledQuickViewWithAbsoluteHeader
});
