import { ColorNeutralDarkGrey, ColorSpot1, Spacing2, Spacing4 } from 'autogen/design-tokens/tokens';
import { Typography } from 'components/generic/typography';
import styled, { css } from 'styled-components';
import dynamic from 'helpers/dynamic';
import { OverlineFontSizes } from 'components/generic/typography/types';
import { HTMLAttributes } from 'react';
import { IPriceModule } from '../shared/types';
import usePriceLabelInfo from './hooks/usePriceLabelInfo';

type PriceLabelRenderer = Required<Pick<IPriceModule, 'priceLabel' | 'onDiscount'>> &
    Pick<HTMLAttributes<HTMLSpanElement>, 'className'> & {
        size: OverlineFontSizes;
    };

const StyledOverline = styled(Typography.Overline)`
    display: inline-flex;
    align-items: center;
    line-height: 1;
`;

const Text = styled.div<{ $onDiscount: PriceLabelRenderer['onDiscount'] }>`
    ${({ $onDiscount }) =>
        $onDiscount &&
        css`
            color: ${ColorSpot1};
        `}
`;

const InfoButton = styled.button`
    display: flex;
    padding: ${Spacing2} ${Spacing4};
    margin: 0;
    background: 0;
    border: 0;
`;

const ExclamationMarkCircle = styled(dynamic(() => import('icons/InfoCircle.svg?react')))`
    width: 16px;
    height: 16px;
    fill: ${ColorNeutralDarkGrey};
`;

export default function PriceLabelRenderer(props: PriceLabelRenderer) {
    const { size, priceLabel, onDiscount, className } = props;
    const { text, type, isInfoIconEnabled } = priceLabel || {};
    const openInfo = usePriceLabelInfo();

    return (
        <StyledOverline size={size} className={className}>
            <Text $onDiscount={onDiscount}>{text}</Text>
            {isInfoIconEnabled && (
                <InfoButton type="button" onClick={() => openInfo(type)}>
                    <ExclamationMarkCircle />
                </InfoButton>
            )}
        </StyledOverline>
    );
}
