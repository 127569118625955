/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';

type NodeArr = { node: Element; isIntersecting?: boolean }[];

export function useOnItemsIntersectionEffect<T extends HTMLElement>(
    ref: React.RefObject<T>,
    effectCallback: (nodeArr: NodeArr) => void,
    deps: any[] = [],
    disconnect = false
) {
    useEffect(() => {
        if (!disconnect) {
            const refElement = ref.current;
            const nodeArr: NodeArr = [];

            const intersectionCallback: IntersectionObserverCallback = (entries) => {
                entries.forEach((entry) => {
                    const { target, isIntersecting } = entry;
                    const index = nodeArr.findIndex((x) => x.node === target);
                    nodeArr[index] = { node: target, isIntersecting };
                });

                effectCallback(nodeArr);
            };

            const observer = new IntersectionObserver(intersectionCallback, { root: ref.current, threshold: 1 });

            if (refElement) {
                const { children } = ref.current;
                Array.from(children).forEach((x) => {
                    observer.observe(x as Element);
                    nodeArr.push({ node: x as Element });
                });
            }

            return () => {
                observer.disconnect();
            };
        }
    }, deps);
}

export default function useHiddenItemsObserver<T extends HTMLElement>(ref: React.RefObject<T>) {
    const [count, set] = useState(0);

    useOnItemsIntersectionEffect(
        ref,
        (nodeArr) => {
            set(nodeArr.filter(({ isIntersecting }) => !isIntersecting).length);
        },
        [count]
    );

    return count;
}
