import { useEffect, useState } from 'react';

export default function useIsOverflowing(ref, initialState) {
    const [isOverflowing, setIsOverflowing] = useState(initialState);

    useEffect(() => {
        const currentContent = ref?.current;
        if (!currentContent) return;

        const { clientHeight, scrollHeight } = currentContent;
        const hasOverflow = scrollHeight > clientHeight;

        setIsOverflowing(hasOverflow);
    }, []);

    return isOverflowing;
}
