import {
    BorderRadiusMedium,
    BorderSecondary,
    ColorNeutralWhite,
    ColorNordicGrey3,
    ColorNordicGrey4,
    Spacing16
} from 'autogen/design-tokens/tokens';
import List from 'components/List';
import { Size } from 'components/List/shared/types';
import { IMenuSection } from 'components/generic/menu/shared/types';
import getTypeStyling from 'helpers/getTypeStyling';
import React from 'react';
import styled, { css } from 'styled-components';
import { StyledListItem } from 'components/List/components/ListItem';
import { BodyThemeLargeSemiBold } from 'components/generic/typography';
import { SectionSizes } from '../shared/styling';

export const itemDivider = css`
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        display: block;
        border-bottom: 1px solid ${ColorNordicGrey3};
    }
`;

const MenuSection = styled.div<{ size?: IMenuSection['size'] }>`
    background-color: ${ColorNeutralWhite};
    ${({ size, theme }) =>
        size
            ? getTypeStyling((resolvedSize: Size) => SectionSizes[resolvedSize].section)(size as Size)
            : getTypeStyling((resolvedSize: Size) => SectionSizes[resolvedSize].section)(theme.size)}
`;

export const MenuSectionHeader = styled.div<{ size?: IMenuSection['size'] }>`
    display: flex;
    align-items: center;

    ${({ size, theme }) =>
        size
            ? getTypeStyling((resolvedSize: Size) => SectionSizes[resolvedSize].heading)(size as Size)
            : getTypeStyling((resolvedSize: Size) => SectionSizes[resolvedSize].heading)(theme.size)}

    ${BodyThemeLargeSemiBold}
`;

export default function DefaultMenuSection({
    size,
    headline,
    headlineRenderer = (headlineProps) => (
        <MenuSectionHeader size={headlineProps.size}>{headlineProps.headline}</MenuSectionHeader>
    ),
    headlineOutsideSection,
    children,
    ...restProps
}: IMenuSection) {
    return (
        <>
            {headline && headlineOutsideSection && headlineRenderer({ headline, size })}
            <MenuSection size={size} {...restProps}>
                {headline && !headlineOutsideSection && headlineRenderer({ headline, size })}
                <List>{children}</List>
            </MenuSection>
        </>
    );
}

const StyledSecondaryMenuSection = styled(DefaultMenuSection)`
    position: relative;
    background-color: ${ColorNordicGrey4};
`;

export function SecondaryMenuSection({ children, ...restProps }: IMenuSection) {
    return <StyledSecondaryMenuSection {...restProps}>{children}</StyledSecondaryMenuSection>;
}

const StyledSecondaryMenuSectionWithDividers = styled(SecondaryMenuSection)`
    & ${StyledListItem} {
        ${itemDivider}

        // itemDivider overrride
        &:after {
            border-bottom: ${BorderSecondary};
        }

        // In this kind of section, the item divider is hidden when the item or its immidiate sibling is selected or has an :active or :focus-within state
        &:where(:active, :focus-within, .selected):after,
        &:where(:has(+ :where(${StyledListItem}:active,
                        ${StyledListItem}:focus-within,
                        ${StyledListItem}.selected))):after {
            display: none;
        }
    }

    &:after {
        display: none;
    }
`;

export function SecondaryMenuSectionWithDividers({ children, ...restProps }: IMenuSection) {
    return <StyledSecondaryMenuSectionWithDividers {...restProps}>{children}</StyledSecondaryMenuSectionWithDividers>;
}

const StyledBoxedMenuSection = styled(DefaultMenuSection)`
    border-radius: ${BorderRadiusMedium};
    border-bottom: none;
    padding: 0;
    margin: 0 ${Spacing16} ${Spacing16};
    overflow: hidden;

    & ${StyledListItem} {
        min-height: 56px;
    }

    & ${StyledListItem}:not(:first-child) {
        &.selected,
        &:active,
        &:focus-within {
            box-shadow: 0px -1px #d6d6d6;
        }
    }

    & ${StyledListItem}:not(:last-child):active {
        position: relative;
    }
`;

export function BoxedMenuSection({ children, ...restProps }: IMenuSection) {
    return <StyledBoxedMenuSection {...restProps}>{children}</StyledBoxedMenuSection>;
}
