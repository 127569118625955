import styled from 'styled-components';
import { fetchHTML } from 'modules/helpers/fetch';
import createModal, { type ICreateProps, type IUseProps } from 'modules/modal';
import debounce from 'debounce';
import TextWrapper from 'modules/modal/components/textWrapper';
import Spinner from 'modules/loadingSpinner';
import { spacing } from 'variables';
import DangerouslySetInnerHTML from 'dangerously-set-html-content';
import { growlGeneralExceptionError } from 'modules/growl';

export const Content = ({ html, isCMSArticle, theme, onClick, className }) => {
    const InnerHTML = <DangerouslySetInnerHTML onClick={onClick} className={className} html={html} />;
    return isCMSArticle ? <TextWrapper theme={theme}>{InnerHTML}</TextWrapper> : InnerHTML;
};

const { modalPadding } = spacing;

const PaddedContent = styled(Content)<{ hideModalHeader: boolean }>`
    padding: ${({ hideModalHeader }) => (hideModalHeader ? modalPadding : `0 ${modalPadding} ${modalPadding}`)};
`;

export interface HtmlModalProps extends IUseProps {
    html?: string;
    url?: string;
    ContentWrapper?: React.ElementType;
}

export default (createProps: ICreateProps = {}) => {
    const {
        layout: createLayout,
        layout: { header, customTheme: theme } = {}
    }: ICreateProps = createProps;
    // Wait X ms before opening the modal - this gives fetchHTML() a chance to resolve, so we maybe avoid flash of empty modal
    const modal = debounce(createModal(createProps), 80);

    return async (props: HtmlModalProps = { open: false }) => {
        const {
            html: incomingHTML,
            url,
            onAfterContent,
            onAfterClose,
            ContentWrapper = PaddedContent,
            layout = createLayout
        } = props;

        let html;

        // Click handler to handle legacy modal content
        const contentClickHandler = ({ target }) => {
            if (target.matches('button[data-js="close"]')) {
                modal({ open: false });
            }
        };

        async function getHtml() {
            if (url) {
                modal({ open: true, content: <Spinner />, layout, userCanClose: false }); // userCanClose is set to false when loading to avoid people accidently closing the modal before seeing the content
                html = await fetchHTML(url);
            } else {
                html = incomingHTML;
            }
            return html;
        }

        getHtml()
            .then((resolvedHtml) => resolvedHtml.includes('class="cms-article"'))
            .then((isCMSArticle) =>
                modal({
                    open: true,
                    content: (
                        <ContentWrapper
                            onClick={contentClickHandler}
                            html={html}
                            isCMSArticle={isCMSArticle}
                            hideModalHeader={header === null}
                            theme={theme}
                        />
                    ),
                    onAfterContent,
                    onAfterClose,
                    layout,
                    userCanClose: true
                })
            )
            .catch((e) => {
                console.error(e);
                growlGeneralExceptionError();
                modal({ open: false, content: <Spinner />, layout, userCanClose: true });
            });
    };
};
