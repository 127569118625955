import isClient from 'helpers/isClient';
/**
 * Locking the scroll, by setting an "overflow: hidden;" on the body element.
 */
export default function toggleScrollLock(status: boolean): void {
    if (isClient) {
        requestAnimationFrame(() => {
            const bodyElm = window.document.body;
            if (!status) {
                bodyElm.style.removeProperty('overflow');
            } else {
                bodyElm.style.setProperty('overflow', 'hidden');
            }
        });
    }
}
