import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { PictureDeprecated } from 'components/generic/picture';
import Translations from 'modules/translations';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { ColorNeutralDarkGrey, FontLeadParagraphFamily, FontBody1Family } from 'autogen/design-tokens/tokens';
import MainContext from 'components/QuickSearch/components/context';
import { GA4Tracking } from 'modules/tracking/GA4';
import { layoutEnum } from '../../enums';
import LinkAction from 'components/shared/Routing/components/LinkAction';

const List = styled.ul`
    padding: 10px 0;
    margin: 0;
`;

const ListItem = styled.li`
    list-style: none;
`;

const Link = styled(LinkAction)`
    padding: 10px 20px;
    display: flex;
    &:hover {
        text-decoration: none;
        background-color: #ececec;
    }
    ${(props) => props.isActive && 'background-color: #ececec;'}
`;

const ArticlePicture = styled(PictureDeprecated)`
    flex: 0 0 50px;
    max-height: 50px;

    width: auto;
    height: auto;
    margin-right: 15px;

    ${(props) =>
        props.layout === layoutEnum.tab &&
        css`
            flex: 0 0 90px;
            max-width: 90px;
            max-height: none;
            height: 90px;
        `};
`;

const PicturePlaceholder = styled(ArticlePicture)`
    ${(props) => props.layout === layoutEnum.column && 'height: 50px; width: 50px;'}
    background: repeating-linear-gradient(-45deg, #012350, #012350 8px, #335071 8px, #335071 16px);
`;

const SectionName = styled.div`
    font-weight: 700;
    color: ${ColorNeutralDarkGrey};
    text-transform: uppercase;
    margin-bottom: 0;
    font-family: ${FontBody1Family};
    font-size: 11px;
    letter-spacing: 0.12em;
    &:after {
        content: '';
        height: 2px;
        width: 32px;
        background-color: ${ColorNeutralDarkGrey};
        margin: 5px 0 8px;
        display: block;
    }
`;

const Title = styled.div`
    ${(props) =>
        props.layout === layoutEnum.tab &&
        css`
            font-family: ${FontLeadParagraphFamily};
            color: #012350;
        `}
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 0px;
    justify-content: ${(props) => (props.layout === layoutEnum.tab ? 'flex-start' : 'center')};
    align-items: flex-start;
`;

export default (props) => {
    const { items, searchTerm } = props;
    const mainContext = useContext(MainContext);
    const { layout, onItemHover } = mainContext;
    console.log(props);
    return (
        <List>
            {items.map((obj, index) => {
                const { image, sectionName, title, linkAction, isActive } = items[index];

                const pathName = linkAction.url.split('/').pop().split('?')[0];

                return (
                    <ListItem
                        key={index}
                        onMouseEnter={() => {
                            onItemHover(obj);
                        }}
                    >
                        <Link
                            action={linkAction}
                            isActive={isActive}
                            onClick={() => {
                                UAEventTracking({
                                    eventCategory: 'quickSearch',
                                    eventAction: 'articleClick',
                                    eventLabel: pathName
                                });

                                GA4Tracking({
                                    eventName: 'article_click',
                                    context: 'search',
                                    category: 'ecommerce',
                                    eventParams: [
                                        { param_name: 'search_type', param_value: 'quick_search' },
                                        { param_name: 'search_term', param_value: searchTerm }
                                    ]
                                });
                            }}
                        >
                            {image ? (
                                <ArticlePicture {...image} layout={layout} />
                            ) : (
                                <PicturePlaceholder as="div" layout={layout} />
                            )}
                            <TextContainer layout={layout}>
                                {sectionName && layout === layoutEnum.tab && <SectionName>{sectionName}</SectionName>}
                                <Title layout={layout}>{title}</Title>
                                {layout !== layoutEnum.tab && (
                                    <button type="button" className="btn btn--link">
                                        {Translations.Website.Shared.Buttons.ReadMore}
                                    </button>
                                )}
                            </TextContainer>
                        </Link>
                    </ListItem>
                );
            })}
        </List>
    );
};
