export default class OfferCounter {

    init() {
        var stack = [],
            intervalId;

        function init($scope) {

            $($scope || document.body).find("span.offer-counter:not([data-binded])").attr("data-binded", new Date()).each(function () {
                stack.push($(this));
            });

            // Update interval
            if (!intervalId) {
                intervalId = setInterval(setState, 1000);
            }
        }

        function stop() {
            clearInterval(intervalId);
        }

        function getDateFromCSV(csv) {
            var parts = csv.split(",");
            for (var i = 0; i < parts.length; i++) {
                parts[i] = parseInt(parts[i]);
            }
            return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
        }

        function setState() {

            var now = new Date(),
                nowTime = now.getTime();

            for (var i = 0; i < stack.length; i++) {
                var $offerCounter = stack[i];

                // Get or set cache
                var data = $offerCounter.data("offer-counter");
                if (!data) {
                    data = {};
                    data.endDate = getDateFromCSV($offerCounter.data("end"));
                    data.endTime = data.endDate.getTime(),
                    data.$offerTexts = $offerCounter.find("[data-from]");
                    $offerCounter.data("offer-counter", data);
                }

                var $offerTextToDisplay,
                    timeDiffSmallest = 999999999999;

                // Expired offer
                if (nowTime > data.endTime) {
                    $offerTextToDisplay = $offerCounter.children(".expired");
                }
                else {
                    data.$offerTexts.each(function () {
                        var $offerText = $(this);

                        // Get or set cache
                        var textData = $offerText.data("offer-counter-text");
                        if (!textData) {
                            textData = {};
                            textData.fromDate = getDateFromCSV($offerText.data("from"));
                            textData.fromTime = textData.fromDate.getTime();

                            $offerText.data("offer-counter-text", textData);
                        }

                        // Get the diff from now to the offer text start time
                        var timeDiff = nowTime - textData.fromTime;

                        if (timeDiff > 0 && timeDiff < timeDiffSmallest) {
                            timeDiffSmallest = timeDiff;
                            $offerTextToDisplay = $offerText;
                        }
                    });
                }

                // None of the offer texts are valid. Lets hide all
                if (!$offerTextToDisplay) {
                    data.$offerTexts.removeClass("show");
                }
                else {

                    if (!$offerTextToDisplay.hasClass("show")) {
                        $offerTextToDisplay.addClass("show").siblings().removeClass("show");
                    }

                    // Update the string template
                    var left = getTimeLeft(data.endTime, nowTime);

                    $offerTextToDisplay.find(".days").text(left.days);
                    if (left.hours === 0) {
                        $offerTextToDisplay.find(".hours").hide();
                    }
                    else {
                        $offerTextToDisplay.find(".hours").show().text(left.hours);
                    }
                    if (left.minutes === 0 && left.hours === 0) {
                        $offerTextToDisplay.find(".minutes").hide();
                    }
                    else {
                        $offerTextToDisplay.find(".minutes").show().text(left.minutes);
                    }

                    $offerTextToDisplay.find(".seconds").show().text(left.seconds);
                }
            }
        }

        function getTimeLeft(date_future, date_now) {

            // get total seconds between the times
            var delta = Math.abs(date_future - date_now) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = Math.floor(delta % 60);  // in theory the modulus is not required

            return {
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            };
        }

        init();
        //stop();
    };
}