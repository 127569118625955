import { useCallback, useEffect, useRef } from 'react';
import showIOSNavigation from 'modules/helpers/showIOSNavigation';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { zIndex } from 'variables';
import { createRoot } from 'react-dom/client';
import mediator from 'modules/mediator';
import { mediatorLegacyModalOpenedAtom } from 'hooks/globals/useOnLegacyModalOpenEffect';
import useMediatorState from 'hooks/globals/useMediatorAtom';
import DefaultLayout from './layouts';
import type { IDefaultLayout } from './layouts/defaultLayout';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { requestDataState } from 'recoilState/atoms/requestDataState';
import javascriptRequestDataContext from 'modules/requestDataState';

// Mutable modal queue array
const queue = {};

const Wrapper = styled.div<{ zIndexProp: number }>`
    position: fixed;
    z-index: ${({ zIndexProp }): number => zIndexProp};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IShadowProps {
    open?: boolean;
}

export interface ICreateProps {
    container?: HTMLElement;
    resetFocus?: boolean;
    lockScroll?: boolean;
    userCanClose?: boolean;
    onAfterOpen?({ wrapper, close }: { wrapper: HTMLElement; close: () => void }): void;
    onBeforeClose?({ userCanClose }: { userCanClose: boolean }): void;
    onAfterClose?(): void;
    onAfterUserClose?(): void;
    layout?: IDefaultLayout;
    zIndex?: number;
}

export interface IUseProps {
    content?: JSX.Element | HTMLElement;
    onAfterContent?({ wrapper, close }: { wrapper: HTMLElement; close: () => void }): void;
    onAfterClose?(): void;
    open?: boolean;
    layout?: IDefaultLayout;
    userCanClose?: boolean;
}

export type IModal = (useProps: IUseProps) => void;

interface IModalProps extends ICreateProps, IUseProps {
    modalId: string;
    containerId: string;
    root?: any;
}

export const DefaultShadow = styled.div<IShadowProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: opacity 0.3 ease;

    /* Prevent double animation if double clicking on the shadow when closing the modal */
    pointer-events: ${({ open }): string => (open ? 'auto' : 'none')};
`;

const previouslyFocusedElement = ((): { set(HTMLElement): void; get(): HTMLElement } => {
    let previousTarget = document.querySelector('body');

    return {
        set: (target): void => {
            previousTarget = target;
        },
        get: (): HTMLElement => previousTarget
    };
})();

function setPreviouslyFocusedElement({ target }): void {
    previouslyFocusedElement.set(target);
    document.removeEventListener('focusout', setPreviouslyFocusedElement);
}

function setFocus(elm): void {
    elm?.focus();
}

function openModal(props): void {
    if (props.open) {
        showIOSNavigation(true);
    }

    // if root._internalRoot method does not exist on the root obj this means no root has been initialized or it has been unmounted
    if (!props.root) {
        props.root = createRoot(props.container);
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    props.root.render(<Modal {...props} />);
}

function closeModal(props): void {
    // When closing the modal we need to render it again with other props to get it to animate out. When animation finishes we unmount.

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    props.root.render(<Modal {...props} open={false} />);
}

function queueModal(props): void {
    if (!queue[props.containerId]) {
        queue[props.containerId] = [];
    }

    const propsFoundInQueue = queue[props.containerId].find(
        (modalProps): boolean => modalProps.modalId === props.modalId
    );

    if (!propsFoundInQueue) {
        queue[props.containerId].push(props);
    } else {
        // Update queued props
        queue[props.containerId] = queue[props.containerId].map(
            (modalProps): IModalProps =>
                modalProps.modalId === props.modalId ? { ...modalProps, ...props } : modalProps
        );
    }
}

function unQueueModal(modalId, containerId): void {
    queue[containerId] = queue[containerId].filter((modalProps): boolean => modalProps.modalId !== modalId);

    if (queue[containerId].length) {
        openModal(queue[containerId][0]);
    }
}

export function Modal(props: IModalProps): JSX.Element {
    const {
        modalId,
        container,
        containerId,
        open,
        content = null,
        lockScroll = true,
        userCanClose = true,
        resetFocus = true,
        onAfterOpen,
        onBeforeClose,
        onAfterClose,
        onAfterUserClose,
        onAfterContent,
        zIndex: zIndexProp = zIndex.modal,
        layout: { component: LayoutComponent = <DefaultLayout />, ...layoutProps } = {}
    } = props;
    const wrapperRef = useRef(null);
    const [, setLegacyModalOpened] = useMediatorState(mediatorLegacyModalOpenedAtom);
    const contentBool = !!content;

    const beforeClose = useCallback((): void => {
        if (onBeforeClose) {
            onBeforeClose({ userCanClose });
        }
    }, [onBeforeClose, userCanClose]);

    function afterClose(): void {
        if (onAfterClose) {
            onAfterClose();
        }
    }

    function initializeState({ set }) {
        set(requestDataState, javascriptRequestDataContext);
    }

    const programmaticClose = useCallback((): void => {
        closeModal(props);
    }, [props]);

    const userClose = useCallback((): void => {
        if (userCanClose) {
            closeModal(props);
            if (onAfterUserClose) {
                onAfterUserClose();
            }
        } else {
            console.warn('User cannot close the modal');
        }
    }, [onAfterUserClose, props, userCanClose]);

    function unmount(): any {
        return new Promise<void>((resolve) => {
            unQueueModal(modalId, containerId);
            resolve();
        }).then(() => {
            setTimeout(() => {
                props.root.unmount();
                container.remove();
                afterClose();
            }, 300); // 300ms equal to the animation duration
        });
    }

    const closeOnKeyup = useCallback(
        ({ keyCode }): void => {
            // keyCode 27 = Escape
            if (keyCode === 27) {
                userClose();
            }
        },
        [userClose]
    );

    /*
        closeOnKeyup effect
    */
    useEffect(() => {
        if (open) {
            document.addEventListener('keyup', closeOnKeyup);
        }

        return (): void => {
            if (open) {
                document.removeEventListener('keyup', closeOnKeyup);
            }
        };
    }, [open, closeOnKeyup]);

    /*
        onAfterOpen effect
    */
    useEffect((): void => {
        if (open) {
            if (onAfterOpen) {
                onAfterOpen({ wrapper: wrapperRef.current, close: programmaticClose });
            }
        }
    }, [onAfterOpen, open, programmaticClose]);

    /*
        focus effect
    */
    useEffect(() => {
        if (open) {
            document.addEventListener('focusout', setPreviouslyFocusedElement);
            setFocus(wrapperRef.current);
        }

        return (): void => {
            if (resetFocus) {
                setFocus(previouslyFocusedElement.get());
            }
        };
    }, [open, resetFocus]);

    /*
        onAfterContent effect
    */
    useEffect((): void => {
        if (contentBool && onAfterContent) {
            onAfterContent({ wrapper: wrapperRef.current, close: programmaticClose });
        }
    }, [programmaticClose, contentBool, onAfterContent]);

    useEffect((): void => {
        if (!open) {
            beforeClose();
        }
    }, [beforeClose, open]);

    useEffect(
        () => (): void => {
            if (open) {
                showIOSNavigation(false);
            }
        },
        [open]
    );

    useEffect(() => {
        mediator.subscribe('closeModal', () => {
            programmaticClose();
        });

        return () => {
            mediator.unsubscribe('closeModal');
        };
    });

    useEffect(() => {
        if (open) {
            setLegacyModalOpened({ id: modalId });
        }
    }, [modalId, open, setLegacyModalOpened]);

    const Component = LayoutComponent.type;

    return (
        <BrowserRouter basename="/">
            {/* RecoilRoot is a temporary fix to non-react components */}
            <RecoilRoot initializeState={initializeState}>
                <Wrapper ref={wrapperRef} tabIndex={0} zIndexProp={zIndexProp}>
                    <DefaultShadow onClick={userClose} open={open} tabIndex={-1} />
                    <Component
                        lockScroll={lockScroll}
                        close={(): void => {
                            userClose();
                        }}
                        userCanClose={userCanClose}
                        open={open}
                        unmount={unmount}
                        content={content}
                        {...LayoutComponent.props}
                        {...layoutProps}
                    />
                </Wrapper>
            </RecoilRoot>
        </BrowserRouter>
    );
}

export default (createProps: ICreateProps = {}): IModal => {
    const modalId = uuidv4();
    const { container: tempContainer = document.querySelector('#jsReactModal') } = createProps;
    const containerId = tempContainer.id;
    const tempDiv = document.createElement('div');

    return (useProps = { open: false }): void => {
        if (!tempContainer) {
            throw new Error('Cannot find Modal target container');
        }

        const container = tempContainer.appendChild(tempDiv);

        // Merge options
        const props = { ...createProps, ...useProps, modalId, container, containerId };

        // queue modal
        queueModal(props);

        // get first modal in queue
        const firstInQueue = queue[containerId][0] || props;

        /* If the current props are not the props of the first modal in queue, do nothing.
         * (this avoids re-rendering of the first modal in queue, when adding more modals to the queue) */
        if (props.modalId !== firstInQueue.modalId) {
            return;
        }

        if (firstInQueue.open) {
            openModal(firstInQueue);
        } else {
            closeModal(firstInQueue);
        }
    };
};
