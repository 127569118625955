import { BorderRadiusSmall, Spacing4 } from 'autogen/design-tokens/tokens';
import LinkAction from 'components/shared/Routing/components/LinkAction';
import imageOverlayStyle from 'components/shared/style/imageOverlay';
import styled, { css } from 'styled-components';

const sharedStyle = css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${Spacing4};

    &:after {
        content: '';
        ${imageOverlayStyle}
        border-radius: ${BorderRadiusSmall}
    }
`;

export const ProductImageContainer = styled.div`
    ${sharedStyle}
`;

export const ProductImageContainerLink = styled(LinkAction)`
    ${sharedStyle}
`;
