import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';
import { useSelector } from 'react-redux';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import {
    ColorNeutralBlack,
    BorderRadiusMedium,
    FontFontFamilyPrimary,
    Spacing16,
    Spacing8,
    ColorNeutralDarkGrey
} from 'autogen/design-tokens/tokens';
import { useTranslation } from 'react-i18next';
import { Clear, HiddenCloseSearchButton, QuickSearchPlaceholder } from 'autogen/translation-keys/trans-website-search';
import { rem } from 'modules/helpers/style';
import { Button, ButtonWithIcon } from 'components/Button';
import dynamic from 'helpers/dynamic';
import { Search } from 'autogen/translation-keys/trans-website-imagealttext-icons';
import { GA4Tracking } from 'modules/tracking/GA4';
import useFeatureToggles from 'hooks/user/useFeatureToggles';
import CameraButton from './CameraButton';
import MainContext from './components/context';

const SearchInput = styled.div<{ isSearchActive: boolean }>`
    display: flex;
    /* && = Higher specificity than server side rendered element */
    && {
        ${({ isSearchActive }) =>
            isSearchActive &&
            css`
                padding: ${Spacing16} ${Spacing8} ${Spacing16} ${Spacing16};
                gap: ${Spacing8};

                ${breakpoint.up(sizes.xl)} {
                    flex: 0 0 auto;
                }
            `}
    }
`;

const DummySearchInputField = styled.button`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0 16px;
    outline: none;
    background: none;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: none;
    font-family: ${FontFontFamilyPrimary};
    font-size: ${rem(14)};
    color: ${ColorNeutralDarkGrey};
`;

const SearchInputField = styled.input`
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0 16px;
    outline: none;
    background: none;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: none;
    font-family: ${FontFontFamilyPrimary};
    font-size: ${rem(14)};
    color: ${ColorNeutralBlack};

    &::placeholder {
        font-family: ${FontFontFamilyPrimary};
        font-size: ${rem(14)};
        color: ${ColorNeutralDarkGrey};
    }
`;

const InputContainer = styled.form<{ isSearchActive: boolean }>`
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: ${BorderRadiusMedium};
    border: 1px solid #999999;
    width: 100%;
`;

const ClearSearchQueryButton = styled(Button)`
    font-weight: normal;
`;

const StyledCameraButton = styled(CameraButton)``;

const Separator = styled.div`
    display: none;
    width: 1px;
    height: 16px;
    background-color: #999999;

    ${ClearSearchQueryButton} ~ &, ${StyledCameraButton} ~ & {
        display: block;
    }
`;

const StyledButtonWithIcon = styled(ButtonWithIcon)`
    && {
        ${breakpoint.up(sizes.lg)} {
            opacity: 1;

            &:focus {
                opacity: 1;
            }
        }
    }
`;

const SearchIcon = dynamic(() => import('icons/Search.svg?react'));

function isVisualSearchActive() {
    // checks multiple places if visual search is disabled
    let localStorageVariable;

    if (typeof window !== 'undefined') {
        const visualSearchValue = localStorage.getItem('visualSearchDisabled_v2');
        localStorageVariable = visualSearchValue && JSON.parse(visualSearchValue);
    }

    return !localStorageVariable;
}

export default function SearchInputComponent(props) {
    const {
        searchToggle,
        searchActive,
        handleInputValue,
        inputValue,
        searchText,
        onEnterCallback,
        arrowNavigationCallback,
        resetActiveItem,
        shouldDisplayCameraButton = true,
        inputRef
    } = props;
    const { visualSearchEnabled } = useFeatureToggles();

    const mainContext = useContext(MainContext);
    const { isHandheld } = mainContext as { isHandheld: boolean };
    const visualSearchActive =
        useSelector((state: any) => state.quickSearch.visualSearchActive) &&
        visualSearchEnabled &&
        isVisualSearchActive();
    const [showCameraButton, setShowCameraButton] = useState(false);
    const { t } = useTranslation();

    const onChangeHandler = (event) => {
        const {
            target: { value }
        } = event;
        handleInputValue(value);
    };

    useEffect(() => {
        const browserSupportsCamera = 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices;
        setShowCameraButton(visualSearchActive && isHandheld && browserSupportsCamera);
    }, [isHandheld, visualSearchActive]);

    return (
        <SearchInput isSearchActive={searchActive}>
            <InputContainer
                className="gb_unmask"
                action=""
                onSubmit={(event) => event.preventDefault()}
                autoComplete="off"
                isSearchActive={searchActive}
            >
                {searchActive ? (
                    <SearchInputField
                        autoFocus
                        id="js-quicksearch-field"
                        className="gb_unmask"
                        type="search"
                        ref={inputRef}
                        value={inputValue}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={onChangeHandler}
                        onClick={resetActiveItem}
                        onMouseEnter={() => {
                            resetActiveItem();
                        }}
                        placeholder={t(QuickSearchPlaceholder)}
                        onKeyUp={(event) => {
                            if (event.keyCode === 13) {
                                onEnterCallback();
                                UAEventTracking({
                                    eventCategory: 'quickSearch',
                                    eventAction: 'enterKey',
                                    eventLabel: inputValue
                                });

                                GA4Tracking({
                                    eventName: 'enter_key',
                                    context: 'search',
                                    category: 'ecommerce',
                                    eventParams: [
                                        { param_name: 'search_type', param_value: 'quick_search' },
                                        { param_name: 'search_term', param_value: inputValue }
                                    ]
                                });
                            }
                        }}
                        onKeyDown={arrowNavigationCallback}
                        tabIndex={isHandheld ? -1 : undefined}
                    />
                ) : (
                    <DummySearchInputField
                        type="button"
                        aria-label="Search åbner"
                        tabIndex={0}
                        onFocus={() => searchToggle()}
                        onClick={searchToggle}
                    >
                        {(searchText === inputValue && inputValue) || t(QuickSearchPlaceholder)}
                    </DummySearchInputField>
                )}

                {searchActive && inputValue ? (
                    <ClearSearchQueryButton type="button" variant="primaryText" onClick={() => handleInputValue('')}>
                        {t(Clear)}
                    </ClearSearchQueryButton>
                ) : (
                    showCameraButton && shouldDisplayCameraButton && <StyledCameraButton />
                )}

                <Separator />

                <ButtonWithIcon
                    type="button"
                    icon={<SearchIcon width={18} height={18} aria-label={t(Search)} />}
                    variant="ghostOnLight"
                    onClick={searchActive ? onEnterCallback : searchToggle}
                />
            </InputContainer>
            {searchActive && (
                <StyledButtonWithIcon
                    variant="ghostOnLight"
                    icon="Close"
                    onClick={() => searchToggle(false)}
                    type="button"
                    aria-label={t(HiddenCloseSearchButton)}
                />
            )}
        </SearchInput>
    );
}
