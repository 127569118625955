import WithIcon from 'components/hoc/withIcon/WithIcon';
import React from 'react';
import Button from './Button';
import type IButtonWithIcon from './shared/types/buttonWithIcon';

const ButtonWithIcon = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IButtonWithIcon>(
    ({ icon, iconProperties, children, ...restProps }, ref) => {
        const hasChildren = children && React.Children.count(children);

        const buttonContent = icon ? (
            <WithIcon icon={icon} spacing={!hasChildren ? '0px' : iconProperties?.spacing} {...iconProperties}>
                {children}
            </WithIcon>
        ) : (
            children
        );

        return (
            <Button ref={ref} {...restProps}>
                {buttonContent}
            </Button>
        );
    }
);

export default ButtonWithIcon;
