import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

const SlideElement = styled.div`
    position: relative;
    flex: 1 0 100%;
`;

const Slide = (props: HTMLAttributes<HTMLDivElement>): JSX.Element => <SlideElement {...props} />;

export default Slide;
