import { ColorNeutralWhite, Spacing12, Spacing8 } from 'autogen/design-tokens/tokens';
import InlineButton from 'components/generic/InlineButton';
import { ButtonWithIcon } from 'components/Button';
import { Typography } from 'components/generic/typography';
import { rem } from 'modules/helpers/style';
import styled from 'styled-components';

/* #region  styles */
const TooltipHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
`;

const Headline = styled(Typography.Body)`
    color: ${ColorNeutralWhite};
    margin: 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
`;

const StyledButtonWithIcon = styled(ButtonWithIcon)`
    align-self: self-end;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    padding: 0;
`;

const StyledBody = styled(Typography.Body)`
    color: ${ColorNeutralWhite};
    line-height: ${rem(18)};
    margin-bottom: ${Spacing12};
`;

export interface ISmallTooltipContent {
    title?: string;
    description: string;
    dismissButtonTitle?: string;
}
/* #endregion */

export default function SmallTooltipPopupContent({
    content,
    close,
    showCloseButton
}: {
    content: ISmallTooltipContent;
    close: () => void;
    showCloseButton?: boolean;
}) {
    const { title, description, dismissButtonTitle } = content;

    return (
        <Content>
            {(title || showCloseButton) && (
                <TooltipHeader>
                    {title && <Headline themeTypes="smallSemiBold">{title}</Headline>}

                    {showCloseButton && (
                        <StyledButtonWithIcon
                            onClick={close}
                            icon="Close"
                            variant="primaryTextNegative"
                            size="sm"
                            iconProperties={{ size: '16px' }}
                        />
                    )}
                </TooltipHeader>
            )}

            <StyledBody themeTypes="small" dangerouslySetInnerHTML={{ __html: description }} />

            {dismissButtonTitle && (
                <InlineButton onClick={close} variant="light" fontWeight="semibold" size="xs">
                    {dismissButtonTitle}
                </InlineButton>
            )}
        </Content>
    );
}
