import React, { useContext } from 'react';
import styled from 'styled-components';
import * as transKey from 'autogen/translation-keys/trans-root-website';
import { useTranslation } from 'react-i18next';
import { ColorNordicGrey4, Spacing8, Spacing16, Spacing24 } from 'autogen/design-tokens/tokens';
import Card from 'components/generic/card';
import { ButtonWithIcon } from 'components/Button';
import { Body as BodyTypography } from 'components/__deprecated/typography';
import { ProductNameWithoutLinks } from 'components/generic/productName';
import ProductImage from 'components/generic/productImage';
import { ReadMoreCallbackContext, SelectedProductIdsContext } from '../hooks/contexts';
import { IPickerCard } from '../shared/types';
import getItemState from '../shared/getItemState';
import ChooseButton from './ChooseButton';

const StyledCard = styled(Card)`
    margin-top: ${Spacing24};
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Body = styled.div`
    display: flex;
    padding: 0 ${Spacing24} ${Spacing24};
    align-items: flex-start;
`;

const Footer = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    padding: ${Spacing16};
    border-top: 1px solid ${ColorNordicGrey4};
`;

const ItemDescription = styled(BodyTypography)`
    margin-bottom: 0;
`;

const CloseButton = styled(ButtonWithIcon)`
    margin-bottom: ${Spacing24};
    text-decoration: none;
`;

const TextContainer = styled.div`
    flex: 0 1 100%;
    padding-left: ${Spacing8};
`;

const StyledProductName = styled(ProductNameWithoutLinks)`
    margin-bottom: ${Spacing16};
`;

const StyledChooseButton = styled(ChooseButton)`
    flex: 0 1 220px;
    margin-left: auto;
    margin-right: auto;
`;

export default function Description(props: IPickerCard): JSX.Element {
    const { id, description, locked, image, productName, isRecommended } = props;
    const selectedProductIds = useContext(SelectedProductIdsContext);
    const isSelected = selectedProductIds.includes(id);
    const { t } = useTranslation();
    const readMoreCallback = useContext(ReadMoreCallbackContext);
    return (
        <StyledCard>
            <Header>
                <CloseButton
                    iconProperties={{ position: 'right' }}
                    variant="ghostOnLight"
                    icon="Close"
                    onClick={() => readMoreCallback()}
                >
                    {t(transKey.Shared.Close)}
                </CloseButton>
            </Header>
            <Body>
                <ProductImage image={image} maxWidth="112px" width={112} transformType="SquareScale" />
                <TextContainer>
                    <StyledProductName productName={productName} size="md" />
                    <ItemDescription size="sm" dangerouslySetInnerHTML={{ __html: description }} />
                </TextContainer>
            </Body>
            <Footer>
                <StyledChooseButton
                    productId={id}
                    isRecommended={isRecommended}
                    state={getItemState(locked, isSelected)}
                    size="lg"
                />
            </Footer>
        </StyledCard>
    );
}
