import { Spacing32, Spacing64, ColorNordicGrey4 } from 'autogen/design-tokens/tokens';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';
import Section, { type ISection } from '../Section';

export interface ISectionWithFullWidthBackground extends ISection {
    backgroundColor: string;
}

const Background = styled.div<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: ${Spacing32} 0;

    ${breakpoint.up(sizes.lg)} {
        padding: ${Spacing32} 0;
    }
`;

export function FullWidthBackgroundSection({
    backgroundColor = ColorNordicGrey4,
    children,
    ...restProps
}: ISectionWithFullWidthBackground) {
    return (
        <Background backgroundColor={backgroundColor}>
            <Section {...restProps}>{children}</Section>
        </Background>
    );
}
