import React, { HTMLAttributes, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { colors } from 'variables';

const rotateAnimation = keyframes`
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Svg = styled.svg<{ animationDuration: number }>`
    transition-property: transform;
    animation-name: ${rotateAnimation};
    animation-duration: ${({ animationDuration }): number => animationDuration}ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`;

interface ISpinner extends HTMLAttributes<HTMLOrSVGElement> {
    size?: number;
    strokeWidth?: number;
    animationDuration?: number;
    color?: string;
    timeout?: boolean | number;
}

const defaults: ISpinner = {
    size: 150,
    strokeWidth: 2,
    animationDuration: 1000,
    color: 'currentColor'
};

export default function Spinner(props: ISpinner): JSX.Element {
    const {
        size = defaults.size,
        strokeWidth = defaults.strokeWidth,
        animationDuration = defaults.animationDuration,
        color = defaults.color,
        timeout,
        className
    } = props;
    const cutOff = uuidv4();
    const gradient = uuidv4();
    const [showSpinner, setShowSpinner] = useState(!timeout);

    useEffect(() => {
        let timer;
        const timeoutDuration = typeof timeout === 'number' ? timeout : 100;

        if (timeout) {
            timer = setTimeout(() => setShowSpinner(true), timeoutDuration);
        }

        return () => {
            if (timeout) {
                clearTimeout(timer);
            }
        };
    }, [timeout]);

    if (!showSpinner) {
        return <div css={{ width: size, height: size }} className={className} />;
    }

    return (
        <Wrapper className={className}>
            <Svg width={size} height={size} animationDuration={animationDuration}>
                <defs>
                    <clipPath id={cutOff}>
                        <rect x="0" y={size / 2} width={size} height={size} />
                    </clipPath>
                    <linearGradient id={gradient}>
                        <stop offset="0" stopColor={color} />
                        <stop offset="100%" stopColor={color} stopOpacity="0" />
                    </linearGradient>
                </defs>

                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - strokeWidth}
                    stroke={`url(#${gradient})`}
                    strokeWidth={strokeWidth}
                    fill="none"
                    clipPath={`url(#${cutOff})`}
                />
            </Svg>
        </Wrapper>
    );
}
