export default newGlobal => {
    if (typeof window === 'undefined') {
        return;
    }

    const matas = window.matas || {};

    Object.keys(newGlobal).forEach(key => {
        if (matas[key]) {
            throw new Error('Global already exists');
        }
    });

    const newMatas = { ...matas, ...newGlobal };
    window.matas = newMatas;
};
