import { MenuItemSubMenuViewModel } from 'autogen/swagger/menu';
import useSmartviewManager from 'components/generic/smartview/shared/hooks/useSmartviewManager';
import dynamic from 'helpers/dynamic';
import useIsPathnameActive from 'components/sideMenu/shared/hooks/useIsPathnameActive';
import MenuWithHeader from 'components/sideMenu/components/Menu/components/MenuWithHeader';
import { useMenuQuery } from 'autogen/swagger/menu/hooks';
import requestContext from 'modules/requestContext';
import { useSideMenu } from '../../../../shared/context/SideMenuProvider';
import DefaultMenuItem from './DefaultMenuItem';
import { DefaultMenuItemProps } from '../types';

const context = requestContext;

const ArrowRight = dynamic(() => import('icons/ArrowRight.svg?react'), { ssr: false });

type SubMenuItemProps = {
    model: MenuItemSubMenuViewModel;
    defaultProps: DefaultMenuItemProps;
};

export default function SubMenuItem({
    /*
        subMenuInitialData: the initial data that came from the parent menu to display the items that have subMenus,
        The initial data doesn't include sections, We fetch that here in this component if we have reached the shouldPrefetch depth.
    */
    model: { subMenu: subMenuInitialData, text, productGroupPath },
    /* default props */
    defaultProps: { iconUrl, styling },
    ...restProps
}: SubMenuItemProps) {
    const smartSideDrawerId = `submenu-${subMenuInitialData.id}`;
    const { open: openSubMenu, close: closeSubMenu } = useSmartviewManager(smartSideDrawerId);
    const { currentPrefetchedDepth, rootMenuPrefetchDepth, subMenuPrefetchDepth } = useSideMenu();
    const isSelected = useIsPathnameActive(productGroupPath, subMenuInitialData?.level - rootMenuPrefetchDepth);

    const { data: subMenu, refetch: prefetchMenu } = useMenuQuery(
        {
            menuId: subMenuInitialData.id,
            depth: subMenuPrefetchDepth,
            display: context.posRequest?.isPosRequest ? 'Pos' : 'Webshop'
        },
        { enabled: false }
    );

    const prefetch = () => {
        // start prefetching when the current lvl is equal to or higher than the current prefetched depth
        const shouldPrefetch = subMenuInitialData.level >= currentPrefetchedDepth.current;
        if (shouldPrefetch) {
            prefetchMenu().then(() => {
                // increment the current depth by subMenuPrefetchDepth's value
                currentPrefetchedDepth.current += subMenuPrefetchDepth;
            });
        }
    };

    const resetCurrentPrefetchDepth = () => {
        // reset prefetchDepth only to a minimum of the RootMenu prefetch depth
        if (currentPrefetchedDepth.current > rootMenuPrefetchDepth) {
            currentPrefetchedDepth.current = subMenuInitialData.level;
        }
    };

    return (
        <>
            <DefaultMenuItem.Button
                buttonProps={{
                    onClick: () => openSubMenu().then(prefetch)
                }}
                afterSlot={<ArrowRight width={16} height={16} />}
                iconUrl={iconUrl}
                styling={styling}
                selected={isSelected}
                {...restProps}
            >
                {text}
            </DefaultMenuItem.Button>

            {/* THIS ITEM MOUNTS THROUGH A REACT PORTAL - is inserted into the sideDrawer */}
            <MenuWithHeader
                smartSideDrawerId={smartSideDrawerId}
                menu={subMenu || subMenuInitialData}
                backButtonCallback={() => closeSubMenu().then(resetCurrentPrefetchDepth)}
                animate={false}
            />
        </>
    );
}
