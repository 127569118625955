import type { ResponsiveImageLinkModel } from 'autogen/swagger/gwpdiscount';
import { forwardRef, type HTMLAttributes } from 'react';
import PictureDeprecated from './PictureDeprecated';
import LinkAction from 'components/shared/Routing/components/LinkAction/LinkAction';

interface IPictureWithLink extends HTMLAttributes<HTMLAnchorElement> {
    props: ResponsiveImageLinkModel;
}

const PictureWithLink = forwardRef(({ props, ...restProps }: IPictureWithLink, ref: any): JSX.Element => {
    const { image, link } = props;

    if (link) {
        const { target, linkAction } = link;

        return (
            <LinkAction action={linkAction} target={target} {...restProps}>
                <PictureDeprecated {...image} ref={ref} />
            </LinkAction>
        );
    }

    return <PictureDeprecated {...image} ref={ref} />;
});

export default PictureWithLink;
