export const SET_HEADER_IS_STICKY_BOOL = 'SET_HEADER_IS_STICKY_BOOL';
export const TOGGLE_HEADER_LOCKED = 'TOGGLE_HEADER_LOCKED';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';

export function setHeaderIsStickyBool(bool) {
    return {
        type: SET_HEADER_IS_STICKY_BOOL,
        bool
    };
}

export function toggleHeaderLocked(bool) {
    return {
        type: TOGGLE_HEADER_LOCKED,
        bool
    };
}

export function setHeaderHeight(height) {
    return {
        type: SET_HEADER_HEIGHT,
        height
    };
}
