import { DependencyList, MutableRefObject, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

interface IElementSize {
    width: DOMRectReadOnly['width'];
    height: DOMRectReadOnly['height'];
    scrollWidth: Element['scrollWidth'];
}

export default function useElementSizeEffect<T extends HTMLElement = HTMLDivElement>(
    effectCallback: (size: IElementSize) => void,
    deps?: DependencyList
): [MutableRefObject<T | null>] {
    const ref = useRef<T | null>(null);
    const resizeObserver = useRef<ResizeObserver>({} as ResizeObserver);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    function observe(ResizeObserver) {
        resizeObserver.current = new ResizeObserver((entries) => {
            if (entries) {
                const containerWidth = entries[0].contentRect.width;
                const containerHeight = entries[0].contentRect.height;
                const containerScrollWidth = entries[0].target.scrollWidth;

                effectCallback({ width: containerWidth, height: containerHeight, scrollWidth: containerScrollWidth });
            }
        });
        if (ref.current) {
            resizeObserver.current.observe(ref.current);
        }
    }

    function disconnect() {
        if (resizeObserver.current) {
            resizeObserver.current.disconnect();
        }
    }

    useIsomorphicLayoutEffect(() => {
        observe(ResizeObserver);

        return () => {
            disconnect();
        };
    }, [JSON.stringify(deps)]);

    return [ref];
}
