/* eslint-disable react/no-array-index-key */
import type { GetScalingAttrProps } from 'helpers/imageScalingHelper';
import type { ImgHTMLAttributes } from 'react';
import type { ResponsiveImageModel } from 'autogen/swagger/page';
import type { ResponsiveImageSource } from 'autogen/swagger/accordion';
import calculateAspectRatio, { aspectRatioCalculateMissingSide } from 'helpers/aspectRatio';
import type { PictureProps, PictureSourceProps } from '../shared/types';

function getBiggestSize(sizes: GetScalingAttrProps['sizes']): number {
    if (typeof sizes === 'number') {
        // If sizes is a single number, return it directly
        // example: sizes = 65
        return sizes;
    }
    if (sizes.length === 1 && typeof sizes[0] === 'number') {
        // If sizes is an array of a single number, return it directly
        // example: sizes = [65]
        return sizes[0];
    }

    const result = sizes.reduce<number>((max, current): number => {
        const currentValue = typeof current === 'number' ? current : current[1];

        const maxValue = typeof max === 'number' ? max : max[1];
        return Math.max(maxValue, currentValue);
    }, 0);

    return result;
}

function filterSizeByBreakpoint(
    sizes: GetScalingAttrProps['sizes'],
    breakpoint: ResponsiveImageSource['breakPoint']
): GetScalingAttrProps['sizes'] {
    if (!Array.isArray(sizes)) {
        return sizes;
    }

    const { minWidth, maxWidth } = breakpoint;
    const sizeArray: GetScalingAttrProps['sizes'] = [];

    sizes.forEach((size) => {
        const sizeMinBreakpoint = size[0];

        if (
            (!Array.isArray(size) && !minWidth) ||
            ((!!minWidth === false || sizeMinBreakpoint >= minWidth) &&
                (!!maxWidth === false || sizeMinBreakpoint <= maxWidth)) // double bang and check if false seems redundant, it's a typescript fix
        ) {
            sizeArray.push(size);
        }
    });

    return sizeArray;
}

function mapToPictureSourceProps(
    sources: ResponsiveImageSource[],
    sizes: GetScalingAttrProps['sizes']
): PictureSourceProps[] {
    return sources.flatMap((source) => {
        const { breakPoint, images } = source;
        const { minWidth = 0, maxWidth } = breakPoint;
        const { url, height, width } = images.reduce((prevSize, currSize) =>
            currSize.width && currSize.width > (prevSize.width || 0) ? currSize : prevSize
        );
        const filteredSizes = filterSizeByBreakpoint(sizes, breakPoint);
        const aspectRatio = !!width && !!height ? calculateAspectRatio(width, height) : null;
        const heightFromAspectRatio =
            aspectRatio && width ? Math.round(aspectRatioCalculateMissingSide(width, aspectRatio)) : undefined;

        return {
            breakpoint: { minWidth, maxWidth },
            sizes: filteredSizes,
            src: url,
            width: width,
            height: heightFromAspectRatio
        };
    });
}

export default function mapResponsiveImageModelToPictureProps(
    props: ResponsiveImageModel,
    sizes: GetScalingAttrProps['sizes'],
    transformType: GetScalingAttrProps['transformType'] = 'WidthScale',
    imageType?: GetScalingAttrProps['imageType']
): PictureProps & ImgHTMLAttributes<HTMLImageElement> {
    const { altText, lazyLoad, sources, fallBackUrl, imageClass } = props;

    return {
        alt: altText,
        fallbackSrc: fallBackUrl,
        transformType,
        imageType,
        loading: lazyLoad ? 'lazy' : 'eager',
        className: imageClass || '',
        sources: mapToPictureSourceProps(sources, sizes)
    };
}
