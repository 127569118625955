import { IQuickView, QuickViewDefaultFooterComponents } from '../shared/types';
import ActionFooter from '../components/ActionFooter';

export default function useDefaultFooterComponents(quickViewProps: IQuickView): QuickViewDefaultFooterComponents {
    const { onClose } = quickViewProps;

    return {
        ActionFooter: (props) => <ActionFooter onClick={onClose} {...props} />
    };
}
