import { type ReactNode, useCallback, type HTMLAttributes, memo, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { type ISlide, quickViewSlidesState } from '../atoms/quickViewState';
import { animated, useTransition } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import { GoBack } from 'autogen/translation-keys/trans-website-shared';
import { Slide } from '../shared/style';
import DefaltBackButton from '../components/DefaultQuickViewComponents/BackButton';

type SlideOptions = {
    BackButtonRenderer?: (props: HTMLAttributes<HTMLButtonElement>) => ReactNode;
};

const AnimatedBackButtonContainer = (animated as any).div;
const AnimatedSlide = animated<any>(Slide);

export default function useSlides(options?: SlideOptions) {
    const { BackButtonRenderer = (props) => <DefaltBackButton {...props} rounded /> } = options || {};

    const [slides, setSlides] = useRecoilState(quickViewSlidesState);
    const { t } = useTranslation();

    function push(slide: ISlide | ISlide['SlideComponent']) {
        if (typeof slide === 'function') {
            setSlides([...slides, { id: uuidv4(), SlideComponent: memo(slide) }]);
        } else {
            if (slides.find((x) => x.id === slide.id)) {
                return;
            }

            slide.SlideComponent = memo(slide.SlideComponent);
            setSlides([...slides, slide]);
        }
    }

    const pop = useCallback(() => {
        setSlides(slides.filter((x, i) => i !== slides.length - 1));
    }, [setSlides, slides]);

    function reset() {
        setSlides([]);
    }

    const slideTransitions = useTransition(slides, {
        from: { transform: 'translateX(100%)' },
        enter: { transform: 'translateX(0%)' },
        leave: { transform: 'translateX(100%)' }
    });

    const backButtonTransitions = useTransition(!!slides.length, {
        from: { opacity: 0, transform: 'translateX(-100%)' },
        enter: { opacity: 1, transform: 'translateX(0%)' },
        leave: { opacity: 0, transform: 'translateX(-100%)' }
    });

    const slideProps = {
        backButtonRenderer: () =>
            backButtonTransitions(
                (style, toggle) =>
                    toggle && (
                        <AnimatedBackButtonContainer style={style}>
                            <BackButtonRenderer onClick={pop} aria-label={t(GoBack)} />
                        </AnimatedBackButtonContainer>
                    )
            ),
        slidesRenderer: () =>
            slideTransitions((style, { SlideComponent }) => (
                <AnimatedSlide style={style}>
                    <SlideComponent />
                </AnimatedSlide>
            ))
    };

    // Reset slides when QuickView closes
    useEffect(() => reset, []);

    return { slides, push, pop, reset, slideProps };
}
