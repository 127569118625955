import { FontFontFamilyPrimary, FontLineHeightTight, FontWeightSemiBold } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

const TitleStyling = css`
    font-family: ${FontFontFamilyPrimary};
    line-height: ${FontLineHeightTight};
    font-weight: ${FontWeightSemiBold};
`;

export default TitleStyling;
