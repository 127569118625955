import type { WithSpinnerProps } from 'components/Button/shared/types/buttonWithSpinner';
import type { WithAsyncStatusProps } from 'components/Button/shared/types/buttonWithStatus';
import Spinner from 'modules/shared/circleSpinner';
import React, { type HTMLAttributes } from 'react';
import styled from 'styled-components';

const Pending = styled.div`
    position: relative;
`;

const ChildrenWrapper = styled.span`
    visibility: hidden;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const withSpinner = <T extends HTMLElement, P extends WithAsyncStatusProps>(Component: React.ComponentType<P>) =>
    React.forwardRef<T, P & WithSpinnerProps & HTMLAttributes<T>>((props, ref) => {
        const { spinner = <StyledSpinner size={16} />, children, ...restProps } = props;

        return (
            <Component
                ref={ref}
                onIdle={children}
                onPending={
                    <Pending>
                        {spinner}
                        <ChildrenWrapper>{children}</ChildrenWrapper>
                    </Pending>
                }
                {...(restProps as P)}
            />
        );
    });

export default withSpinner;
