
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './store';

        export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type JsonLocation = components['schemas']['JsonLocation'];
                    export type ParcelShopSearchOrigin = components['schemas']['ParcelShopSearchOrigin'];
                    export type AddressCleansingSuggestion = components['schemas']['AddressCleansingSuggestion'];
                    export type AddressCleansingSuggestionMaybe = components['schemas']['AddressCleansingSuggestionMaybe'];
                    export type ParcelShopSearchData = components['schemas']['ParcelShopSearchData'];
                    export type ProductStockStatusType = components['schemas']['ProductStockStatusType'];
                    export type OpeningHourTexts = components['schemas']['OpeningHourTexts'];
                    export type ParcelShopRenderType = components['schemas']['ParcelShopRenderType'];
                    export type StoreParcelShopResult = components['schemas']['StoreParcelShopResult'];
                    export type FindStoreResult = components['schemas']['FindStoreResult'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductInfoModel = components['schemas']['ProductInfoModel'];
                    export type ProductStoreStockRequest = components['schemas']['ProductStoreStockRequest'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type ProductStoreStockViewModel = components['schemas']['ProductStoreStockViewModel'];
                    

        
        

        export type SearchStoreByAddressParams = paths['/internal/Store/SearchStoreByAddress']['get']['parameters']['query'];

        export type SearchStoreByAddressSuccess = paths['/internal/Store/SearchStoreByAddress']['get']['responses']['200']['content']['text/plain'];

        export const SearchStoreByAddressUrl = '/internal/Store/SearchStoreByAddress';

        
        export function SearchStoreByAddress(params: SearchStoreByAddressParams): Promise<SearchStoreByAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SearchStoreByAddressSuccess>(`/internal/Store/SearchStoreByAddress${formatParams(params)}`)
        }
    
    
        

        export type SearchStoreByCoordinatesParams = paths['/internal/Store/SearchStoreByCoordinates']['get']['parameters']['query'];

        export type SearchStoreByCoordinatesSuccess = paths['/internal/Store/SearchStoreByCoordinates']['get']['responses']['200']['content']['text/plain'];

        export const SearchStoreByCoordinatesUrl = '/internal/Store/SearchStoreByCoordinates';

        
        export function SearchStoreByCoordinates(params: SearchStoreByCoordinatesParams): Promise<SearchStoreByCoordinatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SearchStoreByCoordinatesSuccess>(`/internal/Store/SearchStoreByCoordinates${formatParams(params)}`)
        }
    
    
        

        export type ValidateParams = paths['/internal/Store/Validate']['post']['parameters']['query'];

        export type ValidateSuccess = paths['/internal/Store/Validate']['post']['responses']['200']['content']['text/plain'];

        export const ValidateUrl = '/internal/Store/Validate';

        
        export function Validate(params: ValidateParams): Promise<ValidateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ValidateSuccess>(`/internal/Store/Validate${formatParams(params)}`)
        }
    
    
        

        export type _GetSelectedProductParams = paths['/internal/Store/_GetSelectedProduct']['get']['parameters']['query'];

        export type _GetSelectedProductSuccess = paths['/internal/Store/_GetSelectedProduct']['get']['responses']['200']['content']['text/plain'];

        export const _GetSelectedProductUrl = '/internal/Store/_GetSelectedProduct';

        
        export function _GetSelectedProduct(params: _GetSelectedProductParams): Promise<_GetSelectedProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_GetSelectedProductSuccess>(`/internal/Store/_GetSelectedProduct${formatParams(params)}`)
        }
    
    
        

        export type SetUserPreferredStoreParams = paths['/internal/Store/SetUserPreferredStore']['post']['parameters']['query'];

        export type SetUserPreferredStoreSuccess = paths['/internal/Store/SetUserPreferredStore']['post']['responses']['200']['content']['text/plain'];

        export const SetUserPreferredStoreUrl = '/internal/Store/SetUserPreferredStore';

        
        export function SetUserPreferredStore(params: SetUserPreferredStoreParams): Promise<SetUserPreferredStoreSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SetUserPreferredStoreSuccess>(`/internal/Store/SetUserPreferredStore${formatParams(params)}`)
        }
    
    
        

        export type GetProductStoreStockModelParams = paths['/internal/Store/GetProductStoreStockModel']['get']['parameters']['query'];

        export type GetProductStoreStockModelSuccess = paths['/internal/Store/GetProductStoreStockModel']['get']['responses']['200']['content']['text/plain'];

        export const GetProductStoreStockModelUrl = '/internal/Store/GetProductStoreStockModel';

        
        export function GetProductStoreStockModel(params: GetProductStoreStockModelParams): Promise<GetProductStoreStockModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductStoreStockModelSuccess>(`/internal/Store/GetProductStoreStockModel${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    