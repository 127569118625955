import { CookieCategory } from 'modules/helpers/cookieInformationHelper';
import { useState, useCallback, useEffect } from 'react';
import useAcceptedCookies from './useAcceptedCookies';

export default function useHasInformationCookieBeenAccepted(cookieType: CookieCategory) {
    const acceptedCookies: 'Functional' | 'Marketing' | 'Necessary' | 'Statistic'[] = useAcceptedCookies();
    const cookieMap = {
        // this map should not be necessary if backend and frontend values are aligned.
        [CookieCategory.functional]: 'Functional',
        [CookieCategory.marketing]: 'Marketing',
        [CookieCategory.necessary]: 'Necessary',
        [CookieCategory.statistic]: 'Statistic'
    };

    const [isAccepted, setIsAccepted] = useState(acceptedCookies.includes(cookieMap[cookieType]));

    const handleCookieChangedEvent = useCallback(() => {
        if (window.CookieInformation) {
            const consentValue = window.CookieInformation.getConsentGivenFor(cookieType);
            setIsAccepted(consentValue);
        }
    }, [cookieType]);

    useEffect(() => {
        handleCookieChangedEvent();
        window.addEventListener('CookieInformationConsentGiven', handleCookieChangedEvent);

        return () => {
            window.removeEventListener('CookieInformationConsentGiven', handleCookieChangedEvent);
        };
    }, [handleCookieChangedEvent]);

    return isAccepted;
}
