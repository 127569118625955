/* eslint-disable no-unused-expressions */
/* eslint-disable class-methods-use-this */
import idleValue from 'modules/helpers/idle';
import { deleteParamByKey, updateUrlWithParams } from 'modules/helpers/urlParams';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { uniqBy } from 'lodash-es';
import { loadModule } from './helpers/moduleLoaders';

export default class ProductPage {
    constructor(productPage) {
        this.window = $(window);
        this.productPage = productPage;
        this.description = productPage.find('[data-js="productPage:description"]');
        this.descriptionReadMore = productPage.find('[data-js="productPage:description:readMore"]');
        this.descriptionMore = productPage.find('[data-js="productPage:description:readMore:more"]');
        this.descriptionLess = productPage.find('[data-js="productPage:description:readMore:less"]');
        this.variantsTruncate = productPage.find('[data-js="productPage:variants:truncate"]');
        this.variantSelector = productPage.find('.js-selection-variant').find('.js-selection-input');
        this.subscriptionRadioButton = productPage.find('.js-subscriptionRadioButton');
        this.toggleBtn = productPage.find('[data-js="toggle-variants-button"]');
        this.viewportWidth = window.innerWidth;

        this.resizeTimer;
        this.resizeDebounceTime = 250; // ms
    }

    init() {
        this.descriptionToggle();
        this.bindings();
        this.variants();

        loadModule('#subscription-container', () =>
            import(/* webpackChunkName: "productPageSubscription" */ 'modules/productPage/subscription')
        );
    }

    bindings() {
        this.variantSelector.on('change', event => {
            window.location.href = event.target.value;
        });

        this.window.on('resize', event => {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => {
                this.variants();
            }, this.resizeDebounceTime);
        });

        this.toggleBtn.on('click', () => {
            this.variantsTruncate.toggleClass('state--showmore');
        });

        this.subscriptionRadioButton.on('change', () => {
            const toggleResult = this.togglePointLines();

            if (toggleResult) {
                updateUrlWithParams('subscription', 'True');
            } else {
                deleteParamByKey('subscription');
            }

            UAEventTracking({
                eventCategory: 'ProductPage',
                eventAction: 'ToggleSubscription',
                eventLabel: toggleResult ? 'toggleOn' : 'toggleOff'
            });
        });
    }

    variants() {
        // Truncate for product-page-b variants
        if (!this.variantsTruncate.length) {
            return;
        }

        this.variantsTruncate.each((i, target) => {
            const container = $(target);
            const list = container.find('ul');
            const hiddenListItem = container.find('li:hidden').first();
            const toggleBtn = container.find('[data-js="toggle-variants-button"]');

            // if overflowing items
            if (hiddenListItem.length || container.hasClass('state--showmore')) {
                // +1 because of round-downs
                // found hidden items
                toggleBtn.show();
            } else {
                toggleBtn.hide();
            }
        });
    }

    descriptionToggle() {
        if (this.description[0] && this.description[0].scrollHeight <= this.description[0].clientHeight + 1) {
            this.description.removeClass('product-page__description--collapsed');
            return;
        }

        this.descriptionReadMore.toggleClass('hidden-xs-up').on('click', () => {
            this.description.toggleClass('product-page__description--collapsed');
            this.descriptionMore.toggleClass('hidden-xs-up');
            this.descriptionLess.toggleClass('hidden-xs-up');
        });
    }

    toggleLargeImage(event) {
        const { target } = event;

        // hide video overlay
        const imageContainer = this.imageLarge.get(0).parentNode;
        const videoOverlay = imageContainer.querySelector('.js-videoOverlay');

        if (videoOverlay) {
            requestAnimationFrame(() => {
                videoOverlay.style.display = 'none';
            });
        }

        // set src
        const src = target.getAttribute('src');
        const srcset = target.getAttribute('srcset');
        const index = target.getAttribute('data-slide-index');
        this.imageLarge[0].srcset = srcset;
        this.imageLarge[0].src = src;
        this.imageLarge[0].setAttribute('data-slide-index', index);
    }

    togglePointLines() {
        const subscriptionRadioButton = document.getElementById('subscriptionRadioButton');
        const withSubscription = document.querySelectorAll('.js-withSubscription');
        const withoutSubscription = document.querySelectorAll('.js-withoutSubscription');

        if (subscriptionRadioButton.checked) {
            withSubscription.forEach(x => {
                x.style.display = '';
            });
            withoutSubscription.forEach(x => {
                x.style.display = 'none';
            });
        } else {
            withSubscription.forEach(x => {
                x.style.display = 'none';
            });
            withoutSubscription.forEach(x => {
                x.style.display = '';
            });
        }
    }
}
