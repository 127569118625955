import { TOGGLE_BACK_VISIBLE, SET_CONTENT_TYPE } from '../actions/expressDeliveryActions';

const initialState = {
    backVisible: false
};

const actions = {};

actions[TOGGLE_BACK_VISIBLE] = (state, action) => {
    return {
        ...state,
        backVisible: action.bool
    };
};

actions[SET_CONTENT_TYPE] = (state, action) => {
    return {
        ...state,
        contentType: action.contentType
    };
};

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
