import type { SurveyCustomAction } from 'autogen/swagger/page';
import { openSurveyModal } from 'components/connectedContent/shared/callbacks';
import { forwardRef } from 'react';
import type { CustomActionProps } from '../CustomAction';
import { CustomActionButton } from '../shared/styles';

type SurveyActionProps = { action: SurveyCustomAction } & CustomActionProps;

const SurveyAction = forwardRef<HTMLButtonElement, SurveyActionProps>(({ action, onClick, ...restProps }, ref) => (
    <CustomActionButton
        ref={ref}
        type="button"
        onClick={(e) => {
            if (onClick) {
                onClick(e);
            }
            openSurveyModal(action.surveyId);
        }}
        {...restProps}
    />
));

export default SurveyAction;
