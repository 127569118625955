import Tippy, { type TippyProps } from '@tippyjs/react';
import React from 'react';

export type LazyTippyProps = TippyProps;

export const LazyTippy = (props: LazyTippyProps) => {
    const { plugins, render } = props;

    const [mounted, setMounted] = React.useState(false);

    const lazyPlugin = {
        fn: () => ({
            onMount: () => setMounted(true),
            onHidden: () => setMounted(false)
        })
    };

    const computedProps = { ...props };

    computedProps.plugins = [lazyPlugin, ...(plugins || [])];

    if (render) {
        computedProps.render = (...args) => (mounted ? render(...args) : '');
    } else {
        computedProps.content = mounted ? props.content : '';
    }

    return <Tippy {...computedProps} />;
};
