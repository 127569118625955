function goToLink(paraName: string, value: string) {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(paraName, value);

    window.location.href = currentUrl.href;
}

function initializeCustomInput(wrapper: HTMLDivElement): void {
    const customInput = wrapper.getElementsByClassName('js-inputSelectionSwitch')[0] as HTMLSelectElement;
    const paraName = customInput?.name;

    customInput.addEventListener('blur', () => goToLink(paraName, customInput.value));
    customInput.addEventListener('keyup', (e) => e.key === 'Enter' && goToLink(paraName, customInput.value));
}

function onPageSelectionChange(wrapper, e): void {
    const { value } = e.target;
    if (value === 'custom') {
        initializeCustomInput(wrapper);
        return;
    }

    const paraName = e.target.attributes.name.value;
    goToLink(paraName, value);
}

const searchResultPaging = (wrapper: HTMLDivElement): void => {
    const selector = wrapper.getElementsByClassName('js-filterListPageSelection')[0];
    selector.addEventListener('change', (e) => {
        onPageSelectionChange(wrapper, e);
    });
};

export default searchResultPaging;
