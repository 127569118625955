/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as SkinTest from './trans-website-questionnaire-skintest';

export const UploadHeading = 'Website.Questionnaire.UploadHeading';
export const UploadImageButton = 'Website.Questionnaire.UploadImageButton';
export const UploadText = 'Website.Questionnaire.UploadText';
export const UploadDisclamer = 'Website.Questionnaire.UploadDisclamer';
export const UploadSend = 'Website.Questionnaire.UploadSend';
export const UploadStartOver = 'Website.Questionnaire.UploadStartOver';
export const CompletedSecondaryButtonText = 'Website.Questionnaire.CompletedSecondaryButtonText';
export const CompletedPrimaryButtonText = 'Website.Questionnaire.CompletedPrimaryButtonText';
export const UploadStartOverConfirm = 'Website.Questionnaire.UploadStartOverConfirm';
export const OutroHeading = 'Website.Questionnaire.OutroHeading';
export const OutroText = 'Website.Questionnaire.OutroText';
export const AnyQuestions = 'Website.Questionnaire.AnyQuestions';
export const StartButton = 'Website.Questionnaire.StartButton';
export const BrandListNoPreferences = 'Website.Questionnaire.BrandListNoPreferences';
export const SpecialistsImageAltText = 'Website.Questionnaire.SpecialistsImageAltText';
export const SubmitErrorMessage = 'Website.Questionnaire.SubmitErrorMessage';
