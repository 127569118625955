import { combineReducers } from 'redux';
import filterReducer from './filterReducer';
import quickSearchReducer from './quickSearchReducer';
import headerReducer from './headerReducer';
import interestsReducer from './interestsReducer';
import expressReducer from './expressDeliveryReducer';
import personalFiltersReducer from './personalFiltersReducer';
import heroCTAReducer from './heroCTAReducer';
import notificationFormReducer from './notificationFormReducer';

export default combineReducers({
    filter: filterReducer,
    quickSearch: quickSearchReducer,
    header: headerReducer,
    interests: interestsReducer,
    express: expressReducer,
    personalFilters: personalFiltersReducer,
    heroCTA: heroCTAReducer,
    notificationForm: notificationFormReducer
});
