import { isNull } from 'lodash-es';
import { useState, useEffect, Dispatch } from 'react';

export function getItem(storage, key, defaultValue) {
    const value = storage.getItem(key);

    if (typeof value === 'undefined' || isNull(value)) {
        return defaultValue;
    }

    try {
        return JSON.parse(value);
    } catch (error) {
        return null;
    }
}

export function setItem(storage, key, value) {
    storage.setItem(key, JSON.stringify(value));
}

function createMemoryStorage() {
    const storage = {};
    return {
        getItem(key) {
            return storage[key];
        },
        setItem(key, value) {
            storage[key] = value;
        }
    };
}

export function getStorage(name) {
    return typeof window === 'object' && window[name] ? window[name] : createMemoryStorage();
}

export function usePersistedState(key, defaultValue): [any, Dispatch<any>] {
    const storage = getStorage('localStorage');

    const [state, setState] = useState(() => getItem(storage, key, defaultValue));

    useEffect(() => {
        setItem(storage, key, state);
    }, [state, key]);

    return [state, setState];
}

export function useSessionState<T>(key, defaultValue?: T): [T, Dispatch<T>] {
    const storage = getStorage('sessionStorage');

    const [state, setState] = useState(() => getItem(storage, key, defaultValue));

    useEffect(() => {
        setItem(storage, key, state);
    }, [state, key]);

    return [state, setState];
}

export function useDynamicStorage<T>(
    storageType: 'localStorage' | 'sessionStorage',
    key,
    defaultValue?: T
): [T, Dispatch<T>] {
    const storage = getStorage(storageType);

    const [state, setState] = useState(() => getItem(storage, key, defaultValue));

    useEffect(() => {
        setItem(storage, key, state);
    }, [state, key, storage]);

    return [state, setState];
}
