
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection ES6UnusedImports
        // noinspection JSUnusedGlobalSymbols
        import { useQuery, type UseQueryOptions, useMutation, type UseMutationOptions } from '@tanstack/react-query';
        import {
PrizeCertificates,
type PrizeCertificatesSuccess,
AddPrizeCertificate,
type AddPrizeCertificateSuccess,
type AddPrizeCertificateParams,
DeliveryRemovePrizeCertificate,
type DeliveryRemovePrizeCertificateSuccess,
type DeliveryRemovePrizeCertificateParams,
CheckoutDeliveryBasket,
type CheckoutDeliveryBasketSuccess,
GetCityFromZipCode,
type GetCityFromZipCodeSuccess,
type GetCityFromZipCodeParams,
GetDeliveryGroups,
type GetDeliveryGroupsSuccess,
GetDeliveryGroup,
type GetDeliveryGroupSuccess,
type GetDeliveryGroupParams,
UpdateInvoiceAddress,
type UpdateInvoiceAddressSuccess,
type UpdateInvoiceAddressRequestBody,
SelectPrivateDelivery,
type SelectPrivateDeliverySuccess,
type SelectPrivateDeliveryParams,
UpdatePrivateDeliveryAddress,
type UpdatePrivateDeliveryAddressSuccess,
type UpdatePrivateDeliveryAddressRequestBody,
SelectAndUpdateCompanyDeliveryAddress,
type SelectAndUpdateCompanyDeliveryAddressSuccess,
type SelectAndUpdateCompanyDeliveryAddressRequestBody,
SelectParcelShopDelivery,
type SelectParcelShopDeliverySuccess,
type SelectParcelShopDeliveryParams,
SelectAndUpdateParcelShopDelivery,
type SelectAndUpdateParcelShopDeliverySuccess,
type SelectAndUpdateParcelShopDeliveryRequestBody,
ListParcelShops,
type ListParcelShopsSuccess,
type ListParcelShopsParams,
ListParcelShopsByAddress,
type ListParcelShopsByAddressSuccess,
type ListParcelShopsByAddressParams,
ListParcelShopsByCoordinates,
type ListParcelShopsByCoordinatesSuccess,
type ListParcelShopsByCoordinatesParams,
SelectMatasDelivery,
type SelectMatasDeliverySuccess,
type SelectMatasDeliveryParams,
SelectAndUpdateMatasDelivery,
type SelectAndUpdateMatasDeliverySuccess,
type SelectAndUpdateMatasDeliveryRequestBody,
ListMatasParcelShops,
type ListMatasParcelShopsSuccess,
type ListMatasParcelShopsParams,
ListMatasParcelShopsByAddress,
type ListMatasParcelShopsByAddressSuccess,
type ListMatasParcelShopsByAddressParams,
ListMatasParcelShopsByCoordinates,
type ListMatasParcelShopsByCoordinatesSuccess,
type ListMatasParcelShopsByCoordinatesParams,
SelectUpsaleDelivery,
type SelectUpsaleDeliverySuccess,
type SelectUpsaleDeliveryParams,
ToggleNonurgentDelivery,
type ToggleNonurgentDeliverySuccess,
type ToggleNonurgentDeliveryParams,
CheckoutDeliveryGroupModel,
type CheckoutDeliveryGroupModelSuccess,
CheckoutDeliveryPostModel,
type CheckoutDeliveryPostModelSuccess,
CheckoutDeliveryViewModel,
type CheckoutDeliveryViewModelSuccess,
PaymentViewModel,
type PaymentViewModelSuccess,
PaymentReepayCallbackViewModel,
type PaymentReepayCallbackViewModelSuccess,
ReceiptPageViewModel,
type ReceiptPageViewModelSuccess,
ReepayAccept,
type ReepayAcceptParams,
OrderDetails,
type OrderDetailsSuccess,
type OrderDetailsParams,
CreateOrderDetailsPdf,
type CreateOrderDetailsPdfSuccess,
type CreateOrderDetailsPdfParams,
ModelForBasket,
type ModelForBasketSuccess,
ModelForCheckout,
type ModelForCheckoutSuccess,
ClubMatasTerms,
type ClubMatasTermsSuccess,
TogglePlusSignup,
type TogglePlusSignupSuccess,
type TogglePlusSignupParams,
ToggleClubSignup,
type ToggleClubSignupParams} from './index'

        
        
    export const PrizeCertificatesQueryKey = 'checkout_PrizeCertificates';

    export function usePrizeCertificatesQuery<TError = unknown>( options?: Omit<UseQueryOptions<PrizeCertificatesSuccess, TError, PrizeCertificatesSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PrizeCertificatesSuccess | (() => PrizeCertificatesSuccess);},  baseQueryKey = PrizeCertificatesQueryKey) {
            return useQuery([baseQueryKey, ],
            PrizeCertificates, options);
        }
  
    
        
      export function useAddPrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddPrizeCertificateSuccess, TError, {params: AddPrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddPrizeCertificate(params, ), options)}
  
    
        
      export function useDeliveryRemovePrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeliveryRemovePrizeCertificateSuccess, TError, {params: DeliveryRemovePrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeliveryRemovePrizeCertificate(params, ), options)}
  
    
        
    export const CheckoutDeliveryBasketQueryKey = 'checkout_CheckoutDeliveryBasket';

    export function useCheckoutDeliveryBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryBasketSuccess, TError, CheckoutDeliveryBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryBasketSuccess | (() => CheckoutDeliveryBasketSuccess);},  baseQueryKey = CheckoutDeliveryBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryBasket, options);
        }
  
    
        
    export const GetCityFromZipCodeQueryKey = 'checkout_GetCityFromZipCode';

    export function useGetCityFromZipCodeQuery<TError = unknown>(params: GetCityFromZipCodeParams, options?: Omit<UseQueryOptions<GetCityFromZipCodeSuccess, TError, GetCityFromZipCodeSuccess, [string, GetCityFromZipCodeParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetCityFromZipCodeSuccess | (() => GetCityFromZipCodeSuccess);},  baseQueryKey = GetCityFromZipCodeQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetCityFromZipCode(params), options);
        }
  
    
        
    export const GetDeliveryGroupsQueryKey = 'checkout_GetDeliveryGroups';

    export function useGetDeliveryGroupsQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetDeliveryGroupsSuccess, TError, GetDeliveryGroupsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetDeliveryGroupsSuccess | (() => GetDeliveryGroupsSuccess);},  baseQueryKey = GetDeliveryGroupsQueryKey) {
            return useQuery([baseQueryKey, ],
            GetDeliveryGroups, options);
        }
  
    
        
    export const GetDeliveryGroupQueryKey = 'checkout_GetDeliveryGroup';

    export function useGetDeliveryGroupQuery<TError = unknown>(params: GetDeliveryGroupParams, options?: Omit<UseQueryOptions<GetDeliveryGroupSuccess, TError, GetDeliveryGroupSuccess, [string, GetDeliveryGroupParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetDeliveryGroupSuccess | (() => GetDeliveryGroupSuccess);},  baseQueryKey = GetDeliveryGroupQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetDeliveryGroup(params), options);
        }
  
    
        
      export function useUpdateInvoiceAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateInvoiceAddressSuccess, TError, {requestBody: UpdateInvoiceAddressRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    UpdateInvoiceAddress( requestBody), options)}
  
    
        
      export function useSelectPrivateDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectPrivateDeliverySuccess, TError, {params: SelectPrivateDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectPrivateDelivery(params, ), options)}
  
    
        
      export function useUpdatePrivateDeliveryAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdatePrivateDeliveryAddressSuccess, TError, {requestBody: UpdatePrivateDeliveryAddressRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    UpdatePrivateDeliveryAddress( requestBody), options)}
  
    
        
      export function useSelectAndUpdateCompanyDeliveryAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectAndUpdateCompanyDeliveryAddressSuccess, TError, {requestBody: SelectAndUpdateCompanyDeliveryAddressRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    SelectAndUpdateCompanyDeliveryAddress( requestBody), options)}
  
    
        
      export function useSelectParcelShopDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectParcelShopDeliverySuccess, TError, {params: SelectParcelShopDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectParcelShopDelivery(params, ), options)}
  
    
        
      export function useSelectAndUpdateParcelShopDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectAndUpdateParcelShopDeliverySuccess, TError, {requestBody: SelectAndUpdateParcelShopDeliveryRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    SelectAndUpdateParcelShopDelivery( requestBody), options)}
  
    
        
    export const ListParcelShopsQueryKey = 'checkout_ListParcelShops';

    export function useListParcelShopsQuery<TError = unknown>(params: ListParcelShopsParams, options?: Omit<UseQueryOptions<ListParcelShopsSuccess, TError, ListParcelShopsSuccess, [string, ListParcelShopsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListParcelShopsSuccess | (() => ListParcelShopsSuccess);},  baseQueryKey = ListParcelShopsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListParcelShops(params), options);
        }
  
    
        
    export const ListParcelShopsByAddressQueryKey = 'checkout_ListParcelShopsByAddress';

    export function useListParcelShopsByAddressQuery<TError = unknown>(params: ListParcelShopsByAddressParams, options?: Omit<UseQueryOptions<ListParcelShopsByAddressSuccess, TError, ListParcelShopsByAddressSuccess, [string, ListParcelShopsByAddressParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListParcelShopsByAddressSuccess | (() => ListParcelShopsByAddressSuccess);},  baseQueryKey = ListParcelShopsByAddressQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListParcelShopsByAddress(params), options);
        }
  
    
        
    export const ListParcelShopsByCoordinatesQueryKey = 'checkout_ListParcelShopsByCoordinates';

    export function useListParcelShopsByCoordinatesQuery<TError = unknown>(params: ListParcelShopsByCoordinatesParams, options?: Omit<UseQueryOptions<ListParcelShopsByCoordinatesSuccess, TError, ListParcelShopsByCoordinatesSuccess, [string, ListParcelShopsByCoordinatesParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListParcelShopsByCoordinatesSuccess | (() => ListParcelShopsByCoordinatesSuccess);},  baseQueryKey = ListParcelShopsByCoordinatesQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListParcelShopsByCoordinates(params), options);
        }
  
    
        
      export function useSelectMatasDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectMatasDeliverySuccess, TError, {params: SelectMatasDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectMatasDelivery(params, ), options)}
  
    
        
      export function useSelectAndUpdateMatasDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectAndUpdateMatasDeliverySuccess, TError, {requestBody: SelectAndUpdateMatasDeliveryRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    SelectAndUpdateMatasDelivery( requestBody), options)}
  
    
        
    export const ListMatasParcelShopsQueryKey = 'checkout_ListMatasParcelShops';

    export function useListMatasParcelShopsQuery<TError = unknown>(params: ListMatasParcelShopsParams, options?: Omit<UseQueryOptions<ListMatasParcelShopsSuccess, TError, ListMatasParcelShopsSuccess, [string, ListMatasParcelShopsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListMatasParcelShopsSuccess | (() => ListMatasParcelShopsSuccess);},  baseQueryKey = ListMatasParcelShopsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListMatasParcelShops(params), options);
        }
  
    
        
    export const ListMatasParcelShopsByAddressQueryKey = 'checkout_ListMatasParcelShopsByAddress';

    export function useListMatasParcelShopsByAddressQuery<TError = unknown>(params: ListMatasParcelShopsByAddressParams, options?: Omit<UseQueryOptions<ListMatasParcelShopsByAddressSuccess, TError, ListMatasParcelShopsByAddressSuccess, [string, ListMatasParcelShopsByAddressParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListMatasParcelShopsByAddressSuccess | (() => ListMatasParcelShopsByAddressSuccess);},  baseQueryKey = ListMatasParcelShopsByAddressQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListMatasParcelShopsByAddress(params), options);
        }
  
    
        
    export const ListMatasParcelShopsByCoordinatesQueryKey = 'checkout_ListMatasParcelShopsByCoordinates';

    export function useListMatasParcelShopsByCoordinatesQuery<TError = unknown>(params: ListMatasParcelShopsByCoordinatesParams, options?: Omit<UseQueryOptions<ListMatasParcelShopsByCoordinatesSuccess, TError, ListMatasParcelShopsByCoordinatesSuccess, [string, ListMatasParcelShopsByCoordinatesParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListMatasParcelShopsByCoordinatesSuccess | (() => ListMatasParcelShopsByCoordinatesSuccess);},  baseQueryKey = ListMatasParcelShopsByCoordinatesQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListMatasParcelShopsByCoordinates(params), options);
        }
  
    
        
      export function useSelectUpsaleDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectUpsaleDeliverySuccess, TError, {params: SelectUpsaleDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectUpsaleDelivery(params, ), options)}
  
    
        
    export const ToggleNonurgentDeliveryQueryKey = 'checkout_ToggleNonurgentDelivery';

    export function useToggleNonurgentDeliveryQuery<TError = unknown>(params: ToggleNonurgentDeliveryParams, options?: Omit<UseQueryOptions<ToggleNonurgentDeliverySuccess, TError, ToggleNonurgentDeliverySuccess, [string, ToggleNonurgentDeliveryParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ToggleNonurgentDeliverySuccess | (() => ToggleNonurgentDeliverySuccess);},  baseQueryKey = ToggleNonurgentDeliveryQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ToggleNonurgentDelivery(params), options);
        }
  
    
        
    export const CheckoutDeliveryGroupModelQueryKey = 'checkout_CheckoutDeliveryGroupModel';

    export function useCheckoutDeliveryGroupModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryGroupModelSuccess, TError, CheckoutDeliveryGroupModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryGroupModelSuccess | (() => CheckoutDeliveryGroupModelSuccess);},  baseQueryKey = CheckoutDeliveryGroupModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryGroupModel, options);
        }
  
    
        
    export const CheckoutDeliveryPostModelQueryKey = 'checkout_CheckoutDeliveryPostModel';

    export function useCheckoutDeliveryPostModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryPostModelSuccess, TError, CheckoutDeliveryPostModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryPostModelSuccess | (() => CheckoutDeliveryPostModelSuccess);},  baseQueryKey = CheckoutDeliveryPostModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryPostModel, options);
        }
  
    
        
    export const CheckoutDeliveryViewModelQueryKey = 'checkout_CheckoutDeliveryViewModel';

    export function useCheckoutDeliveryViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryViewModelSuccess, TError, CheckoutDeliveryViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryViewModelSuccess | (() => CheckoutDeliveryViewModelSuccess);},  baseQueryKey = CheckoutDeliveryViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryViewModel, options);
        }
  
    
        
    export const PaymentViewModelQueryKey = 'checkout_PaymentViewModel';

    export function usePaymentViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PaymentViewModelSuccess, TError, PaymentViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PaymentViewModelSuccess | (() => PaymentViewModelSuccess);},  baseQueryKey = PaymentViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PaymentViewModel, options);
        }
  
    
        
    export const PaymentReepayCallbackViewModelQueryKey = 'checkout_PaymentReepayCallbackViewModel';

    export function usePaymentReepayCallbackViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PaymentReepayCallbackViewModelSuccess, TError, PaymentReepayCallbackViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PaymentReepayCallbackViewModelSuccess | (() => PaymentReepayCallbackViewModelSuccess);},  baseQueryKey = PaymentReepayCallbackViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PaymentReepayCallbackViewModel, options);
        }
  
    
        
    export const ReceiptPageViewModelQueryKey = 'checkout_ReceiptPageViewModel';

    export function useReceiptPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ReceiptPageViewModelSuccess, TError, ReceiptPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ReceiptPageViewModelSuccess | (() => ReceiptPageViewModelSuccess);},  baseQueryKey = ReceiptPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ReceiptPageViewModel, options);
        }
  
    
        
      export function useReepayAcceptMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: ReepayAcceptParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ReepayAccept(params, ), options)}
  
    
        
    export const OrderDetailsQueryKey = 'checkout_OrderDetails';

    export function useOrderDetailsQuery<TError = unknown>(params: OrderDetailsParams, options?: Omit<UseQueryOptions<OrderDetailsSuccess, TError, OrderDetailsSuccess, [string, OrderDetailsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: OrderDetailsSuccess | (() => OrderDetailsSuccess);},  baseQueryKey = OrderDetailsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => OrderDetails(params), options);
        }
  
    
        
    export const CreateOrderDetailsPdfQueryKey = 'checkout_CreateOrderDetailsPdf';

    export function useCreateOrderDetailsPdfQuery<TError = unknown>(params: CreateOrderDetailsPdfParams, options?: Omit<UseQueryOptions<CreateOrderDetailsPdfSuccess, TError, CreateOrderDetailsPdfSuccess, [string, CreateOrderDetailsPdfParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CreateOrderDetailsPdfSuccess | (() => CreateOrderDetailsPdfSuccess);},  baseQueryKey = CreateOrderDetailsPdfQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => CreateOrderDetailsPdf(params), options);
        }
  
    
        
    export const ModelForBasketQueryKey = 'checkout_ModelForBasket';

    export function useModelForBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<ModelForBasketSuccess, TError, ModelForBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ModelForBasketSuccess | (() => ModelForBasketSuccess);},  baseQueryKey = ModelForBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            ModelForBasket, options);
        }
  
    
        
    export const ModelForCheckoutQueryKey = 'checkout_ModelForCheckout';

    export function useModelForCheckoutQuery<TError = unknown>( options?: Omit<UseQueryOptions<ModelForCheckoutSuccess, TError, ModelForCheckoutSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ModelForCheckoutSuccess | (() => ModelForCheckoutSuccess);},  baseQueryKey = ModelForCheckoutQueryKey) {
            return useQuery([baseQueryKey, ],
            ModelForCheckout, options);
        }
  
    
        
    export const ClubMatasTermsQueryKey = 'checkout_ClubMatasTerms';

    export function useClubMatasTermsQuery<TError = unknown>( options?: Omit<UseQueryOptions<ClubMatasTermsSuccess, TError, ClubMatasTermsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ClubMatasTermsSuccess | (() => ClubMatasTermsSuccess);},  baseQueryKey = ClubMatasTermsQueryKey) {
            return useQuery([baseQueryKey, ],
            ClubMatasTerms, options);
        }
  
    
        
      export function useTogglePlusSignupMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<TogglePlusSignupSuccess, TError, {params: TogglePlusSignupParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    TogglePlusSignup(params, ), options)}
  
    
        
      export function useToggleClubSignupMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: ToggleClubSignupParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ToggleClubSignup(params, ), options)}
  
    
    