import WithIcon from 'components/hoc/withIcon/WithIcon';
import React from 'react';
import InlineButton from './InlineButton';
import IInlineButtonWithIcon from './shared/types/inlineButtonWithIcon';

const InlineButtonWithIcon = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IInlineButtonWithIcon>(
    ({ icon, iconProperties, children, ...restProps }, ref) => {
        const hasChildren = children && React.Children.count(children);
        const modifiedIconProperties = { ...iconProperties, size: iconProperties?.size || '1em' };

        const buttonContent = (
            <WithIcon icon={icon} spacing={!hasChildren ? '0px' : iconProperties?.spacing} {...modifiedIconProperties}>
                {children}
            </WithIcon>
        );

        return (
            <InlineButton ref={ref} {...restProps}>
                {buttonContent}
            </InlineButton>
        );
    }
);

export default InlineButtonWithIcon;
