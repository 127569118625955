import { Spacing16 } from 'autogen/design-tokens/tokens';
import getTypeStyling from 'helpers/getTypeStyling';
import React, { type HtmlHTMLAttributes, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import type { BreakPointsType } from 'variables';

type FlexJustifyContent = 'start' | 'center' | 'end' | 'space-between' | 'space-evenly';
type FlexAlignItems = 'start' | 'center' | 'end';
type FlexDirection = 'row' | 'column';
type FlexGrow = React.CSSProperties['flexGrow'];
type FlexShrink = React.CSSProperties['flexShrink'];
type FlexBasis = React.CSSProperties['flexBasis'];

type BreakpointFlexJustifyContent = BreakPointsType<FlexJustifyContent>;
type BreakpointFlexAlignItems = BreakPointsType<FlexAlignItems>;
type BreakpointFlexDirection = BreakPointsType<FlexDirection>;
type BreakpointFlexGap = BreakPointsType<string>;

export type FlexProperties = {
    direction?: FlexDirection | BreakpointFlexDirection;
    justifyContent?: FlexJustifyContent | BreakpointFlexJustifyContent;
    alignItems?: FlexAlignItems | BreakpointFlexAlignItems;
    gap?: string | BreakpointFlexGap;
    grow?: FlexGrow | BreakPointsType<FlexGrow>;
    shrink?: FlexShrink | BreakPointsType<FlexShrink>;
    basis?: FlexBasis | BreakPointsType<FlexBasis>;
};

export type FlexElementProps = { children: ReactNode } & FlexProperties & HtmlHTMLAttributes<HTMLElement>;

const FlexComponent = styled.div<FlexProperties>`
    display: flex;

    ${({ direction }) =>
        getTypeStyling(
            (resolvedDirection: FlexDirection) =>
                css`
                    flex-direction: ${resolvedDirection};
                `
        )(direction as FlexDirection)}

    ${({ justifyContent }) =>
        getTypeStyling(
            (resolvedJustifyContent: FlexDirection) =>
                css`
                    justify-content: ${resolvedJustifyContent};
                `
        )(justifyContent as FlexDirection)}

    ${({ alignItems }) =>
        getTypeStyling(
            (resolvedAlignItems: FlexDirection) =>
                css`
                    align-items: ${resolvedAlignItems};
                `
        )(alignItems as FlexDirection)}

    ${({ gap }) =>
        getTypeStyling(
            (resolvedGap: string) =>
                css`
                    gap: ${resolvedGap};
                `
        )(gap as string)}

    ${({ grow }) =>
        getTypeStyling(
            (resolvedGrow: string) =>
                css`
                        flex-grow: ${resolvedGrow};
                    `
        )(grow as string)}

    ${({ shrink }) =>
        getTypeStyling(
            (resolvedShrink: string) =>
                css`
                        flex-shrink: ${resolvedShrink};
                    `
        )(shrink as string)}

    ${({ basis }) =>
        getTypeStyling(
            (resolvedBasis: string) =>
                css`
                            flex-basis: ${resolvedBasis};
                        `
        )(basis as string)}
`;

const Flex = React.forwardRef<HTMLDivElement, FlexElementProps>(({ gap = Spacing16, children, ...restProps }, ref) => (
    <FlexComponent ref={ref} {...restProps} gap={gap}>
        {children}
    </FlexComponent>
));

export default styled(Flex)``;
