import { animated, useTransition } from '@react-spring/web';
import React, { HtmlHTMLAttributes } from 'react';
import styled from 'styled-components';
import { zIndex } from 'variables';

const Overlay = styled<any>(animated.div)`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    max-height: 100%;
    overflow: auto;
    z-index: ${zIndex.backdrop};
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;
`;

interface IGenericOverlay {
    isActive: boolean;
}

export type OverlayProps = {
    onClick?: HtmlHTMLAttributes<HTMLDivElement>['onClick'];
};

export default function GenericOverlay({ isActive, ...restProps }: IGenericOverlay & OverlayProps) {
    const transition = useTransition(isActive, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });

    return transition(
        (styles, item) =>
            item && <Overlay style={{ ...styles }} onTouchMove={(e) => e.preventDefault()} {...restProps} />
    );
}
