import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Spacing8, Spacing16, Spacing24 } from 'autogen/design-tokens/tokens';
import { ProductNameWithoutLinks } from 'components/generic/productName';
import { ButtonWithIcon } from 'components/Button';
import * as transKey from 'autogen/translation-keys/trans-root-website';
import { useTranslation } from 'react-i18next';
import AccordionAnimation from 'components/shared/accordionAnimation';
import ProductImage from 'components/generic/productImage';
import { SelectedProductIdsContext } from '../hooks/contexts';
import ChooseButton from './ChooseButton';
import type { IPickerCard } from '../shared/types';
import getItemState from '../shared/getItemState';
import { ProductImageContainer } from 'components/generic/productImage/shared/styles';
import { breakpoint, sizes } from 'variables';

const StyledImageContainer = styled(ProductImageContainer)`
    width: 50%;
    margin-right: ${Spacing8};

    ${breakpoint.up(sizes.sm)} {
        width: 25%;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    margin-bottom: ${Spacing16};
`;

const TextContainer = styled.div`
    flex: 0 1 100%;
`;

const StyledProductName = styled(ProductNameWithoutLinks)`
    margin-bottom: ${Spacing8};
    padding-left: ${Spacing8};
`;

const Description = styled.div`
    padding-bottom: ${Spacing8};
`;

const StyledAccordionAnimation = styled(AccordionAnimation)`
    margin-left: ${Spacing8};
`;

export default function VerticalContentLayout(props: IPickerCard): JSX.Element {
    const [isExpanded, setIsExpanded] = useState(false);
    const { productName, image, description, id, locked, isRecommended } = props;
    const selectedProductIds = useContext(SelectedProductIdsContext);
    const isSelected = selectedProductIds.includes(id);
    const { t } = useTranslation();
    const { ShowMoreButton, ShowLessButton } = transKey.Basket.Samples.SamplesPicker;

    return (
        <div>
            <InfoContainer>
                <StyledImageContainer>
                    <ProductImage image={image} maxWidth="80px" width={80} transformType="SquareScale" />
                </StyledImageContainer>
                <TextContainer>
                    <StyledProductName productName={productName} size="lg" />
                    {!!description.length && (
                        <>
                            <StyledAccordionAnimation
                                collapsed={!isExpanded}
                                collapsedHeight={0}
                                springConfig={{ friction: 20 }}
                            >
                                {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
                                <Description dangerouslySetInnerHTML={{ __html: description }} />
                            </StyledAccordionAnimation>
                            <ButtonWithIcon
                                icon={isExpanded ? 'ArrowUp' : 'ArrowDown'}
                                iconProperties={{ position: 'right' }}
                                size="sm"
                                variant="negative"
                                onClick={() => {
                                    setIsExpanded(!isExpanded);
                                }}
                            >
                                {isExpanded ? t(ShowLessButton) : t(ShowMoreButton)}
                            </ButtonWithIcon>
                        </>
                    )}
                </TextContainer>
            </InfoContainer>
            <ChooseButton productId={id} isRecommended={isRecommended} state={getItemState(locked, isSelected)} />
        </div>
    );
}
