import { pad } from 'modules/helpers/strings';
import { POST } from 'modules/helpers/fetch';

function updateProgress(
    countdownElem: HTMLDivElement,
    textElem: HTMLDivElement,
    progressElem: HTMLDivElement,
    startDateTime: Date,
    countdownDuration: number
): number {
    const timeToEvent = startDateTime.getTime() - Date.now();
    const totalMinutes = timeToEvent / 1000 / 60;
    if (totalMinutes < 0) {
        return 0;
    }

    const seconds = pad(Math.floor((totalMinutes % 1) * 60));
    const minutes = pad(Math.floor(totalMinutes % 60));
    const hours = Math.floor(totalMinutes / 60);
    textElem.setAttribute('data-text', `${hours ? `${hours}:` : ''}${minutes}:${seconds}`);

    const percent = (totalMinutes / countdownDuration) * 100;
    const degrees = percent * 3.6;
    progressElem.style.transform = `rotate(${degrees}deg)`;

    if (percent >= 50) {
        countdownElem.classList.add('countdown--fiftyplus');
    } else {
        countdownElem.classList.remove('countdown--fiftyplus');
    }

    return percent;
}

const startTimer = (countdown: HTMLDivElement, startDateTime: Date): void => {
    const { countdownDuration } = countdown.dataset;
    const countDownText = countdown.querySelector('.js-countdownText') as HTMLDivElement;
    const progresElement = countdown.querySelector('.js-countdownProgress') as HTMLDivElement;
    (function update(): void {
        requestAnimationFrame((): void => {
            const percent = updateProgress(
                countdown,
                countDownText,
                progresElement,
                startDateTime,
                Number.parseInt(countdownDuration || '')
            );
            if (percent > 0) setTimeout(update, 500);
        });
    })();
};

enum MessageState {
    beforeLive = 0,
    Live = 1,
    afterLive = 2
}

function getCurrentState(startDateTime: Date, endDateTime: Date): MessageState {
    if (Date.now() < startDateTime.getTime()) return MessageState.beforeLive;
    if (Date.now() < endDateTime.getTime()) return MessageState.Live;
    return MessageState.afterLive;
}

function updateTrackingClass(link: HTMLElement, state: MessageState): void {
    let label = 'beforeLive';
    if (state === MessageState.Live) {
        label = 'live';
    } else if (state === MessageState.afterLive) {
        label = 'afterLive';
    }
    const currentTrackingClass = [...link.classList].find(
        (className: string): boolean => className.indexOf('tracking') > -1
    );
    if (currentTrackingClass) link.classList.remove(currentTrackingClass);
    link.classList.add(`tracking_liveBanner_goToEventLink_${label}`);
}

function checkMessage(banner: HTMLElement, startDateTime: Date, endDateTime: Date): void {
    const link = banner.querySelector('.js-liveEventBannerLink') as HTMLElement;
    const { baseHref } = link.dataset;
    const { bambuserShowId } = banner.dataset;

    let state: MessageState | undefined = undefined;
    (function check(): void {
        const currentState = getCurrentState(startDateTime, endDateTime);
        if (state !== currentState) {
            updateTrackingClass(link, currentState);
            if ([MessageState.beforeLive, MessageState.Live].indexOf(currentState) > -1 && bambuserShowId) {
                link.setAttribute('href', `${baseHref}?autoplayLiveShopping=${bambuserShowId}`);
            }
            if (currentState === MessageState.Live) {
                const startingSoon = banner.querySelector('.js-liveEventBannerStaringSoon');
                const liveNow = banner.querySelector('.js-liveEventBannerLiveNow');

                if (startingSoon) startingSoon.classList.add('d-none');
                if (liveNow) liveNow.classList.remove('d-none');
            }
            if (currentState === MessageState.afterLive) {
                const liveNow = banner.querySelector('.js-liveEventBannerLiveNow');
                const onDemand = banner.querySelector('.js-liveEventBannerWatchOnDemand');

                if (liveNow) liveNow.classList.add('d-none');
                if (onDemand) onDemand.classList.remove('d-none');

                link.setAttribute('href', baseHref || '');
            }
            state = currentState;
        }
        if (state === MessageState.afterLive) return;
        const timeToNext =
            (currentState === MessageState.beforeLive ? startDateTime.getTime() : endDateTime.getTime()) - Date.now();

        setTimeout(check, timeToNext);
    })();
}

function closeBanner(event: Event, button: HTMLButtonElement, banner: HTMLElement): void {
    event.preventDefault();

    const { hideUrl } = button.dataset;

    if (hideUrl) {
        POST(hideUrl);
    }

    banner.remove();

    document.querySelectorAll('.js-showAfterLiveBannerClosed').forEach((elem): void => {
        elem.classList.remove('d-none');
    });
}

export default (banner: HTMLElement): void => {
    const { startTime, endTime } = banner.dataset;
    const startDateTime = new Date(startTime || '');
    const endDateTime = new Date(endTime || '');

    const closeButton = banner.querySelector('.js-closeLiveEventBanner') as HTMLButtonElement;
    if (closeButton) {
        closeButton.addEventListener('click', (event): void => closeBanner(event, closeButton, banner));
    }

    const countdown = banner.querySelector('.js-countdown') as HTMLDivElement;
    if (countdown && startDateTime.getTime() > Date.now()) {
        startTimer(countdown, startDateTime);
    }

    checkMessage(banner, startDateTime, endDateTime);
};
