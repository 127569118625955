import useMediatorState from 'hooks/globals/useMediatorAtom';
import { BasketQuantityMediatorAtom } from 'modules/getQuantities';
import mediator from 'modules/mediator';

export const refreshBasketQuantityGlobally = () => mediator.publish('updateQuantities', 'basket');

export default function useBasketQuantity() {
    const [basketQuantity, setBasketQuantity] = useMediatorState(BasketQuantityMediatorAtom);

    return [basketQuantity, setBasketQuantity, refreshBasketQuantityGlobally];
}
