import FlexBox from './Flex';
import FlexColumn from './components/FlexColumn';
import FlexRow from './components/FlexRow';

const Flex = Object.assign(FlexBox, {
    Column: FlexColumn,
    Row: FlexRow
});

export default Flex;
