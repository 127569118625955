import { useRecoilCallback } from 'recoil';
import { SmartviewInstanceDetails, smartviewInstancesIdsAtom } from '../recoil/atoms';
import { smartviewManager } from '../recoil/selectors';

/**
 * Close all currently active smartviews
 * @returns a function to clear all smartviews
 */
export default function useClearAllSmartviews() {
    const closeAll = useRecoilCallback(
        ({ reset, snapshot }) =>
            () => {
                const smartviewInstances: SmartviewInstanceDetails[] =
                    snapshot.getLoadable(smartviewInstancesIdsAtom).contents;

                reset(smartviewInstancesIdsAtom);

                setTimeout(() => {
                    smartviewInstances.forEach((smartviewInstance) => reset(smartviewManager(smartviewInstance.name)));
                }, 500);
            },
        []
    );

    return closeAll;
}
