import { FontFontFamilyPrimary, FontLineHeightTight, FontWeightSemiBold } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

const PriceStyling = css`
    font-family: ${FontFontFamilyPrimary};
    line-height: normal;
    font-weight: ${FontWeightSemiBold};
`;

export default PriceStyling;
