/* eslint-disable @typescript-eslint/no-shadow */
import { ColorNeutralWhite, Spacing16, Spacing56, Spacing72, Spacing8 } from 'autogen/design-tokens/tokens';
import styled from 'styled-components';
import { breakpoint, sizes, zIndex } from 'variables';
import { useRecoilValue } from 'recoil';
import { quickViewHeaderBackgroundState } from '../../atoms/quickViewState';
import { QuickViewHeaderProps } from '../../shared/types';
import DefaultQuickViewHeader, { QuickViewCloseButton } from '../DefaultQuickViewComponents/Header';

const StyledDefaultQuickViewHeader = styled(DefaultQuickViewHeader)<{
    showHeaderBackground: boolean;
}>`
    flex: 0 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: ${Spacing8};
    height: ${Spacing56};

    ${breakpoint.up(sizes.md)} {
        padding: ${Spacing16};
        height: ${Spacing72};
    }

    position: absolute;
    top: 0;
    width: 100%;
    z-index: calc(${zIndex.modal} + 2);
    background: ${({ showHeaderBackground }) => (showHeaderBackground ? ColorNeutralWhite : 'transparent')};
`;

export default function AbsoluteQuickViewHeader(props: QuickViewHeaderProps) {
    const showHeaderBackground = useRecoilValue(quickViewHeaderBackgroundState);

    return (
        <StyledDefaultQuickViewHeader
            closeButtonRenderer={(onCloseCallback) => <QuickViewCloseButton onClose={onCloseCallback} rounded />}
            showHeaderBackground={showHeaderBackground}
            {...props}
        />
    );
}
