import { useCallback, useEffect, useState } from 'react';

export type StatusStates = 'idle' | 'pending' | 'success' | 'error';

// inspired by https://swr.vercel.app/docs/getting-started

/**
 *
 * @param asyncFunction - An async function to invoke.
 * @param immediate - If the async function should be invoked immediately or using the returned "execute" function.
 * @param initialValue - The initial value of the value returned from the hook.
 * @returns - { execute, status, value, error }
 */
function useAsync<T, A extends unknown[], E = string>(
    asyncFunction: (...args: A) => Promise<T>,
    immediate: false,
    initialValue?: T
): { execute: (...args: A) => Promise<void>; status: StatusStates; value: T; error: E; reset: () => void };
function useAsync<T, E = string>(
    asyncFunction: () => Promise<T>,
    immediate?: boolean,
    initialValue?: T
): { execute: () => Promise<void>; status: StatusStates; value: T; error: E; reset: () => void };
function useAsync(asyncFunction, immediate = true, initialValue) {
    const [status, setStatus] = useState<StatusStates>('idle');
    const [value, setValue] = useState<any>(initialValue);
    const [error, setError] = useState<any>();

    const reset = useCallback(() => {
        setStatus('idle');
        setValue(initialValue);
        setError(undefined);
    }, [initialValue]);

    const execute = useCallback(
        (...args: any) => {
            setStatus('pending');
            setError(undefined);
            return asyncFunction(...args)
                .then((response: any) => {
                    setValue(response);
                    setStatus('success');
                })
                .catch((e: any) => {
                    setError(e);
                    setStatus('error');
                });
        },
        [asyncFunction]
    );

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    return { execute, status, value, error, reset };
}

export default useAsync;
