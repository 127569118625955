import { FilterParams } from '../types/types';

type ProductListEventParam = FilterParams<'list_name'> | FilterParams<'page_number'>;

export default function getProductListParams(): ProductListEventParam[] {
    const { href, pathname } = window?.location;

    const url = new URL(href);
    const pageValue = url.searchParams.get('page') || 1;

    return [
        {
            param_name: 'list_name',
            param_value: pathname
        },
        {
            param_name: 'page_number',
            param_value: pageValue
        }
    ];
}

export type GetListNameParam = typeof getProductListParams;

