import { FontFontFamilyPrimary, FontLineHeightTight, FontLetterSpacing04 } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

const OverlineStyling = css`
    font-family: ${FontFontFamilyPrimary};
    line-height: ${FontLineHeightTight};
    text-transform: uppercase;
    letter-spacing: ${FontLetterSpacing04};
`;

export default OverlineStyling;
