import { Price } from 'components/generic/productPrice';
import { Typography } from 'components/generic/typography';
import { type VoucherSummaryLine as VoucherSummaryLineModel } from 'autogen/swagger/checkout';
import Layout from 'components/Layout';

type VoucherSummaryLine = Record<string, any> & { model: VoucherSummaryLineModel };

export default function VoucherSummaryLine({ model, ...restProps }: VoucherSummaryLine) {
    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <Typography.Body as="span" themeTypes="mediumSemiBold">
                {model.text}
            </Typography.Body>
            <Price price={model.priceText} />
        </Layout.Flex.Row>
    );
}
