/* eslint-disable */
/// This file is auto generated - do not edit manually
export const WriteReview = 'Website.ProductReview.List.WriteReview';
export const ReviewComplianceHeader = 'Website.ProductReview.List.ReviewComplianceHeader';
export const ReviewComplianceDescription = 'Website.ProductReview.List.ReviewComplianceDescription';
export const Heading = 'Website.ProductReview.List.Heading';
export const ReviewsDisabledText = 'Website.ProductReview.List.ReviewsDisabledText';
export const Name = 'Website.ProductReview.List.Name';
export const CityFrom = 'Website.ProductReview.List.CityFrom';
export const City = 'Website.ProductReview.List.City';
export const Age = 'Website.ProductReview.List.Age';
export const AgeYears = 'Website.ProductReview.List.AgeYears';
export const SortBy = 'Website.ProductReview.List.SortBy';
export const IsPrelaunch = 'Website.ProductReview.List.IsPrelaunch';
export const HidePrelaunch = 'Website.ProductReview.List.HidePrelaunch';
export const CustomerReviews = 'Website.ProductReview.List.CustomerReviews';
export const ShowingReviews = 'Website.ProductReview.List.ShowingReviews';
export const BasedOnXReviews = 'Website.ProductReview.List.BasedOnXReviews';
export const RatingSpreadHeadline = 'Website.ProductReview.List.RatingSpreadHeadline';
export const WasThisHelpful = 'Website.ProductReview.List.WasThisHelpful';
export const ShowOriginalLanguage = 'Website.ProductReview.List.ShowOriginalLanguage';
export const ShowTranslated = 'Website.ProductReview.List.ShowTranslated';
