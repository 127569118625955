import { Themes } from 'types/tokens';

let designThemesInput;
const themesObj: Record<string, DesignTheme> = {};
let themesFormated = false;

export const setDesignThemes = (arr: any[]): void => {
    designThemesInput = arr;
    themesFormated = false;
};

function formatThemes(themes: any[]): Record<string, DesignTheme> {
    themes.forEach((theme) => {
        themesObj[theme.name] = theme as DesignTheme;
    });

    themesFormated = true;
    return themesObj;
}

function designThemes(): Record<string, DesignTheme> {
    if (themesFormated) return themesObj;

    if (designThemesInput) return formatThemes(designThemesInput);

    if (typeof window === 'undefined') {
        return {};
    }

    window.DesignThemes ??= [];

    return formatThemes(window.DesignThemes);
}

export function getTheme(themeName: Themes): DesignTheme;
export function getTheme(themeName: string): DesignTheme;
export function getTheme(themeName) {
    if (!themeName) return {};
    return designThemes()[themeName.toLowerCase()] || {};
}

export interface DesignTheme {
    color1?: string;
    color2?: string;
    color3?: string;
    color4?: string;
    color5?: string;
}
