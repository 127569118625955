import CardWithShell from './Card';

import CardHeader from './components/CardHeader';
import CardContent from './components/CardContent';
import CardFooter from './components/CardFooter';

const Card = Object.assign(CardWithShell, {
    Header: CardHeader,
    Content: CardContent,
    Footer: CardFooter
});

export default Card;
