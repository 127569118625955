/* eslint-disable @typescript-eslint/no-shadow */
import mediator from 'modules/mediator';
import { useSyncExternalStore } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface MediatorAtom<AtomType> {
    get: () => AtomType;
    set: (newValue: AtomType | ((prevValue: AtomType) => AtomType)) => void;
    subscribe: (callback: (newValue: AtomType) => void) => () => void;
}

export function mediatorAtom<AtomType>(initialValue: AtomType): MediatorAtom<AtomType> {
    let value = initialValue;

    const channelId = uuidv4();

    return {
        get: () => value,
        set: (newValue) => {
            if (newValue instanceof Function) {
                const nextState = newValue(value);
                if (nextState === value) return;
                value = nextState;
                mediator.publish(channelId, nextState);
            } else {
                if (newValue === value) return;
                value = newValue;
                mediator.publish(channelId, value);
            }
        },
        subscribe: (callback) => {
            mediator.subscribe(channelId, callback);

            return () => mediator.unsubscribeSubscriberFromChannel(channelId, callback);
        }
    };
}

// Returns both value and setter
export default function useMediatorState<AtomType>(
    mediatorAtom: MediatorAtom<AtomType>
): [AtomType, MediatorAtom<AtomType>['set']] {
    const mediatorValue = useSyncExternalStore(mediatorAtom.subscribe, mediatorAtom.get, mediatorAtom.get);

    return [mediatorValue, mediatorAtom.set];
}

// Only returns value
export function useMediatorValue<AtomType>(mediatorAtom: MediatorAtom<AtomType>): AtomType {
    const [value] = useMediatorState(mediatorAtom);

    return value;
}

// Only returns setter
export function useSetMediatorState<AtomType>(mediatorAtom: MediatorAtom<AtomType>): MediatorAtom<AtomType>['set'] {
    return mediatorAtom.set;
}
