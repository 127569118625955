import { trackEvent } from './tracking/dataLayer';
import Translations from './translations';

export const addToDataLayer = rating => {
    if (!rating) return;

    const event = {
        event: 'uaevent',
        eventCategory: 'Product review',
        eventAction: `Product rating chosen: ${rating}`,
        eventLabel: document.title
    };

    trackEvent(event);
};

export default class RatingSelection {
    constructor(elm) {
        this.elm = $(elm);
        this.heading = this.elm.find('[data-js-heading]');
        this.link = this.elm.data('js-href');
        this.resetBtn = this.elm.find('[data-js-reset]');
        this.inputHidden = this.elm.find('[data-js="rating-selection-input-hidden"]');
        this.starsCaption = this.elm.find('[data-js="rating-selection-caption"]');
        this.stars = this.elm.find('[data-js="rating-selection-stars"]');
        this.starContainer = this.stars.get(0).querySelector('[data-js="star-container"]');
        this.borderContainer = this.stars.get(0).querySelector('[data-js="border-container"]');
        this.starInner = this.starContainer.querySelector('[data-js="star-inner"]');
        this.borderInner = this.borderContainer.querySelector('[data-js="border-inner"]');

        this.starContainerDefault = this.starContainer.dataset.jsStarOffset;
        this.borderContainerDefault = this.borderContainer.dataset.jsBorderOffset;

        this.inactiveStateClass = 'state-inactive';
        this.currentRating = null;
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.stars.on('mouseenter touchstart', event => this.reviewRating(event));
        this.stars.on('click touchend', event => this.setRating(event));
        this.resetBtn.on('click', () => this.resetRating());
    }

    reviewRating() {
        const targetWidth = this.stars.width();
        const targetPositionLeft = this.stars.position().left;

        let percentStep = null;

        this.stars
            .on('mousemove touchmove', event => {
                let offsetX =
                    event.type === 'touchmove'
                        ? event.originalEvent.touches[0].clientX - targetPositionLeft
                        : event.offsetX;
                let percent = Math.floor((offsetX / targetWidth) * 100);

                percentStep = Math.ceil((percent / 100) * 5) * 20;
                percentStep = Math.max(20, Math.min(percentStep, 100));

                this.captionHandler(percentStep / 20);

                this.starContainer.style.left = `${percentStep - 100}%`;
                this.starInner.style.right = `${percentStep - 100}%`;
                this.borderContainer.style.left = `${percentStep}%`;
                this.borderInner.style.left = `-${percentStep}%`;

                this.currentRating = percentStep;
            })
            .one('mouseleave', () => {
                this.starContainer.style.left = this.starContainerDefault;
                this.starInner.style.right = this.starContainerDefault;
                this.borderContainer.style.left = this.borderContainerDefault;
                this.borderInner.style.left = `-${this.borderContainerDefault}`;

                this.captionHandler(this.inputHidden.val());

                this.stars.off('mousemove touchmove');
            });
    }

    setRating() {
        const percentStep = this.currentRating === null || this.currentRating === 0 ? 60 : this.currentRating;
        const ratingValue = (percentStep / 100) * 5;

        this.inputHidden.val(ratingValue);
        this.captionHandler(ratingValue);
        this.starContainerDefault = `${percentStep - 100}%`;
        this.borderContainerDefault = `${percentStep}%`;

        if (this.link != undefined) {
            window.location = this.link + ratingValue;
        } else {
            if (ratingValue == 1) {
                this.heading.html(this.heading.attr('data-js-headingsingle'));
            } else {
                this.heading.html(this.heading.attr('data-js-heading').replace(/[0-9]/g, ratingValue));
            }
            this.resetBtn.removeClass(this.inactiveStateClass);
        }

        //remove validation msg if present
        if (ratingValue > 0) {
            $('[data-valmsg-for="Rating"]').toggle();
        }
    }

    captionHandler(int) {
        if (isNaN(int)) return;

        const StarsCaptionArray = this.starsCaption.attr('data-text-array').split('","');
        const newStarCaption = int - 1 >= 0 ? StarsCaptionArray[int - 1] : '';

        this.starsCaption.text(newStarCaption);
    }

    resetRating() {
        this.heading.html(Translations.Website.ProductReview.Create.ChooseNumberOfStars);
        this.inputHidden.val(0);
        this.starContainerDefault = '-100%';
        this.borderContainerDefault = '0%';
        this.starContainer.style.left = this.starContainerDefault;
        this.starInner.style.right = this.starContainerDefault;
        this.borderContainer.style.left = this.borderContainerDefault;
        this.borderInner.style.left = `-${this.borderContainerDefault}`;
        this.starsCaption.text('');
        this.resetBtn.addClass(this.inactiveStateClass);
    }
}
