/* eslint-disable import/prefer-default-export */
import { css, type RuleSet } from 'styled-components';
import { breakpoint, type BreakPointsType, sizes, type Breakpoint } from 'variables';

export type BreakPointObject = BreakPointsType<RuleSet>;

function sortByBreakpoint(styling: BreakPointObject) {
    return Object.entries(styling).sort((a, b) => {
        const breakpointKeyA = Number.isNaN(Number.parseInt(a[0])) ? sizes[a[0]] : Number.parseInt(a[0]);
        const breakpointKeyB = Number.isNaN(Number.parseInt(b[0])) ? sizes[b[0]] : Number.parseInt(b[0]);

        return breakpointKeyA - breakpointKeyB;
    });
}

export function mediaQueryStyling(stylingByBreakpoint: BreakPointObject): RuleSet | null {
    const sortedStylingArray = sortByBreakpoint(stylingByBreakpoint);
    const defaultStyling = sortedStylingArray.shift();

    if (!defaultStyling) return null;

    return css`
        ${defaultStyling[1]}

        ${sortedStylingArray.map(([key, styling]) => {
            const breakpointKey = Number.isNaN(Number.parseInt(key)) ? sizes[key] : (key as Breakpoint);

            return css`
                ${breakpoint.up(breakpointKey)} {
                    ${styling}
                }
            `;
        })}
    `;
}

type GenericStyleObject = {
    [key: string]: RuleSet;
};

export function handleSizesMediaQueryStyling<T extends {}>(sizesObject: T, styleObject: GenericStyleObject) {
    Object.keys(sizesObject).forEach((key) => {
        sizesObject[key] = styleObject[sizesObject[key]];
    });

    return mediaQueryStyling(sizesObject);
}

export type MappedBreakpointStyling<X, Y> = {
    [P in keyof X]: Y[keyof Y];
};
