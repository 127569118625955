const userAgent =
    typeof window === 'undefined' ? '' : navigator.userAgent || navigator.vendor || window.opera || navigator.platform;

// -- These can not be used as is -- //
const isChrome = /(Chrome|CriOS)/i.test(userAgent); // CriOS is Chrome on iOS
const isFireFox = /(FireFox|FxiOS)/i.test(userAgent); // FxiOS is Firefox on iOS
const isEdge = /(Edge|Edg)/i.test(userAgent);
// -- /These can not be used as is -- //

const isIOS = ((browserUserAgent): boolean => {
    if (typeof window === 'undefined') {
        return false;
    }
    // returns false if not, version as number or string as/when `≥8` || `≤3` (which evaluate as truthy)
    const actuallyIE = window.MSStream;

    const iosDevices = /iPad|iPhone|iPod/i;

    if (iosDevices.test(browserUserAgent) && !actuallyIE) {
        return true;
    }
    return false;
})(userAgent);

const isSafari = ((browserUserAgent): boolean => {
    // https://developer.chrome.com/multidevice/user-agent
    const ua = browserUserAgent.toLowerCase();

    if (ua && ua.indexOf('safari') !== -1) {
        if (isChrome || isFireFox || isEdge) {
            return false;
        }
        return true;
    }
    return false;
})(userAgent);

// Test for "Mobi" in user agents string, recommended as a last resort in this MDN article: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
const isMobile = /Mobi/i.test(userAgent);

export default {
    isIOS,
    isSafari,
    isMobile
};
