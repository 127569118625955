import { GetQuantity, type GetQuantitySuccess } from 'autogen/swagger/quantity';
import requestContext from 'modules/requestContext';
import { mediatorAtom } from 'hooks/globals/useMediatorAtom';
import mediator from './mediator';

export type QuantityType = 'basket' | 'favorites';

export const BasketQuantityMediatorAtom = mediatorAtom(0);
export const FavoriteQuantityMediatorAtom = mediatorAtom(0);
export default class GetQuantities {
    attrName: string;

    quantityAttr: string;

    constructor() {
        this.attrName = 'data-js-getquantity';
        this.quantityAttr = 'data-count';
    }

    init() {
        mediator.subscribe('updateQuantities', (type: QuantityType) => this.getAllQuantities(type));
        this.getAllQuantities();
    }

    async getAllQuantities(type?: QuantityType) {
        if (requestContext?.posRequest?.isPosRequest) {
            return;
        }

        if (requestContext?.isClubMatasApp) {
            const [quantities, { default: updateAppQuantities }] = await Promise.all([
                GetQuantity({ includeBasket: true, includeFavorites: true }),
                import('components/clubMatasApp')
            ]);

            updateAppQuantities(quantities);

            return;
        }

        let getQuantityPromise: Promise<GetQuantitySuccess>;

        switch (type) {
            case 'basket':
                getQuantityPromise = GetQuantity({
                    includeBasket: true,
                    includeFavorites: false
                });
                break;
            case 'favorites':
                getQuantityPromise = GetQuantity({
                    includeBasket: false,
                    includeFavorites: true
                });
                break;
            default:
                getQuantityPromise = GetQuantity({
                    includeBasket: true,
                    includeFavorites: true
                });
                break;
        }

        const value = await getQuantityPromise;

        if (value.basketQuantity !== null) BasketQuantityMediatorAtom.set(value.basketQuantity);
        if (value.favoriteQuantity !== null) FavoriteQuantityMediatorAtom.set(value.favoriteQuantity);

        this.getButtons();
    }

    setStuff(item, qty) {
        const quantityElement = item.querySelector(`[${this.quantityAttr}]`);

        requestAnimationFrame(() => {
            quantityElement.setAttribute(this.quantityAttr, qty);
        });
    }

    getButtons() {
        const btns = document.querySelectorAll(`[${this.attrName}]`);
        btns.forEach((item) => {
            const attr = item.getAttribute(this.attrName);

            if (attr.match(/basket$/) && BasketQuantityMediatorAtom.get() != null) {
                this.setStuff(item, BasketQuantityMediatorAtom.get());
            }

            if (attr.match(/favourites$/) && FavoriteQuantityMediatorAtom.get() != null) {
                this.setStuff(item, FavoriteQuantityMediatorAtom.get());
            }
        });
    }
}
