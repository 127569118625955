/* eslint-disable import/prefer-default-export */
import isClient from './isClient';

export function getCookieValueByName(name: string): string | undefined {
    if (!isClient) return undefined;

    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
    return undefined;
}
