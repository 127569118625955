import { createContext, type HTMLAttributes, useCallback, useContext, useEffect, useState } from 'react';
import type { UseEmblaCarouselType, EmblaOptionsType } from 'embla-carousel-react';
import useEmblaCarousel from 'embla-carousel-react';

interface IEmblaCarousel extends HTMLAttributes<HTMLDivElement> {
    options?: EmblaOptionsType;
}

export const EmblaContext = createContext<UseEmblaCarouselType>({} as UseEmblaCarouselType);

export function useNavigation(): {
    prev: () => void;
    next: () => void;
    canScrollPrev: boolean;
    canScrollNext: boolean;
} {
    const [, embla] = useContext(EmblaContext);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const prev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const next = useCallback(() => embla && embla.scrollNext(), [embla]);

    useEffect(() => {
        function detectCanScroll() {
            if (embla) {
                setCanScrollPrev(embla.canScrollPrev());
                setCanScrollNext(embla.canScrollNext());
            }
        }

        detectCanScroll();

        embla?.on('select', detectCanScroll);

        return () => {
            embla?.off('select', detectCanScroll);
        };
    }, [embla]);

    return { prev, next, canScrollPrev, canScrollNext };
}

export function emblaHOC(options?: EmblaOptionsType) {
    return <P,>(WrappedComponent: React.FC<P>) =>
        (props: P & React.JSX.IntrinsicAttributes) => {
            const [emblaRef, embla]: UseEmblaCarouselType = useEmblaCarousel(options);

            return (
                <EmblaContext.Provider value={[emblaRef, embla]}>
                    <WrappedComponent {...props} />
                </EmblaContext.Provider>
            );
        };
}

const EmblaCarousel = (props: IEmblaCarousel): JSX.Element => {
    const { options, children } = props;
    const [emblaRef, embla]: UseEmblaCarouselType = useEmblaCarousel(options);

    return <EmblaContext.Provider value={[emblaRef, embla]}>{children}</EmblaContext.Provider>;
};

export function useEmblaApi() {
    const [, embla] = useContext(EmblaContext);

    return embla;
}

export default EmblaCarousel;
