import isClient from 'helpers/isClient';

export const deleteByValue = (query: URLSearchParams, name: string, value: string): URLSearchParams => {
    if (!isClient || (!query.has(name) && !query.getAll(name).includes(value))) return null;

    // store values and clear name from query
    const otherValues = query.getAll(name);
    query.delete(name);

    // remove deleted value and reappend the rest
    const index = otherValues.indexOf(value);
    if (index > -1) {
        otherValues.splice(index, 1);
    }
    otherValues.forEach((otherValue): void => query.append(name, otherValue));

    return query;
};

export function removeParam(url: string, parameter: string): string {
    const [urlWithoutParams, params] = url.split('?');

    const paramsArray = params
        ? params
              .split('&')
              .map((keyValString) => {
                  const [stringParameter] = keyValString.split('=');
                  if (stringParameter === parameter) {
                      return null;
                  }
                  return keyValString;
              })
              .filter(Boolean)
        : [];

    if (!paramsArray.length) {
        return urlWithoutParams;
    }

    return `${urlWithoutParams}?${paramsArray.join('&')}`;
}

/**
 * Updates the URL with a new query string, without a refresh.
 *
 * Using `history.replaceState`
 */
export const updateUrlWithParams = (key: string, value: string): void => {
    if (!isClient) return;
    const currentParams = new URLSearchParams(window.location.search);

    currentParams.append(key, value);

    const newUrl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
    }?${currentParams.toString()}`;

    window.history.replaceState({ path: newUrl }, '', newUrl);
};

/**
 * Replace a single URL search parameter, without a refresh.
 *
 * Using `history.replaceState`
 */
export const replaceUrlParam = (key: string, value?: string): void => {
    if (!isClient) return;
    const currentParams = new URLSearchParams(window.location.search);

    if (value) {
        currentParams.set(key, value);
    } else {
        currentParams.delete(key);
    }

    const newUrl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
    }?${currentParams.toString()}`;

    window.history.replaceState({ path: newUrl }, '', newUrl);
};

/**
 * Deletes a query param and updates the URL, without refresh.
 *
 * Using `history.replaceState`
 */
export const deleteParamByKey = (key: string): void => {
    if (!isClient) return;
    const currentParams = new URLSearchParams(window.location.search);

    if (currentParams.has(key)) {
        currentParams.delete(key);
        const newUrl = `${window.location.protocol}//${window.location.host}${
            window.location.pathname
        }?${currentParams.toString()}`;
        window.history.replaceState({ path: newUrl }, '', newUrl);
    }
};

export const hasParamByKey = (key: string): boolean => {
    const currentParams = new URLSearchParams(window.location.search);

    if (currentParams.has(key)) {
        return true;
    }
    return false;
};

export const hasParamsValueByKey = (key: string, url?: string, isCompleteUrl = true): boolean => {
    const currentParams = isCompleteUrl
        ? new URLSearchParams(window.location.search)
        : new URL(url, window.location.origin).searchParams;
    if (currentParams.has(key) && currentParams.get(key)) {
        return true;
    }
    return false;
};

export const searchParamsToObject = (
    searchParams: URLSearchParams,
    ignoreEmpty?: boolean
): { [key: string]: unknown } => {
    const entries = searchParams.entries();
    const entriesArray = Array.from(entries);

    let result;
    entriesArray.forEach(([key, value]) => {
        if (ignoreEmpty) {
            result = { ...(value && { [key]: value }), ...result };
        } else {
            result[key] = value;
        }
    });
    return result;
};
