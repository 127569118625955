import breakpoint from './breakpoint';

export default class Breadcrumb {
    static bind() {
        const breadcrumb = new Breadcrumb();
        breadcrumb.init();
    }

    constructor() {
        this.wrapper = $('.js-breadcrumb');
        this.backButton = this.wrapper.find('.js-bc-go-back');
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.backButton.on('click', e => {
            if (breakpoint.max('md')) {
                e.preventDefault();
                this.backButtonClick();
            }
        });
    }

    backButtonClick() {
        if (document.referrer.indexOf(window.location.hostname) != -1) {
            window.history.back();
        } else {
            window.location.href = this.backButton.attr('href');
        }
    }
}
