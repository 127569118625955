/* eslint-disable */
/// This file is auto generated - do not edit manually
export const Title = 'Website.Basket.Samples.SamplesPicker.Title';
export const CaptionWithIcon = 'Website.Basket.Samples.SamplesPicker.CaptionWithIcon';
export const DenySelectionButton = 'Website.Basket.Samples.SamplesPicker.DenySelectionButton';
export const CardHeaderRecommended = 'Website.Basket.Samples.SamplesPicker.CardHeaderRecommended';
export const ShowMoreButton = 'Website.Basket.Samples.SamplesPicker.ShowMoreButton';
export const ShowLessButton = 'Website.Basket.Samples.SamplesPicker.ShowLessButton';
export const ChooseSampleButton = 'Website.Basket.Samples.SamplesPicker.ChooseSampleButton';
export const ChosenSampleButton = 'Website.Basket.Samples.SamplesPicker.ChosenSampleButton';
export const LockedSampleButton = 'Website.Basket.Samples.SamplesPicker.LockedSampleButton';
export const EstimatedValue = 'Website.Basket.Samples.SamplesPicker.EstimatedValue';
