import React from 'react';
import { animated, useTransition } from '@react-spring/web';
import { useNavigation } from 'components/emblaCarousel';
import { ColorNeutralWhite, BoxShadowXl, ColorNeutralLightGrey } from 'autogen/design-tokens/tokens';
import { ButtonWithIcon } from 'components/Button';
import styled from 'styled-components';
import type IButton from 'components/Button/shared/types/button';
import dynamic from 'helpers/dynamic';
import type { onCarouselScrollClick } from 'components/GalleryMediaPreviewer/shared/types';

export type ScrollType = 'next' | 'previous';
export type Direction = 'horizontal' | 'vertical';

/* #region  styles */
const ScrollButton = styled(ButtonWithIcon)`
    background-color: ${ColorNeutralWhite};
    box-shadow: ${BoxShadowXl};
    border: 1px solid ${ColorNeutralLightGrey};
    aspect-ratio: 1;
`;

const AnimatedScrollButton = animated<any>(ScrollButton);
/* #endregion */

interface ICarouselScrollButton extends IButton {
    scrollType: ScrollType;
    onClickCallBack?: onCarouselScrollClick;
    direction?: Direction;
}

const ArrowUp = dynamic(() => import('icons/ArrowUp.svg?react'), { ssr: false });
const ArrowDown = dynamic(() => import('icons/ArrowDown.svg?react'), { ssr: false });
const ArrowLeft = dynamic(() => import('icons/ArrowLeft.svg?react'), { ssr: false });
const ArrowRight = dynamic(() => import('icons/ArrowRight.svg?react'), { ssr: false });

export default function CarouselScrollButton({
    scrollType,
    onClickCallBack,
    size = 'lg',
    direction = 'horizontal',
    ...restProps
}: ICarouselScrollButton) {
    const { prev, next, canScrollNext, canScrollPrev } = useNavigation();

    const iconType = {
        horizontal: { next: <ArrowRight />, previous: <ArrowLeft /> },
        vertical: { next: <ArrowDown />, previous: <ArrowUp /> }
    };

    const buttonType: any = {
        next: { icon: iconType[direction].next, canScroll: canScrollNext, onClick: next },
        previous: { icon: iconType[direction].previous, canScroll: canScrollPrev, onClick: prev }
    };

    const transitions = useTransition(buttonType[scrollType].canScroll, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: buttonType[scrollType].canScroll
    });

    return transitions(
        (styles, item) =>
            item && (
                <AnimatedScrollButton
                    {...restProps}
                    size={size}
                    style={styles}
                    icon={buttonType[scrollType].icon}
                    onClick={() => {
                        if (onClickCallBack) {
                            onClickCallBack(scrollType);
                        }
                        buttonType[scrollType].onClick();
                    }}
                    iconProperties={{ size: '14px' }}
                    variant={null}
                />
            )
    );
}
