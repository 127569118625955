import {
    BorderRadiusMedium,
    BorderRadiusSmall,
    FontSize14,
    FontSize16,
    Spacing12,
    Spacing16,
    Spacing8
} from 'autogen/design-tokens/tokens';
import { mediaQueryStyling } from 'helpers/mediaQueryStyling';
import { rem } from 'modules/helpers/style';
import { css } from 'styled-components';
import type { ButtonSizesObject } from './types/button';

const buttonSizes = {
    sm: css`
        font-size: ${FontSize14};
        padding: ${Spacing8};
        height: ${rem(32)};
        border-radius: 6px;
    `,
    md: css`
        font-size: ${FontSize14};
        padding: ${Spacing12};
        height: ${rem(40)};
        border-radius: ${BorderRadiusMedium};
    `,
    lg: css`
        font-size: ${FontSize16};
        padding: ${Spacing16};
        height: ${rem(48)};
        border-radius: ${BorderRadiusMedium};
    `
};

export type ButtonSizeKeys = keyof typeof buttonSizes;

// Helper for mapping styling to breakpoints
export function getButtonSizesMediaQueryStyling<T extends {}>(sizesObject: T) {
    Object.keys(sizesObject).forEach((key) => {
        sizesObject[key] = buttonSizes[sizesObject[key]];
    });

    return mediaQueryStyling(sizesObject);
}

export const buttonSizeStyling = css<{ size: ButtonSizesObject | ButtonSizeKeys }>`
    ${({ size }) => size && (typeof size === 'object' ? getButtonSizesMediaQueryStyling(size) : buttonSizes[size])}
`;

export default buttonSizes;
