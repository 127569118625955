import { Typography } from 'components/generic/typography';
import styled from 'styled-components';

/* #region  styles */
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
`;

export interface IXSmallTooltipContent {
    description: string;
}
/* #endregion */

export default function SmallTooltipPopupContent({ content }: { content: IXSmallTooltipContent }) {
    const { description } = content;

    return (
        <Content>
            <Typography.Body themeTypes="small" forwardedAs="span" dangerouslySetInnerHTML={{ __html: description }} />
        </Content>
    );
}
