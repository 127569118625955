import type { UserMessage } from 'autogen/swagger/myprofile';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export type UserMessageToastProps = {
    type: UserMessage['messageType'];
    message: string;
};

export const spawnAlertToast = (message: string, type: UserMessage['messageType']) => {
    switch (type) {
        case 'Alert_Success':
            toast.success<string>(message, {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        case 'Alert_Danger':
            toast.error<string>(message, {
                position: toast.POSITION.TOP_RIGHT
            });
            break;
        default:
            break;
    }
};

export default function UserMessageToast(props: UserMessageToastProps) {
    const { type, message } = props;

    useEffect(() => {
        spawnAlertToast(message, type);
    }, []);

    return null;
}
