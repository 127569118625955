import type { ProductNameLineType } from 'autogen/swagger/product';
import { themeTypes } from 'components/generic/typography/components/Body';
import { OverlineFontSizes } from 'components/generic/typography/types';
import { IProductNameSize } from './types';

type BrandType = {
    Brand: OverlineFontSizes;
};

export type ProductNameLineThemeTypes = {
    [IProductNameSizeKey in IProductNameSize]: BrandType & {
        [ProductNameLineTypeKey in Extract<ProductNameLineType, 'Name' | 'Variant'>]: themeTypes;
    };
};

export const ProductNameLineTheme: ProductNameLineThemeTypes = {
    sm: {
        Brand: 10,
        Name: 'small',
        Variant: 'small'
    },
    md: {
        Brand: 10,
        Name: 'medium',
        Variant: 'medium'
    },
    lg: {
        Brand: 14,
        Name: 'large',
        Variant: 'large'
    }
};
