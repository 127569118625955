import mediator from './mediator';
import { InputWPlaceholder } from './input-w-placeholder';
import Translations from './translations';

export default class FormSubmit {
    constructor(elm, successFunction, errorFunction) {
        this.form = $(elm);
        this.inputs = this.form.find('input');
        this.successFunction = typeof successFunction === 'function' ? successFunction : false;
        this.errorFunction = typeof errorFunction === 'function' ? errorFunction : false;
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.form.on('submit', e => {
            e.preventDefault();
            this.submit(e);
        });

        this.inputs.on('focus', e => {
            const item = $(e.target);
            if (item.hasClass('input-validation-error')) {
                item.removeClass('input-validation-error')
                    .siblings('.field-validation-error')
                    .remove();
            }
        });
    }

    rebindInput(newForm) {
        this.form = $(newForm);
        this.inputs = this.form.find('input');
        let input_w_placeholder = new InputWPlaceholder(this.form.find('[data-js*="InputWithLabel"]'));
        input_w_placeholder.init();
    }

    submit(e) {
        const target = $(e.target);
        const action = target.attr('action');
        const method = target.attr('method');

        $.ajax({
            type: method,
            url: action,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType: 'JSON',
            data: this.form.serialize(),
            success: response => {
                if (response.message) {
                    mediator.publish('growl', { message: response.message, remove: 2000 });
                } else if (response.responseText.length != 0) {
                    const newForm = $(response.responseText);

                    this.form.replaceWith(newForm);
                }
                if (this.successFunction) {
                    this.successFunction();
                }
            }
        }).fail(response => {
            if (response.responseText.length != 0) {
                const newForm = $(response.responseText);

                this.form.replaceWith(newForm);

                const formSubmit = new FormSubmit(newForm);
                formSubmit.init();
                this.rebindInput(newForm);

                if (this.errorFunction) {
                    this.errorFunction();
                }
            } else {
                mediator.publish('growl', { message: Translations.Website.ProductCustomerNotification.Fail });
            }
        });
    }
}
