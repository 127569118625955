import Spinner from 'modules/shared/circleSpinner';
import type { ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div<{ $loading: boolean; $canUserInteract: boolean }>`
    position: relative;
    opacity: ${({ $loading }) => ($loading ? 0.4 : 1)};
    transition: opacity 300ms ease;
    ${({ $canUserInteract, $loading }) =>
        $loading &&
        !$canUserInteract &&
        css`
            pointer-events: none;
            cursor: pointer;
        `}
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;

type LoadingContainerProps = {
    isLoading: boolean;
    canUserInteract?: boolean;
} & ComponentPropsWithoutRef<'div'>;

export default function LoadingContainer({
    isLoading,
    canUserInteract = true,
    children,
    ...restProps
}: LoadingContainerProps) {
    return (
        <Container $loading={isLoading} $canUserInteract={canUserInteract} {...restProps}>
            {!!isLoading && <StyledSpinner size={18} />}
            {children}
        </Container>
    );
}
