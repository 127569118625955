/* eslint-disable */
/// This file is auto generated - do not edit manually
export const SummaryLineText = 'Website.Basket.PrizeCertificate.SummaryLineText';
export const SummaryLineText2 = 'Website.Basket.PrizeCertificate.SummaryLineText2';
export const PointDiscountLineText = 'Website.Basket.PrizeCertificate.PointDiscountLineText';
export const GetNotDiscountableLineText = 'Website.Basket.PrizeCertificate.GetNotDiscountableLineText';
export const Heading = 'Website.Basket.PrizeCertificate.Heading';
export const DetailsStandard = 'Website.Basket.PrizeCertificate.DetailsStandard';
export const DetailsBelowMinimum = 'Website.Basket.PrizeCertificate.DetailsBelowMinimum';
export const Saldo = 'Website.Basket.PrizeCertificate.Saldo';
export const TotalPriceCertificateValue = 'Website.Basket.PrizeCertificate.TotalPriceCertificateValue';
