import styled from 'styled-components';
import { actionWrapperStyling } from '../shared/styles';
import type { ListItemType, Size } from '../shared/types';

const Button = styled.button<Pick<ListItemType, 'size'>>`
    ${actionWrapperStyling}
`;

function ButtonWrapper(props: React.ComponentPropsWithoutRef<'button'> & { size?: Size }) {
    const { children, size = 'md', ...restProps } = props;

    return (
        <Button size={size} type="button" {...restProps} tabIndex={0}>
            {children}
        </Button>
    );
}

export default ButtonWrapper;
