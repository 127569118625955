import { SET_HEADER_HEIGHT, SET_HEADER_IS_STICKY_BOOL, TOGGLE_HEADER_LOCKED } from '../actions/headerActions';

const initialState = {
    isSticky: false,
    isLocked: false,
    height: null,
};

const actions = {};

actions[SET_HEADER_IS_STICKY_BOOL] = (state, action) => {
    return {
        ...state,
        isSticky: action.bool
    };
};

actions[TOGGLE_HEADER_LOCKED] = (state, action) => {
    return {
        ...state,
        isLocked: action.bool
    };
};

actions[SET_HEADER_HEIGHT] = (state, action) => {
    return {
        ...state,
        height: action.height
    }
}

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
