import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'modules/breakpoint';
import Translations from 'modules/translations';
import MainContext from 'components/QuickSearch/components/context';
import { layoutEnum } from '../../enums';

const Button = styled.button`
    text-decoration: underline;
    font-weight: ${props => (props.layout === layoutEnum.row ? 400 : 700)};
    text-transform: ${props => (props.layout === layoutEnum.row ? 'initial' : 'uppercase')};
    padding-right: 0px;

    ${props =>
        props.isHandheld &&
        props.layout === layoutEnum.tab &&
        css`
            background-color: #ececec;
            padding: 7px 17px;
            text-decoration: none;
        `}
    @media (min-width: ${breakpoint.sizes.xl}px) {
        text-transform: uppercase;
        font-weight: 700;
    }
`;

export default props => {
    const { text } = props;
    const mainContext = useContext(MainContext);
    const { isHandheld, layout, onClearHistory } = mainContext;

    return (
        <Button
            className="btn"
            type="button"
            layout={layout}
            isHandheld={isHandheld}
            onClick={() => onClearHistory()}
            tabIndex="-1"
        >
            {text || Translations.Website.SearchDialog.ClearHistory}
        </Button>
    );
};
