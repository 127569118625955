import {
    type BasketMessageWrapper,
    BasketMessageDiscriminator as BasketMessageWrapperbasketMessageDiscriminator
} from 'autogen/swagger/minibasket';
import { CampaignBasketNudge } from 'components';
import { BasketMessages } from 'components/pages/Basket/components/BasketMessage';
import React from 'react';

export default function MiniBasketMessageRenderer({ model }: { model: BasketMessageWrapper }) {
    return BasketMessageWrapperbasketMessageDiscriminator(
        model.basketMessage,
        (discountModel) => <CampaignBasketNudge model={discountModel} />,
        (gwpModel) => <BasketMessages.Gwp model={gwpModel} />,
        (matasPlusModel) => <BasketMessages.MatasPlus model={matasPlusModel} />,
        (sameDayDeliveryModel) => <BasketMessages.SameDayDelivery model={sameDayDeliveryModel} />,
        (sampleModel) => <BasketMessages.Sample model={sampleModel} />,
        (shippingModel) => <BasketMessages.Shipping model={shippingModel} />,
        () => {
            throw new Error('invalid basket message type');
        }
    );
}
