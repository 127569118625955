/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as UserCommentBox from './trans-website-booking-bookingflow-usercommentbox';
export * as PersonalInfoBox from './trans-website-booking-bookingflow-personalinfobox';
export * as BookingBox from './trans-website-booking-bookingflow-bookingbox';
export * as TimeAndPlaceBox from './trans-website-booking-bookingflow-timeandplacebox';
export * as POS from './trans-website-booking-bookingflow-pos';

export const PageLabel = 'Website.Booking.BookingFlow.PageLabel';
export const PageLabelEvents = 'Website.Booking.BookingFlow.PageLabelEvents';
export const ReceiptPageHeadline = 'Website.Booking.BookingFlow.ReceiptPageHeadline';
export const ConfirmButton = 'Website.Booking.BookingFlow.ConfirmButton';
export const ConfirmTitle = 'Website.Booking.BookingFlow.ConfirmTitle';
export const ToFrontpageButton = 'Website.Booking.BookingFlow.ToFrontpageButton';
export const GoToMyBookingsButton = 'Website.Booking.BookingFlow.GoToMyBookingsButton';
export const ConfirmationText = 'Website.Booking.BookingFlow.ConfirmationText';
export const TermsAndConditionsText = 'Website.Booking.BookingFlow.TermsAndConditionsText';
export const TermsAndConditionsLink = 'Website.Booking.BookingFlow.TermsAndConditionsLink';
export const TermsAndConditionsTitle = 'Website.Booking.BookingFlow.TermsAndConditionsTitle';
export const NoticeHeadline = 'Website.Booking.BookingFlow.NoticeHeadline';
export const HeadlineSuffix = 'Website.Booking.BookingFlow.HeadlineSuffix';
