import { format } from 'modules/helpers/strings';

interface IFormatTitle {
    productName: string;
    externalPartnerName?: string;
    translation?: string;
}

export default function formatTitle({ productName, externalPartnerName, translation }: IFormatTitle): string {
    if (!externalPartnerName || !translation) {
        return productName;
    }

    return format(translation)(productName, externalPartnerName);
}
