import { constants } from 'variables';
import { createElement } from 'modules/helpers/dom';
import Translations from './translations';

const { pdpReadMoreMaxHeight } = constants;

export default class ReadMore {
    state = {
        expanded: false
    };

    constructor(element, maxHeight = parseInt(pdpReadMoreMaxHeight)) {
        this.element = element;
        const dataMaxHeight = element.dataset.maxHeight;
        if (dataMaxHeight) {
            maxHeight = dataMaxHeight;
        }
        // When Martins style em() helper is merged, the line below should be refactored
        this.maxHeight = maxHeight;
        this.maxHeightStyle = `${maxHeight / 13}em`;
        this.toggleReadMore = this.toggleReadMore.bind(this);
        this.readMore = createElement`<span>${Translations.Website.Shared.Buttons.ReadMore}</span>`;
        this.readLess = createElement`<span style="display: none">${Translations.Website.Shared.Buttons.ReadLess}</span>`;
        this.toggleBtn = createElement`
            <button type="button" class="btn btn--link d-block readmore-button">
                ${this.readMore}
                ${this.readLess}
            </div>
        `;
    }

    init() {
        requestIdleCallback(() => {
            if (this.element.scrollHeight - parseInt(this.maxHeight) > 60) {
                this.setToggleLink();
            } else {
                this.element.style.maxHeight = 'none';
            }
        });
    }

    setToggleLink() {
        this.element.style.height = this.maxHeightStyle;
        this.element.insertAdjacentElement('afterend', this.toggleBtn);
        this.toggleBtn.addEventListener('click', this.toggleReadMore);
    }

    toggleReadMore() {
        const { expanded } = this.state;

        if (expanded) {
            this.element.style.maxHeight = this.maxHeightStyle;
            this.element.style.height = this.maxHeightStyle;
        } else {
            this.element.style.height = `${this.element.scrollHeight}px`;
            this.element.style.maxHeight = 'none';
        }

        this.readMore.style.display = expanded ? 'block' : 'none';
        this.readLess.style.display = expanded ? 'none' : 'block';

        this.state.expanded = !expanded;
    }
}
