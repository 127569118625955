import { Spacing32, Spacing16, Spacing40, Spacing64 } from 'autogen/design-tokens/tokens';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';

const Page = styled.main`
    display: grid;
    row-gap: ${Spacing32};
    padding: ${Spacing16} 0;

    ${breakpoint.up(sizes.lg)} {
        row-gap: ${Spacing64};
        padding: ${Spacing40} 0;
    }
`;

export default Page;
