import useUserContext from './useUserContext';

/**
 *
 * @returns A generic loginDirectLink that returns the user to where they came from.
 */
export default function useLoginLink() {
    const state = useUserContext();

    return state?.loginDirectLink || {};
}
