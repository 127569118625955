import React from 'react';
import type { ButtonProps, ButtonSizesObject } from './shared/types/button';
import LinkAction from 'components/shared/Routing/components/LinkAction/LinkAction';
import styled, { css } from 'styled-components';
import { buttonTextStyling } from 'components/generic/typography';
import { type ButtonSizeKeys, buttonSizeStyling } from './shared/buttonSizes';
import { type ButtonVariant, buttonVariantStyling } from './shared/buttonVariants';
import type { LinkActionProps } from 'components/shared/Routing/components/LinkAction/shared/types';
import { withIcon } from 'components/hoc/withIcon/WithIcon';
import withSpinner from 'components/hoc/WithSpinner/WithSpinner';
import withAsyncStatus from 'components/hoc/WithAsyncStatus/WithAsyncStatus';

const linkButtonDefaultStyling = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;

    a&:hover,
    a&:active,
    a&:focus {
        text-decoration: none;
    }
`;

export const StyledButton = styled(LinkAction)<{
    variant?: ButtonVariant;
    size: ButtonSizesObject | ButtonSizeKeys;
    $disabled?: boolean;
}>`
    //Default styling
    ${linkButtonDefaultStyling}
    //Font styling
    ${buttonTextStyling}
    //Size styling
    ${buttonSizeStyling}
     //Variant styling
    ${buttonVariantStyling}

    ${({ theme }) => theme.ButtonStyling}
`;

export type LinkActionButtonProps = LinkActionProps & ButtonProps<HTMLAnchorElement>;

const LinkActionButton = React.forwardRef<HTMLAnchorElement, LinkActionButtonProps>(
    (
        {
            action,
            variant = 'primary',
            size = 'md',
            disabled,
            onClick,
            onClickDisabled,
            tabIndex,
            children,
            ...restProps
        },
        ref
    ) => (
        <StyledButton
            action={action}
            ref={ref}
            variant={variant}
            size={size}
            $disabled={disabled}
            tabIndex={disabled ? -1 : tabIndex}
            onClick={(event) => {
                if (disabled) {
                    event.preventDefault(); // prevents anchor navigation
                    if (onClickDisabled) onClickDisabled(event);
                    return; // prevents usual onClick events
                }
                if (onClick) onClick(event);
            }}
            {...restProps}
        >
            {children}
        </StyledButton>
    )
);

export default Object.assign(LinkActionButton, {
    WithIcon: withIcon(LinkActionButton),
    WithAsyncStatus: withAsyncStatus(LinkActionButton),
    WithSpinner: withSpinner(withAsyncStatus(LinkActionButton))
});
