import {
    BorderRadiusMedium,
    ColorClubMatas4,
    ColorClubMatasPlus2,
    ColorNordicGrey1,
    ColorSpot1,
    ColorSubscription1,
    ColorSystemAttention2,
    ColorSystemNegative1,
    ColorSystemNegative2,
    ColorSystemPositive1,
    Spacing12,
    Spacing16
} from 'autogen/design-tokens/tokens';
import { rgba } from 'polished';
import React, { type HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import type { ColorTokens } from 'types/tokens';

export interface IHighlightedText extends HTMLAttributes<HTMLDivElement> {
    color?: Extract<
        ColorTokens,
        | 'ColorSpot1'
        | 'ColorSubscription1'
        | 'ColorClubMatas4'
        | 'ColorClubMatasPlus2'
        | 'ColorSystemAttention2'
        | 'ColorSystemPositive1'
        | 'ColorSystemNegative1'
        | 'ColorSystemNegative2'
    >;
    withBorder?: boolean;
}

function getColor(color: IHighlightedText['color']) {
    switch (color) {
        case 'ColorSpot1':
            return ColorSpot1;
        case 'ColorSubscription1':
            return ColorSubscription1;
        case 'ColorClubMatas4':
            return ColorClubMatas4;
        case 'ColorClubMatasPlus2':
            return ColorClubMatasPlus2;
        case 'ColorSystemAttention2':
            return ColorSystemAttention2;
        case 'ColorSystemPositive1':
            return ColorSystemPositive1;
        case 'ColorSystemNegative1':
            return ColorSystemNegative1;
        case 'ColorSystemNegative2':
            return ColorSystemNegative2;
        default:
            return ColorNordicGrey1;
    }
}

const TextContainer = styled.div<IHighlightedText>`
    display: flex;
    padding: ${({ withBorder }) => (withBorder ? Spacing16 : 0)};
    width: 100%;

    ${({ withBorder, color }) =>
        withBorder &&
        css`
            border: 1px solid ${rgba(getColor(color), color ? 0.2 : 1)};
            border-radius: ${BorderRadiusMedium};
        `}

    &::before {
        content: '';
        flex: none;
        width: 4px;
        background-color: ${({ color }) => getColor(color)};
        border-radius: 2px;
        margin-right: ${Spacing12};
    }
`;

const HighlightedText = React.forwardRef<HTMLDivElement, IHighlightedText>((props, ref) => {
    const { color, withBorder = true, children, ...restProps } = props;

    return (
        <TextContainer color={color} withBorder={withBorder} {...restProps} ref={ref}>
            {children}
        </TextContainer>
    );
});

export default HighlightedText;
