/* eslint-disable react/no-array-index-key */
import { type AdditionalSummaryLine, type BasketGiftCardSummary } from 'autogen/swagger/checkout';
import React, { ReactNode } from 'react';
import Layout from 'components/Layout';
import { Typography } from 'components/generic/typography';
import { Price } from 'components/generic/productPrice';
import { useTranslation } from 'react-i18next';
import { PayByGiftCard, PayByCard, PaymentCoveredByGiftCard } from 'autogen/translation-keys/trans-website-checkout';
import SummaryLine from '.';
import AdditionalGiftCardSummaryLine from './AdditionalGiftCardLine';

type AdditionalGiftCardSummaryRenderer = Record<string, any> & {
    model: BasketGiftCardSummary;
    renderer?: (model: AdditionalSummaryLine, props) => ReactNode;
};

export default function AdditionalGiftCardSummaryRenderer({
    model: { giftCardSummaryLines },
    renderer = (lineModel, props) => <AdditionalGiftCardSummaryLine {...props} model={lineModel} />,
    ...restProps
}: AdditionalGiftCardSummaryRenderer): JSX.Element | null {
    return (
        <>
            {giftCardSummaryLines.map((giftCardSummaryLine, index) => (
                <React.Fragment key={index}>{renderer(giftCardSummaryLine, restProps)}</React.Fragment>
            ))}
        </>
    );
}

export function AdditionalGiftCardSummaryWithHeading({ model }: { model: BasketGiftCardSummary }) {
    const { t } = useTranslation();
    const { totalWithGiftCards, paymentCoveredByGiftCard } = model;

    return (
        <Layout.Flex.Column>
            <Typography.Body as="span" themeTypes="largeSemiBold">
                {t(PayByGiftCard)}
            </Typography.Body>

            <SummaryLine.AdditionalGiftCardRenderer model={model} />

            <Layout.Flex.Row justifyContent="space-between" alignItems="center">
                {paymentCoveredByGiftCard ? (
                    <Typography.Body as="span">{t(PaymentCoveredByGiftCard)}</Typography.Body>
                ) : (
                    <>
                        <Typography.Body as="span">{t(PayByCard)}</Typography.Body>
                        <Price price={totalWithGiftCards} />
                    </>
                )}
            </Layout.Flex.Row>
        </Layout.Flex.Column>
    );
}
