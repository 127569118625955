import htmlModal from 'modules/modal/htmlModal';

export default function openGWPModal(elm: HTMLButtonElement): void {
    elm.addEventListener('click', (event): void => {
        const target = event.currentTarget as HTMLButtonElement;
        const url = target.dataset.jsGwpModalAction;

        const theme = {
            width: '600px'
        };
        const modal = htmlModal({ layout: { customTheme: theme } });

        modal({ url, open: true });
    });
}
