import styled, { ThemeProvider } from 'styled-components';
import { Spacing4 } from 'autogen/design-tokens/tokens';
import type { IProductName } from './shared/types';
import standardProductNameRenderer from './components/StandardProductNameRenderer';
import compactNameRenderer from './components/CompactNameRenderer';
import { Brand } from './shared/styles';

const ProductNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${Spacing4};
    line-height: normal;
`;

export default function ProductName(props: IProductName): JSX.Element {
    const {
        productName,
        size = 'md',
        nameRenderer = standardProductNameRenderer,
        as,
        customTheme,
        ...restProps
    } = props;
    const { lines } = productName;
    const resolvedTheme = { ...customTheme, size };

    return (
        <ProductNameContainer {...restProps}>
            <ThemeProvider theme={resolvedTheme}>{lines.map((line) => nameRenderer(line, size, as))}</ThemeProvider>
        </ProductNameContainer>
    );
}

export function ProductNameCompact(props: IProductName) {
    const { productName, size = 'md', nameRenderer = compactNameRenderer, ...restProps } = props;
    const { lines } = productName;
    const linesToRender = [...lines];

    const brandLineIndex = linesToRender.findIndex((line) => line.type === 'Brand');
    const brandLine = linesToRender.splice(brandLineIndex, 1)[0];
    const title = linesToRender.map((line) => line.value).join(' ');

    return (
        <ProductNameContainer {...restProps}>
            <ThemeProvider theme={{ size }}>
                {brandLine && (
                    <Brand forwardedAs="span" size={10}>
                        {brandLine.value}
                    </Brand>
                )}
                <div title={title}>{linesToRender.map((line) => nameRenderer(line))}</div>
            </ThemeProvider>
        </ProductNameContainer>
    );
}
