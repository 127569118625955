
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection ES6UnusedImports
        // noinspection JSUnusedGlobalSymbols
        import { useQuery, type UseQueryOptions, useMutation, type UseMutationOptions } from '@tanstack/react-query';
        import {
AdvisorModal,
type AdvisorModalSuccess,
type AdvisorModalParams,
AdvisorChatIndex,
type AdvisorChatIndexSuccess,
AdvisorChatPage,
ZowieSettings,
type ZowieSettingsSuccess,
AdvisorToolboxIndex,
type AdvisorToolboxIndexSuccess,
type AdvisorToolboxIndexParams,
AdvisorToolboxPreview,
type AdvisorToolboxPreviewSuccess,
BambuserGetProducts,
type BambuserGetProductsSuccess,
type BambuserGetProductsParams,
GetBasketContent,
type GetBasketContentSuccess,
LookupUrl,
type LookupUrlSuccess,
type LookupUrlParams,
GetBasket,
type GetBasketSuccess,
_BasketFooter,
type _BasketFooterSuccess,
_ShippingOptions,
type _ShippingOptionsSuccess,
DeleteFromBasket,
type DeleteFromBasketSuccess,
type DeleteFromBasketParams,
DeleteAllFromBasket,
type DeleteAllFromBasketSuccess,
DeleteGiftFromBasket,
type DeleteGiftFromBasketParams,
AddToBasket,
type AddToBasketSuccess,
type AddToBasketParams,
AddMultipleToBasket,
type AddMultipleToBasketSuccess,
type AddMultipleToBasketParams,
AddOrUpdateQuantity,
type AddOrUpdateQuantitySuccess,
type AddOrUpdateQuantityParams,
UpdateQuantity,
type UpdateQuantitySuccess,
type UpdateQuantityParams,
UpdateFrequency,
type UpdateFrequencySuccess,
type UpdateFrequencyParams,
ToggleSwapCertificates,
type ToggleSwapCertificatesSuccess,
type ToggleSwapCertificatesParams,
UpdateVoucherCode,
type UpdateVoucherCodeSuccess,
type UpdateVoucherCodeParams,
RemoveVoucher,
type RemoveVoucherSuccess,
_BasketAddMinimumBuy,
type _BasketAddMinimumBuySuccess,
type _BasketAddMinimumBuyParams,
AddDigitalProduct,
type AddDigitalProductSuccess,
type AddDigitalProductParams,
RemovePrizeCertificate,
type RemovePrizeCertificateSuccess,
type RemovePrizeCertificateParams,
BasketAddPrizeCertificate,
type BasketAddPrizeCertificateSuccess,
type BasketAddPrizeCertificateParams,
GetSampleSelection,
type GetSampleSelectionSuccess,
ActionWillAffectSample,
type ActionWillAffectSampleSuccess,
type ActionWillAffectSampleParams,
AddSampleToBasket,
type AddSampleToBasketSuccess,
type AddSampleToBasketParams,
RemoveSampleFromBasket,
type RemoveSampleFromBasketSuccess,
type RemoveSampleFromBasketParams,
ReplaceSampleInBasket,
type ReplaceSampleInBasketSuccess,
type ReplaceSampleInBasketParams,
GetPointInfoModel,
type GetPointInfoModelSuccess,
SignupForNotifications,
type SignupForNotificationsSuccess,
GetNotifyMeModels,
type GetNotifyMeModelsSuccess,
GetFutureBookings,
type GetFutureBookingsSuccess,
GetPreviousBookings,
type GetPreviousBookingsSuccess,
type GetPreviousBookingsParams,
GetBookingDetails,
type GetBookingDetailsSuccess,
type GetBookingDetailsParams,
CancelBooking,
type CancelBookingSuccess,
type CancelBookingParams,
UpdateBooking,
type UpdateBookingSuccess,
type UpdateBookingParams,
CreateFeedback,
type CreateFeedbackSuccess,
type CreateFeedbackRequestBody,
GetTicketListPdf,
type GetTicketListPdfSuccess,
type GetTicketListPdfParams,
GetTicketPdf,
type GetTicketPdfSuccess,
type GetTicketPdfParams,
MyProfileBookingModel,
type MyProfileBookingModelSuccess,
MyProfileBookingPageViewModel,
type MyProfileBookingPageViewModelSuccess,
MyProfileBookingDetailsPageViewModel,
type MyProfileBookingDetailsPageViewModelSuccess,
BrandListModel,
type BrandListModelSuccess,
_News,
type _NewsSuccess,
type _NewsParams,
BrandClubPageModel,
type BrandClubPageModelSuccess,
BrandClubFrontPageModel,
type BrandClubFrontPageModelSuccess,
BrandClubBonusPageModel,
type BrandClubBonusPageModelSuccess,
BrandClubSignupModel,
type BrandClubSignupModelSuccess,
BrandClubMessageModel,
type BrandClubMessageModelSuccess,
BrandClubPageViewModel,
type BrandClubPageViewModelSuccess,
CampaignInfoDetailsByDiscountId,
type CampaignInfoDetailsByDiscountIdSuccess,
type CampaignInfoDetailsByDiscountIdParams,
CampaignInfoDetailsByProductId,
type CampaignInfoDetailsByProductIdSuccess,
type CampaignInfoDetailsByProductIdParams,
CampaignInfoByProductId,
type CampaignInfoByProductIdSuccess,
type CampaignInfoByProductIdParams,
GetUspListModel,
type GetUspListModelSuccess,
Fullwidth,
type FullwidthSuccess,
Inline,
type InlineSuccess,
Pdp,
type PdpSuccess,
AcceptTerms,
type AcceptTermsSuccess,
type AcceptTermsParams,
SearchBookingSlots,
type SearchBookingSlotsSuccess,
type SearchBookingSlotsParams,
SearchBookingSlotByLocation,
type SearchBookingSlotByLocationSuccess,
type SearchBookingSlotByLocationParams,
GetBookingOverview,
type GetBookingOverviewSuccess,
type GetBookingOverviewParams,
Book,
type BookSuccess,
type BookParams,
GetBookingEarlyStage,
GetTotalTicketPrice,
type GetTotalTicketPriceSuccess,
type GetTotalTicketPriceParams,
BookingReceiptModel,
type BookingReceiptModelSuccess,
BookRequestModel,
type BookRequestModelSuccess,
BookingPosJournalModel,
type BookingPosJournalModelSuccess,
ListConsultationProducts,
type ListConsultationProductsSuccess,
type ListConsultationProductsParams,
ConsultationProductPageModel,
type ConsultationProductPageModelSuccess,
ConsultationProductListPageContentViewModel,
type ConsultationProductListPageContentViewModelSuccess,
ChangeCreditCardChoice,
type ChangeCreditCardChoiceSuccess,
type ChangeCreditCardChoiceParams,
_CustomizeProduct,
type _CustomizeProductSuccess,
type _CustomizeProductParams,
AddCustomProductToBasket,
type AddCustomProductToBasketSuccess,
type AddCustomProductToBasketRequestBody,
type AddCustomProductToBasketParams,
CustomizeDigitalGiftCardModel,
type CustomizeDigitalGiftCardModelSuccess,
PrizeCertificates,
type PrizeCertificatesSuccess,
AddPrizeCertificate,
type AddPrizeCertificateSuccess,
type AddPrizeCertificateParams,
DeliveryRemovePrizeCertificate,
type DeliveryRemovePrizeCertificateSuccess,
type DeliveryRemovePrizeCertificateParams,
CheckoutDeliveryBasket,
type CheckoutDeliveryBasketSuccess,
GetCityFromZipCode,
type GetCityFromZipCodeSuccess,
type GetCityFromZipCodeParams,
GetDeliveryGroups,
type GetDeliveryGroupsSuccess,
GetDeliveryGroup,
type GetDeliveryGroupSuccess,
type GetDeliveryGroupParams,
UpdateInvoiceAddress,
type UpdateInvoiceAddressSuccess,
type UpdateInvoiceAddressRequestBody,
SelectPrivateDelivery,
type SelectPrivateDeliverySuccess,
type SelectPrivateDeliveryParams,
UpdatePrivateDeliveryAddress,
type UpdatePrivateDeliveryAddressSuccess,
type UpdatePrivateDeliveryAddressRequestBody,
SelectAndUpdateCompanyDeliveryAddress,
type SelectAndUpdateCompanyDeliveryAddressSuccess,
type SelectAndUpdateCompanyDeliveryAddressRequestBody,
SelectParcelShopDelivery,
type SelectParcelShopDeliverySuccess,
type SelectParcelShopDeliveryParams,
SelectAndUpdateParcelShopDelivery,
type SelectAndUpdateParcelShopDeliverySuccess,
type SelectAndUpdateParcelShopDeliveryRequestBody,
ListParcelShops,
type ListParcelShopsSuccess,
type ListParcelShopsParams,
ListParcelShopsByAddress,
type ListParcelShopsByAddressSuccess,
type ListParcelShopsByAddressParams,
ListParcelShopsByCoordinates,
type ListParcelShopsByCoordinatesSuccess,
type ListParcelShopsByCoordinatesParams,
SelectMatasDelivery,
type SelectMatasDeliverySuccess,
type SelectMatasDeliveryParams,
SelectAndUpdateMatasDelivery,
type SelectAndUpdateMatasDeliverySuccess,
type SelectAndUpdateMatasDeliveryRequestBody,
ListMatasParcelShops,
type ListMatasParcelShopsSuccess,
type ListMatasParcelShopsParams,
ListMatasParcelShopsByAddress,
type ListMatasParcelShopsByAddressSuccess,
type ListMatasParcelShopsByAddressParams,
ListMatasParcelShopsByCoordinates,
type ListMatasParcelShopsByCoordinatesSuccess,
type ListMatasParcelShopsByCoordinatesParams,
SelectUpsaleDelivery,
type SelectUpsaleDeliverySuccess,
type SelectUpsaleDeliveryParams,
ToggleNonurgentDelivery,
type ToggleNonurgentDeliverySuccess,
type ToggleNonurgentDeliveryParams,
ProgressiveDiscountForProduct,
type ProgressiveDiscountForProductSuccess,
type ProgressiveDiscountForProductParams,
ProgressiveDiscountDetailsForProduct,
type ProgressiveDiscountDetailsForProductSuccess,
type ProgressiveDiscountDetailsForProductParams,
SetZipCode,
type SetZipCodeSuccess,
type SetZipCodeParams,
SetZipCodeByCoordinates,
type SetZipCodeByCoordinatesSuccess,
type SetZipCodeByCoordinatesParams,
GetExpectedDeliverySummaryForBasket,
type GetExpectedDeliverySummaryForBasketSuccess,
type GetExpectedDeliverySummaryForBasketParams,
GetExpectedDeliverySummaryForProductPage,
type GetExpectedDeliverySummaryForProductPageSuccess,
type GetExpectedDeliverySummaryForProductPageParams,
GetExpectedDeliveryFullInformation,
type GetExpectedDeliveryFullInformationSuccess,
type GetExpectedDeliveryFullInformationParams,
GetExpectedDeliveryInformationForProductPage,
type GetExpectedDeliveryInformationForProductPageSuccess,
type GetExpectedDeliveryInformationForProductPageParams,
GetExpectedDeliveryInformationForBasket,
type GetExpectedDeliveryInformationForBasketSuccess,
UpdateStatus,
type UpdateStatusSuccess,
type UpdateStatusParams,
FavoriteGet,
type FavoriteGetSuccess,
type FavoriteGetParams,
GetBrands,
type GetBrandsSuccess,
FindationGetProducts,
type FindationGetProductsSuccess,
type FindationGetProductsParams,
GetShades,
type GetShadesSuccess,
type GetShadesParams,
FindationSearch,
type FindationSearchSuccess,
type FindationSearchRequestBody,
UpdateGranularMailCampaignPermissions,
type UpdateGranularMailCampaignPermissionsSuccess,
type UpdateGranularMailCampaignPermissionsParams,
RegisterReceipt,
type RegisterReceiptSuccess,
type RegisterReceiptRequestBody,
RegisterBasket,
type RegisterBasketSuccess,
type RegisterBasketRequestBody,
type RegisterBasketParams,
ToggleInPageNotification,
type ToggleInPageNotificationSuccess,
type ToggleInPageNotificationParams,
InspirationMatasLivePageViewModel,
type InspirationMatasLivePageViewModelSuccess,
Get,
type GetSuccess,
type GetParams,
HideCountdownBanner,
type HideCountdownBannerSuccess,
CountDownBannerModel,
type CountDownBannerModelSuccess,
UnsubscribeAll,
type UnsubscribeAllSuccess,
type UnsubscribeAllParams,
GetEventSubscriberInfo,
type GetEventSubscriberInfoSuccess,
type GetEventSubscriberInfoParams,
AddEventNotification,
type AddEventNotificationRequestBody,
GetLiveEventStatusModel,
type GetLiveEventStatusModelSuccess,
type GetLiveEventStatusModelParams,
LiveEventListViewModel,
type LiveEventListViewModelSuccess,
LiveEventFilteredResultViewModel,
type LiveEventFilteredResultViewModelSuccess,
LogPosEvent,
type LogPosEventRequestBody,
_LoginInfo,
type _LoginInfoSuccess,
type _LoginInfoParams,
MatasPlusRecommendations,
type MatasPlusRecommendationsSuccess,
ChangeSubscriptionType,
type ChangeSubscriptionTypeSuccess,
type ChangeSubscriptionTypeParams,
CancelSubscription,
type CancelSubscriptionSuccess,
MatasPlusConfirmCancel,
type MatasPlusConfirmCancelSuccess,
type MatasPlusConfirmCancelParams,
UndoCancelSubscription,
GetMatasPlusPageModel,
type GetMatasPlusPageModelSuccess,
MatasPlusCalculatorIndex,
type MatasPlusCalculatorIndexSuccess,
GetConfirmCancellationModal,
type GetConfirmCancellationModalSuccess,
MatasPlusSavingsPreview,
type MatasPlusSavingsPreviewSuccess,
type MatasPlusSavingsPreviewParams,
MatasPlusSavingsDetails,
type MatasPlusSavingsDetailsSuccess,
PointSavings,
type PointSavingsSuccess,
MatasPlusSavingsDisclaimerModel,
type MatasPlusSavingsDisclaimerModelSuccess,
Mega,
type MegaSuccess,
Footer,
type FooterSuccess,
List,
type ListSuccess,
type ListParams,
MenuForWebshop,
type MenuForWebshopSuccess,
type MenuForWebshopParams,
Menu,
type MenuSuccess,
type MenuParams,
_MiniBasket,
type _MiniBasketSuccess,
MiniBasketIndex,
type MiniBasketIndexSuccess,
AccordionDTO,
type AccordionDTOSuccess,
HighlightedCampaignBlockDTO,
type HighlightedCampaignBlockDTOSuccess,
MatasPlusUnboxingModel,
type MatasPlusUnboxingModelSuccess,
MatasPlusFaqBlockDTO,
type MatasPlusFaqBlockDTOSuccess,
GwpDiscountsBlock,
type GwpDiscountsBlockSuccess,
AdvisorBlock,
type AdvisorBlockSuccess,
AdvisorListBlockDTO,
type AdvisorListBlockDTOSuccess,
FooterViewModel,
type FooterViewModelSuccess,
StepLayoutFooterViewModel,
type StepLayoutFooterViewModelSuccess,
PrimaryHeaderViewModel,
type PrimaryHeaderViewModelSuccess,
ProductCustomerNotificationCreateModel,
type ProductCustomerNotificationCreateModelSuccess,
ProductReviewHeaderModel,
type ProductReviewHeaderModelSuccess,
AdvisorToolboxModel,
type AdvisorToolboxModelSuccess,
ProductSliderBlockDTOModel,
type ProductSliderBlockDTOModelSuccess,
ProductReviewCreateViewModel,
type ProductReviewCreateViewModelSuccess,
DonationModel,
type DonationModelSuccess,
BasketLineSubscriptionNudge,
type BasketLineSubscriptionNudgeSuccess,
BasketMessageDiscountViewModel,
type BasketMessageDiscountViewModelSuccess,
BasketShippingSupplierOption,
type BasketShippingSupplierOptionSuccess,
FeaturedFooterViewModel,
type FeaturedFooterViewModelSuccess,
MenuTooltipsViewModel,
type MenuTooltipsViewModelSuccess,
InPageNotificationViewModel,
type InPageNotificationViewModelSuccess,
ImageWithDisclaimerPlaceholderModel,
type ImageWithDisclaimerPlaceholderModelSuccess,
GlobalUspViewModel,
type GlobalUspViewModelSuccess,
NoRebuysAvailableViewModel,
type NoRebuysAvailableViewModelSuccess,
ExtendedPlaceholderModel,
type ExtendedPlaceholderModelSuccess,
CountdownModel,
type CountdownModelSuccess,
MultiBuyModel,
type MultiBuyModelSuccess,
StripedVerticalSpacer,
type StripedVerticalSpacerSuccess,
JavascriptRequestDataModel,
type JavascriptRequestDataModelSuccess,
BreadcrumbViewModel,
type BreadcrumbViewModelSuccess,
CmsButtonModel,
type CmsButtonModelSuccess,
CmsButtonGridModel,
type CmsButtonGridModelSuccess,
ProductPrelaunchBannerModel,
type ProductPrelaunchBannerModelSuccess,
BambuserViewModel,
type BambuserViewModelSuccess,
VimeoBlockDTOModel,
type VimeoBlockDTOModelSuccess,
YouTubeBlockDTOModel,
type YouTubeBlockDTOModelSuccess,
ShippingSummaryModel,
type ShippingSummaryModelSuccess,
VerticalSpacerContentBlockModel,
type VerticalSpacerContentBlockModelSuccess,
HorizontalSpaceBlockDTOModel,
type HorizontalSpaceBlockDTOModelSuccess,
AdvisorBlockDTOModel,
type AdvisorBlockDTOModelSuccess,
IStockStatusViewModel,
type IStockStatusViewModelSuccess,
ImageBannerModel,
type ImageBannerModelSuccess,
QuestionnaireContentBlockDTO,
type QuestionnaireContentBlockDTOSuccess,
PageActionModel,
type PageActionModelSuccess,
MyProfileSettingPageViewModel,
type MyProfileSettingPageViewModelSuccess,
SignoffPageViewModel,
type SignoffPageViewModelSuccess,
SignoffConfirmDialogueModel,
type SignoffConfirmDialogueModelSuccess,
PermissionViewModel,
type PermissionViewModelSuccess,
UpdateMyStoreViewModel,
type UpdateMyStoreViewModelSuccess,
RevieveMakeupAdvisorViewModel,
type RevieveMakeupAdvisorViewModelSuccess,
ShippingOptionsModel,
type ShippingOptionsModelSuccess,
CheckoutDeliveryGroupModel,
type CheckoutDeliveryGroupModelSuccess,
CheckoutDeliveryPostModel,
type CheckoutDeliveryPostModelSuccess,
CheckoutDeliveryViewModel,
type CheckoutDeliveryViewModelSuccess,
CheckoutSummaryViewModel,
type CheckoutSummaryViewModelSuccess,
CheckoutSummaryPostModel,
type CheckoutSummaryPostModelSuccess,
PaymentViewModel,
type PaymentViewModelSuccess,
PaymentReepayCallbackViewModel,
type PaymentReepayCallbackViewModelSuccess,
ReceiptPageViewModel,
type ReceiptPageViewModelSuccess,
ModifaceIndex,
type ModifaceIndexSuccess,
type ModifaceIndexParams,
MyFavoritesGet,
type MyFavoritesGetSuccess,
_StatusDetails,
type _StatusDetailsParams,
_FollowupDetails,
type _FollowupDetailsSuccess,
type _FollowupDetailsParams,
MyOrderCancel,
type MyOrderCancelParams,
OrderSimpleModel,
type OrderSimpleModelSuccess,
Header,
type HeaderSuccess,
MyProfileLayoutViewModel,
type MyProfileLayoutViewModelSuccess,
MyProfileFrontPageViewModel,
type MyProfileFrontPageViewModelSuccess,
GetProductReview,
type GetProductReviewSuccess,
type GetProductReviewParams,
ReviewViewModelWrapper,
type ReviewViewModelWrapperSuccess,
GetSampleProfilePageModel,
type GetSampleProfilePageModelSuccess,
RateSample,
type RateSampleSuccess,
type RateSampleParams,
UpdateFavoriteStatus,
type UpdateFavoriteStatusSuccess,
type UpdateFavoriteStatusParams,
GetOrderListModel,
type GetOrderListModelSuccess,
type GetOrderListModelParams,
ReceiptFile,
type ReceiptFileSuccess,
type ReceiptFileParams,
GetReceipt,
type GetReceiptSuccess,
type GetReceiptParams,
_Receipt,
type _ReceiptParams,
RenderSubPage,
type RenderSubPageSuccess,
type RenderSubPageParams,
ReepayAccept,
type ReepayAcceptParams,
Approve,
type ApproveSuccess,
type ApproveParams,
PendingSubscriptionCancel,
type PendingSubscriptionCancelSuccess,
type PendingSubscriptionCancelParams,
PendingSubscriptionGetModel,
type PendingSubscriptionGetModelSuccess,
type PendingSubscriptionGetModelParams,
PermissionGetModel,
type PermissionGetModelSuccess,
PermissionUpdate,
type PermissionUpdateParams,
PersonalFiltersIndex,
type PersonalFiltersIndexSuccess,
UpdatePreferenceModel,
type UpdatePreferenceModelSuccess,
type UpdatePreferenceModelRequestBody,
SetIntroduced,
type SetIntroducedSuccess,
ActivateEmployeeLogin,
type ActivateEmployeeLoginSuccess,
type ActivateEmployeeLoginParams,
Enable,
type EnableSuccess,
type EnableParams,
Disable,
type DisableSuccess,
type DisableParams,
PersonalOfferGetModel,
type PersonalOfferGetModelSuccess,
DeleteState,
type DeleteStateSuccess,
MyProfilePointInfoViewModel,
type MyProfilePointInfoViewModelSuccess,
type MyProfilePointInfoViewModelParams,
HidePointExpiryInfo,
PosFrontpageViewModel,
type PosFrontpageViewModelSuccess,
GetOrderNumber,
type GetOrderNumberSuccess,
GetPowerstep,
type GetPowerstepSuccess,
type GetPowerstepParams,
GetMatasPlusInfo,
type GetMatasPlusInfoSuccess,
StepNudgingViewModel,
type StepNudgingViewModelSuccess,
GetForFixedLowPrice,
type GetForFixedLowPriceSuccess,
GetVariantColorPickerForPdp,
type GetVariantColorPickerForPdpSuccess,
type GetVariantColorPickerForPdpParams,
GetVariantPickerForPlp,
type GetVariantPickerForPlpSuccess,
type GetVariantPickerForPlpParams,
ProductVariantsPdpModel,
type ProductVariantsPdpModelSuccess,
GetProductJson,
type GetProductJsonSuccess,
type GetProductJsonParams,
_GetAllProductReviews,
type _GetAllProductReviewsSuccess,
type _GetAllProductReviewsParams,
CreateCustomerNotification,
type CreateCustomerNotificationSuccess,
type CreateCustomerNotificationRequestBody,
GetRegimePrice,
type GetRegimePriceSuccess,
type GetRegimePriceParams,
GetProductRegime,
type GetProductRegimeSuccess,
type GetProductRegimeParams,
ProductInformationViewModel,
type ProductInformationViewModelSuccess,
ProductPageViewModel,
type ProductPageViewModelSuccess,
type ProductPageViewModelParams,
_GetProductReturnInformation,
GetCertification,
type GetCertificationSuccess,
type GetCertificationParams,
PageActions,
type PageActionsSuccess,
ProductDeliveryToStoreInfoViewModel,
type ProductDeliveryToStoreInfoViewModelSuccess,
type ProductDeliveryToStoreInfoViewModelParams,
ProductDeliveryInfoViewModel,
type ProductDeliveryInfoViewModelSuccess,
type ProductDeliveryInfoViewModelParams,
ProductListItems,
type ProductListItemsSuccess,
type ProductListItemsParams,
GetProductListModel,
type GetProductListModelSuccess,
ResetHistory,
type ResetHistorySuccess,
ProductRecommendationAIAssistantIndex,
GetRecommendation,
type GetRecommendationSuccess,
type GetRecommendationParams,
ValidateField,
type ValidateFieldSuccess,
type ValidateFieldParams,
ProductReviewCreate,
type ProductReviewCreateSuccess,
type ProductReviewCreateParams,
DeleteReview,
type DeleteReviewSuccess,
type DeleteReviewParams,
AddReviewLike,
type AddReviewLikeSuccess,
type AddReviewLikeParams,
ProductReviewSummaryIndex,
type ProductReviewSummaryIndexSuccess,
type ProductReviewSummaryIndexParams,
GetQuantity,
type GetQuantitySuccess,
type GetQuantityParams,
GetQuestionnaireData,
type GetQuestionnaireDataSuccess,
type GetQuestionnaireDataParams,
SubmitQuestionnaireData,
type SubmitQuestionnaireDataSuccess,
type SubmitQuestionnaireDataParams,
QuestionnaireResultListViewModel,
type QuestionnaireResultListViewModelSuccess,
QuickGetModel,
type QuickGetModelSuccess,
type QuickGetModelParams,
CreateOrder,
type CreateOrderRequestBody,
RebuyGet,
type RebuyGetSuccess,
type RebuyGetParams,
OrderDetails,
type OrderDetailsSuccess,
type OrderDetailsParams,
CreateOrderDetailsPdf,
type CreateOrderDetailsPdfSuccess,
type CreateOrderDetailsPdfParams,
GetByProductId,
type GetByProductIdSuccess,
type GetByProductIdParams,
GetByBasket,
type GetByBasketSuccess,
GetByProductGroupId,
type GetByProductGroupIdSuccess,
type GetByProductGroupIdParams,
GetBySearchResult,
type GetBySearchResultSuccess,
type GetBySearchResultParams,
GetByZeroSearchResult,
type GetByZeroSearchResultSuccess,
type GetByZeroSearchResultParams,
GetByHomePage,
type GetByHomePageSuccess,
type GetByHomePageParams,
GetByPowerstep,
type GetByPowerstepSuccess,
type GetByPowerstepParams,
GetByPowerstepTwo,
type GetByPowerstepTwoSuccess,
type GetByPowerstepTwoParams,
GetCustom,
type GetCustomSuccess,
type GetCustomParams,
GetForReceiptPage,
type GetForReceiptPageSuccess,
type GetForReceiptPageParams,
RecommendationRequestModel,
type RecommendationRequestModelSuccess,
ReviewRequestViewModel,
type ReviewRequestViewModelSuccess,
QuickSearch,
type QuickSearchSuccess,
type QuickSearchParams,
GetSearchViewModel,
type GetSearchViewModelSuccess,
GetConfirmDialogue,
type GetConfirmDialogueSuccess,
DoSignoff,
type DoSignoffSuccess,
ModelForBasket,
type ModelForBasketSuccess,
ModelForCheckout,
type ModelForCheckoutSuccess,
ClubMatasTerms,
type ClubMatasTermsSuccess,
TogglePlusSignup,
type TogglePlusSignupSuccess,
type TogglePlusSignupParams,
ToggleClubSignup,
type ToggleClubSignupParams,
Disclaimer,
type DisclaimerSuccess,
type DisclaimerParams,
StateUpdate,
type StateUpdateSuccess,
type StateUpdateParams,
ValidateFieldForShipping,
type ValidateFieldForShippingParams,
ValidateFieldForTypedAddress,
type ValidateFieldForTypedAddressParams,
ValidateInvoiceAddress,
type ValidateInvoiceAddressSuccess,
type ValidateInvoiceAddressParams,
GetStaticImage,
type GetStaticImageSuccess,
type GetStaticImageParams,
SearchStoreByAddress,
type SearchStoreByAddressSuccess,
type SearchStoreByAddressParams,
SearchStoreByCoordinates,
type SearchStoreByCoordinatesSuccess,
type SearchStoreByCoordinatesParams,
Validate,
type ValidateSuccess,
type ValidateParams,
_GetSelectedProduct,
type _GetSelectedProductSuccess,
type _GetSelectedProductParams,
SetUserPreferredStore,
type SetUserPreferredStoreSuccess,
type SetUserPreferredStoreParams,
GetProductStoreStockModel,
type GetProductStoreStockModelSuccess,
type GetProductStoreStockModelParams,
UpdateStoreViewModel,
StoreGet,
Choose,
type ChooseSuccess,
type ChooseParams,
StoreSearch,
type StoreSearchSuccess,
type StoreSearchParams,
ListStores,
type ListStoresSuccess,
type ListStoresParams,
_SubscriptionProductModal,
type _SubscriptionProductModalSuccess,
type _SubscriptionProductModalParams,
CreatePendingSubscription,
type CreatePendingSubscriptionSuccess,
GetPosSubscriptionInformation,
type GetPosSubscriptionInformationSuccess,
type GetPosSubscriptionInformationRequestBody,
OverviewModel,
type OverviewModelSuccess,
GetAll,
type GetAllSuccess,
SubscriptionDeliveryDeliveryGroupModel,
SubscriptionDeliveryGetPrivateAddressModel,
SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddress,
type SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess,
type SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressParams,
SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddress,
type SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess,
type SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressParams,
SubscriptionDeliverySelectAndUpdateParcelShopDelivery,
type SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess,
type SubscriptionDeliverySelectAndUpdateParcelShopDeliveryParams,
SubscriptionDeliverySelectMatasDelivery,
type SubscriptionDeliverySelectMatasDeliverySuccess,
type SubscriptionDeliverySelectMatasDeliveryParams,
SubscriptionDeliverySelectAndUpdateMatasDelivery,
type SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess,
type SubscriptionDeliverySelectAndUpdateMatasDeliveryParams,
SubscriptionDeliveryListMatasParcelShops,
type SubscriptionDeliveryListMatasParcelShopsSuccess,
type SubscriptionDeliveryListMatasParcelShopsParams,
SubscriptionDeliveryListParcelShops,
type SubscriptionDeliveryListParcelShopsSuccess,
type SubscriptionDeliveryListParcelShopsParams,
SubscriptionDeliveryCompanyDeliveryAddress,
SubscriptionDeliveryValidateFieldForShippingSwagger,
type SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess,
type SubscriptionDeliveryValidateFieldForShippingSwaggerParams,
GetDetails,
type GetDetailsSuccess,
type GetDetailsParams,
GetMore,
type GetMoreSuccess,
type GetMoreParams,
GetAdhocOrder,
type GetAdhocOrderSuccess,
type GetAdhocOrderParams,
UpdateAdhocOrder,
type UpdateAdhocOrderSuccess,
type UpdateAdhocOrderParams,
GetConfirmUpdateInfo,
type GetConfirmUpdateInfoSuccess,
type GetConfirmUpdateInfoParams,
GetCancel,
type GetCancelSuccess,
type GetCancelParams,
SubscriptionUpdateConfirmCancel,
type SubscriptionUpdateConfirmCancelParams,
OnHold,
type OnHoldSuccess,
type OnHoldParams,
SubscriptionUpdateUpdate,
type SubscriptionUpdateUpdateSuccess,
type SubscriptionUpdateUpdateParams,
GetSubscriptionPriceTotal,
type GetSubscriptionPriceTotalSuccess,
type GetSubscriptionPriceTotalParams,
GetGroupedBasket,
type GetGroupedBasketSuccess,
UpdateHasUserComment,
type UpdateHasUserCommentParams,
ActivateGiftCard,
type ActivateGiftCardSuccess,
type ActivateGiftCardParams,
RemoveGiftCardLine,
type RemoveGiftCardLineSuccess,
type RemoveGiftCardLineParams,
ActivatePaymentByGiftCard,
type ActivatePaymentByGiftCardSuccess,
DeactivatePaymentByGiftCard,
type DeactivatePaymentByGiftCardSuccess,
AddGiftCardLine,
type AddGiftCardLineSuccess,
GetGiftCardInfo,
type GetGiftCardInfoSuccess,
GetUserCommentInfo,
type GetUserCommentInfoSuccess,
CompleteSurvey,
type CompleteSurveySuccess,
type CompleteSurveyRequestBody,
SurveyResponseIndex,
type SurveyResponseIndexSuccess,
type SurveyResponseIndexParams,
SurveyResponseCreate,
type SurveyResponseCreateSuccess,
type SurveyResponseCreateParams,
SurveyResponseUpdate,
type SurveyResponseUpdateSuccess,
type SurveyResponseUpdateRequestBody,
TermIndex,
type TermIndexSuccess,
Hide,
type HideSuccess,
TopBannerIndex,
type TopBannerIndexSuccess,
type TopBannerIndexParams,
TrackAdformClick,
type TrackAdformClickRequestBody,
TrackAdformImpressions,
type TrackAdformImpressionsRequestBody,
TrackEvent,
type TrackEventRequestBody,
TrackEnhancedEcommercePromotionImpressionEvent,
type TrackEnhancedEcommercePromotionImpressionEventRequestBody,
VisualSearchResultIndex,
type VisualSearchResultIndexRequestBody,
FollowBarCode,
type FollowBarCodeParams,
WrappingIndex,
type WrappingIndexSuccess,
type WrappingIndexParams,
DeleteAll,
type DeleteAllSuccess,
type DeleteAllParams,
_Update,
type _UpdateSuccess,
type _UpdateRequestBody} from './index'

        
        
    export const AdvisorModalQueryKey = 'all_AdvisorModal';

    export function useAdvisorModalQuery<TError = unknown>(params: AdvisorModalParams, options?: Omit<UseQueryOptions<AdvisorModalSuccess, TError, AdvisorModalSuccess, [string, AdvisorModalParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorModalSuccess | (() => AdvisorModalSuccess);},  baseQueryKey = AdvisorModalQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => AdvisorModal(params), options);
        }
  
    
        
    export const AdvisorChatIndexQueryKey = 'all_AdvisorChatIndex';

    export function useAdvisorChatIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<AdvisorChatIndexSuccess, TError, AdvisorChatIndexSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorChatIndexSuccess | (() => AdvisorChatIndexSuccess);},  baseQueryKey = AdvisorChatIndexQueryKey) {
            return useQuery([baseQueryKey, ],
            AdvisorChatIndex, options);
        }
  
    
        
    export const AdvisorChatPageQueryKey = 'all_AdvisorChatPage';

    export function useAdvisorChatPageQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = AdvisorChatPageQueryKey) {
            return useQuery([baseQueryKey, ],
            AdvisorChatPage, options);
        }
  
    
        
    export const ZowieSettingsQueryKey = 'all_ZowieSettings';

    export function useZowieSettingsQuery<TError = unknown>( options?: Omit<UseQueryOptions<ZowieSettingsSuccess, TError, ZowieSettingsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ZowieSettingsSuccess | (() => ZowieSettingsSuccess);},  baseQueryKey = ZowieSettingsQueryKey) {
            return useQuery([baseQueryKey, ],
            ZowieSettings, options);
        }
  
    
        
    export const AdvisorToolboxIndexQueryKey = 'all_AdvisorToolboxIndex';

    export function useAdvisorToolboxIndexQuery<TError = unknown>(params: AdvisorToolboxIndexParams, options?: Omit<UseQueryOptions<AdvisorToolboxIndexSuccess, TError, AdvisorToolboxIndexSuccess, [string, AdvisorToolboxIndexParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorToolboxIndexSuccess | (() => AdvisorToolboxIndexSuccess);},  baseQueryKey = AdvisorToolboxIndexQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => AdvisorToolboxIndex(params), options);
        }
  
    
        
    export const AdvisorToolboxPreviewQueryKey = 'all_AdvisorToolboxPreview';

    export function useAdvisorToolboxPreviewQuery<TError = unknown>( options?: Omit<UseQueryOptions<AdvisorToolboxPreviewSuccess, TError, AdvisorToolboxPreviewSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorToolboxPreviewSuccess | (() => AdvisorToolboxPreviewSuccess);},  baseQueryKey = AdvisorToolboxPreviewQueryKey) {
            return useQuery([baseQueryKey, ],
            AdvisorToolboxPreview, options);
        }
  
    
        
    export const BambuserGetProductsQueryKey = 'all_BambuserGetProducts';

    export function useBambuserGetProductsQuery<TError = unknown>(params: BambuserGetProductsParams, options?: Omit<UseQueryOptions<BambuserGetProductsSuccess, TError, BambuserGetProductsSuccess, [string, BambuserGetProductsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BambuserGetProductsSuccess | (() => BambuserGetProductsSuccess);},  baseQueryKey = BambuserGetProductsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => BambuserGetProducts(params), options);
        }
  
    
        
    export const GetBasketContentQueryKey = 'all_GetBasketContent';

    export function useGetBasketContentQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetBasketContentSuccess, TError, GetBasketContentSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetBasketContentSuccess | (() => GetBasketContentSuccess);},  baseQueryKey = GetBasketContentQueryKey) {
            return useQuery([baseQueryKey, ],
            GetBasketContent, options);
        }
  
    
        
    export const LookupUrlQueryKey = 'all_LookupUrl';

    export function useLookupUrlQuery<TError = unknown>(params: LookupUrlParams, options?: Omit<UseQueryOptions<LookupUrlSuccess, TError, LookupUrlSuccess, [string, LookupUrlParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: LookupUrlSuccess | (() => LookupUrlSuccess);},  baseQueryKey = LookupUrlQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => LookupUrl(params), options);
        }
  
    
        
    export const GetBasketQueryKey = 'all_GetBasket';

    export function useGetBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetBasketSuccess, TError, GetBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetBasketSuccess | (() => GetBasketSuccess);},  baseQueryKey = GetBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            GetBasket, options);
        }
  
    
        
    export const _BasketFooterQueryKey = 'all__BasketFooter';

    export function use_BasketFooterQuery<TError = unknown>( options?: Omit<UseQueryOptions<_BasketFooterSuccess, TError, _BasketFooterSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _BasketFooterSuccess | (() => _BasketFooterSuccess);},  baseQueryKey = _BasketFooterQueryKey) {
            return useQuery([baseQueryKey, ],
            _BasketFooter, options);
        }
  
    
        
    export const _ShippingOptionsQueryKey = 'all__ShippingOptions';

    export function use_ShippingOptionsQuery<TError = unknown>( options?: Omit<UseQueryOptions<_ShippingOptionsSuccess, TError, _ShippingOptionsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _ShippingOptionsSuccess | (() => _ShippingOptionsSuccess);},  baseQueryKey = _ShippingOptionsQueryKey) {
            return useQuery([baseQueryKey, ],
            _ShippingOptions, options);
        }
  
    
        
      export function useDeleteFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteFromBasketSuccess, TError, {params: DeleteFromBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeleteFromBasket(params, ), options)}
  
    
        
      export function useDeleteAllFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteAllFromBasketSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    DeleteAllFromBasket( ), options)}
  
    
        
      export function useDeleteGiftFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: DeleteGiftFromBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeleteGiftFromBasket(params, ), options)}
  
    
        
      export function useAddToBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddToBasketSuccess, TError, {params: AddToBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddToBasket(params, ), options)}
  
    
        
      export function useAddMultipleToBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddMultipleToBasketSuccess, TError, {params: AddMultipleToBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddMultipleToBasket(params, ), options)}
  
    
        
      export function useAddOrUpdateQuantityMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddOrUpdateQuantitySuccess, TError, {params: AddOrUpdateQuantityParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddOrUpdateQuantity(params, ), options)}
  
    
        
      export function useUpdateQuantityMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateQuantitySuccess, TError, {params: UpdateQuantityParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateQuantity(params, ), options)}
  
    
        
      export function useUpdateFrequencyMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateFrequencySuccess, TError, {params: UpdateFrequencyParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateFrequency(params, ), options)}
  
    
        
      export function useToggleSwapCertificatesMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ToggleSwapCertificatesSuccess, TError, {params: ToggleSwapCertificatesParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ToggleSwapCertificates(params, ), options)}
  
    
        
      export function useUpdateVoucherCodeMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateVoucherCodeSuccess, TError, {params: UpdateVoucherCodeParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateVoucherCode(params, ), options)}
  
    
        
      export function useRemoveVoucherMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RemoveVoucherSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    RemoveVoucher( ), options)}
  
    
        
    export const _BasketAddMinimumBuyQueryKey = 'all__BasketAddMinimumBuy';

    export function use_BasketAddMinimumBuyQuery<TError = unknown>(params: _BasketAddMinimumBuyParams, options?: Omit<UseQueryOptions<_BasketAddMinimumBuySuccess, TError, _BasketAddMinimumBuySuccess, [string, _BasketAddMinimumBuyParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _BasketAddMinimumBuySuccess | (() => _BasketAddMinimumBuySuccess);},  baseQueryKey = _BasketAddMinimumBuyQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _BasketAddMinimumBuy(params), options);
        }
  
    
        
      export function useAddDigitalProductMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddDigitalProductSuccess, TError, {params: AddDigitalProductParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddDigitalProduct(params, ), options)}
  
    
        
      export function useRemovePrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RemovePrizeCertificateSuccess, TError, {params: RemovePrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    RemovePrizeCertificate(params, ), options)}
  
    
        
      export function useBasketAddPrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<BasketAddPrizeCertificateSuccess, TError, {params: BasketAddPrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    BasketAddPrizeCertificate(params, ), options)}
  
    
        
    export const GetSampleSelectionQueryKey = 'all_GetSampleSelection';

    export function useGetSampleSelectionQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetSampleSelectionSuccess, TError, GetSampleSelectionSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetSampleSelectionSuccess | (() => GetSampleSelectionSuccess);},  baseQueryKey = GetSampleSelectionQueryKey) {
            return useQuery([baseQueryKey, ],
            GetSampleSelection, options);
        }
  
    
        
    export const ActionWillAffectSampleQueryKey = 'all_ActionWillAffectSample';

    export function useActionWillAffectSampleQuery<TError = unknown>(params: ActionWillAffectSampleParams, options?: Omit<UseQueryOptions<ActionWillAffectSampleSuccess, TError, ActionWillAffectSampleSuccess, [string, ActionWillAffectSampleParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ActionWillAffectSampleSuccess | (() => ActionWillAffectSampleSuccess);},  baseQueryKey = ActionWillAffectSampleQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ActionWillAffectSample(params), options);
        }
  
    
        
      export function useAddSampleToBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddSampleToBasketSuccess, TError, {params: AddSampleToBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddSampleToBasket(params, ), options)}
  
    
        
      export function useRemoveSampleFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RemoveSampleFromBasketSuccess, TError, {params: RemoveSampleFromBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    RemoveSampleFromBasket(params, ), options)}
  
    
        
      export function useReplaceSampleInBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ReplaceSampleInBasketSuccess, TError, {params: ReplaceSampleInBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ReplaceSampleInBasket(params, ), options)}
  
    
        
    export const GetPointInfoModelQueryKey = 'all_GetPointInfoModel';

    export function useGetPointInfoModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetPointInfoModelSuccess, TError, GetPointInfoModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetPointInfoModelSuccess | (() => GetPointInfoModelSuccess);},  baseQueryKey = GetPointInfoModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GetPointInfoModel, options);
        }
  
    
        
      export function useSignupForNotificationsMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SignupForNotificationsSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    SignupForNotifications( ), options)}
  
    
        
    export const GetNotifyMeModelsQueryKey = 'all_GetNotifyMeModels';

    export function useGetNotifyMeModelsQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetNotifyMeModelsSuccess, TError, GetNotifyMeModelsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetNotifyMeModelsSuccess | (() => GetNotifyMeModelsSuccess);},  baseQueryKey = GetNotifyMeModelsQueryKey) {
            return useQuery([baseQueryKey, ],
            GetNotifyMeModels, options);
        }
  
    
        
    export const GetFutureBookingsQueryKey = 'all_GetFutureBookings';

    export function useGetFutureBookingsQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetFutureBookingsSuccess, TError, GetFutureBookingsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetFutureBookingsSuccess | (() => GetFutureBookingsSuccess);},  baseQueryKey = GetFutureBookingsQueryKey) {
            return useQuery([baseQueryKey, ],
            GetFutureBookings, options);
        }
  
    
        
    export const GetPreviousBookingsQueryKey = 'all_GetPreviousBookings';

    export function useGetPreviousBookingsQuery<TError = unknown>(params: GetPreviousBookingsParams, options?: Omit<UseQueryOptions<GetPreviousBookingsSuccess, TError, GetPreviousBookingsSuccess, [string, GetPreviousBookingsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetPreviousBookingsSuccess | (() => GetPreviousBookingsSuccess);},  baseQueryKey = GetPreviousBookingsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetPreviousBookings(params), options);
        }
  
    
        
    export const GetBookingDetailsQueryKey = 'all_GetBookingDetails';

    export function useGetBookingDetailsQuery<TError = unknown>(params: GetBookingDetailsParams, options?: Omit<UseQueryOptions<GetBookingDetailsSuccess, TError, GetBookingDetailsSuccess, [string, GetBookingDetailsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetBookingDetailsSuccess | (() => GetBookingDetailsSuccess);},  baseQueryKey = GetBookingDetailsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetBookingDetails(params), options);
        }
  
    
        
      export function useCancelBookingMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CancelBookingSuccess, TError, {params: CancelBookingParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    CancelBooking(params, ), options)}
  
    
        
      export function useUpdateBookingMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateBookingSuccess, TError, {params: UpdateBookingParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateBooking(params, ), options)}
  
    
        
      export function useCreateFeedbackMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CreateFeedbackSuccess, TError, {requestBody: CreateFeedbackRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    CreateFeedback( requestBody), options)}
  
    
        
    export const GetTicketListPdfQueryKey = 'all_GetTicketListPdf';

    export function useGetTicketListPdfQuery<TError = unknown>(params: GetTicketListPdfParams, options?: Omit<UseQueryOptions<GetTicketListPdfSuccess, TError, GetTicketListPdfSuccess, [string, GetTicketListPdfParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetTicketListPdfSuccess | (() => GetTicketListPdfSuccess);},  baseQueryKey = GetTicketListPdfQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetTicketListPdf(params), options);
        }
  
    
        
    export const GetTicketPdfQueryKey = 'all_GetTicketPdf';

    export function useGetTicketPdfQuery<TError = unknown>(params: GetTicketPdfParams, options?: Omit<UseQueryOptions<GetTicketPdfSuccess, TError, GetTicketPdfSuccess, [string, GetTicketPdfParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetTicketPdfSuccess | (() => GetTicketPdfSuccess);},  baseQueryKey = GetTicketPdfQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetTicketPdf(params), options);
        }
  
    
        
    export const MyProfileBookingModelQueryKey = 'all_MyProfileBookingModel';

    export function useMyProfileBookingModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MyProfileBookingModelSuccess, TError, MyProfileBookingModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyProfileBookingModelSuccess | (() => MyProfileBookingModelSuccess);},  baseQueryKey = MyProfileBookingModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MyProfileBookingModel, options);
        }
  
    
        
    export const MyProfileBookingPageViewModelQueryKey = 'all_MyProfileBookingPageViewModel';

    export function useMyProfileBookingPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MyProfileBookingPageViewModelSuccess, TError, MyProfileBookingPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyProfileBookingPageViewModelSuccess | (() => MyProfileBookingPageViewModelSuccess);},  baseQueryKey = MyProfileBookingPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MyProfileBookingPageViewModel, options);
        }
  
    
        
    export const MyProfileBookingDetailsPageViewModelQueryKey = 'all_MyProfileBookingDetailsPageViewModel';

    export function useMyProfileBookingDetailsPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MyProfileBookingDetailsPageViewModelSuccess, TError, MyProfileBookingDetailsPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyProfileBookingDetailsPageViewModelSuccess | (() => MyProfileBookingDetailsPageViewModelSuccess);},  baseQueryKey = MyProfileBookingDetailsPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MyProfileBookingDetailsPageViewModel, options);
        }
  
    
        
    export const BrandListModelQueryKey = 'all_BrandListModel';

    export function useBrandListModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BrandListModelSuccess, TError, BrandListModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BrandListModelSuccess | (() => BrandListModelSuccess);},  baseQueryKey = BrandListModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BrandListModel, options);
        }
  
    
        
    export const _NewsQueryKey = 'all__News';

    export function use_NewsQuery<TError = unknown>(params: _NewsParams, options?: Omit<UseQueryOptions<_NewsSuccess, TError, _NewsSuccess, [string, _NewsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _NewsSuccess | (() => _NewsSuccess);},  baseQueryKey = _NewsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _News(params), options);
        }
  
    
        
    export const BrandClubPageModelQueryKey = 'all_BrandClubPageModel';

    export function useBrandClubPageModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BrandClubPageModelSuccess, TError, BrandClubPageModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BrandClubPageModelSuccess | (() => BrandClubPageModelSuccess);},  baseQueryKey = BrandClubPageModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BrandClubPageModel, options);
        }
  
    
        
    export const BrandClubFrontPageModelQueryKey = 'all_BrandClubFrontPageModel';

    export function useBrandClubFrontPageModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BrandClubFrontPageModelSuccess, TError, BrandClubFrontPageModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BrandClubFrontPageModelSuccess | (() => BrandClubFrontPageModelSuccess);},  baseQueryKey = BrandClubFrontPageModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BrandClubFrontPageModel, options);
        }
  
    
        
    export const BrandClubBonusPageModelQueryKey = 'all_BrandClubBonusPageModel';

    export function useBrandClubBonusPageModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BrandClubBonusPageModelSuccess, TError, BrandClubBonusPageModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BrandClubBonusPageModelSuccess | (() => BrandClubBonusPageModelSuccess);},  baseQueryKey = BrandClubBonusPageModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BrandClubBonusPageModel, options);
        }
  
    
        
    export const BrandClubSignupModelQueryKey = 'all_BrandClubSignupModel';

    export function useBrandClubSignupModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BrandClubSignupModelSuccess, TError, BrandClubSignupModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BrandClubSignupModelSuccess | (() => BrandClubSignupModelSuccess);},  baseQueryKey = BrandClubSignupModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BrandClubSignupModel, options);
        }
  
    
        
    export const BrandClubMessageModelQueryKey = 'all_BrandClubMessageModel';

    export function useBrandClubMessageModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BrandClubMessageModelSuccess, TError, BrandClubMessageModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BrandClubMessageModelSuccess | (() => BrandClubMessageModelSuccess);},  baseQueryKey = BrandClubMessageModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BrandClubMessageModel, options);
        }
  
    
        
    export const BrandClubPageViewModelQueryKey = 'all_BrandClubPageViewModel';

    export function useBrandClubPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BrandClubPageViewModelSuccess, TError, BrandClubPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BrandClubPageViewModelSuccess | (() => BrandClubPageViewModelSuccess);},  baseQueryKey = BrandClubPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BrandClubPageViewModel, options);
        }
  
    
        
    export const CampaignInfoDetailsByDiscountIdQueryKey = 'all_CampaignInfoDetailsByDiscountId';

    export function useCampaignInfoDetailsByDiscountIdQuery<TError = unknown>(params: CampaignInfoDetailsByDiscountIdParams, options?: Omit<UseQueryOptions<CampaignInfoDetailsByDiscountIdSuccess, TError, CampaignInfoDetailsByDiscountIdSuccess, [string, CampaignInfoDetailsByDiscountIdParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CampaignInfoDetailsByDiscountIdSuccess | (() => CampaignInfoDetailsByDiscountIdSuccess);},  baseQueryKey = CampaignInfoDetailsByDiscountIdQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => CampaignInfoDetailsByDiscountId(params), options);
        }
  
    
        
    export const CampaignInfoDetailsByProductIdQueryKey = 'all_CampaignInfoDetailsByProductId';

    export function useCampaignInfoDetailsByProductIdQuery<TError = unknown>(params: CampaignInfoDetailsByProductIdParams, options?: Omit<UseQueryOptions<CampaignInfoDetailsByProductIdSuccess, TError, CampaignInfoDetailsByProductIdSuccess, [string, CampaignInfoDetailsByProductIdParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CampaignInfoDetailsByProductIdSuccess | (() => CampaignInfoDetailsByProductIdSuccess);},  baseQueryKey = CampaignInfoDetailsByProductIdQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => CampaignInfoDetailsByProductId(params), options);
        }
  
    
        
    export const CampaignInfoByProductIdQueryKey = 'all_CampaignInfoByProductId';

    export function useCampaignInfoByProductIdQuery<TError = unknown>(params: CampaignInfoByProductIdParams, options?: Omit<UseQueryOptions<CampaignInfoByProductIdSuccess, TError, CampaignInfoByProductIdSuccess, [string, CampaignInfoByProductIdParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CampaignInfoByProductIdSuccess | (() => CampaignInfoByProductIdSuccess);},  baseQueryKey = CampaignInfoByProductIdQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => CampaignInfoByProductId(params), options);
        }
  
    
        
    export const GetUspListModelQueryKey = 'all_GetUspListModel';

    export function useGetUspListModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetUspListModelSuccess, TError, GetUspListModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetUspListModelSuccess | (() => GetUspListModelSuccess);},  baseQueryKey = GetUspListModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GetUspListModel, options);
        }
  
    
        
    export const FullwidthQueryKey = 'all_Fullwidth';

    export function useFullwidthQuery<TError = unknown>( options?: Omit<UseQueryOptions<FullwidthSuccess, TError, FullwidthSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: FullwidthSuccess | (() => FullwidthSuccess);},  baseQueryKey = FullwidthQueryKey) {
            return useQuery([baseQueryKey, ],
            Fullwidth, options);
        }
  
    
        
    export const InlineQueryKey = 'all_Inline';

    export function useInlineQuery<TError = unknown>( options?: Omit<UseQueryOptions<InlineSuccess, TError, InlineSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: InlineSuccess | (() => InlineSuccess);},  baseQueryKey = InlineQueryKey) {
            return useQuery([baseQueryKey, ],
            Inline, options);
        }
  
    
        
    export const PdpQueryKey = 'all_Pdp';

    export function usePdpQuery<TError = unknown>( options?: Omit<UseQueryOptions<PdpSuccess, TError, PdpSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PdpSuccess | (() => PdpSuccess);},  baseQueryKey = PdpQueryKey) {
            return useQuery([baseQueryKey, ],
            Pdp, options);
        }
  
    
        
      export function useAcceptTermsMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AcceptTermsSuccess, TError, {params: AcceptTermsParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AcceptTerms(params, ), options)}
  
    
        
    export const SearchBookingSlotsQueryKey = 'all_SearchBookingSlots';

    export function useSearchBookingSlotsQuery<TError = unknown>(params: SearchBookingSlotsParams, options?: Omit<UseQueryOptions<SearchBookingSlotsSuccess, TError, SearchBookingSlotsSuccess, [string, SearchBookingSlotsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SearchBookingSlotsSuccess | (() => SearchBookingSlotsSuccess);},  baseQueryKey = SearchBookingSlotsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SearchBookingSlots(params), options);
        }
  
    
        
    export const SearchBookingSlotByLocationQueryKey = 'all_SearchBookingSlotByLocation';

    export function useSearchBookingSlotByLocationQuery<TError = unknown>(params: SearchBookingSlotByLocationParams, options?: Omit<UseQueryOptions<SearchBookingSlotByLocationSuccess, TError, SearchBookingSlotByLocationSuccess, [string, SearchBookingSlotByLocationParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SearchBookingSlotByLocationSuccess | (() => SearchBookingSlotByLocationSuccess);},  baseQueryKey = SearchBookingSlotByLocationQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SearchBookingSlotByLocation(params), options);
        }
  
    
        
    export const GetBookingOverviewQueryKey = 'all_GetBookingOverview';

    export function useGetBookingOverviewQuery<TError = unknown>(params: GetBookingOverviewParams, options?: Omit<UseQueryOptions<GetBookingOverviewSuccess, TError, GetBookingOverviewSuccess, [string, GetBookingOverviewParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetBookingOverviewSuccess | (() => GetBookingOverviewSuccess);},  baseQueryKey = GetBookingOverviewQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetBookingOverview(params), options);
        }
  
    
        
      export function useBookMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<BookSuccess, TError, {params: BookParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    Book(params, ), options)}
  
    
        
    export const GetBookingEarlyStageQueryKey = 'all_GetBookingEarlyStage';

    export function useGetBookingEarlyStageQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = GetBookingEarlyStageQueryKey) {
            return useQuery([baseQueryKey, ],
            GetBookingEarlyStage, options);
        }
  
    
        
    export const GetTotalTicketPriceQueryKey = 'all_GetTotalTicketPrice';

    export function useGetTotalTicketPriceQuery<TError = unknown>(params: GetTotalTicketPriceParams, options?: Omit<UseQueryOptions<GetTotalTicketPriceSuccess, TError, GetTotalTicketPriceSuccess, [string, GetTotalTicketPriceParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetTotalTicketPriceSuccess | (() => GetTotalTicketPriceSuccess);},  baseQueryKey = GetTotalTicketPriceQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetTotalTicketPrice(params), options);
        }
  
    
        
    export const BookingReceiptModelQueryKey = 'all_BookingReceiptModel';

    export function useBookingReceiptModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BookingReceiptModelSuccess, TError, BookingReceiptModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BookingReceiptModelSuccess | (() => BookingReceiptModelSuccess);},  baseQueryKey = BookingReceiptModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BookingReceiptModel, options);
        }
  
    
        
    export const BookRequestModelQueryKey = 'all_BookRequestModel';

    export function useBookRequestModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BookRequestModelSuccess, TError, BookRequestModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BookRequestModelSuccess | (() => BookRequestModelSuccess);},  baseQueryKey = BookRequestModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BookRequestModel, options);
        }
  
    
        
    export const BookingPosJournalModelQueryKey = 'all_BookingPosJournalModel';

    export function useBookingPosJournalModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BookingPosJournalModelSuccess, TError, BookingPosJournalModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BookingPosJournalModelSuccess | (() => BookingPosJournalModelSuccess);},  baseQueryKey = BookingPosJournalModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BookingPosJournalModel, options);
        }
  
    
        
    export const ListConsultationProductsQueryKey = 'all_ListConsultationProducts';

    export function useListConsultationProductsQuery<TError = unknown>(params: ListConsultationProductsParams, options?: Omit<UseQueryOptions<ListConsultationProductsSuccess, TError, ListConsultationProductsSuccess, [string, ListConsultationProductsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListConsultationProductsSuccess | (() => ListConsultationProductsSuccess);},  baseQueryKey = ListConsultationProductsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListConsultationProducts(params), options);
        }
  
    
        
    export const ConsultationProductPageModelQueryKey = 'all_ConsultationProductPageModel';

    export function useConsultationProductPageModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ConsultationProductPageModelSuccess, TError, ConsultationProductPageModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ConsultationProductPageModelSuccess | (() => ConsultationProductPageModelSuccess);},  baseQueryKey = ConsultationProductPageModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ConsultationProductPageModel, options);
        }
  
    
        
    export const ConsultationProductListPageContentViewModelQueryKey = 'all_ConsultationProductListPageContentViewModel';

    export function useConsultationProductListPageContentViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ConsultationProductListPageContentViewModelSuccess, TError, ConsultationProductListPageContentViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ConsultationProductListPageContentViewModelSuccess | (() => ConsultationProductListPageContentViewModelSuccess);},  baseQueryKey = ConsultationProductListPageContentViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ConsultationProductListPageContentViewModel, options);
        }
  
    
        
    export const ChangeCreditCardChoiceQueryKey = 'all_ChangeCreditCardChoice';

    export function useChangeCreditCardChoiceQuery<TError = unknown>(params: ChangeCreditCardChoiceParams, options?: Omit<UseQueryOptions<ChangeCreditCardChoiceSuccess, TError, ChangeCreditCardChoiceSuccess, [string, ChangeCreditCardChoiceParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ChangeCreditCardChoiceSuccess | (() => ChangeCreditCardChoiceSuccess);},  baseQueryKey = ChangeCreditCardChoiceQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ChangeCreditCardChoice(params), options);
        }
  
    
        
    export const _CustomizeProductQueryKey = 'all__CustomizeProduct';

    export function use_CustomizeProductQuery<TError = unknown>(params: _CustomizeProductParams, options?: Omit<UseQueryOptions<_CustomizeProductSuccess, TError, _CustomizeProductSuccess, [string, _CustomizeProductParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _CustomizeProductSuccess | (() => _CustomizeProductSuccess);},  baseQueryKey = _CustomizeProductQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _CustomizeProduct(params), options);
        }
  
    
        
      export function useAddCustomProductToBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddCustomProductToBasketSuccess, TError, {params: AddCustomProductToBasketParams, requestBody: AddCustomProductToBasketRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, requestBody}) =>
    AddCustomProductToBasket(params, requestBody), options)}
  
    
        
    export const CustomizeDigitalGiftCardModelQueryKey = 'all_CustomizeDigitalGiftCardModel';

    export function useCustomizeDigitalGiftCardModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CustomizeDigitalGiftCardModelSuccess, TError, CustomizeDigitalGiftCardModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CustomizeDigitalGiftCardModelSuccess | (() => CustomizeDigitalGiftCardModelSuccess);},  baseQueryKey = CustomizeDigitalGiftCardModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CustomizeDigitalGiftCardModel, options);
        }
  
    
        
    export const PrizeCertificatesQueryKey = 'all_PrizeCertificates';

    export function usePrizeCertificatesQuery<TError = unknown>( options?: Omit<UseQueryOptions<PrizeCertificatesSuccess, TError, PrizeCertificatesSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PrizeCertificatesSuccess | (() => PrizeCertificatesSuccess);},  baseQueryKey = PrizeCertificatesQueryKey) {
            return useQuery([baseQueryKey, ],
            PrizeCertificates, options);
        }
  
    
        
      export function useAddPrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddPrizeCertificateSuccess, TError, {params: AddPrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddPrizeCertificate(params, ), options)}
  
    
        
      export function useDeliveryRemovePrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeliveryRemovePrizeCertificateSuccess, TError, {params: DeliveryRemovePrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeliveryRemovePrizeCertificate(params, ), options)}
  
    
        
    export const CheckoutDeliveryBasketQueryKey = 'all_CheckoutDeliveryBasket';

    export function useCheckoutDeliveryBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryBasketSuccess, TError, CheckoutDeliveryBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryBasketSuccess | (() => CheckoutDeliveryBasketSuccess);},  baseQueryKey = CheckoutDeliveryBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryBasket, options);
        }
  
    
        
    export const GetCityFromZipCodeQueryKey = 'all_GetCityFromZipCode';

    export function useGetCityFromZipCodeQuery<TError = unknown>(params: GetCityFromZipCodeParams, options?: Omit<UseQueryOptions<GetCityFromZipCodeSuccess, TError, GetCityFromZipCodeSuccess, [string, GetCityFromZipCodeParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetCityFromZipCodeSuccess | (() => GetCityFromZipCodeSuccess);},  baseQueryKey = GetCityFromZipCodeQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetCityFromZipCode(params), options);
        }
  
    
        
    export const GetDeliveryGroupsQueryKey = 'all_GetDeliveryGroups';

    export function useGetDeliveryGroupsQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetDeliveryGroupsSuccess, TError, GetDeliveryGroupsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetDeliveryGroupsSuccess | (() => GetDeliveryGroupsSuccess);},  baseQueryKey = GetDeliveryGroupsQueryKey) {
            return useQuery([baseQueryKey, ],
            GetDeliveryGroups, options);
        }
  
    
        
    export const GetDeliveryGroupQueryKey = 'all_GetDeliveryGroup';

    export function useGetDeliveryGroupQuery<TError = unknown>(params: GetDeliveryGroupParams, options?: Omit<UseQueryOptions<GetDeliveryGroupSuccess, TError, GetDeliveryGroupSuccess, [string, GetDeliveryGroupParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetDeliveryGroupSuccess | (() => GetDeliveryGroupSuccess);},  baseQueryKey = GetDeliveryGroupQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetDeliveryGroup(params), options);
        }
  
    
        
      export function useUpdateInvoiceAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateInvoiceAddressSuccess, TError, {requestBody: UpdateInvoiceAddressRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    UpdateInvoiceAddress( requestBody), options)}
  
    
        
      export function useSelectPrivateDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectPrivateDeliverySuccess, TError, {params: SelectPrivateDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectPrivateDelivery(params, ), options)}
  
    
        
      export function useUpdatePrivateDeliveryAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdatePrivateDeliveryAddressSuccess, TError, {requestBody: UpdatePrivateDeliveryAddressRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    UpdatePrivateDeliveryAddress( requestBody), options)}
  
    
        
      export function useSelectAndUpdateCompanyDeliveryAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectAndUpdateCompanyDeliveryAddressSuccess, TError, {requestBody: SelectAndUpdateCompanyDeliveryAddressRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    SelectAndUpdateCompanyDeliveryAddress( requestBody), options)}
  
    
        
      export function useSelectParcelShopDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectParcelShopDeliverySuccess, TError, {params: SelectParcelShopDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectParcelShopDelivery(params, ), options)}
  
    
        
      export function useSelectAndUpdateParcelShopDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectAndUpdateParcelShopDeliverySuccess, TError, {requestBody: SelectAndUpdateParcelShopDeliveryRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    SelectAndUpdateParcelShopDelivery( requestBody), options)}
  
    
        
    export const ListParcelShopsQueryKey = 'all_ListParcelShops';

    export function useListParcelShopsQuery<TError = unknown>(params: ListParcelShopsParams, options?: Omit<UseQueryOptions<ListParcelShopsSuccess, TError, ListParcelShopsSuccess, [string, ListParcelShopsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListParcelShopsSuccess | (() => ListParcelShopsSuccess);},  baseQueryKey = ListParcelShopsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListParcelShops(params), options);
        }
  
    
        
    export const ListParcelShopsByAddressQueryKey = 'all_ListParcelShopsByAddress';

    export function useListParcelShopsByAddressQuery<TError = unknown>(params: ListParcelShopsByAddressParams, options?: Omit<UseQueryOptions<ListParcelShopsByAddressSuccess, TError, ListParcelShopsByAddressSuccess, [string, ListParcelShopsByAddressParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListParcelShopsByAddressSuccess | (() => ListParcelShopsByAddressSuccess);},  baseQueryKey = ListParcelShopsByAddressQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListParcelShopsByAddress(params), options);
        }
  
    
        
    export const ListParcelShopsByCoordinatesQueryKey = 'all_ListParcelShopsByCoordinates';

    export function useListParcelShopsByCoordinatesQuery<TError = unknown>(params: ListParcelShopsByCoordinatesParams, options?: Omit<UseQueryOptions<ListParcelShopsByCoordinatesSuccess, TError, ListParcelShopsByCoordinatesSuccess, [string, ListParcelShopsByCoordinatesParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListParcelShopsByCoordinatesSuccess | (() => ListParcelShopsByCoordinatesSuccess);},  baseQueryKey = ListParcelShopsByCoordinatesQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListParcelShopsByCoordinates(params), options);
        }
  
    
        
      export function useSelectMatasDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectMatasDeliverySuccess, TError, {params: SelectMatasDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectMatasDelivery(params, ), options)}
  
    
        
      export function useSelectAndUpdateMatasDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectAndUpdateMatasDeliverySuccess, TError, {requestBody: SelectAndUpdateMatasDeliveryRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    SelectAndUpdateMatasDelivery( requestBody), options)}
  
    
        
    export const ListMatasParcelShopsQueryKey = 'all_ListMatasParcelShops';

    export function useListMatasParcelShopsQuery<TError = unknown>(params: ListMatasParcelShopsParams, options?: Omit<UseQueryOptions<ListMatasParcelShopsSuccess, TError, ListMatasParcelShopsSuccess, [string, ListMatasParcelShopsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListMatasParcelShopsSuccess | (() => ListMatasParcelShopsSuccess);},  baseQueryKey = ListMatasParcelShopsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListMatasParcelShops(params), options);
        }
  
    
        
    export const ListMatasParcelShopsByAddressQueryKey = 'all_ListMatasParcelShopsByAddress';

    export function useListMatasParcelShopsByAddressQuery<TError = unknown>(params: ListMatasParcelShopsByAddressParams, options?: Omit<UseQueryOptions<ListMatasParcelShopsByAddressSuccess, TError, ListMatasParcelShopsByAddressSuccess, [string, ListMatasParcelShopsByAddressParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListMatasParcelShopsByAddressSuccess | (() => ListMatasParcelShopsByAddressSuccess);},  baseQueryKey = ListMatasParcelShopsByAddressQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListMatasParcelShopsByAddress(params), options);
        }
  
    
        
    export const ListMatasParcelShopsByCoordinatesQueryKey = 'all_ListMatasParcelShopsByCoordinates';

    export function useListMatasParcelShopsByCoordinatesQuery<TError = unknown>(params: ListMatasParcelShopsByCoordinatesParams, options?: Omit<UseQueryOptions<ListMatasParcelShopsByCoordinatesSuccess, TError, ListMatasParcelShopsByCoordinatesSuccess, [string, ListMatasParcelShopsByCoordinatesParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListMatasParcelShopsByCoordinatesSuccess | (() => ListMatasParcelShopsByCoordinatesSuccess);},  baseQueryKey = ListMatasParcelShopsByCoordinatesQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListMatasParcelShopsByCoordinates(params), options);
        }
  
    
        
      export function useSelectUpsaleDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SelectUpsaleDeliverySuccess, TError, {params: SelectUpsaleDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SelectUpsaleDelivery(params, ), options)}
  
    
        
    export const ToggleNonurgentDeliveryQueryKey = 'all_ToggleNonurgentDelivery';

    export function useToggleNonurgentDeliveryQuery<TError = unknown>(params: ToggleNonurgentDeliveryParams, options?: Omit<UseQueryOptions<ToggleNonurgentDeliverySuccess, TError, ToggleNonurgentDeliverySuccess, [string, ToggleNonurgentDeliveryParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ToggleNonurgentDeliverySuccess | (() => ToggleNonurgentDeliverySuccess);},  baseQueryKey = ToggleNonurgentDeliveryQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ToggleNonurgentDelivery(params), options);
        }
  
    
        
    export const ProgressiveDiscountForProductQueryKey = 'all_ProgressiveDiscountForProduct';

    export function useProgressiveDiscountForProductQuery<TError = unknown>(params: ProgressiveDiscountForProductParams, options?: Omit<UseQueryOptions<ProgressiveDiscountForProductSuccess, TError, ProgressiveDiscountForProductSuccess, [string, ProgressiveDiscountForProductParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProgressiveDiscountForProductSuccess | (() => ProgressiveDiscountForProductSuccess);},  baseQueryKey = ProgressiveDiscountForProductQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ProgressiveDiscountForProduct(params), options);
        }
  
    
        
    export const ProgressiveDiscountDetailsForProductQueryKey = 'all_ProgressiveDiscountDetailsForProduct';

    export function useProgressiveDiscountDetailsForProductQuery<TError = unknown>(params: ProgressiveDiscountDetailsForProductParams, options?: Omit<UseQueryOptions<ProgressiveDiscountDetailsForProductSuccess, TError, ProgressiveDiscountDetailsForProductSuccess, [string, ProgressiveDiscountDetailsForProductParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProgressiveDiscountDetailsForProductSuccess | (() => ProgressiveDiscountDetailsForProductSuccess);},  baseQueryKey = ProgressiveDiscountDetailsForProductQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ProgressiveDiscountDetailsForProduct(params), options);
        }
  
    
        
      export function useSetZipCodeMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SetZipCodeSuccess, TError, {params: SetZipCodeParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SetZipCode(params, ), options)}
  
    
        
      export function useSetZipCodeByCoordinatesMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SetZipCodeByCoordinatesSuccess, TError, {params: SetZipCodeByCoordinatesParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SetZipCodeByCoordinates(params, ), options)}
  
    
        
    export const GetExpectedDeliverySummaryForBasketQueryKey = 'all_GetExpectedDeliverySummaryForBasket';

    export function useGetExpectedDeliverySummaryForBasketQuery<TError = unknown>(params: GetExpectedDeliverySummaryForBasketParams, options?: Omit<UseQueryOptions<GetExpectedDeliverySummaryForBasketSuccess, TError, GetExpectedDeliverySummaryForBasketSuccess, [string, GetExpectedDeliverySummaryForBasketParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliverySummaryForBasketSuccess | (() => GetExpectedDeliverySummaryForBasketSuccess);},  baseQueryKey = GetExpectedDeliverySummaryForBasketQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliverySummaryForBasket(params), options);
        }
  
    
        
    export const GetExpectedDeliverySummaryForProductPageQueryKey = 'all_GetExpectedDeliverySummaryForProductPage';

    export function useGetExpectedDeliverySummaryForProductPageQuery<TError = unknown>(params: GetExpectedDeliverySummaryForProductPageParams, options?: Omit<UseQueryOptions<GetExpectedDeliverySummaryForProductPageSuccess, TError, GetExpectedDeliverySummaryForProductPageSuccess, [string, GetExpectedDeliverySummaryForProductPageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliverySummaryForProductPageSuccess | (() => GetExpectedDeliverySummaryForProductPageSuccess);},  baseQueryKey = GetExpectedDeliverySummaryForProductPageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliverySummaryForProductPage(params), options);
        }
  
    
        
    export const GetExpectedDeliveryFullInformationQueryKey = 'all_GetExpectedDeliveryFullInformation';

    export function useGetExpectedDeliveryFullInformationQuery<TError = unknown>(params: GetExpectedDeliveryFullInformationParams, options?: Omit<UseQueryOptions<GetExpectedDeliveryFullInformationSuccess, TError, GetExpectedDeliveryFullInformationSuccess, [string, GetExpectedDeliveryFullInformationParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliveryFullInformationSuccess | (() => GetExpectedDeliveryFullInformationSuccess);},  baseQueryKey = GetExpectedDeliveryFullInformationQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliveryFullInformation(params), options);
        }
  
    
        
    export const GetExpectedDeliveryInformationForProductPageQueryKey = 'all_GetExpectedDeliveryInformationForProductPage';

    export function useGetExpectedDeliveryInformationForProductPageQuery<TError = unknown>(params: GetExpectedDeliveryInformationForProductPageParams, options?: Omit<UseQueryOptions<GetExpectedDeliveryInformationForProductPageSuccess, TError, GetExpectedDeliveryInformationForProductPageSuccess, [string, GetExpectedDeliveryInformationForProductPageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliveryInformationForProductPageSuccess | (() => GetExpectedDeliveryInformationForProductPageSuccess);},  baseQueryKey = GetExpectedDeliveryInformationForProductPageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliveryInformationForProductPage(params), options);
        }
  
    
        
    export const GetExpectedDeliveryInformationForBasketQueryKey = 'all_GetExpectedDeliveryInformationForBasket';

    export function useGetExpectedDeliveryInformationForBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetExpectedDeliveryInformationForBasketSuccess, TError, GetExpectedDeliveryInformationForBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliveryInformationForBasketSuccess | (() => GetExpectedDeliveryInformationForBasketSuccess);},  baseQueryKey = GetExpectedDeliveryInformationForBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            GetExpectedDeliveryInformationForBasket, options);
        }
  
    
        
      export function useUpdateStatusMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateStatusSuccess, TError, {params: UpdateStatusParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateStatus(params, ), options)}
  
    
        
    export const FavoriteGetQueryKey = 'all_FavoriteGet';

    export function useFavoriteGetQuery<TError = unknown>(params: FavoriteGetParams, options?: Omit<UseQueryOptions<FavoriteGetSuccess, TError, FavoriteGetSuccess, [string, FavoriteGetParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: FavoriteGetSuccess | (() => FavoriteGetSuccess);},  baseQueryKey = FavoriteGetQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => FavoriteGet(params), options);
        }
  
    
        
    export const GetBrandsQueryKey = 'all_GetBrands';

    export function useGetBrandsQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetBrandsSuccess, TError, GetBrandsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetBrandsSuccess | (() => GetBrandsSuccess);},  baseQueryKey = GetBrandsQueryKey) {
            return useQuery([baseQueryKey, ],
            GetBrands, options);
        }
  
    
        
    export const FindationGetProductsQueryKey = 'all_FindationGetProducts';

    export function useFindationGetProductsQuery<TError = unknown>(params: FindationGetProductsParams, options?: Omit<UseQueryOptions<FindationGetProductsSuccess, TError, FindationGetProductsSuccess, [string, FindationGetProductsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: FindationGetProductsSuccess | (() => FindationGetProductsSuccess);},  baseQueryKey = FindationGetProductsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => FindationGetProducts(params), options);
        }
  
    
        
    export const GetShadesQueryKey = 'all_GetShades';

    export function useGetShadesQuery<TError = unknown>(params: GetShadesParams, options?: Omit<UseQueryOptions<GetShadesSuccess, TError, GetShadesSuccess, [string, GetShadesParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetShadesSuccess | (() => GetShadesSuccess);},  baseQueryKey = GetShadesQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetShades(params), options);
        }
  
    
        
      export function useFindationSearchMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<FindationSearchSuccess, TError, {requestBody: FindationSearchRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    FindationSearch( requestBody), options)}
  
    
        
      export function useUpdateGranularMailCampaignPermissionsMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateGranularMailCampaignPermissionsSuccess, TError, {params: UpdateGranularMailCampaignPermissionsParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateGranularMailCampaignPermissions(params, ), options)}
  
    
        
      export function useRegisterReceiptMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RegisterReceiptSuccess, TError, {requestBody: RegisterReceiptRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    RegisterReceipt( requestBody), options)}
  
    
        
      export function useRegisterBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RegisterBasketSuccess, TError, {params: RegisterBasketParams, requestBody: RegisterBasketRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, requestBody}) =>
    RegisterBasket(params, requestBody), options)}
  
    
        
    export const ToggleInPageNotificationQueryKey = 'all_ToggleInPageNotification';

    export function useToggleInPageNotificationQuery<TError = unknown>(params: ToggleInPageNotificationParams, options?: Omit<UseQueryOptions<ToggleInPageNotificationSuccess, TError, ToggleInPageNotificationSuccess, [string, ToggleInPageNotificationParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ToggleInPageNotificationSuccess | (() => ToggleInPageNotificationSuccess);},  baseQueryKey = ToggleInPageNotificationQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ToggleInPageNotification(params), options);
        }
  
    
        
    export const InspirationMatasLivePageViewModelQueryKey = 'all_InspirationMatasLivePageViewModel';

    export function useInspirationMatasLivePageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<InspirationMatasLivePageViewModelSuccess, TError, InspirationMatasLivePageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: InspirationMatasLivePageViewModelSuccess | (() => InspirationMatasLivePageViewModelSuccess);},  baseQueryKey = InspirationMatasLivePageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            InspirationMatasLivePageViewModel, options);
        }
  
    
        
    export const GetQueryKey = 'all_Get';

    export function useGetQuery<TError = unknown>(params: GetParams, options?: Omit<UseQueryOptions<GetSuccess, TError, GetSuccess, [string, GetParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetSuccess | (() => GetSuccess);},  baseQueryKey = GetQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => Get(params), options);
        }
  
    
        
      export function useHideCountdownBannerMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<HideCountdownBannerSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    HideCountdownBanner( ), options)}
  
    
        
      export function useCountDownBannerModelMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CountDownBannerModelSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    CountDownBannerModel( ), options)}
  
    
        
      export function useUnsubscribeAllMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UnsubscribeAllSuccess, TError, {params: UnsubscribeAllParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UnsubscribeAll(params, ), options)}
  
    
        
    export const GetEventSubscriberInfoQueryKey = 'all_GetEventSubscriberInfo';

    export function useGetEventSubscriberInfoQuery<TError = unknown>(params: GetEventSubscriberInfoParams, options?: Omit<UseQueryOptions<GetEventSubscriberInfoSuccess, TError, GetEventSubscriberInfoSuccess, [string, GetEventSubscriberInfoParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetEventSubscriberInfoSuccess | (() => GetEventSubscriberInfoSuccess);},  baseQueryKey = GetEventSubscriberInfoQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetEventSubscriberInfo(params), options);
        }
  
    
        
      export function useAddEventNotificationMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: AddEventNotificationRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    AddEventNotification( requestBody), options)}
  
    
        
    export const GetLiveEventStatusModelQueryKey = 'all_GetLiveEventStatusModel';

    export function useGetLiveEventStatusModelQuery<TError = unknown>(params: GetLiveEventStatusModelParams, options?: Omit<UseQueryOptions<GetLiveEventStatusModelSuccess, TError, GetLiveEventStatusModelSuccess, [string, GetLiveEventStatusModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetLiveEventStatusModelSuccess | (() => GetLiveEventStatusModelSuccess);},  baseQueryKey = GetLiveEventStatusModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetLiveEventStatusModel(params), options);
        }
  
    
        
    export const LiveEventListViewModelQueryKey = 'all_LiveEventListViewModel';

    export function useLiveEventListViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<LiveEventListViewModelSuccess, TError, LiveEventListViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: LiveEventListViewModelSuccess | (() => LiveEventListViewModelSuccess);},  baseQueryKey = LiveEventListViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            LiveEventListViewModel, options);
        }
  
    
        
    export const LiveEventFilteredResultViewModelQueryKey = 'all_LiveEventFilteredResultViewModel';

    export function useLiveEventFilteredResultViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<LiveEventFilteredResultViewModelSuccess, TError, LiveEventFilteredResultViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: LiveEventFilteredResultViewModelSuccess | (() => LiveEventFilteredResultViewModelSuccess);},  baseQueryKey = LiveEventFilteredResultViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            LiveEventFilteredResultViewModel, options);
        }
  
    
        
      export function useLogPosEventMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: LogPosEventRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    LogPosEvent( requestBody), options)}
  
    
        
    export const _LoginInfoQueryKey = 'all__LoginInfo';

    export function use_LoginInfoQuery<TError = unknown>(params: _LoginInfoParams, options?: Omit<UseQueryOptions<_LoginInfoSuccess, TError, _LoginInfoSuccess, [string, _LoginInfoParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _LoginInfoSuccess | (() => _LoginInfoSuccess);},  baseQueryKey = _LoginInfoQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _LoginInfo(params), options);
        }
  
    
        
    export const MatasPlusRecommendationsQueryKey = 'all_MatasPlusRecommendations';

    export function useMatasPlusRecommendationsQuery<TError = unknown>( options?: Omit<UseQueryOptions<MatasPlusRecommendationsSuccess, TError, MatasPlusRecommendationsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusRecommendationsSuccess | (() => MatasPlusRecommendationsSuccess);},  baseQueryKey = MatasPlusRecommendationsQueryKey) {
            return useQuery([baseQueryKey, ],
            MatasPlusRecommendations, options);
        }
  
    
        
      export function useChangeSubscriptionTypeMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ChangeSubscriptionTypeSuccess, TError, {params: ChangeSubscriptionTypeParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ChangeSubscriptionType(params, ), options)}
  
    
        
    export const CancelSubscriptionQueryKey = 'all_CancelSubscription';

    export function useCancelSubscriptionQuery<TError = unknown>( options?: Omit<UseQueryOptions<CancelSubscriptionSuccess, TError, CancelSubscriptionSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CancelSubscriptionSuccess | (() => CancelSubscriptionSuccess);},  baseQueryKey = CancelSubscriptionQueryKey) {
            return useQuery([baseQueryKey, ],
            CancelSubscription, options);
        }
  
    
        
    export const MatasPlusConfirmCancelQueryKey = 'all_MatasPlusConfirmCancel';

    export function useMatasPlusConfirmCancelQuery<TError = unknown>(params: MatasPlusConfirmCancelParams, options?: Omit<UseQueryOptions<MatasPlusConfirmCancelSuccess, TError, MatasPlusConfirmCancelSuccess, [string, MatasPlusConfirmCancelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusConfirmCancelSuccess | (() => MatasPlusConfirmCancelSuccess);},  baseQueryKey = MatasPlusConfirmCancelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => MatasPlusConfirmCancel(params), options);
        }
  
    
        
      export function useUndoCancelSubscriptionMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    UndoCancelSubscription( ), options)}
  
    
        
    export const GetMatasPlusPageModelQueryKey = 'all_GetMatasPlusPageModel';

    export function useGetMatasPlusPageModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetMatasPlusPageModelSuccess, TError, GetMatasPlusPageModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetMatasPlusPageModelSuccess | (() => GetMatasPlusPageModelSuccess);},  baseQueryKey = GetMatasPlusPageModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GetMatasPlusPageModel, options);
        }
  
    
        
    export const MatasPlusCalculatorIndexQueryKey = 'all_MatasPlusCalculatorIndex';

    export function useMatasPlusCalculatorIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<MatasPlusCalculatorIndexSuccess, TError, MatasPlusCalculatorIndexSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusCalculatorIndexSuccess | (() => MatasPlusCalculatorIndexSuccess);},  baseQueryKey = MatasPlusCalculatorIndexQueryKey) {
            return useQuery([baseQueryKey, ],
            MatasPlusCalculatorIndex, options);
        }
  
    
        
    export const GetConfirmCancellationModalQueryKey = 'all_GetConfirmCancellationModal';

    export function useGetConfirmCancellationModalQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetConfirmCancellationModalSuccess, TError, GetConfirmCancellationModalSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetConfirmCancellationModalSuccess | (() => GetConfirmCancellationModalSuccess);},  baseQueryKey = GetConfirmCancellationModalQueryKey) {
            return useQuery([baseQueryKey, ],
            GetConfirmCancellationModal, options);
        }
  
    
        
    export const MatasPlusSavingsPreviewQueryKey = 'all_MatasPlusSavingsPreview';

    export function useMatasPlusSavingsPreviewQuery<TError = unknown>(params: MatasPlusSavingsPreviewParams, options?: Omit<UseQueryOptions<MatasPlusSavingsPreviewSuccess, TError, MatasPlusSavingsPreviewSuccess, [string, MatasPlusSavingsPreviewParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusSavingsPreviewSuccess | (() => MatasPlusSavingsPreviewSuccess);},  baseQueryKey = MatasPlusSavingsPreviewQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => MatasPlusSavingsPreview(params), options);
        }
  
    
        
    export const MatasPlusSavingsDetailsQueryKey = 'all_MatasPlusSavingsDetails';

    export function useMatasPlusSavingsDetailsQuery<TError = unknown>( options?: Omit<UseQueryOptions<MatasPlusSavingsDetailsSuccess, TError, MatasPlusSavingsDetailsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusSavingsDetailsSuccess | (() => MatasPlusSavingsDetailsSuccess);},  baseQueryKey = MatasPlusSavingsDetailsQueryKey) {
            return useQuery([baseQueryKey, ],
            MatasPlusSavingsDetails, options);
        }
  
    
        
    export const PointSavingsQueryKey = 'all_PointSavings';

    export function usePointSavingsQuery<TError = unknown>( options?: Omit<UseQueryOptions<PointSavingsSuccess, TError, PointSavingsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PointSavingsSuccess | (() => PointSavingsSuccess);},  baseQueryKey = PointSavingsQueryKey) {
            return useQuery([baseQueryKey, ],
            PointSavings, options);
        }
  
    
        
    export const MatasPlusSavingsDisclaimerModelQueryKey = 'all_MatasPlusSavingsDisclaimerModel';

    export function useMatasPlusSavingsDisclaimerModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MatasPlusSavingsDisclaimerModelSuccess, TError, MatasPlusSavingsDisclaimerModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusSavingsDisclaimerModelSuccess | (() => MatasPlusSavingsDisclaimerModelSuccess);},  baseQueryKey = MatasPlusSavingsDisclaimerModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MatasPlusSavingsDisclaimerModel, options);
        }
  
    
        
    export const MegaQueryKey = 'all_Mega';

    export function useMegaQuery<TError = unknown>( options?: Omit<UseQueryOptions<MegaSuccess, TError, MegaSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MegaSuccess | (() => MegaSuccess);},  baseQueryKey = MegaQueryKey) {
            return useQuery([baseQueryKey, ],
            Mega, options);
        }
  
    
        
    export const FooterQueryKey = 'all_Footer';

    export function useFooterQuery<TError = unknown>( options?: Omit<UseQueryOptions<FooterSuccess, TError, FooterSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: FooterSuccess | (() => FooterSuccess);},  baseQueryKey = FooterQueryKey) {
            return useQuery([baseQueryKey, ],
            Footer, options);
        }
  
    
        
    export const ListQueryKey = 'all_List';

    export function useListQuery<TError = unknown>(params: ListParams, options?: Omit<UseQueryOptions<ListSuccess, TError, ListSuccess, [string, ListParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListSuccess | (() => ListSuccess);},  baseQueryKey = ListQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => List(params), options);
        }
  
    
        
    export const MenuForWebshopQueryKey = 'all_MenuForWebshop';

    export function useMenuForWebshopQuery<TError = unknown>(params: MenuForWebshopParams, options?: Omit<UseQueryOptions<MenuForWebshopSuccess, TError, MenuForWebshopSuccess, [string, MenuForWebshopParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MenuForWebshopSuccess | (() => MenuForWebshopSuccess);},  baseQueryKey = MenuForWebshopQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => MenuForWebshop(params), options);
        }
  
    
        
    export const MenuQueryKey = 'all_Menu';

    export function useMenuQuery<TError = unknown>(params: MenuParams, options?: Omit<UseQueryOptions<MenuSuccess, TError, MenuSuccess, [string, MenuParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MenuSuccess | (() => MenuSuccess);},  baseQueryKey = MenuQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => Menu(params), options);
        }
  
    
        
    export const _MiniBasketQueryKey = 'all__MiniBasket';

    export function use_MiniBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<_MiniBasketSuccess, TError, _MiniBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _MiniBasketSuccess | (() => _MiniBasketSuccess);},  baseQueryKey = _MiniBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            _MiniBasket, options);
        }
  
    
        
    export const MiniBasketIndexQueryKey = 'all_MiniBasketIndex';

    export function useMiniBasketIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<MiniBasketIndexSuccess, TError, MiniBasketIndexSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MiniBasketIndexSuccess | (() => MiniBasketIndexSuccess);},  baseQueryKey = MiniBasketIndexQueryKey) {
            return useQuery([baseQueryKey, ],
            MiniBasketIndex, options);
        }
  
    
        
    export const AccordionDTOQueryKey = 'all_AccordionDTO';

    export function useAccordionDTOQuery<TError = unknown>( options?: Omit<UseQueryOptions<AccordionDTOSuccess, TError, AccordionDTOSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AccordionDTOSuccess | (() => AccordionDTOSuccess);},  baseQueryKey = AccordionDTOQueryKey) {
            return useQuery([baseQueryKey, ],
            AccordionDTO, options);
        }
  
    
        
    export const HighlightedCampaignBlockDTOQueryKey = 'all_HighlightedCampaignBlockDTO';

    export function useHighlightedCampaignBlockDTOQuery<TError = unknown>( options?: Omit<UseQueryOptions<HighlightedCampaignBlockDTOSuccess, TError, HighlightedCampaignBlockDTOSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: HighlightedCampaignBlockDTOSuccess | (() => HighlightedCampaignBlockDTOSuccess);},  baseQueryKey = HighlightedCampaignBlockDTOQueryKey) {
            return useQuery([baseQueryKey, ],
            HighlightedCampaignBlockDTO, options);
        }
  
    
        
    export const MatasPlusUnboxingModelQueryKey = 'all_MatasPlusUnboxingModel';

    export function useMatasPlusUnboxingModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MatasPlusUnboxingModelSuccess, TError, MatasPlusUnboxingModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusUnboxingModelSuccess | (() => MatasPlusUnboxingModelSuccess);},  baseQueryKey = MatasPlusUnboxingModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MatasPlusUnboxingModel, options);
        }
  
    
        
    export const MatasPlusFaqBlockDTOQueryKey = 'all_MatasPlusFaqBlockDTO';

    export function useMatasPlusFaqBlockDTOQuery<TError = unknown>( options?: Omit<UseQueryOptions<MatasPlusFaqBlockDTOSuccess, TError, MatasPlusFaqBlockDTOSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MatasPlusFaqBlockDTOSuccess | (() => MatasPlusFaqBlockDTOSuccess);},  baseQueryKey = MatasPlusFaqBlockDTOQueryKey) {
            return useQuery([baseQueryKey, ],
            MatasPlusFaqBlockDTO, options);
        }
  
    
        
    export const GwpDiscountsBlockQueryKey = 'all_GwpDiscountsBlock';

    export function useGwpDiscountsBlockQuery<TError = unknown>( options?: Omit<UseQueryOptions<GwpDiscountsBlockSuccess, TError, GwpDiscountsBlockSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GwpDiscountsBlockSuccess | (() => GwpDiscountsBlockSuccess);},  baseQueryKey = GwpDiscountsBlockQueryKey) {
            return useQuery([baseQueryKey, ],
            GwpDiscountsBlock, options);
        }
  
    
        
    export const AdvisorBlockQueryKey = 'all_AdvisorBlock';

    export function useAdvisorBlockQuery<TError = unknown>( options?: Omit<UseQueryOptions<AdvisorBlockSuccess, TError, AdvisorBlockSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorBlockSuccess | (() => AdvisorBlockSuccess);},  baseQueryKey = AdvisorBlockQueryKey) {
            return useQuery([baseQueryKey, ],
            AdvisorBlock, options);
        }
  
    
        
    export const AdvisorListBlockDTOQueryKey = 'all_AdvisorListBlockDTO';

    export function useAdvisorListBlockDTOQuery<TError = unknown>( options?: Omit<UseQueryOptions<AdvisorListBlockDTOSuccess, TError, AdvisorListBlockDTOSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorListBlockDTOSuccess | (() => AdvisorListBlockDTOSuccess);},  baseQueryKey = AdvisorListBlockDTOQueryKey) {
            return useQuery([baseQueryKey, ],
            AdvisorListBlockDTO, options);
        }
  
    
        
    export const FooterViewModelQueryKey = 'all_FooterViewModel';

    export function useFooterViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<FooterViewModelSuccess, TError, FooterViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: FooterViewModelSuccess | (() => FooterViewModelSuccess);},  baseQueryKey = FooterViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            FooterViewModel, options);
        }
  
    
        
    export const StepLayoutFooterViewModelQueryKey = 'all_StepLayoutFooterViewModel';

    export function useStepLayoutFooterViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<StepLayoutFooterViewModelSuccess, TError, StepLayoutFooterViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: StepLayoutFooterViewModelSuccess | (() => StepLayoutFooterViewModelSuccess);},  baseQueryKey = StepLayoutFooterViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            StepLayoutFooterViewModel, options);
        }
  
    
        
    export const PrimaryHeaderViewModelQueryKey = 'all_PrimaryHeaderViewModel';

    export function usePrimaryHeaderViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PrimaryHeaderViewModelSuccess, TError, PrimaryHeaderViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PrimaryHeaderViewModelSuccess | (() => PrimaryHeaderViewModelSuccess);},  baseQueryKey = PrimaryHeaderViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PrimaryHeaderViewModel, options);
        }
  
    
        
    export const ProductCustomerNotificationCreateModelQueryKey = 'all_ProductCustomerNotificationCreateModel';

    export function useProductCustomerNotificationCreateModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductCustomerNotificationCreateModelSuccess, TError, ProductCustomerNotificationCreateModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductCustomerNotificationCreateModelSuccess | (() => ProductCustomerNotificationCreateModelSuccess);},  baseQueryKey = ProductCustomerNotificationCreateModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductCustomerNotificationCreateModel, options);
        }
  
    
        
    export const ProductReviewHeaderModelQueryKey = 'all_ProductReviewHeaderModel';

    export function useProductReviewHeaderModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductReviewHeaderModelSuccess, TError, ProductReviewHeaderModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductReviewHeaderModelSuccess | (() => ProductReviewHeaderModelSuccess);},  baseQueryKey = ProductReviewHeaderModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductReviewHeaderModel, options);
        }
  
    
        
    export const AdvisorToolboxModelQueryKey = 'all_AdvisorToolboxModel';

    export function useAdvisorToolboxModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<AdvisorToolboxModelSuccess, TError, AdvisorToolboxModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorToolboxModelSuccess | (() => AdvisorToolboxModelSuccess);},  baseQueryKey = AdvisorToolboxModelQueryKey) {
            return useQuery([baseQueryKey, ],
            AdvisorToolboxModel, options);
        }
  
    
        
    export const ProductSliderBlockDTOModelQueryKey = 'all_ProductSliderBlockDTOModel';

    export function useProductSliderBlockDTOModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductSliderBlockDTOModelSuccess, TError, ProductSliderBlockDTOModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductSliderBlockDTOModelSuccess | (() => ProductSliderBlockDTOModelSuccess);},  baseQueryKey = ProductSliderBlockDTOModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductSliderBlockDTOModel, options);
        }
  
    
        
    export const ProductReviewCreateViewModelQueryKey = 'all_ProductReviewCreateViewModel';

    export function useProductReviewCreateViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductReviewCreateViewModelSuccess, TError, ProductReviewCreateViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductReviewCreateViewModelSuccess | (() => ProductReviewCreateViewModelSuccess);},  baseQueryKey = ProductReviewCreateViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductReviewCreateViewModel, options);
        }
  
    
        
    export const DonationModelQueryKey = 'all_DonationModel';

    export function useDonationModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<DonationModelSuccess, TError, DonationModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: DonationModelSuccess | (() => DonationModelSuccess);},  baseQueryKey = DonationModelQueryKey) {
            return useQuery([baseQueryKey, ],
            DonationModel, options);
        }
  
    
        
    export const BasketLineSubscriptionNudgeQueryKey = 'all_BasketLineSubscriptionNudge';

    export function useBasketLineSubscriptionNudgeQuery<TError = unknown>( options?: Omit<UseQueryOptions<BasketLineSubscriptionNudgeSuccess, TError, BasketLineSubscriptionNudgeSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BasketLineSubscriptionNudgeSuccess | (() => BasketLineSubscriptionNudgeSuccess);},  baseQueryKey = BasketLineSubscriptionNudgeQueryKey) {
            return useQuery([baseQueryKey, ],
            BasketLineSubscriptionNudge, options);
        }
  
    
        
    export const BasketMessageDiscountViewModelQueryKey = 'all_BasketMessageDiscountViewModel';

    export function useBasketMessageDiscountViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BasketMessageDiscountViewModelSuccess, TError, BasketMessageDiscountViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BasketMessageDiscountViewModelSuccess | (() => BasketMessageDiscountViewModelSuccess);},  baseQueryKey = BasketMessageDiscountViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BasketMessageDiscountViewModel, options);
        }
  
    
        
    export const BasketShippingSupplierOptionQueryKey = 'all_BasketShippingSupplierOption';

    export function useBasketShippingSupplierOptionQuery<TError = unknown>( options?: Omit<UseQueryOptions<BasketShippingSupplierOptionSuccess, TError, BasketShippingSupplierOptionSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BasketShippingSupplierOptionSuccess | (() => BasketShippingSupplierOptionSuccess);},  baseQueryKey = BasketShippingSupplierOptionQueryKey) {
            return useQuery([baseQueryKey, ],
            BasketShippingSupplierOption, options);
        }
  
    
        
    export const FeaturedFooterViewModelQueryKey = 'all_FeaturedFooterViewModel';

    export function useFeaturedFooterViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<FeaturedFooterViewModelSuccess, TError, FeaturedFooterViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: FeaturedFooterViewModelSuccess | (() => FeaturedFooterViewModelSuccess);},  baseQueryKey = FeaturedFooterViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            FeaturedFooterViewModel, options);
        }
  
    
        
    export const MenuTooltipsViewModelQueryKey = 'all_MenuTooltipsViewModel';

    export function useMenuTooltipsViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MenuTooltipsViewModelSuccess, TError, MenuTooltipsViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MenuTooltipsViewModelSuccess | (() => MenuTooltipsViewModelSuccess);},  baseQueryKey = MenuTooltipsViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MenuTooltipsViewModel, options);
        }
  
    
        
    export const InPageNotificationViewModelQueryKey = 'all_InPageNotificationViewModel';

    export function useInPageNotificationViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<InPageNotificationViewModelSuccess, TError, InPageNotificationViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: InPageNotificationViewModelSuccess | (() => InPageNotificationViewModelSuccess);},  baseQueryKey = InPageNotificationViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            InPageNotificationViewModel, options);
        }
  
    
        
    export const ImageWithDisclaimerPlaceholderModelQueryKey = 'all_ImageWithDisclaimerPlaceholderModel';

    export function useImageWithDisclaimerPlaceholderModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ImageWithDisclaimerPlaceholderModelSuccess, TError, ImageWithDisclaimerPlaceholderModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ImageWithDisclaimerPlaceholderModelSuccess | (() => ImageWithDisclaimerPlaceholderModelSuccess);},  baseQueryKey = ImageWithDisclaimerPlaceholderModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ImageWithDisclaimerPlaceholderModel, options);
        }
  
    
        
    export const GlobalUspViewModelQueryKey = 'all_GlobalUspViewModel';

    export function useGlobalUspViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GlobalUspViewModelSuccess, TError, GlobalUspViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GlobalUspViewModelSuccess | (() => GlobalUspViewModelSuccess);},  baseQueryKey = GlobalUspViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GlobalUspViewModel, options);
        }
  
    
        
    export const NoRebuysAvailableViewModelQueryKey = 'all_NoRebuysAvailableViewModel';

    export function useNoRebuysAvailableViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<NoRebuysAvailableViewModelSuccess, TError, NoRebuysAvailableViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: NoRebuysAvailableViewModelSuccess | (() => NoRebuysAvailableViewModelSuccess);},  baseQueryKey = NoRebuysAvailableViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            NoRebuysAvailableViewModel, options);
        }
  
    
        
    export const ExtendedPlaceholderModelQueryKey = 'all_ExtendedPlaceholderModel';

    export function useExtendedPlaceholderModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ExtendedPlaceholderModelSuccess, TError, ExtendedPlaceholderModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ExtendedPlaceholderModelSuccess | (() => ExtendedPlaceholderModelSuccess);},  baseQueryKey = ExtendedPlaceholderModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ExtendedPlaceholderModel, options);
        }
  
    
        
    export const CountdownModelQueryKey = 'all_CountdownModel';

    export function useCountdownModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CountdownModelSuccess, TError, CountdownModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CountdownModelSuccess | (() => CountdownModelSuccess);},  baseQueryKey = CountdownModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CountdownModel, options);
        }
  
    
        
    export const MultiBuyModelQueryKey = 'all_MultiBuyModel';

    export function useMultiBuyModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MultiBuyModelSuccess, TError, MultiBuyModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MultiBuyModelSuccess | (() => MultiBuyModelSuccess);},  baseQueryKey = MultiBuyModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MultiBuyModel, options);
        }
  
    
        
    export const StripedVerticalSpacerQueryKey = 'all_StripedVerticalSpacer';

    export function useStripedVerticalSpacerQuery<TError = unknown>( options?: Omit<UseQueryOptions<StripedVerticalSpacerSuccess, TError, StripedVerticalSpacerSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: StripedVerticalSpacerSuccess | (() => StripedVerticalSpacerSuccess);},  baseQueryKey = StripedVerticalSpacerQueryKey) {
            return useQuery([baseQueryKey, ],
            StripedVerticalSpacer, options);
        }
  
    
        
    export const JavascriptRequestDataModelQueryKey = 'all_JavascriptRequestDataModel';

    export function useJavascriptRequestDataModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<JavascriptRequestDataModelSuccess, TError, JavascriptRequestDataModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: JavascriptRequestDataModelSuccess | (() => JavascriptRequestDataModelSuccess);},  baseQueryKey = JavascriptRequestDataModelQueryKey) {
            return useQuery([baseQueryKey, ],
            JavascriptRequestDataModel, options);
        }
  
    
        
    export const BreadcrumbViewModelQueryKey = 'all_BreadcrumbViewModel';

    export function useBreadcrumbViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BreadcrumbViewModelSuccess, TError, BreadcrumbViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BreadcrumbViewModelSuccess | (() => BreadcrumbViewModelSuccess);},  baseQueryKey = BreadcrumbViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BreadcrumbViewModel, options);
        }
  
    
        
    export const CmsButtonModelQueryKey = 'all_CmsButtonModel';

    export function useCmsButtonModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CmsButtonModelSuccess, TError, CmsButtonModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CmsButtonModelSuccess | (() => CmsButtonModelSuccess);},  baseQueryKey = CmsButtonModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CmsButtonModel, options);
        }
  
    
        
    export const CmsButtonGridModelQueryKey = 'all_CmsButtonGridModel';

    export function useCmsButtonGridModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CmsButtonGridModelSuccess, TError, CmsButtonGridModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CmsButtonGridModelSuccess | (() => CmsButtonGridModelSuccess);},  baseQueryKey = CmsButtonGridModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CmsButtonGridModel, options);
        }
  
    
        
    export const ProductPrelaunchBannerModelQueryKey = 'all_ProductPrelaunchBannerModel';

    export function useProductPrelaunchBannerModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductPrelaunchBannerModelSuccess, TError, ProductPrelaunchBannerModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductPrelaunchBannerModelSuccess | (() => ProductPrelaunchBannerModelSuccess);},  baseQueryKey = ProductPrelaunchBannerModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductPrelaunchBannerModel, options);
        }
  
    
        
    export const BambuserViewModelQueryKey = 'all_BambuserViewModel';

    export function useBambuserViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BambuserViewModelSuccess, TError, BambuserViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BambuserViewModelSuccess | (() => BambuserViewModelSuccess);},  baseQueryKey = BambuserViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BambuserViewModel, options);
        }
  
    
        
    export const VimeoBlockDTOModelQueryKey = 'all_VimeoBlockDTOModel';

    export function useVimeoBlockDTOModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<VimeoBlockDTOModelSuccess, TError, VimeoBlockDTOModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: VimeoBlockDTOModelSuccess | (() => VimeoBlockDTOModelSuccess);},  baseQueryKey = VimeoBlockDTOModelQueryKey) {
            return useQuery([baseQueryKey, ],
            VimeoBlockDTOModel, options);
        }
  
    
        
    export const YouTubeBlockDTOModelQueryKey = 'all_YouTubeBlockDTOModel';

    export function useYouTubeBlockDTOModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<YouTubeBlockDTOModelSuccess, TError, YouTubeBlockDTOModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: YouTubeBlockDTOModelSuccess | (() => YouTubeBlockDTOModelSuccess);},  baseQueryKey = YouTubeBlockDTOModelQueryKey) {
            return useQuery([baseQueryKey, ],
            YouTubeBlockDTOModel, options);
        }
  
    
        
    export const ShippingSummaryModelQueryKey = 'all_ShippingSummaryModel';

    export function useShippingSummaryModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ShippingSummaryModelSuccess, TError, ShippingSummaryModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ShippingSummaryModelSuccess | (() => ShippingSummaryModelSuccess);},  baseQueryKey = ShippingSummaryModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ShippingSummaryModel, options);
        }
  
    
        
    export const VerticalSpacerContentBlockModelQueryKey = 'all_VerticalSpacerContentBlockModel';

    export function useVerticalSpacerContentBlockModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<VerticalSpacerContentBlockModelSuccess, TError, VerticalSpacerContentBlockModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: VerticalSpacerContentBlockModelSuccess | (() => VerticalSpacerContentBlockModelSuccess);},  baseQueryKey = VerticalSpacerContentBlockModelQueryKey) {
            return useQuery([baseQueryKey, ],
            VerticalSpacerContentBlockModel, options);
        }
  
    
        
    export const HorizontalSpaceBlockDTOModelQueryKey = 'all_HorizontalSpaceBlockDTOModel';

    export function useHorizontalSpaceBlockDTOModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<HorizontalSpaceBlockDTOModelSuccess, TError, HorizontalSpaceBlockDTOModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: HorizontalSpaceBlockDTOModelSuccess | (() => HorizontalSpaceBlockDTOModelSuccess);},  baseQueryKey = HorizontalSpaceBlockDTOModelQueryKey) {
            return useQuery([baseQueryKey, ],
            HorizontalSpaceBlockDTOModel, options);
        }
  
    
        
    export const AdvisorBlockDTOModelQueryKey = 'all_AdvisorBlockDTOModel';

    export function useAdvisorBlockDTOModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<AdvisorBlockDTOModelSuccess, TError, AdvisorBlockDTOModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: AdvisorBlockDTOModelSuccess | (() => AdvisorBlockDTOModelSuccess);},  baseQueryKey = AdvisorBlockDTOModelQueryKey) {
            return useQuery([baseQueryKey, ],
            AdvisorBlockDTOModel, options);
        }
  
    
        
    export const IStockStatusViewModelQueryKey = 'all_IStockStatusViewModel';

    export function useIStockStatusViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<IStockStatusViewModelSuccess, TError, IStockStatusViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: IStockStatusViewModelSuccess | (() => IStockStatusViewModelSuccess);},  baseQueryKey = IStockStatusViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            IStockStatusViewModel, options);
        }
  
    
        
    export const ImageBannerModelQueryKey = 'all_ImageBannerModel';

    export function useImageBannerModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ImageBannerModelSuccess, TError, ImageBannerModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ImageBannerModelSuccess | (() => ImageBannerModelSuccess);},  baseQueryKey = ImageBannerModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ImageBannerModel, options);
        }
  
    
        
    export const QuestionnaireContentBlockDTOQueryKey = 'all_QuestionnaireContentBlockDTO';

    export function useQuestionnaireContentBlockDTOQuery<TError = unknown>( options?: Omit<UseQueryOptions<QuestionnaireContentBlockDTOSuccess, TError, QuestionnaireContentBlockDTOSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: QuestionnaireContentBlockDTOSuccess | (() => QuestionnaireContentBlockDTOSuccess);},  baseQueryKey = QuestionnaireContentBlockDTOQueryKey) {
            return useQuery([baseQueryKey, ],
            QuestionnaireContentBlockDTO, options);
        }
  
    
        
    export const PageActionModelQueryKey = 'all_PageActionModel';

    export function usePageActionModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PageActionModelSuccess, TError, PageActionModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PageActionModelSuccess | (() => PageActionModelSuccess);},  baseQueryKey = PageActionModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PageActionModel, options);
        }
  
    
        
    export const MyProfileSettingPageViewModelQueryKey = 'all_MyProfileSettingPageViewModel';

    export function useMyProfileSettingPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MyProfileSettingPageViewModelSuccess, TError, MyProfileSettingPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyProfileSettingPageViewModelSuccess | (() => MyProfileSettingPageViewModelSuccess);},  baseQueryKey = MyProfileSettingPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MyProfileSettingPageViewModel, options);
        }
  
    
        
    export const SignoffPageViewModelQueryKey = 'all_SignoffPageViewModel';

    export function useSignoffPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<SignoffPageViewModelSuccess, TError, SignoffPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SignoffPageViewModelSuccess | (() => SignoffPageViewModelSuccess);},  baseQueryKey = SignoffPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            SignoffPageViewModel, options);
        }
  
    
        
    export const SignoffConfirmDialogueModelQueryKey = 'all_SignoffConfirmDialogueModel';

    export function useSignoffConfirmDialogueModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<SignoffConfirmDialogueModelSuccess, TError, SignoffConfirmDialogueModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SignoffConfirmDialogueModelSuccess | (() => SignoffConfirmDialogueModelSuccess);},  baseQueryKey = SignoffConfirmDialogueModelQueryKey) {
            return useQuery([baseQueryKey, ],
            SignoffConfirmDialogueModel, options);
        }
  
    
        
    export const PermissionViewModelQueryKey = 'all_PermissionViewModel';

    export function usePermissionViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PermissionViewModelSuccess, TError, PermissionViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PermissionViewModelSuccess | (() => PermissionViewModelSuccess);},  baseQueryKey = PermissionViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PermissionViewModel, options);
        }
  
    
        
    export const UpdateMyStoreViewModelQueryKey = 'all_UpdateMyStoreViewModel';

    export function useUpdateMyStoreViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<UpdateMyStoreViewModelSuccess, TError, UpdateMyStoreViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: UpdateMyStoreViewModelSuccess | (() => UpdateMyStoreViewModelSuccess);},  baseQueryKey = UpdateMyStoreViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            UpdateMyStoreViewModel, options);
        }
  
    
        
    export const RevieveMakeupAdvisorViewModelQueryKey = 'all_RevieveMakeupAdvisorViewModel';

    export function useRevieveMakeupAdvisorViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<RevieveMakeupAdvisorViewModelSuccess, TError, RevieveMakeupAdvisorViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: RevieveMakeupAdvisorViewModelSuccess | (() => RevieveMakeupAdvisorViewModelSuccess);},  baseQueryKey = RevieveMakeupAdvisorViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            RevieveMakeupAdvisorViewModel, options);
        }
  
    
        
    export const ShippingOptionsModelQueryKey = 'all_ShippingOptionsModel';

    export function useShippingOptionsModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ShippingOptionsModelSuccess, TError, ShippingOptionsModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ShippingOptionsModelSuccess | (() => ShippingOptionsModelSuccess);},  baseQueryKey = ShippingOptionsModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ShippingOptionsModel, options);
        }
  
    
        
    export const CheckoutDeliveryGroupModelQueryKey = 'all_CheckoutDeliveryGroupModel';

    export function useCheckoutDeliveryGroupModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryGroupModelSuccess, TError, CheckoutDeliveryGroupModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryGroupModelSuccess | (() => CheckoutDeliveryGroupModelSuccess);},  baseQueryKey = CheckoutDeliveryGroupModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryGroupModel, options);
        }
  
    
        
    export const CheckoutDeliveryPostModelQueryKey = 'all_CheckoutDeliveryPostModel';

    export function useCheckoutDeliveryPostModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryPostModelSuccess, TError, CheckoutDeliveryPostModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryPostModelSuccess | (() => CheckoutDeliveryPostModelSuccess);},  baseQueryKey = CheckoutDeliveryPostModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryPostModel, options);
        }
  
    
        
    export const CheckoutDeliveryViewModelQueryKey = 'all_CheckoutDeliveryViewModel';

    export function useCheckoutDeliveryViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutDeliveryViewModelSuccess, TError, CheckoutDeliveryViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutDeliveryViewModelSuccess | (() => CheckoutDeliveryViewModelSuccess);},  baseQueryKey = CheckoutDeliveryViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutDeliveryViewModel, options);
        }
  
    
        
    export const CheckoutSummaryViewModelQueryKey = 'all_CheckoutSummaryViewModel';

    export function useCheckoutSummaryViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutSummaryViewModelSuccess, TError, CheckoutSummaryViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutSummaryViewModelSuccess | (() => CheckoutSummaryViewModelSuccess);},  baseQueryKey = CheckoutSummaryViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutSummaryViewModel, options);
        }
  
    
        
    export const CheckoutSummaryPostModelQueryKey = 'all_CheckoutSummaryPostModel';

    export function useCheckoutSummaryPostModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<CheckoutSummaryPostModelSuccess, TError, CheckoutSummaryPostModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CheckoutSummaryPostModelSuccess | (() => CheckoutSummaryPostModelSuccess);},  baseQueryKey = CheckoutSummaryPostModelQueryKey) {
            return useQuery([baseQueryKey, ],
            CheckoutSummaryPostModel, options);
        }
  
    
        
    export const PaymentViewModelQueryKey = 'all_PaymentViewModel';

    export function usePaymentViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PaymentViewModelSuccess, TError, PaymentViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PaymentViewModelSuccess | (() => PaymentViewModelSuccess);},  baseQueryKey = PaymentViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PaymentViewModel, options);
        }
  
    
        
    export const PaymentReepayCallbackViewModelQueryKey = 'all_PaymentReepayCallbackViewModel';

    export function usePaymentReepayCallbackViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PaymentReepayCallbackViewModelSuccess, TError, PaymentReepayCallbackViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PaymentReepayCallbackViewModelSuccess | (() => PaymentReepayCallbackViewModelSuccess);},  baseQueryKey = PaymentReepayCallbackViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PaymentReepayCallbackViewModel, options);
        }
  
    
        
    export const ReceiptPageViewModelQueryKey = 'all_ReceiptPageViewModel';

    export function useReceiptPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ReceiptPageViewModelSuccess, TError, ReceiptPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ReceiptPageViewModelSuccess | (() => ReceiptPageViewModelSuccess);},  baseQueryKey = ReceiptPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ReceiptPageViewModel, options);
        }
  
    
        
    export const ModifaceIndexQueryKey = 'all_ModifaceIndex';

    export function useModifaceIndexQuery<TError = unknown>(params: ModifaceIndexParams, options?: Omit<UseQueryOptions<ModifaceIndexSuccess, TError, ModifaceIndexSuccess, [string, ModifaceIndexParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ModifaceIndexSuccess | (() => ModifaceIndexSuccess);},  baseQueryKey = ModifaceIndexQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ModifaceIndex(params), options);
        }
  
    
        
    export const MyFavoritesGetQueryKey = 'all_MyFavoritesGet';

    export function useMyFavoritesGetQuery<TError = unknown>( options?: Omit<UseQueryOptions<MyFavoritesGetSuccess, TError, MyFavoritesGetSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyFavoritesGetSuccess | (() => MyFavoritesGetSuccess);},  baseQueryKey = MyFavoritesGetQueryKey) {
            return useQuery([baseQueryKey, ],
            MyFavoritesGet, options);
        }
  
    
        
    export const _StatusDetailsQueryKey = 'all__StatusDetails';

    export function use_StatusDetailsQuery<TError = unknown>(params: _StatusDetailsParams, options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, _StatusDetailsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = _StatusDetailsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _StatusDetails(params), options);
        }
  
    
        
      export function use_FollowupDetailsMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<_FollowupDetailsSuccess, TError, {params: _FollowupDetailsParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    _FollowupDetails(params, ), options)}
  
    
        
      export function useMyOrderCancelMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: MyOrderCancelParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    MyOrderCancel(params, ), options)}
  
    
        
    export const OrderSimpleModelQueryKey = 'all_OrderSimpleModel';

    export function useOrderSimpleModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<OrderSimpleModelSuccess, TError, OrderSimpleModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: OrderSimpleModelSuccess | (() => OrderSimpleModelSuccess);},  baseQueryKey = OrderSimpleModelQueryKey) {
            return useQuery([baseQueryKey, ],
            OrderSimpleModel, options);
        }
  
    
        
    export const HeaderQueryKey = 'all_Header';

    export function useHeaderQuery<TError = unknown>( options?: Omit<UseQueryOptions<HeaderSuccess, TError, HeaderSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: HeaderSuccess | (() => HeaderSuccess);},  baseQueryKey = HeaderQueryKey) {
            return useQuery([baseQueryKey, ],
            Header, options);
        }
  
    
        
    export const MyProfileLayoutViewModelQueryKey = 'all_MyProfileLayoutViewModel';

    export function useMyProfileLayoutViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MyProfileLayoutViewModelSuccess, TError, MyProfileLayoutViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyProfileLayoutViewModelSuccess | (() => MyProfileLayoutViewModelSuccess);},  baseQueryKey = MyProfileLayoutViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MyProfileLayoutViewModel, options);
        }
  
    
        
    export const MyProfileFrontPageViewModelQueryKey = 'all_MyProfileFrontPageViewModel';

    export function useMyProfileFrontPageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<MyProfileFrontPageViewModelSuccess, TError, MyProfileFrontPageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyProfileFrontPageViewModelSuccess | (() => MyProfileFrontPageViewModelSuccess);},  baseQueryKey = MyProfileFrontPageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            MyProfileFrontPageViewModel, options);
        }
  
    
        
    export const GetProductReviewQueryKey = 'all_GetProductReview';

    export function useGetProductReviewQuery<TError = unknown>(params: GetProductReviewParams, options?: Omit<UseQueryOptions<GetProductReviewSuccess, TError, GetProductReviewSuccess, [string, GetProductReviewParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductReviewSuccess | (() => GetProductReviewSuccess);},  baseQueryKey = GetProductReviewQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetProductReview(params), options);
        }
  
    
        
    export const ReviewViewModelWrapperQueryKey = 'all_ReviewViewModelWrapper';

    export function useReviewViewModelWrapperQuery<TError = unknown>( options?: Omit<UseQueryOptions<ReviewViewModelWrapperSuccess, TError, ReviewViewModelWrapperSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ReviewViewModelWrapperSuccess | (() => ReviewViewModelWrapperSuccess);},  baseQueryKey = ReviewViewModelWrapperQueryKey) {
            return useQuery([baseQueryKey, ],
            ReviewViewModelWrapper, options);
        }
  
    
        
    export const GetSampleProfilePageModelQueryKey = 'all_GetSampleProfilePageModel';

    export function useGetSampleProfilePageModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetSampleProfilePageModelSuccess, TError, GetSampleProfilePageModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetSampleProfilePageModelSuccess | (() => GetSampleProfilePageModelSuccess);},  baseQueryKey = GetSampleProfilePageModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GetSampleProfilePageModel, options);
        }
  
    
        
      export function useRateSampleMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RateSampleSuccess, TError, {params: RateSampleParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    RateSample(params, ), options)}
  
    
        
      export function useUpdateFavoriteStatusMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateFavoriteStatusSuccess, TError, {params: UpdateFavoriteStatusParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateFavoriteStatus(params, ), options)}
  
    
        
    export const GetOrderListModelQueryKey = 'all_GetOrderListModel';

    export function useGetOrderListModelQuery<TError = unknown>(params: GetOrderListModelParams, options?: Omit<UseQueryOptions<GetOrderListModelSuccess, TError, GetOrderListModelSuccess, [string, GetOrderListModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetOrderListModelSuccess | (() => GetOrderListModelSuccess);},  baseQueryKey = GetOrderListModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetOrderListModel(params), options);
        }
  
    
        
    export const ReceiptFileQueryKey = 'all_ReceiptFile';

    export function useReceiptFileQuery<TError = unknown>(params: ReceiptFileParams, options?: Omit<UseQueryOptions<ReceiptFileSuccess, TError, ReceiptFileSuccess, [string, ReceiptFileParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ReceiptFileSuccess | (() => ReceiptFileSuccess);},  baseQueryKey = ReceiptFileQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ReceiptFile(params), options);
        }
  
    
        
    export const GetReceiptQueryKey = 'all_GetReceipt';

    export function useGetReceiptQuery<TError = unknown>(params: GetReceiptParams, options?: Omit<UseQueryOptions<GetReceiptSuccess, TError, GetReceiptSuccess, [string, GetReceiptParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetReceiptSuccess | (() => GetReceiptSuccess);},  baseQueryKey = GetReceiptQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetReceipt(params), options);
        }
  
    
        
    export const _ReceiptQueryKey = 'all__Receipt';

    export function use_ReceiptQuery<TError = unknown>(params: _ReceiptParams, options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, _ReceiptParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = _ReceiptQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _Receipt(params), options);
        }
  
    
        
    export const RenderSubPageQueryKey = 'all_RenderSubPage';

    export function useRenderSubPageQuery<TError = unknown>(params: RenderSubPageParams, options?: Omit<UseQueryOptions<RenderSubPageSuccess, TError, RenderSubPageSuccess, [string, RenderSubPageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: RenderSubPageSuccess | (() => RenderSubPageSuccess);},  baseQueryKey = RenderSubPageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => RenderSubPage(params), options);
        }
  
    
        
      export function useReepayAcceptMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: ReepayAcceptParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ReepayAccept(params, ), options)}
  
    
        
    export const ApproveQueryKey = 'all_Approve';

    export function useApproveQuery<TError = unknown>(params: ApproveParams, options?: Omit<UseQueryOptions<ApproveSuccess, TError, ApproveSuccess, [string, ApproveParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ApproveSuccess | (() => ApproveSuccess);},  baseQueryKey = ApproveQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => Approve(params), options);
        }
  
    
        
    export const PendingSubscriptionCancelQueryKey = 'all_PendingSubscriptionCancel';

    export function usePendingSubscriptionCancelQuery<TError = unknown>(params: PendingSubscriptionCancelParams, options?: Omit<UseQueryOptions<PendingSubscriptionCancelSuccess, TError, PendingSubscriptionCancelSuccess, [string, PendingSubscriptionCancelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PendingSubscriptionCancelSuccess | (() => PendingSubscriptionCancelSuccess);},  baseQueryKey = PendingSubscriptionCancelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => PendingSubscriptionCancel(params), options);
        }
  
    
        
    export const PendingSubscriptionGetModelQueryKey = 'all_PendingSubscriptionGetModel';

    export function usePendingSubscriptionGetModelQuery<TError = unknown>(params: PendingSubscriptionGetModelParams, options?: Omit<UseQueryOptions<PendingSubscriptionGetModelSuccess, TError, PendingSubscriptionGetModelSuccess, [string, PendingSubscriptionGetModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PendingSubscriptionGetModelSuccess | (() => PendingSubscriptionGetModelSuccess);},  baseQueryKey = PendingSubscriptionGetModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => PendingSubscriptionGetModel(params), options);
        }
  
    
        
    export const PermissionGetModelQueryKey = 'all_PermissionGetModel';

    export function usePermissionGetModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PermissionGetModelSuccess, TError, PermissionGetModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PermissionGetModelSuccess | (() => PermissionGetModelSuccess);},  baseQueryKey = PermissionGetModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PermissionGetModel, options);
        }
  
    
        
      export function usePermissionUpdateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: PermissionUpdateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    PermissionUpdate(params, ), options)}
  
    
        
    export const PersonalFiltersIndexQueryKey = 'all_PersonalFiltersIndex';

    export function usePersonalFiltersIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<PersonalFiltersIndexSuccess, TError, PersonalFiltersIndexSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PersonalFiltersIndexSuccess | (() => PersonalFiltersIndexSuccess);},  baseQueryKey = PersonalFiltersIndexQueryKey) {
            return useQuery([baseQueryKey, ],
            PersonalFiltersIndex, options);
        }
  
    
        
      export function useUpdatePreferenceModelMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdatePreferenceModelSuccess, TError, {requestBody: UpdatePreferenceModelRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    UpdatePreferenceModel( requestBody), options)}
  
    
        
    export const SetIntroducedQueryKey = 'all_SetIntroduced';

    export function useSetIntroducedQuery<TError = unknown>( options?: Omit<UseQueryOptions<SetIntroducedSuccess, TError, SetIntroducedSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SetIntroducedSuccess | (() => SetIntroducedSuccess);},  baseQueryKey = SetIntroducedQueryKey) {
            return useQuery([baseQueryKey, ],
            SetIntroduced, options);
        }
  
    
        
      export function useActivateEmployeeLoginMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ActivateEmployeeLoginSuccess, TError, {params: ActivateEmployeeLoginParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ActivateEmployeeLogin(params, ), options)}
  
    
        
      export function useEnableMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<EnableSuccess, TError, {params: EnableParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    Enable(params, ), options)}
  
    
        
      export function useDisableMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DisableSuccess, TError, {params: DisableParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    Disable(params, ), options)}
  
    
        
    export const PersonalOfferGetModelQueryKey = 'all_PersonalOfferGetModel';

    export function usePersonalOfferGetModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PersonalOfferGetModelSuccess, TError, PersonalOfferGetModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PersonalOfferGetModelSuccess | (() => PersonalOfferGetModelSuccess);},  baseQueryKey = PersonalOfferGetModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PersonalOfferGetModel, options);
        }
  
    
        
      export function useDeleteStateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteStateSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    DeleteState( ), options)}
  
    
        
    export const MyProfilePointInfoViewModelQueryKey = 'all_MyProfilePointInfoViewModel';

    export function useMyProfilePointInfoViewModelQuery<TError = unknown>(params: MyProfilePointInfoViewModelParams, options?: Omit<UseQueryOptions<MyProfilePointInfoViewModelSuccess, TError, MyProfilePointInfoViewModelSuccess, [string, MyProfilePointInfoViewModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MyProfilePointInfoViewModelSuccess | (() => MyProfilePointInfoViewModelSuccess);},  baseQueryKey = MyProfilePointInfoViewModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => MyProfilePointInfoViewModel(params), options);
        }
  
    
        
      export function useHidePointExpiryInfoMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    HidePointExpiryInfo( ), options)}
  
    
        
    export const PosFrontpageViewModelQueryKey = 'all_PosFrontpageViewModel';

    export function usePosFrontpageViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<PosFrontpageViewModelSuccess, TError, PosFrontpageViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PosFrontpageViewModelSuccess | (() => PosFrontpageViewModelSuccess);},  baseQueryKey = PosFrontpageViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            PosFrontpageViewModel, options);
        }
  
    
        
    export const GetOrderNumberQueryKey = 'all_GetOrderNumber';

    export function useGetOrderNumberQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetOrderNumberSuccess, TError, GetOrderNumberSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetOrderNumberSuccess | (() => GetOrderNumberSuccess);},  baseQueryKey = GetOrderNumberQueryKey) {
            return useQuery([baseQueryKey, ],
            GetOrderNumber, options);
        }
  
    
        
    export const GetPowerstepQueryKey = 'all_GetPowerstep';

    export function useGetPowerstepQuery<TError = unknown>(params: GetPowerstepParams, options?: Omit<UseQueryOptions<GetPowerstepSuccess, TError, GetPowerstepSuccess, [string, GetPowerstepParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetPowerstepSuccess | (() => GetPowerstepSuccess);},  baseQueryKey = GetPowerstepQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetPowerstep(params), options);
        }
  
    
        
    export const GetMatasPlusInfoQueryKey = 'all_GetMatasPlusInfo';

    export function useGetMatasPlusInfoQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetMatasPlusInfoSuccess, TError, GetMatasPlusInfoSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetMatasPlusInfoSuccess | (() => GetMatasPlusInfoSuccess);},  baseQueryKey = GetMatasPlusInfoQueryKey) {
            return useQuery([baseQueryKey, ],
            GetMatasPlusInfo, options);
        }
  
    
        
    export const StepNudgingViewModelQueryKey = 'all_StepNudgingViewModel';

    export function useStepNudgingViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<StepNudgingViewModelSuccess, TError, StepNudgingViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: StepNudgingViewModelSuccess | (() => StepNudgingViewModelSuccess);},  baseQueryKey = StepNudgingViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            StepNudgingViewModel, options);
        }
  
    
        
    export const GetForFixedLowPriceQueryKey = 'all_GetForFixedLowPrice';

    export function useGetForFixedLowPriceQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetForFixedLowPriceSuccess, TError, GetForFixedLowPriceSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetForFixedLowPriceSuccess | (() => GetForFixedLowPriceSuccess);},  baseQueryKey = GetForFixedLowPriceQueryKey) {
            return useQuery([baseQueryKey, ],
            GetForFixedLowPrice, options);
        }
  
    
        
    export const GetVariantColorPickerForPdpQueryKey = 'all_GetVariantColorPickerForPdp';

    export function useGetVariantColorPickerForPdpQuery<TError = unknown>(params: GetVariantColorPickerForPdpParams, options?: Omit<UseQueryOptions<GetVariantColorPickerForPdpSuccess, TError, GetVariantColorPickerForPdpSuccess, [string, GetVariantColorPickerForPdpParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetVariantColorPickerForPdpSuccess | (() => GetVariantColorPickerForPdpSuccess);},  baseQueryKey = GetVariantColorPickerForPdpQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetVariantColorPickerForPdp(params), options);
        }
  
    
        
    export const GetVariantPickerForPlpQueryKey = 'all_GetVariantPickerForPlp';

    export function useGetVariantPickerForPlpQuery<TError = unknown>(params: GetVariantPickerForPlpParams, options?: Omit<UseQueryOptions<GetVariantPickerForPlpSuccess, TError, GetVariantPickerForPlpSuccess, [string, GetVariantPickerForPlpParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetVariantPickerForPlpSuccess | (() => GetVariantPickerForPlpSuccess);},  baseQueryKey = GetVariantPickerForPlpQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetVariantPickerForPlp(params), options);
        }
  
    
        
    export const ProductVariantsPdpModelQueryKey = 'all_ProductVariantsPdpModel';

    export function useProductVariantsPdpModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductVariantsPdpModelSuccess, TError, ProductVariantsPdpModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductVariantsPdpModelSuccess | (() => ProductVariantsPdpModelSuccess);},  baseQueryKey = ProductVariantsPdpModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductVariantsPdpModel, options);
        }
  
    
        
    export const GetProductJsonQueryKey = 'all_GetProductJson';

    export function useGetProductJsonQuery<TError = unknown>(params: GetProductJsonParams, options?: Omit<UseQueryOptions<GetProductJsonSuccess, TError, GetProductJsonSuccess, [string, GetProductJsonParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductJsonSuccess | (() => GetProductJsonSuccess);},  baseQueryKey = GetProductJsonQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetProductJson(params), options);
        }
  
    
        
    export const _GetAllProductReviewsQueryKey = 'all__GetAllProductReviews';

    export function use_GetAllProductReviewsQuery<TError = unknown>(params: _GetAllProductReviewsParams, options?: Omit<UseQueryOptions<_GetAllProductReviewsSuccess, TError, _GetAllProductReviewsSuccess, [string, _GetAllProductReviewsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _GetAllProductReviewsSuccess | (() => _GetAllProductReviewsSuccess);},  baseQueryKey = _GetAllProductReviewsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _GetAllProductReviews(params), options);
        }
  
    
        
      export function useCreateCustomerNotificationMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CreateCustomerNotificationSuccess, TError, {requestBody: CreateCustomerNotificationRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    CreateCustomerNotification( requestBody), options)}
  
    
        
    export const GetRegimePriceQueryKey = 'all_GetRegimePrice';

    export function useGetRegimePriceQuery<TError = unknown>(params: GetRegimePriceParams, options?: Omit<UseQueryOptions<GetRegimePriceSuccess, TError, GetRegimePriceSuccess, [string, GetRegimePriceParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetRegimePriceSuccess | (() => GetRegimePriceSuccess);},  baseQueryKey = GetRegimePriceQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetRegimePrice(params), options);
        }
  
    
        
    export const GetProductRegimeQueryKey = 'all_GetProductRegime';

    export function useGetProductRegimeQuery<TError = unknown>(params: GetProductRegimeParams, options?: Omit<UseQueryOptions<GetProductRegimeSuccess, TError, GetProductRegimeSuccess, [string, GetProductRegimeParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductRegimeSuccess | (() => GetProductRegimeSuccess);},  baseQueryKey = GetProductRegimeQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetProductRegime(params), options);
        }
  
    
        
    export const ProductInformationViewModelQueryKey = 'all_ProductInformationViewModel';

    export function useProductInformationViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ProductInformationViewModelSuccess, TError, ProductInformationViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductInformationViewModelSuccess | (() => ProductInformationViewModelSuccess);},  baseQueryKey = ProductInformationViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductInformationViewModel, options);
        }
  
    
        
    export const ProductPageViewModelQueryKey = 'all_ProductPageViewModel';

    export function useProductPageViewModelQuery<TError = unknown>(params: ProductPageViewModelParams, options?: Omit<UseQueryOptions<ProductPageViewModelSuccess, TError, ProductPageViewModelSuccess, [string, ProductPageViewModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductPageViewModelSuccess | (() => ProductPageViewModelSuccess);},  baseQueryKey = ProductPageViewModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ProductPageViewModel(params), options);
        }
  
    
        
    export const _GetProductReturnInformationQueryKey = 'all__GetProductReturnInformation';

    export function use_GetProductReturnInformationQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = _GetProductReturnInformationQueryKey) {
            return useQuery([baseQueryKey, ],
            _GetProductReturnInformation, options);
        }
  
    
        
    export const GetCertificationQueryKey = 'all_GetCertification';

    export function useGetCertificationQuery<TError = unknown>(params: GetCertificationParams, options?: Omit<UseQueryOptions<GetCertificationSuccess, TError, GetCertificationSuccess, [string, GetCertificationParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetCertificationSuccess | (() => GetCertificationSuccess);},  baseQueryKey = GetCertificationQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetCertification(params), options);
        }
  
    
        
    export const PageActionsQueryKey = 'all_PageActions';

    export function usePageActionsQuery<TError = unknown>( options?: Omit<UseQueryOptions<PageActionsSuccess, TError, PageActionsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: PageActionsSuccess | (() => PageActionsSuccess);},  baseQueryKey = PageActionsQueryKey) {
            return useQuery([baseQueryKey, ],
            PageActions, options);
        }
  
    
        
    export const ProductDeliveryToStoreInfoViewModelQueryKey = 'all_ProductDeliveryToStoreInfoViewModel';

    export function useProductDeliveryToStoreInfoViewModelQuery<TError = unknown>(params: ProductDeliveryToStoreInfoViewModelParams, options?: Omit<UseQueryOptions<ProductDeliveryToStoreInfoViewModelSuccess, TError, ProductDeliveryToStoreInfoViewModelSuccess, [string, ProductDeliveryToStoreInfoViewModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductDeliveryToStoreInfoViewModelSuccess | (() => ProductDeliveryToStoreInfoViewModelSuccess);},  baseQueryKey = ProductDeliveryToStoreInfoViewModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ProductDeliveryToStoreInfoViewModel(params), options);
        }
  
    
        
    export const ProductDeliveryInfoViewModelQueryKey = 'all_ProductDeliveryInfoViewModel';

    export function useProductDeliveryInfoViewModelQuery<TError = unknown>(params: ProductDeliveryInfoViewModelParams, options?: Omit<UseQueryOptions<ProductDeliveryInfoViewModelSuccess, TError, ProductDeliveryInfoViewModelSuccess, [string, ProductDeliveryInfoViewModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductDeliveryInfoViewModelSuccess | (() => ProductDeliveryInfoViewModelSuccess);},  baseQueryKey = ProductDeliveryInfoViewModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ProductDeliveryInfoViewModel(params), options);
        }
  
    
        
    export const ProductListItemsQueryKey = 'all_ProductListItems';

    export function useProductListItemsQuery<TError = unknown>(params: ProductListItemsParams, options?: Omit<UseQueryOptions<ProductListItemsSuccess, TError, ProductListItemsSuccess, [string, ProductListItemsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductListItemsSuccess | (() => ProductListItemsSuccess);},  baseQueryKey = ProductListItemsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ProductListItems(params), options);
        }
  
    
        
    export const GetProductListModelQueryKey = 'all_GetProductListModel';

    export function useGetProductListModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetProductListModelSuccess, TError, GetProductListModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductListModelSuccess | (() => GetProductListModelSuccess);},  baseQueryKey = GetProductListModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GetProductListModel, options);
        }
  
    
        
      export function useResetHistoryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ResetHistorySuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    ResetHistory( ), options)}
  
    
        
    export const ProductRecommendationAIAssistantIndexQueryKey = 'all_ProductRecommendationAIAssistantIndex';

    export function useProductRecommendationAIAssistantIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = ProductRecommendationAIAssistantIndexQueryKey) {
            return useQuery([baseQueryKey, ],
            ProductRecommendationAIAssistantIndex, options);
        }
  
    
        
      export function useGetRecommendationMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<GetRecommendationSuccess, TError, {params: GetRecommendationParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    GetRecommendation(params, ), options)}
  
    
        
      export function useValidateFieldMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ValidateFieldSuccess, TError, {params: ValidateFieldParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ValidateField(params, ), options)}
  
    
        
      export function useProductReviewCreateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ProductReviewCreateSuccess, TError, {params: ProductReviewCreateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ProductReviewCreate(params, ), options)}
  
    
        
      export function useDeleteReviewMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteReviewSuccess, TError, {params: DeleteReviewParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeleteReview(params, ), options)}
  
    
        
      export function useAddReviewLikeMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddReviewLikeSuccess, TError, {params: AddReviewLikeParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddReviewLike(params, ), options)}
  
    
        
    export const ProductReviewSummaryIndexQueryKey = 'all_ProductReviewSummaryIndex';

    export function useProductReviewSummaryIndexQuery<TError = unknown>(params: ProductReviewSummaryIndexParams, options?: Omit<UseQueryOptions<ProductReviewSummaryIndexSuccess, TError, ProductReviewSummaryIndexSuccess, [string, ProductReviewSummaryIndexParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ProductReviewSummaryIndexSuccess | (() => ProductReviewSummaryIndexSuccess);},  baseQueryKey = ProductReviewSummaryIndexQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ProductReviewSummaryIndex(params), options);
        }
  
    
        
    export const GetQuantityQueryKey = 'all_GetQuantity';

    export function useGetQuantityQuery<TError = unknown>(params: GetQuantityParams, options?: Omit<UseQueryOptions<GetQuantitySuccess, TError, GetQuantitySuccess, [string, GetQuantityParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetQuantitySuccess | (() => GetQuantitySuccess);},  baseQueryKey = GetQuantityQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetQuantity(params), options);
        }
  
    
        
    export const GetQuestionnaireDataQueryKey = 'all_GetQuestionnaireData';

    export function useGetQuestionnaireDataQuery<TError = unknown>(params: GetQuestionnaireDataParams, options?: Omit<UseQueryOptions<GetQuestionnaireDataSuccess, TError, GetQuestionnaireDataSuccess, [string, GetQuestionnaireDataParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetQuestionnaireDataSuccess | (() => GetQuestionnaireDataSuccess);},  baseQueryKey = GetQuestionnaireDataQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetQuestionnaireData(params), options);
        }
  
    
        
      export function useSubmitQuestionnaireDataMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SubmitQuestionnaireDataSuccess, TError, {params: SubmitQuestionnaireDataParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SubmitQuestionnaireData(params, ), options)}
  
    
        
    export const QuestionnaireResultListViewModelQueryKey = 'all_QuestionnaireResultListViewModel';

    export function useQuestionnaireResultListViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<QuestionnaireResultListViewModelSuccess, TError, QuestionnaireResultListViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: QuestionnaireResultListViewModelSuccess | (() => QuestionnaireResultListViewModelSuccess);},  baseQueryKey = QuestionnaireResultListViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            QuestionnaireResultListViewModel, options);
        }
  
    
        
    export const QuickGetModelQueryKey = 'all_QuickGetModel';

    export function useQuickGetModelQuery<TError = unknown>(params: QuickGetModelParams, options?: Omit<UseQueryOptions<QuickGetModelSuccess, TError, QuickGetModelSuccess, [string, QuickGetModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: QuickGetModelSuccess | (() => QuickGetModelSuccess);},  baseQueryKey = QuickGetModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => QuickGetModel(params), options);
        }
  
    
        
      export function useCreateOrderMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: CreateOrderRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    CreateOrder( requestBody), options)}
  
    
        
    export const RebuyGetQueryKey = 'all_RebuyGet';

    export function useRebuyGetQuery<TError = unknown>(params: RebuyGetParams, options?: Omit<UseQueryOptions<RebuyGetSuccess, TError, RebuyGetSuccess, [string, RebuyGetParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: RebuyGetSuccess | (() => RebuyGetSuccess);},  baseQueryKey = RebuyGetQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => RebuyGet(params), options);
        }
  
    
        
    export const OrderDetailsQueryKey = 'all_OrderDetails';

    export function useOrderDetailsQuery<TError = unknown>(params: OrderDetailsParams, options?: Omit<UseQueryOptions<OrderDetailsSuccess, TError, OrderDetailsSuccess, [string, OrderDetailsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: OrderDetailsSuccess | (() => OrderDetailsSuccess);},  baseQueryKey = OrderDetailsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => OrderDetails(params), options);
        }
  
    
        
    export const CreateOrderDetailsPdfQueryKey = 'all_CreateOrderDetailsPdf';

    export function useCreateOrderDetailsPdfQuery<TError = unknown>(params: CreateOrderDetailsPdfParams, options?: Omit<UseQueryOptions<CreateOrderDetailsPdfSuccess, TError, CreateOrderDetailsPdfSuccess, [string, CreateOrderDetailsPdfParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: CreateOrderDetailsPdfSuccess | (() => CreateOrderDetailsPdfSuccess);},  baseQueryKey = CreateOrderDetailsPdfQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => CreateOrderDetailsPdf(params), options);
        }
  
    
        
    export const GetByProductIdQueryKey = 'all_GetByProductId';

    export function useGetByProductIdQuery<TError = unknown>(params: GetByProductIdParams, options?: Omit<UseQueryOptions<GetByProductIdSuccess, TError, GetByProductIdSuccess, [string, GetByProductIdParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetByProductIdSuccess | (() => GetByProductIdSuccess);},  baseQueryKey = GetByProductIdQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetByProductId(params), options);
        }
  
    
        
    export const GetByBasketQueryKey = 'all_GetByBasket';

    export function useGetByBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetByBasketSuccess, TError, GetByBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetByBasketSuccess | (() => GetByBasketSuccess);},  baseQueryKey = GetByBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            GetByBasket, options);
        }
  
    
        
    export const GetByProductGroupIdQueryKey = 'all_GetByProductGroupId';

    export function useGetByProductGroupIdQuery<TError = unknown>(params: GetByProductGroupIdParams, options?: Omit<UseQueryOptions<GetByProductGroupIdSuccess, TError, GetByProductGroupIdSuccess, [string, GetByProductGroupIdParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetByProductGroupIdSuccess | (() => GetByProductGroupIdSuccess);},  baseQueryKey = GetByProductGroupIdQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetByProductGroupId(params), options);
        }
  
    
        
    export const GetBySearchResultQueryKey = 'all_GetBySearchResult';

    export function useGetBySearchResultQuery<TError = unknown>(params: GetBySearchResultParams, options?: Omit<UseQueryOptions<GetBySearchResultSuccess, TError, GetBySearchResultSuccess, [string, GetBySearchResultParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetBySearchResultSuccess | (() => GetBySearchResultSuccess);},  baseQueryKey = GetBySearchResultQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetBySearchResult(params), options);
        }
  
    
        
    export const GetByZeroSearchResultQueryKey = 'all_GetByZeroSearchResult';

    export function useGetByZeroSearchResultQuery<TError = unknown>(params: GetByZeroSearchResultParams, options?: Omit<UseQueryOptions<GetByZeroSearchResultSuccess, TError, GetByZeroSearchResultSuccess, [string, GetByZeroSearchResultParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetByZeroSearchResultSuccess | (() => GetByZeroSearchResultSuccess);},  baseQueryKey = GetByZeroSearchResultQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetByZeroSearchResult(params), options);
        }
  
    
        
    export const GetByHomePageQueryKey = 'all_GetByHomePage';

    export function useGetByHomePageQuery<TError = unknown>(params: GetByHomePageParams, options?: Omit<UseQueryOptions<GetByHomePageSuccess, TError, GetByHomePageSuccess, [string, GetByHomePageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetByHomePageSuccess | (() => GetByHomePageSuccess);},  baseQueryKey = GetByHomePageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetByHomePage(params), options);
        }
  
    
        
    export const GetByPowerstepQueryKey = 'all_GetByPowerstep';

    export function useGetByPowerstepQuery<TError = unknown>(params: GetByPowerstepParams, options?: Omit<UseQueryOptions<GetByPowerstepSuccess, TError, GetByPowerstepSuccess, [string, GetByPowerstepParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetByPowerstepSuccess | (() => GetByPowerstepSuccess);},  baseQueryKey = GetByPowerstepQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetByPowerstep(params), options);
        }
  
    
        
    export const GetByPowerstepTwoQueryKey = 'all_GetByPowerstepTwo';

    export function useGetByPowerstepTwoQuery<TError = unknown>(params: GetByPowerstepTwoParams, options?: Omit<UseQueryOptions<GetByPowerstepTwoSuccess, TError, GetByPowerstepTwoSuccess, [string, GetByPowerstepTwoParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetByPowerstepTwoSuccess | (() => GetByPowerstepTwoSuccess);},  baseQueryKey = GetByPowerstepTwoQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetByPowerstepTwo(params), options);
        }
  
    
        
    export const GetCustomQueryKey = 'all_GetCustom';

    export function useGetCustomQuery<TError = unknown>(params: GetCustomParams, options?: Omit<UseQueryOptions<GetCustomSuccess, TError, GetCustomSuccess, [string, GetCustomParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetCustomSuccess | (() => GetCustomSuccess);},  baseQueryKey = GetCustomQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetCustom(params), options);
        }
  
    
        
    export const GetForReceiptPageQueryKey = 'all_GetForReceiptPage';

    export function useGetForReceiptPageQuery<TError = unknown>(params: GetForReceiptPageParams, options?: Omit<UseQueryOptions<GetForReceiptPageSuccess, TError, GetForReceiptPageSuccess, [string, GetForReceiptPageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetForReceiptPageSuccess | (() => GetForReceiptPageSuccess);},  baseQueryKey = GetForReceiptPageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetForReceiptPage(params), options);
        }
  
    
        
    export const RecommendationRequestModelQueryKey = 'all_RecommendationRequestModel';

    export function useRecommendationRequestModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<RecommendationRequestModelSuccess, TError, RecommendationRequestModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: RecommendationRequestModelSuccess | (() => RecommendationRequestModelSuccess);},  baseQueryKey = RecommendationRequestModelQueryKey) {
            return useQuery([baseQueryKey, ],
            RecommendationRequestModel, options);
        }
  
    
        
    export const ReviewRequestViewModelQueryKey = 'all_ReviewRequestViewModel';

    export function useReviewRequestViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ReviewRequestViewModelSuccess, TError, ReviewRequestViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ReviewRequestViewModelSuccess | (() => ReviewRequestViewModelSuccess);},  baseQueryKey = ReviewRequestViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ReviewRequestViewModel, options);
        }
  
    
        
    export const QuickSearchQueryKey = 'all_QuickSearch';

    export function useQuickSearchQuery<TError = unknown>(params: QuickSearchParams, options?: Omit<UseQueryOptions<QuickSearchSuccess, TError, QuickSearchSuccess, [string, QuickSearchParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: QuickSearchSuccess | (() => QuickSearchSuccess);},  baseQueryKey = QuickSearchQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => QuickSearch(params), options);
        }
  
    
        
    export const GetSearchViewModelQueryKey = 'all_GetSearchViewModel';

    export function useGetSearchViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetSearchViewModelSuccess, TError, GetSearchViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetSearchViewModelSuccess | (() => GetSearchViewModelSuccess);},  baseQueryKey = GetSearchViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GetSearchViewModel, options);
        }
  
    
        
    export const GetConfirmDialogueQueryKey = 'all_GetConfirmDialogue';

    export function useGetConfirmDialogueQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetConfirmDialogueSuccess, TError, GetConfirmDialogueSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetConfirmDialogueSuccess | (() => GetConfirmDialogueSuccess);},  baseQueryKey = GetConfirmDialogueQueryKey) {
            return useQuery([baseQueryKey, ],
            GetConfirmDialogue, options);
        }
  
    
        
      export function useDoSignoffMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DoSignoffSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    DoSignoff( ), options)}
  
    
        
    export const ModelForBasketQueryKey = 'all_ModelForBasket';

    export function useModelForBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<ModelForBasketSuccess, TError, ModelForBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ModelForBasketSuccess | (() => ModelForBasketSuccess);},  baseQueryKey = ModelForBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            ModelForBasket, options);
        }
  
    
        
    export const ModelForCheckoutQueryKey = 'all_ModelForCheckout';

    export function useModelForCheckoutQuery<TError = unknown>( options?: Omit<UseQueryOptions<ModelForCheckoutSuccess, TError, ModelForCheckoutSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ModelForCheckoutSuccess | (() => ModelForCheckoutSuccess);},  baseQueryKey = ModelForCheckoutQueryKey) {
            return useQuery([baseQueryKey, ],
            ModelForCheckout, options);
        }
  
    
        
    export const ClubMatasTermsQueryKey = 'all_ClubMatasTerms';

    export function useClubMatasTermsQuery<TError = unknown>( options?: Omit<UseQueryOptions<ClubMatasTermsSuccess, TError, ClubMatasTermsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ClubMatasTermsSuccess | (() => ClubMatasTermsSuccess);},  baseQueryKey = ClubMatasTermsQueryKey) {
            return useQuery([baseQueryKey, ],
            ClubMatasTerms, options);
        }
  
    
        
      export function useTogglePlusSignupMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<TogglePlusSignupSuccess, TError, {params: TogglePlusSignupParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    TogglePlusSignup(params, ), options)}
  
    
        
      export function useToggleClubSignupMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: ToggleClubSignupParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ToggleClubSignup(params, ), options)}
  
    
        
    export const DisclaimerQueryKey = 'all_Disclaimer';

    export function useDisclaimerQuery<TError = unknown>(params: DisclaimerParams, options?: Omit<UseQueryOptions<DisclaimerSuccess, TError, DisclaimerSuccess, [string, DisclaimerParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: DisclaimerSuccess | (() => DisclaimerSuccess);},  baseQueryKey = DisclaimerQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => Disclaimer(params), options);
        }
  
    
        
      export function useStateUpdateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<StateUpdateSuccess, TError, {params: StateUpdateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    StateUpdate(params, ), options)}
  
    
        
      export function useValidateFieldForShippingMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: ValidateFieldForShippingParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ValidateFieldForShipping(params, ), options)}
  
    
        
      export function useValidateFieldForTypedAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: ValidateFieldForTypedAddressParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ValidateFieldForTypedAddress(params, ), options)}
  
    
        
      export function useValidateInvoiceAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ValidateInvoiceAddressSuccess, TError, {params: ValidateInvoiceAddressParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ValidateInvoiceAddress(params, ), options)}
  
    
        
    export const GetStaticImageQueryKey = 'all_GetStaticImage';

    export function useGetStaticImageQuery<TError = unknown>(params: GetStaticImageParams, options?: Omit<UseQueryOptions<GetStaticImageSuccess, TError, GetStaticImageSuccess, [string, GetStaticImageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetStaticImageSuccess | (() => GetStaticImageSuccess);},  baseQueryKey = GetStaticImageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetStaticImage(params), options);
        }
  
    
        
    export const SearchStoreByAddressQueryKey = 'all_SearchStoreByAddress';

    export function useSearchStoreByAddressQuery<TError = unknown>(params: SearchStoreByAddressParams, options?: Omit<UseQueryOptions<SearchStoreByAddressSuccess, TError, SearchStoreByAddressSuccess, [string, SearchStoreByAddressParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SearchStoreByAddressSuccess | (() => SearchStoreByAddressSuccess);},  baseQueryKey = SearchStoreByAddressQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SearchStoreByAddress(params), options);
        }
  
    
        
    export const SearchStoreByCoordinatesQueryKey = 'all_SearchStoreByCoordinates';

    export function useSearchStoreByCoordinatesQuery<TError = unknown>(params: SearchStoreByCoordinatesParams, options?: Omit<UseQueryOptions<SearchStoreByCoordinatesSuccess, TError, SearchStoreByCoordinatesSuccess, [string, SearchStoreByCoordinatesParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SearchStoreByCoordinatesSuccess | (() => SearchStoreByCoordinatesSuccess);},  baseQueryKey = SearchStoreByCoordinatesQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SearchStoreByCoordinates(params), options);
        }
  
    
        
      export function useValidateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ValidateSuccess, TError, {params: ValidateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    Validate(params, ), options)}
  
    
        
    export const _GetSelectedProductQueryKey = 'all__GetSelectedProduct';

    export function use_GetSelectedProductQuery<TError = unknown>(params: _GetSelectedProductParams, options?: Omit<UseQueryOptions<_GetSelectedProductSuccess, TError, _GetSelectedProductSuccess, [string, _GetSelectedProductParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _GetSelectedProductSuccess | (() => _GetSelectedProductSuccess);},  baseQueryKey = _GetSelectedProductQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _GetSelectedProduct(params), options);
        }
  
    
        
      export function useSetUserPreferredStoreMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SetUserPreferredStoreSuccess, TError, {params: SetUserPreferredStoreParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SetUserPreferredStore(params, ), options)}
  
    
        
    export const GetProductStoreStockModelQueryKey = 'all_GetProductStoreStockModel';

    export function useGetProductStoreStockModelQuery<TError = unknown>(params: GetProductStoreStockModelParams, options?: Omit<UseQueryOptions<GetProductStoreStockModelSuccess, TError, GetProductStoreStockModelSuccess, [string, GetProductStoreStockModelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetProductStoreStockModelSuccess | (() => GetProductStoreStockModelSuccess);},  baseQueryKey = GetProductStoreStockModelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetProductStoreStockModel(params), options);
        }
  
    
        
    export const UpdateStoreViewModelQueryKey = 'all_UpdateStoreViewModel';

    export function useUpdateStoreViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = UpdateStoreViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            UpdateStoreViewModel, options);
        }
  
    
        
    export const StoreGetQueryKey = 'all_StoreGet';

    export function useStoreGetQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = StoreGetQueryKey) {
            return useQuery([baseQueryKey, ],
            StoreGet, options);
        }
  
    
        
      export function useChooseMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ChooseSuccess, TError, {params: ChooseParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    Choose(params, ), options)}
  
    
        
    export const StoreSearchQueryKey = 'all_StoreSearch';

    export function useStoreSearchQuery<TError = unknown>(params: StoreSearchParams, options?: Omit<UseQueryOptions<StoreSearchSuccess, TError, StoreSearchSuccess, [string, StoreSearchParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: StoreSearchSuccess | (() => StoreSearchSuccess);},  baseQueryKey = StoreSearchQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => StoreSearch(params), options);
        }
  
    
        
    export const ListStoresQueryKey = 'all_ListStores';

    export function useListStoresQuery<TError = unknown>(params: ListStoresParams, options?: Omit<UseQueryOptions<ListStoresSuccess, TError, ListStoresSuccess, [string, ListStoresParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ListStoresSuccess | (() => ListStoresSuccess);},  baseQueryKey = ListStoresQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ListStores(params), options);
        }
  
    
        
    export const _SubscriptionProductModalQueryKey = 'all__SubscriptionProductModal';

    export function use_SubscriptionProductModalQuery<TError = unknown>(params: _SubscriptionProductModalParams, options?: Omit<UseQueryOptions<_SubscriptionProductModalSuccess, TError, _SubscriptionProductModalSuccess, [string, _SubscriptionProductModalParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _SubscriptionProductModalSuccess | (() => _SubscriptionProductModalSuccess);},  baseQueryKey = _SubscriptionProductModalQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _SubscriptionProductModal(params), options);
        }
  
    
        
      export function useCreatePendingSubscriptionMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CreatePendingSubscriptionSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    CreatePendingSubscription( ), options)}
  
    
        
      export function useGetPosSubscriptionInformationMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<GetPosSubscriptionInformationSuccess, TError, {requestBody: GetPosSubscriptionInformationRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    GetPosSubscriptionInformation( requestBody), options)}
  
    
        
    export const OverviewModelQueryKey = 'all_OverviewModel';

    export function useOverviewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<OverviewModelSuccess, TError, OverviewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: OverviewModelSuccess | (() => OverviewModelSuccess);},  baseQueryKey = OverviewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            OverviewModel, options);
        }
  
    
        
    export const GetAllQueryKey = 'all_GetAll';

    export function useGetAllQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetAllSuccess, TError, GetAllSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetAllSuccess | (() => GetAllSuccess);},  baseQueryKey = GetAllQueryKey) {
            return useQuery([baseQueryKey, ],
            GetAll, options);
        }
  
    
        
    export const SubscriptionDeliveryDeliveryGroupModelQueryKey = 'all_SubscriptionDeliveryDeliveryGroupModel';

    export function useSubscriptionDeliveryDeliveryGroupModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = SubscriptionDeliveryDeliveryGroupModelQueryKey) {
            return useQuery([baseQueryKey, ],
            SubscriptionDeliveryDeliveryGroupModel, options);
        }
  
    
        
    export const SubscriptionDeliveryGetPrivateAddressModelQueryKey = 'all_SubscriptionDeliveryGetPrivateAddressModel';

    export function useSubscriptionDeliveryGetPrivateAddressModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = SubscriptionDeliveryGetPrivateAddressModelQueryKey) {
            return useQuery([baseQueryKey, ],
            SubscriptionDeliveryGetPrivateAddressModel, options);
        }
  
    
        
      export function useSubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess, TError, {params: SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddress(params, ), options)}
  
    
        
      export function useSubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess, TError, {params: SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddress(params, ), options)}
  
    
        
      export function useSubscriptionDeliverySelectAndUpdateParcelShopDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess, TError, {params: SubscriptionDeliverySelectAndUpdateParcelShopDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SubscriptionDeliverySelectAndUpdateParcelShopDelivery(params, ), options)}
  
    
        
      export function useSubscriptionDeliverySelectMatasDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SubscriptionDeliverySelectMatasDeliverySuccess, TError, {params: SubscriptionDeliverySelectMatasDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SubscriptionDeliverySelectMatasDelivery(params, ), options)}
  
    
        
      export function useSubscriptionDeliverySelectAndUpdateMatasDeliveryMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess, TError, {params: SubscriptionDeliverySelectAndUpdateMatasDeliveryParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SubscriptionDeliverySelectAndUpdateMatasDelivery(params, ), options)}
  
    
        
    export const SubscriptionDeliveryListMatasParcelShopsQueryKey = 'all_SubscriptionDeliveryListMatasParcelShops';

    export function useSubscriptionDeliveryListMatasParcelShopsQuery<TError = unknown>(params: SubscriptionDeliveryListMatasParcelShopsParams, options?: Omit<UseQueryOptions<SubscriptionDeliveryListMatasParcelShopsSuccess, TError, SubscriptionDeliveryListMatasParcelShopsSuccess, [string, SubscriptionDeliveryListMatasParcelShopsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SubscriptionDeliveryListMatasParcelShopsSuccess | (() => SubscriptionDeliveryListMatasParcelShopsSuccess);},  baseQueryKey = SubscriptionDeliveryListMatasParcelShopsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SubscriptionDeliveryListMatasParcelShops(params), options);
        }
  
    
        
    export const SubscriptionDeliveryListParcelShopsQueryKey = 'all_SubscriptionDeliveryListParcelShops';

    export function useSubscriptionDeliveryListParcelShopsQuery<TError = unknown>(params: SubscriptionDeliveryListParcelShopsParams, options?: Omit<UseQueryOptions<SubscriptionDeliveryListParcelShopsSuccess, TError, SubscriptionDeliveryListParcelShopsSuccess, [string, SubscriptionDeliveryListParcelShopsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SubscriptionDeliveryListParcelShopsSuccess | (() => SubscriptionDeliveryListParcelShopsSuccess);},  baseQueryKey = SubscriptionDeliveryListParcelShopsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SubscriptionDeliveryListParcelShops(params), options);
        }
  
    
        
    export const SubscriptionDeliveryCompanyDeliveryAddressQueryKey = 'all_SubscriptionDeliveryCompanyDeliveryAddress';

    export function useSubscriptionDeliveryCompanyDeliveryAddressQuery<TError = unknown>( options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = SubscriptionDeliveryCompanyDeliveryAddressQueryKey) {
            return useQuery([baseQueryKey, ],
            SubscriptionDeliveryCompanyDeliveryAddress, options);
        }
  
    
        
      export function useSubscriptionDeliveryValidateFieldForShippingSwaggerMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess, TError, {params: SubscriptionDeliveryValidateFieldForShippingSwaggerParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SubscriptionDeliveryValidateFieldForShippingSwagger(params, ), options)}
  
    
        
    export const GetDetailsQueryKey = 'all_GetDetails';

    export function useGetDetailsQuery<TError = unknown>(params: GetDetailsParams, options?: Omit<UseQueryOptions<GetDetailsSuccess, TError, GetDetailsSuccess, [string, GetDetailsParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetDetailsSuccess | (() => GetDetailsSuccess);},  baseQueryKey = GetDetailsQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetDetails(params), options);
        }
  
    
        
    export const GetMoreQueryKey = 'all_GetMore';

    export function useGetMoreQuery<TError = unknown>(params: GetMoreParams, options?: Omit<UseQueryOptions<GetMoreSuccess, TError, GetMoreSuccess, [string, GetMoreParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetMoreSuccess | (() => GetMoreSuccess);},  baseQueryKey = GetMoreQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetMore(params), options);
        }
  
    
        
    export const GetAdhocOrderQueryKey = 'all_GetAdhocOrder';

    export function useGetAdhocOrderQuery<TError = unknown>(params: GetAdhocOrderParams, options?: Omit<UseQueryOptions<GetAdhocOrderSuccess, TError, GetAdhocOrderSuccess, [string, GetAdhocOrderParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetAdhocOrderSuccess | (() => GetAdhocOrderSuccess);},  baseQueryKey = GetAdhocOrderQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetAdhocOrder(params), options);
        }
  
    
        
      export function useUpdateAdhocOrderMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateAdhocOrderSuccess, TError, {params: UpdateAdhocOrderParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateAdhocOrder(params, ), options)}
  
    
        
    export const GetConfirmUpdateInfoQueryKey = 'all_GetConfirmUpdateInfo';

    export function useGetConfirmUpdateInfoQuery<TError = unknown>(params: GetConfirmUpdateInfoParams, options?: Omit<UseQueryOptions<GetConfirmUpdateInfoSuccess, TError, GetConfirmUpdateInfoSuccess, [string, GetConfirmUpdateInfoParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetConfirmUpdateInfoSuccess | (() => GetConfirmUpdateInfoSuccess);},  baseQueryKey = GetConfirmUpdateInfoQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetConfirmUpdateInfo(params), options);
        }
  
    
        
    export const GetCancelQueryKey = 'all_GetCancel';

    export function useGetCancelQuery<TError = unknown>(params: GetCancelParams, options?: Omit<UseQueryOptions<GetCancelSuccess, TError, GetCancelSuccess, [string, GetCancelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetCancelSuccess | (() => GetCancelSuccess);},  baseQueryKey = GetCancelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetCancel(params), options);
        }
  
    
        
    export const SubscriptionUpdateConfirmCancelQueryKey = 'all_SubscriptionUpdateConfirmCancel';

    export function useSubscriptionUpdateConfirmCancelQuery<TError = unknown>(params: SubscriptionUpdateConfirmCancelParams, options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, SubscriptionUpdateConfirmCancelParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = SubscriptionUpdateConfirmCancelQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SubscriptionUpdateConfirmCancel(params), options);
        }
  
    
        
    export const OnHoldQueryKey = 'all_OnHold';

    export function useOnHoldQuery<TError = unknown>(params: OnHoldParams, options?: Omit<UseQueryOptions<OnHoldSuccess, TError, OnHoldSuccess, [string, OnHoldParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: OnHoldSuccess | (() => OnHoldSuccess);},  baseQueryKey = OnHoldQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => OnHold(params), options);
        }
  
    
        
    export const SubscriptionUpdateUpdateQueryKey = 'all_SubscriptionUpdateUpdate';

    export function useSubscriptionUpdateUpdateQuery<TError = unknown>(params: SubscriptionUpdateUpdateParams, options?: Omit<UseQueryOptions<SubscriptionUpdateUpdateSuccess, TError, SubscriptionUpdateUpdateSuccess, [string, SubscriptionUpdateUpdateParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SubscriptionUpdateUpdateSuccess | (() => SubscriptionUpdateUpdateSuccess);},  baseQueryKey = SubscriptionUpdateUpdateQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SubscriptionUpdateUpdate(params), options);
        }
  
    
        
    export const GetSubscriptionPriceTotalQueryKey = 'all_GetSubscriptionPriceTotal';

    export function useGetSubscriptionPriceTotalQuery<TError = unknown>(params: GetSubscriptionPriceTotalParams, options?: Omit<UseQueryOptions<GetSubscriptionPriceTotalSuccess, TError, GetSubscriptionPriceTotalSuccess, [string, GetSubscriptionPriceTotalParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetSubscriptionPriceTotalSuccess | (() => GetSubscriptionPriceTotalSuccess);},  baseQueryKey = GetSubscriptionPriceTotalQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetSubscriptionPriceTotal(params), options);
        }
  
    
        
    export const GetGroupedBasketQueryKey = 'all_GetGroupedBasket';

    export function useGetGroupedBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetGroupedBasketSuccess, TError, GetGroupedBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetGroupedBasketSuccess | (() => GetGroupedBasketSuccess);},  baseQueryKey = GetGroupedBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            GetGroupedBasket, options);
        }
  
    
        
      export function useUpdateHasUserCommentMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: UpdateHasUserCommentParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateHasUserComment(params, ), options)}
  
    
        
      export function useActivateGiftCardMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ActivateGiftCardSuccess, TError, {params: ActivateGiftCardParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ActivateGiftCard(params, ), options)}
  
    
        
      export function useRemoveGiftCardLineMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RemoveGiftCardLineSuccess, TError, {params: RemoveGiftCardLineParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    RemoveGiftCardLine(params, ), options)}
  
    
        
      export function useActivatePaymentByGiftCardMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ActivatePaymentByGiftCardSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    ActivatePaymentByGiftCard( ), options)}
  
    
        
      export function useDeactivatePaymentByGiftCardMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeactivatePaymentByGiftCardSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    DeactivatePaymentByGiftCard( ), options)}
  
    
        
      export function useAddGiftCardLineMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddGiftCardLineSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    AddGiftCardLine( ), options)}
  
    
        
    export const GetGiftCardInfoQueryKey = 'all_GetGiftCardInfo';

    export function useGetGiftCardInfoQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetGiftCardInfoSuccess, TError, GetGiftCardInfoSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetGiftCardInfoSuccess | (() => GetGiftCardInfoSuccess);},  baseQueryKey = GetGiftCardInfoQueryKey) {
            return useQuery([baseQueryKey, ],
            GetGiftCardInfo, options);
        }
  
    
        
    export const GetUserCommentInfoQueryKey = 'all_GetUserCommentInfo';

    export function useGetUserCommentInfoQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetUserCommentInfoSuccess, TError, GetUserCommentInfoSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetUserCommentInfoSuccess | (() => GetUserCommentInfoSuccess);},  baseQueryKey = GetUserCommentInfoQueryKey) {
            return useQuery([baseQueryKey, ],
            GetUserCommentInfo, options);
        }
  
    
        
      export function useCompleteSurveyMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<CompleteSurveySuccess, TError, {requestBody: CompleteSurveyRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    CompleteSurvey( requestBody), options)}
  
    
        
    export const SurveyResponseIndexQueryKey = 'all_SurveyResponseIndex';

    export function useSurveyResponseIndexQuery<TError = unknown>(params: SurveyResponseIndexParams, options?: Omit<UseQueryOptions<SurveyResponseIndexSuccess, TError, SurveyResponseIndexSuccess, [string, SurveyResponseIndexParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: SurveyResponseIndexSuccess | (() => SurveyResponseIndexSuccess);},  baseQueryKey = SurveyResponseIndexQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => SurveyResponseIndex(params), options);
        }
  
    
        
      export function useSurveyResponseCreateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SurveyResponseCreateSuccess, TError, {params: SurveyResponseCreateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    SurveyResponseCreate(params, ), options)}
  
    
        
      export function useSurveyResponseUpdateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SurveyResponseUpdateSuccess, TError, {requestBody: SurveyResponseUpdateRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    SurveyResponseUpdate( requestBody), options)}
  
    
        
    export const TermIndexQueryKey = 'all_TermIndex';

    export function useTermIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<TermIndexSuccess, TError, TermIndexSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: TermIndexSuccess | (() => TermIndexSuccess);},  baseQueryKey = TermIndexQueryKey) {
            return useQuery([baseQueryKey, ],
            TermIndex, options);
        }
  
    
        
      export function useHideMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<HideSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    Hide( ), options)}
  
    
        
    export const TopBannerIndexQueryKey = 'all_TopBannerIndex';

    export function useTopBannerIndexQuery<TError = unknown>(params: TopBannerIndexParams, options?: Omit<UseQueryOptions<TopBannerIndexSuccess, TError, TopBannerIndexSuccess, [string, TopBannerIndexParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: TopBannerIndexSuccess | (() => TopBannerIndexSuccess);},  baseQueryKey = TopBannerIndexQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => TopBannerIndex(params), options);
        }
  
    
        
      export function useTrackAdformClickMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: TrackAdformClickRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    TrackAdformClick( requestBody), options)}
  
    
        
      export function useTrackAdformImpressionsMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: TrackAdformImpressionsRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    TrackAdformImpressions( requestBody), options)}
  
    
        
      export function useTrackEventMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: TrackEventRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    TrackEvent( requestBody), options)}
  
    
        
      export function useTrackEnhancedEcommercePromotionImpressionEventMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: TrackEnhancedEcommercePromotionImpressionEventRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    TrackEnhancedEcommercePromotionImpressionEvent( requestBody), options)}
  
    
        
      export function useVisualSearchResultIndexMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {requestBody: VisualSearchResultIndexRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    VisualSearchResultIndex( requestBody), options)}
  
    
        
    export const FollowBarCodeQueryKey = 'all_FollowBarCode';

    export function useFollowBarCodeQuery<TError = unknown>(params: FollowBarCodeParams, options?: Omit<UseQueryOptions<unknown, TError, unknown, [string, FollowBarCodeParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: unknown | (() => unknown);},  baseQueryKey = FollowBarCodeQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => FollowBarCode(params), options);
        }
  
    
        
    export const WrappingIndexQueryKey = 'all_WrappingIndex';

    export function useWrappingIndexQuery<TError = unknown>(params: WrappingIndexParams, options?: Omit<UseQueryOptions<WrappingIndexSuccess, TError, WrappingIndexSuccess, [string, WrappingIndexParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: WrappingIndexSuccess | (() => WrappingIndexSuccess);},  baseQueryKey = WrappingIndexQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => WrappingIndex(params), options);
        }
  
    
        
      export function useDeleteAllMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteAllSuccess, TError, {params: DeleteAllParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeleteAll(params, ), options)}
  
    
        
      export function use_UpdateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<_UpdateSuccess, TError, {requestBody: _UpdateRequestBody} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ requestBody}) =>
    _Update( requestBody), options)}
  
    
    