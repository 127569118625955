import useOnKeyPressEffect from './useOnKeyPressEffect';

const escapeKey = 'Escape';

export default function useEscapeKeyEffect(onEscape: () => void) {
    useOnKeyPressEffect((keyType) => {
        if (keyType === escapeKey) {
            onEscape();
        }
    });
}
