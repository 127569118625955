/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Overview from './trans-website-myprofile-subscription-overview';
export * as Details from './trans-website-myprofile-subscription-details';
export * as OnHold from './trans-website-myprofile-subscription-onhold';
export * as Update from './trans-website-myprofile-subscription-update';
export * as Cancel from './trans-website-myprofile-subscription-cancel';
export * as NoSubscriptionsPage from './trans-website-myprofile-subscription-nosubscriptionspage';

export const ReadMore = 'Website.MyProfile.Subscription.ReadMore';
export const ReadTerms = 'Website.MyProfile.Subscription.ReadTerms';
export const ShowMore = 'Website.MyProfile.Subscription.ShowMore';
export const EditPayment = 'Website.MyProfile.Subscription.EditPayment';
export const EditDelivery = 'Website.MyProfile.Subscription.EditDelivery';
export const EditSubscriptionDelivery = 'Website.MyProfile.Subscription.EditSubscriptionDelivery';
