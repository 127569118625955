/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as NotificationSubscriptions from './trans-website-liveevent-notificationsubscriptions';
export * as Banner from './trans-website-liveevent-banner';
export * as CmsArticle from './trans-website-liveevent-cmsarticle';

export const SeeLive = 'Website.LiveEvent.SeeLive';
export const SeeLiveNow = 'Website.LiveEvent.SeeLiveNow';
export const RemindMe = 'Website.LiveEvent.RemindMe';
export const RemindMeAbout = 'Website.LiveEvent.RemindMeAbout';
export const Email = 'Website.LiveEvent.Email';
export const Message = 'Website.LiveEvent.Message';
export const Phone = 'Website.LiveEvent.Phone';
export const CloseWindow = 'Website.LiveEvent.CloseWindow';
export const ReminderSaved = 'Website.LiveEvent.ReminderSaved';
export const ReminderSavedSubText = 'Website.LiveEvent.ReminderSavedSubText';
export const ReminderDisclaimer = 'Website.LiveEvent.ReminderDisclaimer';
export const ReminderDisclaimerSMS = 'Website.LiveEvent.ReminderDisclaimerSMS';
export const ShowLives = 'Website.LiveEvent.ShowLives';
export const FeaturedSliderHeader = 'Website.LiveEvent.FeaturedSliderHeader';
export const Participants = 'Website.LiveEvent.Participants';
