import { type HTMLAttributes, useEffect, useState } from 'react';
import { hashHistory } from 'modules/helpers/history';
import Default from 'modules/modal/layouts/defaultLayout';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import type { ICameraWarningCallback } from 'modules/quicksearch/sharedInterfaces/ICameraWarningCallback';
import mediator from 'modules/mediator';
import { ButtonWithIcon } from 'components/Button';
import dynamic from 'helpers/dynamic';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';
import { useTranslation } from 'react-i18next';
import { Camera } from 'autogen/translation-keys/trans-website-imagealttext-icons';
import { GA4Tracking } from 'modules/tracking/GA4';

const modalTheme = {
    maxHeight: '100%',
    maxWidth: '100%'
};

enum HashRoutes {
    VisualSearchPath = 'visualsearch',
    VisualSearchSkipIntroPath = 'visualsearchskipintro'
}

const VisualSearch = dynamic(() => import('modules/visualSearch/visualSearch'), { ssr: false });
const CameraIcon = dynamic(() => import('icons/Camera.svg?react'));

const StyledButtonWithIcon = styled(ButtonWithIcon)`
    ${breakpoint.up(sizes.lg)} {
        display: none;
    }
`;

export default function CameraButtonComponent({ className }: HTMLAttributes<HTMLDivElement>): JSX.Element {
    const [warningMessage, setWarningMessage] = useState<string | null>(null);
    const { t } = useTranslation();

    function cameraWarningCallback({ message }: ICameraWarningCallback): void {
        if (message) {
            setWarningMessage(message);
        }
    }

    async function openModal(): Promise<void> {
        const { default: createModal } = await import('modules/modal');
        const modal = createModal();
        const modalObjectTemplate = {
            content: <VisualSearch inModal modal={modal} warningCallback={cameraWarningCallback} />,
            open: true,
            layout: {
                component: <Default customTheme={modalTheme} />,
                header: null
            }
        };
        modal(modalObjectTemplate);
    }

    useEffect(() => {
        const unobserveHashHistoryChanges = hashHistory?.listen(({ location }) => {
            const { pathname } = location;

            if (pathname) {
                switch (pathname) {
                    case HashRoutes.VisualSearchSkipIntroPath:
                        localStorage.setItem('VSConditions', 'true');
                        break;
                    case HashRoutes.VisualSearchPath:
                        openModal();
                        break;
                    default:
                        break;
                }
            }
        });

        return (): any => {
            unobserveHashHistoryChanges(); // call to unlisten
        };
    }, []);

    return (
        <StyledButtonWithIcon
            className={className}
            icon={<CameraIcon width={20} height={20} aria-label={t(Camera)} />}
            type="button"
            variant="ghostOnLight"
            id="js-VSButton"
            onClick={() => {
                if (warningMessage !== null) {
                    mediator.publish('growl', {
                        message: warningMessage
                    });

                    return;
                }

                UAEventTracking({ eventCategory: 'visualSearch', eventAction: 'open', eventLabel: 'quicksearch' });
                GA4Tracking({
                    eventName: 'open_visual_search',
                    context: 'search',
                    category: 'ecommerce',
                    eventParams: [{ param_name: 'search_type', param_value: 'visual_search' }]
                });
                openModal();
            }}
        />
    );
}
