/* eslint-disable */
/// This file is auto generated - do not edit manually
export const Heading = 'Website.ProductReview.Create.Heading';
export const SubHeading = 'Website.ProductReview.Create.SubHeading';
export const ChooseNumberOfStars = 'Website.ProductReview.Create.ChooseNumberOfStars';
export const ChosenRating = 'Website.ProductReview.Create.ChosenRating';
export const HeadlineTitle = 'Website.ProductReview.Create.HeadlineTitle';
export const HeadlineDescription = 'Website.ProductReview.Create.HeadlineDescription';
export const HeadlinePlaceholder = 'Website.ProductReview.Create.HeadlinePlaceholder';
export const NoteTitle = 'Website.ProductReview.Create.NoteTitle';
export const NoteDescription = 'Website.ProductReview.Create.NoteDescription';
export const NotePlaceholder = 'Website.ProductReview.Create.NotePlaceholder';
export const RequiredDisclaimer = 'Website.ProductReview.Create.RequiredDisclaimer';
export const IsRecommended = 'Website.ProductReview.Create.IsRecommended';
export const IsPrelaunch = 'Website.ProductReview.Create.IsPrelaunch';
export const AcceptTerms = 'Website.ProductReview.Create.AcceptTerms';
export const PostReview = 'Website.ProductReview.Create.PostReview';
