import { mediatorAtom, useMediatorValue, useSetMediatorState } from 'hooks/globals/useMediatorAtom';

export const productListIntersectionAtom = mediatorAtom(false);

export default function useIsProductListIntersecting() {
    return useMediatorValue(productListIntersectionAtom);
}

export function useSetIsProductListIntersecting() {
    return useSetMediatorState(productListIntersectionAtom);
}
