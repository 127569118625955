import React from 'react';
import styled, { css } from 'styled-components';
import {
    ColorAdvice4,
    Spacing12,
    Spacing16,
    Spacing24,
    Spacing32,
    Spacing6,
    Spacing8
} from 'autogen/design-tokens/tokens';
import getTypeStyling from 'helpers/getTypeStyling';
import type { CardProps, CardSize } from '../shared/types';

const CardFooterSizes = {
    xs: css`
        padding: ${Spacing6} ${Spacing8};
    `,
    sm: css`
        padding: ${Spacing12};
    `,
    md: css`
        padding: ${Spacing16};
    `,
    lg: css`
        padding: ${Spacing16} ${Spacing24};
    `,
    xl: css`
        padding: ${Spacing24} ${Spacing32};
    `
};

const StyledCardFooter = styled.div<{ size?: CardProps['size'] }>`
    display: flex;
    background-color: ${ColorAdvice4};

    ${({ size, theme }) =>
        size
            ? getTypeStyling((resolvedSize: CardSize) => CardFooterSizes[resolvedSize])(size as CardSize)
            : getTypeStyling((resolvedSize: CardSize) => CardFooterSizes[resolvedSize])(theme.size)}
`;

export default function CardFooter({ children, ...restProps }: CardProps) {
    return <StyledCardFooter {...restProps}>{children}</StyledCardFooter>;
}
