
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './pendingsubscription';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type PendingSubscriptionState = components['schemas']['PendingSubscriptionState'];
                    export type ExplanationModel = components['schemas']['ExplanationModel'];
                    export type SubscriptionWeekInfo = components['schemas']['SubscriptionWeekInfo'];
                    export type SubscriptionFrequency = components['schemas']['SubscriptionFrequency'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type IProductImageMaybe = components['schemas']['IProductImageMaybe'];
                    export type PendingSubscriptionLineViewModel = components['schemas']['PendingSubscriptionLineViewModel'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type PaymentCardOptionModelMaybe = components['schemas']['PaymentCardOptionModelMaybe'];
                    export type WebsiteIcon = components['schemas']['WebsiteIcon'];
                    export type CreditCardModel = components['schemas']['CreditCardModel'];
                    export type UserMessageType = components['schemas']['UserMessageType'];
                    export type UserMessage = components['schemas']['UserMessage'];
                    export type UserMessageMaybe = components['schemas']['UserMessageMaybe'];
                    export type PendingSubscriptionActivationModel = components['schemas']['PendingSubscriptionActivationModel'];
                    

        
        

        export type ApproveParams = paths['/internal/myprofile/PendingSubscription/Approve']['get']['parameters']['query'];

        export type ApproveSuccess = paths['/internal/myprofile/PendingSubscription/Approve']['get']['responses']['200']['content']['text/plain'];

        export const ApproveUrl = '/internal/myprofile/PendingSubscription/Approve';

        
        export function Approve(params: ApproveParams): Promise<ApproveSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ApproveSuccess>(`/internal/myprofile/PendingSubscription/Approve${formatParams(params)}`)
        }
    
    
        

        export type PendingSubscriptionCancelParams = paths['/internal/myprofile/PendingSubscription/Cancel']['get']['parameters']['query'];

        export type PendingSubscriptionCancelSuccess = paths['/internal/myprofile/PendingSubscription/Cancel']['get']['responses']['200']['content']['text/plain'];

        export const PendingSubscriptionCancelUrl = '/internal/myprofile/PendingSubscription/Cancel';

        
        export function PendingSubscriptionCancel(params: PendingSubscriptionCancelParams): Promise<PendingSubscriptionCancelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PendingSubscriptionCancelSuccess>(`/internal/myprofile/PendingSubscription/Cancel${formatParams(params)}`)
        }
    
    
        

        export type PendingSubscriptionGetModelParams = paths['/internal/myprofile/PendingSubscription/GetModel']['get']['parameters']['query'];

        export type PendingSubscriptionGetModelSuccess = paths['/internal/myprofile/PendingSubscription/GetModel']['get']['responses']['200']['content']['text/plain'];

        export const PendingSubscriptionGetModelUrl = '/internal/myprofile/PendingSubscription/GetModel';

        
        export function PendingSubscriptionGetModel(params: PendingSubscriptionGetModelParams): Promise<PendingSubscriptionGetModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PendingSubscriptionGetModelSuccess>(`/internal/myprofile/PendingSubscription/GetModel${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    