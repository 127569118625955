import PageAction, { type PageActionModel } from 'components/shared/Routing/PageAction';
import styled from 'styled-components';
import type { ReactNode } from 'react';
import { actionWrapperStyling } from '../shared/styles';
import type { ListItemType, Size } from '../shared/types';

const ActionElement = styled(PageAction)<Pick<ListItemType, 'size'>>`
    ${actionWrapperStyling}
`;

type ActionWrapperProps = { size?: Size; action: PageActionModel; children?: ReactNode };

function ActionWrapper(props: ActionWrapperProps) {
    const { children, size = 'md', action, ...restProps } = props;

    return (
        <ActionElement action={action} size={size} tabIndex={0} {...restProps}>
            {children}
        </ActionElement>
    );
}

export default ActionWrapper;
