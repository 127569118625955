/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Entrance from './trans-website-basket-samples-entrance';
export * as SingleSample from './trans-website-basket-samples-singlesample';
export * as SamplesPicker from './trans-website-basket-samples-samplespicker';

export const SampleLineAchievementText = 'Website.Basket.Samples.SampleLineAchievementText';
export const ChooseYourSample = 'Website.Basket.Samples.ChooseYourSample';
export const SampleDecline = 'Website.Basket.Samples.SampleDecline';
export const Free = 'Website.Basket.Samples.Free';
export const Change = 'Website.Basket.Samples.Change';
export const Chosen = 'Website.Basket.Samples.Chosen';
export const MoreInfo = 'Website.Basket.Samples.MoreInfo';
export const Sample = 'Website.Basket.Samples.Sample';
export const SamplePlural = 'Website.Basket.Samples.SamplePlural';
export const InvalidSample = 'Website.Basket.Samples.InvalidSample';
export const YouSelectedSample = 'Website.Basket.Samples.YouSelectedSample';
export const YouSelectedSamplePlural = 'Website.Basket.Samples.YouSelectedSamplePlural';
export const YouHaveNoSelectedSample = 'Website.Basket.Samples.YouHaveNoSelectedSample';
export const YouHaveNotUnlockedSample = 'Website.Basket.Samples.YouHaveNotUnlockedSample';
export const Deselect = 'Website.Basket.Samples.Deselect';
export const DeselectOneToChooseNew = 'Website.Basket.Samples.DeselectOneToChooseNew';
export const OpenSampleLevel = 'Website.Basket.Samples.OpenSampleLevel';
export const HereAfterYouCanChooseSample = 'Website.Basket.Samples.HereAfterYouCanChooseSample';
export const ChooseSample = 'Website.Basket.Samples.ChooseSample';
export const ChooseSampleSimple = 'Website.Basket.Samples.ChooseSampleSimple';
export const UnlockSamplesHeader = 'Website.Basket.Samples.UnlockSamplesHeader';
export const SamplesUnlockedHeader = 'Website.Basket.Samples.SamplesUnlockedHeader';
export const BuyForToChange = 'Website.Basket.Samples.BuyForToChange';
export const BuyForToSelect = 'Website.Basket.Samples.BuyForToSelect';
export const SelectBetweenMultipleSamples = 'Website.Basket.Samples.SelectBetweenMultipleSamples';
export const ErrorMessage = 'Website.Basket.Samples.ErrorMessage';
