import styled from 'styled-components';
import format from 'helpers/translations';
import { formatReact } from 'modules/helpers/strings';
import type { BasketMessageSampleGroupViewModel } from 'autogen/swagger/basket';
import { Typography } from 'components/generic/typography';
import WithIcon from 'components/hoc/withIcon/WithIcon';
import dynamic from 'helpers/dynamic';
import { ColorHealth5, ColorNordicGrey4 } from 'autogen/design-tokens/tokens';
import BasketMessageContainer from '../shared/styles';
import InlineButton from 'components/generic/InlineButton';
import useSingleSampleSelect from 'components/samples/components/SingleSampleSelection/shared/hooks/useSingleSampleSelect';
import { useTranslation } from 'react-i18next';
import { AddSample } from 'autogen/translation-keys/trans-website-basket-samples-singlesample';
import { Here } from 'autogen/translation-keys/trans-website-shared';
import useChooseSample from 'components/samples/components/samplesPicker/hooks/useChooseSample';
import { openSamplesModal } from 'components/samples/components/samplesPicker';
import { useRef, useEffect } from 'react';

const StyledBasketMessageContainer = styled(BasketMessageContainer)<{ $achievement?: boolean }>`
    background-color: ${({ $achievement }) => ($achievement ? ColorHealth5 : ColorNordicGrey4)};
`;

const Checkmark = dynamic(() => import('icons/Checkmark.svg?react'));

export default function SampleMessage({
    model: { text, intro, hasOnlyOneSample }
}: { model: BasketMessageSampleGroupViewModel }) {
    const ref = useRef<HTMLDivElement>(null);
    // The samplePicker button is a part of the translation as html which is we have to do this currently.
    // The translation should render a react component instead like the formattedText below.
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const button = ref?.current?.querySelector('.js-samplesOpenModal');

        if (button) {
            button.addEventListener('click', () => {
                openSamplesModal(button.innerHTML);
            });

            return () => {
                button.removeEventListener('click', () => {
                    openSamplesModal(button.innerHTML);
                });
            };
        }
    }, []);

    const { t } = useTranslation();
    const handleSingleSampleSelect = useSingleSampleSelect();
    const handleOpenChooseSample = useChooseSample();

    const formattedText = hasOnlyOneSample
        ? formatReact(text)(<InlineButton onClick={handleSingleSampleSelect}>{t(AddSample)}</InlineButton>)
        : formatReact(text)(<InlineButton onClick={handleOpenChooseSample}>{t(Here)}</InlineButton>);

    if (intro) {
        return (
            <StyledBasketMessageContainer $achievement>
                <WithIcon icon={<Checkmark />}>
                    <Typography.Body as="span" themeTypes="small">
                        {format(intro)} {formattedText}
                    </Typography.Body>
                </WithIcon>
            </StyledBasketMessageContainer>
        );
    }

    return (
        <StyledBasketMessageContainer ref={ref}>
            <Typography.Body as="span" themeTypes="small">
                {format(text)}
            </Typography.Body>
        </StyledBasketMessageContainer>
    );
}
