import WebsiteSettings from 'modules/websiteSettings';
import { ZowieSettings } from 'autogen/swagger/advisorchat';

export default function init() {
    ((d, id, cb) => {
        const js = d.createElement('script');
        const n = d.createElement('div');
        n.id = id;
        // @ts-ignore
        if (js.readyState) {
            // @ts-ignore
            js.onreadystatechange = () => {
                // @ts-ignore
                if (js.readyState === 'loaded' || js.readyState === 'complete') {
                    // @ts-ignore
                    js.onreadystatechange = null;
                    cb();
                }
            };
        } else {
            js.onload = () => {
                cb();
            };
        }
        js.src = 'https://matas.chat.getzowie.com/web/live-chat/chatbotize-entrypoint.min.js';
        document.body.appendChild(js);
        document.body.appendChild(n);
    })(document, 'chatbotize', () => {
        window.Zowie.init({
            instanceId: WebsiteSettings.liveChat.zowieInstanceId,
            allowDownloadTranscript: false,
            zendeskAccountKey: WebsiteSettings.liveChat.zowieZendeskAccountKey,
            startOnOpen: true,
            headerMode: 'white',
            onLoaded: () => {
                window.Zowie.setAccessTokenCallback(async (onSuccess, onError) => {
                    try {
                        const token = await ZowieSettings();
                        if (token.userToken) {
                            const data = JSON.parse(token.userToken);
                            onSuccess(data.access_token);
                        } else {
                            onSuccess(null);
                        }
                    } catch (error) {
                        onError(error);
                    }
                });

                if (WebsiteSettings.liveChat.openZowieOnLoad) {
                    window.Zowie.open();
                }
            }
        });
    });
}
