import type { LiveEventCustomAction } from 'autogen/swagger/page';
import openBambuser from 'components/live/openBambuser';
import { forwardRef } from 'react';
import type { CustomActionProps } from '../CustomAction';
import { CustomActionButton } from '../shared/styles';

type LiveEventActionProps = { action: LiveEventCustomAction } & CustomActionProps;

const LiveEventAction = forwardRef<HTMLButtonElement, LiveEventActionProps>(
    ({ action, onClick, ...restProps }, ref) => (
        <CustomActionButton
            ref={ref}
            type="button"
            onClick={(e) => {
                if (onClick) {
                    onClick(e);
                }
                openBambuser(action.liveEventId);
            }}
            {...restProps}
        />
    )
);

export default LiveEventAction;
