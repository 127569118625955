import { Spacing16 } from 'autogen/design-tokens/tokens';
import Flex from 'components/Layout/components/Flex';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';

export const ProductActions = styled(Flex.Column)`
    grid-column: 1/-1;

    ${breakpoint.up(sizes.lg)} {
        grid-column: auto;
    }
`;

export const PriceContainer = styled(Flex.Column)`
    text-align: right;
`;

export const Grid = styled.div`
    display: grid;
    gap: ${Spacing16};
    grid-template-columns: 104px 1fr;

    ${breakpoint.up(sizes.lg)} {
        align-items: start;
        grid-template-columns: 104px 1fr;
    }
`;
