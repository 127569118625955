const activeClass = 'state--active';

export function checkIfFieldsAreFilled(scope = $('body')) {
    scope.find('[data-js*="InputWithLabel"]').each((i, item) => {
        const $inputField = $(item);
        if ($inputField.val().length !== 0) {
            const $wrap = $inputField.closest('.input-w-placeholder');
            const $placeholder = $wrap.find('label');

            $placeholder.addClass(activeClass);
        }
    });
}

function setLabelActive(event) {
    const $inputField = $(event.target);
    const $wrap = $inputField.closest('.input-w-placeholder');
    const $placeholder = $wrap.find('.input__label--placeholder');

    if (
        (event.type == 'focusout' || event.type == 'input') &&
        $inputField.val().length === 0 &&
        !$inputField.is(':focus')
    ) {
        $placeholder.removeClass(activeClass);
        return;
    }
    $placeholder.addClass(activeClass);
    checkIfFieldsAreFilled();
}

export class InputWPlaceholder {
    constructor() {
        this.doc = $(document);
        this.$inputWithLabel = $('[data-js*="InputWithLabel"]');
    }

    init() {
        this.bindings();

        checkIfFieldsAreFilled();
        $(window).on('load', () => {
            // IOS does not sent events on autofill events, so check
            setTimeout(() => {
                checkIfFieldsAreFilled();
            }, 1000);
        });
    }

    bindings() {
        this.doc.on('focus focusout input change', '[data-js*="InputWithLabel"]', setLabelActive);
    }
}

export default InputWPlaceholder;
