
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './recommendation';

        export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type ConnectedContentFullwidthLocationType = components['schemas']['ConnectedContentFullwidthLocationType'];
                    export type ConnectedContentAdformTrackingModel = components['schemas']['ConnectedContentAdformTrackingModel'];
                    export type ConnectedContentAdformTrackingModelMaybe = components['schemas']['ConnectedContentAdformTrackingModelMaybe'];
                    export type CertificationCustomAction = components['schemas']['CertificationCustomAction'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type JavascriptHookCustomAction = components['schemas']['JavascriptHookCustomAction'];
                    export type LiveEventCustomAction = components['schemas']['LiveEventCustomAction'];
                    export type SurveyCustomAction = components['schemas']['SurveyCustomAction'];
                    export type VimeoCustomAction = components['schemas']['VimeoCustomAction'];
                    export type CustomActionType = components['schemas']['CustomActionType'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type LinkActionType = components['schemas']['LinkActionType'];
                    export type PageActionMaybe = components['schemas']['PageActionMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentFullwidthModel = components['schemas']['ConnectedContentFullwidthModel'];
                    export type ConnectedContentWidthType = components['schemas']['ConnectedContentWidthType'];
                    export type ConnectedContentInlineModel = components['schemas']['ConnectedContentInlineModel'];
                    export type ConnectedContentInventoryLaneBlockModel = components['schemas']['ConnectedContentInventoryLaneBlockModel'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type ConnectedContentProductDisplayModel = components['schemas']['ConnectedContentProductDisplayModel'];
                    export type ListItem = components['schemas']['ListItem'];
                    export type ListRowAlignment = components['schemas']['ListRowAlignment'];
                    export type ListRowItem = components['schemas']['ListRowItem'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelModel = components['schemas']['PriceLabelModel'];
                    export type PriceLabelModelMaybe = components['schemas']['PriceLabelModelMaybe'];
                    export type ProductListPriceInfo = components['schemas']['ProductListPriceInfo'];
                    export type SplashPlacement = components['schemas']['SplashPlacement'];
                    export type SplashDisplayType = components['schemas']['SplashDisplayType'];
                    export type SplashType = components['schemas']['SplashType'];
                    export type Splash = components['schemas']['Splash'];
                    export type SplashMaybe = components['schemas']['SplashMaybe'];
                    export type SplashModel = components['schemas']['SplashModel'];
                    export type VariantDisplayType = components['schemas']['VariantDisplayType'];
                    export type VariantListItem = components['schemas']['VariantListItem'];
                    export type ProductListVariantsModel = components['schemas']['ProductListVariantsModel'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type RecommendationType = components['schemas']['RecommendationType'];
                    export type RecommendationTrackingModelDTO = components['schemas']['RecommendationTrackingModelDTO'];
                    export type SearchEventModelDTO = components['schemas']['SearchEventModelDTO'];
                    export type SearchOrigin = components['schemas']['SearchOrigin'];
                    export type SuggestedSearchEventModelDTO = components['schemas']['SuggestedSearchEventModelDTO'];
                    export type SearchTrackingModelDTO = components['schemas']['SearchTrackingModelDTO'];
                    export type PowerstepTrackingModelDTO = components['schemas']['PowerstepTrackingModelDTO'];
                    export type AdformProductTrackingModelDTO = components['schemas']['AdformProductTrackingModelDTO'];
                    export type TrackingModelDTO = components['schemas']['TrackingModelDTO'];
                    export type TrackingModelDTOMaybe = components['schemas']['TrackingModelDTOMaybe'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type IBundleInfo = components['schemas']['IBundleInfo'];
                    export type IBundleInfoMaybe = components['schemas']['IBundleInfoMaybe'];
                    export type UATrackingCategory = components['schemas']['UATrackingCategory'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type ProductListItemModel = components['schemas']['ProductListItemModel'];
                    export type ProductCardSizeType = components['schemas']['ProductCardSizeType'];
                    export type ProductRecommendationModel = components['schemas']['ProductRecommendationModel'];
                    export type ProductRecommendationListModel = components['schemas']['ProductRecommendationListModel'];
                    export type PowerstepTwoType = components['schemas']['PowerstepTwoType'];
                    export type ProductRecommendationListPowerstepTwoModel = components['schemas']['ProductRecommendationListPowerstepTwoModel'];
                    export type RecommendationProductSliderCustomModuleType = components['schemas']['RecommendationProductSliderCustomModuleType'];
                    export type ProductRecommendationRequest = components['schemas']['ProductRecommendationRequest'];
                    export type BasketRecommendationRequest = components['schemas']['BasketRecommendationRequest'];
                    export type ProductGroupRecommendationRequest = components['schemas']['ProductGroupRecommendationRequest'];
                    export type SearchResultRecommendationRequest = components['schemas']['SearchResultRecommendationRequest'];
                    export type ZeroSearchResultRecommendationRequest = components['schemas']['ZeroSearchResultRecommendationRequest'];
                    export type HomePageRecommendationRequest = components['schemas']['HomePageRecommendationRequest'];
                    export type PowerstepRecommendationRequest = components['schemas']['PowerstepRecommendationRequest'];
                    export type PowerstepTwoRecommendationRequest = components['schemas']['PowerstepTwoRecommendationRequest'];
                    export type ReceiptPageRecommendationRequest = components['schemas']['ReceiptPageRecommendationRequest'];
                    export type CustomRecommendationRequest = components['schemas']['CustomRecommendationRequest'];
                    

        
        

        export type GetByProductIdParams = paths['/internal/Recommendation/GetByProductId']['get']['parameters']['query'];

        export type GetByProductIdSuccess = paths['/internal/Recommendation/GetByProductId']['get']['responses']['200']['content']['text/plain'];

        export const GetByProductIdUrl = '/internal/Recommendation/GetByProductId';

        
        export function GetByProductId(params: GetByProductIdParams): Promise<GetByProductIdSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByProductIdSuccess>(`/internal/Recommendation/GetByProductId${formatParams(params)}`)
        }
    
    
        

        

        export type GetByBasketSuccess = paths['/internal/Recommendation/GetByBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetByBasketUrl = '/internal/Recommendation/GetByBasket';

        
        export function GetByBasket(): Promise<GetByBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByBasketSuccess>(`/internal/Recommendation/GetByBasket`)
        }
    
    
        

        export type GetByProductGroupIdParams = paths['/internal/Recommendation/GetByProductGroupId']['get']['parameters']['query'];

        export type GetByProductGroupIdSuccess = paths['/internal/Recommendation/GetByProductGroupId']['get']['responses']['200']['content']['text/plain'];

        export const GetByProductGroupIdUrl = '/internal/Recommendation/GetByProductGroupId';

        
        export function GetByProductGroupId(params: GetByProductGroupIdParams): Promise<GetByProductGroupIdSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByProductGroupIdSuccess>(`/internal/Recommendation/GetByProductGroupId${formatParams(params)}`)
        }
    
    
        

        export type GetBySearchResultParams = paths['/internal/Recommendation/GetBySearchResult']['get']['parameters']['query'];

        export type GetBySearchResultSuccess = paths['/internal/Recommendation/GetBySearchResult']['get']['responses']['200']['content']['text/plain'];

        export const GetBySearchResultUrl = '/internal/Recommendation/GetBySearchResult';

        
        export function GetBySearchResult(params: GetBySearchResultParams): Promise<GetBySearchResultSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBySearchResultSuccess>(`/internal/Recommendation/GetBySearchResult${formatParams(params)}`)
        }
    
    
        

        export type GetByZeroSearchResultParams = paths['/internal/Recommendation/GetByZeroSearchResult']['get']['parameters']['query'];

        export type GetByZeroSearchResultSuccess = paths['/internal/Recommendation/GetByZeroSearchResult']['get']['responses']['200']['content']['text/plain'];

        export const GetByZeroSearchResultUrl = '/internal/Recommendation/GetByZeroSearchResult';

        
        export function GetByZeroSearchResult(params: GetByZeroSearchResultParams): Promise<GetByZeroSearchResultSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByZeroSearchResultSuccess>(`/internal/Recommendation/GetByZeroSearchResult${formatParams(params)}`)
        }
    
    
        

        export type GetByHomePageParams = paths['/internal/Recommendation/GetByHomePage']['get']['parameters']['query'];

        export type GetByHomePageSuccess = paths['/internal/Recommendation/GetByHomePage']['get']['responses']['200']['content']['text/plain'];

        export const GetByHomePageUrl = '/internal/Recommendation/GetByHomePage';

        
        export function GetByHomePage(params: GetByHomePageParams): Promise<GetByHomePageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByHomePageSuccess>(`/internal/Recommendation/GetByHomePage${formatParams(params)}`)
        }
    
    
        

        export type GetByPowerstepParams = paths['/internal/Recommendation/GetByPowerstep']['get']['parameters']['query'];

        export type GetByPowerstepSuccess = paths['/internal/Recommendation/GetByPowerstep']['get']['responses']['200']['content']['text/plain'];

        export const GetByPowerstepUrl = '/internal/Recommendation/GetByPowerstep';

        
        export function GetByPowerstep(params: GetByPowerstepParams): Promise<GetByPowerstepSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByPowerstepSuccess>(`/internal/Recommendation/GetByPowerstep${formatParams(params)}`)
        }
    
    
        

        export type GetByPowerstepTwoParams = paths['/internal/Recommendation/GetByPowerstepTwo']['get']['parameters']['query'];

        export type GetByPowerstepTwoSuccess = paths['/internal/Recommendation/GetByPowerstepTwo']['get']['responses']['200']['content']['text/plain'];

        export const GetByPowerstepTwoUrl = '/internal/Recommendation/GetByPowerstepTwo';

        
        export function GetByPowerstepTwo(params: GetByPowerstepTwoParams): Promise<GetByPowerstepTwoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetByPowerstepTwoSuccess>(`/internal/Recommendation/GetByPowerstepTwo${formatParams(params)}`)
        }
    
    
        

        export type GetCustomParams = paths['/internal/Recommendation/GetCustom']['get']['parameters']['query'];

        export type GetCustomSuccess = paths['/internal/Recommendation/GetCustom']['get']['responses']['200']['content']['text/plain'];

        export const GetCustomUrl = '/internal/Recommendation/GetCustom';

        
        export function GetCustom(params: GetCustomParams): Promise<GetCustomSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetCustomSuccess>(`/internal/Recommendation/GetCustom${formatParams(params)}`)
        }
    
    
        

        export type GetForReceiptPageParams = paths['/internal/Recommendation/GetForReceiptPage']['get']['parameters']['query'];

        export type GetForReceiptPageSuccess = paths['/internal/Recommendation/GetForReceiptPage']['get']['responses']['200']['content']['text/plain'];

        export const GetForReceiptPageUrl = '/internal/Recommendation/GetForReceiptPage';

        
        export function GetForReceiptPage(params: GetForReceiptPageParams): Promise<GetForReceiptPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetForReceiptPageSuccess>(`/internal/Recommendation/GetForReceiptPage${formatParams(params)}`)
        }
    
    
        

        

        export type RecommendationRequestModelSuccess = paths['/internal/Recommendation/RecommendationRequestModel']['get']['responses']['200']['content']['text/plain'];

        export const RecommendationRequestModelUrl = '/internal/Recommendation/RecommendationRequestModel';

        
        export function RecommendationRequestModel(): Promise<RecommendationRequestModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<RecommendationRequestModelSuccess>(`/internal/Recommendation/RecommendationRequestModel`)
        }
    
    

        export * from './discriminators';
    