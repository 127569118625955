import React from 'react';
import styled from 'styled-components';
import htmlModal, { Content as HtmlModalContent } from 'modules/modal/htmlModal';
import DefaultLayout from 'modules/modal/layouts';
import { TermIndex as Index, Hide } from 'autogen/swagger/term';
import fetch from 'cross-fetch';
import mediator from './mediator';
import Translations from './translations';
import { growlGeneralExceptionError } from 'modules/growl';

export default class permissionCompliance {
    constructor() {
        this.headerHtml = `<div class="permission-modal__header"><div class="permission-modal__title"> ${Translations.Website.PermissionCompliance.Title}</div><div class="permission-modal__description">${Translations.Website.PermissionCompliance.Description}</div></div><div class="permission-modal__content" data-js-permission-compliance="content">`;
        this.footerHtml = `<span id="js-permission-modal-bottom"></<span></div><div class="permission-modal__footer"><button type="button" class="btn btn--cta-quinary text-bold permission-modal__logout-btn" data-js-permission-compliance="logout">${Translations.Website.PermissionCompliance.Logout}</button><span class="text-italic permission-modal__logout-append">${Translations.Website.PermissionCompliance.Or}</span><button type="button" class="btn btn--cta-quinary text-bold permission-modal__accept-btn" data-js-permission-compliance="accept">${Translations.Website.PermissionCompliance.SubmitButton}</button><button type="button" class="btn btn--cta-quinary text-bold permission-modal__continue-btn" data-js-permission-compliance="continue" data-js="close">${Translations.Website.PermissionCompliance.ContinueButton}</button></div>`;
        this.continueState = false;
    }

    async init() {
        try {
            const response = await Index();

            const { isPermissionRequired, text, acceptUrl, isSkipAllowed } = response;

            if (isPermissionRequired) {
                this.text = text; // Desktop text (with icons)
                this.acceptUrl = acceptUrl;
                this.skipAllowed = isSkipAllowed;

                this.openModal();
            }
        } catch (error) {
            console.error(error);
            growlGeneralExceptionError();
        }
    }

    openModal() {
        const NoSkipAllow = this.skipAllowed ? '' : ' state--no-skip';

        const ContentWrapper = styled(HtmlModalContent).attrs({ className: `permission-modal ${NoSkipAllow}` })`
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            min-height: 1px;
            padding: ${this.skipAllowed ? '0 20px 0' : '20px 20px 0'};
        `;

        const modal = htmlModal({
            layout: { component: <DefaultLayout customTheme={{ width: '800px' }} /> },
            userCanClose: this.skipAllowed,
            onBeforeClose: ({ userCanClose }) => {
                if (!userCanClose) {
                    mediator.publish('growl', { message: Translations.Website.PermissionCompliance.NoSkipAllowed });
                }
            }
        });

        modal({
            html: this.headerHtml + this.text + this.footerHtml,
            ContentWrapper,
            onAfterContent: ({ close }) => {
                this.hasBottomBeenSeen = this.checkInView($('#js-permission-modal-bottom'), true);
                this.setSubmitBtnActive();
                this.bindings(close);
            }
        });
    }

    checkInView(elem, partial) {
        this.container = $('[data-js-permission-compliance="content"]');
        this.contHeight = this.container.outerHeight();
        this.contBottom = this.container.scrollTop() + this.contHeight;

        const elemTop = $(elem).offset().top - this.container.offset().top;
        const elemBottom = elemTop + $(elem)[0].scrollHeight;

        const isTotal = elemTop >= 0 && elemBottom <= this.contHeight; // total in view
        const isPart = ((elemTop < 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= this.contHeight)) && partial; // Top is in view

        return isTotal || isPart;
    }

    bindings(closeModal) {
        if (!this.hasBottomBeenSeen) {
            $('[data-js-permission-compliance="content"]').scroll(() => {
                if (this.hasBottomBeenSeen) return;
                const AtBottom = this.checkInView($('#js-permission-modal-bottom'), true);
                this.hasBottomBeenSeen = AtBottom ? true : this.hasBottomBeenSeen;

                this.setSubmitBtnActive();
            });
        }

        $('[data-js-permission-compliance="accept"]').on('click', (e) => {
            if (!this.hasBottomBeenSeen) {
                this.container.scrollTop(this.contBottom); // scroll top bottom of container
            } else {
                $.loadingSpinner($(e.target), true);

                this.submit($(e.target));
                closeModal();
            }
        });

        if (this.skipAllowed) {
            $('[data-js="close"]').one('click', (e) => {
                if (!this.continueState) {
                    Hide();
                }
            });
        } else {
            $('[data-js="close"]:not([data-js-permission-compliance="continue"])').on('click', (e) => {
                e.stopImmediatePropagation(); // prevent modal closes

                mediator.publish('growl', { message: Translations.Website.PermissionCompliance.NoSkipAllowed });
            });
        }
    }

    setSubmitBtnActive() {
        if (this.hasBottomBeenSeen) {
            $('[data-js-permission-compliance="accept"]').addClass('state--active');
        }
    }

    async submit(target) {
        try {
            const response = await fetch(this.acceptUrl, { method: 'POST' });

            if (!response.success) throw Error({ message: Translations.Website.PermissionCompliance.FailAcceptGrowl });

            $.loadingSpinner(target, false);
            this.toggleToContinue();
        } catch (error) {
            console.error(error);
            if (error.message) {
                mediator.publish('growl', { message: error.message });
            } else {
                growlGeneralExceptionError();
            }
        }
    }

    toggleToContinue() {
        this.continueState = true;
        $('[data-js-permission-compliance="accept"]').hide();
        $('[data-js-permission-compliance="continue"]').show();
    }
}
