import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import TitleStyling from '../styles/title';
import { fontSizeTypes } from '../types';
import Text from './Text';

const StyledText = styled(Text)`
    ${TitleStyling};
`;

type TitleTypes = 'span' | 'div' | 'strong' | 'b' | 'h1' | 'h2';

export interface ITitle extends HTMLAttributes<HTMLSpanElement> {
    as?: TitleTypes;
    size: fontSizeTypes<16 | 18 | 20 | 24>;
}

export default function Title(props: ITitle): JSX.Element {
    const { as, size, children, ...restProps } = props;

    return (
        <StyledText forwardedAs={as as any} size={size} {...restProps}>
            {children}
        </StyledText>
    );
}
