import { type BasketSubTotalSummary } from 'autogen/swagger/checkout';
import { SubTotal, SubTotalWithBottleDeposit } from 'autogen/translation-keys/trans-website-basket';
import Layout from 'components/Layout';
import { Price } from 'components/generic/productPrice';
import { Typography } from 'components/generic/typography';
import { useTranslation } from 'react-i18next';

type SubTotalSummary = Record<string, any> & { model: BasketSubTotalSummary };

export default function SubTotalSummary({ model: { subTotal, hasBottleDeposit }, ...restProps }: SubTotalSummary) {
    const { t } = useTranslation();
    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <Typography.Body as="span" themeTypes="mediumSemiBold">
                {hasBottleDeposit ? t(SubTotalWithBottleDeposit) : t(SubTotal)}
            </Typography.Body>

            <Price price={subTotal} />
        </Layout.Flex.Row>
    );
}
