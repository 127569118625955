import { useState, useEffect, useRef, createRef } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import styled, { css } from 'styled-components';
import mediator from 'modules/mediator';
import pointInTriangle from 'modules/helpers/pointInTriangle';
import { breakpoint, sizes, zIndex } from 'variables';
import { toggleHeaderLocked } from 'store/actions/headerActions';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { trackArray } from 'modules/tracking/dataLayer';
import Dialog from 'modules/quicksearch/src/components/dialog';
import { layoutEnum, sectionEnum, navigationEvent } from 'modules/quicksearch/src/enums';
import { getSearchHistory, saveSearchHistory, clearSearchHistory } from 'modules/quicksearch/src/searchHistory';
import Routes from 'modules/routes';
import fetch from 'cross-fetch';
import { useIsAboveBreakPoint } from 'hooks/responsive';
import { ButtonWithIcon } from 'components/Button';
import dynamic from 'helpers/dynamic';
import { ColorNordicGrey4 } from 'autogen/design-tokens/tokens';
import { useRecoilState } from 'recoil';
import { Search } from 'autogen/translation-keys/trans-website-imagealttext-icons';
import { useTranslation } from 'react-i18next';
import useEscapeKeyEffect from 'hooks/globals/useEscapeKeyEffect';
import useOnNestedFocusoutEffect from 'hooks/globals/useOnNestedFocusoutEffect';
import { GA4Tracking } from 'modules/tracking/GA4';
import SearchInput from './SearchInput';
import { searchActiveAtom } from './atoms/quickViewAtoms';
import MainContext from './components/context';
import usePointOfSaleRequest from 'hooks/user/usePointOfSaleRequest';
import { stripDialogStyling } from 'modules/helpers/mixins';

const SearchIcon = dynamic(() => import('icons/Search.svg?react'));

// after inset-inline to height, these styles are added in order to reset styles that comes from dialog element automatically
const SearchContainer = styled.dialog<{ $isSearchActive: boolean; $isPosRequest: boolean }>`
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    ${stripDialogStyling()}

    ${({ $isSearchActive, $isPosRequest }) =>
        $isSearchActive &&
        css`
            position: fixed;
            z-index: ${zIndex.dialog};
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;

            ${breakpoint.up(sizes.lg)} {
                position: absolute;
                bottom: auto;
                border-radius: 10px;
                top: -16px;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% + 32px);
                min-width: 496px;
                max-width: 730px;
                overflow: hidden;

                .state--fixed.state--search-open & {
                    left: 0;
                }

                ${
                    $isPosRequest &&
                    css`
                    right: 0;
                    left: auto;
                    transform: unset;
                    width: 610px;
                    top: 0;
                `
                }
            }
        `};
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: 40px;
`;

const ShadowOverlay = styled.div<{ isSearchActive: boolean }>`
    ${breakpoint.up(sizes.lg)} {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${({ isSearchActive }) => isSearchActive && 'transform: translateX(0px);'}
        opacity: ${({ isSearchActive }) => (isSearchActive ? '1' : '0')};
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s step-middle;
        z-index: ${zIndex.backdrop};
    }

    /* Prevent double animation if double clicking on the shadow when closing the dialog */
    pointer-events: ${({ isSearchActive }) => (isSearchActive ? 'auto' : 'none')};
`;

const initialSearchHistory = () => {
    if (typeof window === 'undefined') return null;

    return getSearchHistory();
};

// Timers
const inputDebounceTime = 150;
let spinnerTimerId;
let resizeTimeOutId;

// Navigation
let itemCount;
let activeSuggestion;
let lastActiveSuggestionIndex;

// menu aim
let menuAiming = false;
let originPoint = {};
let productSectionTop = {};
let productSectionBottom = {};
let menuAimTimer;
const menuAimTimerDuration = 300;
let userNavigation = navigationEvent.hover;

// no result tracking timer
let noResultTrackingTimer;
const noResultTrackingTimerDuration = 1000;
let readyFastNoResultTracking = false;

const sectionCoordsCallback = (boundingClientRect) => {
    const { x, y, height } = boundingClientRect;
    productSectionTop = { x, y };
    productSectionBottom = { x, y: y + height };
};

const resetAiming = () => {
    menuAiming = false;
    originPoint = {};
    productSectionTop = {};
    productSectionBottom = {};
};

const isPointInTriangle = (event) => {
    const currentPoint = { x: event.pageX, y: event.pageY };
    return pointInTriangle(currentPoint, originPoint, productSectionTop, productSectionBottom);
};

const onItemLeave = (position) => {
    if (menuAiming) return;
    clearTimeout(menuAimTimer);
    originPoint = position;
    menuAiming = true;
};

// fetch results
let lastFetchedController: AbortController | null = null;
const getSearchResult = async (string, sections, categories?: any, getNextQueries?: any) => {
    // Abort fetch if we have a new request (debounce)
    if (lastFetchedController) {
        lastFetchedController.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;
    let url = Routes.Search.QuickSearch + encodeURIComponent(string);
    lastFetchedController = controller;

    if (sections && sections.length !== 0) {
        // eslint-disable-next-line array-callback-return
        sections.map((sectionName) => {
            url = `${url}&sections=${sectionName}`;
        });
    }

    if (categories && categories.length !== 0) {
        // eslint-disable-next-line array-callback-return
        categories.map((categoryName) => {
            url = `${url}&cat=${categoryName}`;
        });
    }

    if (getNextQueries) {
        url = `${url}&nextQuery=true`;
    }

    return (
        fetch(url, { signal })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Failed to fetch JSON from: ${url}`);
                }
                lastFetchedController = null;

                return res.json();
            })
            // eslint-disable-next-line consistent-return
            .catch((err) => {
                if (err.name !== 'AbortError') {
                    console.error(err);
                    return null;
                }
            })
    );
};

const getActiveTab = (props, currentActiveTab) => {
    const { articles, brands, featuredBrands, brandFacets, products, suggestions } = props;
    const prioritizedOrder = [
        { sectionEnum: sectionEnum.suggestions, ...suggestions },
        { sectionEnum: sectionEnum.products, ...products },
        { sectionEnum: sectionEnum.brands, ...{ ...brands, ...featuredBrands } },
        { sectionEnum: sectionEnum.brandFacets, ...brandFacets },
        { sectionEnum: sectionEnum.articles, ...articles }
    ];

    if (typeof currentActiveTab === 'number') {
        let itemFound = false;
        Object.values(prioritizedOrder).forEach((obj) => {
            //  move currentActiveTab to front of array, so it will be prioritized first
            if (!itemFound && currentActiveTab === obj.sectionEnum) {
                itemFound = true;

                // if brand add brandFacets to start of prioritized list
                if (obj.sectionEnum === sectionEnum.brands) {
                    Object.keys(prioritizedOrder).forEach((key) => {
                        if (prioritizedOrder[key].sectionEnum === sectionEnum.brandFacets) {
                            prioritizedOrder.unshift(prioritizedOrder.splice(Number(key), 1)[0]);
                        }
                    });
                }

                prioritizedOrder.unshift(prioritizedOrder.splice(prioritizedOrder.indexOf(obj), 1)[0]);
            }
        });
    }

    const firstSectionWithData = prioritizedOrder.find((section) => {
        if (section.items && section.items.length >= 1) {
            return section;
        }

        return null;
    });

    return firstSectionWithData?.sectionEnum || undefined;
};

const formatSearchResult = (layoutEnumValue, searchResult, latestSearchQueriesResult?: any) => {
    // format search result based on layout.
    // this function is used to deliver the right data to the different types of layout and in the right order, for arrow-key navigation purposes.
    const latestSearchQueries = latestSearchQueriesResult || { items: [] };
    const {
        articles = { items: [] },
        brandFacets = { items: [] },
        brands = { items: [] },
        featuredBrands = { ...brands },
        products = { items: [] },
        suggestions = { items: [] },
        nextQueries = { items: [] }
    } = searchResult;

    switch (layoutEnumValue) {
        case layoutEnum.row:
            latestSearchQueries.items.splice(5);
            suggestions.items.splice(8);
            nextQueries.items.splice(3);

            return {
                latestSearchQueries,
                suggestions,
                nextQueries
            };

        case layoutEnum.column:
            articles.items.splice(2);
            products.items.splice(4);
            suggestions.items.splice(8);
            featuredBrands.items = featuredBrands.items.filter((item) => item.image);
            featuredBrands.items.splice(2);
            nextQueries.items.splice(3);

            return {
                suggestions,
                articles,
                products,
                brands,
                featuredBrands,
                nextQueries
            };

        case layoutEnum.tab:
            // Data order in tabs are irrelevant because we will not be supporting arrow-key navigation for tabs.
            articles.items.splice(10);
            products.items.splice(10);
            suggestions.items.splice(8);
            brands.items.splice(40);
            featuredBrands.items = featuredBrands.items.filter((item) => item.image);
            featuredBrands.items.splice(2);
            brandFacets.items.splice(40);
            nextQueries.items.splice(3);

            // Filter out shown featured brands from brand list
            Object.keys(featuredBrands.items).forEach((key) => {
                const brandIndex = brands.items.indexOf(featuredBrands.items[key]);
                if (brandIndex !== -1) {
                    brands.items.splice(brandIndex, 1);
                }
            });

            return {
                suggestions,
                brands,
                featuredBrands,
                brandFacets,
                products,
                articles,
                nextQueries
            };

        case layoutEnum.noResult:
            return null;

        default:
            return searchResult;
    }
};

const getItemByIndex = (index, json) => {
    let counter = 0;
    let itemIndex = 0;
    let itemFound = false;
    let newActiveItem;
    let newActiveSection;
    let indexInSection;

    if (index !== 0) {
        Object.entries(json).forEach(([value]) => {
            if (itemFound) return;
            const section = json[value];
            Object.entries(section.items).forEach(([key], i) => {
                if (itemFound) return;
                counter += 1;
                const currentItem = section.items[key];
                const scopedItems = currentItem.scopedQueries;

                if (index === counter) {
                    itemFound = true;
                    newActiveItem = currentItem;
                } else if (scopedItems?.length) {
                    Object.entries(scopedItems).forEach(([scopedKey]) => {
                        if (itemFound) return;
                        counter += 1;
                        if (index === counter) {
                            itemFound = true;
                            newActiveItem = scopedItems[scopedKey];
                        }
                    });
                }
                if (itemFound) {
                    indexInSection = i + 1;
                    itemIndex = counter;
                    newActiveSection = sectionEnum[value];
                }
            });
        });
    }

    return { section: newActiveSection, item: newActiveItem, itemIndex, indexInSection };
};

const getIndexByItem = (item, json) => {
    let itemFound = false;
    let index = 0;

    Object.keys(json).forEach((value) => {
        if (itemFound) return;
        const section = json[value];

        Object.keys(section.items).forEach((key) => {
            if (itemFound) return;
            index += 1;
            const currentItem = section.items[key];
            const scopedItems = currentItem.scopedQueries;

            if (item === section.items[key]) {
                itemFound = true;
            } else if (scopedItems?.length) {
                Object.keys(scopedItems).forEach((scopedKey) => {
                    index += 1;
                    if (item === scopedItems[scopedKey]) {
                        itemFound = true;
                    }
                });
            }
        });
    });

    return index;
};

const resetActiveSuggestion = (json, index = lastActiveSuggestionIndex) => {
    if (!lastActiveSuggestionIndex) return;
    const lastActiveSuggestion = getItemByIndex(index, json);
    if (lastActiveSuggestion) lastActiveSuggestion.item.isActive = false;
    activeSuggestion = undefined;
};

function QuickSearch(props) {
    const { useButton, searchText, shouldDisplayCameraButton } = props;
    const isDesktop = useIsAboveBreakPoint(sizes.lg);
    const isHandheld = isDesktop !== undefined && !isDesktop;
    const [searchActive, setSearchActive] = useRecoilState(searchActiveAtom);
    const [layout, setLayout] = useState(layoutEnum.noResult);
    const [buttonControl, setButtonControl] = useState<boolean>(useButton || false);
    const { t } = useTranslation();
    const { isPosRequest } = usePointOfSaleRequest();

    // Search result states
    const [searchResult, setSearchResult] = useState(null);
    const [latestSearchQueries, setLatestSearchQueries] = useState(initialSearchHistory); // TODO necessary to load here?
    const [formattedSearchResult, setFormattedSearchResult] = useState(null);
    const [finalSearchResult, setFinalSearchResult] = useState(formattedSearchResult);

    // Dialog and items active states
    const [activeTab, setActiveTab] = useState(sectionEnum.suggestions);
    const [loadingSpinnerActive, setLoadingSpinnerActive] = useState(true);
    const [activeItem, setActiveItem] = useState(undefined);
    const [activeItemCount, setActiveItemCount] = useState(0);

    // Search string states
    const [inputValue, setInputValue] = useState(searchText ?? '');
    const [navigationInputValue, setNavigationInputValue] = useState(inputValue);
    const [searchString] = useDebounce(inputValue, inputDebounceTime);

    const scrollRef = createRef<HTMLDialogElement>();
    const wrapperRef = useRef(null);
    const inputRef = createRef<HTMLInputElement>();

    const getNextQueries = useRef(true);

    const searchToggle = (open = true) => {
        if (!open) {
            document.body.classList.remove('state--search-open');
        }
        if (useButton) {
            setButtonControl(true);
        }
        setSearchActive(open);
    };

    const overlayClick = () => {
        searchToggle(false);
    };

    const handleInputValue = (string) => {
        const stringWithoutLastSpace = string.trim();

        if (stringWithoutLastSpace !== inputValue) {
            setInputValue(string);
        }
        setNavigationInputValue(string);
        getNextQueries.current = false;

        if (string === '') {
            inputRef?.current?.focus();
        }
    };

    const onEnterCallback = () => {
        if (activeItem?.isActive) {
            window.location.href = activeItem.linkAction.url;
        } else if (navigationInputValue.length > 0) {
            saveSearchHistory(navigationInputValue);
            window.location.href = Routes.Search.InitiateSearchFromHistory + encodeURIComponent(navigationInputValue);
        }
    };

    const onClearHistory = () => {
        clearSearchHistory();
        setLatestSearchQueries(null);
        setFormattedSearchResult(formatSearchResult(layoutEnum.row, searchResult, null));
    };

    const updateTabResults = (text) => {
        const sectionsToUpdate = ['Products', 'Articles', 'Brands'];
        if (layout === layoutEnum.row) sectionsToUpdate.push('Suggestions');

        getSearchResult(text, sectionsToUpdate).then((result) => {
            const combinedResult = { ...finalSearchResult, ...result };
            setLayout(layoutEnum.tab);
            setFormattedSearchResult(formatSearchResult(layoutEnum.tab, combinedResult, latestSearchQueries));
        });
    };

    const setActiveTabCallback = (sectionEnumValue) => {
        setActiveTab(sectionEnumValue);
    };

    const toggleSpinner = (toggleBool) => {
        clearTimeout(spinnerTimerId);
        if (toggleBool === false) {
            setLoadingSpinnerActive(toggleBool);
        } else {
            spinnerTimerId = setTimeout(() => {
                setLoadingSpinnerActive(toggleBool);
            }, 500);
        }
    };

    async function toggleActiveProp(newItemIndex) {
        if (
            !finalSearchResult ||
            typeof newItemIndex !== 'number' ||
            layout === layoutEnum.tab ||
            userNavigation === navigationEvent.autofill
        )
            return;

        let navigationResult = finalSearchResult;
        const lastActive = getItemByIndex(activeItemCount, navigationResult);
        const newActiveItem = getItemByIndex(newItemIndex, navigationResult);
        const { section, item, itemIndex } = newActiveItem;

        if (section === sectionEnum.suggestions) {
            // Suggestions
            resetActiveSuggestion(navigationResult, lastActiveSuggestionIndex);

            // if suggestions get product unless lastSuggestion === newItem
            if (lastActiveSuggestionIndex !== itemIndex && layout === layoutEnum.column) {
                if (userNavigation !== navigationEvent.hover) {
                    setNavigationInputValue(item.title);
                }

                const categories = item.productGroupName ? [item.productGroupName] : undefined;
                getSearchResult(item.title, ['Products'], categories).then((result) => {
                    if (!result) return;
                    const { products } = result;

                    if (products.items.length <= 0) {
                        item.hasArrow = false;
                    }
                    products.items.splice(4);
                    navigationResult = { ...finalSearchResult, products };
                    setLayout(layoutEnum.column);
                    setFinalSearchResult(navigationResult);
                });
            }
            lastActiveSuggestionIndex = itemIndex;
        } else if (lastActive.section === sectionEnum.suggestions && section === sectionEnum.products) {
            // Suggestions -> Products
            activeSuggestion = lastActive.itemIndex;
        } else if (activeSuggestion && section !== sectionEnum.products) {
            // reset active Suggestion when not in productSection
            resetActiveSuggestion(navigationResult);
            setFinalSearchResult(formattedSearchResult);
            setNavigationInputValue(inputValue);
        } else if (
            section !== undefined &&
            lastActive.section === sectionEnum.suggestions &&
            section !== sectionEnum.suggestions &&
            section !== sectionEnum.products
        ) {
            resetActiveSuggestion(navigationResult);
            setFinalSearchResult(formattedSearchResult);
            setNavigationInputValue(inputValue);
        } else if (section === sectionEnum.latestSearchQueries) {
            setNavigationInputValue(item.title);
        }

        if (
            lastActive.itemIndex !== 0 &&
            !(lastActive.section === sectionEnum.suggestions && section === sectionEnum.products)
        ) {
            lastActive.item.isActive = false;
        }

        if (itemIndex !== 0) {
            item.isActive = true;
            setActiveItem(item);
        }

        setActiveItemCount(newItemIndex);
    }

    const arrowNavigation = (event) => {
        const arrowUp = event.keyCode === 38;
        const arrowDown = event.keyCode === 40;
        const arrowLeft = event.keyCode === 37;
        const arrowRight = event.keyCode === 39;
        const searchStringLength = event.target.value.length;
        const caretPosition = event.target.selectionStart;

        if (
            isHandheld ||
            (!arrowUp && !arrowDown && !arrowLeft && !arrowRight) ||
            ((arrowLeft || arrowRight) && activeItemCount === 0)
        ) {
            return;
        }
        userNavigation = navigationEvent.arrow;

        const currentItem = getItemByIndex(activeItemCount, finalSearchResult);

        if (arrowUp || arrowDown) {
            // event.preventDefault() is to prevent text caret from moving in the input while navigating
            event.preventDefault();
        }
        if (itemCount === undefined || typeof itemCount !== 'number') {
            itemCount = 0;
            Object.keys(formattedSearchResult).forEach((value) => {
                itemCount += formattedSearchResult[value].items.length;
            });
        }

        // Navigation inside of products sections
        if (currentItem.section === sectionEnum.products && layout === layoutEnum.column) {
            const lastActive = getItemByIndex(activeItemCount, finalSearchResult);
            const productsPerRow = 2;

            if (arrowUp) {
                const item = getItemByIndex(activeItemCount - productsPerRow, finalSearchResult);

                if (item.section === sectionEnum.products || lastActive.indexInSection === 0) {
                    toggleActiveProp(item.itemIndex);
                    return;
                }
                return;
            }
            if (arrowDown) {
                const item = getItemByIndex(activeItemCount + productsPerRow, finalSearchResult);
                const lastProductIndex = finalSearchResult.products.items.length - 1;
                if (lastActive.indexInSection === lastProductIndex) {
                    // Go to brands or inputfield
                    if (finalSearchResult.brands?.items.length) {
                        const indexOfFirstBrand = getIndexByItem(finalSearchResult.brands.items[0], finalSearchResult);
                        toggleActiveProp(indexOfFirstBrand);
                    } else {
                        toggleActiveProp(0);
                    }
                    return;
                }
                if (item.section === sectionEnum.products) {
                    toggleActiveProp(item.itemIndex);
                    return;
                }
                return;
            }
            if (arrowLeft) {
                const item = getItemByIndex(activeItemCount - 1, finalSearchResult);
                if (item.section === sectionEnum.products) {
                    toggleActiveProp(item.itemIndex);
                } else if (finalSearchResult.suggestions?.items.length) {
                    // if section = products? go to first suggestion or article, unless a suggestion is saved saved
                    if (activeSuggestion) {
                        toggleActiveProp(activeSuggestion);
                    } else {
                        const indexOfFirstSuggestions = getIndexByItem(
                            finalSearchResult.suggestions.items[0],
                            finalSearchResult
                        );
                        toggleActiveProp(indexOfFirstSuggestions);
                    }
                } else if (finalSearchResult.articles?.items.length) {
                    const indexOfFirstArticle = getIndexByItem(finalSearchResult.articles.items[0], finalSearchResult);
                    toggleActiveProp(indexOfFirstArticle);
                }
            }
            if (arrowRight) {
                const item = getItemByIndex(activeItemCount + 1, finalSearchResult);
                toggleActiveProp(item.itemIndex);
            }
        }

        if (arrowDown) {
            if (activeItemCount >= itemCount) {
                toggleActiveProp(0);
            } else {
                toggleActiveProp(activeItemCount + 1);
            }
        } else if (arrowUp) {
            if (activeItemCount === 0) {
                toggleActiveProp(itemCount);
            } else {
                toggleActiveProp(activeItemCount - 1);
            }
        } else if (arrowLeft) {
            if (currentItem.section !== sectionEnum.suggestions && currentItem.section !== sectionEnum.articles) {
                event.preventDefault();
            }

            if (currentItem.section === sectionEnum.brands && layout === layoutEnum.column) {
                // if section = brands? go to first article or suggestion
                if (finalSearchResult.articles?.items.length) {
                    const indexOfFirstArticle = getIndexByItem(finalSearchResult.articles.items[0], finalSearchResult);
                    toggleActiveProp(indexOfFirstArticle);
                } else if (finalSearchResult.suggestions?.items.length) {
                    const indexOfFirstSuggestions = getIndexByItem(
                        finalSearchResult.suggestions.items[0],
                        finalSearchResult
                    );
                    toggleActiveProp(indexOfFirstSuggestions);
                }
            }
        } else if (arrowRight) {
            if (
                (currentItem.section !== sectionEnum.suggestions || currentItem.section !== sectionEnum.articles) &&
                caretPosition === searchStringLength
            ) {
                event.preventDefault();
            } else return;

            if (currentItem.section === sectionEnum.suggestions && layout === layoutEnum.column) {
                // if section = suggestions? go to first product or brand
                if (finalSearchResult.products?.items.length) {
                    const indexOfFirstProduct = getIndexByItem(finalSearchResult.products.items[0], finalSearchResult);
                    toggleActiveProp(indexOfFirstProduct);
                } else if (finalSearchResult.brands?.items.length) {
                    const indexOfFirstBrand = getIndexByItem(finalSearchResult.brands.items[0], finalSearchResult);
                    toggleActiveProp(indexOfFirstBrand);
                }
            } else if (currentItem.section === sectionEnum.articles && layout === layoutEnum.column) {
                // if section = articles? go to first brand or product
                if (finalSearchResult.brands?.items.length) {
                    const indexOfFirstBrand = getIndexByItem(finalSearchResult.brands.items[0], finalSearchResult);
                    toggleActiveProp(indexOfFirstBrand);
                } else if (finalSearchResult.products?.items.length) {
                    const indexOfFirstProduct = getIndexByItem(finalSearchResult.products.items[0], finalSearchResult);
                    toggleActiveProp(indexOfFirstProduct);
                }
            }
        }
    };

    const resetActiveItem = () => {
        if (!searchActive || !(userNavigation === navigationEvent.arrow || userNavigation === navigationEvent.hover))
            return;
        toggleActiveProp(0);
        resetAiming();
    };

    const onItemHover = (item) => {
        if (layout !== layoutEnum.column) return;
        if (
            finalSearchResult.articles.items.indexOf(item) >= 0 ||
            finalSearchResult.brands.items.indexOf(item) >= 0 ||
            !item
        ) {
            toggleActiveProp(0);
            return;
        }

        userNavigation = navigationEvent.hover;

        // if timer runs out, set item as active.
        clearTimeout(menuAimTimer);
        if (menuAiming) {
            // if timer runs out, set item as active.
            menuAimTimer = setTimeout(() => {
                toggleActiveProp(getIndexByItem(item, finalSearchResult));
            }, menuAimTimerDuration);
            return;
        }
        toggleActiveProp(getIndexByItem(item, finalSearchResult));
    };

    const onAutoFill = (obj) => {
        const { title } = obj;
        userNavigation = navigationEvent.autofill;
        setNavigationInputValue(title);

        if (layout === layoutEnum.tab) updateTabResults(title);
    };

    const dialogTouchCallback = () => {
        if (isHandheld && document.activeElement) {
            (document.activeElement as HTMLElement).blur();
        }
    };

    function trackNoResult(fast = false) {
        UAEventTracking({
            eventCategory: 'quickSearch',
            eventAction: fast ? 'noResultShort' : 'noResult',
            eventLabel: searchString
        });

        GA4Tracking({
            eventName: fast ? 'no_result_short' : 'no_result',
            context: 'search',
            category: 'ecommerce',
            eventParams: [
                { param_name: 'search_type', param_value: 'quick_search' },
                { param_name: 'search_term', param_value: searchString }
            ]
        });
    }

    const mainContextData = {
        isHandheld,
        layout,
        inputValue,
        onClearHistory,
        onAutoFill,
        onItemHover,
        onItemLeave
    };

    useEffect(() => {
        mediator.subscribe('closeQuicksearch', () => {
            searchToggle(false);
        });
        return () => {
            mediator.unsubscribe('closeQuicksearch');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            if (!searchActive) return;

            toggleSpinner(true);

            const result = await getSearchResult(inputValue, null, null, getNextQueries.current);
            if (result !== undefined) {
                setSearchResult(result);
                trackArray(result.dataLayerEvents);
                toggleSpinner(false);
            }

            if (!searchActive || searchString.length <= 0) {
                setActiveTab(sectionEnum.suggestions);
            }
        })();
    }, [searchActive, searchString]);

    useEffect(() => {
        // set layout
        if (!searchActive) return;

        const haveSearchResultItems =
            searchResult !== null &&
            (searchResult.suggestions?.items.length > 0 ||
                searchResult.brandFacets?.items.length > 0 ||
                searchResult.brands?.items.length > 0 ||
                searchResult.products?.items.length > 0 ||
                searchResult.articles?.items.length > 0);

        if (!haveSearchResultItems) {
            setLayout(layoutEnum.noResult);
            setFormattedSearchResult(null);
        } else if (isHandheld && inputValue.length > 0) {
            setLayout(layoutEnum.tab);
            setFormattedSearchResult(formatSearchResult(layoutEnum.tab, searchResult));
        } else if (!isHandheld && inputValue.length > 0 && haveSearchResultItems) {
            setLayout(layoutEnum.column);
            setFormattedSearchResult(formatSearchResult(layoutEnum.column, searchResult));
        } else {
            setLayout(layoutEnum.row);
            setFormattedSearchResult(formatSearchResult(layoutEnum.row, searchResult, latestSearchQueries));
        }
    }, [searchResult, isHandheld]);

    useEffect(() => {
        resetAiming();
        if (layout !== layoutEnum.tab || formattedSearchResult === null) return;
        const getActiveTabResult = getActiveTab(formattedSearchResult, activeTab);

        if (getActiveTabResult !== undefined) {
            setActiveTab(getActiveTabResult);
        } else {
            setLayout(layoutEnum.noResult);
        }
    }, [formattedSearchResult, layout]);

    useEffect(() => {
        setActiveItemCount(0);
        setActiveItem(null);
        itemCount = undefined;
        setFinalSearchResult(formattedSearchResult);
    }, [formattedSearchResult, searchActive]);

    useEffect(() => {
        const overlay = document.querySelector('.js-mainOverlay');

        if (searchActive) {
            overlay?.addEventListener('click', overlayClick);
            overlay?.classList.add('overlay--quicksearch');
        }
        return () => {
            if (searchActive) {
                overlay?.removeEventListener('click', overlayClick);
                overlay?.classList.remove('overlay--quicksearch');
            }
        };
    }, [searchActive]);

    useEffect(() => {
        props.toggleHeaderLocked(searchActive);
    }, [searchActive]);

    useEffect(() => {
        const wrapper = scrollRef.current;

        if (!isHandheld) {
            // Check if dialog is fully in window if opened in fixedHeader
            const windowHeight = window.innerHeight;
            const isHeaderFixed = document.body.classList.contains('state--fixed');
            if (!searchActive || windowHeight > 850 || !isHeaderFixed) return;

            const { top } = wrapper.getBoundingClientRect();
            const bottomSpacing = 15;

            requestAnimationFrame(() => {
                wrapper.style.maxHeight = `${windowHeight - top - bottomSpacing}px`;
            });
        }

        // eslint-disable-next-line consistent-return
        return () => {
            if (!isHandheld) {
                requestAnimationFrame(() => {
                    wrapper.style.maxHeight = '';
                });
            }
        };
    }, [searchActive, isHandheld]);

    // Track noResult. within 1000ms = track noResultShort, after 1000ms = track noResult
    useEffect(() => {
        // For tracking no results
        // Track no result if noResult-layout has been shown for 1000ms.
        if (layout !== layoutEnum.noResult) {
            // Do not track no results if other layouts are shown (the user found something)
            clearTimeout(noResultTrackingTimer);
        }

        if (layout === layoutEnum.noResult && searchActive) {
            noResultTrackingTimer = setTimeout(() => {
                trackNoResult();
                // It's after 1000ms, so we wont track noResultShort.
                readyFastNoResultTracking = false;
            }, noResultTrackingTimerDuration);
        }
    }, [layout, searchActive, searchString]);

    useEffect(() => {
        // For tracking a short view of noResult layout
        // if the user sees a noResult layout, but removes text before tracking no result (within 1000ms).
        if (layout === layoutEnum.noResult && inputValue.length < searchString.length) {
            // Removing text
            clearTimeout(noResultTrackingTimer);
            if (readyFastNoResultTracking) {
                trackNoResult(true);
                readyFastNoResultTracking = false;
            }
        } else if (inputValue.length > searchString.length) {
            // Writing
            // Now we can track noResultShort again
            readyFastNoResultTracking = true;
        }
    }, [layout, searchString, inputValue]);

    function openQuicksearchViaButton() {
        setButtonControl(false);
        setSearchActive(true);
    }

    useEffect(() => {
        mediator.subscribe('openQuickSearch', openQuicksearchViaButton);
    }, []);

    useEscapeKeyEffect(() => {
        if (searchActive) {
            searchToggle(false);
        }
    });

    useOnNestedFocusoutEffect(() => {
        if (searchActive && wrapperRef) {
            searchToggle(false);
        }
    }, wrapperRef);

    return (
        <MainContext.Provider value={mainContextData}>
            {!isHandheld && searchActive && (
                <ShadowOverlay isSearchActive={searchActive} onClick={() => searchToggle(false)} />
            )}
            <Wrapper ref={wrapperRef}>
                <SearchContainer
                    $isSearchActive={searchActive}
                    $isPosRequest={isPosRequest}
                    ref={scrollRef}
                    data-js-open={searchActive}
                    role={searchActive ? 'dialog' : undefined}
                    aria-modal={searchActive}
                    tabIndex={-1}
                    onMouseMove={(event) => {
                        if (menuAiming && layout === layoutEnum.column && finalSearchResult.products.items.length > 0) {
                            if (!isPointInTriangle(event)) {
                                menuAiming = false;
                            }
                        }
                    }}
                    onMouseLeave={() => {
                        resetAiming();
                        toggleActiveProp(0);
                    }}
                >
                    {!buttonControl ? (
                        <SearchInput
                            searchToggle={searchToggle}
                            searchActive={searchActive}
                            inputValue={navigationInputValue}
                            searchText={searchText}
                            handleInputValue={handleInputValue}
                            onEnterCallback={onEnterCallback}
                            arrowNavigationCallback={arrowNavigation}
                            resetActiveItem={resetActiveItem}
                            shouldDisplayCameraButton={shouldDisplayCameraButton}
                            inputRef={inputRef}
                        />
                    ) : (
                        <ButtonWithIcon
                            icon={<SearchIcon aria-label={t(Search)} />}
                            type="button"
                            variant="secondary"
                            style={{ backgroundColor: ColorNordicGrey4 }}
                            onClick={openQuicksearchViaButton}
                        />
                    )}
                    {searchActive && (
                        <Dialog
                            searchResult={finalSearchResult}
                            latestSearchQueries={latestSearchQueries}
                            activeTab={activeTab}
                            setActiveTabCallback={setActiveTabCallback}
                            loadingSpinnerActive={loadingSpinnerActive}
                            sectionCoordsCallback={sectionCoordsCallback}
                            dialogTouchCallback={dialogTouchCallback}
                        />
                    )}
                </SearchContainer>
            </Wrapper>
        </MainContext.Provider>
    );
}

const mapDispatchToProps = {
    toggleHeaderLocked
};

export default connect(null, mapDispatchToProps)(QuickSearch);
