const fontSizes = {
    base: '14px',
    small: '12px',
    large: '16px',
    heading2: '24px',
    heading3: '18px'
};

const lineHeights = {
    base: 1.5
};

enum CustomFonts {
    Inter = 'Inter',
    DMSerifText = 'DM_Serif_Text',
    MatasHandwrite = 'MatasHandwrite, "Brush Script MT"'
}

export default {
    sizeRoot: fontSizes.base,
    lineHeightBase: lineHeights.base,

    sizeSmall: fontSizes.small,
    sizeLarge: fontSizes.large,
    CustomFonts,
    heading2: fontSizes.heading2,
    heading3: fontSizes.heading3
};
