import { TOGGLE_STICKY_FILTERS } from '../actions/filterActions';

const initialState = {
    sticky: false
};

const actions = {};

actions[TOGGLE_STICKY_FILTERS] = (state, action) => {
    return {
        ...state,
        sticky: action.bool
    };
};

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
