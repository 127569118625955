export interface IUpdatePath {
    url: string;
    title: string;
}

export interface IZendeskObject {
    $zopim: any;
    zE: (param1: string, param2?: string, cb?: () => void) => void;
}

let retryLoadZendesk = 3;

export const loadZendesk = (): Promise<IZendeskObject> =>
    new Promise((resolve, reject): void => {
        if (typeof window.loadZendesk !== 'function') {
            if (retryLoadZendesk > 0) {
                retryLoadZendesk -= 1;
                setTimeout((): void => {
                    loadZendesk().then(resolve).catch(reject);
                }, 0);
                console.info('retrying loadZendesk');
            }

            return;
        }

        (async (): Promise<any> => {
            const { zE, $zopim } = await window.loadZendesk();

            if (typeof window !== 'undefined') {
                const updatePathArray = JSON.parse(window.sessionStorage.getItem('updatePathArray')) || [];

                window.sessionStorage.setItem(
                    'updatePathArray',
                    JSON.stringify(
                        updatePathArray
                            .map((page: IUpdatePath): null => {
                                $zopim.livechat.sendVisitorPath(page);
                                return null;
                            })
                            .filter(Boolean)
                    )
                );
            }

            zE('webWidget:on', 'chat:connected', (): void => {
                resolve({ zE, $zopim });
            });
        })().catch((err): void => {
            console.warn(err);

            // Give the user some spinner time, to see that the button click worked, even if loading the chat fails.
            setTimeout((): void => {
                reject();
            }, 1000);
        });
    });

export default loadZendesk;
