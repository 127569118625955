import store from 'store';
import { toggleHeaderLocked } from 'store/actions/headerActions';
import breakpoint from 'modules/breakpoint';
import mediator from 'modules/mediator';
import track from 'modules/tracking/dataLayer';
import { UpdateQuantity } from 'autogen/swagger/basket';
import { _MiniBasket } from 'autogen/swagger/minibasket';

export default class MinibasketDialog {
    constructor(btnCart) {
        this.btnCart = btnCart;
        this.btnCartDialog = this.btnCart.find('[data-js="btnCartDialog"]');
        this.innerDialog = this.btnCart.find('.js-minibasket-wrap');
        this.activeClass = 'state--active';
        this.timer = null;
        this.isMiniBasketActive = false;
        this.numberInput = $('[data-js="input:number:field"]');
        this.isMiniBasketVisible = false;
    }

    init() {
        this.bindings();
        mediator.subscribe('updateMiniBasketView', () => this.updateMiniBasket());
    }

    bindings() {
        this.btnCart.on('mouseenter mouseleave', event => this.dialogToggle(event));
        this.btnCart.on('change', this.numberInput, event => this.updateProductsQuantity(event));
    }

    updateProductsQuantity(e) {
        const $target = $(e.target);
        const quantity = parseInt($target.val());
        const productId = $target.attr('data-js-product-id');

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            UpdateQuantity({ productId, quantity }).then(response => {
                const { dataLayerDto } = response;
                this.updateMiniBasket();
                mediator.publish('updateQuantities', 'basket');

                if ($('#basketpage').length > 0) {
                    (async () => {
                        const { updateBasket } = await import(/* webpackChunkName: "basket" */ '../views/basket/index');

                        updateBasket();
                    })();
                }

                if (dataLayerDto) {
                    track(dataLayerDto);
                }
            });
        }, 500);
    }

    dialogToggle(event) {
        clearTimeout(this.timer);
        if (event.type === 'mouseenter') {
            this.timer = setTimeout(() => this.dialogShow(), 200);
        } else if (event.type === 'mouseleave') {
            this.timer = setTimeout(() => this.dialogHide(), 200);
        }
    }

    dialogShow() {
        if (!breakpoint.min('xl')) return;

        this.btnCartDialog.addClass(this.activeClass);
        this.setMiniBasketMaxHeight();
        this.getMiniBasketView();
        this.isMiniBasketVisible = true;
        store.dispatch(toggleHeaderLocked(true));
    }

    dialogHide() {
        this.btnCartDialog.removeClass(this.activeClass);
        this.isMiniBasketVisible = false;
        store.dispatch(toggleHeaderLocked(false));
    }

    getMiniBasketView() {
        if (this.isMiniBasketActive === false) {
            $.loadingSpinner(this.innerDialog, true);
            _MiniBasket().then(partialViewResult => {
                this.innerDialog.html(partialViewResult);
                $.loadingSpinner(this.innerDialog, false);
                this.isMiniBasketActive = true;
            });
        }
    }

    updateMiniBasket() {
        this.isMiniBasketActive = false;

        if (this.isMiniBasketVisible) {
            $.loadingSpinner(this.innerDialog, true);
            this.getMiniBasketView();
        } else {
            this.innerDialog.empty();
        }
    }

    setMiniBasketMaxHeight() {
        const minHeight = 500;
        const windowHeight = window.innerHeight;
        const pageOffset = window.pageYOffset;
        const dialogOffsetTop = Math.round(this.btnCartDialog.offset().top);
        const spaceAvailable = windowHeight - (dialogOffsetTop - pageOffset);
        const dialogMaxHeight = spaceAvailable > minHeight ? spaceAvailable : minHeight;

        this.btnCartDialog.css({
            'max-height': `${dialogMaxHeight}px`
        });
    }
}
