/* eslint-disable import/prefer-default-export */

import { Splash } from 'autogen/swagger/product';
import { splashesWithImagesTypesArray } from './types';

export function handleColor(color: string | undefined) {
    if (!color) return 'transparent';
    if (color.indexOf('#') === 0) return color;

    return `rgb(${color})`;
}

export function filterSplashesByImage(arrayData?: Splash[]): Splash[] {
    if (!arrayData) return [];

    return arrayData.filter((splash: Splash) => {
        // mimicking splashesWithImagesTypesArray.includes(splash.type);
        for (let i = 0; i < splashesWithImagesTypesArray.length; i++) {
            if (splash.type === splashesWithImagesTypesArray[i]) {
                return true;
            }
        }
        return false;
    });
}
