import { selector, useRecoilValue } from 'recoil';
import SideDrawer from 'components/generic/sideDrawer/SideDrawer';
import { zIndex } from 'variables';
import styled from 'styled-components';
import GenericOverlay, { type OverlayProps } from 'components/generic/sideDrawer/components/GenericOverlay';
import useBodyScrollLock from 'hooks/globals/useBodyScrollLock';
import { smartviewInstancesIdsAtom } from '../shared/recoil/atoms';
import useClearAllSmartviews from '../shared/hooks/useClearAllSmartviews';

const StyledSideDrawer = styled(SideDrawer)`
    overscroll-behavior: contain;
    max-height: 100%;
    z-index: ${zIndex.modal};
`;

let shouldInitializeSmartviewSideDrawer = false;

export const isSmartviewSideDrawerOpen = selector({
    key: 'is-smartview-side-drawer-open',
    get: ({ get }) => !!get(smartviewInstancesIdsAtom).length
});

export const isRightSmartviewDrawerOpen = selector({
    key: 'is-right-smartview-drawer-open',
    get: ({ get }) => {
        const smartviewInstances = get(smartviewInstancesIdsAtom);
        const rightSmartviews = smartviewInstances.filter((smartview) => smartview.position === 'right');
        return !!rightSmartviews.length;
    }
});

export const isLeftSmartviewDrawerOpen = selector({
    key: 'is-left-smartview-drawer-open',
    get: ({ get }) => {
        const smartviewInstances = get(smartviewInstancesIdsAtom);
        const leftSmartviews = smartviewInstances.filter((smartview) => smartview.position === 'left');
        return !!leftSmartviews.length;
    }
});

function LeftDrawer() {
    const isLeftOpen = useRecoilValue(isLeftSmartviewDrawerOpen);
    const clearAll = useClearAllSmartviews();

    return <StyledSideDrawer position="left" isOpen={isLeftOpen} onClose={clearAll} />;
}

function RightDrawer() {
    const isRightOpen = useRecoilValue(isRightSmartviewDrawerOpen);
    const clearAll = useClearAllSmartviews();

    return <StyledSideDrawer position="right" isOpen={isRightOpen} onClose={clearAll} />;
}

function DrawerOverlay(props?: OverlayProps) {
    const isOpen = useRecoilValue(isSmartviewSideDrawerOpen);
    const clearAll = useClearAllSmartviews();

    const overlayProps = {
        onClick: () => clearAll(),
        ...props
    };

    return <GenericOverlay isActive={isOpen} {...overlayProps} />;
}

/**
 * SmartviewSideDrawer should only be instantiated once globally in a React Application
 * @returns JSX.element
 */
export default function SmartviewSideDrawer({ overlayProps }: { overlayProps?: OverlayProps }) {
    const isOpen = useRecoilValue(isSmartviewSideDrawerOpen);

    useBodyScrollLock(isOpen);

    /* This conditional is only here until we have a full React application!  */
    if (isOpen && !shouldInitializeSmartviewSideDrawer) {
        shouldInitializeSmartviewSideDrawer = true;
    }
    if (!shouldInitializeSmartviewSideDrawer) return null;

    return (
        <>
            <LeftDrawer />
            <DrawerOverlay {...overlayProps} />
            <RightDrawer />
        </>
    );
}
