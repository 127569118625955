import { Spacing8 } from 'autogen/design-tokens/tokens';
import type { SvgSpriteType } from 'autogen/spriteData';
import { SvgSprite } from 'modules/svgSprite';
import React, { type HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import type { IconPosition, IconProperties, IconSize, IWithIcon } from './shared/types';
import type { WithIconProps } from 'components/Button/shared/types/buttonWithIcon';
import { mediaQueryStyling } from 'helpers/mediaQueryStyling';

const Container = styled.div<{ position: IconPosition; $spacing: NonNullable<IconProperties['spacing']> }>`
    display: inline-grid;
    align-items: center;
    justify-items: center;

    ${({ position }) => {
        if (position === 'left' || position === 'right') {
            return css`
                grid-template-columns: auto auto;
            `;
        }

        return undefined;
    }}



    ${({ $spacing }) =>
        typeof $spacing === 'object'
            ? mediaQueryStyling($spacing)
            : css`
            gap: ${$spacing};
        `}

`;

const StyledSvgSprite = styled(SvgSprite)<{ $size: string | IconSize }>`
    width: ${({ $size }) => (typeof $size === 'string' ? $size : $size.width)};
    height: ${({ $size }) => (typeof $size === 'string' ? $size : $size.height)};
`;

const isSizeObj = (size: IconProperties['size']) => {
    if ((size as IconSize).width !== undefined && (size as IconSize).height !== undefined) return true;

    return false;
};

function isReactElement(icon: SvgSpriteType | JSX.Element): icon is JSX.Element {
    return React.isValidElement(icon);
}

const WithIcon: React.FC<React.PropsWithChildren<IWithIcon>> = ({
    icon,
    position = 'left',
    spacing = Spacing8,
    fill = 'currentColor',
    size = '16px',
    children,
    ...restProps
}) => {
    const iconElement = isReactElement(icon) ? (
        // Cloning element with props passed directly to the element or through the WithIcon iconProperties. props passed directly to the element takes priority.
        React.cloneElement(icon, {
            width: icon.props.width || (isSizeObj(size) ? (size as IconSize).width : size),
            height: icon.props.height || (isSizeObj(size) ? (size as IconSize).height : size),
            fill: icon.props.fill || fill
        })
    ) : (
        <StyledSvgSprite $size={size} sprite={icon} fill={fill} />
    );

    const items =
        position === 'left' || position === 'top' ? (
            <>
                {iconElement}
                {children}
            </>
        ) : (
            <>
                {children}
                {iconElement}
            </>
        );

    return (
        <Container position={position} $spacing={spacing} {...restProps}>
            {items}
        </Container>
    );
};

export const withIcon = <T extends HTMLElement, P>(Component: React.ComponentType<P>) =>
    React.forwardRef<T, P & WithIconProps & HTMLAttributes<T>>((props, ref) => {
        const { icon, iconProperties, children, ...restProps } = props;
        const hasChildren = children && React.Children.count(children);

        const buttonContent = icon ? (
            <WithIcon icon={icon} spacing={!hasChildren ? '0px' : iconProperties?.spacing} {...iconProperties}>
                {children}
            </WithIcon>
        ) : (
            children
        );

        return (
            <Component ref={ref} {...(restProps as P)}>
                {buttonContent}
            </Component>
        );
    });

export default WithIcon;
