import React from 'react';

export const formatReact =
    string =>
    (...args) => {
        const splitString = string?.split(/\{[a-zA-Z0-9]+\}/);

        return splitString?.map((stringFragment, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
                <span dangerouslySetInnerHTML={{ __html: stringFragment }} />
                {args[index]}
            </React.Fragment>
        ));
    };

export const toHtmlContent = (string, Element = 'div', className = '') => {
    if (React.isValidElement(Element)) {
        return React.cloneElement(Element, { dangerouslySetInnerHTML: { __html: string } });
    }
    return <Element className={className} dangerouslySetInnerHTML={{ __html: string }} />;
};

export const toHtmlString = ({ value } = {}) => (value ? toHtmlContent(value, 'span') : null);

export const format =
    string =>
    (...args) =>
        args.reduce((acc, arg, index) => acc.replace(`{${index}}`, arg), string);

export const URLify = string =>
    // if the string doesn't have a space, then it's properly already encoded.
    string.split(' ').length > 1 ? encodeURI(string.trim()) : string.trim();

export const stringToKey = string => {
    if (!string) return;
    // map special characters
    const charsMap = {
        æ: 'ae',
        ø: 'oe',
        å: 'aa'
    };
    const mappedString = string.replace(/[æøå]/g, c => charsMap[c]);

    // replace everything but letters
    return mappedString.toLowerCase().replace(/[^a-z]/g, '');
};

export const safelyParseJSON = json => {
    try {
        return JSON.parse(json);
    } catch (e) {
        return json;
    }
};

export function toPascalCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, word => word.toUpperCase()).replace(/\s+/g, '');
}

export function objectToPascalCase(obj) {
    const newObj = {};
    Object.entries(obj).forEach(([key, val]) => {
        if (typeof val === 'object') {
            newObj[toPascalCase(key)] = objectToPascalCase(val);
        } else {
            newObj[toPascalCase(key)] = val;
        }
    });
    return newObj;
}

export function pad(number, width = 2, char = '0') {
    const numberString = number.toString();
    return numberString.length >= width
        ? numberString
        : new Array(width - numberString.length + 1).join(char) + numberString;
}

export function capitalizeFirstLetter(str) {
    return str ? str[0].toUpperCase() + str.substring(1) : undefined;
}

export function firstCharacterToLower(str) {
    return str ? str[0].toLowerCase() + str.substring(1) : undefined;
}

export function thousandSeparator(number, separator = '.') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function removeHTMLTagsFromString(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
}
