import { animated, useTransition } from '@react-spring/web';
import { BoxShadowLarge, ColorNeutralWhite } from 'autogen/design-tokens/tokens';
import useEscapeKeyEffect from 'hooks/globals/useEscapeKeyEffect';
import { type ReactNode, useRef } from 'react';
import { atom, useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import useSetDialogFocusEffect from 'hooks/globals/useSetDialogFocusEffect';
import { breakpoint, sizes } from 'variables';
import useClearAllSmartviews from '../smartview/shared/hooks/useClearAllSmartviews';

export type Position = 'right' | 'left';
export interface ISideDrawer {
    isOpen: boolean;
    onClose?: () => void;
    position?: Position;
    children?: ReactNode;
}

const SideDrawerContainer = styled<any>(animated.div)<{ $isOpen: boolean; position: Position }>`
    position: fixed;
    display: flex;
    top: 0;
    ${({ position }) => (position === 'right' ? 'right: 0;' : 'left: 0;')};
    width: 100%;
    min-height: 100%;
    background-color: ${ColorNeutralWhite};
    overflow: hidden;
    max-width: 480px;
    will-change: transform;

    ${({ $isOpen }) =>
        $isOpen &&
        css`
            box-shadow: ${BoxShadowLarge};
        `}

    ${breakpoint.up(sizes.sm)} {
        width: calc(100% - 56px);
    }
`;

export const drawerReferenceAtom = atom({
    key: `side-drawer-ref-${uuidv4()}`,
    default: null
});

const rightConfig = {
    from: { x: '100%' },
    enter: { x: '0%' },
    leave: { x: '100%' },
    expires: false
};

const leftConfig = {
    from: { x: '-100%' },
    enter: { x: '0%' },
    leave: { x: '-100%' },
    expires: false
};

const getTransitionConfig = (position: ISideDrawer['position']) => {
    if (position === 'right') return rightConfig;
    if (position === 'left') return leftConfig;
    return leftConfig;
};

export default function SideDrawer({ isOpen, position = 'left', children, onClose, ...restProps }: ISideDrawer) {
    const [, setRef] = useRecoilState(drawerReferenceAtom);

    const transitionConfig = getTransitionConfig(position);

    const drawerTransition = useTransition(isOpen, transitionConfig);

    useEscapeKeyEffect(() => {
        if (isOpen && onClose) {
            onClose();
        }
    });

    return drawerTransition(
        (styles, active) =>
            active && (
                <SideDrawerContainer
                    role="dialog"
                    aria-modal
                    tabIndex="-1"
                    style={styles}
                    ref={setRef}
                    position={position}
                    $isOpen={isOpen}
                    {...restProps}
                >
                    {children}
                </SideDrawerContainer>
            )
    );
}
