import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Card from 'components/generic/card';
import * as transKey from 'autogen/translation-keys/trans-root-website';
import { useTranslation } from 'react-i18next';
import { ColorNeutralBlack, ColorBeauty1, ColorBeauty4, ColorBeauty5, Spacing16 } from 'autogen/design-tokens/tokens';
import { IPickerCard } from '../shared/types';
import VerticalContentLayout from './VerticalContentLayout';
import HorizontalContentLayout from './HorizontalContentLayout';

const StyledCard = styled(Card)<{ hasBorder: boolean }>`
    ${({ hasBorder }) => hasBorder && `border: 1px solid ${ColorBeauty4};`}
`;

const StyledCardHeader = styled(Card.Header)`
    font-weight: bold;
    background-color: ${ColorBeauty1};
    color: ${ColorNeutralBlack};
    justify-content: center;
`;

const StyledCardFooter = styled(Card.Footer)`
    background-color: ${ColorBeauty5};
    color: ${ColorNeutralBlack};
    justify-content: center;
    text-align: center;

    ${({ theme: { isDesktop } }): string => isDesktop && 'margin-top: auto;'}
    ${({ theme: { isDesktop } }): string => isDesktop && 'flex: 0 1 100%;'}
`;

export default function PickerCard(props: IPickerCard): JSX.Element {
    const { isRecommended, recommendedDescription, isDesktop, id, ...restProps } = props;
    const { t } = useTranslation();
    const { CardHeaderRecommended } = transKey.Basket.Samples.SamplesPicker;

    return (
        <ThemeProvider theme={{ isDesktop, isRecommended }}>
            <StyledCard size="sm" hasBorder={!!(isRecommended && recommendedDescription)} {...restProps}>
                {isRecommended && <StyledCardHeader>{t(CardHeaderRecommended)}</StyledCardHeader>}
                <Card.Content size="lg">
                    {!isDesktop ? <VerticalContentLayout {...props} /> : <HorizontalContentLayout {...props} />}
                </Card.Content>
                {isRecommended && recommendedDescription && (
                    <StyledCardFooter>
                        <span dangerouslySetInnerHTML={{ __html: recommendedDescription }} />
                    </StyledCardFooter>
                )}
            </StyledCard>
        </ThemeProvider>
    );
}
