/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as MyProfile from './trans-website-productreview-myprofile';
export * as Create from './trans-website-productreview-create';
export * as List from './trans-website-productreview-list';
export * as Error from './trans-website-productreview-error';
export * as Header from './trans-website-productreview-header';
export * as ReviewReport from './trans-website-productreview-reviewreport';
export * as Rating from './trans-website-productreview-rating';
export * as Sort from './trans-website-productreview-sort';

export const CustomersRating = 'Website.ProductReview.CustomersRating';
export const ReviewHeadingUnrated = 'Website.ProductReview.ReviewHeadingUnrated';
export const ReviewsAnchorLink = 'Website.ProductReview.ReviewsAnchorLink';
export const Ratings = 'Website.ProductReview.Ratings';
export const SingleRating = 'Website.ProductReview.SingleRating';
export const SeeRating = 'Website.ProductReview.SeeRating';
export const SeeRatings = 'Website.ProductReview.SeeRatings';
export const ProductAccordionAnchor = 'Website.ProductReview.ProductAccordionAnchor';
export const ChangeRating = 'Website.ProductReview.ChangeRating';
export const ILikedThis = 'Website.ProductReview.ILikedThis';
export const ReceivedText = 'Website.ProductReview.ReceivedText';
export const DeleteProductReview = 'Website.ProductReview.DeleteProductReview';
export const PersonRecommendsThis = 'Website.ProductReview.PersonRecommendsThis';
export const LessThanAMinute = 'Website.ProductReview.LessThanAMinute';
export const AboutAMinute = 'Website.ProductReview.AboutAMinute';
export const MinutesAgo = 'Website.ProductReview.MinutesAgo';
export const OneHourAgo = 'Website.ProductReview.OneHourAgo';
export const HoursAgo = 'Website.ProductReview.HoursAgo';
export const DaysAgo = 'Website.ProductReview.DaysAgo';
export const ReviewItemSeeMore = 'Website.ProductReview.ReviewItemSeeMore';
export const ShowMoreReviewsButtonText = 'Website.ProductReview.ShowMoreReviewsButtonText';
export const CustomersSays = 'Website.ProductReview.CustomersSays';
export const MoreReviews = 'Website.ProductReview.MoreReviews';
export const VerifiedPurchaseTag = 'Website.ProductReview.VerifiedPurchaseTag';
