
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection ES6UnusedImports
        // noinspection JSUnusedGlobalSymbols
        import { useQuery, type UseQueryOptions, useMutation, type UseMutationOptions } from '@tanstack/react-query';
        import {
GetBasket,
type GetBasketSuccess,
_BasketFooter,
type _BasketFooterSuccess,
_ShippingOptions,
type _ShippingOptionsSuccess,
DeleteFromBasket,
type DeleteFromBasketSuccess,
type DeleteFromBasketParams,
DeleteAllFromBasket,
type DeleteAllFromBasketSuccess,
DeleteGiftFromBasket,
type DeleteGiftFromBasketParams,
AddToBasket,
type AddToBasketSuccess,
type AddToBasketParams,
AddMultipleToBasket,
type AddMultipleToBasketSuccess,
type AddMultipleToBasketParams,
AddOrUpdateQuantity,
type AddOrUpdateQuantitySuccess,
type AddOrUpdateQuantityParams,
UpdateQuantity,
type UpdateQuantitySuccess,
type UpdateQuantityParams,
UpdateFrequency,
type UpdateFrequencySuccess,
type UpdateFrequencyParams,
ToggleSwapCertificates,
type ToggleSwapCertificatesSuccess,
type ToggleSwapCertificatesParams,
UpdateVoucherCode,
type UpdateVoucherCodeSuccess,
type UpdateVoucherCodeParams,
RemoveVoucher,
type RemoveVoucherSuccess,
_BasketAddMinimumBuy,
type _BasketAddMinimumBuySuccess,
type _BasketAddMinimumBuyParams,
AddDigitalProduct,
type AddDigitalProductSuccess,
type AddDigitalProductParams,
RemovePrizeCertificate,
type RemovePrizeCertificateSuccess,
type RemovePrizeCertificateParams,
BasketAddPrizeCertificate,
type BasketAddPrizeCertificateSuccess,
type BasketAddPrizeCertificateParams,
GetSampleSelection,
type GetSampleSelectionSuccess,
ActionWillAffectSample,
type ActionWillAffectSampleSuccess,
type ActionWillAffectSampleParams,
AddSampleToBasket,
type AddSampleToBasketSuccess,
type AddSampleToBasketParams,
RemoveSampleFromBasket,
type RemoveSampleFromBasketSuccess,
type RemoveSampleFromBasketParams,
ReplaceSampleInBasket,
type ReplaceSampleInBasketSuccess,
type ReplaceSampleInBasketParams,
GetPointInfoModel,
type GetPointInfoModelSuccess,
DonationModel,
type DonationModelSuccess,
BasketLineSubscriptionNudge,
type BasketLineSubscriptionNudgeSuccess,
BasketMessageDiscountViewModel,
type BasketMessageDiscountViewModelSuccess,
BasketShippingSupplierOption,
type BasketShippingSupplierOptionSuccess,
ShippingSummaryModel,
type ShippingSummaryModelSuccess} from './index'

        
        
    export const GetBasketQueryKey = 'basket_GetBasket';

    export function useGetBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetBasketSuccess, TError, GetBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetBasketSuccess | (() => GetBasketSuccess);},  baseQueryKey = GetBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            GetBasket, options);
        }
  
    
        
    export const _BasketFooterQueryKey = 'basket__BasketFooter';

    export function use_BasketFooterQuery<TError = unknown>( options?: Omit<UseQueryOptions<_BasketFooterSuccess, TError, _BasketFooterSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _BasketFooterSuccess | (() => _BasketFooterSuccess);},  baseQueryKey = _BasketFooterQueryKey) {
            return useQuery([baseQueryKey, ],
            _BasketFooter, options);
        }
  
    
        
    export const _ShippingOptionsQueryKey = 'basket__ShippingOptions';

    export function use_ShippingOptionsQuery<TError = unknown>( options?: Omit<UseQueryOptions<_ShippingOptionsSuccess, TError, _ShippingOptionsSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _ShippingOptionsSuccess | (() => _ShippingOptionsSuccess);},  baseQueryKey = _ShippingOptionsQueryKey) {
            return useQuery([baseQueryKey, ],
            _ShippingOptions, options);
        }
  
    
        
      export function useDeleteFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteFromBasketSuccess, TError, {params: DeleteFromBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeleteFromBasket(params, ), options)}
  
    
        
      export function useDeleteAllFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<DeleteAllFromBasketSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    DeleteAllFromBasket( ), options)}
  
    
        
      export function useDeleteGiftFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<unknown, TError, {params: DeleteGiftFromBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    DeleteGiftFromBasket(params, ), options)}
  
    
        
      export function useAddToBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddToBasketSuccess, TError, {params: AddToBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddToBasket(params, ), options)}
  
    
        
      export function useAddMultipleToBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddMultipleToBasketSuccess, TError, {params: AddMultipleToBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddMultipleToBasket(params, ), options)}
  
    
        
      export function useAddOrUpdateQuantityMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddOrUpdateQuantitySuccess, TError, {params: AddOrUpdateQuantityParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddOrUpdateQuantity(params, ), options)}
  
    
        
      export function useUpdateQuantityMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateQuantitySuccess, TError, {params: UpdateQuantityParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateQuantity(params, ), options)}
  
    
        
      export function useUpdateFrequencyMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateFrequencySuccess, TError, {params: UpdateFrequencyParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateFrequency(params, ), options)}
  
    
        
      export function useToggleSwapCertificatesMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ToggleSwapCertificatesSuccess, TError, {params: ToggleSwapCertificatesParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ToggleSwapCertificates(params, ), options)}
  
    
        
      export function useUpdateVoucherCodeMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<UpdateVoucherCodeSuccess, TError, {params: UpdateVoucherCodeParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    UpdateVoucherCode(params, ), options)}
  
    
        
      export function useRemoveVoucherMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RemoveVoucherSuccess, TError, {} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({ }) =>
    RemoveVoucher( ), options)}
  
    
        
    export const _BasketAddMinimumBuyQueryKey = 'basket__BasketAddMinimumBuy';

    export function use_BasketAddMinimumBuyQuery<TError = unknown>(params: _BasketAddMinimumBuyParams, options?: Omit<UseQueryOptions<_BasketAddMinimumBuySuccess, TError, _BasketAddMinimumBuySuccess, [string, _BasketAddMinimumBuyParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _BasketAddMinimumBuySuccess | (() => _BasketAddMinimumBuySuccess);},  baseQueryKey = _BasketAddMinimumBuyQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _BasketAddMinimumBuy(params), options);
        }
  
    
        
      export function useAddDigitalProductMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddDigitalProductSuccess, TError, {params: AddDigitalProductParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddDigitalProduct(params, ), options)}
  
    
        
      export function useRemovePrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RemovePrizeCertificateSuccess, TError, {params: RemovePrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    RemovePrizeCertificate(params, ), options)}
  
    
        
      export function useBasketAddPrizeCertificateMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<BasketAddPrizeCertificateSuccess, TError, {params: BasketAddPrizeCertificateParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    BasketAddPrizeCertificate(params, ), options)}
  
    
        
    export const GetSampleSelectionQueryKey = 'basket_GetSampleSelection';

    export function useGetSampleSelectionQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetSampleSelectionSuccess, TError, GetSampleSelectionSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetSampleSelectionSuccess | (() => GetSampleSelectionSuccess);},  baseQueryKey = GetSampleSelectionQueryKey) {
            return useQuery([baseQueryKey, ],
            GetSampleSelection, options);
        }
  
    
        
    export const ActionWillAffectSampleQueryKey = 'basket_ActionWillAffectSample';

    export function useActionWillAffectSampleQuery<TError = unknown>(params: ActionWillAffectSampleParams, options?: Omit<UseQueryOptions<ActionWillAffectSampleSuccess, TError, ActionWillAffectSampleSuccess, [string, ActionWillAffectSampleParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ActionWillAffectSampleSuccess | (() => ActionWillAffectSampleSuccess);},  baseQueryKey = ActionWillAffectSampleQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => ActionWillAffectSample(params), options);
        }
  
    
        
      export function useAddSampleToBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<AddSampleToBasketSuccess, TError, {params: AddSampleToBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    AddSampleToBasket(params, ), options)}
  
    
        
      export function useRemoveSampleFromBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<RemoveSampleFromBasketSuccess, TError, {params: RemoveSampleFromBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    RemoveSampleFromBasket(params, ), options)}
  
    
        
      export function useReplaceSampleInBasketMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<ReplaceSampleInBasketSuccess, TError, {params: ReplaceSampleInBasketParams} & TVariables>, 'mutationFn'>) {
          // biome-ignore lint/correctness/noEmptyPattern: <explanation>
          return useMutation(({params, }) =>
    ReplaceSampleInBasket(params, ), options)}
  
    
        
    export const GetPointInfoModelQueryKey = 'basket_GetPointInfoModel';

    export function useGetPointInfoModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetPointInfoModelSuccess, TError, GetPointInfoModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetPointInfoModelSuccess | (() => GetPointInfoModelSuccess);},  baseQueryKey = GetPointInfoModelQueryKey) {
            return useQuery([baseQueryKey, ],
            GetPointInfoModel, options);
        }
  
    
        
    export const DonationModelQueryKey = 'basket_DonationModel';

    export function useDonationModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<DonationModelSuccess, TError, DonationModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: DonationModelSuccess | (() => DonationModelSuccess);},  baseQueryKey = DonationModelQueryKey) {
            return useQuery([baseQueryKey, ],
            DonationModel, options);
        }
  
    
        
    export const BasketLineSubscriptionNudgeQueryKey = 'basket_BasketLineSubscriptionNudge';

    export function useBasketLineSubscriptionNudgeQuery<TError = unknown>( options?: Omit<UseQueryOptions<BasketLineSubscriptionNudgeSuccess, TError, BasketLineSubscriptionNudgeSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BasketLineSubscriptionNudgeSuccess | (() => BasketLineSubscriptionNudgeSuccess);},  baseQueryKey = BasketLineSubscriptionNudgeQueryKey) {
            return useQuery([baseQueryKey, ],
            BasketLineSubscriptionNudge, options);
        }
  
    
        
    export const BasketMessageDiscountViewModelQueryKey = 'basket_BasketMessageDiscountViewModel';

    export function useBasketMessageDiscountViewModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<BasketMessageDiscountViewModelSuccess, TError, BasketMessageDiscountViewModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BasketMessageDiscountViewModelSuccess | (() => BasketMessageDiscountViewModelSuccess);},  baseQueryKey = BasketMessageDiscountViewModelQueryKey) {
            return useQuery([baseQueryKey, ],
            BasketMessageDiscountViewModel, options);
        }
  
    
        
    export const BasketShippingSupplierOptionQueryKey = 'basket_BasketShippingSupplierOption';

    export function useBasketShippingSupplierOptionQuery<TError = unknown>( options?: Omit<UseQueryOptions<BasketShippingSupplierOptionSuccess, TError, BasketShippingSupplierOptionSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: BasketShippingSupplierOptionSuccess | (() => BasketShippingSupplierOptionSuccess);},  baseQueryKey = BasketShippingSupplierOptionQueryKey) {
            return useQuery([baseQueryKey, ],
            BasketShippingSupplierOption, options);
        }
  
    
        
    export const ShippingSummaryModelQueryKey = 'basket_ShippingSummaryModel';

    export function useShippingSummaryModelQuery<TError = unknown>( options?: Omit<UseQueryOptions<ShippingSummaryModelSuccess, TError, ShippingSummaryModelSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: ShippingSummaryModelSuccess | (() => ShippingSummaryModelSuccess);},  baseQueryKey = ShippingSummaryModelQueryKey) {
            return useQuery([baseQueryKey, ],
            ShippingSummaryModel, options);
        }
  
    
    