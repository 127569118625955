import React, { useContext } from 'react';
import styled from 'styled-components';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import MainContext from 'components/QuickSearch/components/context';
import { GA4Tracking } from 'modules/tracking/GA4';
import LinkAction from 'components/shared/Routing/components/LinkAction';

const List = styled.ul`
    padding: 0 0;
    margin: 0;
`;
const ListItem = styled.li`
    list-style: none;
    ${(props) => props.isHandheld && 'border-bottom: 1px solid #ececec;'}
    &:last-of-type {
        border-bottom: 0px;
    }
`;
const Link = styled(LinkAction)`
    padding: 10px 20px;
    display: block;
    &:hover {
        text-decoration: none;
        background-color: #ececec;
    }
`;
const LetterHeading = styled.span`
    display: block;
    width: 100%;
    padding: 0 20px;
    background-color: #ececec;
    line-height: 20px;
    font-weight: 700;
    font-size: 11px;
`;

const dynamicSort = (property) => {
    let sortOrder = 1;

    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }

    return (a, b) => {
        if (sortOrder === -1) {
            return b[property].localeCompare(a[property]);
        }
        return a[property].localeCompare(b[property]);
    };
};

export default (props) => {
    const { items, searchTerm } = props;
    let currentLetter = null;
    const mainContext = useContext(MainContext);
    const { isHandheld } = mainContext;
    items.sort(dynamicSort('name'));
    return (
        <List>
            {items.map((obj, index) => {
                const { linkAction, name, isActive } = obj;

                const thisLetter = name.charAt(0).toUpperCase();
                const isNewLetter = thisLetter !== currentLetter;
                currentLetter = isNewLetter ? thisLetter : currentLetter.toUpperCase();

                const pathName = linkAction.url.split('/').pop().split('?')[0];

                return (
                    <ListItem key={index} isHandheld={isHandheld}>
                        {isNewLetter && <LetterHeading>{currentLetter}</LetterHeading>}
                        <Link
                            action={linkAction}
                            isActive={isActive}
                            onClick={() => {
                                UAEventTracking({
                                    eventCategory: 'quickSearch',
                                    eventAction: 'brandClick',
                                    eventLabel: pathName
                                });

                                GA4Tracking({
                                    eventName: 'brand_click',
                                    context: 'search',
                                    category: 'ecommerce',
                                    eventParams: [
                                        { param_name: 'search_type', param_value: 'quick_search' },
                                        { param_name: 'search_term', param_value: searchTerm }
                                    ]
                                });
                            }}
                        >
                            {name}
                        </Link>
                    </ListItem>
                );
            })}
        </List>
    );
};
