import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const fadeOut = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

export const popup = keyframes`
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
`;

export const popdown = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0.5);
    }
`;

export const bubblePulse = (startOpacity = 0.75) => keyframes`
    0%, 40% {
        transform: scale3d(1, 1, 1);
        opacity: ${startOpacity}
    }
    80%, 100% {
        transform: scale3d(2.5, 2.5, 1);
        opacity: 0
    }
`;
