import { colors, sizes, breakpoint, fonts } from 'variables';
import { em as polishedEM, rem as PolishedREM, stripUnit } from 'polished';
import { css, type RuleSet } from 'styled-components';

export function em(pxVal: string | number, base: string | number = fonts.sizeRoot): string {
    return `${Number.parseFloat(polishedEM(pxVal, base)).toFixed(4)}em`;
}

export function rem(pxVal: string | number, base: string | number = fonts.sizeRoot): string {
    return `${Number.parseFloat(PolishedREM(pxVal, base)).toFixed(4)}rem`;
}

export function percentage(pxVal: string | number, base: string | number): string {
    const strippedValue = Number.parseFloat(stripUnit(pxVal));
    const strippedBase = Number.parseFloat(stripUnit(base));
    const calculatedViewWidth = Number.parseFloat(`${(strippedValue / strippedBase) * 100}`).toFixed(4) || 0;

    return `${calculatedViewWidth}%`;
}

export function vw(pxVal: string | number, base: string | number = 375): string {
    return `${stripUnit(percentage(pxVal, base))}vw`;
}

export function backgroundStripes({
    width = '8px',
    color1 = colors.brand1,
    color2 = 'transparent',
    angle = '135deg'
} = {}): string {
    return `background: repeating-linear-gradient(${angle}, ${color1}, ${color1} ${width}, ${color2} 0, ${color2} ${
        Number.parseInt(width) * 2
    }px);`;
}

export interface IPseudoStripes {
    position?: 'top' | 'bottom';
    width?: string;
    height?: string;
    color1?: string;
    color2?: string;
}

export function pseudoStripes({
    position = 'top',
    width = '8px',
    height = '30px',
    color1 = colors.brand1,
    color2 = 'transparent'
}: IPseudoStripes): RuleSet {
    return css`
        &:before {
            content: '';
            position: absolute;
            ${position}: 0;
            height: ${height};
            left: 0;
            right: 0;
            ${backgroundStripes({ width, color1, color2 })}
        }
    `;
}

interface IDynamicSizeObject {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
    xxxl?: number;
}

export function dynamicSize(cssProperty: string, breakpointObject: IDynamicSizeObject): any {
    return Object.entries(breakpointObject).map((sizeObject, index): string => {
        const [key, size] = sizeObject;
        const nextKey = Object.keys(breakpointObject)[index + 1];
        const nextSize = breakpointObject[nextKey];

        // if last key, set the value in rem so it won't increase beyond max
        if (!nextKey) {
            return `
                ${breakpoint.up(sizes[key])} {    
                    ${cssProperty}: ${rem(size)}
                }`;
        }

        // inspired by https://css-tricks.com/snippets/css/fluid-typography/
        return `
            ${breakpoint.up(sizes[key])} {
                ${cssProperty}: calc(${size}px + (${nextSize} - ${size}) * ((100vw - ${sizes[key]}px) / (${
                    sizes[nextKey]
                } - ${sizes[key]})));
            }
        `;
    });
}

export function dynamicFontSize(breakpointObject: IDynamicSizeObject): string {
    return dynamicSize('font-size', breakpointObject);
}
