import Dropdown from 'components/dropdown';
import React from 'react';
import styled from 'styled-components';
import DropdownButton from 'components/dropdown/partials/DropdownButton';
import DropdownContent from 'components/dropdown/partials/DropdownContent';
import type ISelect from '../shared/types/select';
import SelectOption from './SelectOption';
import SelectProvider from './SelectProvider';
import format from 'helpers/translations';

const OptionsList = styled.div`
    display: flex;
    flex-direction: column;
`;

export default function CustomSelect({
    value,
    options,
    placeholderText,
    disabled,
    className,
    size = 'md',
    ...restProps
}: ISelect) {
    return (
        <Dropdown
            disabled={disabled}
            trigger={
                <DropdownButton size={size} className={className}>
                    {format(value?.label || placeholderText)}
                </DropdownButton>
            }
        >
            <DropdownContent>
                <SelectProvider {...restProps}>
                    <OptionsList>
                        {options.map((option) => (
                            <SelectOption key={option.value} value={option}>
                                {format(option.label)}
                            </SelectOption>
                        ))}
                    </OptionsList>
                </SelectProvider>
            </DropdownContent>
        </Dropdown>
    );
}
