/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as DeleteOrEditConfirmation from './trans-website-basket-wrapping-deleteoreditconfirmation';

export const GiftDate = 'Website.Basket.Wrapping.GiftDate';
export const AsGift = 'Website.Basket.Wrapping.AsGift';
export const WithWrappingCard = 'Website.Basket.Wrapping.WithWrappingCard';
export const CardTo = 'Website.Basket.Wrapping.CardTo';
export const CardFrom = 'Website.Basket.Wrapping.CardFrom';
export const PersonalGreeting = 'Website.Basket.Wrapping.PersonalGreeting';
export const TotalPrice = 'Website.Basket.Wrapping.TotalPrice';
export const NoWrapping = 'Website.Basket.Wrapping.NoWrapping';
export const SaveMyChoices = 'Website.Basket.Wrapping.SaveMyChoices';
export const FreeCards = 'Website.Basket.Wrapping.FreeCards';
