
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './expecteddelivery';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type ZipCodeWithSource = components['schemas']['ZipCodeWithSource'];
                    export type ExpectedDeliveryType = components['schemas']['ExpectedDeliveryType'];
                    export type ExpectedDeliverySingleMethodModel = components['schemas']['ExpectedDeliverySingleMethodModel'];
                    export type ExpectedDeliveryFullInformationModel = components['schemas']['ExpectedDeliveryFullInformationModel'];
                    export type CompanyAddress = components['schemas']['CompanyAddress'];
                    export type ParcelShop = components['schemas']['ParcelShop'];
                    export type PrivateAddress = components['schemas']['PrivateAddress'];
                    export type DeliveryType = components['schemas']['DeliveryType'];
                    export type DeliveryMethodTagType = components['schemas']['DeliveryMethodTagType'];
                    export type DeliveryMethodTag = components['schemas']['DeliveryMethodTag'];
                    export type DeliveryMethodTagMaybe = components['schemas']['DeliveryMethodTagMaybe'];
                    export type DeliveryPrice = components['schemas']['DeliveryPrice'];
                    export type ParcelShopPrice = components['schemas']['ParcelShopPrice'];
                    export type SubscriptionDeliveryPrice = components['schemas']['SubscriptionDeliveryPrice'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type UpsaleDeliveryMethod = components['schemas']['UpsaleDeliveryMethod'];
                    export type DeliveryMethod = components['schemas']['DeliveryMethod'];
                    export type SelectedDeliveryMethod = components['schemas']['SelectedDeliveryMethod'];
                    export type DeliveryMethodCategory = components['schemas']['DeliveryMethodCategory'];
                    export type SimpleDeliveryGroup = components['schemas']['SimpleDeliveryGroup'];
                    export type DropShipDisclaimer = components['schemas']['DropShipDisclaimer'];
                    export type DropShipDisclaimerMaybe = components['schemas']['DropShipDisclaimerMaybe'];
                    export type ExpectedDeliveryInformation = components['schemas']['ExpectedDeliveryInformation'];
                    

        
        

        export type SetZipCodeParams = paths['/internal/ExpectedDelivery/SetZipCode']['post']['parameters']['query'];

        export type SetZipCodeSuccess = paths['/internal/ExpectedDelivery/SetZipCode']['post']['responses']['200']['content']['text/plain'];

        export const SetZipCodeUrl = '/internal/ExpectedDelivery/SetZipCode';

        
        export function SetZipCode(params: SetZipCodeParams): Promise<SetZipCodeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SetZipCodeSuccess>(`/internal/ExpectedDelivery/SetZipCode${formatParams(params)}`)
        }
    
    
        

        export type SetZipCodeByCoordinatesParams = paths['/internal/ExpectedDelivery/SetZipCodeByCoordinates']['post']['parameters']['query'];

        export type SetZipCodeByCoordinatesSuccess = paths['/internal/ExpectedDelivery/SetZipCodeByCoordinates']['post']['responses']['200']['content']['text/plain'];

        export const SetZipCodeByCoordinatesUrl = '/internal/ExpectedDelivery/SetZipCodeByCoordinates';

        
        export function SetZipCodeByCoordinates(params: SetZipCodeByCoordinatesParams): Promise<SetZipCodeByCoordinatesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SetZipCodeByCoordinatesSuccess>(`/internal/ExpectedDelivery/SetZipCodeByCoordinates${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliverySummaryForBasketParams = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket']['get']['parameters']['query'];

        export type GetExpectedDeliverySummaryForBasketSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliverySummaryForBasketUrl = '/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket';

        
        export function GetExpectedDeliverySummaryForBasket(params: GetExpectedDeliverySummaryForBasketParams): Promise<GetExpectedDeliverySummaryForBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliverySummaryForBasketSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliverySummaryForProductPageParams = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage']['get']['parameters']['query'];

        export type GetExpectedDeliverySummaryForProductPageSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliverySummaryForProductPageUrl = '/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage';

        
        export function GetExpectedDeliverySummaryForProductPage(params: GetExpectedDeliverySummaryForProductPageParams): Promise<GetExpectedDeliverySummaryForProductPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliverySummaryForProductPageSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliveryFullInformationParams = paths['/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation']['get']['parameters']['query'];

        export type GetExpectedDeliveryFullInformationSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryFullInformationUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation';

        
        export function GetExpectedDeliveryFullInformation(params: GetExpectedDeliveryFullInformationParams): Promise<GetExpectedDeliveryFullInformationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliveryFullInformationSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation${formatParams(params)}`)
        }
    
    
        

        export type GetExpectedDeliveryInformationForProductPageParams = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage']['get']['parameters']['query'];

        export type GetExpectedDeliveryInformationForProductPageSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryInformationForProductPageUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage';

        
        export function GetExpectedDeliveryInformationForProductPage(params: GetExpectedDeliveryInformationForProductPageParams): Promise<GetExpectedDeliveryInformationForProductPageSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliveryInformationForProductPageSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage${formatParams(params)}`)
        }
    
    
        

        

        export type GetExpectedDeliveryInformationForBasketSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryInformationForBasketUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket';

        
        export function GetExpectedDeliveryInformationForBasket(): Promise<GetExpectedDeliveryInformationForBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetExpectedDeliveryInformationForBasketSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket`)
        }
    
    

        export * from './discriminators';
    