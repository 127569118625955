import styled, { css } from 'styled-components';
import React from 'react';
import { ColorSpot1, FontWeightNormal, Spacing2 } from 'autogen/design-tokens/tokens';
import Price from './components/Price';
import PriceLabelRenderer from './components/PriceLabelRenderer';
import AdditionalDeliveryTooltip from './components/AdditionalDeliveryTooltip';
import { PriceDiscount } from './components/PriceDiscount';
import { IPriceModule } from './components/shared/types';

const PriceModuleContainer = styled.div`
    display: inline-grid;
    gap: ${Spacing2};
    text-align: left;
`;

const StyledPrice = styled(Price)<{ $onDiscount?: boolean }>`
    ${({ $onDiscount }) =>
        $onDiscount &&
        css`
            color: ${ColorSpot1};
        `}
`;

const StyledPriceLabelRenderer = styled(PriceLabelRenderer)`
    font-weight: ${FontWeightNormal};
`;

export default function PriceModule({
    price,
    priceLabel,
    priceBefore,
    priceSaving,
    pricePrefix,
    size = 'sm',
    onDiscount = false,
    showPossibleAdditionalDeliveryCost,
    ...restProps
}: IPriceModule) {
    /* Top Title */
    const getPriceToptitle = () => {
        const getSizeValue = () => {
            switch (size) {
                case 'xs':
                case 'sm':
                    return 10;
                case 'lg':
                    return 14;
                default:
                    throw new Error('size does not match IPriceModule["size"]');
            }
        };

        if (priceLabel) {
            return <StyledPriceLabelRenderer priceLabel={priceLabel} onDiscount={onDiscount} size={getSizeValue()} />;
        }

        return null;
    };

    /* Price */
    const getPrice = () => {
        const getSizeValue = () => {
            switch (size) {
                case 'xs':
                    return 12;
                case 'sm':
                    return 14;
                case 'lg':
                    return 24;
                default:
                    throw new Error('size does not match IPriceModule["size"]');
            }
        };

        if (showPossibleAdditionalDeliveryCost) {
            return (
                <span>
                    <StyledPrice
                        $onDiscount={onDiscount}
                        price={price}
                        pricePrefix={pricePrefix}
                        size={getSizeValue()}
                    />{' '}
                    <AdditionalDeliveryTooltip />
                </span>
            );
        }

        return <StyledPrice $onDiscount={onDiscount} price={price} pricePrefix={pricePrefix} size={getSizeValue()} />;
    };

    /* Sub Title */
    const getPriceSubtitle = () => {
        const getSizeValue = () => {
            switch (size) {
                case 'xs':
                case 'sm':
                    return 12;
                case 'lg':
                    return 14;
                default:
                    throw new Error('size does not match IPriceModule["size"]');
            }
        };

        if (onDiscount && priceBefore) {
            return <PriceDiscount price={priceBefore} priceSaving={priceSaving} size={getSizeValue()} />;
        }
        return null;
    };

    return (
        <PriceModuleContainer {...restProps}>
            {getPriceToptitle()}
            {getPrice()}
            {getPriceSubtitle()}
        </PriceModuleContainer>
    );
}
