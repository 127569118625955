import { Spacing16 } from 'autogen/design-tokens/tokens';
import colors from './colors';

export default {
    heightStickyHeader: '115px',
    heightModalHeader: '50px',
    pdpReadMoreMaxHeight: '156px',
    borderRadius1: '6px',
    gridGutter: Spacing16,
    gridGutterHalf: `${Number.parseInt(Spacing16) / 2}px`,
    shadow1: `0 1px 2px 0 ${colors.boxShadow}`,
    shadow2: `0 1px 15px 0 ${colors.boxShadow}`,
    shadow3: `0 1px 1px ${colors.shadow}`,
    shadow4: `0 1px 6px ${colors.shadow}`,
    fadeTransition: '1s cubic-bezier(0.19, 1, 0.22, 1) 0s'
};
