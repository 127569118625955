
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './quantity';

        export type QuantityModel = components['schemas']['QuantityModel'];
                    

        
        

        export type GetQuantityParams = paths['/internal/Quantity/GetQuantity']['get']['parameters']['query'];

        export type GetQuantitySuccess = paths['/internal/Quantity/GetQuantity']['get']['responses']['200']['content']['text/plain'];

        export const GetQuantityUrl = '/internal/Quantity/GetQuantity';

        
        export function GetQuantity(params: GetQuantityParams): Promise<GetQuantitySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetQuantitySuccess>(`/internal/Quantity/GetQuantity${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    