import {
    FontCaptionFamily,
    FontCaptionLineHeightLarge,
    FontCaptionLineHeightMedium,
    FontCaptionLineHeightSmall,
    FontCaptionWeightRegular,
    FontCaptionWeightSemiBold,
    FontLabelFamily,
    FontLabelLineHeight,
    FontLabelWeightRegular,
    FontLabelWeightSemiBold
} from 'autogen/design-tokens/tokens';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type sizes = 'sm' | 'md' | 'lg';
type weights = 'regular' | 'semibold';

interface IHeading {
    size?: sizes;
    weight?: weights;
    children: ReactNode;
}

const labelSizes = {
    sm: css`
        font-size: 14px;
        line-height: ${FontLabelLineHeight};
    `,
    lg: css`
        font-size: 16px;
        line-height: ${FontLabelLineHeight};
    `
};

const labelWeights = {
    regular: FontLabelWeightRegular,
    semibold: FontLabelWeightSemiBold
};

const StyledLabel = styled.span<{ size: sizes; weight: weights }>`
    ${({ size }) => labelSizes[size]};
    font-family: ${FontLabelFamily};
    font-weight: ${({ weight }) => labelWeights[weight]};
    width: 100%;
    text-align: left;
`;

export default function Label({ size = 'lg', weight = 'regular', children, ...restProps }: IHeading) {
    return (
        <StyledLabel size={size} weight={weight} {...restProps}>
            {children}
        </StyledLabel>
    );
}
