import React from 'react';
import ProductName from '../ProductName';
import type { IProductName } from '../shared/types';

export default function ProductNameWithoutLinks({ productName, size = 'md', ...restProps }: IProductName): JSX.Element {
    const productLinesWithoutUrls = productName.lines.map((line) => ({
        ...line,
        url: undefined
    }));
    productName.lines = productLinesWithoutUrls;

    return <ProductName productName={productName} size={size} {...restProps} />;
}
