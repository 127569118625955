/* eslint-disable import/prefer-default-export */
import { Spacing12, Spacing16, Spacing24, Spacing4, Spacing8 } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

export const SectionSizes = {
    sm: {
        section: css`
            padding: ${Spacing4} ${Spacing12};
        `,
        heading: css`
            padding: ${Spacing8} ${Spacing12} ${Spacing8} ${Spacing12};
        `
    },
    md: {
        section: css`
            padding: ${Spacing8} ${Spacing16};
        `,
        heading: css`
            padding: ${Spacing16} ${Spacing24} ${Spacing8} ${Spacing16};
        `
    },
    lg: {
        section: css`
            padding: ${Spacing8} ${Spacing16};
        `,
        heading: css`
            padding: ${Spacing16} ${Spacing24} ${Spacing8} ${Spacing16};
        `
    },
    xl: {
        section: css`
            padding: ${Spacing8} ${Spacing16};
        `,
        heading: css`
            padding: ${Spacing16} ${Spacing24} ${Spacing8} ${Spacing16};
        `
    },
    xxl: {
        section: css`
            padding: ${Spacing8} ${Spacing16};
        `,
        heading: css`
            padding: ${Spacing16} ${Spacing24} ${Spacing8} ${Spacing16};
        `
    }
};
