import { Spacing16, Spacing24 } from 'autogen/design-tokens/tokens';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { type BreakPointsType, breakpoint, sizes } from 'variables';
import { Typography } from 'components/generic/typography';
import usePageContext from 'hooks/user/usePageContext';
import getTypeStyling from 'helpers/getTypeStyling';
import containerFluid from '../../shared/styles';

const SectionComponent = styled.section<{ $removePadding: boolean }>`
    display: grid;
    min-width: 0;
    padding: ${({ $removePadding }) => ($removePadding ? 0 : `0 ${Spacing16}`)};
    row-gap: ${Spacing16};

    ${breakpoint.up(sizes.lg)} {
        row-gap: ${Spacing24};
    }

    ${containerFluid}
`;

export const DefaultSectionContent = styled.div<{ columns: Columns }>`
    display: grid;
    gap: ${Spacing16};
    min-width: 0;

    ${({ columns }) =>
        getTypeStyling(
            (resolvedColumns: number) =>
                css`
                    grid-template-columns: repeat(${resolvedColumns}, 1fr);
                `
        )(columns)}
`;

const StyledHeadline = styled(Typography.Headline)<{ isChanel?: boolean }>`
    ${({ isChanel }) =>
        isChanel &&
        css`
            text-align: center;
        `};
`;

type Columns = number | BreakPointsType<number>;
export interface ISection {
    heading?: string;
    indentMobileContent?: boolean;
    removePadding?: boolean;
    columns?: Columns;
    children: ReactNode;
    headingRenderer?: (heading: ISection['heading']) => JSX.Element | null;
    contentRenderer?: (children: ISection['children'], columns: Columns) => JSX.Element;
    id?: string;
}

export function DefaultSectionHeading({ heading }) {
    const pageContext = usePageContext();
    const isChanel = pageContext?.brandContext === 'chanel';

    return (
        <StyledHeadline forwardedAs="h2" size={{ sm: 24, lg: 32 }} isChanel={isChanel}>
            {heading}
        </StyledHeadline>
    );
}

export default function Section({
    heading,
    removePadding = false,
    columns = 1,
    children,
    headingRenderer = (sectionHeading) => (sectionHeading ? <DefaultSectionHeading heading={sectionHeading} /> : null),
    contentRenderer = (sectionChildren, sectionColumns) => (
        <DefaultSectionContent columns={sectionColumns}>{sectionChildren}</DefaultSectionContent>
    ),
    id,
    ...restProps
}: ISection) {
    return (
        <SectionComponent $removePadding={removePadding} {...restProps} id={id}>
            {headingRenderer(heading)}
            {contentRenderer(children, columns)}
        </SectionComponent>
    );
}
