import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

const ContainerElement = styled.div`
    display: flex;
`;

const Container = (props: HTMLAttributes<HTMLDivElement>): JSX.Element => <ContainerElement {...props} />;

export default Container;
