import React from 'react';
import styled from 'styled-components';
import { BorderRadiusMedium, BoxShadowLarge, ColorNeutralWhite } from 'autogen/design-tokens/tokens';
import useDropdown from '../hooks/useDropdown';
import { IDropdownContent } from '../interface/dropdownContent';

const Content = styled.div<{
    placement: string;
    contentWidth?: string;
    contentHeight?: string;
    contentMaxHeight?: string;
    contentMinWidth?: number;
    noTopBorderRadius?: boolean;
}>`
    overflow: auto;

    ${({ contentWidth }) => `width: ${contentWidth};`}
    ${({ contentHeight }) => `height: ${contentHeight};`}
    ${({ contentMaxHeight }) => `max-height: ${contentMaxHeight};`}
    ${({ contentMinWidth }) => `min-width: ${contentMinWidth}px;`}
    background-color: ${ColorNeutralWhite};
    border-radius: ${({ placement, noTopBorderRadius }): string => {
        if (noTopBorderRadius) return `0 0 ${BorderRadiusMedium} ${BorderRadiusMedium}`;
        if (placement === 'bottom-start') return `0 ${BorderRadiusMedium} ${BorderRadiusMedium} ${BorderRadiusMedium}`;
        if (placement === 'bottom-end') return `${BorderRadiusMedium} 0px ${BorderRadiusMedium} ${BorderRadiusMedium}`;
        if (placement === 'top-start') return `${BorderRadiusMedium} ${BorderRadiusMedium} ${BorderRadiusMedium} 0px`;
        if (placement === 'top-end') return `${BorderRadiusMedium} ${BorderRadiusMedium} 0px ${BorderRadiusMedium}`;
        return BorderRadiusMedium;
    }};
    box-shadow: ${({ placement }): string => {
        if (placement === 'bottom-start' || placement === 'bottom-end') return BoxShadowLarge;
        if (placement === 'top-start' || placement === 'top-end') return '0px -6px 6px rgb(0 0 0 / 8%)';
        return BoxShadowLarge;
    }};
`;

export default function DropdownContent({
    children,
    width = '100%',
    height = '100%',
    maxHeight,
    noTopBorderRadius,
    className
}: IDropdownContent) {
    const { placement, minWidth } = useDropdown();

    return (
        <Content
            contentWidth={width}
            contentHeight={height}
            contentMaxHeight={maxHeight}
            contentMinWidth={minWidth}
            placement={placement}
            noTopBorderRadius={noTopBorderRadius}
            className={className}
        >
            {children}
        </Content>
    );
}
