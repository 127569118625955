import React from 'react';
import htmlModal from 'modules/modal/htmlModal';
import DefaultLayout from 'modules/modal/layouts';
import { GET } from 'modules/helpers/fetch';
import mediator from './mediator';

function rebindBasket() {
    (async () => {
        const { updateBasket } = await import(/* webpackChunkName: "basket" */ '../views/basket/index');
        updateBasket();
    })();

    mediator.publish('updateMiniBasketView');
    mediator.publish('updateQuantities', 'basket');
}

export function addSingleGWP(target) {
    const url = $(target).attr('data-js-gwp-add');
    GET(url).then(() => {
        rebindBasket();
    });
}

export function initializeSelectGWPModal(url) {
    const giftBtnAttr = 'data-js-select-gift';

    const modal = htmlModal({
        layout: {
            component: <DefaultLayout customTheme={{ width: '600px' }} />
        }
    });

    modal({
        url,
        onAfterContent: ({ wrapper, close }) => {
            $(wrapper)
                .find(`[${giftBtnAttr}]`)
                .on('click', (e) => {
                    const selectGiftUrl = $(e.target).attr(giftBtnAttr);
                    GET(selectGiftUrl).then(() => {
                        close();
                        rebindBasket();
                    });
                });
        }
    });
}

export default class ChooseGift {
    constructor() {
        this.list = $('[data-js="gwp-list"]');
        this.giftBtnAttr = 'data-js-select-gift';
        this.doc = $(document);
        this.modal = null;
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.doc.off('click', '[data-js-gwp-select]');
        this.doc.off('click', '[data-js-gwp-add]');

        this.doc.on('click', '[data-js-gwp-select]', (e) => this.getGWPModal(e.currentTarget));
        this.doc.on('click', '[data-js-gwp-add]', (e) => addSingleGWP(e.currentTarget));
    }

    getGWPModal(target) {
        const url = $(target).attr('data-js-gwp-select');
        const modal = htmlModal({
            layout: {
                component: <DefaultLayout customTheme={{ width: '600px' }} />
            }
        });

        modal({
            url,
            onAfterContent: ({ wrapper, close }) => {
                $(wrapper)
                    .find(`[${this.giftBtnAttr}]`)
                    .on('click', (e) => {
                        this.selectGift(e.target, close);
                    });
            }
        });
    }

    selectGift(target, modalClose) {
        const url = $(target).attr(this.giftBtnAttr);
        $.ajax({
            url,
            type: 'GET',
            cache: false
        }).done((response) => {
            if (response.success) {
                modalClose();
                rebindBasket();
            }
        });
    }
}
