import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { SvgSprite } from 'modules/svgSprite';
import Translations from 'modules/translations';
import { FontSize16 } from 'autogen/design-tokens/tokens';

const CloseButton = styled.button`
    background: none;
    border: 0;
    color: currentColor;
`;

const CloseButtonInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
`;

const CloseButtonText = styled.span`
    color: ${(props): string => props.theme.color || 'inherit'};
    font-weight: bold;
    font-size: ${FontSize16};
`;

const CloseButtonIconWrapper = styled.span`
    width: 14px;
    height: 14px;
    margin: 0 8px;
`;

const CloseButtonIcon = styled(SvgSprite)`
    fill: ${(props): string => props.theme.fill || 'inherit'};
`;

interface IProps extends HTMLAttributes<HTMLButtonElement> {
    alternativeCloseText?: string;
}

export default (props: IProps): JSX.Element => {
    const { onClick, className, alternativeCloseText } = props;
    const closeText = alternativeCloseText || Translations.Website.Shared.Close;

    return (
        <CloseButton onClick={onClick} className={className}>
            <CloseButtonInner>
                <CloseButtonText>{closeText}</CloseButtonText>
                <CloseButtonIconWrapper>
                    <CloseButtonIcon sprite="Close" />
                </CloseButtonIconWrapper>
            </CloseButtonInner>
        </CloseButton>
    );
};
