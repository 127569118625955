import useMediatorState from 'hooks/globals/useMediatorAtom';
import { FavoriteQuantityMediatorAtom } from 'modules/getQuantities';
import mediator from 'modules/mediator';

export const refreshFavoriteQuantityGlobally = () => mediator.publish('updateQuantities', 'favorites');

export default function useFavoriteQuantity() {
    const [favoriteQuantity, setFavoriteQuantity] = useMediatorState(FavoriteQuantityMediatorAtom);

    return [favoriteQuantity, setFavoriteQuantity, refreshFavoriteQuantityGlobally];
}
