/* eslint-disable import/prefer-default-export */

/**
 * @interface ITimeSpan
 * @prop {string} timeSpan Format = HH:MM:SS.ss
 */

interface ITimeSpan {
    timeSpan: string;
}

export function timeSpanToSeconds({ timeSpan }: ITimeSpan): number {
    const [hours, minutes, seconds] = timeSpan.split(':').map((item) => Number(item));
    return hours * (60 * 2) + minutes * 60 + seconds;
}

export function timeSpanToMinutes({ timeSpan }: ITimeSpan): number {
    const [hours, minutes, seconds] = timeSpan.split(':').map((item) => Number(item));
    return hours * 60 + minutes + seconds / 60;
}

export function timeSpanToHours({ timeSpan }: ITimeSpan): number {
    const [hours, minutes, seconds] = timeSpan.split(':').map((item) => Number(item));
    return hours + minutes / 60 + seconds / (60 * 2);
}

function checkOverflow(number: number, overflowNumber = 60): { overflow: number; remaining: number } {
    const overflow = Math.floor(number / overflowNumber);
    const remaining = number % overflowNumber;

    return {
        overflow,
        remaining
    };
}

type IUnitDisplay = 'long' | 'short' | 'narrow';

interface IConvertTimeSpanToString extends ITimeSpan {
    unitDisplay?: IUnitDisplay;
}

export function convertTimeSpanToString({ timeSpan, unitDisplay = 'short' }: IConvertTimeSpanToString): string {
    const [hour, minute, second] = timeSpan.split(':').map((item) => Number(item));
    const timeObject = { day: 0, hour, minute, second };
    let timeStringResult = '';

    Object.entries(timeObject)
        .reverse()
        .forEach((time, index) => {
            const [key] = time;
            const value = timeObject[key];
            const overflowNumber = key === 'hour' ? 24 : 60;

            if (value > overflowNumber && key !== 'day') {
                const { overflow, remaining } = checkOverflow(value, overflowNumber);

                timeObject[key] = remaining;
                timeObject[Object.keys(timeObject).reverse()[index + 1]] += overflow;
            }
            if (!value) return;

            const unitTime = new Intl.NumberFormat('da', {
                style: 'unit',
                unit: key,
                unitDisplay
            }).format(timeObject[key]);

            timeStringResult = `${unitTime} ${timeStringResult}`;
        });

    return timeStringResult;
}

/**
 * @interface IConvertTime
 * @prop {string} time Format example = 2021-09-24T12:20:00+02:00
 */

interface IConvertTime {
    dateTime: string;
    locales?: string | string[];
    options?: Intl.DateTimeFormatOptions;
}

export function dateTimeFormat({
    dateTime,
    locales = 'da-DK',
    options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
}: IConvertTime): string {
    const date = new Date(dateTime);

    return new Intl.DateTimeFormat(locales, options).format(date);
}
