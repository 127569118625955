import { FontFontFamilySecondary, FontLineHeightTight } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

const HeadlineStyling = css`
    font-family: ${FontFontFamilySecondary};
    line-height: ${FontLineHeightTight};
    margin-top: 0;
    margin-bottom: 0;
`;

export default HeadlineStyling;
