import { atom, MutableSnapshot } from 'recoil';

export type IadditionalInfoIdAtom = number;

const campaignInfoAdditionalInfoIdAtom = atom({
    key: 'campaignInfoAdditionalInfoIdAtom',
    default: 0
});

export default campaignInfoAdditionalInfoIdAtom;
