import { trackEvent } from 'modules/tracking/dataLayer';
import { firstCharacterToLower } from 'modules/helpers/strings';
import type { TrackingCategory } from './types/trackingCategory';
import type { TrackingAction } from './types/trackingAction';

export type { TrackingCategory } from './types/trackingCategory';
export type { TrackingAction, DepricatedStringConcatenation } from './types/trackingAction';

export interface ITrackUAEvent {
    eventCategory: TrackingCategory;
    eventAction: TrackingAction;
    eventLabel?: string | number | null | (string | number)[];
}

export default function UAEventTracking({ eventCategory, eventAction, eventLabel }: ITrackUAEvent): void {
    if (!window.dataLayer) return;

    const eventCategoryToLower = firstCharacterToLower(eventCategory);
    const eventActionToLower = firstCharacterToLower(eventAction);

    const eventObject: ITrackUAEvent & { event: string } = {
        event: 'uaevent',
        eventCategory: eventCategoryToLower,
        eventAction: eventActionToLower
    };

    if (Array.isArray(eventLabel)) {
        trackEvent(...eventLabel.map((label) => ({ ...eventObject, eventLabel: label })));
    } else {
        trackEvent({ ...eventObject, ...(eventLabel && { eventLabel }) });
    }
}

export function TrackUAEvent(
    eventCategory: ITrackUAEvent['eventCategory'],
    eventAction: ITrackUAEvent['eventAction'],
    eventLabel: ITrackUAEvent['eventLabel'] = null
): void {
    UAEventTracking({ eventCategory, eventAction, ...(eventLabel && { eventLabel }) });
}
