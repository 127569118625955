import format from 'helpers/translations';

export type TextContentProps = {
    model: any;
};

export default function TextContent(props: TextContentProps) {
    const { model } = props;

    const bgColorClass = model.backgroundColor.predefinedColorKey
        ? `color-${model.backgroundColor.predefinedColorKey}-bg`
        : '';

    const cssClasses = `${model.margin} ${bgColorClass}`;

    return <div className={cssClasses}>{format(model.text)}</div>;
}
