/* eslint-disable */
/// This file is auto generated - do not edit manually
export const ArrowLeft = 'Website.ImageAltText.Icons.ArrowLeft';
export const ArrowRight = 'Website.ImageAltText.Icons.ArrowRight';
export const ArrowUp = 'Website.ImageAltText.Icons.ArrowUp';
export const ArrowDown = 'Website.ImageAltText.Icons.ArrowDown';
export const Checkmark = 'Website.ImageAltText.Icons.Checkmark';
export const Search = 'Website.ImageAltText.Icons.Search';
export const Close = 'Website.ImageAltText.Icons.Close';
export const Camera = 'Website.ImageAltText.Icons.Camera';
export const Info = 'Website.ImageAltText.Icons.Info';
