import styled from 'styled-components';
import { zIndex } from 'variables';
import { customScrollbar } from 'modules/helpers/mixins';
import { useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import type { QuickViewContentProps } from '../../shared/types';
import { quickViewHeaderBackgroundState } from '../../atoms/quickViewState';
import { QuickViewContent } from '../DefaultQuickViewComponents';

const StyledDefaultQuickContentContainer = styled(QuickViewContent)`
    overflow: auto;
    ${customScrollbar};
    z-index: calc(${zIndex.modal} + 1);
`;

const Scroller = styled.div`
    overflow: auto;
    ${customScrollbar};
    width: 100%;
`;

export default function AbsoluteQuickViewContentContainer({ children, ...restProps }: QuickViewContentProps) {
    const setShowHeaderBackground = useSetRecoilState(quickViewHeaderBackgroundState);
    const slideRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (!slideRef.current) {
            return;
        }

        if (slideRef.current.getBoundingClientRect().top < 0) {
            setShowHeaderBackground(true);
        } else {
            setShowHeaderBackground(false);
        }
    };

    return (
        <StyledDefaultQuickContentContainer {...restProps}>
            <Scroller onScroll={handleScroll}>
                <div ref={slideRef}>{children}</div>
            </Scroller>
        </StyledDefaultQuickContentContainer>
    );
}
