export const SHOW_NOTIFICATION_FORM_MESSAGE = 'SHOW_NOTIFICATION_FORM_MESSAGE';
export const SET_NOTICIATION_FORM_MESSAGE = 'SET_NOTICIATION_FORM_MESSAGE';

export function setShowNotificationFormMessage(bool) {
    return {
        type: SHOW_NOTIFICATION_FORM_MESSAGE,
        bool
    };
}

export function setNotificationFormMessage(message) {
    return {
        type: SET_NOTICIATION_FORM_MESSAGE,
        message
    };
}
