import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import QuickSearch from './QuickSearch';

export default (props) => (
    <Provider store={store}>
        <QuickSearch {...props} />
    </Provider>
);
