import type { ResponsiveImage, ResponsiveImageModel } from 'autogen/swagger/page';

export type ImageTransformType = 'WidthScale' | 'SquareScale' | 'ThumbnailScale';

export interface IProductImage {
    url: string;
}

type Size = number | Record<number, number>;
type Sizes = number | (Record<number, number> | number)[];
type ImageType = 'default' | 'product';

export type GetScalingAttrProps = {
    src: string;
    sizes: Sizes;
    transformType: ImageTransformType;
    imageType?: ImageType;
};

const subdirectory: Record<ImageType, string> = {
    default: '/trsaf',
    product: '/trs'
};

const transformTypeSubdirectory: Record<ImageTransformType, string> = {
    SquareScale: '/s',
    WidthScale: '/w',
    ThumbnailScale: '/t'
};

function createCloudflareScalingPath(
    width: number,
    transformType: ImageTransformType,
    imageType: ImageType,
    density: number
): string {
    const getSubdirectory = subdirectory[imageType];
    const getTransformTypeSubdirectory = transformTypeSubdirectory[transformType];
    const url = `${getSubdirectory}${getTransformTypeSubdirectory}${width * density}/`;

    return url;
}

const transformDomain = 'https://images.matas.dk';
const transformDomainLength: number = transformDomain.length;
const supportedDPR = 2;

export function getCloudFlareScaledUrl(
    src: string,
    width: number,
    transformType: ImageTransformType,
    imageType: ImageType,
    density: number
): string {
    if (src.includes('.hdk')) return src;

    const scalingPath = createCloudflareScalingPath(width, transformType, imageType, density);

    if (src.startsWith(transformDomain)) {
        return `${transformDomain}${scalingPath}${src.substring(transformDomainLength)}`;
    }

    return `${transformDomain}${scalingPath}${src}`;
}

function sizesToImgSizesAttribute(sizes: Sizes): string {
    if (typeof sizes === 'number') {
        return `${sizes}px`;
    }

    if (Array.isArray(sizes)) {
        return sizes
            .map((item) => {
                if (typeof item === 'number') {
                    return `${item}px`;
                }

                return `(min-width: ${item[0]}px) ${item[1]}px`;
            })
            .join(', ');
    }
    return '';
}

function findClosestValidSize(targetSize: number, imageType: NonNullable<GetScalingAttrProps['imageType']>) {
    const validSizes =
        imageType === 'default'
            ? [275, 550, 825, 1100, 1375, 1650, 1925, 2200, 2475, 2750, 3025, 3300] // should always be equal to validSizes in cloudflare/imagescalingadform.js
            : [50, 65, 136, 185, 365, 445, /* dd365 */ 730, /* dd445 */ 890, /* dd890 */ 1780, 1980]; // should always be equal to validSizes in cloudflare/imagescaling.js

    // Ensure the array is sorted in ascending order
    const sortedSizes = validSizes.slice().sort((a, b) => a - b);

    // Get the first size that is greater than or equal to the target size
    const closestSize = sortedSizes.find((size) => size >= targetSize);

    // If all sizes are smaller than the target, return the largest available size
    return closestSize || sortedSizes[sortedSizes.length - 1];
}

function getSizeValue(size: Size): number {
    const sizeValue = Array.isArray(size) ? size[size.length - 1] : size;

    return sizeValue;
}

function getBiggestSize(sizes: Sizes): number {
    if (Array.isArray(sizes)) {
        return Math.max(...sizes.map((subArray) => subArray[(subArray as Array<number>).length - 1]));
    }

    return sizes;
}

function getSrc({ src, transformType, sizes, imageType = 'default' }: GetScalingAttrProps): string {
    const sizeValue = getBiggestSize(sizes);

    return getCloudFlareScaledUrl(src, sizeValue, transformType, imageType, 1);
}

export function getSrcSet(
    src: GetScalingAttrProps['src'],
    size: Size, // eg. 65 || [65] || [375, 65]
    transformType: GetScalingAttrProps['transformType'],
    imageType: NonNullable<GetScalingAttrProps['imageType']>
): string {
    const srcSetArray: string[] = [];
    const sizeValue = getSizeValue(size);

    for (let index = 1; index <= supportedDPR; index++) {
        const url = getCloudFlareScaledUrl(src, sizeValue, transformType, imageType, index);
        const width = findClosestValidSize(sizeValue * index, imageType);

        srcSetArray.push(`${url} ${width}w`);
    }

    return srcSetArray.join(', ');
}

interface IImageResizeAttributes {
    srcSet: string;
    src: string;
    sizes: string;
}

export default function GetScalingAttr({
    src,
    transformType,
    sizes,
    imageType = 'default'
}: GetScalingAttrProps): IImageResizeAttributes {
    const srcSet: string = Array.isArray(sizes)
        ? sizes.map((size) => getSrcSet(src, size, transformType, imageType)).join(', ')
        : getSrcSet(src, sizes, transformType, imageType);

    return {
        srcSet,
        src: getSrc({ src, transformType, sizes, imageType }),
        sizes: sizesToImgSizesAttribute(sizes)
    };
}

// Temporary fix to get ResponsiveImageModel largest image for  for img srcSet.
export function getLargestPictureSrc(data: ResponsiveImageModel): string {
    const largestImage = data.sources.reduce(
        (maxImageSoFar, source) => {
            const maxInSource = source.images.reduce(
                (maxInThisSource, image) =>
                    !maxInThisSource || (image.width && (!maxInThisSource.width || image.width > maxInThisSource.width))
                        ? image
                        : maxInThisSource,
                maxImageSoFar
            );
            return maxInSource;
        },
        null as ResponsiveImage | null
    );

    return largestImage ? largestImage.url : data.fallBackUrl || '';
}
