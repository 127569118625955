import { rgba } from 'polished';
import { css } from 'styled-components';

export const overlayColor = rgba(21, 21, 21, 0.04);

const imageOverlayStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${overlayColor};
    pointer-events: none;
`;

export default imageOverlayStyle;
