import type { JavascriptWebsiteTime } from 'autogen/swagger/all';

const websiteTimeObj: JavascriptWebsiteTime = { load: 0 };

export const setWebsiteTime = (obj: JavascriptWebsiteTime | string) => {
    if (typeof obj === 'string') {
        websiteTimeObj.load = Number.parseInt((new Date(obj).getTime() / 1000).toFixed(0));
    } else {
        Object.assign(websiteTimeObj, obj);
    }
};

const WebsiteTime = () => websiteTimeObj;

export default WebsiteTime();
