import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { constants } from 'variables';

export const Header = styled.header`
    flex: none;
    display: flex;
    justify-content: flex-end;
    height: ${constants.heightModalHeader};
`;

export default ({ className, children }: HTMLAttributes<HTMLElement>) => (
    <Header className={className}>{children}</Header>
);
