
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './myprofilesubscription';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type SubscriptionOverviewUpsaleBannerViewModel = components['schemas']['SubscriptionOverviewUpsaleBannerViewModel'];
                    export type SubscriptionOverviewUpsaleBannerViewModelMaybe = components['schemas']['SubscriptionOverviewUpsaleBannerViewModelMaybe'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ProductDetailedViewModel = components['schemas']['ProductDetailedViewModel'];
                    export type SubscriptionWeekInfo = components['schemas']['SubscriptionWeekInfo'];
                    export type PendingSubscriptionOverviewViewModel = components['schemas']['PendingSubscriptionOverviewViewModel'];
                    export type SubscriptionInfoLinkActions = components['schemas']['SubscriptionInfoLinkActions'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type PaymentMethodError = components['schemas']['PaymentMethodError'];
                    export type SubscriptionAdhocOrderViewModel = components['schemas']['SubscriptionAdhocOrderViewModel'];
                    export type SubscriptionAdhocOrderViewModelMaybe = components['schemas']['SubscriptionAdhocOrderViewModelMaybe'];
                    export type SubscriptionViewModel = components['schemas']['SubscriptionViewModel'];
                    export type SubscriptionInfoViewModel = components['schemas']['SubscriptionInfoViewModel'];
                    export type SubscriptionPriceSummaryViewModel = components['schemas']['SubscriptionPriceSummaryViewModel'];
                    export type SubscriptionMyProfileViewModel = components['schemas']['SubscriptionMyProfileViewModel'];
                    export type SubscriptionListViewModel = components['schemas']['SubscriptionListViewModel'];
                    export type SubscriptionOverviewViewModel = components['schemas']['SubscriptionOverviewViewModel'];
                    export type SubscriptionOverviewViewModelMaybe = components['schemas']['SubscriptionOverviewViewModelMaybe'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type OverviewViewModel = components['schemas']['OverviewViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type ValidatedOperationResult = components['schemas']['ValidatedOperationResult'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type JsonLocation = components['schemas']['JsonLocation'];
                    export type ParcelShopSearchOrigin = components['schemas']['ParcelShopSearchOrigin'];
                    export type AddressCleansingSuggestion = components['schemas']['AddressCleansingSuggestion'];
                    export type AddressCleansingSuggestionMaybe = components['schemas']['AddressCleansingSuggestionMaybe'];
                    export type ParcelShopSearchData = components['schemas']['ParcelShopSearchData'];
                    export type DeliveryPrice = components['schemas']['DeliveryPrice'];
                    export type ParcelShopPrice = components['schemas']['ParcelShopPrice'];
                    export type SubscriptionDeliveryPrice = components['schemas']['SubscriptionDeliveryPrice'];
                    export type OpeningHourTexts = components['schemas']['OpeningHourTexts'];
                    export type ParcelShopRenderType = components['schemas']['ParcelShopRenderType'];
                    export type DeliveryParcelShopOption = components['schemas']['DeliveryParcelShopOption'];
                    export type ParcelShopSearchResponseModel = components['schemas']['ParcelShopSearchResponseModel'];
                    export type CheckoutStateField = components['schemas']['CheckoutStateField'];
                    export type DropDownListItemViewModel = components['schemas']['DropDownListItemViewModel'];
                    export type DropDownListViewModel = components['schemas']['DropDownListViewModel'];
                    export type ChoosableSubscriptionFrequencyGroupViewModel = components['schemas']['ChoosableSubscriptionFrequencyGroupViewModel'];
                    export type UserMessageType = components['schemas']['UserMessageType'];
                    export type UserMessageViewModel = components['schemas']['UserMessageViewModel'];
                    export type SubscriptionPackageViewModel = components['schemas']['SubscriptionPackageViewModel'];
                    export type SubscriptionPackageListViewModel = components['schemas']['SubscriptionPackageListViewModel'];
                    export type SubscriptionDetailsViewModel = components['schemas']['SubscriptionDetailsViewModel'];
                    export type SubscriptionAdhocOrderAction = components['schemas']['SubscriptionAdhocOrderAction'];
                    export type SubscriptionAdhocOrderActionModel = components['schemas']['SubscriptionAdhocOrderActionModel'];
                    export type SubscriptionAdhocOrderUseCaseModel = components['schemas']['SubscriptionAdhocOrderUseCaseModel'];
                    export type SubscriptionAdhocOrderUseCaseModelMaybe = components['schemas']['SubscriptionAdhocOrderUseCaseModelMaybe'];
                    export type AdhocOrderViewModel = components['schemas']['AdhocOrderViewModel'];
                    export type ConfirmUpdateViewModel = components['schemas']['ConfirmUpdateViewModel'];
                    export type CancelViewModel = components['schemas']['CancelViewModel'];
                    export type SubscriptionUpdateResult = components['schemas']['SubscriptionUpdateResult'];
                    export type SubscriptionPriceViewModel = components['schemas']['SubscriptionPriceViewModel'];
                    

        
        

        

        export type OverviewModelSuccess = paths['/internal/myprofile/Subscription/OverviewModel']['get']['responses']['200']['content']['text/plain'];

        export const OverviewModelUrl = '/internal/myprofile/Subscription/OverviewModel';

        
        export function OverviewModel(): Promise<OverviewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<OverviewModelSuccess>(`/internal/myprofile/Subscription/OverviewModel`)
        }
    
    
        

        

        export type GetAllSuccess = paths['/internal/myprofile/Subscription/GetAll']['get']['responses']['200']['content']['text/plain'];

        export const GetAllUrl = '/internal/myprofile/Subscription/GetAll';

        
        export function GetAll(): Promise<GetAllSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetAllSuccess>(`/internal/myprofile/Subscription/GetAll`)
        }
    
    
        

        

        

        export const SubscriptionDeliveryDeliveryGroupModelUrl = '/internal/myprofile/SubscriptionDelivery/DeliveryGroupModel';

        
        export function SubscriptionDeliveryDeliveryGroupModel(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/DeliveryGroupModel`)
        }
    
    
        

        

        

        export const SubscriptionDeliveryGetPrivateAddressModelUrl = '/internal/myprofile/SubscriptionDelivery/GetPrivateAddressModel';

        
        export function SubscriptionDeliveryGetPrivateAddressModel(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/GetPrivateAddressModel`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress';

        
        export function SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddress(params: SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressParams): Promise<SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdatePrivateDeliveryAddressSuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdatePrivateDeliveryAddress${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress';

        
        export function SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddress(params: SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressParams): Promise<SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdateCompanyDeliveryAddressSuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateCompanyDeliveryAddress${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdateParcelShopDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdateParcelShopDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery';

        
        export function SubscriptionDeliverySelectAndUpdateParcelShopDelivery(params: SubscriptionDeliverySelectAndUpdateParcelShopDeliveryParams): Promise<SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdateParcelShopDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateParcelShopDelivery${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectMatasDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectMatasDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectMatasDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery';

        
        export function SubscriptionDeliverySelectMatasDelivery(params: SubscriptionDeliverySelectMatasDeliveryParams): Promise<SubscriptionDeliverySelectMatasDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectMatasDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectMatasDelivery${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliverySelectAndUpdateMatasDeliveryParams = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery']['post']['parameters']['query'];

        export type SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess = paths['/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliverySelectAndUpdateMatasDeliveryUrl = '/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery';

        
        export function SubscriptionDeliverySelectAndUpdateMatasDelivery(params: SubscriptionDeliverySelectAndUpdateMatasDeliveryParams): Promise<SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliverySelectAndUpdateMatasDeliverySuccess>(`/internal/myprofile/SubscriptionDelivery/SelectAndUpdateMatasDelivery${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliveryListMatasParcelShopsParams = paths['/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops']['get']['parameters']['query'];

        export type SubscriptionDeliveryListMatasParcelShopsSuccess = paths['/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliveryListMatasParcelShopsUrl = '/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops';

        
        export function SubscriptionDeliveryListMatasParcelShops(params: SubscriptionDeliveryListMatasParcelShopsParams): Promise<SubscriptionDeliveryListMatasParcelShopsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SubscriptionDeliveryListMatasParcelShopsSuccess>(`/internal/myprofile/SubscriptionDelivery/ListMatasParcelShops${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionDeliveryListParcelShopsParams = paths['/internal/myprofile/SubscriptionDelivery/ListParcelShops']['get']['parameters']['query'];

        export type SubscriptionDeliveryListParcelShopsSuccess = paths['/internal/myprofile/SubscriptionDelivery/ListParcelShops']['get']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliveryListParcelShopsUrl = '/internal/myprofile/SubscriptionDelivery/ListParcelShops';

        
        export function SubscriptionDeliveryListParcelShops(params: SubscriptionDeliveryListParcelShopsParams): Promise<SubscriptionDeliveryListParcelShopsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SubscriptionDeliveryListParcelShopsSuccess>(`/internal/myprofile/SubscriptionDelivery/ListParcelShops${formatParams(params)}`)
        }
    
    
        

        

        

        export const SubscriptionDeliveryCompanyDeliveryAddressUrl = '/internal/myprofile/SubscriptionDelivery/CompanyDeliveryAddress';

        
        export function SubscriptionDeliveryCompanyDeliveryAddress(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionDelivery/CompanyDeliveryAddress`)
        }
    
    
        

        export type SubscriptionDeliveryValidateFieldForShippingSwaggerParams = paths['/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger']['post']['parameters']['query'];

        export type SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess = paths['/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger']['post']['responses']['200']['content']['text/plain'];

        export const SubscriptionDeliveryValidateFieldForShippingSwaggerUrl = '/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger';

        
        export function SubscriptionDeliveryValidateFieldForShippingSwagger(params: SubscriptionDeliveryValidateFieldForShippingSwaggerParams): Promise<SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SubscriptionDeliveryValidateFieldForShippingSwaggerSuccess>(`/internal/myprofile/SubscriptionDelivery/ValidateFieldForShippingSwagger${formatParams(params)}`)
        }
    
    
        

        export type GetDetailsParams = paths['/internal/myprofile/SubscriptionDetails/GetDetails']['get']['parameters']['query'];

        export type GetDetailsSuccess = paths['/internal/myprofile/SubscriptionDetails/GetDetails']['get']['responses']['200']['content']['text/plain'];

        export const GetDetailsUrl = '/internal/myprofile/SubscriptionDetails/GetDetails';

        
        export function GetDetails(params: GetDetailsParams): Promise<GetDetailsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetDetailsSuccess>(`/internal/myprofile/SubscriptionDetails/GetDetails${formatParams(params)}`)
        }
    
    
        

        export type GetMoreParams = paths['/internal/myprofile/SubscriptionDetails/GetMore']['get']['parameters']['query'];

        export type GetMoreSuccess = paths['/internal/myprofile/SubscriptionDetails/GetMore']['get']['responses']['200']['content']['text/plain'];

        export const GetMoreUrl = '/internal/myprofile/SubscriptionDetails/GetMore';

        
        export function GetMore(params: GetMoreParams): Promise<GetMoreSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetMoreSuccess>(`/internal/myprofile/SubscriptionDetails/GetMore${formatParams(params)}`)
        }
    
    
        

        export type GetAdhocOrderParams = paths['/internal/myprofile/SubscriptionDetails/GetAdhocOrder']['get']['parameters']['query'];

        export type GetAdhocOrderSuccess = paths['/internal/myprofile/SubscriptionDetails/GetAdhocOrder']['get']['responses']['200']['content']['text/plain'];

        export const GetAdhocOrderUrl = '/internal/myprofile/SubscriptionDetails/GetAdhocOrder';

        
        export function GetAdhocOrder(params: GetAdhocOrderParams): Promise<GetAdhocOrderSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetAdhocOrderSuccess>(`/internal/myprofile/SubscriptionDetails/GetAdhocOrder${formatParams(params)}`)
        }
    
    
        

        export type UpdateAdhocOrderParams = paths['/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder']['post']['parameters']['query'];

        export type UpdateAdhocOrderSuccess = paths['/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder']['post']['responses']['200']['content']['text/plain'];

        export const UpdateAdhocOrderUrl = '/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder';

        
        export function UpdateAdhocOrder(params: UpdateAdhocOrderParams): Promise<UpdateAdhocOrderSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateAdhocOrderSuccess>(`/internal/myprofile/SubscriptionDetails/UpdateAdhocOrder${formatParams(params)}`)
        }
    
    
        

        export type GetConfirmUpdateInfoParams = paths['/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo']['get']['parameters']['query'];

        export type GetConfirmUpdateInfoSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetConfirmUpdateInfoUrl = '/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo';

        
        export function GetConfirmUpdateInfo(params: GetConfirmUpdateInfoParams): Promise<GetConfirmUpdateInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetConfirmUpdateInfoSuccess>(`/internal/myprofile/SubscriptionUpdate/GetConfirmUpdateInfo${formatParams(params)}`)
        }
    
    
        

        export type GetCancelParams = paths['/internal/myprofile/SubscriptionUpdate/GetCancel']['get']['parameters']['query'];

        export type GetCancelSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetCancel']['get']['responses']['200']['content']['text/plain'];

        export const GetCancelUrl = '/internal/myprofile/SubscriptionUpdate/GetCancel';

        
        export function GetCancel(params: GetCancelParams): Promise<GetCancelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetCancelSuccess>(`/internal/myprofile/SubscriptionUpdate/GetCancel${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionUpdateConfirmCancelParams = paths['/internal/myprofile/SubscriptionUpdate/ConfirmCancel']['get']['parameters']['query'];

        

        export const SubscriptionUpdateConfirmCancelUrl = '/internal/myprofile/SubscriptionUpdate/ConfirmCancel';

        
        export function SubscriptionUpdateConfirmCancel(params: SubscriptionUpdateConfirmCancelParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/myprofile/SubscriptionUpdate/ConfirmCancel${formatParams(params)}`)
        }
    
    
        

        export type OnHoldParams = paths['/internal/myprofile/SubscriptionUpdate/OnHold']['get']['parameters']['query'];

        export type OnHoldSuccess = paths['/internal/myprofile/SubscriptionUpdate/OnHold']['get']['responses']['200']['content']['text/plain'];

        export const OnHoldUrl = '/internal/myprofile/SubscriptionUpdate/OnHold';

        
        export function OnHold(params: OnHoldParams): Promise<OnHoldSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<OnHoldSuccess>(`/internal/myprofile/SubscriptionUpdate/OnHold${formatParams(params)}`)
        }
    
    
        

        export type SubscriptionUpdateUpdateParams = paths['/internal/myprofile/SubscriptionUpdate/Update']['get']['parameters']['query'];

        export type SubscriptionUpdateUpdateSuccess = paths['/internal/myprofile/SubscriptionUpdate/Update']['get']['responses']['200']['content']['text/plain'];

        export const SubscriptionUpdateUpdateUrl = '/internal/myprofile/SubscriptionUpdate/Update';

        
        export function SubscriptionUpdateUpdate(params: SubscriptionUpdateUpdateParams): Promise<SubscriptionUpdateUpdateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SubscriptionUpdateUpdateSuccess>(`/internal/myprofile/SubscriptionUpdate/Update${formatParams(params)}`)
        }
    
    
        

        export type GetSubscriptionPriceTotalParams = paths['/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal']['get']['parameters']['query'];

        export type GetSubscriptionPriceTotalSuccess = paths['/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal']['get']['responses']['200']['content']['text/plain'];

        export const GetSubscriptionPriceTotalUrl = '/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal';

        
        export function GetSubscriptionPriceTotal(params: GetSubscriptionPriceTotalParams): Promise<GetSubscriptionPriceTotalSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetSubscriptionPriceTotalSuccess>(`/internal/myprofile/SubscriptionUpdate/GetSubscriptionPriceTotal${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    