import { TrackingCategory } from 'modules/tracking/UAEventTracking';
import { firstCharacterToLower } from 'modules/helpers/strings';
import type { ContextValues } from '../types/ContextValues';

export default function eventCategoryToContext(eventCategory: TrackingCategory): ContextValues {
    const categoryType: Uncapitalize<TrackingCategory> = firstCharacterToLower(eventCategory);

    switch (categoryType) {
        case 'productList':
            return 'product_list';
        case 'productPage':
            return 'product_page';
        case 'basketRecomendation':
            return 'recommendation';
        case 'cmsPageRecomendation':
            return 'recommendation';
        case 'powerstep':
            return 'recommendation';
        case 'powerstepAfterBasket':
            return 'recommendation';
        case 'productGroupRecomendation':
            return 'recommendation';
        case 'productRecomendation':
            return 'recommendation';
        case 'receiptPageRecommendation':
            return 'recommendation';
        case 'searchResultPageRecomendation':
        case 'zeroResultPageRecomendation':
            return 'recommendation';
        case 'bestMatch':
        case 'visualSearchResults':
            return 'search';
        case 'crossSell':
            return 'product_page';
        case 'productSlider':
            return 'product_slider';
        case 'productRegime':
            return 'product_page';
        case 'replenish':
            return 'product_list';
        case 'samples':
            return 'product_list';
        case 'variantPicker':
            return 'variant_picker';
        case 'none':
        default:
            return undefined;
    }
}

export type EventCategoryToContext = typeof eventCategoryToContext;
