import { useCallback, useEffect } from 'react';

export default function useOnKeyPressEffect(callback: (key: KeyboardEvent['key']) => void) {
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            callback(event.key);
        },
        [callback]
    );

    useEffect(() => {
        document.addEventListener('keyup', handleKeyPress, false);

        return () => {
            document.removeEventListener('keyup', handleKeyPress, false);
        };
    }, [handleKeyPress]);
}
