import { scrollDirection } from 'modules/helpers/scroll';
import store from 'store';
import { setHeaderIsStickyBool } from 'store/actions/headerActions';
import ResizeObserver from 'resize-observer-polyfill';
import breakpoint from './breakpoint';

export default class Header {
    constructor(header) {
        this.body = $('body');
        this.header = header;
        this.scroller = $(window);
        this.headerFixed = this.header.find('.js-header-fixed');

        this.fixedHeader = this.fixedHeader.bind(this);
        this.fixedSearchHeader = this.fixedSearchHeader.bind(this);
    }

    init() {
        this.bindings();
        this.scrollDirection = scrollDirection({
            up: ({ programmaticScroll }) => {
                if (programmaticScroll) return;
                if (this.shouldBeFixed) {
                    if (!this.body.hasClass('state--fixed')) {
                        requestAnimationFrame(() => {
                            this.body.addClass('state--fixed');
                        });
                    }
                    if (!this.body.hasClass('state--scroll-up')) {
                        store.dispatch(setHeaderIsStickyBool(true));
                        requestAnimationFrame(() => {
                            this.body.addClass('state--scroll-up');
                        });
                    }
                }
                if (this.searchShouldBeFixed) {
                    if (!this.body.hasClass('state--fixed-search')) {
                        requestAnimationFrame(() => {
                            this.body.addClass('state--fixed-search');
                        });
                    }
                }
            },
            down: ({ programmaticScroll }) => {
                if (programmaticScroll) return;
                const {
                    header: { isLocked }
                } = store.getState();
                if (isLocked) return;
                if (this.body.hasClass('state--scroll-up')) {
                    store.dispatch(setHeaderIsStickyBool(false));
                    requestAnimationFrame(() => {
                        this.body.removeClass('state--scroll-up');
                    });
                }
                if (this.body.hasClass('state--fixed') && this.removeFixedOnScrollDown) {
                    requestAnimationFrame(() => {
                        this.body.removeClass('state--fixed');
                    });
                }
            }
        });
    }

    bindings() {
        const intersectionObserverTarget = document.querySelector('.js-intersectionObserverFixedHeader');
        const intersectionObserverTargetTop = document.querySelector('.js-intersectionObserverFixedHeaderTop');
        if (intersectionObserverTarget && intersectionObserverTargetTop) {
            const intersectionObserver = new IntersectionObserver(this.fixedHeader);
            const resizeObserver = new ResizeObserver(() => {
                if (breakpoint.min('xl')) {
                    intersectionObserver.observe(intersectionObserverTarget);
                    intersectionObserver.observe(intersectionObserverTargetTop);
                } else {
                    intersectionObserver.unobserve(intersectionObserverTarget);
                    intersectionObserver.unobserve(intersectionObserverTargetTop);
                    this.scrollDirection.unsubscribe();
                    requestAnimationFrame(() => {
                        this.body.removeClass('state--scroll-up');
                        this.body.removeClass('state--fixed');
                        store.dispatch(setHeaderIsStickyBool(true));
                    });
                }
            });

            resizeObserver.observe(intersectionObserverTarget);
        }

        const intersectionObserverSearchTarget = document.querySelector('.js-intersectionObserverSearchFixedHeader');
        if (intersectionObserverSearchTarget) {
            const searchIntersectionObserver = new IntersectionObserver(this.fixedSearchHeader);
            const searchResizeObserver = new ResizeObserver(() => {
                if (breakpoint.min('xl')) {
                    searchIntersectionObserver.observe(intersectionObserverSearchTarget);
                } else {
                    searchIntersectionObserver.unobserve(intersectionObserverSearchTarget);
                }
            });

            searchResizeObserver.observe(intersectionObserverSearchTarget);
        }
    }

    fixedHeader(entries) {
        const fixedBottom = entries.find(entry =>
            entry.target.classList.contains('js-intersectionObserverFixedHeader')
        );
        const fixedTop = entries.find(entry =>
            entry.target.classList.contains('js-intersectionObserverFixedHeaderTop')
        );

        if (fixedTop) {
            if (fixedTop.isIntersecting) {
                this.shouldBeFixed = false;
                requestAnimationFrame(() => {
                    this.body.removeClass('state--fixed');
                    this.body.removeClass('state--scroll-up');
                    store.dispatch(setHeaderIsStickyBool(false));
                });
            } else {
                this.shouldBeFixed = true;
            }
        }

        if (fixedBottom) {
            if (!fixedBottom.isIntersecting) {
                this.scrollDirection.subscribe();
                this.removeFixedOnScrollDown = false;
                const {
                    header: { isLocked }
                } = store.getState();
                if (isLocked) {
                    requestAnimationFrame(() => {
                        this.body.addClass('state--fixed');
                        this.body.addClass('state--scroll-up');
                        store.dispatch(setHeaderIsStickyBool(true));
                    });
                }
            } else {
                this.removeFixedOnScrollDown = true;
            }
        } else if (fixedTop && !fixedTop.isIntersecting) {
            this.scrollDirection.unsubscribe();
        }
    }

    fixedSearchHeader(entries) {
        if (entries[0].isIntersecting) {
            this.searchShouldBeFixed = false;
            requestAnimationFrame(() => {
                this.body.removeClass('state--fixed-search');
            });
        } else {
            this.searchShouldBeFixed = true;
        }
    }
}
