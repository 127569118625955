import { css } from 'styled-components';
import { type MappedBreakpointStyling, mediaQueryStyling } from 'helpers/mediaQueryStyling';
import type { BreakPointsType } from 'variables';
import { em } from 'modules/helpers/style';

export const inlineButtonFontWeights = {
    regular: css`
        font-weight: 400;
        text-decoration-thickness: ${em(1)};
    `,
    semibold: css`
        font-weight: 600;
    `
};

export type inlineButtonFontWeightKeys = keyof typeof inlineButtonFontWeights;
export type inlineButtonFontWeightObject = BreakPointsType<inlineButtonFontWeightKeys>;
export type inlineButtonFontWeight = inlineButtonFontWeightObject | inlineButtonFontWeightKeys;
type BreakpointFontWeight = MappedBreakpointStyling<inlineButtonFontWeightObject, typeof inlineButtonFontWeights>;

function getButtonSizesMediaQueryStyling(sizesObject: inlineButtonFontWeightObject) {
    const breakpointFontWeight = Object.keys(sizesObject).reduce<BreakpointFontWeight>((acc, value) => {
        acc[value] = inlineButtonFontWeights[sizesObject[value]];
        return acc;
    }, {});

    return mediaQueryStyling(breakpointFontWeight);
}

export const fontWeightStyling = css<{ $fontWeight: inlineButtonFontWeight }>`
    ${({ $fontWeight }) =>
        $fontWeight &&
        (typeof $fontWeight === 'object'
            ? getButtonSizesMediaQueryStyling($fontWeight)
            : inlineButtonFontWeights[$fontWeight])}
`;
