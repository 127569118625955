import { type BasketDiscountSummary } from 'autogen/swagger/checkout';
import { TotalDiscount } from 'autogen/translation-keys/trans-website-basket';
import Layout from 'components/Layout';
import { Price } from 'components/generic/productPrice';
import { Typography } from 'components/generic/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledBody = styled(Typography.Body)`
    font-style: italic;
`;

const StyledPrice = styled(Price)`
    font-style: italic;
    font-weight: normal;
`;

type TotalDiscountSummary = Record<string, any> & { model: BasketDiscountSummary };

export default function TotalDiscountSummary({ model: { total }, ...restProps }: TotalDiscountSummary) {
    const { t } = useTranslation();

    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <StyledBody forwardedAs="span" themeTypes="medium">
                {t(TotalDiscount)}
            </StyledBody>
            <StyledPrice price={total} />
        </Layout.Flex.Row>
    );
}
