import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import setGlobal from 'modules/helpers/setGlobal';
import rootReducer from './reducers';

const initialState = {};
const middleWare = [thunk];
const store = createStore(rootReducer, initialState, applyMiddleware(...middleWare));

setGlobal({ store });

export default store;
