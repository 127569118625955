import {
    ColorClubMatas1,
    ColorClubMatasPlus1,
    ColorClubMatasPlus4,
    ColorClubMatas4,
    ColorClubMatas3,
    ColorClubMatasPlus3
} from 'autogen/design-tokens/tokens';
import PointTheme from './types';

/* #region  themes */
export const club = {
    colors: {
        primary: ColorClubMatas1,
        secondary1: ColorClubMatas3,
        secondary2: ColorClubMatas4
    }
};

export const plus = {
    colors: {
        primary: ColorClubMatasPlus1,
        secondary1: ColorClubMatasPlus3,
        secondary2: ColorClubMatasPlus4
    }
};
/* #endregion */

export const getPointTheme = (theme: PointTheme) => {
    switch (theme) {
        case PointTheme.club:
            return club;
        case PointTheme.plus:
            return plus;
        default:
            return club;
    }
};
