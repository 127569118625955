import UAEventTracking, { TrackingAction } from 'modules/tracking/UAEventTracking';
import initMultiselect from './subscriptionMultiSelect';
import initRadioSelect from './subscriptionRadioSelect';

function track(action: TrackingAction, eventLabel?: string): void {
    UAEventTracking({
        eventCategory: 'subscription',
        eventAction: action,
        ...(eventLabel && { eventLabel })
    });
}

export default function (selectContainer: HTMLElement): void {
    const subscriptionValue = selectContainer.querySelector('.js-subscriptionFrequencyValue') as HTMLInputElement;

    initMultiselect(selectContainer, subscriptionValue, track);

    initRadioSelect(selectContainer, subscriptionValue, track);
}
