/* eslint-disable @typescript-eslint/no-shadow */
import { useRecoilState } from 'recoil';
import { quickViewState } from '../atoms/quickViewState';
import type { QuickViewState } from '../shared/types';

export default function useQuickView<P extends Record<string, unknown>>(quickViewId: string) {
    const [{ visible, props }, setQuickView] = useRecoilState<QuickViewState<P>>(quickViewState(quickViewId));

    const toggle = (visible: QuickViewState['visible'], props?: P) =>
        setQuickView((previousState) => {
            const nextState = {
                visible,
                props: props || previousState.props
            };
            return nextState;
        });

    const updateProps = (props: P) => {
        setQuickView((previousState) => {
            const nextState = {
                visible: previousState.visible,
                props
            };
            return nextState;
        });
    };

    return { visible, toggle, props, updateProps };
}
