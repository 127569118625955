export default async function polyfill(locale) {
    const [
        { default: getcanonicallocalesPolyfill },
        { default: localePolyfill },
        { default: pluralRulesPolyfill },
        { default: numberFormatPolyfill },
        { default: dateTimeFormatPolyfill },
        { default: listFormatPolyfill }
    ] = await Promise.all([
        import('./getcanonicallocales'),
        import('./locale'),
        import('./pluralRules'),
        import('./numberFormat'),
        import('./dateTimeFormat'),
        import('./listFormat')
    ]);

    await getcanonicallocalesPolyfill();
    await localePolyfill();
    await pluralRulesPolyfill(locale);
    await numberFormatPolyfill(locale);
    await dateTimeFormatPolyfill(locale);
    await listFormatPolyfill(locale);
}
