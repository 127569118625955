import TotalPriceSummary from './TotalPriceSummary';
import ActivePrizeCertificateRenderer from './ActivePrizeCertificateSummaryRenderer';
import AdditionalGiftCardRenderer from './AdditionalGiftCardSummaryRenderer';
import AdditionalSummary from './AdditionalSummaryLine';
import AdditionalSummaryRenderer from './AdditionalSummaryRenderer';
import VoucherSummaryRenderer from './VoucherSummaryRenderer';
import ShippingSummary from './ShippingSummary';
import TotalDiscountSummary from './TotalDiscountSummary';
import SubTotalSummary from './SubTotalSummary';

const SummaryLine = {
    SubTotalSummary,
    TotalDiscountSummary,
    AdditionalSummary,
    AdditionalSummaryRenderer,
    ShippingSummary,
    VoucherSummaryRenderer,
    AdditionalGiftCardRenderer,
    ActivePrizeCertificateRenderer,
    TotalPriceSummary
};

export default SummaryLine;
