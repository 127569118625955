import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavItem } from 'autogen/translation-keys/trans-website-advisortoolbox-booking';
import BookingFeatureTooltip from 'components/shared/TooltipNotification/BookingFeatureTooltip';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { useRecoilValue } from 'recoil';
import TooltipNotificationAtom from 'components/shared/TooltipNotification/shared/recoil/atom';
import type { TooltipModelMaybe } from 'autogen/swagger/menu';
import { SessionMenuAnchorItem } from 'components/SessionMenu/components/SessionMenuItem';
import Calendar from 'icons/Calendar.svg?react';
import { GA4Tracking } from 'modules/tracking/GA4';
import { HeaderItemText } from './shared/styles';
import type { LinkAction } from 'components/shared/Routing/components/LinkAction/shared/types';

export default function Booking({
    tooltip,
    linkAction,
    ...restProps
}: {
    tooltip?: TooltipModelMaybe;
    linkAction: LinkAction;
}) {
    const { t } = useTranslation();
    const isOpen = tooltip ? useRecoilValue(TooltipNotificationAtom(tooltip.tooltipType)) : false;

    return (
        <BookingFeatureTooltip model={tooltip}>
            <SessionMenuAnchorItem
                icon={<Calendar />}
                action={linkAction}
                onClick={() => {
                    UAEventTracking({
                        eventCategory: 'header',
                        eventAction: 'goToBookingPage',
                        eventLabel: isOpen ? 'TooltipActive' : undefined
                    });

                    GA4Tracking({
                        eventName: 'go_to_booking_page',
                        context: 'header',
                        category: 'navigation',
                        eventParams: [{ param_name: 'link_type', param_value: 'internal' }]
                    });
                }}
                {...restProps}
            >
                <HeaderItemText>{t(NavItem)}</HeaderItemText>
            </SessionMenuAnchorItem>
        </BookingFeatureTooltip>
    );
}
