import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Translations from 'modules/translations';
import breakpoint from 'modules/breakpoint';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { format } from 'modules/helpers/strings';
import { GA4Tracking } from 'modules/tracking/GA4';
import { listTypeEnum, buttonEnum, sectionEnum, trackingActionEnum } from '../../enums';
import ResultSection from '../../components/resultSection/resultSection';

const Dialog = styled.div`
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
`;

const ScrollElement = styled.div`
    flex: 1 0 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`;

const TabButtons = styled.div`
    display: flex;
    width: 100%;
    border-top: 2px solid #000;
    flex: 0 0 auto;
`;

const TabButton = styled.button`
    display: inline-block;
    text-transform: uppercase;
    flex: 1 1 auto;
    height: 40px;
    border-top: 0;
    border-right: 1px solid #b8b8b8;
    border-bottom: 1px solid #b8b8b8;
    border-left: 1px solid #b8b8b8;
    background-color: #ececec;
    font-size: 10px;
    cursor: pointer;

    @media (min-width: ${breakpoint.sizes.md}px) {
        font-size: 1rem;
    }
    ${props =>
        props.activeTab &&
        css`
            font-weight: 700;
            border-bottom-color: transparent;
            background-color: transparent;
        `}

    & ~ &,
    &:first-of-type {
        border-left: 0;
    }
    &:last-of-type {
        border-right: 0;
    }
`;

export default props => {
    const {
        articles,
        brands,
        featuredBrands,
        brandFacets,
        products,
        suggestions,
        activeTab,
        setActiveTab,
        nextQueries,
        dialogTouchCallback
    } = props;
    const hasSuggestions = suggestions && suggestions.items.length !== 0;
    const hasProducts = products && products.items.length !== 0;
    const hasArticles = articles && articles.items.length !== 0;
    const hasBrands = brands && brands.items.length !== 0;
    const hasFeaturedBrands = featuredBrands && featuredBrands.items.length !== 0;
    const hasBrandFacets = brandFacets && brandFacets.items.length !== 0;
    const hasNextQueries = nextQueries && nextQueries.items.length > 0;

    const scrollRef = React.createRef();

    const renderSection = activeTab => {
        switch (activeTab) {
            case sectionEnum.suggestions:
                if (!hasSuggestions) return null;

                return (
                    <>
                        {hasNextQueries && (
                            <ResultSection
                                items={nextQueries}
                                headline={Translations.Website.SearchDialog.RelatedToLatestSearch}
                                listType={listTypeEnum.link}
                                buttonType={buttonEnum.link}
                                trackingAction={trackingActionEnum.nextQuery}
                            />
                        )}
                        <ResultSection
                            items={suggestions}
                            listType={listTypeEnum.link}
                            headline={hasNextQueries ? Translations.Website.SearchDialog.SearchSuggestions : null}
                        />
                    </>
                );
            case sectionEnum.brands:
            case sectionEnum.brandFacets:
                if (!hasBrands && !featuredBrands && !hasBrandFacets) return null;

                return (
                    <>
                        {(hasBrands || hasFeaturedBrands) && (
                            <ResultSection
                                headline={Translations.Website.SearchDialog.Brands}
                                buttonType={buttonEnum.link}
                                items={{ brands, featuredBrands }}
                                listType={listTypeEnum.featuredBrand}
                            />
                        )}
                        {!hasBrands && !hasFeaturedBrands && hasBrandFacets && (
                            <ResultSection
                                headline={format(Translations.Website.SearchDialog.BrandFacetTitle)(
                                    brandFacets.actualSearchText
                                )}
                                buttonType={buttonEnum.link}
                                items={brandFacets}
                                listType={listTypeEnum.brand}
                            />
                        )}
                    </>
                );
            case sectionEnum.products:
                if (!hasProducts) return null;

                return <ResultSection items={products} listType={listTypeEnum.product} />;
            case sectionEnum.articles:
                if (!hasArticles) return null;

                return <ResultSection items={articles} listType={listTypeEnum.article} />;
            default:
                console.error(`couldn't render section: ${activeTab}`);
                return null;
        }
    };

    useEffect(() => {
        const scrollContainer = scrollRef.current;

        disableBodyScroll(scrollContainer, {
            reserveScrollBarGap: true
        });

        return () => {
            document.getElementsByTagName('body')[0].classList.remove('state--search-open');
            enableBodyScroll(scrollContainer);
        };
    }, []);

    useEffect(() => {
        scrollRef.current.scrollTop = 0;
    }, [activeTab]);

    return (
        <Dialog>
            <TabButtons>
                {hasSuggestions && (
                    <TabButton
                        type="button"
                        className="tracking_quicksearch_tabClick_suggestions"
                        onClick={() => {
                            setActiveTab(sectionEnum.suggestions);
                            GA4Tracking({
                                eventName: 'tab_click',
                                context: 'search',
                                category: 'ecommerce',
                                eventParams: [
                                    { param_name: 'search_type', param_value: 'quick_search' },
                                    { param_name: 'search_term', param_value: suggestions.actualSearchText },
                                    { param_name: 'tab_type', param_value: 'suggestions' }
                                ]
                            });
                        }}
                        activeTab={activeTab === sectionEnum.suggestions}
                    >
                        {Translations.Website.SearchDialog.SearchSuggestions}
                    </TabButton>
                )}
                {hasProducts && (
                    <TabButton
                        type="button"
                        className="tracking_quicksearch_tabClick_products"
                        onClick={() => {
                            setActiveTab(sectionEnum.products);
                            GA4Tracking({
                                eventName: 'tab_click',
                                context: 'search',
                                category: 'ecommerce',
                                eventParams: [
                                    { param_name: 'search_type', param_value: 'quick_search' },
                                    { param_name: 'search_term', param_value: products.actualSearchText },
                                    { param_name: 'tab_type', param_value: 'products' }
                                ]
                            });
                        }}
                        activeTab={activeTab === sectionEnum.products}
                    >
                        {Translations.Website.SearchDialog.Products}
                    </TabButton>
                )}
                {(hasBrands || hasFeaturedBrands || hasBrandFacets) && (
                    <TabButton
                        type="button"
                        className="tracking_quicksearch_tabClick_brands"
                        onClick={() => {
                            setActiveTab(sectionEnum.brands);
                            GA4Tracking({
                                eventName: 'tab_click',
                                context: 'search',
                                category: 'ecommerce',
                                eventParams: [
                                    { param_name: 'search_type', param_value: 'quick_search' },
                                    { param_name: 'search_term', param_value: brands.actualSearchText },
                                    { param_name: 'tab_type', param_value: 'brands' }
                                ]
                            });
                        }}
                        activeTab={activeTab === sectionEnum.brands || activeTab === sectionEnum.brandFacets}
                    >
                        {Translations.Website.SearchDialog.Brands}
                    </TabButton>
                )}
                {hasArticles && (
                    <TabButton
                        type="button"
                        className="tracking_quicksearch_tabClick_articles"
                        onClick={() => {
                            setActiveTab(sectionEnum.articles);
                            GA4Tracking({
                                eventName: 'tab_click',
                                context: 'search',
                                category: 'ecommerce',
                                eventParams: [
                                    { param_name: 'search_type', param_value: 'quick_search' },
                                    { param_name: 'search_term', param_value: articles.actualSearchText },
                                    { param_name: 'tab_type', param_value: 'articles' }
                                ]
                            });
                        }}
                        activeTab={activeTab === sectionEnum.articles}
                    >
                        {Translations.Website.SearchDialog.Articles}
                    </TabButton>
                )}
            </TabButtons>
            <ScrollElement ref={scrollRef} onTouchStart={dialogTouchCallback}>
                {renderSection(activeTab)}
            </ScrollElement>
        </Dialog>
    );
};
