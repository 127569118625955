export default class SubscriptionList {
    static bind(scope = $('body')) {
        scope.find('#subscriptions-list').each((i, item) => {
            const subscriptionList = new SubscriptionList($(item));
            subscriptionList.init();
        });
    }

    constructor(subscriptionList) {
        this.wrapper = $(subscriptionList);
        this.getMoreButton = this.wrapper.find('[data-js-load-more]');

        this.getContent = this.getContent.bind(this);
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.getMoreButton.one('click', this.getContent);
    }

    getContent(event) {
        const target = $(event.target);
        const url = target.attr('data-js-load-more');

        $.ajax({
            url
        }).done(response => {
            if (!response) return;
            this.wrapper.replaceWith(response);
            SubscriptionList.bind();
        });
    }
}
