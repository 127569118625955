export const listTypeEnum = Object.freeze({ link: 1, article: 2, product: 3, brand: 4, featuredBrand: 5 });
export const layoutEnum = Object.freeze({ row: 1, column: 2, tab: 3, noResult: 4 });
export const buttonEnum = Object.freeze({ link: 1, clearHistory: 2 });
export const sectionEnum = Object.freeze({
    suggestions: 1,
    brands: 2,
    brandFacets: 3,
    products: 4,
    articles: 5,
    latestSearchQueries: 6
});
export const navigationEvent = Object.freeze({ arrow: 1, hover: 2, autofill: 3 });
export const trackingActionEnum = Object.freeze({
    querySuggestion: 'querySuggestion',
    popularSearchSuggestion: 'popularSearchSuggestion',
    latestSearchSuggestion: 'latestSearchSuggestion',
    nextQuery: 'nextQuery'
});
