
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './advisorchat';

        export type AdvisorChatType = components['schemas']['AdvisorChatType'];
                    export type HeroChatDepartment = components['schemas']['HeroChatDepartment'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type AdvisorChatNudgeViewModel = components['schemas']['AdvisorChatNudgeViewModel'];
                    export type AdvisorChatNudgeViewModelMaybe = components['schemas']['AdvisorChatNudgeViewModelMaybe'];
                    export type AdvisorChatViewModel = components['schemas']['AdvisorChatViewModel'];
                    export type ZowieUseCaseModel = components['schemas']['ZowieUseCaseModel'];
                    

        
        

        

        export type AdvisorChatIndexSuccess = paths['/internal/AdvisorChat/Index']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorChatIndexUrl = '/internal/AdvisorChat/Index';

        
        export function AdvisorChatIndex(): Promise<AdvisorChatIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorChatIndexSuccess>(`/internal/AdvisorChat/Index`)
        }
    
    
        

        

        

        export const AdvisorChatPageUrl = '/internal/AdvisorChat/AdvisorChatPage';

        
        export function AdvisorChatPage(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/AdvisorChat/AdvisorChatPage`)
        }
    
    
        

        

        export type ZowieSettingsSuccess = paths['/internal/AdvisorChat/ZowieSettings']['get']['responses']['200']['content']['text/plain'];

        export const ZowieSettingsUrl = '/internal/AdvisorChat/ZowieSettings';

        
        export function ZowieSettings(): Promise<ZowieSettingsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ZowieSettingsSuccess>(`/internal/AdvisorChat/ZowieSettings`)
        }
    
    

        export * from './discriminators';
    