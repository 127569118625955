/* eslint-disable */
/// This file is auto generated - do not edit manually
export const Headline = 'Website.Booking.ListStores.Headline';
export const EventsNearYou = 'Website.Booking.ListStores.EventsNearYou';
export const EditBookingHeadline = 'Website.Booking.ListStores.EditBookingHeadline';
export const SubmitButton = 'Website.Booking.ListStores.SubmitButton';
export const ResetButton = 'Website.Booking.ListStores.ResetButton';
export const Select = 'Website.Booking.ListStores.Select';
export const Deselect = 'Website.Booking.ListStores.Deselect';
export const LoadMore = 'Website.Booking.ListStores.LoadMore';
export const ChooseStores = 'Website.Booking.ListStores.ChooseStores';
export const SearchStores = 'Website.Booking.ListStores.SearchStores';
export const SelectedStoreHeadline = 'Website.Booking.ListStores.SelectedStoreHeadline';
export const ClosestStoresHeadline = 'Website.Booking.ListStores.ClosestStoresHeadline';
export const ChosenStores = 'Website.Booking.ListStores.ChosenStores';
export const StoreSingular = 'Website.Booking.ListStores.StoreSingular';
export const StorePlural = 'Website.Booking.ListStores.StorePlural';
export const EarlyStageMessage = 'Website.Booking.ListStores.EarlyStageMessage';
export const EarlyStageLink = 'Website.Booking.ListStores.EarlyStageLink';
