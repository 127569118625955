import goBack from 'modules/helpers/goBack';

export default function goBackButton(buttons: HTMLCollectionOf<HTMLButtonElement | HTMLLinkElement>): void {
    const buttonArray = Array.from(buttons);

    buttonArray.forEach((button: HTMLLinkElement | HTMLButtonElement): void => {
        button.addEventListener('click', (event): void => {
            const fallBackUrl = button.dataset.historyBackFallback || null;

            event.preventDefault();
            goBack(fallBackUrl);
        });
    });
}
