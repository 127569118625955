import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import type { LinkActionProps } from '../shared/types';

const DefaultLink = forwardRef<HTMLAnchorElement, LinkActionProps>(({ action, children, ...restProps }, ref) => (
    <Link ref={ref} to={action.url} state={action} reloadDocument {...restProps}>
        {children}
    </Link>
));

export default DefaultLink;
