import { ColorNeutralGrey3, ColorNeutralLightGrey, Spacing12 } from 'autogen/design-tokens/tokens';
import type React from 'react';
import styled from 'styled-components';
import * as Typography from 'components/__deprecated/typography';
import type option from '../shared/types/option';
import { useSelect } from './SelectProvider';
import type { OptionType } from '../../SelectWithHitCount';

/* #region  styles */
const Option = styled.li<{ isSelected: boolean; isDisabled?: boolean }>`
    display: flex;
    padding: ${Spacing12};
    justify-content: space-between;
    background-color: ${({ isSelected }) => isSelected && ColorNeutralGrey3};
    cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
    border: 1px solid transparent;
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
    opacity: ${({ isDisabled }) => (isDisabled ? '0.32' : '1')};

    &:focus {
        border-color: ${ColorNeutralLightGrey};
    }

    &:hover {
        background-color: ${ColorNeutralGrey3};
    }
`;
/* #endregion */

const SelectOption: React.FC<React.PropsWithChildren<{ value: option | OptionType }>> = ({ value, children }) => {
    const { isSelected, selectOption } = useSelect();

    const selected = isSelected(value);

    return (
        <Option
            onClick={() => selectOption(value)}
            tabIndex={value.disabled ? -1 : 0}
            isSelected={selected}
            isDisabled={value.disabled}
        >
            <Typography.Label size="sm">{children}</Typography.Label>
        </Option>
    );
};

export default SelectOption;
