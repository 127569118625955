import Translations from 'modules/translations';

export default class HandleEpaperSession {
    constructor() {
        this.sessionName = 'Epaper-Url';
        this.stepsSessionName = 'Epaper-steps';
        this.sessionData = window.location.href;
        this.refHostname = document.referrer.indexOf(window.location.hostname);
        this.maxSteps = 2;
        this.EpaperUrl = this.getEpaperSession();
    }

    init() {
        this.checkIfInSession();
    }

    checkIfInSession() {
        if (document.referrer.indexOf(this.getEpaperSession()) != -1) {
            // make sure steps are cleared when leaving epaper
            this.removeEpaperSession(this.stepsSessionName);
        }

        // check if last page was from the same domain
        this.handleSteps();

        if (this.refHostname == -1 || this.EpaperUrl == null) {
            this.removeEpaperSession(this.sessionName);
            this.removeEpaperSession(this.stepsSessionName);
        } else if ((this.refHostname != -1 || this.EpaperUrl != null) && window.location.href != this.EpaperUrl) {
            // in session
            this.setBackButton();
        } else {
            this.removeEpaperSession(this.sessionName);
            this.removeEpaperSession(this.stepsSessionName);
        }
    }

    setBackButton() {
        if (this.stepsArray == undefined || this.stepsArray.length > this.maxSteps) {
            return;
        }

        const backButton = $(
            `<button type="button" class="btn btn--cta btn--epaper-back"><svg class="icon icon--arrow-left"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icons_arrow"></use></svg><span class="btn__epaper-text">${
                Translations.Website.Epaper.BackButtonText
            }</span></button>`
        );

        $('[data-js="viewMain"]').addClass('state--epaper-session');
        $('[data-js="toTop"]').after(backButton);
        backButton.on('click', () => {
            this.removeEpaperSession(this.stepsSessionName);
            window.history.go(`-${this.stepsArray.length}`);
        });
    }

    removeEpaperSession(item) {
        sessionStorage.removeItem(item);
    }

    setEpaperSession() {
        const name = this.sessionName;
        let data = this.sessionData;

        data = data.split('?')[0]; // to remove loadsubpages

        sessionStorage.setItem(name, data);
    }

    getEpaperSession() {
        return sessionStorage.getItem(this.sessionName);
    }

    handleSteps() {
        if ($('body.epaper, html.checkout-layout, .basket').length >= 1 || this.EpaperUrl == null) {
            this.removeEpaperSession();
            return;
        }

        // Leave Epaper Session if user gets more the 2 steps away from the Epaper
        const currentSteps = sessionStorage.getItem(this.stepsSessionName);
        this.stepsArray = currentSteps != null ? currentSteps.split(',') : [];

        if (this.sessionData == this.stepsArray[this.stepsArray.length - 2]) {
            // go back
            this.stepsArray.splice(-1, 2);
        } else if (this.sessionData == this.stepsArray[this.stepsArray.length - 1]) {
            // reload
            return;
        } else {
            this.stepsArray.push(this.sessionData);
        }
        sessionStorage.setItem(this.stepsSessionName, this.stepsArray);
    }
}
