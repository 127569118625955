import { Spacing2, Spacing4, Spacing8 } from 'autogen/design-tokens/tokens';
import InlineButton from 'components/generic/InlineButton';
import { Typography } from 'components/generic/typography';
import type { themeTypes } from 'components/generic/typography/components/Body';
import { verticalEllipsis } from 'modules/helpers/mixins';
import React, { type HTMLAttributes } from 'react';
import styled from 'styled-components';
import { toHtmlContent } from 'modules/helpers/strings';
import type { IArticleCardTextBox, LayoutType } from '../shared/types';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin-bottom: ${({ theme }) => theme.layoutTheme === 'horizontalSmall' && Spacing2};
`;

const Overline = styled(Typography.Overline)`
    margin-bottom: ${({ theme }) => (theme.layoutTheme === 'horizontalSmall' ? Spacing8 : Spacing4)};
`;

const TitleContainer = styled.div`
    flex-grow: 1;
    max-width: 100%;
`;

const TitleVertical = styled(Typography.Headline)`
    ${verticalEllipsis(3)}
`;

const TitleHorizontal = styled(Typography.Body)`
    ${verticalEllipsis(2)}
`;

const StyledInlineButton = styled(InlineButton)`
    margin-top: ${({ theme }) => (theme.layoutTheme === 'vertical' ? Spacing8 : 'auto')};

    ${({ theme }) => theme.layoutTheme === 'horizontalSmall' && 'display: none;'};
`;

function getTitleSize(layoutTheme: LayoutType): themeTypes {
    switch (layoutTheme) {
        case 'horizontalSmall':
            return 'smallSemiBold';
        case 'horizontal':
            return 'mediumSemiBold';
        case 'vertical':
        default:
            return 'largeSemiBold';
    }
}

type TitleType = {
    layoutTheme: LayoutType;
} & HTMLAttributes<HTMLDivElement>;

function Title({ layoutTheme, ...restProps }: TitleType) {
    const { children } = restProps;

    if (layoutTheme === 'vertical') {
        return <TitleVertical size={{ xs: 18, lg: 20 }}>{children}</TitleVertical>;
    }

    return (
        <TitleHorizontal forwardedAs="span" themeTypes={getTitleSize(layoutTheme)}>
            {children}
        </TitleHorizontal>
    );
}

export default function ArticleCardTextBox({
    props,
    layoutTheme
}: {
    props: IArticleCardTextBox;
    layoutTheme: LayoutType;
}): JSX.Element {
    const { title, action, overline, additionalInfo } = props;

    const actionText = action?.buttonText;

    const isSmall = layoutTheme === 'horizontalSmall';

    return (
        <Wrapper>
            {overline && <Overline size={isSmall ? 8 : 10}>{overline}</Overline>}
            <TitleContainer>
                <Title layoutTheme={layoutTheme}>{toHtmlContent(title)}</Title>
            </TitleContainer>
            {additionalInfo && additionalInfo}
            {actionText && (
                <StyledInlineButton forwardedAs="span" variant="dark" size="sm" fontWeight="semibold">
                    {actionText}
                </StyledInlineButton>
            )}
        </Wrapper>
    );
}
