/* eslint-disable */
/// This file is auto generated - do not edit manually
export const DoYouHaveVoucherCode = 'Website.Basket.Voucher.DoYouHaveVoucherCode';
export const AddVoucher = 'Website.Basket.Voucher.AddVoucher';
export const InputPlaceholder = 'Website.Basket.Voucher.InputPlaceholder';
export const Code = 'Website.Basket.Voucher.Code';
export const Error_CodeTooLong = 'Website.Basket.Voucher.Error_CodeTooLong';
export const Error_CodeEmpty = 'Website.Basket.Voucher.Error_CodeEmpty';
export const Success_Updated = 'Website.Basket.Voucher.Success_Updated';
export const Redeem = 'Website.Basket.Voucher.Redeem';
export const RemoveVoucher = 'Website.Basket.Voucher.RemoveVoucher';
export const VoucherWasRemoved = 'Website.Basket.Voucher.VoucherWasRemoved';
export const VoucherWasAdded = 'Website.Basket.Voucher.VoucherWasAdded';
export const VoucherNotFound = 'Website.Basket.Voucher.VoucherNotFound';
export const VoucherNotAvailable = 'Website.Basket.Voucher.VoucherNotAvailable';
export const VoucherNotApplicable = 'Website.Basket.Voucher.VoucherNotApplicable';
export const Notice = 'Website.Basket.Voucher.Notice';
export const UnusedCouponVoucherPriceText = 'Website.Basket.Voucher.UnusedCouponVoucherPriceText';
