import { UpdateHasUserComment } from 'autogen/swagger/summary';
import SimpleToggle from '../../modules/simpleToggle';

export function updateBasket(html) {
    $('[data-js="checkout-basket"]').each((i, item) => {
        item.outerHTML = html;
    });
    SimpleToggle.bind($('[data-js="checkout-basket"]'));
}

export default class ContentToggle {
    doc: JQuery<Document>;

    wrapper: JQuery<any>;

    content: any;

    constructor() {
        this.doc = $(document);
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.doc.on('change', '[data-js-content-toggle^="radio:"]', (e) => this.toggleContent(e));
        this.doc.on('click', '[data-js-content-toggle][type=button]', (e) => this.buttonToggle(e));
    }

    buttonToggle(e) {
        this.toggleContent(e);

        const target = $(e.target);

        target
            .closest('[data-js-content-toggle="wrap"]')
            .find('[data-js-content-toggle][type=button]')
            .val(target.val().toString().toLowerCase() === 'true' ? 'false' : 'true');
    }

    toggleContent(e) {
        const target = $(e.target);
        const basketGuid = target.attr('data-basketguid');
        this.wrapper = target.closest('[data-js-content-toggle="wrap"]');
        this.content = this.wrapper.find('[data-js-content-toggle="content"]');
        const toggleUserComment = target.val().toString().toLowerCase() === 'true';

        if (toggleUserComment) {
            this.content.show();
        } else {
            this.content.hide();
            this.emptyInputs();
        }

        UpdateHasUserComment({ basketGuid, value: toggleUserComment });
    }

    emptyInputs() {
        this.content.find('textarea, input').val('').trigger('focusout');
    }
}
