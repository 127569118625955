import { fetchHTML } from 'modules/helpers/fetch';
import { $loadClass } from 'modules/helpers/moduleLoaders';

export default class PointTransactionList {
    constructor(wrapper) {
        this.wrapper = wrapper;
        this.selectors = this.wrapper.getElementsByClassName('js-selection-input');
    }

    init() {
        this.bindings();
    }

    bindings() {
        for (let i = 0; i < this.selectors.length; i++) {
            const element = this.selectors[i];
            element.addEventListener('change', event => {
                this.sortList(event);
            });
        }
    }

    async sortList(event) {
        const { target } = event;
        const { value: url } = target;
        const html = await fetchHTML(url);

        this.wrapper.outerHTML = html;
        rebindPage();
    }
}

const rebindPage = () => {
    const newContainer = document.getElementById('point-page');
    const pointTransactionList = new PointTransactionList(newContainer);
    pointTransactionList.init();

    $loadClass('.js-selection', () => import(/* webpackChunkName: "selection" */ 'modules/selection'));
};
