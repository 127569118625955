import useIsomorphicLayoutEffect from 'hooks/globals/useIsomorphicLayoutEffect';
import { useEffect, useRef } from 'react';

/*
    A declarative interval hook
*/
export default function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback);

    useIsomorphicLayoutEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);

        // eslint-disable-next-line consistent-return
        return () => clearInterval(id);
    }, [delay]);
}
