import React from 'react';
import styled from 'styled-components';
import { ListType } from './shared/types';

const ListElement = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export default function List(props: ListType) {
    const { children, ...restProps } = props;

    return <ListElement {...restProps}>{children}</ListElement>;
}
