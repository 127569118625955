import { Spacing8, ColorNeutralBlack, BorderRadiusSmall } from 'autogen/design-tokens/tokens';
import { useDeleteGiftFromBasketMutation } from 'autogen/swagger/basket/hooks';
import type { MiniBasketGiftLineViewModel } from 'autogen/swagger/minibasket';
import { Free } from 'autogen/translation-keys/trans-website-basket';
import { ButtonWithIcon } from 'components/Button';
import Flex from 'components/Layout/components/Flex';
import { QuantityText } from 'components/basket/BasketItem/components/Actions/components/QuantitySelect';
import { updateBasket } from 'components/basket/shared/basket';
import Card from 'components/generic/card';
import ProductImage from 'components/generic/productImage';
import { ProductImageContainer } from 'components/generic/productImage/shared/styles';
import ProductName from 'components/generic/productName';
import dynamic from 'helpers/dynamic';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';

/* #region styles */
export const Grid = styled.div`
    display: grid;
    gap: ${Spacing8};
    grid-template-columns: auto 64px 1fr;
    align-items: center;

    ${breakpoint.up(sizes.lg)} {
        grid-template-columns: auto 64px 1fr;
    }
`;

const StyledQuantityText = styled(QuantityText)`
    background-color: white;
    height: 40px;
    border-radius: ${BorderRadiusSmall};
    width: 64px;
    min-width: 64px;
`;

/* #endregion */
const Cross = dynamic(() => import('icons/Cross.svg?react'));

export default function MiniBasketGiftItem({ model }: { model: MiniBasketGiftLineViewModel }) {
    const { productName, primaryImage, productId, discountId, quantity } = model;
    const { t } = useTranslation();
    const { mutateAsync: deleteGiftFromBasket } = useDeleteGiftFromBasketMutation({ onSuccess: updateBasket });

    return (
        <Card size={null}>
            <Card.Content>
                <Grid>
                    <ButtonWithIcon
                        css={css`
                            align-self: center;
                        `}
                        size="sm"
                        variant="ghostOnLight"
                        icon={<Cross />}
                        onClick={() => deleteGiftFromBasket({ params: { productId, discountId } })}
                    />

                    <ProductImageContainer>
                        <ProductImage width={64} image={primaryImage} transformType="SquareScale" />
                    </ProductImageContainer>

                    <Flex.Row justifyContent="space-between" alignItems="center">
                        <Flex.Column gap={Spacing8}>
                            <ProductName
                                productName={productName}
                                size="sm"
                                customTheme={{
                                    ProductTitle: css`
                                        color: ${ColorNeutralBlack};
                                    `
                                }}
                            />
                        </Flex.Column>

                        <StyledQuantityText quantity={quantity} />

                        <Flex.Column alignItems="end">{t(Free)}</Flex.Column>
                    </Flex.Row>
                </Grid>
            </Card.Content>
        </Card>
    );
}
