
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './progressivediscount';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ProgressiveDiscountViewModel = components['schemas']['ProgressiveDiscountViewModel'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type ProgressiveDiscountStepViewModel = components['schemas']['ProgressiveDiscountStepViewModel'];
                    export type ProgressiveDiscountDetailsViewModel = components['schemas']['ProgressiveDiscountDetailsViewModel'];
                    

        
        

        export type ProgressiveDiscountForProductParams = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountForProductSuccess = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountForProductUrl = '/internal/Discount/ProgressiveDiscountForProduct';

        
        export function ProgressiveDiscountForProduct(params: ProgressiveDiscountForProductParams): Promise<ProgressiveDiscountForProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProgressiveDiscountForProductSuccess>(`/internal/Discount/ProgressiveDiscountForProduct${formatParams(params)}`)
        }
    
    
        

        export type ProgressiveDiscountDetailsForProductParams = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountDetailsForProductSuccess = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountDetailsForProductUrl = '/internal/Discount/ProgressiveDiscountDetailsForProduct';

        
        export function ProgressiveDiscountDetailsForProduct(params: ProgressiveDiscountDetailsForProductParams): Promise<ProgressiveDiscountDetailsForProductSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProgressiveDiscountDetailsForProductSuccess>(`/internal/Discount/ProgressiveDiscountDetailsForProduct${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    