import React from 'react';
import { objectToPascalCase } from 'modules/helpers/strings';

const routesObj = {};

export const setRoutesData = (obj: any): void => {
    Object.entries(obj).forEach(([key, item]): void => {
        routesObj[key] = item;
    });
};

const Routes = (): any => routesObj;

export default Routes();

export function setRoutes(WrappedComponent): (any) => JSX.Element {
    return (props: any): JSX.Element => {
        const { routes, ...restProps } = props;

        if (routes) {
            if (typeof routes === 'string') {
                setRoutesData(JSON.parse(routes));
            } else {
                setRoutesData(objectToPascalCase(routes));
            }
        }

        return <WrappedComponent {...restProps} />;
    };
}
