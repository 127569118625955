/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as SubscriptionLine from './trans-website-myprofile-pendingsubscription-subscriptionline';
export * as Cancel from './trans-website-myprofile-pendingsubscription-cancel';
export * as Confirmation from './trans-website-myprofile-pendingsubscription-confirmation';

export const Headline = 'Website.MyProfile.PendingSubscription.Headline';
export const ActivateButtonText = 'Website.MyProfile.PendingSubscription.ActivateButtonText';
export const Date = 'Website.MyProfile.PendingSubscription.Date';
export const Store = 'Website.MyProfile.PendingSubscription.Store';
export const DataTableHeadline = 'Website.MyProfile.PendingSubscription.DataTableHeadline';
export const SubscriptionProductListHeadline = 'Website.MyProfile.PendingSubscription.SubscriptionProductListHeadline';
export const Subscription = 'Website.MyProfile.PendingSubscription.Subscription';
export const CancelButtonText = 'Website.MyProfile.PendingSubscription.CancelButtonText';
export const PaymentHeadline = 'Website.MyProfile.PendingSubscription.PaymentHeadline';
export const TermsAndConditionsHeadline = 'Website.MyProfile.PendingSubscription.TermsAndConditionsHeadline';
export const TermsAndConditionsText = 'Website.MyProfile.PendingSubscription.TermsAndConditionsText';
export const TermsAndConditionsButton = 'Website.MyProfile.PendingSubscription.TermsAndConditionsButton';
export const TermsAndConditionsSubmitButton = 'Website.MyProfile.PendingSubscription.TermsAndConditionsSubmitButton';
export const SubscriptionBenefit1 = 'Website.MyProfile.PendingSubscription.SubscriptionBenefit1';
export const SubscriptionBenefit2 = 'Website.MyProfile.PendingSubscription.SubscriptionBenefit2';
export const SubscriptionBenefit3 = 'Website.MyProfile.PendingSubscription.SubscriptionBenefit3';
