/* eslint-disable react/no-array-index-key */
import type { GetScalingAttrProps } from 'helpers/imageScalingHelper';
import { forwardRef, type ForwardedRef, type HTMLAttributes } from 'react';
import Image from '../Image';
import PictureSource from './PictureSource';
import type { PictureProps, PictureSourceProps } from './shared/types';
import styled from 'styled-components';

const ImageWithScaling = styled(Image.WithScaling)`
    max-width: 100%;
    height: auto;
    width: auto;
`;

function getHighestBreakpoint(data): PictureSourceProps {
    return data.reduce((prev, curr) => (prev.breakpoint > curr.breakpoint ? prev : curr));
}

function mapSizesFromSources(sources: PictureSourceProps[]): GetScalingAttrProps['sizes'] {
    const removedDuplicates = new Set(sources.flatMap((source) => source.sizes));

    return Array.from(removedDuplicates);
}

const Picture = forwardRef(
    (props: PictureProps & HTMLAttributes<HTMLPictureElement>, ref: ForwardedRef<HTMLPictureElement>): JSX.Element => {
        const { alt, sources, transformType = 'WidthScale', imageType = 'default', fallbackSrc, ...restProps } = props;
        const fallbackUrl = fallbackSrc || getHighestBreakpoint(sources).src;
        const mappedSizes = mapSizesFromSources(sources);

        return (
            <picture ref={ref} {...restProps}>
                {sources.map((source, index) => (
                    <PictureSource key={index} source={source} transformType={transformType} imageType={imageType} />
                ))}

                <ImageWithScaling
                    alt={alt}
                    transformType={transformType}
                    imageType={imageType}
                    src={fallbackUrl}
                    sizes={mappedSizes}
                />
            </picture>
        );
    }
);

export default Picture;
