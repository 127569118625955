import useUserContext from './useUserContext';

export default function useIsClubMatasMember(): {
    isClubMatasMember: boolean;
    isMatasPlusMember: boolean;
} {
    const state = useUserContext();

    return {
        isClubMatasMember: state?.isClubMatasMember,
        isMatasPlusMember: state?.isMatasPlusMember
    };
}
