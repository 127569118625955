import {
    ColorNeutralBlack,
    ColorNeutralWhite,
    ColorOpacityDark1,
    ColorOpacityDark2,
    ColorOpacityLight1,
    ColorOpacityLight2
} from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';
import InlineButtonVariation from './types/inlineButtonVariation';

const sharedDisableStyling = css`
    cursor: not-allowed;
    opacity: 0.4;
`;

const inherit: InlineButtonVariation = css<{ $disabled: boolean }>`
    color: currentColor;

    ${({ $disabled }) =>
        $disabled &&
        css`
            ${sharedDisableStyling}
        `};
`;

const dark: InlineButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralBlack};

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      color: ${ColorOpacityDark2};
                  }

                  &:focus {
                      color: ${ColorOpacityDark1};
                  }
              `}
`;

const light: InlineButtonVariation = css<{ $disabled: boolean }>`
    color: ${ColorNeutralWhite};

    ${({ $disabled }) =>
        $disabled
            ? css`
                  ${sharedDisableStyling}
              `
            : css`
                  &:hover {
                      color: ${ColorOpacityLight2};
                  }

                  &:focus {
                      color: ${ColorOpacityLight1};
                  }
              `}
`;

export const inlineButtonVariants = {
    inherit,
    dark,
    light
} as const;

export type inlineButtonVariantKeys = keyof typeof inlineButtonVariants;

export const variantStyling = css<{ variant?: inlineButtonVariantKeys }>`
    ${({ variant }) => (variant ? inlineButtonVariants[variant] : null)}
`;

export default inlineButtonVariants;
