import type { HTMLAttributes } from 'react';
import type { BreakPointsType } from 'variables';

export const availableFontSizeArray = [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 40, 48, 56] as const;
export type availableFontSizeTypes = (typeof availableFontSizeArray)[number];
export type breakPointObject<T = availableFontSizeTypes> = BreakPointsType<T>;

export type fontSizeTypes<T extends availableFontSizeTypes = availableFontSizeTypes> =
    | Extract<availableFontSizeTypes, T>
    | BreakPointsType<Extract<availableFontSizeTypes, T>>;

export interface IText extends HTMLAttributes<HTMLElement> {
    size?: fontSizeTypes;
    nowrap?: boolean;
}

export type OverlineFontSizes = fontSizeTypes<8 | 10 | 14>;
