import Tippy, { type TippyProps } from '@tippyjs/react';
import { useRef } from 'react';
import { zIndex as zIndexLayer } from 'variables';
import LargeTooltipPopupContent, { type ILargeTooltipContent } from './components/LargeTooltipPopupContent';
import SmallTooltipPopupContent, { type ISmallTooltipContent } from './components/SmallTooltipPopupContent';
import useStorageTooltipPopup, { type IStorageTooltipPopupConfigMaybe } from './shared/hooks/useStorageTooltipPopup';
import XSmallTooltipPopupContent, { type IXSmallTooltipContent } from './components/XSmallTooltipPopupContent';

type XSmallProps = {
    size: 'xs';
    content: IXSmallTooltipContent;
};

type SmallProps = {
    size: 'sm';
    content: ISmallTooltipContent;
};

type LargeProps = {
    size: 'lg';
    content: ILargeTooltipContent;
};

export type StorageTooltipPopupProps = {
    name: string;
    config?: IStorageTooltipPopupConfigMaybe;
    children: JSX.Element;
    showCloseButton?: boolean;
} & (XSmallProps | SmallProps | LargeProps);

/**
 * Ready to use TooltipPopup that supports storing a display state locally and ScreenTimeLimit, visibility when intersection
 * Tooltip popup automatically opens based on user-defined conditions contrary to a traditional tooltip
 *
 * @param name - identifier/key for storage
 * @param content - Content for the tooltip
 * @param config - Tooltip configurationObj
 * @param children - Root reference element
 * @returns
 */

export default function StorageTooltipPopup(
    props: StorageTooltipPopupProps & Omit<TippyProps, 'theme' | 'visible' | 'content'>
) {
    const {
        name,
        config,
        children,
        size,
        delay = [500, 500],
        interactive = true,
        arrow = true,
        showCloseButton,
        zIndex = zIndexLayer.fixed - 1,
        ...restProps
    } = props;
    const rootElementRef = useRef<Element>(null);
    const { isOpen, close } = useStorageTooltipPopup(name, rootElementRef, config);

    const getTooltipContent = () => {
        switch (size) {
            case 'xs':
                return <XSmallTooltipPopupContent content={props.content} />;
            case 'sm':
                return (
                    <SmallTooltipPopupContent content={props.content} close={close} showCloseButton={showCloseButton} />
                );
            case 'lg':
                return <LargeTooltipPopupContent content={props.content} close={close} />;
            default:
                return null;
        }
    };

    return (
        <Tippy
            {...restProps}
            ref={rootElementRef}
            visible={isOpen}
            content={getTooltipContent()}
            theme="rich-tooltip"
            delay={delay}
            arrow={arrow}
            interactive={interactive}
            zIndex={zIndex}
        >
            {children}
        </Tippy>
    );
}
