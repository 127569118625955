import type { IProductImage, ProductImage as ProductImageModel } from 'autogen/swagger/product';
import Image from 'components/generic/Image';
import imageOverlayStyle from 'components/shared/style/imageOverlay';
import type { ImageTransformType } from 'helpers/imageScalingHelper';
import type { ComponentPropsWithoutRef, ImgHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

const ProductRatioContainer = styled.div<{ maxWidth?: string; ratioHeight: number; ratioWidth: number }>`
    position: relative;
    width: 100%;
    ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth};
        `}

    &::before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: ${({ ratioWidth, ratioHeight }) => `calc(${ratioWidth} / ${ratioHeight} * 100%)`};
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }
`;

const AbsoluteContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface AspectRatio {
    maxWidth?: string;
    ratioHeight?: number;
    ratioWidth?: number;
}

export function AspectRatioContainer({
    children,
    maxWidth,
    ratioHeight = 1, // default ratio is 1:1, a perfect square
    ratioWidth = 1, // default ratio is 1:1, a perfect square
    ...restProps
}: AspectRatio & ComponentPropsWithoutRef<'div'>) {
    return (
        <ProductRatioContainer maxWidth={maxWidth} ratioHeight={ratioHeight} ratioWidth={ratioWidth} {...restProps}>
            <AbsoluteContainer>{children}</AbsoluteContainer>
        </ProductRatioContainer>
    );
}

const StyledProductImage = styled(Image.WithScaling)`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

export interface IProductImageProps extends AspectRatio {
    image: ImgHTMLAttributes<HTMLImageElement> & (IProductImage | ProductImageModel); // all image props are spread to the <img/> tag
    width: number;
    transformType: ImageTransformType;
}

export function ProductImage({
    image,
    width = 136,
    maxWidth = `${width}px`,
    ratioHeight = 1, // default ratio is 1:1, a perfect square
    ratioWidth = 1, // default ratio is 1:1, a perfect square
    transformType,
    ...restProps
}: IProductImageProps) {
    return (
        <AspectRatioContainer maxWidth={maxWidth} ratioHeight={ratioHeight} ratioWidth={ratioWidth} {...restProps}>
            <StyledProductImage
                alt={image.alt || ''}
                sizes={width}
                src={image.url}
                transformType={transformType}
                imageType="product"
                loading="lazy"
            />
        </AspectRatioContainer>
    );
}

const StyledProductImageWithOverlay = styled(ProductImage)`
      &:after {
        content: '';
        ${imageOverlayStyle}
    }
`;

export function ProductImageWithOverlay(props: IProductImageProps) {
    return <StyledProductImageWithOverlay {...props} />;
}

export default Object.assign(ProductImage, {
    WithOverlay: ProductImageWithOverlay
});
