import { BorderRadiusSmall, ColorNordicGrey4, Spacing12 } from 'autogen/design-tokens/tokens';
import { BasketMessageDiscountViewModel } from 'autogen/swagger/basket';
import { ComponentPropsWithoutRef, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { toHtmlContent } from 'modules/helpers/strings';
import UAEventTracking, { type TrackingAction } from 'modules/tracking/UAEventTracking';
import InlineButton from 'components/generic/InlineButton';
import { _LoginInfo } from 'autogen/swagger/login';
import useLoginInfoQuickView from 'components/login/shared/hooks/useLoginInfoQuickView';
import AdditionalInfoButton from './AdditionalInfoButton';
import campaignInfoAdditionalInfoIdAtom from '../recoil/additionalInfoIdAtom';
import InlineLinkActionButton from 'components/generic/InlineButton/InlineLinkActionButton';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    background-color: ${ColorNordicGrey4};
    border-radius: ${BorderRadiusSmall};
`;

const Text = styled.div`
    padding: ${Spacing12};
`;

const StyledAdditionalInfoButton = styled(AdditionalInfoButton)`
    margin-left: auto;
    padding: ${Spacing12};
`;

const RemainingText = styled.strong`
    display: inline-block;
`;

export type ICampaignBasketNudge = { model: BasketMessageDiscountViewModel } & ComponentPropsWithoutRef<'div'>;

export default function CampaignBasketNudge({
    model: { hasAdditionalInfo, discountNudgeText, remainingText, loginToGetDiscount, loginLink, discountId },
    ...restProps
}: ICampaignBasketNudge) {
    const [, toggle] = useLoginInfoQuickView();
    const setProductId = useSetRecoilState(campaignInfoAdditionalInfoIdAtom);
    const { loginModal, loginLinkAction } = loginLink || {};

    function getLoginInfo() {
        _LoginInfo({
            infoType: 'BasketSample',
            level: 'Hard',
            mode: 'Standard',
            returnUrl: window.location.pathname
        }).then((loginInfo) => {
            toggle({ isOpen: true, loginInfo });
        });
    }

    async function openLoginModal() {
        if (loginModal) {
            getLoginInfo();
            UAEventTracking({
                eventCategory: 'campaignInfo',
                eventAction: loginModal.trackingAction as TrackingAction
            });
        }
    }

    useEffect(() => {
        setProductId(discountId);
    }, []);

    return (
        <Wrapper {...restProps}>
            <Text>
                {toHtmlContent(discountNudgeText, 'span')} <RemainingText>{remainingText} </RemainingText>
                {loginLinkAction && (
                    <span>
                        <span> - </span>
                        <InlineLinkActionButton variant="dark" action={loginLinkAction}>
                            {loginToGetDiscount}
                        </InlineLinkActionButton>
                    </span>
                )}
                {!loginModal && (
                    <span>
                        <span> - </span>
                        <InlineButton type="button" variant="dark" onClick={openLoginModal}>
                            {loginToGetDiscount}
                        </InlineButton>
                    </span>
                )}
            </Text>
            {hasAdditionalInfo && <StyledAdditionalInfoButton />}
        </Wrapper>
    );
}
