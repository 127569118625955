class PubSub {
    channels: Record<string, { context; callback }[]>;

    constructor() {
        this.channels = {};
    }

    subscribe<T>(channel: string, fn: (...args: T[]) => void) {
        if (!this.channels[channel]) {
            this.channels[channel] = [];
        }

        this.channels[channel].push({ context: this, callback: fn });

        return this;
    }

    unsubscribeSubscriberFromChannel(channel, callback) {
        const index = this.channels[channel].findIndex((subscriber) => subscriber.callback === callback);
        if (index !== -1) {
            this.channels[channel].splice(index, 1);
        }
    }

    unsubscribe(channel: string) {
        if (!this.channels[channel]) {
            return false;
        }

        delete this.channels[channel];

        return this;
    }

    publish<T>(channel: string, ...args: T[]) {
        if (!this.channels[channel]) {
            return false;
        }

        this.channels[channel].forEach((subscription) => {
            subscription.callback.apply(subscription.context, args);
        });

        return this;
    }
}

const mediator = new PubSub();

export default mediator;
