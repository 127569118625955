export function createElement(HtmlStringArray, ...children): Node {
    const temp = document.createElement('div');

    temp.innerHTML = HtmlStringArray?.flatMap((HtmlString, index, array): string[] | null =>
        index + 1 < array.length ? [HtmlString.trim(), `<div id="child-placeholder-${index}"></div>`] : null
    )
        .filter(Boolean)
        .join('');

    children?.forEach((child, index): void => {
        const type = typeof child;
        const childPlaceholder = temp.querySelector(`#child-placeholder-${index}`);
        childPlaceholder.replaceWith(type === 'string' ? document.createTextNode(child) : child);
    });

    return temp.childNodes[0];
}

export default createElement;

export function getParents(node: Node, selector: string): HTMLElement[] {
    const parents: HTMLElement[] = [];
    let currentElem = node;

    for (; currentElem && currentElem !== document; currentElem = currentElem.parentNode) {
        const elem = currentElem as HTMLElement;
        if (selector) {
            if (elem.matches(selector)) {
                parents.push(elem);
            }
        } else {
            parents.push(elem);
        }
    }

    return parents;
}
