
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedGlobalSymbols
import type {components, paths} from './minibasket';

export function LinkActionDiscriminator<T>(
    model: components['schemas']['MiniBasketViewModel']['basketLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}
export function BasketMessageDiscriminator<T>(
    model: components['schemas']['BasketMessageWrapperMaybe']['basketMessage'],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}
