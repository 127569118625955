import { FullWidthBackgroundSection } from './components/FullWidthBackgroundSection';
import TightSection from './components/TightSection';
import DefaultSection from './Section';

const Section = Object.assign(DefaultSection, {
    WithFullWidthBackground: FullWidthBackgroundSection,
    Tight: TightSection
});

export default Section;
