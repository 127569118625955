import type { ReactNode } from 'react';
import type { ProductNameLine } from 'autogen/swagger/product';
import { Brand, Miscellaneous, Product, Variant } from '../shared/styles';
import type { IProductName, IProductNameSize } from '../shared/types';
import { ProductNameLineTheme } from '../shared/theme';

const standardProductNameRenderer = (
    line: ProductNameLine,
    size: IProductNameSize = 'md',
    as?: IProductName['as']
): JSX.Element | ReactNode => {
    const { type, url, value } = line;

    switch (type) {
        case 'Brand':
            return (
                <Brand
                    size={ProductNameLineTheme[size].Brand}
                    forwardedAs={as || (url ? 'a' : 'span')}
                    key={type}
                    href={url}
                >
                    {value}
                </Brand>
            );

        case 'Name':
            return (
                <Product
                    themeTypes={ProductNameLineTheme[size].Name}
                    forwardedAs={as || (url ? 'a' : 'span')}
                    key={type}
                    href={url}
                >
                    {value}
                </Product>
            );
        case 'Size':
        case 'Color':
        case 'Variant':
            return (
                <Variant themeTypes={ProductNameLineTheme[size].Variant} forwardedAs="span" key={type}>
                    {value}
                </Variant>
            );

        default:
            return (
                <Miscellaneous forwardedAs="span" themeTypes={ProductNameLineTheme[size].Variant} key={type}>
                    {value}
                </Miscellaneous>
            );
    }
};

export default standardProductNameRenderer;
