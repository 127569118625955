import Flex from './components/Flex';
import Page from './components/Page';
import Section from './components/Section';

const Layout = {
    Page,
    Section,
    Flex
};

export default Layout;
