import React, { useRef, useState, useEffect } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import toggleScrollLock from 'helpers/scrollLock';
import debounce from 'debounce';
import matchMedia from 'modules/helpers/matchMedia';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { breakpoint, colors, sizes } from 'variables';
import { animated, config, useTransition } from '@react-spring/web';
import { BorderRadiusMedium } from 'autogen/design-tokens/tokens';
import useIsomorphicLayoutEffect from 'hooks/globals/useIsomorphicLayoutEffect';
import DefaultHeader from '../components/header';
import CloseButton from '../components/closeButton';
import type { IDefaultLayout } from './defaultLayout';

const Box = styled.div<{ fitContent?: boolean }>`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: ${({ fitContent }) => (fitContent ? 'auto' : '90%')};
    background-color: ${({ theme }) => theme.backgroundColor || colors.negative};
    border-radius: ${BorderRadiusMedium} ${BorderRadiusMedium} 0 0;

    &:after {
        content: '';
        position: absolute;
        bottom: -10%;
        background-color: white;
        width: 100%;
        height: 10%;
    }

    ${breakpoint.up(sizes.md)} {
        position: relative;
        width: ${({ theme }) => theme.width || 'auto'};
        height: ${({ theme }) => theme.height || 'auto'};
        min-width: ${({ theme }) => theme.minWidth || '600px'};
        max-width: ${({ theme }) => theme.maxWidth || '90%'};
        max-height: ${({ theme }) => theme.maxHeight || '90%'};
        margin-bottom: 0;
        border-radius: ${BorderRadiusMedium};

        &:after {
            content: none;
        }
    }
`;

const AnimatedBox = animated<any>(Box);

const HeaderContainer = styled.div``;

const Content = styled.div<{ headerHeight: number }>`
    ${({ headerHeight }) =>
        headerHeight === undefined
            ? css`
                  // hide overflowing desktop content during animation
                  ${breakpoint.up(sizes.md)} {
                      display: none;
                  }
              `
            : css`
                  display: block;
                  height: calc(100% - ${headerHeight}px);
              `}
    overflow: auto;
`;

interface IDrawerLayout extends IDefaultLayout {
    fitContent?: boolean;
}

export default (props: IDrawerLayout): JSX.Element => {
    const {
        fitContent,
        customTheme = {},
        header,
        open,
        close,
        userCanClose,
        unmount,
        content,
        trackingCategory
    } = props;

    const headerRef = useRef<HTMLDivElement>();
    const [headerHeight, setHeaderHeight] = useState<number>(undefined);
    const [isMobile, setIsMobile] = useState<boolean>(matchMedia.isMobile);

    const handleResize = debounce((): void => setIsMobile(matchMedia.isMobile), 100);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    useIsomorphicLayoutEffect(() => {
        toggleScrollLock(open);
    }, [open]);

    function closeModal() {
        close();
        if (trackingCategory) {
            UAEventTracking({
                eventCategory: trackingCategory,
                eventAction: 'closeModal'
            });
        }
    }

    function onAnimationEnd() {
        if (!open) {
            unmount();
        } else if (headerRef.current) {
            const rect = headerRef.current.getBoundingClientRect();
            if (rect) setHeaderHeight(rect.height);
        }
    }

    const slideConfig = {
        from: { opacity: 1, transform: 'translateY(100%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 1, transform: 'translateY(100%)' },
        config: { ...config.wobbly, friction: 20 },
        onRest: () => onAnimationEnd()
    };

    const popConfig = {
        from: { transform: 'scale(0.5)', opacity: 0 },
        enter: { transform: 'scale(1)', opacity: 1 },
        leave: { transform: 'scale(0.5)', opacity: 0 },
        // eslint-disable-next-line no-plusplus, no-param-reassign
        config: { duration: 300, easing: (t) => --t * t * t + 1 }, // cubicOut easing
        onRest: () => onAnimationEnd()
    };

    const transition = useTransition(open, isMobile ? slideConfig : popConfig);

    const HeaderProp = (headerProps) => (header !== null ? React.cloneElement(header, headerProps) : null);

    return (
        <ThemeProvider theme={customTheme}>
            {transition(
                (styles, item) =>
                    item && (
                        <AnimatedBox style={styles} fitContent={fitContent}>
                            <HeaderContainer ref={headerRef}>
                                {header ? (
                                    <HeaderProp userCanClose={userCanClose} close={closeModal} />
                                ) : (
                                    <DefaultHeader>
                                        {userCanClose && <CloseButton onClick={closeModal} />}
                                    </DefaultHeader>
                                )}
                            </HeaderContainer>
                            <Content headerHeight={headerHeight}>{content}</Content>
                        </AnimatedBox>
                    )
            )}
        </ThemeProvider>
    );
};
