import {
    ColorBeauty1,
    ColorBeauty4,
    ColorBeauty3,
    ColorFragrance1,
    ColorFragrance4,
    ColorFragrance3,
    ColorHair1,
    ColorHair5,
    ColorHair3,
    ColorHealth1,
    ColorHealth5,
    ColorHealth3,
    ColorMen1,
    ColorMen4,
    ColorMen3,
    ColorNeutralBlack,
    ColorNeutralWhite,
    ColorNordicGrey1,
    ColorNordicGrey4,
    ColorNordicGrey3,
    ColorSkin1,
    ColorSkin4,
    ColorSkin3
} from 'autogen/design-tokens/tokens';
import { ColorTheme, IColorTheme } from './shared/types';

export default function handleColorTheme(colorTheme?: ColorTheme): IColorTheme {
    if (typeof colorTheme === 'object') return colorTheme;

    switch (colorTheme) {
        // Beauty
        case 'beauty':
            return {
                backgroundColor: ColorBeauty1,
                color: ColorNeutralBlack
            };
        case 'beauty60':
            return {
                backgroundColor: ColorBeauty3,
                color: ColorNeutralBlack
            };
        case 'beauty40':
            return {
                backgroundColor: ColorBeauty4,
                color: ColorNeutralBlack
            };
        // Fragrance
        case 'fragrance':
            return {
                backgroundColor: ColorFragrance1,
                color: ColorNeutralBlack
            };
        case 'fragrance60':
            return {
                backgroundColor: ColorFragrance3,
                color: ColorNeutralBlack
            };
        case 'fragrance40':
            return {
                backgroundColor: ColorFragrance4,
                color: ColorNeutralBlack
            };
        // Hair
        case 'hair':
            return {
                backgroundColor: ColorHair1,
                color: ColorNeutralBlack
            };
        case 'hair60':
            return {
                backgroundColor: ColorHair3,
                color: ColorNeutralBlack
            };
        case 'hair20':
            return {
                backgroundColor: ColorHair5,
                color: ColorNeutralBlack
            };
        // Health
        case 'health':
            return {
                backgroundColor: ColorHealth1,
                color: ColorNeutralBlack
            };
        case 'health60':
            return {
                backgroundColor: ColorHealth3,
                color: ColorNeutralBlack
            };
        case 'health40':
            return {
                backgroundColor: ColorHealth5,
                color: ColorNeutralBlack
            };
        // Men
        case 'men':
            return {
                backgroundColor: ColorMen1,
                color: ColorNeutralBlack
            };
        case 'men60':
            return {
                backgroundColor: ColorMen3,
                color: ColorNeutralBlack
            };
        case 'men40':
            return {
                backgroundColor: ColorMen4,
                color: ColorNeutralBlack
            };
        // Nordic grey
        case 'nordicGrey':
            return {
                backgroundColor: ColorNordicGrey1,
                color: ColorNeutralBlack
            };
        case 'nordicGrey60':
            return {
                backgroundColor: ColorNordicGrey3,
                color: ColorNeutralBlack
            };
        case 'nordicGrey40':
            return {
                backgroundColor: ColorNordicGrey4,
                color: ColorNeutralBlack
            };
        // Skin
        case 'skin':
            return {
                backgroundColor: ColorSkin1,
                color: ColorNeutralBlack
            };
        case 'skin60':
            return {
                backgroundColor: ColorSkin3,
                color: ColorNeutralBlack
            };
        case 'skin40':
            return {
                backgroundColor: ColorSkin4,
                color: ColorNeutralBlack
            };
        // Neutral
        case 'neutral':
        default:
            return {
                color: ColorNeutralBlack
            };
    }
}
