import {
    BorderRadiusLarge,
    BorderRadiusMedium,
    BorderRadiusSmall,
    ColorNeutralWhite,
    Spacing12,
    Spacing16,
    Spacing32,
    Spacing40
} from 'autogen/design-tokens/tokens';
import styled, { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';
import type { IQuickView } from './types';

export type HeaderButtonProps = {
    rounded?: boolean;
};

export const HeaderButton = styled.button<HeaderButtonProps>`
    background-color: ${ColorNeutralWhite};
    border: 0;
    padding: ${Spacing12};
    font-weight: bold;

    ${({ rounded }) =>
        rounded &&
        css`
            border-radius: 50%;
        `}
`;

export const SlideContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1 1 auto;
    min-height: 0;
    background-color: inherit;
`;

export const Slide = styled(SlideContainer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const bottomDrawerTransition = {
    from: { opacity: 0, transform: 'translateY(100%)' },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: { opacity: 0, transform: 'translateY(100%)' }
};

const leftSideDrawerTransition = {
    from: { transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(-100%)' }
};

const rightSideDrawerTransition = {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' }
};

export function positionTransition(position: IQuickView['position']) {
    switch (position) {
        case 'left':
            return leftSideDrawerTransition;
        case 'right':
            return rightSideDrawerTransition;
        case 'bottom':
        default:
            return bottomDrawerTransition;
    }
}

const commonStyling = css`
    width: 100%;
    min-height: 200px;
`;

const bottomStyling = css`
    ${commonStyling}
    max-height: calc(100% - ${Spacing40});
    border-top-left-radius: ${BorderRadiusLarge};
    border-top-right-radius: ${BorderRadiusLarge};

    ${breakpoint.up(sizes.sm)} {
        border-radius: ${BorderRadiusSmall};
        width: auto;
    }
`;

const leftStyling = css`
    ${commonStyling}
    height: 100%;

    ${breakpoint.up(sizes.sm)} {
        max-width: 480px;
    }
`;

const rightStyling = css`
    ${commonStyling}
    height: 100%;

    ${breakpoint.up(sizes.sm)} {
        max-width: 480px;
    }
`;

const centerStyling = css`
    margin: ${Spacing16};
    max-height: calc(100% - ${Spacing32});
    border-radius: ${BorderRadiusMedium};
`;

export function positionStyling(position: IQuickView['position']) {
    switch (position) {
        case 'center':
            return centerStyling;
        case 'left':
            return leftStyling;
        case 'right':
            return rightStyling;
        case 'bottom':
        default:
            return bottomStyling;
    }
}

const centerPlacementStyling = css`
    justify-content: center;
    align-items: center;
`;

const leftPlacementStyling = css`
    align-items: stretch;
    justify-content: flex-start;
`;

const rightPlacementStyling = css`
    align-items: stretch;
    justify-content: flex-end;
`;

const bottomPlacementStyling = css`
    justify-content: stretch;
    align-items: flex-end;

    ${breakpoint.up(sizes.sm)} {
        ${centerPlacementStyling}
    }
`;

export function placementContainerPositionStyling(position: IQuickView['position']) {
    switch (position) {
        case 'center':
            return centerPlacementStyling;
        case 'left':
            return leftPlacementStyling;
        case 'right':
            return rightPlacementStyling;
        case 'bottom':
        default:
            return bottomPlacementStyling;
    }
}
