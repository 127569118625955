import Translations from 'modules/translations';
import { format } from 'modules/helpers/strings';
import UAEventTracking, { type TrackingAction } from 'modules/tracking/UAEventTracking';

let lastLabel = ''; // used to stop double tracking
function updateValue(
    subscriptionValue: HTMLInputElement,
    select: HTMLSelectElement,
    frequencyText: HTMLSpanElement | null,
    doNotTrack?: boolean
): void {
    let selectedContent = '';
    const val = select.value;
    subscriptionValue.value = val;
    subscriptionValue.dispatchEvent(new window.Event('change', { bubbles: true }));

    if (frequencyText) {
        selectedContent = select.options[select.selectedIndex].text;
        const text = format(Translations.Website.Product.Subscription.FrequencyExplanation)(selectedContent);
        frequencyText.textContent = text;
    }

    if (!doNotTrack && selectedContent !== lastLabel) {
        lastLabel = selectedContent;

        UAEventTracking({
            eventCategory: 'subscription',
            eventAction: 'changeFrequency',
            eventLabel: selectedContent
        });
    }
}

export default function initMultiselect(
    selectContainer: HTMLElement,
    subscriptionValue: HTMLInputElement,
    track: (action: TrackingAction, label: string) => void
): void {
    const simpleSelect = selectContainer.querySelector('.js-subscriptionSimpleSelect') as HTMLSpanElement;

    if (!simpleSelect) return;

    const shortContainer = selectContainer.querySelector('.js-subscriptionFrequenciesShort') as HTMLSpanElement;
    const shortSelection = shortContainer.querySelector('.js-selection-input') as HTMLSelectElement;
    const advancedSelect = selectContainer.querySelector('.js-subscriptionAdvancedSelect') as HTMLDivElement;
    const groupSelector = advancedSelect.querySelector(
        '.js-subscriptionFrequencyGroupSelect .js-selection-input'
    ) as HTMLSelectElement;
    const frequencyGroups = advancedSelect.querySelectorAll(
        '.js-subscriptionFrequencyGroup'
    ) as NodeListOf<HTMLSpanElement>;
    const toggleButtons = selectContainer.querySelectorAll('.js-subscriptionToggleAdvancedSelect');
    const frequencyText = selectContainer.querySelector('.js-subscriptionFrequencyText') as HTMLSpanElement;

    function toggleSelectType(): void {
        simpleSelect.classList.toggle('d-none');
        advancedSelect.classList.toggle('d-none');

        if (!simpleSelect.classList.contains('d-none')) {
            updateValue(subscriptionValue, shortSelection, null, true);
            const firstValue = shortSelection.options[0].value;
            shortSelection.value = firstValue;
            shortSelection.dispatchEvent(new window.Event('change', { bubbles: true }));
            track('frequencySelectChange', 'simple');
        } else {
            const select = advancedSelect.querySelector(
                '.js-subscriptionFrequencyGroup:not(.d-none) .js-selection-input'
            ) as HTMLSelectElement;
            updateValue(subscriptionValue, select, frequencyText, true);
            track('frequencySelectChange', 'advanced');
        }
    }

    shortSelection.addEventListener('change', (): void => {
        const val = shortSelection.value;
        if (val === 'customInterval') {
            toggleSelectType();
        } else {
            updateValue(subscriptionValue, shortSelection, frequencyText);
        }
    });

    groupSelector.addEventListener('change', (): void => {
        const val = groupSelector.value;
        frequencyGroups.forEach((frequency) => {
            const groupId = frequency.dataset.frequencyGroupId;
            frequency.classList.toggle('d-none', val !== groupId);
            if (val === groupId) {
                const select = frequency.querySelector('.js-selection-input') as HTMLSelectElement;
                updateValue(subscriptionValue, select, frequencyText);
            }
        });
    });

    frequencyGroups.forEach((frequency) => {
        const select = frequency.querySelector('.js-selection-input') as HTMLSelectElement;
        select.addEventListener('change', () => {
            updateValue(subscriptionValue, select, frequencyText);
        });
    });

    toggleButtons.forEach((button) => {
        button.addEventListener('click', toggleSelectType);
    });
}
