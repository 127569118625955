
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './menu';

        export type MenuItemJsonModel = components['schemas']['MenuItemJsonModel'];
                    export type MenuStyling = components['schemas']['MenuStyling'];
                    export type MenuSectionHeadlineStyling = components['schemas']['MenuSectionHeadlineStyling'];
                    export type MenuSectionStyling = components['schemas']['MenuSectionStyling'];
                    export type MenuItemStyling = components['schemas']['MenuItemStyling'];
                    export type FrontendCustomMenuAction = components['schemas']['FrontendCustomMenuAction'];
                    export type MenuItemCustomActionViewModel = components['schemas']['MenuItemCustomActionViewModel'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type MenuItemCustomActionWithUrlViewModel = components['schemas']['MenuItemCustomActionWithUrlViewModel'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type MenuItemLinkViewModel = components['schemas']['MenuItemLinkViewModel'];
                    export type MenuItemLoginOrMyProfileViewModel = components['schemas']['MenuItemLoginOrMyProfileViewModel'];
                    export type MyProfileMenuItemSubTextViewModel = components['schemas']['MyProfileMenuItemSubTextViewModel'];
                    export type MyProfileMenuItemSubTextViewModelMaybe = components['schemas']['MyProfileMenuItemSubTextViewModelMaybe'];
                    export type LoginLevel = components['schemas']['LoginLevel'];
                    export type CertificationCustomAction = components['schemas']['CertificationCustomAction'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type JavascriptHookCustomAction = components['schemas']['JavascriptHookCustomAction'];
                    export type LiveEventCustomAction = components['schemas']['LiveEventCustomAction'];
                    export type SurveyCustomAction = components['schemas']['SurveyCustomAction'];
                    export type VimeoCustomAction = components['schemas']['VimeoCustomAction'];
                    export type CustomActionType = components['schemas']['CustomActionType'];
                    export type LinkActionType = components['schemas']['LinkActionType'];
                    export type MenuItemMyProfileViewModel = components['schemas']['MenuItemMyProfileViewModel'];
                    export type MenuItemSubMenuViewModel = components['schemas']['MenuItemSubMenuViewModel'];
                    export type MenuItemViewModel = components['schemas']['MenuItemViewModel'];
                    export type PageActionMaybe = components['schemas']['PageActionMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type MenuSectionViewModel = components['schemas']['MenuSectionViewModel'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type MenuHeaderViewModel = components['schemas']['MenuHeaderViewModel'];
                    export type MenuViewModel = components['schemas']['MenuViewModel'];
                    export type MenuDisplayViewEnum = components['schemas']['MenuDisplayViewEnum'];
                    export type TooltipType = components['schemas']['TooltipType'];
                    export type TooltipModel = components['schemas']['TooltipModel'];
                    export type TooltipModelMaybe = components['schemas']['TooltipModelMaybe'];
                    export type MenuTooltipsViewModel = components['schemas']['MenuTooltipsViewModel'];
                    

        
        

        

        export type MegaSuccess = paths['/internal/Menu/Mega']['get']['responses']['200']['content']['text/plain'];

        export const MegaUrl = '/internal/Menu/Mega';

        
        export function Mega(): Promise<MegaSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MegaSuccess>(`/internal/Menu/Mega`)
        }
    
    
        

        

        export type FooterSuccess = paths['/internal/Menu/Footer']['get']['responses']['200']['content']['text/plain'];

        export const FooterUrl = '/internal/Menu/Footer';

        
        export function Footer(): Promise<FooterSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FooterSuccess>(`/internal/Menu/Footer`)
        }
    
    
        

        export type ListParams = paths['/internal/Menu/List']['get']['parameters']['query'];

        export type ListSuccess = paths['/internal/Menu/List']['get']['responses']['200']['content']['text/plain'];

        export const ListUrl = '/internal/Menu/List';

        
        export function List(params: ListParams): Promise<ListSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ListSuccess>(`/internal/Menu/List${formatParams(params)}`)
        }
    
    
        

        export type MenuForWebshopParams = paths['/internal/Menu/MenuForWebshop']['get']['parameters']['query'];

        export type MenuForWebshopSuccess = paths['/internal/Menu/MenuForWebshop']['get']['responses']['200']['content']['text/plain'];

        export const MenuForWebshopUrl = '/internal/Menu/MenuForWebshop';

        
        export function MenuForWebshop(params: MenuForWebshopParams): Promise<MenuForWebshopSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MenuForWebshopSuccess>(`/internal/Menu/MenuForWebshop${formatParams(params)}`)
        }
    
    
        

        export type MenuParams = paths['/internal/Menu/Menu']['get']['parameters']['query'];

        export type MenuSuccess = paths['/internal/Menu/Menu']['get']['responses']['200']['content']['text/plain'];

        export const MenuUrl = '/internal/Menu/Menu';

        
        export function Menu(params: MenuParams): Promise<MenuSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MenuSuccess>(`/internal/Menu/Menu${formatParams(params)}`)
        }
    
    
        

        

        export type MenuTooltipsViewModelSuccess = paths['/internal/ModelOnly/MenuTooltipsViewModel']['get']['responses']['200']['content']['text/plain'];

        export const MenuTooltipsViewModelUrl = '/internal/ModelOnly/MenuTooltipsViewModel';

        
        export function MenuTooltipsViewModel(): Promise<MenuTooltipsViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MenuTooltipsViewModelSuccess>(`/internal/ModelOnly/MenuTooltipsViewModel`)
        }
    
    

        export * from './discriminators';
    