import { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';

const containerFluid = css`
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    ${breakpoint.up(sizes.xl)} {
        max-width: 1230px;
    }

    ${breakpoint.up(sizes.xxl)} {
        max-width: 1400px;
    }

    ${breakpoint.up(sizes.xxxl)} {
        max-width: 1680px;
    }
`;

export default containerFluid;
