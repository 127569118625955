import serverSideTracking from 'components/shared/productItem/shared/helpers';

$(document).on('click', '[data-js-tracking-action]', event => {
    const currentTarget = $(event.currentTarget);
    const trackingInfoTarget = currentTarget.closest('[data-js-tracking-info]');

    if (trackingInfoTarget === undefined || trackingInfoTarget.length === 0) {
        return;
    }

    const eventType = currentTarget.data('js-tracking-action');
    const jsondata = trackingInfoTarget.attr('data-js-tracking-info');

    serverSideTracking(jsondata, eventType);
});
