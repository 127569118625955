import React from 'react';
import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';
import { shallowEqual } from 'react-redux';
import { QuickViewProps, QuickViewState } from '../shared/types';

export const guardRecoilDefaultValue = (candidate: any): candidate is DefaultValue => {
    if (candidate instanceof DefaultValue) return true;
    return false;
};

export interface ISlide {
    id: string;
    SlideComponent: React.FC<React.PropsWithChildren<unknown>>;
}

// eslint-disable-next-line import/prefer-default-export
export const quickViewHeaderBackgroundState = atom<boolean>({
    key: 'quickViewHeaderBackgroundState',
    default: false
});

export const quickViewSlidesState = atom<ISlide[]>({
    key: 'quickViewSlidesState',
    default: []
});

export const quickViewToggleState = atomFamily<boolean, string>({
    key: 'quickViewToggleState',
    default: false
});

export const quickViewPropsState = atomFamily<QuickViewProps, string>({
    key: 'quickViewPropsState',
    default: {}
});

export const quickViewState = selectorFamily<QuickViewState, string>({
    key: 'quickViewState',
    get:
        (quickViewId) =>
        ({ get }) => ({
            visible: get(quickViewToggleState(quickViewId)),
            props: get(quickViewPropsState(quickViewId))
        }),
    set:
        (quickViewId) =>
        ({ set, get, reset }, newValue) => {
            if (guardRecoilDefaultValue(newValue)) {
                reset(quickViewToggleState(quickViewId));
                reset(quickViewPropsState(quickViewId));
            } else {
                const prevProps = get(quickViewPropsState(quickViewId));
                if (!shallowEqual(newValue.props, prevProps)) {
                    set(quickViewPropsState(quickViewId), newValue.props);
                }

                set(quickViewToggleState(quickViewId), newValue.visible);
            }
        }
});
