import { CreatePendingSubscription, LogPosEvent } from 'autogen/swagger/pos';
import fetch from 'cross-fetch';
import { fetchJSON, getJSON, postJSON, putJSON } from 'modules/helpers/fetch';
import requestContext from 'modules/requestContext';
import type {
    BasketResponse,
    CustomerResponse,
    ReceiptResponse,
    ScriptReceipt,
    TransactionResponse
} from 'pos/shared/fiftytwo/model';
import { toast } from 'react-toastify';

const context = requestContext;

const GetBaseUrl = () => context.posRequest?.posHost + (context.posRequest?.posHost.slice(-1) === '/' ? '' : '/');
const GetReceiptId = () => context.posRequest?.receiptId;

function logFailedRequest(error: Error, url: string, logLevel: 'Warn' | 'Error' = 'Warn') {
    LogPosEvent({ logLevel, message: error.message, stackTrace: error.stack ?? '', url });
}

function logEvent(error: Error) {
    LogPosEvent({
        logLevel: 'Warn',
        message: error.message,
        stackTrace: error.stack ?? '',
        url: ''
    });
}

export async function addToReceipt(productId: string, amount: number): Promise<void> {
    const url = `${GetBaseUrl()}api/v1/Receipt/${GetReceiptId()}/Article`;

    const requestData = {
        articleId: productId,
        amount,
        tags: ['weborder']
    };
    try {
        await postJSON(url, requestData);
    } catch (error) {
        logFailedRequest(error as Error, url);
        throw error;
    }
}

export async function getReceipt(): Promise<ReceiptResponse> {
    const url = `${GetBaseUrl()}api/v3/receipt`;

    try {
        const result = await fetchJSON<ReceiptResponse>(url);

        return result;
    } catch (error) {
        logFailedRequest(error as Error, url, 'Error');
        throw error;
    }
}

export async function GetTransactionId(): Promise<TransactionResponse> {
    const url = `${GetBaseUrl()}api/v1/Scripts/GetCRTransactionID`;

    try {
        const result = await fetchJSON<TransactionResponse>(url);
        return result;
    } catch (error) {
        logFailedRequest(error as Error, url);
        throw error;
    }
}

export async function getReceiptCustomer(receiptId?: string): Promise<CustomerResponse | undefined> {
    const url = `${GetBaseUrl()}api/v1/Receipt/${receiptId || GetReceiptId()}/Customer`;

    try {
        const res = await fetch(url, getJSON);

        if (!res.ok) {
            if (res.status === 404) return undefined;

            throw new Error(`Failed to fetch JSON content from: ${url}`);
        }
        const result = (await res.json()) as CustomerResponse;

        if (result.mobilePhone) result.mobile = result.mobilePhone;
        if (result.mobile) result.mobilePhone = result.mobile;

        return result;
    } catch (error) {
        logFailedRequest(error as Error, url);
        throw error;
    }
}

export async function getReceiptFromScript(): Promise<ScriptReceipt> {
    const url = `${GetBaseUrl()}api/v1/Scripts/GetReceiptV2WithClubMatas`;

    try {
        const res = await fetch(url, getJSON);

        if (!res.ok) {
            throw new Error(`Failed to fetch JSON content from: ${url}`);
        }
        const result = await res.json();

        return result as ScriptReceipt;
    } catch (error) {
        logFailedRequest(error as Error, url, 'Error');
        throw error;
    }
}

export async function addSubscriptionToReceipt(
    productId: string,
    lineId: string,
    discountId: string,
    frequency: string
): Promise<void> {
    const url = `${GetBaseUrl()}api/v1/Scripts/Subscription`;

    const requestData = {
        articleId: productId,
        discountId,
        lineId,
        key: `subFreqId_receiptLineId${lineId}`,
        value: frequency
    };

    try {
        await postJSON(url, requestData);
        CreatePendingSubscription().then((response) => {
            const { success } = response;

            if (success) {
                toast.success<string>('Abonnement tilføjet', {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error<string>('Der skete en fejl', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        });
    } catch (error) {
        toast.error<string>('Der skete en fejl', {
            position: toast.POSITION.TOP_RIGHT
        });

        logFailedRequest(error as Error, url);
        throw error;
    }
}

export async function addProductAndSubscriptionToReceipt(
    productId: string,
    amount: number,
    discountId: string,
    frequency: string
): Promise<void> {
    try {
        await addToReceipt(productId, amount);

        const articleId = Number.parseInt(productId);

        let attempts = 0;
        let lineId = -1;

        do {
            // eslint-disable-next-line no-await-in-loop
            const receipt = await getReceipt();

            const line = receipt?.receipt?.lines?.find((x) => x.articleId === articleId);

            if (line) lineId = line.id;

            // eslint-disable-next-line no-plusplus
            attempts++;
        } while (lineId < 0 && attempts < 3);

        if (lineId < 0) {
            return;
        }

        await addSubscriptionToReceipt(productId, lineId.toString(), discountId, frequency);
    } catch (error) {
        logEvent(error as Error);
        throw error;
    }
}

export async function getBasket(): Promise<BasketResponse> {
    const url = `${GetBaseUrl()}api/v1/Basket`;

    try {
        const result = await fetchJSON<{ basket: BasketResponse }>(url);

        return result.basket as BasketResponse;
    } catch (error) {
        logFailedRequest(error as Error, url, 'Error');
        throw error;
    }
}

async function endCROrder(orderNumber: number, success: boolean): Promise<void> {
    const url = `${GetBaseUrl()}api/v1/Scripts${
        success ? `/CheckoutAccept?orderNumber=${orderNumber}` : '/CheckoutCancel'
    }`;

    try {
        await fetch(url, getJSON);
    } catch (error) {
        logFailedRequest(error as Error, url, 'Error');
        throw error;
    }
}

export async function checkoutAccept(orderNumber: number): Promise<void> {
    await endCROrder(orderNumber, true);
}

export async function addBookingTicketToReceipt(
    productId: string,
    amount: number,
    bookingKey: string,
    ticketPrice: number
): Promise<void> {
    let lineId = -1;

    try {
        await addToReceipt(productId, amount);

        lineId = await GetLastAddedLineIdOfProduct(productId);

        if (lineId < 0) {
            return;
        }
    } catch (error) {
        logEvent(error as Error);
        throw error;
    }

    let url = `${GetBaseUrl()}api/v1/Receipt/${GetReceiptId()}/Lines/${lineId}/Price`;

    try {
        await putJSON(url, { NewUnitPrice: ticketPrice, PriceIsWithoutVat: false });

        lineId = await GetLastAddedLineIdOfProduct(productId);
        if (lineId < 0) {
            return;
        }

        url = `${GetBaseUrl()}api/v1/Scripts/UpdateHHData`;

        const requestData = {
            key: `bookingKey_receiptLineId${lineId}`,
            value: bookingKey
        };

        await postJSON(url, requestData);
    } catch (error) {
        logFailedRequest(error as Error, url);
        throw error;
    }
}

async function GetLastAddedLineIdOfProduct(productId: string): Promise<number> {
    let lineId = -1;

    try {
        const articleId = Number.parseInt(productId);

        let attempts = 0;

        do {
            await new Promise((r) => setTimeout(r, 50));

            const receipt = await getReceipt();

            const line = receipt?.receipt?.lines?.findLast((x) => x.articleId === articleId);

            if (line) lineId = line.id;

            attempts++;
        } while (lineId < 0 && attempts < 3);
    } catch (error) {
        logEvent(error as Error);
        throw error;
    }
    return lineId;
}

export async function GetBookingKey(): Promise<string | undefined> {
    const scriptReceipt = await getReceiptFromScript();

    const keyValuePairs = Object.entries(scriptReceipt.KeyValueStore).filter(([key]) =>
        key.startsWith('bookingKey_receiptLineId')
    );

    if (!keyValuePairs) {
        return undefined;
    }

    const receipt = await getReceipt();

    for (const [key, value] of keyValuePairs) {
        const id = key.replace('bookingKey_receiptLineId', '');

        const line = receipt.receipt.lines?.find((x) => x.id === Number.parseInt(id));

        if (line && !line.isVoided) return value as string;
    }

    return undefined;
}
