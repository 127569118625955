import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import dynamic from 'helpers/dynamic';
import QuickView, { useQuickView } from 'components/quickView';
import UAEventButton from 'modules/react-button/uaEventButton';

const AdditionalInfo = dynamic(() => import('./AdditionalInfoDetails'), { ssr: false });

const InfoIcon = styled(dynamic(() => import('icons/Info.svg?react'), { ssr: false }))`
    flex: none;
    width: 20px;
    height: 20px;
`;

const StyledButton = styled(UAEventButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0;
`;

const StyledQuickView = styled(QuickView)`
    max-width: 520px;
`;

type IAdditionalInfoButton = HTMLAttributes<HTMLButtonElement>;

export default function AdditionalInfoButton(props: IAdditionalInfoButton) {
    const { className } = props;
    const { toggle } = useQuickView('campaignAdditionalInfo');

    function showAdditionalInfo() {
        toggle(true);
    }
    return (
        <>
            <StyledButton
                className={className}
                onClick={showAdditionalInfo}
                uaEvent={{ eventCategory: 'campaignInfo', eventAction: 'additionalInfoButton' }}
            >
                <InfoIcon />
            </StyledButton>
            <StyledQuickView quickViewId="campaignAdditionalInfo" position="right">
                <AdditionalInfo />
            </StyledQuickView>
        </>
    );
}
