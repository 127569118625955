import React, { createContext, useContext, useMemo, useRef } from 'react';

interface ISideMenuContext {
    currentPrefetchedDepth: React.MutableRefObject<number>;
    rootMenuPrefetchDepth: number;
    subMenuPrefetchDepth: number;
}

const SideMenuContext = createContext<ISideMenuContext>({} as ISideMenuContext);
/**
 *
 * @param rootMenuPrefetchDepth - Specifies how deep the initial menu prefetch is.
 * @param subMenuPrefetchDepth - Specifies how deep should be prefetched when subsequently opening sub-menus.
 * @returns
 */
export default function SideMenuProvider({ rootMenuPrefetchDepth = 2, subMenuPrefetchDepth = 1, children }) {
    /**
     *  currentPrefetchedDepth - How deep has been prefetched so far.
     *  This value is stored in a useRef, since no rerender is needed when updating this value
     */
    const currentPrefetchedDepth = useRef(rootMenuPrefetchDepth);

    const value = useMemo(
        () => ({
            currentPrefetchedDepth,
            rootMenuPrefetchDepth,
            subMenuPrefetchDepth
        }),
        [rootMenuPrefetchDepth, subMenuPrefetchDepth]
    );

    return <SideMenuContext.Provider value={value}>{children}</SideMenuContext.Provider>;
}

export function useSideMenu() {
    return useContext(SideMenuContext);
}
