export const TOGGLE_BACK_VISIBLE = 'TOGGLE_BACK_VISIBLE';
export const SET_CONTENT_TYPE = 'SET_CONTENT_TYPE';

export function setBackVisible(bool) {
    return {
        type: TOGGLE_BACK_VISIBLE,
        bool
    };
}

export function setContentType(contentType) {
    return {
        type: SET_CONTENT_TYPE,
        contentType
    };
}
