import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'modules/breakpoint';
import MainContext from 'components/QuickSearch/components/context';
import Header from './header';
import { layoutEnum, listTypeEnum } from '../../enums';
import LinkList, { ArticleList, BrandList, ProductList, FeaturedBrandList } from '../../layouts/list/index';

export const DefaultSection = styled.div`
    ${(props) => props.layout !== layoutEnum.tab && 'border-top: 2px solid #000;'}
    ${(props) => props.layout === layoutEnum.row && 'flex: 0 0 auto;'}
    ${(props) =>
        props.layout === layoutEnum.column &&
        css`
            flex: 1 0 50%;
            @media (min-width: ${breakpoint.sizes.xl}px) {
                flex: 1 0 auto;

                & + & {
                    flex: 0 0 auto;
                }
            }
        `}
    ${(props) =>
        props.layout === layoutEnum.tab &&
        css`
            & + & {
                border-top: 2px solid #000;
            }
        `}
`;

const renderListItems = (obj, listType, trackingAction) => {
    const { items, hitsTotal, brands, featuredBrands, actualSearchText, linkAction } = obj;

    switch (listType) {
        case listTypeEnum.link:
            return <LinkList items={items} trackingAction={trackingAction} searchTerm={actualSearchText} />;
        case listTypeEnum.article:
            return <ArticleList items={items} searchTerm={actualSearchText} />;
        case listTypeEnum.product:
            return (
                <ProductList
                    items={items}
                    hitsTotal={hitsTotal}
                    linkAction={linkAction}
                    actualSearchText={actualSearchText}
                />
            );
        case listTypeEnum.brand:
            return <BrandList items={items} searchTerm={actualSearchText} />;
        case listTypeEnum.featuredBrand:
            return (
                <FeaturedBrandList
                    brands={brands}
                    featuredBrands={featuredBrands}
                    searchTerm={featuredBrands.actualSearchText}
                />
            );
        default:
            return null;
    }
};

export default (props) => {
    const { items: obj, trackingAction } = props;
    const { hitsTotal, items, linkAction } = obj;

    const hitsShown = items ? items.length : 0;

    const {
        headline,
        captionText,
        listType,
        buttonType,
        styles: { Section = DefaultSection } = {},
        sectionCoordsCallback
    } = props;

    const mainContext = useContext(MainContext);
    const { layout } = mainContext;
    const buttonLinkAction = linkAction;
    const sectionRef = React.createRef();

    useEffect(() => {
        if (typeof sectionCoordsCallback !== 'function') return;
        sectionCoordsCallback(sectionRef.current.getBoundingClientRect());
    }, [items]);

    return (
        <Section layout={layout} ref={sectionRef}>
            {(headline || (buttonType && buttonLinkAction)) && (
                <Header
                    headline={headline}
                    buttonType={buttonType}
                    buttonLinkAction={buttonLinkAction}
                    hitsShown={hitsShown}
                    hitsTotal={hitsTotal}
                    listType={listType}
                    captionText={captionText}
                />
            )}
            {renderListItems(obj, listType, trackingAction)}
        </Section>
    );
};
