import React from 'react';
import { SessionMenuAnchorItem } from 'components/SessionMenu/components/SessionMenuItem';
import { useTranslation } from 'react-i18next';
import { Basket as BasketTrans } from 'autogen/translation-keys/trans-website-minibasket';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';
import BasketIcon from 'icons/Basket.svg?react';
import dynamic from 'helpers/dynamic';
import MiniBasket from 'components/MiniBasket';
import Tippy from 'components/Tippy';
import { BorderRadiusSmall, ColorNeutralWhite } from 'autogen/design-tokens/tokens';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { HeaderItemText } from './shared/styles';
import type { LinkAction } from 'components/shared/Routing/components/LinkAction/shared/types';
import { useSetRecoilState } from 'recoil';
import { miniBasketActiveAtom } from 'components/MiniBasket/recoil/miniBasketAtoms';

const BasketQuantityBadge = dynamic(() => import('components/generic/badge/badges/BasketQuantityBadge'), {
    ssr: false
});

/* #region styles */
const StyledTippy = styled(Tippy.Lazy)`
    && {
        background-color: ${ColorNeutralWhite};
        color: initial;
        line-height: initial;
        outline: initial;
        border-radius: ${BorderRadiusSmall};
        width: 496px;

        .tippy-content {
            padding: initial;
            border: none;
            border-radius: ${BorderRadiusSmall};
        }
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);

        &[data-placement^='top'] > .tippy-arrow::before {
            bottom: -8px;
            border-top-color: ${ColorNeutralWhite};
            border-width: 8px 8px 0;
        }

        &[data-placement^='bottom'] > .tippy-arrow::before {
            top: -8px;
            border-bottom-color: ${ColorNeutralWhite};
            border-width: 0 8px 8px;
        }

        &[data-placement^='left'] > .tippy-arrow::before {
            right: -8px;
            border-left-color: ${ColorNeutralWhite};
            border-width: 8px 0 8px 8px;
        }

        &[data-placement^='right'] > .tippy-arrow::before {
            left: -8px;
            border-right-color: ${ColorNeutralWhite};
            border-width: 8px 8px 8px 0;
        }
    }
`;

const StyledBasketQuantityBadge = styled(BasketQuantityBadge)`
    position: absolute;
    top: -6px;
    right: -2px;

    ${breakpoint.up(sizes.lg)} {
        right: 4px;
    }
`;
/* #endregion */

export default function Basket({ linkAction, ...restProps }: { linkAction: LinkAction }) {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const setIsMiniBasketActive = useSetRecoilState(miniBasketActiveAtom);

    return (
        <StyledTippy
            disabled={isMobile}
            interactive
            content={<MiniBasket />}
            maxWidth={496}
            delay={[null, 250]}
            placement="bottom-end"
            onShow={() => {
                setIsMiniBasketActive(true);
            }}
            onHide={() => {
                setIsMiniBasketActive(false);
            }}
        >
            <SessionMenuAnchorItem action={linkAction} {...restProps} icon={<BasketIcon aria-hidden />}>
                <StyledBasketQuantityBadge />
                <HeaderItemText>{t(BasketTrans)}</HeaderItemText>
            </SessionMenuAnchorItem>
        </StyledTippy>
    );
}
