import React, { useContext } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { PictureDeprecated } from 'components/generic/picture';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import MainContext from 'components/QuickSearch/components/context';
import { GA4Tracking } from 'modules/tracking/GA4';
import { layoutEnum, listTypeEnum } from '../../enums';
import ResultSection from '../../components/resultSection/resultSection';
import LinkAction from 'components/shared/Routing/components/LinkAction';

const List = styled.ul`
    padding: 15px 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
`;

const ListItem = styled.li`
    list-style: none;
    flex: 0 0 50%;
    width: 50%;
    ${(props) => props.layout === layoutEnum.column && 'display: flex;'}
    ${(props) => props.layout === layoutEnum.column && 'justify-content: center;'}
`;

const itemHover = keyframes` 
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const LinkHoverBeforeStyling = css`
    content: '';
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: #fff;
    border: 1px solid #b8b8b8;
    -webkit-animation-name: ${itemHover};
    animation-name: ${itemHover};
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
`;

const Link = styled(LinkAction)`
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.layout === layoutEnum.tab ? 'flex-start' : 'center')};
    height: 100%;
    position: relative;
    ${(props) =>
        props.isActive &&
        css`
            &:before {
                ${LinkHoverBeforeStyling}
            }
        `};
`;

const StyledPicture = styled(PictureDeprecated)`
    img {
        max-height: 50px;
        max-width: 133px;
        flex: none;
        display: block;
    }
`;

export default (props) => {
    const { brands, featuredBrands, searchTerm } = props;
    const hasFeaturedBrands = featuredBrands && featuredBrands.items.length > 0;
    const hasBrands = brands && brands.items.length > 0;

    const mainContext = useContext(MainContext);
    const { layout, onItemHover } = mainContext;

    return (
        <>
            {hasFeaturedBrands && (
                <List>
                    {featuredBrands.items.map((obj, index) => {
                        const {
                            image: { image },
                            isActive,
                            linkAction
                        } = obj;

                        const pathName = linkAction.url.split('/').pop().split('?')[0];

                        return (
                            <ListItem key={index} onMouseEnter={() => onItemHover(obj)}>
                                <Link
                                    action={linkAction}
                                    layout={layout}
                                    isActive={isActive}
                                    onClick={() => {
                                        UAEventTracking({
                                            eventCategory: 'quickSearch',
                                            eventAction: 'brandFeaturedClick',
                                            eventLabel: pathName
                                        });

                                        GA4Tracking({
                                            eventName: 'featured_brand_click',
                                            context: 'search',
                                            category: 'ecommerce',
                                            eventParams: [
                                                { param_name: 'search_type', param_value: 'quick_search' },
                                                { param_name: 'search_term', param_value: searchTerm }
                                            ]
                                        });
                                    }}
                                >
                                    <StyledPicture {...image} layout={layout} />
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
            )}
            {layout === layoutEnum.tab && hasBrands && <ResultSection items={brands} listType={listTypeEnum.brand} />}
        </>
    );
};
