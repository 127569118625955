import { ColorNeutralDarkGrey } from 'autogen/design-tokens/tokens';
import { Typography } from 'components/generic/typography';
import { verticalEllipsis } from 'modules/helpers/mixins';
import styled from 'styled-components';

const Brand = styled(Typography.Overline)<{ maxVerticalLines?: number }>`
    ${({ maxVerticalLines }) => verticalEllipsis(maxVerticalLines || 1)}
    ${({ theme }) => theme.BrandTitle}
`;

const Product = styled(Typography.Body)<{ maxVerticalLines?: number }>`
    ${({ maxVerticalLines }) => verticalEllipsis(maxVerticalLines || 2)}
    color: ${ColorNeutralDarkGrey};
    ${({ theme }) => theme.ProductTitle}
`;

const Variant = styled(Typography.Body)<{ maxVerticalLines?: number }>`
    ${({ maxVerticalLines }) => verticalEllipsis(maxVerticalLines || 2)}
    color: ${ColorNeutralDarkGrey};
    ${({ theme }) => theme.VariantTitle}
`;

const MoreVariantsContainer = styled(Typography.Body)<{ maxVerticalLines?: number }>`
    display: flex;
    color: ${ColorNeutralDarkGrey};
    max-width: 100%;
    ${({ theme }) => theme.VariantTitle};
`;

const Ellipsis = styled.span`
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MoreVariants = styled.span`
    flex-shrink: 0;
    white-space: nowrap;
`;

const Miscellaneous = styled(Typography.Body)<{ maxVerticalLines?: number }>`
    ${({ maxVerticalLines }) => verticalEllipsis(maxVerticalLines || 2)}
    ${({ theme }) => theme.ProductMiscellaneousTitle}
`;

export { Brand, Product, Variant, MoreVariantsContainer, Ellipsis, MoreVariants, Miscellaneous };
