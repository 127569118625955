import { programmaticScroll } from 'modules/helpers/scroll';
import breakpoint from './breakpoint';

function scrollToAnchor(href, offset) {
    requestAnimationFrame(() => {
        const offsetTop = document.querySelector(href).getBoundingClientRect().top + window.scrollY - offset;
        programmaticScroll(() => {
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        });
    });
}

export default function anchorScroll() {
    $(document).on('click', '[data-js="anchorlink"], [data-cke-saved-href^="#"], .js-anchorLink', e => {
        e.preventDefault();

        const target = e.currentTarget;
        const href = target.getAttribute('href') || target.dataset.ckeSavedHref;
        let stickyHeaderHeight = breakpoint.max('lg') || $('.brand-list').length ? 120 : 115;
        if (breakpoint.min('xl') && $('.brand-list').length) {
            const hasFixedHeader = document.querySelector('body').classList.contains('state--scroll-up');
            stickyHeaderHeight = hasFixedHeader ? 180 : 65;
        }
        if (href && href !== '#') {
            scrollToAnchor(href, stickyHeaderHeight);
        }
    });
}
