
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedGlobalSymbols
import type {components, paths} from './all';

export function LinkActionDiscriminator<T>(
    model: components['schemas']['BasketLineWrappingUpdateViewModel']['linkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}
export function BoxButtonDiscriminator<T>(
    model: components['schemas']['BasketInfoBox']['buttons'][0],
    funcBoxButton: (m: components['schemas']['BoxButton']) => T,
    funcBoxButtonWithCreditCardOption: (m: components['schemas']['BoxButtonWithCreditCardOption']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'BoxButton': return funcBoxButton(model as components['schemas']['BoxButton']);
        case 'BoxButtonWithCreditCardOption': return funcBoxButtonWithCreditCardOption(model as components['schemas']['BoxButtonWithCreditCardOption']);

        default:
            return defaultFunc();
    }
}
export function BasketMessageDiscriminator<T>(
    model: components['schemas']['BasketMessageWrapperMaybe']['basketMessage'],
    funcBasketMessageDiscountViewModel: (m: components['schemas']['BasketMessageDiscountViewModel']) => T,
    funcBasketMessageGwpViewModel: (m: components['schemas']['BasketMessageGwpViewModel']) => T,
    funcBasketMessageMatasPlusViewModel: (m: components['schemas']['BasketMessageMatasPlusViewModel']) => T,
    funcBasketMessageSameDayDeliveryViewModel: (m: components['schemas']['BasketMessageSameDayDeliveryViewModel']) => T,
    funcBasketMessageSampleGroupViewModel: (m: components['schemas']['BasketMessageSampleGroupViewModel']) => T,
    funcBasketMessageShippingViewModel: (m: components['schemas']['BasketMessageShippingViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'BasketMessageDiscountViewModel': return funcBasketMessageDiscountViewModel(model as components['schemas']['BasketMessageDiscountViewModel']);
        case 'BasketMessageGwpViewModel': return funcBasketMessageGwpViewModel(model as components['schemas']['BasketMessageGwpViewModel']);
        case 'BasketMessageMatasPlusViewModel': return funcBasketMessageMatasPlusViewModel(model as components['schemas']['BasketMessageMatasPlusViewModel']);
        case 'BasketMessageSameDayDeliveryViewModel': return funcBasketMessageSameDayDeliveryViewModel(model as components['schemas']['BasketMessageSameDayDeliveryViewModel']);
        case 'BasketMessageSampleGroupViewModel': return funcBasketMessageSampleGroupViewModel(model as components['schemas']['BasketMessageSampleGroupViewModel']);
        case 'BasketMessageShippingViewModel': return funcBasketMessageShippingViewModel(model as components['schemas']['BasketMessageShippingViewModel']);

        default:
            return defaultFunc();
    }
}
export function EcommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}
export function DataLayerTrackingEventDiscriminator<T>(
    model: components['schemas']['QuickSearchViewModel']['dataLayerEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}
export function CustomActionDiscriminator<T>(
    model: components['schemas']['CustomActionType']['action'],
    funcCertificationCustomAction: (m: components['schemas']['CertificationCustomAction']) => T,
    funcJavascriptHookCustomAction: (m: components['schemas']['JavascriptHookCustomAction']) => T,
    funcLiveEventCustomAction: (m: components['schemas']['LiveEventCustomAction']) => T,
    funcSurveyCustomAction: (m: components['schemas']['SurveyCustomAction']) => T,
    funcVimeoCustomAction: (m: components['schemas']['VimeoCustomAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CertificationCustomAction': return funcCertificationCustomAction(model as components['schemas']['CertificationCustomAction']);
        case 'JavascriptHookCustomAction': return funcJavascriptHookCustomAction(model as components['schemas']['JavascriptHookCustomAction']);
        case 'LiveEventCustomAction': return funcLiveEventCustomAction(model as components['schemas']['LiveEventCustomAction']);
        case 'SurveyCustomAction': return funcSurveyCustomAction(model as components['schemas']['SurveyCustomAction']);
        case 'VimeoCustomAction': return funcVimeoCustomAction(model as components['schemas']['VimeoCustomAction']);

        default:
            return defaultFunc();
    }
}
export function CustomListItemDiscriminator<T>(
    model: components['schemas']['ListRowItem']['item'],
    funcConnectedContentFullwidthModel: (m: components['schemas']['ConnectedContentFullwidthModel']) => T,
    funcConnectedContentInlineModel: (m: components['schemas']['ConnectedContentInlineModel']) => T,
    funcConnectedContentInventoryLaneBlockModel: (m: components['schemas']['ConnectedContentInventoryLaneBlockModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    funcConnectedContentProductDisplayModel: (m: components['schemas']['ConnectedContentProductDisplayModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ConnectedContentFullwidthModel': return funcConnectedContentFullwidthModel(model as components['schemas']['ConnectedContentFullwidthModel']);
        case 'ConnectedContentInlineModel': return funcConnectedContentInlineModel(model as components['schemas']['ConnectedContentInlineModel']);
        case 'ConnectedContentInventoryLaneBlockModel': return funcConnectedContentInventoryLaneBlockModel(model as components['schemas']['ConnectedContentInventoryLaneBlockModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);
        case 'ConnectedContentProductDisplayModel': return funcConnectedContentProductDisplayModel(model as components['schemas']['ConnectedContentProductDisplayModel']);

        default:
            return defaultFunc();
    }
}
export function ListItemDiscriminator<T>(
    model: paths['/internal/ProductList/ProductListItems']['get']['responses']['200']['content']['text/plain'][0],
    funcListItem: (m: components['schemas']['ListItem']) => T,
    funcListRowItem: (m: components['schemas']['ListRowItem']) => T,
    funcProductListItemModel: (m: components['schemas']['ProductListItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ListItem': return funcListItem(model as components['schemas']['ListItem']);
        case 'ListRowItem': return funcListRowItem(model as components['schemas']['ListRowItem']);
        case 'ProductListItemModel': return funcProductListItemModel(model as components['schemas']['ProductListItemModel']);

        default:
            return defaultFunc();
    }
}
export function ActiveCardBaseDiscriminator<T>(
    model: components['schemas']['ActiveCardListItem']['card'],
    funcAmountBasedActiveCard: (m: components['schemas']['AmountBasedActiveCard']) => T,
    funcItemBasedActiveCard: (m: components['schemas']['ItemBasedActiveCard']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AmountBasedActiveCard': return funcAmountBasedActiveCard(model as components['schemas']['AmountBasedActiveCard']);
        case 'ItemBasedActiveCard': return funcItemBasedActiveCard(model as components['schemas']['ItemBasedActiveCard']);

        default:
            return defaultFunc();
    }
}
export function CardListItemBaseDiscriminator<T>(
    model: components['schemas']['ActiveBrandClubListModel']['items'][0],
    funcActiveCardListItem: (m: components['schemas']['ActiveCardListItem']) => T,
    funcBonusGiftListItem: (m: components['schemas']['BonusGiftListItem']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ActiveCardListItem': return funcActiveCardListItem(model as components['schemas']['ActiveCardListItem']);
        case 'BonusGiftListItem': return funcBonusGiftListItem(model as components['schemas']['BonusGiftListItem']);

        default:
            return defaultFunc();
    }
}
export function DestinationDiscriminator<T>(
    model: components['schemas']['SelectedDeliveryMethod']['destination'],
    funcCompanyAddress: (m: components['schemas']['CompanyAddress']) => T,
    funcParcelShop: (m: components['schemas']['ParcelShop']) => T,
    funcPrivateAddress: (m: components['schemas']['PrivateAddress']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CompanyAddress': return funcCompanyAddress(model as components['schemas']['CompanyAddress']);
        case 'ParcelShop': return funcParcelShop(model as components['schemas']['ParcelShop']);
        case 'PrivateAddress': return funcPrivateAddress(model as components['schemas']['PrivateAddress']);

        default:
            return defaultFunc();
    }
}
export function DeliveryPriceDiscriminator<T>(
    model: components['schemas']['DeliveryParcelShopOption']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}
export function DeliveryMethodDiscriminator<T>(
    model: components['schemas']['SimpleDeliveryGroup']['highlightedDeliveryMethods'][0],
    funcUpsaleDeliveryMethod: (m: components['schemas']['UpsaleDeliveryMethod']) => T,
    funcDeliveryMethod: (m: components['schemas']['DeliveryMethod']) => T,
    funcSelectedDeliveryMethod: (m: components['schemas']['SelectedDeliveryMethod']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'UpsaleDeliveryMethod': return funcUpsaleDeliveryMethod(model as components['schemas']['UpsaleDeliveryMethod']);
        case 'DeliveryMethod': return funcDeliveryMethod(model as components['schemas']['DeliveryMethod']);
        case 'SelectedDeliveryMethod': return funcSelectedDeliveryMethod(model as components['schemas']['SelectedDeliveryMethod']);

        default:
            return defaultFunc();
    }
}
export function DeliveryMethodCategoryDiscriminator<T>(
    model: components['schemas']['SimpleDeliveryGroup']['deliveryMethodCategories'][0],
    funcDeliveryMethodCategory: (m: components['schemas']['DeliveryMethodCategory']) => T,
    funcSelectedDeliveryMethod: (m: components['schemas']['SelectedDeliveryMethod']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'DeliveryMethodCategory': return funcDeliveryMethodCategory(model as components['schemas']['DeliveryMethodCategory']);
        case 'SelectedDeliveryMethod': return funcSelectedDeliveryMethod(model as components['schemas']['SelectedDeliveryMethod']);

        default:
            return defaultFunc();
    }
}
export function DeliveryMessageDiscriminator<T>(
    model: components['schemas']['DeliveryGroup']['deliveryMessages'][0],
    funcFixedDeliveryMessage: (m: components['schemas']['FixedDeliveryMessage']) => T,
    funcFreeDeliveryWithClubMatasMessage: (m: components['schemas']['FreeDeliveryWithClubMatasMessage']) => T,
    funcSubscriptionsMessage: (m: components['schemas']['SubscriptionsMessage']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'FixedDeliveryMessage': return funcFixedDeliveryMessage(model as components['schemas']['FixedDeliveryMessage']);
        case 'FreeDeliveryWithClubMatasMessage': return funcFreeDeliveryWithClubMatasMessage(model as components['schemas']['FreeDeliveryWithClubMatasMessage']);
        case 'SubscriptionsMessage': return funcSubscriptionsMessage(model as components['schemas']['SubscriptionsMessage']);

        default:
            return defaultFunc();
    }
}
export function ProductMediaContentDiscriminator<T>(
    model: components['schemas']['ProductInformationViewModel']['productMedia'][0],
    funcProductVideoModel: (m: components['schemas']['ProductVideoModel']) => T,
    funcProduct360Directory: (m: components['schemas']['Product360Directory']) => T,
    funcProductImage: (m: components['schemas']['ProductImage']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductVideoModel': return funcProductVideoModel(model as components['schemas']['ProductVideoModel']);
        case 'Product360Directory': return funcProduct360Directory(model as components['schemas']['Product360Directory']);
        case 'ProductImage': return funcProductImage(model as components['schemas']['ProductImage']);

        default:
            return defaultFunc();
    }
}
export function FrontPageSectionContentDiscriminator<T>(
    model: components['schemas']['FrontPageSectionMaybe']['content'],
    funcMatasPlusCalculatorBannerModel: (m: components['schemas']['MatasPlusCalculatorBannerModel']) => T,
    funcMatasPlusSavingsBannerModel: (m: components['schemas']['MatasPlusSavingsBannerModel']) => T,
    funcBannerModel: (m: components['schemas']['BannerModel']) => T,
    funcBrandClubListModel: (m: components['schemas']['BrandClubListModel']) => T,
    funcFavoritesModel: (m: components['schemas']['FavoritesModel']) => T,
    funcHeaderModel: (m: components['schemas']['HeaderModel']) => T,
    funcImageModel: (m: components['schemas']['ImageModel']) => T,
    funcOrdersModel: (m: components['schemas']['OrdersModel']) => T,
    funcPersonalOffersModel: (m: components['schemas']['PersonalOffersModel']) => T,
    funcProductListModel: (m: components['schemas']['ProductListModel']) => T,
    funcRecommendationsModel: (m: components['schemas']['RecommendationsModel']) => T,
    funcReviewRequestsModel: (m: components['schemas']['ReviewRequestsModel']) => T,
    funcSubscriptionsModel: (m: components['schemas']['SubscriptionsModel']) => T,
    funcUnboxingOffersModel: (m: components['schemas']['UnboxingOffersModel']) => T,
    funcMatasPlusNudgeMessage: (m: components['schemas']['MatasPlusNudgeMessage']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'MatasPlusCalculatorBannerModel': return funcMatasPlusCalculatorBannerModel(model as components['schemas']['MatasPlusCalculatorBannerModel']);
        case 'MatasPlusSavingsBannerModel': return funcMatasPlusSavingsBannerModel(model as components['schemas']['MatasPlusSavingsBannerModel']);
        case 'BannerModel': return funcBannerModel(model as components['schemas']['BannerModel']);
        case 'BrandClubListModel': return funcBrandClubListModel(model as components['schemas']['BrandClubListModel']);
        case 'FavoritesModel': return funcFavoritesModel(model as components['schemas']['FavoritesModel']);
        case 'HeaderModel': return funcHeaderModel(model as components['schemas']['HeaderModel']);
        case 'ImageModel': return funcImageModel(model as components['schemas']['ImageModel']);
        case 'OrdersModel': return funcOrdersModel(model as components['schemas']['OrdersModel']);
        case 'PersonalOffersModel': return funcPersonalOffersModel(model as components['schemas']['PersonalOffersModel']);
        case 'ProductListModel': return funcProductListModel(model as components['schemas']['ProductListModel']);
        case 'RecommendationsModel': return funcRecommendationsModel(model as components['schemas']['RecommendationsModel']);
        case 'ReviewRequestsModel': return funcReviewRequestsModel(model as components['schemas']['ReviewRequestsModel']);
        case 'SubscriptionsModel': return funcSubscriptionsModel(model as components['schemas']['SubscriptionsModel']);
        case 'UnboxingOffersModel': return funcUnboxingOffersModel(model as components['schemas']['UnboxingOffersModel']);
        case 'MatasPlusNudgeMessage': return funcMatasPlusNudgeMessage(model as components['schemas']['MatasPlusNudgeMessage']);

        default:
            return defaultFunc();
    }
}
export function PageActionDiscriminator<T>(
    model: paths['/internal/ModelOnly/PageActionModel']['get']['responses']['200']['content']['text/plain'],
    funcCustomActionType: (m: components['schemas']['CustomActionType']) => T,
    funcLinkActionType: (m: components['schemas']['LinkActionType']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CustomActionType': return funcCustomActionType(model as components['schemas']['CustomActionType']);
        case 'LinkActionType': return funcLinkActionType(model as components['schemas']['LinkActionType']);

        default:
            return defaultFunc();
    }
}
export function LiveEventListBlockViewModelDiscriminator<T>(
    model: components['schemas']['LiveEventFilteredResultViewModelMaybe']['results'][0],
    funcLiveEventBlockViewModel: (m: components['schemas']['LiveEventBlockViewModel']) => T,
    funcSubscribeToNotificationsBlockViewModel: (m: components['schemas']['SubscribeToNotificationsBlockViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'LiveEventBlockViewModel': return funcLiveEventBlockViewModel(model as components['schemas']['LiveEventBlockViewModel']);
        case 'SubscribeToNotificationsBlockViewModel': return funcSubscribeToNotificationsBlockViewModel(model as components['schemas']['SubscribeToNotificationsBlockViewModel']);

        default:
            return defaultFunc();
    }
}
export function MenuItemContentViewModelDiscriminator<T>(
    model: components['schemas']['MenuItemViewModel']['content'],
    funcMenuItemCustomActionViewModel: (m: components['schemas']['MenuItemCustomActionViewModel']) => T,
    funcMenuItemCustomActionWithUrlViewModel: (m: components['schemas']['MenuItemCustomActionWithUrlViewModel']) => T,
    funcMenuItemLinkViewModel: (m: components['schemas']['MenuItemLinkViewModel']) => T,
    funcMenuItemLoginOrMyProfileViewModel: (m: components['schemas']['MenuItemLoginOrMyProfileViewModel']) => T,
    funcMenuItemMyProfileViewModel: (m: components['schemas']['MenuItemMyProfileViewModel']) => T,
    funcMenuItemSubMenuViewModel: (m: components['schemas']['MenuItemSubMenuViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'MenuItemCustomActionViewModel': return funcMenuItemCustomActionViewModel(model as components['schemas']['MenuItemCustomActionViewModel']);
        case 'MenuItemCustomActionWithUrlViewModel': return funcMenuItemCustomActionWithUrlViewModel(model as components['schemas']['MenuItemCustomActionWithUrlViewModel']);
        case 'MenuItemLinkViewModel': return funcMenuItemLinkViewModel(model as components['schemas']['MenuItemLinkViewModel']);
        case 'MenuItemLoginOrMyProfileViewModel': return funcMenuItemLoginOrMyProfileViewModel(model as components['schemas']['MenuItemLoginOrMyProfileViewModel']);
        case 'MenuItemMyProfileViewModel': return funcMenuItemMyProfileViewModel(model as components['schemas']['MenuItemMyProfileViewModel']);
        case 'MenuItemSubMenuViewModel': return funcMenuItemSubMenuViewModel(model as components['schemas']['MenuItemSubMenuViewModel']);

        default:
            return defaultFunc();
    }
}
export function MenuItemViewModelDiscriminator<T>(
    model: components['schemas']['MenuSectionViewModel']['items'][0],
    funcMenuItemViewModel: (m: components['schemas']['MenuItemViewModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'MenuItemViewModel': return funcMenuItemViewModel(model as components['schemas']['MenuItemViewModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);

        default:
            return defaultFunc();
    }
}
export function CmsButtonModelDiscriminator<T>(
    model: paths['/internal/ModelOnly/CmsButtonModel']['get']['responses']['200']['content']['text/plain'],
    funcAdformButtonModel: (m: components['schemas']['AdformButtonModel']) => T,
    funcLinkButtonModel: (m: components['schemas']['LinkButtonModel']) => T,
    funcSignupButtonModel: (m: components['schemas']['SignupButtonModel']) => T,
    funcLoginButtonModel: (m: components['schemas']['LoginButtonModel']) => T,
    funcNoButtonModel: (m: components['schemas']['NoButtonModel']) => T,
    funcCustomButtonModel: (m: components['schemas']['CustomButtonModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdformButtonModel': return funcAdformButtonModel(model as components['schemas']['AdformButtonModel']);
        case 'LinkButtonModel': return funcLinkButtonModel(model as components['schemas']['LinkButtonModel']);
        case 'SignupButtonModel': return funcSignupButtonModel(model as components['schemas']['SignupButtonModel']);
        case 'LoginButtonModel': return funcLoginButtonModel(model as components['schemas']['LoginButtonModel']);
        case 'NoButtonModel': return funcNoButtonModel(model as components['schemas']['NoButtonModel']);
        case 'CustomButtonModel': return funcCustomButtonModel(model as components['schemas']['CustomButtonModel']);

        default:
            return defaultFunc();
    }
}
export function BasketInfoBoxDiscriminator<T>(
    model: components['schemas']['ReceiptOrderDetailsViewModel']['infoBoxes'][0],
    funcCheckoutGiftCardInfo: (m: components['schemas']['CheckoutGiftCardInfo']) => T,
    funcCheckoutPaymentTypeList: (m: components['schemas']['CheckoutPaymentTypeList']) => T,
    funcBasketInfoBox: (m: components['schemas']['BasketInfoBox']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CheckoutGiftCardInfo': return funcCheckoutGiftCardInfo(model as components['schemas']['CheckoutGiftCardInfo']);
        case 'CheckoutPaymentTypeList': return funcCheckoutPaymentTypeList(model as components['schemas']['CheckoutPaymentTypeList']);
        case 'BasketInfoBox': return funcBasketInfoBox(model as components['schemas']['BasketInfoBox']);

        default:
            return defaultFunc();
    }
}
export function PointInfoModelDiscriminator<T>(
    model: components['schemas']['PointInfoViewModelMaybe']['pointInfo'],
    funcStandardPointInfoModel: (m: components['schemas']['StandardPointInfoModel']) => T,
    funcMatasPlusPointInfoModel: (m: components['schemas']['MatasPlusPointInfoModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'StandardPointInfoModel': return funcStandardPointInfoModel(model as components['schemas']['StandardPointInfoModel']);
        case 'MatasPlusPointInfoModel': return funcMatasPlusPointInfoModel(model as components['schemas']['MatasPlusPointInfoModel']);

        default:
            return defaultFunc();
    }
}
export function ProductTabContentDiscriminator<T>(
    model: components['schemas']['ProductTabModel']['content'],
    funcProductTabBlockListModel: (m: components['schemas']['ProductTabBlockListModel']) => T,
    funcProductTabContentBrandTeaserModel: (m: components['schemas']['ProductTabContentBrandTeaserModel']) => T,
    funcProductTabDeliveryAndReturnModel: (m: components['schemas']['ProductTabDeliveryAndReturnModel']) => T,
    funcProductTabDescriptionModel: (m: components['schemas']['ProductTabDescriptionModel']) => T,
    funcProductTabHighlightsModel: (m: components['schemas']['ProductTabHighlightsModel']) => T,
    funcProductTabIconListModel: (m: components['schemas']['ProductTabIconListModel']) => T,
    funcProductTabPropertyListModel: (m: components['schemas']['ProductTabPropertyListModel']) => T,
    funcProductTabTableListModel: (m: components['schemas']['ProductTabTableListModel']) => T,
    funcProductTabTableModel: (m: components['schemas']['ProductTabTableModel']) => T,
    funcProductTabUsageModel: (m: components['schemas']['ProductTabUsageModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductTabBlockListModel': return funcProductTabBlockListModel(model as components['schemas']['ProductTabBlockListModel']);
        case 'ProductTabContentBrandTeaserModel': return funcProductTabContentBrandTeaserModel(model as components['schemas']['ProductTabContentBrandTeaserModel']);
        case 'ProductTabDeliveryAndReturnModel': return funcProductTabDeliveryAndReturnModel(model as components['schemas']['ProductTabDeliveryAndReturnModel']);
        case 'ProductTabDescriptionModel': return funcProductTabDescriptionModel(model as components['schemas']['ProductTabDescriptionModel']);
        case 'ProductTabHighlightsModel': return funcProductTabHighlightsModel(model as components['schemas']['ProductTabHighlightsModel']);
        case 'ProductTabIconListModel': return funcProductTabIconListModel(model as components['schemas']['ProductTabIconListModel']);
        case 'ProductTabPropertyListModel': return funcProductTabPropertyListModel(model as components['schemas']['ProductTabPropertyListModel']);
        case 'ProductTabTableListModel': return funcProductTabTableListModel(model as components['schemas']['ProductTabTableListModel']);
        case 'ProductTabTableModel': return funcProductTabTableModel(model as components['schemas']['ProductTabTableModel']);
        case 'ProductTabUsageModel': return funcProductTabUsageModel(model as components['schemas']['ProductTabUsageModel']);

        default:
            return defaultFunc();
    }
}
export function MediaContentDiscriminator<T>(
    model: components['schemas']['ProductInformationViewModel']['customMediaContent'][0],
    funcImageContent: (m: components['schemas']['ImageContent']) => T,
    funcVideoContent: (m: components['schemas']['VideoContent']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ImageContent': return funcImageContent(model as components['schemas']['ImageContent']);
        case 'VideoContent': return funcVideoContent(model as components['schemas']['VideoContent']);

        default:
            return defaultFunc();
    }
}
export function RecommendationRequestDiscriminator<T>(
    model: paths['/internal/Recommendation/RecommendationRequestModel']['get']['responses']['200']['content']['text/plain'],
    funcProductRecommendationRequest: (m: components['schemas']['ProductRecommendationRequest']) => T,
    funcBasketRecommendationRequest: (m: components['schemas']['BasketRecommendationRequest']) => T,
    funcProductGroupRecommendationRequest: (m: components['schemas']['ProductGroupRecommendationRequest']) => T,
    funcSearchResultRecommendationRequest: (m: components['schemas']['SearchResultRecommendationRequest']) => T,
    funcZeroSearchResultRecommendationRequest: (m: components['schemas']['ZeroSearchResultRecommendationRequest']) => T,
    funcHomePageRecommendationRequest: (m: components['schemas']['HomePageRecommendationRequest']) => T,
    funcPowerstepRecommendationRequest: (m: components['schemas']['PowerstepRecommendationRequest']) => T,
    funcPowerstepTwoRecommendationRequest: (m: components['schemas']['PowerstepTwoRecommendationRequest']) => T,
    funcReceiptPageRecommendationRequest: (m: components['schemas']['ReceiptPageRecommendationRequest']) => T,
    funcCustomRecommendationRequest: (m: components['schemas']['CustomRecommendationRequest']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductRecommendationRequest': return funcProductRecommendationRequest(model as components['schemas']['ProductRecommendationRequest']);
        case 'BasketRecommendationRequest': return funcBasketRecommendationRequest(model as components['schemas']['BasketRecommendationRequest']);
        case 'ProductGroupRecommendationRequest': return funcProductGroupRecommendationRequest(model as components['schemas']['ProductGroupRecommendationRequest']);
        case 'SearchResultRecommendationRequest': return funcSearchResultRecommendationRequest(model as components['schemas']['SearchResultRecommendationRequest']);
        case 'ZeroSearchResultRecommendationRequest': return funcZeroSearchResultRecommendationRequest(model as components['schemas']['ZeroSearchResultRecommendationRequest']);
        case 'HomePageRecommendationRequest': return funcHomePageRecommendationRequest(model as components['schemas']['HomePageRecommendationRequest']);
        case 'PowerstepRecommendationRequest': return funcPowerstepRecommendationRequest(model as components['schemas']['PowerstepRecommendationRequest']);
        case 'PowerstepTwoRecommendationRequest': return funcPowerstepTwoRecommendationRequest(model as components['schemas']['PowerstepTwoRecommendationRequest']);
        case 'ReceiptPageRecommendationRequest': return funcReceiptPageRecommendationRequest(model as components['schemas']['ReceiptPageRecommendationRequest']);
        case 'CustomRecommendationRequest': return funcCustomRecommendationRequest(model as components['schemas']['CustomRecommendationRequest']);

        default:
            return defaultFunc();
    }
}
export function MenuItemElementViewModelDiscriminator<T>(
    model: components['schemas']['ProductListMenuItemViewModel']['itemElement'],
    funcTextElementViewModel: (m: components['schemas']['TextElementViewModel']) => T,
    funcLinkElementViewModel: (m: components['schemas']['LinkElementViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'TextElementViewModel': return funcTextElementViewModel(model as components['schemas']['TextElementViewModel']);
        case 'LinkElementViewModel': return funcLinkElementViewModel(model as components['schemas']['LinkElementViewModel']);

        default:
            return defaultFunc();
    }
}
export function FilterViewModelDiscriminator<T>(
    model: components['schemas']['ProductListResultViewModel']['filters'][0],
    funcPriceFilterViewModel: (m: components['schemas']['PriceFilterViewModel']) => T,
    funcBoolFilterViewModel: (m: components['schemas']['BoolFilterViewModel']) => T,
    funcBoolStarFilterViewModel: (m: components['schemas']['BoolStarFilterViewModel']) => T,
    funcRangeFilterViewModel: (m: components['schemas']['RangeFilterViewModel']) => T,
    funcColorFilterViewModel: (m: components['schemas']['ColorFilterViewModel']) => T,
    funcLinkFilterViewModel: (m: components['schemas']['LinkFilterViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'PriceFilterViewModel': return funcPriceFilterViewModel(model as components['schemas']['PriceFilterViewModel']);
        case 'BoolFilterViewModel': return funcBoolFilterViewModel(model as components['schemas']['BoolFilterViewModel']);
        case 'BoolStarFilterViewModel': return funcBoolStarFilterViewModel(model as components['schemas']['BoolStarFilterViewModel']);
        case 'RangeFilterViewModel': return funcRangeFilterViewModel(model as components['schemas']['RangeFilterViewModel']);
        case 'ColorFilterViewModel': return funcColorFilterViewModel(model as components['schemas']['ColorFilterViewModel']);
        case 'LinkFilterViewModel': return funcLinkFilterViewModel(model as components['schemas']['LinkFilterViewModel']);

        default:
            return defaultFunc();
    }
}
export function ActiveFilterViewModelDiscriminator<T>(
    model: components['schemas']['ProductListResultViewModel']['activeFilterValues'][0],
    funcSimpleActiveFilter: (m: components['schemas']['SimpleActiveFilter']) => T,
    funcColorActiveFilter: (m: components['schemas']['ColorActiveFilter']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'SimpleActiveFilter': return funcSimpleActiveFilter(model as components['schemas']['SimpleActiveFilter']);
        case 'ColorActiveFilter': return funcColorActiveFilter(model as components['schemas']['ColorActiveFilter']);

        default:
            return defaultFunc();
    }
}
export function ProductListHeaderDiscriminator<T>(
    model: components['schemas']['ProductListViewModel']['pageHeader'],
    funcProductListBonusGiftHeaderModel: (m: components['schemas']['ProductListBonusGiftHeaderModel']) => T,
    funcProductListBrandHeaderModel: (m: components['schemas']['ProductListBrandHeaderModel']) => T,
    funcProductListOfferHeaderModel: (m: components['schemas']['ProductListOfferHeaderModel']) => T,
    funcProductListRebuyHeaderModel: (m: components['schemas']['ProductListRebuyHeaderModel']) => T,
    funcProductListTextHeaderModel: (m: components['schemas']['ProductListTextHeaderModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductListBonusGiftHeaderModel': return funcProductListBonusGiftHeaderModel(model as components['schemas']['ProductListBonusGiftHeaderModel']);
        case 'ProductListBrandHeaderModel': return funcProductListBrandHeaderModel(model as components['schemas']['ProductListBrandHeaderModel']);
        case 'ProductListOfferHeaderModel': return funcProductListOfferHeaderModel(model as components['schemas']['ProductListOfferHeaderModel']);
        case 'ProductListRebuyHeaderModel': return funcProductListRebuyHeaderModel(model as components['schemas']['ProductListRebuyHeaderModel']);
        case 'ProductListTextHeaderModel': return funcProductListTextHeaderModel(model as components['schemas']['ProductListTextHeaderModel']);

        default:
            return defaultFunc();
    }
}
export function StockStatusViewModelDiscriminator<T>(
    model: paths['/internal/ModelOnly/IStockStatusViewModel']['get']['responses']['200']['content']['text/plain'],
    funcStockStatusViewModel: (m: components['schemas']['StockStatusViewModel']) => T,
    funcStockStatusWithPreciseDeliveryAndModalViewModel: (m: components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel']) => T,
    funcStockStatusWithPreciseDeliveryViewModel: (m: components['schemas']['StockStatusWithPreciseDeliveryViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'StockStatusViewModel': return funcStockStatusViewModel(model as components['schemas']['StockStatusViewModel']);
        case 'StockStatusWithPreciseDeliveryAndModalViewModel': return funcStockStatusWithPreciseDeliveryAndModalViewModel(model as components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel']);
        case 'StockStatusWithPreciseDeliveryViewModel': return funcStockStatusWithPreciseDeliveryViewModel(model as components['schemas']['StockStatusWithPreciseDeliveryViewModel']);

        default:
            return defaultFunc();
    }
}
export function OrderSimpleModelDiscriminator<T>(
    model: paths['/internal/MyOrder/OrderSimpleModel']['get']['responses']['200']['content']['text/plain'],
    funcOfflineOrderSimpleModel: (m: components['schemas']['OfflineOrderSimpleModel']) => T,
    funcOrderSimpleModel: (m: components['schemas']['OrderSimpleModel']) => T,
    funcUpsaleOrderSimpleModel: (m: components['schemas']['UpsaleOrderSimpleModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'OfflineOrderSimpleModel': return funcOfflineOrderSimpleModel(model as components['schemas']['OfflineOrderSimpleModel']);
        case 'OrderSimpleModel': return funcOrderSimpleModel(model as components['schemas']['OrderSimpleModel']);
        case 'UpsaleOrderSimpleModel': return funcUpsaleOrderSimpleModel(model as components['schemas']['UpsaleOrderSimpleModel']);

        default:
            return defaultFunc();
    }
}
export function IPowerstepViewModelDiscriminator<T>(
    model: paths['/internal/Powerstep/GetPowerstep']['get']['responses']['200']['content']['text/plain'],
    funcBrandClubPowerstepViewModel: (m: components['schemas']['BrandClubPowerstepViewModel']) => T,
    funcPowerstepViewModel: (m: components['schemas']['PowerstepViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'BrandClubPowerstepViewModel': return funcBrandClubPowerstepViewModel(model as components['schemas']['BrandClubPowerstepViewModel']);
        case 'PowerstepViewModel': return funcPowerstepViewModel(model as components['schemas']['PowerstepViewModel']);

        default:
            return defaultFunc();
    }
}
export function GroupedBasketResultModelDiscriminator<T>(
    model: paths['/internal/checkout/Summary/GetGroupedBasket']['get']['responses']['200']['content']['text/plain'],
    funcGroupedBasketResultModel: (m: components['schemas']['GroupedBasketResultModel']) => T,
    funcRedirectGroupedBasketResultModel: (m: components['schemas']['RedirectGroupedBasketResultModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'GroupedBasketResultModel': return funcGroupedBasketResultModel(model as components['schemas']['GroupedBasketResultModel']);
        case 'RedirectGroupedBasketResultModel': return funcRedirectGroupedBasketResultModel(model as components['schemas']['RedirectGroupedBasketResultModel']);

        default:
            return defaultFunc();
    }
}
