import React from 'react';
import { SessionMenuAnchorItem } from 'components/SessionMenu/components/SessionMenuItem';
import { useTranslation } from 'react-i18next';
import { FindStore as FindStoreTrans } from 'autogen/translation-keys/trans-website-shared';
import MapMarker from 'icons/MapMarker.svg?react';
import { HeaderItemText } from './shared/styles';
import type { LinkAction } from 'components/shared/Routing/components/LinkAction/shared/types';

export default function FindStore({ linkAction, ...restProps }: { linkAction: LinkAction }) {
    const { t } = useTranslation();

    return (
        <SessionMenuAnchorItem icon={<MapMarker aria-hidden />} action={linkAction} {...restProps}>
            <HeaderItemText>{t(FindStoreTrans)}</HeaderItemText>
        </SessionMenuAnchorItem>
    );
}
