import { TOGGLE_FILTER, SHOW_TOOLTIP } from '../actions/personalFiltersAction';

const initialState = {};
const actions = {};

actions[TOGGLE_FILTER] = (state, action) => {
    return {
        ...state,
        checked: action.bool
    };
};

actions[SHOW_TOOLTIP] = (state, action) => {
    return {
        ...state,
        showTooltip: action.bool
    };
};

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
