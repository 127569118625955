/*
    p = point we are checking if inside triangle or not.
    p0, p1, p2 = triangle points.
*/
export default function pointInTriangle(p, p0, p1, p2) {
    if (JSON.stringify(p) === JSON.stringify(p0)) return true;

    const A = (1 / 2) * (-p1.y * p2.x + p0.y * (-p1.x + p2.x) + p0.x * (p1.y - p2.y) + p1.x * p2.y);
    const sign = A < 0 ? -1 : 1;
    const s = (p0.y * p2.x - p0.x * p2.y + (p2.y - p0.y) * p.x + (p0.x - p2.x) * p.y) * sign;
    const t = (p0.x * p1.y - p0.y * p1.x + (p0.y - p1.y) * p.x + (p1.x - p0.x) * p.y) * sign;

    return s > 0 && t > 0 && s + t < 2 * A * sign;
}
