import { animated, useTransition } from '@react-spring/web';
import { Position } from 'components/generic/sideDrawer/SideDrawer';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { smartviewInstanceAtom, latestSmartviewActionAtom } from '../../shared/recoil/atoms';
import { ISmartSideDrawerView } from '../shared/types';

const AnimatedDrawerViewComponent = styled<any>(animated.div)<{ position: Position }>`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    ${({ position }) => (position === 'right' ? 'right: 0;' : 'left: 0;')}
    top: 0;
    will-change: transform;
`;

export default function AnimatedDrawerView({ name, children, style, ...restProps }: ISmartSideDrawerView) {
    const { isOpen, position, instanceId } = useRecoilValue(smartviewInstanceAtom(name));
    const getLatestAction = useRecoilCallback(
        ({ snapshot }) =>
            () =>
                snapshot.getLoadable(latestSmartviewActionAtom).contents,
        []
    );
    const latestAction = getLatestAction();

    const sideDrawerTransition = useTransition(isOpen, {
        key: instanceId, // if the instanceId changes the SmartSideDrawerView will be treated as a new view and redo the animation
        from: { x: '-100%' },
        enter: { x: '0%' },
        leave: { x: latestAction === 'replace' ? '100%' : '-100%' }
    });

    return sideDrawerTransition(
        (animationStyles, item) =>
            item && (
                <AnimatedDrawerViewComponent
                    position={position}
                    style={
                        latestAction === 'initialized' || latestAction === 'terminated'
                            ? style
                            : { ...animationStyles, ...style }
                    }
                    {...restProps}
                >
                    {children}
                </AnimatedDrawerViewComponent>
            )
    );
}
