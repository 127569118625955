
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection ES6UnusedImports
        // noinspection JSUnusedGlobalSymbols
        import { useQuery, type UseQueryOptions, useMutation, type UseMutationOptions } from '@tanstack/react-query';
        import {
_MiniBasket,
type _MiniBasketSuccess,
MiniBasketIndex,
type MiniBasketIndexSuccess} from './index'

        
        
    export const _MiniBasketQueryKey = 'minibasket__MiniBasket';

    export function use_MiniBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<_MiniBasketSuccess, TError, _MiniBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _MiniBasketSuccess | (() => _MiniBasketSuccess);},  baseQueryKey = _MiniBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            _MiniBasket, options);
        }
  
    
        
    export const MiniBasketIndexQueryKey = 'minibasket_MiniBasketIndex';

    export function useMiniBasketIndexQuery<TError = unknown>( options?: Omit<UseQueryOptions<MiniBasketIndexSuccess, TError, MiniBasketIndexSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: MiniBasketIndexSuccess | (() => MiniBasketIndexSuccess);},  baseQueryKey = MiniBasketIndexQueryKey) {
            return useQuery([baseQueryKey, ],
            MiniBasketIndex, options);
        }
  
    
    