
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedGlobalSymbols
import type {components, paths} from './page';

export function LinkActionDiscriminator<T>(
    model: components['schemas']['QuestionnaireContentBlockDTO']['frontpageLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}
export function CustomActionDiscriminator<T>(
    model: components['schemas']['CustomActionType']['action'],
    funcCertificationCustomAction: (m: components['schemas']['CertificationCustomAction']) => T,
    funcJavascriptHookCustomAction: (m: components['schemas']['JavascriptHookCustomAction']) => T,
    funcLiveEventCustomAction: (m: components['schemas']['LiveEventCustomAction']) => T,
    funcSurveyCustomAction: (m: components['schemas']['SurveyCustomAction']) => T,
    funcVimeoCustomAction: (m: components['schemas']['VimeoCustomAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CertificationCustomAction': return funcCertificationCustomAction(model as components['schemas']['CertificationCustomAction']);
        case 'JavascriptHookCustomAction': return funcJavascriptHookCustomAction(model as components['schemas']['JavascriptHookCustomAction']);
        case 'LiveEventCustomAction': return funcLiveEventCustomAction(model as components['schemas']['LiveEventCustomAction']);
        case 'SurveyCustomAction': return funcSurveyCustomAction(model as components['schemas']['SurveyCustomAction']);
        case 'VimeoCustomAction': return funcVimeoCustomAction(model as components['schemas']['VimeoCustomAction']);

        default:
            return defaultFunc();
    }
}
export function EcommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}
export function DataLayerTrackingEventDiscriminator<T>(
    model: components['schemas']['DataLayerDto']['onLoadEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}
export function CustomListItemDiscriminator<T>(
    model: components['schemas']['ListRowItem']['item'],
    funcConnectedContentFullwidthModel: (m: components['schemas']['ConnectedContentFullwidthModel']) => T,
    funcConnectedContentInlineModel: (m: components['schemas']['ConnectedContentInlineModel']) => T,
    funcConnectedContentInventoryLaneBlockModel: (m: components['schemas']['ConnectedContentInventoryLaneBlockModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    funcConnectedContentProductDisplayModel: (m: components['schemas']['ConnectedContentProductDisplayModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ConnectedContentFullwidthModel': return funcConnectedContentFullwidthModel(model as components['schemas']['ConnectedContentFullwidthModel']);
        case 'ConnectedContentInlineModel': return funcConnectedContentInlineModel(model as components['schemas']['ConnectedContentInlineModel']);
        case 'ConnectedContentInventoryLaneBlockModel': return funcConnectedContentInventoryLaneBlockModel(model as components['schemas']['ConnectedContentInventoryLaneBlockModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);
        case 'ConnectedContentProductDisplayModel': return funcConnectedContentProductDisplayModel(model as components['schemas']['ConnectedContentProductDisplayModel']);

        default:
            return defaultFunc();
    }
}
export function ListItemDiscriminator<T>(
    model: components['schemas']['ExtendedPlaceholderProductsModelMaybe']['items'][0],
    funcListItem: (m: components['schemas']['ListItem']) => T,
    funcListRowItem: (m: components['schemas']['ListRowItem']) => T,
    funcProductListItemModel: (m: components['schemas']['ProductListItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ListItem': return funcListItem(model as components['schemas']['ListItem']);
        case 'ListRowItem': return funcListRowItem(model as components['schemas']['ListRowItem']);
        case 'ProductListItemModel': return funcProductListItemModel(model as components['schemas']['ProductListItemModel']);

        default:
            return defaultFunc();
    }
}
export function CmsButtonModelDiscriminator<T>(
    model: paths['/internal/ModelOnly/CmsButtonModel']['get']['responses']['200']['content']['text/plain'],
    funcAdformButtonModel: (m: components['schemas']['AdformButtonModel']) => T,
    funcLinkButtonModel: (m: components['schemas']['LinkButtonModel']) => T,
    funcSignupButtonModel: (m: components['schemas']['SignupButtonModel']) => T,
    funcLoginButtonModel: (m: components['schemas']['LoginButtonModel']) => T,
    funcNoButtonModel: (m: components['schemas']['NoButtonModel']) => T,
    funcCustomButtonModel: (m: components['schemas']['CustomButtonModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdformButtonModel': return funcAdformButtonModel(model as components['schemas']['AdformButtonModel']);
        case 'LinkButtonModel': return funcLinkButtonModel(model as components['schemas']['LinkButtonModel']);
        case 'SignupButtonModel': return funcSignupButtonModel(model as components['schemas']['SignupButtonModel']);
        case 'LoginButtonModel': return funcLoginButtonModel(model as components['schemas']['LoginButtonModel']);
        case 'NoButtonModel': return funcNoButtonModel(model as components['schemas']['NoButtonModel']);
        case 'CustomButtonModel': return funcCustomButtonModel(model as components['schemas']['CustomButtonModel']);

        default:
            return defaultFunc();
    }
}
export function StockStatusViewModelDiscriminator<T>(
    model: paths['/internal/ModelOnly/IStockStatusViewModel']['get']['responses']['200']['content']['text/plain'],
    funcStockStatusViewModel: (m: components['schemas']['StockStatusViewModel']) => T,
    funcStockStatusWithPreciseDeliveryAndModalViewModel: (m: components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel']) => T,
    funcStockStatusWithPreciseDeliveryViewModel: (m: components['schemas']['StockStatusWithPreciseDeliveryViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'StockStatusViewModel': return funcStockStatusViewModel(model as components['schemas']['StockStatusViewModel']);
        case 'StockStatusWithPreciseDeliveryAndModalViewModel': return funcStockStatusWithPreciseDeliveryAndModalViewModel(model as components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel']);
        case 'StockStatusWithPreciseDeliveryViewModel': return funcStockStatusWithPreciseDeliveryViewModel(model as components['schemas']['StockStatusWithPreciseDeliveryViewModel']);

        default:
            return defaultFunc();
    }
}
export function PageActionDiscriminator<T>(
    model: paths['/internal/ModelOnly/PageActionModel']['get']['responses']['200']['content']['text/plain'],
    funcCustomActionType: (m: components['schemas']['CustomActionType']) => T,
    funcLinkActionType: (m: components['schemas']['LinkActionType']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CustomActionType': return funcCustomActionType(model as components['schemas']['CustomActionType']);
        case 'LinkActionType': return funcLinkActionType(model as components['schemas']['LinkActionType']);

        default:
            return defaultFunc();
    }
}
