
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './powerstep';

        export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelModel = components['schemas']['PriceLabelModel'];
                    export type PriceLabelModelMaybe = components['schemas']['PriceLabelModelMaybe'];
                    export type ProductListPriceInfo = components['schemas']['ProductListPriceInfo'];
                    export type SplashPlacement = components['schemas']['SplashPlacement'];
                    export type SplashDisplayType = components['schemas']['SplashDisplayType'];
                    export type SplashType = components['schemas']['SplashType'];
                    export type Splash = components['schemas']['Splash'];
                    export type SplashMaybe = components['schemas']['SplashMaybe'];
                    export type SplashModel = components['schemas']['SplashModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type VariantDisplayType = components['schemas']['VariantDisplayType'];
                    export type VariantListItem = components['schemas']['VariantListItem'];
                    export type ProductListVariantsModel = components['schemas']['ProductListVariantsModel'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type RecommendationType = components['schemas']['RecommendationType'];
                    export type RecommendationTrackingModelDTO = components['schemas']['RecommendationTrackingModelDTO'];
                    export type SearchEventModelDTO = components['schemas']['SearchEventModelDTO'];
                    export type SearchOrigin = components['schemas']['SearchOrigin'];
                    export type SuggestedSearchEventModelDTO = components['schemas']['SuggestedSearchEventModelDTO'];
                    export type SearchTrackingModelDTO = components['schemas']['SearchTrackingModelDTO'];
                    export type PowerstepTrackingModelDTO = components['schemas']['PowerstepTrackingModelDTO'];
                    export type AdformProductTrackingModelDTO = components['schemas']['AdformProductTrackingModelDTO'];
                    export type TrackingModelDTO = components['schemas']['TrackingModelDTO'];
                    export type TrackingModelDTOMaybe = components['schemas']['TrackingModelDTOMaybe'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type IBundleInfo = components['schemas']['IBundleInfo'];
                    export type IBundleInfoMaybe = components['schemas']['IBundleInfoMaybe'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type UATrackingCategory = components['schemas']['UATrackingCategory'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type ProductListItemModel = components['schemas']['ProductListItemModel'];
                    export type CountdownSizeType = components['schemas']['CountdownSizeType'];
                    export type CountdownViewModel = components['schemas']['CountdownViewModel'];
                    export type CountdownViewModelMaybe = components['schemas']['CountdownViewModelMaybe'];
                    export type TooltipType = components['schemas']['TooltipType'];
                    export type TooltipModel = components['schemas']['TooltipModel'];
                    export type BrandClubPowerstepTextModel = components['schemas']['BrandClubPowerstepTextModel'];
                    export type BrandClubPowerstepProgressModel = components['schemas']['BrandClubPowerstepProgressModel'];
                    export type FormattedPriceInfo = components['schemas']['FormattedPriceInfo'];
                    export type GwpOfferType = components['schemas']['GwpOfferType'];
                    export type IGwpOffer = components['schemas']['IGwpOffer'];
                    export type IGwpOfferMaybe = components['schemas']['IGwpOfferMaybe'];
                    export type PowerstepSubscriptionInfoModel = components['schemas']['PowerstepSubscriptionInfoModel'];
                    export type PowerstepSubscriptionInfoModelMaybe = components['schemas']['PowerstepSubscriptionInfoModelMaybe'];
                    export type CampaignType = components['schemas']['CampaignType'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type HighlightDiscountCore = components['schemas']['HighlightDiscountCore'];
                    export type HighlightDiscountCoreMaybe = components['schemas']['HighlightDiscountCoreMaybe'];
                    export type CustomSplash = components['schemas']['CustomSplash'];
                    export type CustomSplashMaybe = components['schemas']['CustomSplashMaybe'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type DiscountClubType = components['schemas']['DiscountClubType'];
                    export type DiscountCore = components['schemas']['DiscountCore'];
                    export type Discount = components['schemas']['Discount'];
                    export type DiscountMaybe = components['schemas']['DiscountMaybe'];
                    export type BasketCouponVoucher = components['schemas']['BasketCouponVoucher'];
                    export type BasketCouponVoucherMaybe = components['schemas']['BasketCouponVoucherMaybe'];
                    export type VoucherType = components['schemas']['VoucherType'];
                    export type VoucherFlowType = components['schemas']['VoucherFlowType'];
                    export type PersonalOfferImages = components['schemas']['PersonalOfferImages'];
                    export type PersonalOfferFutureTexts = components['schemas']['PersonalOfferFutureTexts'];
                    export type PersonalOfferFutureTextsMaybe = components['schemas']['PersonalOfferFutureTextsMaybe'];
                    export type ActiveTimeSpan = components['schemas']['ActiveTimeSpan'];
                    export type PersonalOffer = components['schemas']['PersonalOffer'];
                    export type ClubMatasMemberVoucher = components['schemas']['ClubMatasMemberVoucher'];
                    export type PersonalOfferDetailed = components['schemas']['PersonalOfferDetailed'];
                    export type PersonalOfferDetailedMaybe = components['schemas']['PersonalOfferDetailedMaybe'];
                    export type TriggeredDiscount = components['schemas']['TriggeredDiscount'];
                    export type PotentialDiscount = components['schemas']['PotentialDiscount'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type DiscountText = components['schemas']['DiscountText'];
                    export type ProgressiveDiscountBasketStepText = components['schemas']['ProgressiveDiscountBasketStepText'];
                    export type ProgressiveDiscountBasketStepTextMaybe = components['schemas']['ProgressiveDiscountBasketStepTextMaybe'];
                    export type ProgressiveDiscountBasketInfo = components['schemas']['ProgressiveDiscountBasketInfo'];
                    export type ProgressiveDiscountBasketInfoMaybe = components['schemas']['ProgressiveDiscountBasketInfoMaybe'];
                    export type BasketLineDiscountInfo = components['schemas']['BasketLineDiscountInfo'];
                    export type LoginState = components['schemas']['LoginState'];
                    export type LoginDirectRequest = components['schemas']['LoginDirectRequest'];
                    export type BasketLineDiscountInfoModel = components['schemas']['BasketLineDiscountInfoModel'];
                    export type BasketLineDiscountInfoModelMaybe = components['schemas']['BasketLineDiscountInfoModelMaybe'];
                    export type ProductLinkedItemType = components['schemas']['ProductLinkedItemType'];
                    export type LinkedItemPriceInfo = components['schemas']['LinkedItemPriceInfo'];
                    export type PowerstepProductViewModel = components['schemas']['PowerstepProductViewModel'];
                    export type PowerstepInformationFromBasketViewModel = components['schemas']['PowerstepInformationFromBasketViewModel'];
                    export type PowerstepInformationFromBasketViewModelMaybe = components['schemas']['PowerstepInformationFromBasketViewModelMaybe'];
                    export type BrandClubPowerstepViewModel = components['schemas']['BrandClubPowerstepViewModel'];
                    export type BasketMessageType = components['schemas']['BasketMessageType'];
                    export type BasketMessage = components['schemas']['BasketMessage'];
                    export type PowerstepNudgingsViewModel = components['schemas']['PowerstepNudgingsViewModel'];
                    export type PowerstepNudgingsViewModelMaybe = components['schemas']['PowerstepNudgingsViewModelMaybe'];
                    export type StepNudgingItemViewModel = components['schemas']['StepNudgingItemViewModel'];
                    export type StepNudgingViewModel = components['schemas']['StepNudgingViewModel'];
                    export type StepNudgingViewModelMaybe = components['schemas']['StepNudgingViewModelMaybe'];
                    export type PowerstepViewModel = components['schemas']['PowerstepViewModel'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type MatasPlusAdvantageType = components['schemas']['MatasPlusAdvantageType'];
                    export type MatasPlusPowerStepAdvantage = components['schemas']['MatasPlusPowerStepAdvantage'];
                    export type MatasPlusPowerStep = components['schemas']['MatasPlusPowerStep'];
                    export type StepNudgingItem = components['schemas']['StepNudgingItem'];
                    export type StepNudgingModel = components['schemas']['StepNudgingModel'];
                    

        
        

        export type GetPowerstepParams = paths['/internal/Powerstep/GetPowerstep']['get']['parameters']['query'];

        export type GetPowerstepSuccess = paths['/internal/Powerstep/GetPowerstep']['get']['responses']['200']['content']['text/plain'];

        export const GetPowerstepUrl = '/internal/Powerstep/GetPowerstep';

        
        export function GetPowerstep(params: GetPowerstepParams): Promise<GetPowerstepSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetPowerstepSuccess>(`/internal/Powerstep/GetPowerstep${formatParams(params)}`)
        }
    
    
        

        

        export type GetMatasPlusInfoSuccess = paths['/internal/Powerstep/GetMatasPlusInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetMatasPlusInfoUrl = '/internal/Powerstep/GetMatasPlusInfo';

        
        export function GetMatasPlusInfo(): Promise<GetMatasPlusInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetMatasPlusInfoSuccess>(`/internal/Powerstep/GetMatasPlusInfo`)
        }
    
    
        

        

        export type StepNudgingViewModelSuccess = paths['/internal/Powerstep/StepNudgingViewModel']['get']['responses']['200']['content']['text/plain'];

        export const StepNudgingViewModelUrl = '/internal/Powerstep/StepNudgingViewModel';

        
        export function StepNudgingViewModel(): Promise<StepNudgingViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<StepNudgingViewModelSuccess>(`/internal/Powerstep/StepNudgingViewModel`)
        }
    
    

        export * from './discriminators';
    