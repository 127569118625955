export default (findInStoreLink: HTMLElement): void => {
    findInStoreLink.addEventListener('click', async (event): Promise<void> => {
        const target = event.target as HTMLElement;
        const link = target.closest('.js-findInStoreLink') as HTMLElement;

        if (link) {
            const { productId } = link.dataset;

            const { openFindInStoreModal } = await import(
                /* webpackChunkName: "findInStoreModal" */ './findInStoreModal'
            );
            openFindInStoreModal(productId);
        }
    });
};
