import { useAddSampleToBasketMutation, useGetSampleSelectionQuery } from 'autogen/swagger/basket/hooks';
import { updateBasket } from 'components/basket/shared/basket';
import useIsAuthenticated from 'hooks/user/useIsAuthenticated';
import { useOpenLoginQuickView } from 'components/login/shared/hooks/useLoginInfoQuickView';
import isClient from 'helpers/isClient';
import useGA4Tracking from 'modules/tracking/GA4/GA4Tracking';

export default function useSingleSampleSelect() {
    const { refetch: getSampleSelection } = useGetSampleSelectionQuery({ enabled: false });
    const { mutateAsync: addSampleToBasket } = useAddSampleToBasketMutation({ onSuccess: updateBasket });
    const ga4Tracking = useGA4Tracking();
    const isAuthenticated = useIsAuthenticated();
    const openLoginQuickView = useOpenLoginQuickView({
        infoType: 'BasketSample',
        level: 'Hard',
        mode: 'Standard',
        returnUrl: isClient ? window.location.pathname : '/'
    });

    const handleSingleSampleSelect = async () => {
        if (!isAuthenticated) {
            openLoginQuickView();
        } else {
            try {
                const { data } = await getSampleSelection();
                const sample = data?.sampleGroups[0].samples[0];
                if (!sample) {
                    throw new Error('Could not find a sample for selection');
                }
                const result = await addSampleToBasket({
                    params: { productId: sample.id, isRecommended: sample.isRecommended }
                });

                if (!result.success) {
                    throw new Error('Could not add sample to basket');
                }

                ga4Tracking({
                    eventName: 'add_to_cart',
                    category: 'ecommerce',
                    eventParams: [
                        { param_name: 'checkout_step', param_value: 1 },
                        { param_name: 'add_to_cart_quantity', param_value: 1 },
                        { param_name: 'is_sample', param_value: true }
                    ]
                });
            } catch (error) {
                console.error(error);
            }
        }
    };

    return handleSingleSampleSelect;
}
