import Card from 'components/generic/card';
import type React from 'react';
import { forwardRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ColorNeutralWhite, Spacing12, Spacing4 } from 'autogen/design-tokens/tokens';
import type { CardProps } from 'components/generic/card/shared/types';
import Tag from 'components/generic/tag/Tag';
import ArticleCardTextBox from './components/TextBox';
import handleColorTheme from './handleColorTheme';
import type { IArticleCard, LinkActionArticleCardProps, PageActionArticleCardProps } from './shared/types';
import PageAction from 'components/shared/Routing/PageAction';
import LinkAction from 'components/shared/Routing/components/LinkAction';

const StyledCard = styled(Card)<{ hasLink?: boolean }>`
    hyphens: auto;
    ${({ hasLink }) => (hasLink ? 'cursor: pointer;' : null)};

    && {
        background-color: ${({ theme }) => theme.colorTheme.backgroundColor || theme.color1 || ColorNeutralWhite};
        color: ${({ theme }) => theme.colorTheme.color};
        ${({ theme }) => theme.layoutTheme !== 'vertical' && 'flex-direction: row;'};
    }
`;

const TagContainer = styled.div`
    position: absolute;
    top: ${Spacing12};
    left: ${Spacing12};
    right: ${Spacing12};
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const StyledTag = styled(Tag)`
    background-color: ${ColorNeutralWhite};
    margin-bottom: ${Spacing4};
`;

const StyledLinkAction = styled(LinkAction)`
    padding: 0;
    border: 0;
    text-align: initial;
    background-color: transparent;

    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
    }

    & > ${StyledCard} {
        height: 100%;
    }
`;

const StyledPageAction = styled(PageAction)`
    padding: 0;
    border: 0;
    text-align: initial;
    background-color: transparent;

    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
    }

    & > ${StyledCard} {
        height: 100%;
    }
`;

const Content = forwardRef<HTMLDivElement, React.ComponentProps<'div'> & IArticleCard>((props, ref): JSX.Element => {
    const { textBox, tag, secondaryTag, customTheme, ...restProps } = props;
    const { children } = restProps;
    const { layoutTheme = 'vertical', disableEllipsis = false } = customTheme || {};
    let { colorTheme } = customTheme || {};

    colorTheme = handleColorTheme(colorTheme);

    const sizeByLayout: Record<string, CardProps['size']> = {
        horizontalSmall: 'sm',
        horizontal: 'md',
        vertical: 'md'
    };

    function themeHandler(inheritedTheme) {
        return { colorTheme, layoutTheme, disableEllipsis, ...inheritedTheme };
    }

    return (
        <ThemeProvider theme={themeHandler}>
            <StyledCard {...restProps} forwardedAs="article" ref={ref}>
                {children}
                {tag && (
                    <TagContainer>
                        <StyledTag>{tag}</StyledTag>
                        {secondaryTag && <StyledTag>{secondaryTag}</StyledTag>}
                    </TagContainer>
                )}
                <Card.Content size={sizeByLayout[layoutTheme]}>
                    <ArticleCardTextBox props={textBox} layoutTheme={layoutTheme} />
                </Card.Content>
            </StyledCard>
        </ThemeProvider>
    );
});

export const LinkActionArticleCard = forwardRef<HTMLDivElement, LinkActionArticleCardProps>((props, ref?) => {
    const { action, onClick, className, ...restProps } = props;

    return (
        <StyledLinkAction action={action} onClick={onClick} className={className}>
            <Content ref={ref} {...restProps} />
        </StyledLinkAction>
    );
});

export const PageActionArticleCard = forwardRef<HTMLDivElement, PageActionArticleCardProps>((props, ref?) => {
    const { action, onClick, className, ...restProps } = props;

    return (
        <StyledPageAction action={action} onClick={onClick} className={className}>
            <Content ref={ref} {...restProps} />
        </StyledPageAction>
    );
});

const ArticleCard = forwardRef<HTMLDivElement, React.ComponentProps<'div'> & IArticleCard>((props, ref?) => {
    const { textBox, tag, secondaryTag, customTheme, className, ...restProps } = props;

    return (
        <Content
            tag={tag}
            secondaryTag={secondaryTag}
            textBox={textBox}
            customTheme={customTheme}
            ref={ref}
            className={className}
            {...restProps}
        />
    );
});

export default ArticleCard;
