
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './survey';

        export type SurveyAnswerOptionStyle = components['schemas']['SurveyAnswerOptionStyle'];
                    export type SurveyAnswerOptionInputType = components['schemas']['SurveyAnswerOptionInputType'];
                    export type SurveyAnswerOptionDynamicDataViewModel = components['schemas']['SurveyAnswerOptionDynamicDataViewModel'];
                    export type SurveyAnswerOptionDynamicDataViewModelMaybe = components['schemas']['SurveyAnswerOptionDynamicDataViewModelMaybe'];
                    export type SurveyAnswerOptionViewModel = components['schemas']['SurveyAnswerOptionViewModel'];
                    export type SurveyQuestionViewModel = components['schemas']['SurveyQuestionViewModel'];
                    export type SurveyPageViewModel = components['schemas']['SurveyPageViewModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type SurveyResponseViewModel = components['schemas']['SurveyResponseViewModel'];
                    export type SurveyAnswerOptionResponseModel = components['schemas']['SurveyAnswerOptionResponseModel'];
                    export type SurveyQuestionResponseModel = components['schemas']['SurveyQuestionResponseModel'];
                    export type SurveyResponsePostModel = components['schemas']['SurveyResponsePostModel'];
                    export type SurveyResponseViewModelMaybe = components['schemas']['SurveyResponseViewModelMaybe'];
                    export type SurveyResponseUpdateResult = components['schemas']['SurveyResponseUpdateResult'];
                    

        
        

        export type SurveyResponseIndexParams = paths['/internal/surveysystem/SurveyResponse/Index']['get']['parameters']['query'];

        export type SurveyResponseIndexSuccess = paths['/internal/surveysystem/SurveyResponse/Index']['get']['responses']['200']['content']['text/plain'];

        export const SurveyResponseIndexUrl = '/internal/surveysystem/SurveyResponse/Index';

        
        export function SurveyResponseIndex(params: SurveyResponseIndexParams): Promise<SurveyResponseIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<SurveyResponseIndexSuccess>(`/internal/surveysystem/SurveyResponse/Index${formatParams(params)}`)
        }
    
    
        

        export type SurveyResponseCreateParams = paths['/internal/surveysystem/SurveyResponse/Create']['post']['parameters']['query'];

        export type SurveyResponseCreateSuccess = paths['/internal/surveysystem/SurveyResponse/Create']['post']['responses']['200']['content']['text/plain'];

        export const SurveyResponseCreateUrl = '/internal/surveysystem/SurveyResponse/Create';

        
        export function SurveyResponseCreate(params: SurveyResponseCreateParams): Promise<SurveyResponseCreateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<SurveyResponseCreateSuccess>(`/internal/surveysystem/SurveyResponse/Create${formatParams(params)}`)
        }
    
    
        export type SurveyResponseUpdateRequestBody = paths['/internal/surveysystem/SurveyResponse/Update']['post']['requestBody']['content']['application/json'];

        

        export type SurveyResponseUpdateSuccess = paths['/internal/surveysystem/SurveyResponse/Update']['post']['responses']['200']['content']['text/plain'];

        export const SurveyResponseUpdateUrl = '/internal/surveysystem/SurveyResponse/Update';

        
        export function SurveyResponseUpdate(requestBody: SurveyResponseUpdateRequestBody): Promise<SurveyResponseUpdateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<SurveyResponseUpdateSuccess>(`/internal/surveysystem/SurveyResponse/Update`, requestBody)
        }
    
    

        export * from './discriminators';
    