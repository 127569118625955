import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Translations from 'modules/translations';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import MainContext from 'components/QuickSearch/components/context';
import ResultSection from '../../components/resultSection/resultSection';
import { listTypeEnum, buttonEnum, trackingActionEnum } from '../../enums';

const Dialog = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow: auto;

    ${({ isHandheld, scrollHeight }) => (isHandheld ? `overflow: auto;` : `max-height: ${scrollHeight};`)}
`;

export default props => {
    const { suggestions, latestSearchQueries, dialogTouchCallback, nextQueries } = props;
    const scrollRef = React.createRef();
    const mainContext = useContext(MainContext);
    const { isHandheld } = mainContext;
    const [scrollHeight, setScrollHeight] = useState('');

    const hasSuggestions = suggestions && suggestions.items.length > 0;
    const hasLatestSearchQueries = latestSearchQueries && latestSearchQueries.items.length > 0;
    const hasNextQueries = nextQueries && nextQueries.items.length > 0;

    useEffect(() => {
        if (!isHandheld) {
            return;
        }

        const scrollContainer = scrollRef.current;

        disableBodyScroll(scrollContainer, {
            reserveScrollBarGap: true
        });

        document.getElementsByTagName('body')[0].classList.remove('state--search-open');
        enableBodyScroll(scrollContainer);
    }, []);

    useEffect(() => {
        if (!isHandheld) {
            requestAnimationFrame(() => {
                if (!scrollRef.current) return;
                const rect = scrollRef.current.getBoundingClientRect();
                setScrollHeight(`calc(100vh - 1rem - ${rect.top}px)`);
            });
        }
    }, [isHandheld, scrollRef]);

    return (
        <Dialog
            ref={scrollRef}
            scrollHeight={scrollHeight}
            isHandheld={isHandheld}
            onTouchStart={() => dialogTouchCallback()}
        >
            {hasNextQueries && (
                <ResultSection
                    headline={Translations.Website.SearchDialog.RelatedToLatestSearch}
                    buttonType={buttonEnum.link}
                    items={nextQueries}
                    listType={listTypeEnum.link}
                    trackingAction={trackingActionEnum.nextQuery}
                />
            )}
            {hasLatestSearchQueries && (
                <ResultSection
                    headline={Translations.Website.SearchDialog.YourLatestQueries}
                    buttonType={buttonEnum.clearHistory}
                    items={latestSearchQueries}
                    listType={listTypeEnum.link}
                    trackingAction={trackingActionEnum.latestSearchSuggestion}
                />
            )}
            {hasSuggestions && (
                <ResultSection
                    headline={Translations.Website.SearchDialog.TrendingQueries}
                    buttonType={buttonEnum.link}
                    items={suggestions}
                    listType={listTypeEnum.link}
                />
            )}
        </Dialog>
    );
};
