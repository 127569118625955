import React, { type HTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import type { SampleGroupViewModel, SampleSelectionViewModel } from 'autogen/swagger/basket';
import { Spacing16 } from 'autogen/design-tokens/tokens';
import { useIsDesktop } from 'hooks/responsive';
import PickerCard from './PickerCard';
import { ReadMoreCallbackContext } from '../hooks/contexts';
import SamplesSlider from './SampleSlider';
import Description from './Description';

const Wrapper = styled.div``;

const SampleGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${Spacing16};
`;

interface ISamplesGroup extends HTMLAttributes<HTMLDivElement> {
    props: SampleGroupViewModel;
}

export default function SampleGroup({ props, ...restProps }: ISamplesGroup): JSX.Element {
    const { samples, locked } = props;
    const isDesktop = useIsDesktop();
    const [descriptionContent, setDescriptionContent] = useState<SampleSelectionViewModel>();

    function readMoreCallback(item?: SampleSelectionViewModel) {
        setDescriptionContent(item);
    }

    return (
        <ReadMoreCallbackContext.Provider value={readMoreCallback}>
            <Wrapper {...restProps}>
                {isDesktop ? (
                    <SamplesSlider samples={samples} locked={locked} />
                ) : (
                    <SampleGrid>
                        {samples.map((sample) => {
                            const { id } = sample;
                            return <PickerCard key={id} {...{ ...sample, locked, isDesktop }} />;
                        })}
                    </SampleGrid>
                )}
                {descriptionContent && <Description {...{ ...descriptionContent, locked }} />}
            </Wrapper>
        </ReadMoreCallbackContext.Provider>
    );
}
