import breakpoint from '../../modules/breakpoint';

export default class matchPermissionCheckboxes {

    constructor() {
        this.checkboxes = $('[data-js^="permissions:"]').find('input[type="checkbox"]');
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.checkboxes.on('click', e => this.matchCheckboxes(e));
    }

    matchCheckboxes(e) {
        const target = $(e.target);
        const targetName = target.attr('name');
        const isChecked = target.is(':checked');
        const targetsMatch = $('input[type="checkbox"][name="'+ targetName +'"]');
        
        targetsMatch.each((i, item) => {
            $(item).prop('checked', isChecked);
        });
    }
}
