import {
    ColorNeutralBlack,
    FontCaptionFamily,
    FontCaptionLineHeightLarge,
    FontCaptionLineHeightMedium,
    FontCaptionLineHeightSmall,
    FontCaptionWeightRegular,
    FontCaptionWeightSemiBold
} from 'autogen/design-tokens/tokens';
import React, { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type sizes = 'sm' | 'md' | 'lg';
type weights = 'regular' | 'semibold';

interface IHeading extends React.AnchorHTMLAttributes<HTMLSpanElement> {
    size?: sizes;
    weight?: weights;
    children?: ReactNode;
}

const captionSizes = {
    sm: css`
        font-size: 10px;
        line-height: ${FontCaptionLineHeightSmall};
    `,
    md: css`
        font-size: 12px;
        line-height: ${FontCaptionLineHeightMedium};
    `,
    lg: css`
        font-size: 14px;
        line-height: ${FontCaptionLineHeightLarge};
    `
};

const captionWeights = {
    regular: FontCaptionWeightRegular,
    semibold: FontCaptionWeightSemiBold
};

const StyledCaption = styled.span<{ size: sizes; weight: weights }>`
    ${({ size }) => captionSizes[size]};
    font-family: ${FontCaptionFamily};
    font-weight: ${({ weight }) => captionWeights[weight]};
    color: ${ColorNeutralBlack};
`;

export default function Caption({ size = 'md', weight = 'regular', children, ...restProps }: IHeading) {
    return (
        <StyledCaption size={size} weight={weight} {...restProps}>
            {children}
        </StyledCaption>
    );
}
