/* eslint-disable */
/// This file is auto generated - do not edit manually
export * as Icons from './trans-website-imagealttext-icons';

export const EMAAltText = 'Website.ImageAltText.EMAAltText';
export const GoToProductPage = 'Website.ImageAltText.GoToProductPage';
export const PDPThumbnail = 'Website.ImageAltText.PDPThumbnail';
export const PDPPreviewImage = 'Website.ImageAltText.PDPPreviewImage';
export const Certified = 'Website.ImageAltText.Certified';
export const HeaderLogoAltText = 'Website.ImageAltText.HeaderLogoAltText';
export const PlayVideo = 'Website.ImageAltText.PlayVideo';
