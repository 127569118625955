import { useState } from 'react';
import useInterval from './useInterval';

export default function useTimer(delayMilliseconds = 1000, paused = false, initialTimeMilliseconds = 0) {
    const [time, setTime] = useState(initialTimeMilliseconds);

    useInterval(
        () => {
            setTime((prevTimeMilliseconds) => prevTimeMilliseconds + delayMilliseconds);
        },
        paused ? null : delayMilliseconds
    );

    return time;
}
