import { Typography } from 'components/generic/typography';

export type JsonLdContentProps = {
    model: any;
};

export default function JsonLdContent(props: JsonLdContentProps) {
    const { model } = props;

    return (
        <>
            {model.isPreview && (
                <>
                    <Typography.Title size={20}>JsonLD preview</Typography.Title>
                    <pre>{`<script type="application/ld+json">${model.jsonLd}</script>`}</pre>
                </>
            )}
            <script type="application/ld+json">{model.jsonLd}</script>
        </>
    );
}
