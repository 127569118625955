import { mediatorAtom, useSetMediatorState } from 'hooks/globals/useMediatorAtom';
import { useCallback } from 'react';

// Since we need to support legacy-none-react views. The source of truth for powerstep state will live here were both legacy and react has access to it.
export const PowerstepMediatorAtom = mediatorAtom<{ isOpen: boolean; productIds: number[] }>({
    isOpen: false,
    productIds: []
});

export default function usePowerstep(): [(productIds: number[]) => void, () => void] {
    const setPowerstepMediatorAtom = useSetMediatorState(PowerstepMediatorAtom);

    const close = useCallback(() => {
        setPowerstepMediatorAtom({ isOpen: false, productIds: [] });
    }, [setPowerstepMediatorAtom]);

    const open = useCallback(
        (productIds: number[]) => {
            setPowerstepMediatorAtom({ isOpen: true, productIds });
        },
        [setPowerstepMediatorAtom]
    );

    return [open, close];
}

// Legacy functions for opening the react-powerstep outside of React.
export const legacyOpenPowerstep = (productIds: number[]) => PowerstepMediatorAtom.set({ isOpen: true, productIds });
export const legacyClosePowerstep = () => PowerstepMediatorAtom.set({ isOpen: false, productIds: [] });
