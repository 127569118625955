import { LinkActionDiscriminator } from 'autogen/swagger/page';
import { forwardRef } from 'react';
import type { LinkActionProps } from './shared/types';
import DefaultLink from './components/DefaultLink';

const LinkAction = forwardRef<HTMLAnchorElement, LinkActionProps>(({ action, ...restProps }, ref) =>
    LinkActionDiscriminator(
        action,
        (advisorLinkAction) => <DefaultLink action={advisorLinkAction} ref={ref} {...restProps} />,
        (articleSectionLinkAction) => <DefaultLink action={articleSectionLinkAction} ref={ref} {...restProps} />,
        (articleTagLinkAction) => <DefaultLink action={articleTagLinkAction} ref={ref} {...restProps} />,
        (brandClubLinkAction) => <DefaultLink action={brandClubLinkAction} ref={ref} {...restProps} />,
        (brandLinkAction) => <DefaultLink action={brandLinkAction} ref={ref} {...restProps} />,
        (campaignOfferLinkAction) => <DefaultLink action={campaignOfferLinkAction} ref={ref} {...restProps} />,
        (cmsMenuItemLinkAction) => <DefaultLink action={cmsMenuItemLinkAction} ref={ref} {...restProps} />,
        (cmsPageLinkAction) => <DefaultLink action={cmsPageLinkAction} ref={ref} {...restProps} />,
        (discountLinkAction) => <DefaultLink action={discountLinkAction} ref={ref} {...restProps} />,
        (externalLinkAction) => <DefaultLink action={externalLinkAction} ref={ref} {...restProps} />,
        (frontpageLinkAction) => <DefaultLink action={frontpageLinkAction} ref={ref} {...restProps} />,
        (plainLinkAction) => <DefaultLink action={plainLinkAction} ref={ref} {...restProps} />,
        (productGroupLinkAction) => <DefaultLink action={productGroupLinkAction} ref={ref} {...restProps} />,
        (productLinkAction) => <DefaultLink action={productLinkAction} ref={ref} {...restProps} />,
        (seriesLinkAction) => <DefaultLink action={seriesLinkAction} ref={ref} {...restProps} />,
        (staticPageLinkAction) => <DefaultLink action={staticPageLinkAction} ref={ref} {...restProps} />,
        (storeLinkAction) => <DefaultLink action={storeLinkAction} ref={ref} {...restProps} />,
        () => {
            console.log(action);

            throw new Error('Invalid Link Action');
        }
    )
);

export default LinkAction;
