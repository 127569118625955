/* eslint-disable import/prefer-default-export */
import { FontFontFamilyPrimary, FontSize10, FontSize12, FontWeightNormal } from 'autogen/design-tokens/tokens';
import styled from 'styled-components';
import { breakpoint, sizes } from 'variables';

export const HeaderItemText = styled.span`
    display: none;

    ${breakpoint.up(361)} {
        display: initial;
        font-size: ${FontSize10};
        font-weight: ${FontWeightNormal};
        font-family: ${FontFontFamilyPrimary};
        white-space: nowrap;
    }

    ${breakpoint.up(sizes.lg)} {
        font-size: ${FontSize12};
    }
`;
