import React from 'react';
import styled from 'styled-components';
import { BorderRadiusMedium, ColorNeutralWhite } from 'autogen/design-tokens/tokens';
import type { CardProps } from '../shared/types';

const StyledCardShell = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: ${BorderRadiusMedium};
    overflow: hidden;
    width: 100%;
    background-color: ${({ theme }) => theme?.backgroundColor || ColorNeutralWhite};
`;

const CardShell = React.forwardRef<HTMLDivElement, CardProps>(({ children, ...restProps }, ref) => (
    <StyledCardShell ref={ref} {...restProps}>
        {children}
    </StyledCardShell>
));

export default CardShell;
