
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './liveevent';

        export type LiveEventFilterType = components['schemas']['LiveEventFilterType'];
                    export type ILiveEventFilterViewModel = components['schemas']['ILiveEventFilterViewModel'];
                    export type QueryStringParameter = components['schemas']['QueryStringParameter'];
                    export type LiveEventFilterViewModel = components['schemas']['LiveEventFilterViewModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type CertificationCustomAction = components['schemas']['CertificationCustomAction'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type JavascriptHookCustomAction = components['schemas']['JavascriptHookCustomAction'];
                    export type LiveEventCustomAction = components['schemas']['LiveEventCustomAction'];
                    export type SurveyCustomAction = components['schemas']['SurveyCustomAction'];
                    export type VimeoCustomAction = components['schemas']['VimeoCustomAction'];
                    export type CustomActionType = components['schemas']['CustomActionType'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type LinkActionType = components['schemas']['LinkActionType'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type LiveEventBlockViewModel = components['schemas']['LiveEventBlockViewModel'];
                    export type SubscribeToNotificationsBlockViewModel = components['schemas']['SubscribeToNotificationsBlockViewModel'];
                    export type LiveEventSliderViewModel = components['schemas']['LiveEventSliderViewModel'];
                    export type LiveEventSliderViewModelMaybe = components['schemas']['LiveEventSliderViewModelMaybe'];
                    export type LiveEventFilteredResultViewModel = components['schemas']['LiveEventFilteredResultViewModel'];
                    export type LiveEventFilteredResultViewModelMaybe = components['schemas']['LiveEventFilteredResultViewModelMaybe'];
                    export type SortDirection = components['schemas']['SortDirection'];
                    export type LiveEventListUnsubscribeViewModel = components['schemas']['LiveEventListUnsubscribeViewModel'];
                    export type LiveEventListUnsubscribeViewModelMaybe = components['schemas']['LiveEventListUnsubscribeViewModelMaybe'];
                    export type LiveEventListViewModel = components['schemas']['LiveEventListViewModel'];
                    export type InspirationMatasLivePageViewModel = components['schemas']['InspirationMatasLivePageViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type LiveEventNotificationDeliveryType = components['schemas']['LiveEventNotificationDeliveryType'];
                    export type NotificationDeliveryTypeViewModel = components['schemas']['NotificationDeliveryTypeViewModel'];
                    export type LiveEventNotificationViewModel = components['schemas']['LiveEventNotificationViewModel'];
                    export type LiveEventNotificationViewModelMaybe = components['schemas']['LiveEventNotificationViewModelMaybe'];
                    export type LiveEventSubscriptionViewModel = components['schemas']['LiveEventSubscriptionViewModel'];
                    export type LiveEventSubscriberViewModel = components['schemas']['LiveEventSubscriberViewModel'];
                    export type LiveEventSubscriberOverviewViewModel = components['schemas']['LiveEventSubscriberOverviewViewModel'];
                    export type AddLiveEventNotificationPostModel = components['schemas']['AddLiveEventNotificationPostModel'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type LiveEventInfo = components['schemas']['LiveEventInfo'];
                    export type DateTimeTranslations = components['schemas']['DateTimeTranslations'];
                    export type BambuserViewModel = components['schemas']['BambuserViewModel'];
                    

        
        

        

        export type InspirationMatasLivePageViewModelSuccess = paths['/internal/Inspiration/InspirationMatasLivePageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const InspirationMatasLivePageViewModelUrl = '/internal/Inspiration/InspirationMatasLivePageViewModel';

        
        export function InspirationMatasLivePageViewModel(): Promise<InspirationMatasLivePageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<InspirationMatasLivePageViewModelSuccess>(`/internal/Inspiration/InspirationMatasLivePageViewModel`)
        }
    
    
        

        export type UnsubscribeAllParams = paths['/internal/LiveEvent/UnsubscribeAll']['post']['parameters']['query'];

        export type UnsubscribeAllSuccess = paths['/internal/LiveEvent/UnsubscribeAll']['post']['responses']['200']['content']['text/plain'];

        export const UnsubscribeAllUrl = '/internal/LiveEvent/UnsubscribeAll';

        
        export function UnsubscribeAll(params: UnsubscribeAllParams): Promise<UnsubscribeAllSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UnsubscribeAllSuccess>(`/internal/LiveEvent/UnsubscribeAll${formatParams(params)}`)
        }
    
    
        

        export type GetEventSubscriberInfoParams = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['parameters']['query'];

        export type GetEventSubscriberInfoSuccess = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetEventSubscriberInfoUrl = '/internal/LiveEvent/GetEventSubscriberInfo';

        
        export function GetEventSubscriberInfo(params: GetEventSubscriberInfoParams): Promise<GetEventSubscriberInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetEventSubscriberInfoSuccess>(`/internal/LiveEvent/GetEventSubscriberInfo${formatParams(params)}`)
        }
    
    
        export type AddEventNotificationRequestBody = paths['/internal/LiveEvent/AddEventNotification']['post']['requestBody']['content']['application/json'];

        

        

        export const AddEventNotificationUrl = '/internal/LiveEvent/AddEventNotification';

        
        export function AddEventNotification(requestBody: AddEventNotificationRequestBody): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<unknown>(`/internal/LiveEvent/AddEventNotification`, requestBody)
        }
    
    
        

        export type GetLiveEventStatusModelParams = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['parameters']['query'];

        export type GetLiveEventStatusModelSuccess = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['responses']['200']['content']['text/plain'];

        export const GetLiveEventStatusModelUrl = '/internal/LiveEvent/GetLiveEventStatusModel';

        
        export function GetLiveEventStatusModel(params: GetLiveEventStatusModelParams): Promise<GetLiveEventStatusModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetLiveEventStatusModelSuccess>(`/internal/LiveEvent/GetLiveEventStatusModel${formatParams(params)}`)
        }
    
    
        

        

        export type LiveEventListViewModelSuccess = paths['/internal/LiveEvent/LiveEventListViewModel']['get']['responses']['200']['content']['text/plain'];

        export const LiveEventListViewModelUrl = '/internal/LiveEvent/LiveEventListViewModel';

        
        export function LiveEventListViewModel(): Promise<LiveEventListViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<LiveEventListViewModelSuccess>(`/internal/LiveEvent/LiveEventListViewModel`)
        }
    
    
        

        

        export type LiveEventFilteredResultViewModelSuccess = paths['/internal/LiveEvent/LiveEventFilteredResultViewModel']['get']['responses']['200']['content']['text/plain'];

        export const LiveEventFilteredResultViewModelUrl = '/internal/LiveEvent/LiveEventFilteredResultViewModel';

        
        export function LiveEventFilteredResultViewModel(): Promise<LiveEventFilteredResultViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<LiveEventFilteredResultViewModelSuccess>(`/internal/LiveEvent/LiveEventFilteredResultViewModel`)
        }
    
    
        

        

        export type BambuserViewModelSuccess = paths['/internal/ModelOnly/BambuserViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BambuserViewModelUrl = '/internal/ModelOnly/BambuserViewModel';

        
        export function BambuserViewModel(): Promise<BambuserViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BambuserViewModelSuccess>(`/internal/ModelOnly/BambuserViewModel`)
        }
    
    

        export * from './discriminators';
    