/* eslint-disable @typescript-eslint/no-shadow */
import { animated, useTransition } from '@react-spring/web';
import { ColorNeutralWhite, Spacing20 } from 'autogen/design-tokens/tokens';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { breakpoint, sizes, zIndex } from 'variables';
import UAEventTracking from 'modules/tracking/UAEventTracking';
import { useIsAboveBreakPoint } from 'hooks/responsive';
import useRequestContext from 'hooks/user/useRequestContext';
import showIOSNavigation from 'modules/helpers/showIOSNavigation';
import useSetDialogFocusEffect from 'hooks/globals/useSetDialogFocusEffect';
import useOnNestedFocusoutEffect from 'hooks/globals/useOnNestedFocusoutEffect';
import useEscapeKeyEffect from 'hooks/globals/useEscapeKeyEffect';
import useBodyScrollLock from 'hooks/globals/useBodyScrollLock';
import useGA4Tracking from 'modules/tracking/GA4/GA4Tracking';
import type { IControlledQuickView, IQuickView, ISlots, IUncontrolledQuickView } from './shared/types';
import DefaultQuickViewHeader from './components/DefaultQuickViewComponents/Header';
import DefaultQuickViewContentContainer from './components/DefaultQuickViewComponents/Content';
import { placementContainerPositionStyling, positionStyling, positionTransition } from './shared/style';
import useQuickView from './hooks/useQuickView';
import useDefaultFooterComponents from './hooks/useDefaultFooterComponents';

const PlacementContainer = styled.div<Pick<IQuickView, 'position'> & { visible: boolean }>`
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    z-index: ${zIndex.modal};
    display: flex;

    ${({ visible }) =>
        !visible &&
        css`
            pointer-events: none;
        `}

    ${({ position }) => placementContainerPositionStyling(position)}
`;

const BackDrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
`;

const AnimatedBackDrop = animated<any>(BackDrop);

export const QuickViewWrapper = styled.div<Pick<IQuickView, 'position' | 'backgroundColor' | 'fullscreenOnMobile'>>`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 0;
    background-color: ${({ backgroundColor }) => backgroundColor || ColorNeutralWhite};
    overflow: hidden;

    ${({ position }) => positionStyling(position)}

    ${({ fullscreenOnMobile }) =>
        fullscreenOnMobile &&
        css`
            ${breakpoint.down(sizes.xs)} {
                height: calc(100% - ${Spacing20});
            }
        `}
`;

const AnimatedQuickViewWrapper = animated<any>(QuickViewWrapper);

const Component: React.FC<IQuickView & { style: any }> = (props) => {
    const {
        children,
        active,
        onClose,
        trackingCategory,
        ariaLabel,
        position,
        userCanClose = true,
        lockScroll,
        headerRenderer = ({ ...headerProps }) => <DefaultQuickViewHeader {...headerProps} />,
        contentRenderer = ({ ...contentProps }) => <DefaultQuickViewContentContainer {...contentProps} />,
        footerRenderer = () => null,
        backButtonRenderer = () => null,
        slidesRenderer = () => null,
        ...rest
    } = props;

    const portalContainer = typeof document !== 'undefined' && document.getElementById('jsReactQuickView');
    const { isClubMatasApp } = useRequestContext();
    const ref = useRef<HTMLElement>(null);
    const [isFocused, setIsFocused] = useState(true);
    const defaultFooterComponents = useDefaultFooterComponents(props);
    const shouldLock = active && lockScroll;
    const ga4Tracking = useGA4Tracking();

    useBodyScrollLock(shouldLock);
    useSetDialogFocusEffect(ref, isFocused);

    const backDropTransitions = useTransition(active, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });

    const handleCloseEvent = () => {
        if (onClose && typeof onClose === 'function') {
            onClose();

            if (trackingCategory) {
                ga4Tracking({ eventName: 'close_modal' });
                UAEventTracking({
                    eventCategory: trackingCategory,
                    eventAction: 'closeQuickView'
                });
            }
        }
    };

    useEffect(() => {
        if (isClubMatasApp) {
            if (active) {
                showIOSNavigation(true);
            } else {
                showIOSNavigation(false);
            }
        }
    }, [isClubMatasApp, active]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(
        () => () => {
            if (onClose) {
                onClose();
            }
        },
        []
    );

    useEffect(() => {
        if (!active) {
            setIsFocused(false);
        }
    }, [active]);

    if (!portalContainer) {
        return null;
    }

    return createPortal(
        <PlacementContainer visible={active} position={position}>
            {backDropTransitions(
                (style, toggleBackdrop) =>
                    toggleBackdrop && (
                        <AnimatedBackDrop style={style} onClick={userCanClose ? handleCloseEvent : undefined} />
                    )
            )}
            <AnimatedQuickViewWrapper
                ref={ref}
                role="dialog"
                aria-modal
                tabIndex="-1"
                position={position}
                aria-label={ariaLabel || ''}
                {...rest}
            >
                {headerRenderer({ userCanClose, onClose: handleCloseEvent, backButtonRenderer })}
                {contentRenderer({
                    children: (
                        <>
                            {children}
                            {slidesRenderer()}
                        </>
                    )
                })}
                {footerRenderer({ DefaultComponents: defaultFooterComponents })}
            </AnimatedQuickViewWrapper>
        </PlacementContainer>,
        portalContainer
    );
};

function getMobilePosition(position: IQuickView['position']): IQuickView['position'] {
    switch (position) {
        case 'center':
            return 'center';
        default:
            return 'bottom';
    }
}

/**
 * Controlled QuickView - Can be used to implement your own state solution to open a quickView.
 */
export const ControlledQuickView = ({
    active,
    onClose,
    position = 'bottom',
    lockScroll = true,
    userCanClose = true,
    ...restProps
}: IControlledQuickView) => {
    const isAboveSm = useIsAboveBreakPoint(sizes.sm);
    const positionFromBreakpoint = isAboveSm ? position : getMobilePosition(position);
    const transitions = useTransition(active && isAboveSm !== undefined, positionTransition(positionFromBreakpoint));

    useEscapeKeyEffect(() => {
        if (active && userCanClose) {
            onClose();
        }
    });

    return transitions(
        (style, isActive) =>
            isActive && (
                <Component
                    active={active}
                    style={style}
                    position={positionFromBreakpoint}
                    onClose={onClose}
                    lockScroll={lockScroll}
                    userCanClose={userCanClose}
                    {...restProps}
                />
            )
    );
};

/**
 * QuickView - Built in Recoil state, can be opened using a specified quickViewId and the complementary useQuickView hook.
 */
const QuickView = ({
    position = 'bottom',
    quickViewId,
    lockScroll = true,
    userCanClose = true,
    onClose,
    ...restProps
}: IUncontrolledQuickView) => {
    const { visible, toggle } = useQuickView(quickViewId);

    return (
        <ControlledQuickView
            active={visible}
            onClose={() => {
                onClose?.();
                toggle(false);
            }}
            position={position}
            lockScroll={lockScroll}
            userCanClose={userCanClose}
            {...restProps}
        />
    );
};

export default Object.assign(QuickView, {
    Controlled: ControlledQuickView
});
