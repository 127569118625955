import DefaultMenu from './components/DefaultMenu';
import DefaultMenuItem from './components/DefaultMenuItem';
import DefaultMenuSection, {
    BoxedMenuSection,
    SecondaryMenuSection,
    SecondaryMenuSectionWithDividers
} from './components/DefaultMenuSection';

const Menu = Object.assign(DefaultMenu, {
    Item: DefaultMenuItem,
    Section: DefaultMenuSection,
    SecondarySection: SecondaryMenuSection,
    SecondarySectionWithDividers: SecondaryMenuSectionWithDividers,
    BoxedSection: BoxedMenuSection
});

export default Menu;
