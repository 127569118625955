import debounce from 'debounce';
import breakpoint from './breakpoint';

function valueThreshold(elm, minValue, maxValue) {
    const inputField = $(elm);
    const min = minValue || inputField.attr('min');
    const max = maxValue || inputField.attr('max');
    if (!min || !max) return;

    const originalValue = inputField.val();
    let lastValidatedValue = originalValue;

    inputField
        .on('keyup', () => {
            const inputValue = inputField.val();
            let intValue = Number(inputValue);
            if (intValue < min && inputValue !== '') {
                inputField.val(min);
                intValue = min;
            } else if (intValue > max) {
                inputField.val(max);
                intValue = max;
            } else if (Number.isNaN(intValue)) {
                inputField.val(lastValidatedValue);
                return;
            }
            if (!Number.isNaN(intValue)) {
                lastValidatedValue = intValue;
            }
        })
        .on('blur', () => {
            if (inputField.val().length === 0) {
                inputField.val(originalValue);
            }
            lastValidatedValue = originalValue;
        });
}

function fixIDs(string) {
    const id = string.match(/id=".+?(?:")/gi);
    let newString = string;
    if (id) {
        for (let i = 0; i < id.length; i += 1) {
            const idValue = id[i].split('"')[1];
            const re = new RegExp(idValue, 'gm');
            newString = newString.replace(re, `${idValue}-clone`);
        }
    }

    return newString;
}

export default class Selection {
    static bind(scope = $('body')) {
        scope.find('.js-selection').each((i, item) => {
            const selection = new Selection($(item));
            selection.init();
        });
    }

    constructor(selection, additionalStateActiveObject) {
        this.doc = $(document);
        this.selection = selection;
        this.select = selection.find('[data-js="selection-select"]');
        this.dropdown = selection.find('[data-js="selection-dropdown"]');
        this.option = selection.find('[data-js="selection-option"]');
        this.current = selection.find('[data-js="selection-current"]');
        this.currentText = this.current.find('[data-js="selection-current-text"]');
        this.changeObj = selection.attr('data-js-change');
        this.inputHidden = selection.find('.js-selection-input');
        this.additionalStateActiveObject = additionalStateActiveObject;
        this.appendToBody = this.selection.hasClass('js-dropdownOnBody');

        this.activeClass = 'state--active';

        this.toggle = this.toggle.bind(this);
        this.clickOutside = this.clickOutside.bind(this);
        this.setActiveSelection = this.setActiveSelection.bind(this);
        this.closeDropDown = this.closeDropDown.bind(this);
        this.checkScrollEvent = this.checkScrollEvent.bind(this);

        this.debounceResize = debounce(this.closeDropDown, 100, true);
        this.debounceScroll = debounce(this.checkScrollEvent, 100, true);
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.selection.on('click', this.toggle);
        this.option.on('click', this.setActiveSelection);
        this.inputHidden.on('change', this.setActiveSelection);
        this.current.on('click', event => {
            /* If there is an anchor tag inside this.current, we need to preventDefault, when clicking it */
            event.preventDefault();
        });
    }

    checkScrollEvent(e) {
        if (e.target !== this.dropdown.get(0)) this.closeDropDown();
    }

    closeDropDown() {
        if (this.dropdown.is(':visible')) {
            this.dropdown.hide();
            this.cleanupDropDown();
        }
    }

    cleanupDropDown() {
        this.doc.off('click', this.clickOutside);
        this.select.removeClass('selection__select--upwards');
        if (this.appendToBody) {
            $(window).off('resize', this.debounceResize);
            document.removeEventListener('scroll', this.debounceScroll, true);
        }
    }

    toggle() {
        if (this.inputHidden.is('select') && window.innerWidth < breakpoint.sizes.md) return;

        let bounds = null;
        let scroll = null;
        if (this.appendToBody) {
            this.doc.find('body').append(this.dropdown);
            bounds = this.selection.get(0).getBoundingClientRect();
            scroll = document.documentElement.scrollTop;
            this.dropdown.css({
                top: bounds.top + bounds.height + scroll,
                left: bounds.left,
                zIndex: 10000,
                width: Math.floor(bounds.width),
                bottom: 'auto'
            });
        }

        this.dropdown.toggle();
        this.select.toggleClass(this.activeClass);
        if (this.additionalStateActiveObject) {
            this.additionalStateActiveObject.toggleClass(this.activeClass);
        }

        if (this.dropdown.is(':visible')) {
            this.doc.on('click', this.clickOutside);
            const bounding = this.dropdown.get(0).getBoundingClientRect();

            if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
                this.select.addClass('selection__select--upwards');
                if (this.appendToBody && bounds) {
                    this.dropdown.css({
                        top: 'auto',
                        bottom: `calc(100% - 1px - ${bounds.top + scroll}px)`
                    });
                }
            }

            if (this.appendToBody) {
                $(window).on('resize', this.debounceResize);
                document.addEventListener('scroll', this.debounceScroll, true);
            }
        } else {
            this.cleanupDropDown();
        }
    }

    clickOutside(event) {
        const target = $(event.target);

        if (target.closest(this.selection).length) {
            return;
        }
        this.toggle();
    }

    setActiveSelection(event) {
        let target;

        if (event.type === 'change') {
            const selectInputValue = $(event.target).find('option:selected').val();
            target = this.dropdown.find(`[data-select-value="${selectInputValue}"]`); // keep <select> and custom select updated with each other
        } else {
            target = $(event.target).closest('[data-js="selection-option"]');
        }

        const value = target.attr('data-select-value');
        const optionContent = target.find('[data-js="selection-content"]').last().clone();
        const optionHTML = fixIDs(optionContent.html());

        if (this.inputHidden.val() !== value) {
            this.inputHidden.val(value);
        }

        if (optionContent.html() !== this.currentText.html()) {
            this.currentText.html(optionHTML);

            if (breakpoint.min('md')) {
                this.changeEvent();
            }
        }

        if (this.inputHidden.val() === 'custom') {
            const dataAttrs = this.inputHidden.find('option:selected').data();

            this.insertCustomInput(dataAttrs);
        }

        this.inputHidden.focus();
    }

    insertCustomInput(AttrObj) {
        const attrPrefix = 'jsCustom';
        const attrArray = Object.entries(AttrObj);
        const newInput = document.createElement('input');
        newInput.classList.add('input');
        newInput.classList.add('input--selection-switch');
        newInput.classList.add('js-inputSelectionSwitch');

        attrArray.forEach(keyValString => {
            const attrName = keyValString[0];
            const attrValue = keyValString[1];
            if (attrName.indexOf(attrPrefix) === -1) return;
            newInput.setAttribute(attrName.replace(attrPrefix, ''), attrValue);
        });

        if (!newInput.getAttribute('name') && this.inputHidden.attr('name')) {
            newInput.setAttribute('name', this.inputHidden.attr('name'));
        }

        this.selection.replaceWith(newInput);

        if (newInput.getAttribute('type') === 'tel' || newInput.getAttribute('type') === 'number') {
            valueThreshold(newInput);
        }

        newInput.focus();
        // Hack to set cursor at end of input field
        const val = newInput.value;
        newInput.value = '';
        newInput.value = val;
    }

    changeEvent() {
        this.inputHidden[0].dispatchEvent(new window.Event('change', { bubbles: true }));
    }
}
