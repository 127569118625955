const simpleToggleObj = {};

export default class SimpleToggle {

    static bind(scope = $('body')) {
        scope.find('[data-js*="simpleToggle:btn"]').each((i, item) => {
            const link = item.dataset.js.split(':').pop();
            const $item = $(item);
            simpleToggleObj[link] = simpleToggleObj[link] || [];

            if ($.inArray($item, simpleToggleObj[link]) !== -1) {
                // If current btn is found in array
                return;
            }

            simpleToggleObj[link].push($item);

            const simpleToggle = new SimpleToggle($item, link);
            simpleToggle.init();
        });

    }

    constructor(btn, link) {
        this.btn = btn;
        this.link = link;
        this.toggle = this.toggle.bind(this);
        this.item = null;
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.btn.on('click', this.toggle);
    }

    toggle(event) {
        const link = this.link;
        const item = $(`[data-js="simpleToggle:item:${link}"]`);
        const visibleBool = item.is(':visible');
        const display = visibleBool ? 'display: none !important' : 'display: block !important';

        item.toggleClass('state-toggled', !visibleBool).attr('style', display);
        simpleToggleObj[link].forEach(btn => btn.toggleClass('state-toggled', !visibleBool));
    }
}
