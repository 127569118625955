import {
    FontHeading1LineHeight,
    FontHeading1FamilySerif,
    FontHeading1WeightSemiBold
} from 'autogen/design-tokens/tokens';
import { rem } from 'modules/helpers/style';
import React, { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type sizes = 'sm' | 'lg';

interface IHeading extends HTMLAttributes<HTMLHeadingElement> {
    size?: sizes;
    children: ReactNode;
}

const headingSizes = {
    sm: css`
        font-size: ${rem(32)};
        line-height: ${rem(40)};
    `,
    lg: css`
        font-size: ${rem(40)};
        line-height: ${rem(48)};
    `
};

const StyledHeading1 = styled.h1<{ size: sizes }>`
    ${({ size }) => headingSizes[size]};
    font-weight: ${FontHeading1WeightSemiBold};
    line-height: ${FontHeading1LineHeight};
    font-family: ${FontHeading1FamilySerif};
`;

export default function Heading1({ size = 'sm', children, ...restProps }: IHeading) {
    return (
        <StyledHeading1 size={size} {...restProps}>
            {children}
        </StyledHeading1>
    );
}
