import React from 'react';
import styled, { css } from 'styled-components';
import {
    ColorNordicGrey1,
    ColorNeutralWhite,
    ColorNeutralBlack,
    Spacing16,
    Spacing32,
    BorderRadiusMedium,
    BorderWidthSmall,
    Spacing12,
    FontLabelLineHeight
} from 'autogen/design-tokens/tokens';
import type ISelect from '../shared/types/select';
import type { SelectSizes } from '../shared/types/select';
import format from 'helpers/translations';

/* #region  styles */
const NativeDropdown = styled.select<{ sizeProp?: SelectSizes }>`
    ${({ sizeProp }) => {
        switch (sizeProp) {
            case 'lg':
                return css`
                    height: 48px;
                    padding: ${Spacing16} ${Spacing32} ${Spacing16} ${Spacing16};
                `;
            case 'md':
                return css`
                    height: 40px;
                    padding: ${Spacing12} ${Spacing32} ${Spacing12} ${Spacing16};
                `;
            default:
                return css`
                    padding: ${Spacing12} ${Spacing32} ${Spacing12} ${Spacing16};
                `;
        }
    }}
    border: ${BorderWidthSmall} solid ${ColorNordicGrey1};
    background-color: ${ColorNeutralWhite};
    color: ${ColorNeutralBlack};
    border-radius: ${BorderRadiusMedium};
    font-size: 14px;
    line-height: ${FontLabelLineHeight};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(/images/icons_arrow.svg);
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right ${Spacing16} center;
`;
/* #endregion */

export default function NativeSelect({ value, onChange, options, placeholderText, size, ...rest }: ISelect) {
    const handleChange = (e) => {
        const selected = options.find((option) => option.value === e.target.value);

        if (selected) {
            onChange(selected);
        }
    };

    return (
        <NativeDropdown value={value?.value} onChange={handleChange} sizeProp={size} {...rest}>
            {placeholderText && (
                <option hidden disabled>
                    {format(placeholderText)}
                </option>
            )}

            {options.map((option) => (
                <option key={option.value} value={option.value} disabled={option.disabled}>
                    {format(option.label)}
                </option>
            ))}
        </NativeDropdown>
    );
}
