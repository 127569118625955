const idleValue = (init: any): { value(): any } => {
    let value;

    const idleHandler = window.requestIdleCallback((): void => {
        value = init();
    });

    return {
        get value(): any {
            if (!value) {
                window.cancelIdleCallback(idleHandler);
                value = init();
            }

            return value;
        }
    };
};

export default idleValue;
