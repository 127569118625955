import { OPEN_INTERESTS_MODAL, SET_INTERESTS } from '../actions/interestsActions';

const initialState = {
    openModal: false,
    interests: null
};

const actions = {};

actions[OPEN_INTERESTS_MODAL] = (state, action) => {
    const { openModal, props } = action;
    return {
        ...props,
        ...state,
        openModal
    };
};

actions[SET_INTERESTS] = (state, action) => {
    return {
        ...state,
        interests: action.interests
    };
};

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
