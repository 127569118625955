import { FontSize12, FontSize14, FontSize16 } from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';
import { type MappedBreakpointStyling, mediaQueryStyling } from 'helpers/mediaQueryStyling';
import type { BreakPointsType } from 'variables';

export const inlineButtonSizes = {
    xs: css`
        font-size: ${FontSize12};
    `,
    sm: css`
        font-size: ${FontSize14};
    `,
    lg: css`
        font-size: ${FontSize16};
    `
};

export type inlineButtonSizeKeys = keyof typeof inlineButtonSizes;
export type inlineButtonSizesObject = BreakPointsType<inlineButtonSizeKeys>;
export type inlineButtonSize = inlineButtonSizesObject | inlineButtonSizeKeys;

type BreakpointSize = MappedBreakpointStyling<inlineButtonSizesObject, typeof inlineButtonSizes>;

export function getButtonSizesMediaQueryStyling(sizesObject: inlineButtonSizesObject) {
    const breakpointSize = Object.keys(sizesObject).reduce<BreakpointSize>((acc, key) => {
        acc[key] = inlineButtonSizes[sizesObject[key]];
        return acc;
    }, {});

    return mediaQueryStyling(breakpointSize);
}

export const sizeStyling = css<{ size?: inlineButtonSize }>`
    ${({ size }) =>
        size && (typeof size === 'object' ? getButtonSizesMediaQueryStyling(size) : inlineButtonSizes[size])}
`;
