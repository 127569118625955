import { forwardRef } from 'react';
import type { IResponsiveImageModel, IBreakpoint } from 'shared/interfaces/responsiveImage';
import styled from 'styled-components';

const StyledImg = styled.img`
    max-width: 100%;
    height: auto;
    width: auto;
`;

function createMediaCondition(breakPoint: IBreakpoint): string {
    const { minWidth, maxWidth } = breakPoint;
    const hasMinWidth = !!minWidth;
    const hasMaxWidth = !!maxWidth;

    const minWidthQuery = `${hasMinWidth ? `(min-width: ${minWidth}px)` : ''}`;
    const maxWidthQuery = `${hasMaxWidth ? `(max-width: ${maxWidth}px)` : ''}`;
    const separator = `${hasMinWidth && hasMaxWidth ? 'and' : ''}`;

    return `${minWidthQuery} ${separator} ${maxWidthQuery}`.trim();
}

const PictureDeprecated = forwardRef(
    (props: IResponsiveImageModel & { lazyLoad?: boolean }, ref: any): JSX.Element | null => {
        const { sources, fallBackUrl, altText, className, lazyLoad } = props;

        if (!fallBackUrl && !sources?.length) {
            return null;
        }

        return (
            <picture className={className}>
                {!!sources?.length &&
                    sources.map((source, index): JSX.Element | null => {
                        if (!source.images) return null;
                        const { images, breakPoint, sizes } = source;
                        const { width, height } = images[0] || {};
                        const srcsetString = images.reduce(
                            (acc, image): string =>
                                `${acc.length ? `${acc}, ` : acc}${image.url} ${image.width ? `${image.width}w` : ''}`,
                            ''
                        );

                        return (
                            <source
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                media={breakPoint && createMediaCondition(breakPoint)}
                                srcSet={srcsetString}
                                width={width}
                                height={height}
                                sizes={sizes}
                            />
                        );
                    })}

                <StyledImg
                    className="img"
                    src={fallBackUrl}
                    alt={altText || ''}
                    ref={ref}
                    loading={lazyLoad ? 'lazy' : 'eager'}
                />
            </picture>
        );
    }
);

export default PictureDeprecated;
