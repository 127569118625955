export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';

export function setToggleFilter(bool) {
    return {
        type: TOGGLE_FILTER,
        bool
    };
}

export function showTooltip(bool) {
    return {
        type: SHOW_TOOLTIP,
        bool
    };
}
