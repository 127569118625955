import React, { type HTMLAttributes, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { buttonTextStyling } from 'components/generic/typography';
import { type ButtonVariant, buttonVariantStyling } from './shared/buttonVariants';
import { type ButtonSizeKeys, buttonSizeStyling } from './shared/buttonSizes';
import type { ButtonSizesObject } from './shared/types/button';
import type IButton from './shared/types/button';
import { withIcon } from 'components/hoc/withIcon/WithIcon';
import withAsyncStatus from 'components/hoc/WithAsyncStatus/WithAsyncStatus';
import withSpinner from 'components/hoc/WithSpinner/WithSpinner';
import type { Spacings } from 'types/tokens';

const buttonDefaultStyling = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;

    a&:hover,
    a&:active,
    a&:focus {
        text-decoration: none;
    }
`;

export const StyledButton = styled.button<{
    variant?: ButtonVariant;
    size: ButtonSizesObject | ButtonSizeKeys;
    disabled?: boolean;
}>`
    //Default styling
    ${buttonDefaultStyling}
    //Font styling
    ${buttonTextStyling}
    //Size styling
    ${buttonSizeStyling}
     //Variant styling
    ${buttonVariantStyling}

    ${({ theme }) => theme.ButtonStyling}
`;

const Button = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IButton>(
    (
        { as, variant = 'primary', size = 'md', disabled, onClick, onClickDisabled, tabIndex, children, ...restProps },
        ref
    ) => (
        <StyledButton
            {...restProps}
            ref={ref}
            as={as}
            variant={variant}
            size={size}
            $disabled={disabled}
            tabIndex={disabled ? '-1' : tabIndex}
            onClick={(event) => {
                if (disabled) {
                    event.preventDefault(); // prevents anchor navigation
                    if (onClickDisabled) onClickDisabled(event);
                    return; // prevents usual onClick events
                }
                if (onClick) onClick(event);
            }}
        >
            {children}
        </StyledButton>
    )
);

const ButtonGroupContainer = styled.div<Pick<ButtonGroupProps, 'gap' | 'equalWidth'>>`
    display: flex;

    ${({ gap }) =>
        gap &&
        css`
            gap: ${gap}px;
        `}

    ${StyledButton} {
        flex-grow: 1;

        ${({ gap }) =>
            !gap &&
            css`
                &:not(:first-child) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left: 0;
                }

                &:not(:last-child) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            `}
    }

    ${({ equalWidth }) =>
        equalWidth &&
        css`
        ${StyledButton} {
            flex: 1 1 0px;
        }
    `}
`;

type ButtonGroupProps = PropsWithChildren &
    HTMLAttributes<HTMLDivElement> & {
        gap?: Spacings;
        equalWidth?: boolean;
    };

export function ButtonGroup(props: ButtonGroupProps) {
    return <ButtonGroupContainer {...props} />;
}

export default Object.assign(Button, {
    Group: ButtonGroup,
    WithIcon: withIcon(Button),
    WithAsyncStatus: withAsyncStatus(Button),
    WithSpinner: withSpinner(withAsyncStatus(Button))
});
