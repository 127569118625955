import { ColorNeutralWhite } from 'autogen/design-tokens/tokens';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import CardShell from './components/CardShell';
import type { CardProps } from './shared/types';

const Card = React.forwardRef<HTMLDivElement, CardProps>(
    ({ children, size = 'md', backgroundColor = ColorNeutralWhite, ...restProps }, ref) => {
        const theme = {
            size,
            backgroundColor
        };

        return (
            <ThemeProvider theme={theme}>
                <CardShell ref={ref} {...restProps}>
                    {children}
                </CardShell>
            </ThemeProvider>
        );
    }
);

export default Card;
