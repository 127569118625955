import styled from 'styled-components';
import breakpoint from 'modules/breakpoint';

const TextWrapper = styled.div`
    @media (min-width: ${breakpoint.sizes.md}px) {
        max-width: ${props => props.theme.width || '600px'};
    }
`;

export default TextWrapper;
