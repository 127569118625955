function scrollToTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
}

export default class ToTop {
    constructor(elm) {
        this.elm = elm;
        this.toggle = this.toggle.bind(this);
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.elm.on('click', event => scrollToTop(event));

        const observerHook = document.querySelector('.js-intersectionObserverToTop');

        if (observerHook) {
            const intersectionObserver = new IntersectionObserver(this.toggle);
            intersectionObserver.observe(observerHook);
        }
    }

    toggle(entries) {
        if (entries[0].intersectionRect.top <= 0) {
            this.elm.addClass('state--active');
        } else {
            this.elm.removeClass('state--active');
        }
    }
}
