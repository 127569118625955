import {
    FontFontFamilyPrimary,
    FontSize16,
    FontLineHeightNormal,
    FontWeightNormal,
    FontWeightSemiBold,
    FontSize14,
    FontSize12,
    FontLineHeightTight
} from 'autogen/design-tokens/tokens';
import { css } from 'styled-components';

const BodyThemeCommon = css`
    font-family: ${FontFontFamilyPrimary};
`;

export const BodyThemeLarge = css`
    ${BodyThemeCommon}
    font-size: ${FontSize16};
    line-height: ${FontLineHeightNormal};
    font-weight: ${FontWeightNormal};
`;

export const BodyThemeLargeSemiBold = css`
    ${BodyThemeLarge}
    font-weight: ${FontWeightSemiBold};
`;

export const BodyThemeMedium = css`
    ${BodyThemeCommon}
    font-size: ${FontSize14};
    line-height: ${FontLineHeightNormal};
    font-weight: ${FontWeightNormal};
`;

export const BodyThemeMediumSemiBold = css`
    ${BodyThemeMedium}
    font-weight: ${FontWeightSemiBold};
`;

export const BodyThemeSmall = css`
    ${BodyThemeCommon}
    font-size: ${FontSize12};
    line-height: ${FontLineHeightTight};
    font-weight: ${FontWeightNormal};
`;

export const BodyThemeSmallSemiBold = css`
    ${BodyThemeSmall}
    font-weight: ${FontWeightSemiBold};
`;
