import type { JavascriptFeatureToggles } from 'autogen/swagger/all';

const featureTogglesObj: JavascriptFeatureToggles = {
    bookingAppVirtualEnabled: false,
    bookingEarlyStageEnabled: false,
    bookingEnabled: false,
    bookingVirtualEnabled: false,
    dawaAutocompleteEnabled: false,
    debugViewEnabled: false,
    findationEnabled: false,
    makeupAdvisorEnabled: false,
    pointAsDiscountEnabled: false,
    powerstepEnabled: false,
    virtualTryOnEnabled: false,
    visualSearchEnabled: false,
    onskeskyenEnabled: false,
    onskeskyenEnabledInApp: false
};

export const setFeatureToggles = (obj: JavascriptFeatureToggles) => {
    for (const item in obj) {
        featureTogglesObj[item] = obj[item];
    }
};

const FeatureToggles = () => featureTogglesObj;

export default FeatureToggles();
