import React, { HtmlHTMLAttributes } from 'react';
import { SvgSprite } from 'modules/svgSprite';
import styled from 'styled-components';
import { useNavigation } from './emblaCarousel';

enum NavigationDirection {
    prev = 'prev',
    next = 'next'
}

const ArrowButton = styled.button<{ fade: boolean }>`
    flex: none;
    background: 0;
    border: 0;
    padding: 0;
    pointer-events: ${({ fade }) => (fade ? 'none' : 'all')};
    opacity: ${({ fade }) => (fade ? 0 : 1)};
    transition: opacity 100ms ease;
`;

const ArrowElement = styled(SvgSprite)<{ direction?: NavigationDirection }>`
    transform: rotate(${({ direction }): number => (direction === NavigationDirection.prev ? 90 : -90)}deg);
`;

function ArrowComponent(
    props: { direction: NavigationDirection } & HtmlHTMLAttributes<HTMLButtonElement>
): JSX.Element {
    const { direction, ...rest } = props;
    const navigation = useNavigation();

    const fade =
        (direction === NavigationDirection.prev && !navigation.canScrollPrev) ||
        (direction === NavigationDirection.next && !navigation.canScrollNext);

    return (
        <ArrowButton type="button" onClick={navigation[direction]} {...rest} fade={fade}>
            <ArrowElement sprite="Arrow" direction={direction} />
        </ArrowButton>
    );
}

export function Prev(props): JSX.Element {
    return <ArrowComponent direction={NavigationDirection.prev} {...props} />;
}

export function Next(props): JSX.Element {
    return <ArrowComponent direction={NavigationDirection.next} {...props} />;
}
