import type { JavascriptRequestDataModel } from 'autogen/swagger/all';

const JavascriptRequestDataContextObj: JavascriptRequestDataModel = {
    featureToggles: {
        bookingAppVirtualEnabled: false,
        bookingEarlyStageEnabled: false,
        bookingEnabled: false,
        bookingVirtualEnabled: false,
        dawaAutocompleteEnabled: false,
        debugViewEnabled: false,
        findationEnabled: false,
        makeupAdvisorEnabled: false,
        pointAsDiscountEnabled: false,
        powerstepEnabled: false,
        virtualTryOnEnabled: false,
        visualSearchEnabled: false,
        onskeskyenEnabled: false,
        onskeskyenEnabledInApp: false
    },
    javascriptPageContext: {},
    requestContextModel: {
        acceptedCookieCategories: [],
        isClubMatasApp: false,
        isClubMatasAppApplePaySupport: false,
        isClubMatasLightPdp: false,
        supportsNativeBackArrow: false,
        supportsNativeTitleBar: false
    },
    segmentation: {
        countryCode: '',
        countryName: '',
        culture: '',
        mobileLength: 0,
        mobilePrefix: 0,
        name: '',
        primaryWebsiteUrl: '',
        recoilName: '',
        seoCulture: '',
        tenant: undefined,
        zipCodeLength: 0
    },
    userContextModel: {
        permissionRequired: false,
        userHasSeenProductPage: false,
        isMatasPlusMember: false,
        isClubMatasMember: false,
        isGa4Debug: false,
        isAuthenticated: false,
        loginDirectLink: {
            loginLinkAction: {
                $type: '',
                advisorId: 0,
                url: '',
                noFollow: false,
                openInNewWindow: false
            },
            loginModal: {
                linkAction: {
                    $type: '',
                    advisorId: 0,
                    url: '',
                    noFollow: false,
                    openInNewWindow: false
                },
                modifier: '',
                trackingAction: 'None'
            }
        },
        splitTestAssignments: []
    },
    websiteTime: {
        load: 0
    },
    websiteSettings: {
        enhancedEcommerceTrackingEnabled: false,
        liveChat: {
            customerServiceChatDisabledText: '',
            isCustomerServiceChatEnabled: false,
            customerServiceChatOnlyEnabled: false,
            advisorChatDisabledText: '',
            isAdvisorChatEnabled: false,
            isChatEnabled: false,
            isZowieEnabled: false,
            zowieInstanceId: '',
            zowieZendeskAccountKey: '',
            openZowieOnLoad: false
        }
    }
};

export const setJavascriptRequestDataContext = (obj: JavascriptRequestDataModel): void => {
    Object.assign(JavascriptRequestDataContextObj, obj);
};

const JavascriptRequestDataContext = (): JavascriptRequestDataModel => JavascriptRequestDataContextObj;

export default JavascriptRequestDataContext;
