import GetScalingAttr from 'helpers/imageScalingHelper';
import { forwardRef } from 'react';
import Image from '../Image';
import { WithScalingProps } from '../shared/types';

const WithScaling = forwardRef<HTMLImageElement, WithScalingProps>((props, ref) => {
    const { sizes, src, transformType, imageType, ...restProps } = props;

    return <Image ref={ref} {...GetScalingAttr({ src, sizes, transformType, imageType })} {...restProps} />;
});

export default WithScaling;
