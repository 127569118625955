import { hasParamByKey } from 'modules/helpers/urlParams';
import { TrackEventUrl } from 'autogen/swagger/tracking';
import { IProductActionTrackingContext } from 'components/generic/productCTA/shared/types';
import type { IBundleInfoMaybe, TrackingModelDTOMaybe } from 'autogen/swagger/product';
import { type TrackingCategory } from 'modules/tracking/UAEventTracking';
import { UATrackingCategory } from 'autogen/sharedEnums';
import { TrackingEvent } from './types';

export default function serverSideTracking(JsonData: string, eventType: TrackingEvent) {
    const isSubscription = hasParamByKey('subscription');

    if (eventType && JsonData != null && JsonData.length > 0 && isSubscription === false) {
        const formData = new FormData();
        formData.append('jsonData', JsonData); // Parameter name must match endpoint parameter name
        formData.append('eventType', eventType); // Parameter name must match endpoint parameter name
        fetch(TrackEventUrl, { body: formData, method: 'post', keepalive: true });
    }
}

/* Create tracking context, (It was ported over from razer view and needed to be identical) (as u guessed it was not clean in the razer view) */
export function createProductItemTrackingContext(
    eventTrackingCategory: TrackingCategory,
    eventTrackingLabel?: string,
    trackModel?: TrackingModelDTOMaybe,
    bundleInfo?: IBundleInfoMaybe,
    recommendationPlacement?: string
): IProductActionTrackingContext {
    const trackingContext: IProductActionTrackingContext = {
        type: 'List',
        eventCategory: eventTrackingCategory
    };

    if (eventTrackingCategory === 'productGroupRecomendation') {
        trackingContext.type = 'ProductGroupRecomendation';
        trackingContext.params = { recommendationPlacement };
    }

    if (eventTrackingLabel) {
        trackingContext.eventLabel = eventTrackingLabel;
    }

    if (bundleInfo) {
        trackingContext.type = 'Bundle';
    }

    if (trackModel) {
        trackingContext.serverSideTrackingModel = {
            data: JSON.stringify(trackModel),
            eventType: TrackingEvent.ProductClickAndAddToBasket
        };
    }

    return trackingContext;
}
