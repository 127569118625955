import { SET_HERO_CTA_DATA } from '../actions/heroCTAActions';

const initialState = {
    isChatNudgingEnabled: false,
    isOnline: false
};

const actions = {};
actions[SET_HERO_CTA_DATA] = (state, action) => ({
    ...state,
    ...action.data
});

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
