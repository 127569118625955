import React, { lazy } from 'react';
import { submitToBasket, isPowerstepActive } from './addAndRemoveFromBasket';

const DefaultLayout = lazy(() => import('modules/modal/layouts'));

const updatePowerstep = (event, button) => {
    const url = button.dataset.jsBundleCart;
    submitToBasket(event, url);
};

const openBundleModal = async urlAttr => {
    const [url, modifiers = ''] = urlAttr.split(':');

    const { default: htmlModal } = await import('modules/modal/htmlModal');

    const modal = htmlModal({
        layout: { component: <DefaultLayout className={modifiers} /> }
    });

    modal({
        url
    });
};

const handleBundleAddToBasket = event => {
    const button = event.target;

    if (isPowerstepActive()) {
        updatePowerstep(event, button);
    } else {
        const bundleUrl = button.dataset.jsBundleUrl;
        openBundleModal(bundleUrl);
    }
};

export default class AddBundleToBasket {
    init() {
        this.bindings();
    }

    bindings = () => {
        $(document).on('click', '.js-productBundle', event => handleBundleAddToBasket(event));
    };
}
