import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import * as transKey from 'autogen/translation-keys/trans-root-website';
import { useTranslation } from 'react-i18next';
import { Button, ButtonWithIcon } from 'components/Button';
import { ColorBrandBlue2 } from 'autogen/design-tokens/tokens';
import IButton from 'components/Button/shared/types/button';
import { itemState } from '../shared/types';
import { SelectItemCallbackContext } from '../hooks/contexts';

const sharedButtonStyle = css`
    width: 100%;
`;

const StyledButton = styled(Button)`
    ${sharedButtonStyle};
`;

const StyledButtonWithIcon = styled(ButtonWithIcon)`
    ${sharedButtonStyle};
`;

const ChosenButton = styled(ButtonWithIcon)`
    ${sharedButtonStyle};
    background-color: ${ColorBrandBlue2};
`;

interface IChooseButton extends IButton {
    state: itemState;
    productId: number;
    isRecommended: boolean;
}

export default function ChooseButton({ state, productId, isRecommended, ...restProps }: IChooseButton): JSX.Element {
    const selectItemCallback = useContext(SelectItemCallbackContext);
    const { t } = useTranslation();
    const { ChooseSampleButton, ChosenSampleButton, LockedSampleButton } = transKey.Basket.Samples.SamplesPicker;

    switch (state) {
        case 'locked':
            return (
                <StyledButtonWithIcon icon="LockOutline" disabled {...restProps}>
                    {t(LockedSampleButton)}
                </StyledButtonWithIcon>
            );
        case 'chosen':
            return (
                <ChosenButton
                    icon="Checkmark"
                    {...restProps}
                    onClick={() => {
                        selectItemCallback('chosen', productId, isRecommended);
                    }}
                >
                    {t(ChosenSampleButton)}
                </ChosenButton>
            );
        default:
            return (
                <StyledButton
                    {...restProps}
                    onClick={() => {
                        selectItemCallback('choose', productId, isRecommended);
                    }}
                >
                    {t(ChooseSampleButton)}
                </StyledButton>
            );
    }
}
