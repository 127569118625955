import { ColorNeutralWhite } from 'autogen/design-tokens/tokens';
import type { Size } from 'components/List/shared/types';
import getTypeStyling from 'helpers/getTypeStyling';
import useSetDialogFocusEffect from 'hooks/globals/useSetDialogFocusEffect';
import React, { type HtmlHTMLAttributes, type ReactNode, forwardRef, useRef } from 'react';
import styled, { ThemeProvider, css } from 'styled-components';

const MenuSizes = {
    sm: css``,
    md: css``
};

const Menu = styled.div<{ size?: IMenu['size'] }>`
    display: flex;
    flex-direction: column;
    background-color: ${ColorNeutralWhite};
    ${({ size, theme }) =>
        size
            ? getTypeStyling((resolvedSize: Size) => MenuSizes[resolvedSize])(size as Size)
            : getTypeStyling((resolvedSize: Size) => MenuSizes[resolvedSize])(theme.size)}
`;

interface IMenu extends HtmlHTMLAttributes<HTMLDivElement> {
    size?: Size;
    shouldFocus?: boolean;
    children: ReactNode;
}

const DefaultMenu = ({ size = 'md', children, shouldFocus = false, ...restProps }: IMenu) => {
    const ref = useRef<HTMLDivElement>(null);
    const theme = {
        size
    };
    useSetDialogFocusEffect(ref, shouldFocus);

    return (
        <ThemeProvider theme={theme}>
            <Menu ref={ref} tabIndex={-1} {...restProps}>
                {children}
            </Menu>
        </ThemeProvider>
    );
};

export default DefaultMenu;
