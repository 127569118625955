
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './summary';

        export type CheckoutTrackingInfo = components['schemas']['CheckoutTrackingInfo'];
                    export type CheckoutUserCommentInfo = components['schemas']['CheckoutUserCommentInfo'];
                    export type CheckoutOtcTerms = components['schemas']['CheckoutOtcTerms'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type CheckoutPermissionInfo = components['schemas']['CheckoutPermissionInfo'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModel = components['schemas']['CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModel'];
                    export type CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['CheckoutBasketModelsProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type CheckoutBasketProgressiveDiscountViewModel = components['schemas']['CheckoutBasketProgressiveDiscountViewModel'];
                    export type CheckoutBasketProgressiveDiscountViewModelMaybe = components['schemas']['CheckoutBasketProgressiveDiscountViewModelMaybe'];
                    export type UATrackingAction = components['schemas']['UATrackingAction'];
                    export type ModalLink = components['schemas']['ModalLink'];
                    export type ModalLinkMaybe = components['schemas']['ModalLinkMaybe'];
                    export type LoginDirectLink = components['schemas']['LoginDirectLink'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type CheckoutBasketTriggeredDiscountViewModel = components['schemas']['CheckoutBasketTriggeredDiscountViewModel'];
                    export type CheckoutBasketPotentialDiscountViewModel = components['schemas']['CheckoutBasketPotentialDiscountViewModel'];
                    export type PointDiscountViewModel = components['schemas']['PointDiscountViewModel'];
                    export type CheckoutBasketDiscountInfoViewModel = components['schemas']['CheckoutBasketDiscountInfoViewModel'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type BasketLinePriceInfoViewModel = components['schemas']['BasketLinePriceInfoViewModel'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type CheckoutBasketModelsSubscriptionInfoViewModel = components['schemas']['CheckoutBasketModelsSubscriptionInfoViewModel'];
                    export type CheckoutBasketModelsSubscriptionInfoViewModelMaybe = components['schemas']['CheckoutBasketModelsSubscriptionInfoViewModelMaybe'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type StockStatusViewModel = components['schemas']['StockStatusViewModel'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type StockStatusWithPreciseDeliveryAndModalViewModel = components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel'];
                    export type StockStatusWithPreciseDeliveryViewModel = components['schemas']['StockStatusWithPreciseDeliveryViewModel'];
                    export type IStockStatusViewModelMaybe = components['schemas']['IStockStatusViewModelMaybe'];
                    export type CheckoutBasketLineViewModel = components['schemas']['CheckoutBasketLineViewModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type IPointInfo = components['schemas']['IPointInfo'];
                    export type SingleSubscriptionPrice = components['schemas']['SingleSubscriptionPrice'];
                    export type SingleSubscriptionPriceMaybe = components['schemas']['SingleSubscriptionPriceMaybe'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type ISubscriptionIntroPriceInfo = components['schemas']['ISubscriptionIntroPriceInfo'];
                    export type ISubscriptionIntroPriceInfoMaybe = components['schemas']['ISubscriptionIntroPriceInfoMaybe'];
                    export type SubscriptionIntroPriceState = components['schemas']['SubscriptionIntroPriceState'];
                    export type SubscriptionPriceInfo = components['schemas']['SubscriptionPriceInfo'];
                    export type SubscriptionInfoModel = components['schemas']['SubscriptionInfoModel'];
                    export type SubscriptionInfoModelMaybe = components['schemas']['SubscriptionInfoModelMaybe'];
                    export type CheckoutBasketBookingLineViewModel = components['schemas']['CheckoutBasketBookingLineViewModel'];
                    export type CheckoutBasketGiftLineViewModel = components['schemas']['CheckoutBasketGiftLineViewModel'];
                    export type CheckoutBasketSampleLineViewModel = components['schemas']['CheckoutBasketSampleLineViewModel'];
                    export type CheckoutGiftCardLine = components['schemas']['CheckoutGiftCardLine'];
                    export type CheckoutGiftCardInfo = components['schemas']['CheckoutGiftCardInfo'];
                    export type PaymentType = components['schemas']['PaymentType'];
                    export type PaymentCardType = components['schemas']['PaymentCardType'];
                    export type CheckoutPaymentTypeModel = components['schemas']['CheckoutPaymentTypeModel'];
                    export type CheckoutPaymentTypeList = components['schemas']['CheckoutPaymentTypeList'];
                    export type BoxButton = components['schemas']['BoxButton'];
                    export type PaymentCardChangeChoiceModel = components['schemas']['PaymentCardChangeChoiceModel'];
                    export type PaymentCardOptionModel = components['schemas']['PaymentCardOptionModel'];
                    export type BoxButtonWithCreditCardOption = components['schemas']['BoxButtonWithCreditCardOption'];
                    export type BasketInfoBoxType = components['schemas']['BasketInfoBoxType'];
                    export type BasketInfoBox = components['schemas']['BasketInfoBox'];
                    export type IBasketInfoBoxMaybe = components['schemas']['IBasketInfoBoxMaybe'];
                    export type ISupplierGroup = components['schemas']['ISupplierGroup'];
                    export type CheckoutBasketGroupViewModel = components['schemas']['CheckoutBasketGroupViewModel'];
                    export type BasketSubTotalSummary = components['schemas']['BasketSubTotalSummary'];
                    export type BasketTotalSummary = components['schemas']['BasketTotalSummary'];
                    export type BasketShippingPriceSummary = components['schemas']['BasketShippingPriceSummary'];
                    export type BasketShippingPriceSummaryMaybe = components['schemas']['BasketShippingPriceSummaryMaybe'];
                    export type BasketDiscountSummary = components['schemas']['BasketDiscountSummary'];
                    export type BasketDiscountSummaryMaybe = components['schemas']['BasketDiscountSummaryMaybe'];
                    export type AdditionalSummaryLine = components['schemas']['AdditionalSummaryLine'];
                    export type BasketAdditionalSummary = components['schemas']['BasketAdditionalSummary'];
                    export type BasketAdditionalSummaryMaybe = components['schemas']['BasketAdditionalSummaryMaybe'];
                    export type BasketGiftCardSummary = components['schemas']['BasketGiftCardSummary'];
                    export type BasketGiftCardSummaryMaybe = components['schemas']['BasketGiftCardSummaryMaybe'];
                    export type VoucherSummaryLine = components['schemas']['VoucherSummaryLine'];
                    export type BasketVoucherSummary = components['schemas']['BasketVoucherSummary'];
                    export type BasketVoucherSummaryMaybe = components['schemas']['BasketVoucherSummaryMaybe'];
                    export type PrizeCertificateModel = components['schemas']['PrizeCertificateModel'];
                    export type BasketPrizeCertificateSummary = components['schemas']['BasketPrizeCertificateSummary'];
                    export type BasketPrizeCertificateSummaryMaybe = components['schemas']['BasketPrizeCertificateSummaryMaybe'];
                    export type BasketSummaryViewModel = components['schemas']['BasketSummaryViewModel'];
                    export type CheckoutGroupedBasketViewModel = components['schemas']['CheckoutGroupedBasketViewModel'];
                    export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type GoToPaymentButtonViewModel = components['schemas']['GoToPaymentButtonViewModel'];
                    export type CheckoutSummaryViewModel = components['schemas']['CheckoutSummaryViewModel'];
                    export type CheckoutPermissionInfoCore = components['schemas']['CheckoutPermissionInfoCore'];
                    export type CheckoutSummaryPostModel = components['schemas']['CheckoutSummaryPostModel'];
                    export type CheckoutGroupedBasketViewModelMaybe = components['schemas']['CheckoutGroupedBasketViewModelMaybe'];
                    export type GroupedBasketResultModel = components['schemas']['GroupedBasketResultModel'];
                    export type RedirectGroupedBasketResultModel = components['schemas']['RedirectGroupedBasketResultModel'];
                    

        
        

        

        export type CheckoutSummaryViewModelSuccess = paths['/internal/ModelOnly/CheckoutSummaryViewModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutSummaryViewModelUrl = '/internal/ModelOnly/CheckoutSummaryViewModel';

        
        export function CheckoutSummaryViewModel(): Promise<CheckoutSummaryViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutSummaryViewModelSuccess>(`/internal/ModelOnly/CheckoutSummaryViewModel`)
        }
    
    
        

        

        export type CheckoutSummaryPostModelSuccess = paths['/internal/ModelOnly/CheckoutSummaryPostModel']['get']['responses']['200']['content']['text/plain'];

        export const CheckoutSummaryPostModelUrl = '/internal/ModelOnly/CheckoutSummaryPostModel';

        
        export function CheckoutSummaryPostModel(): Promise<CheckoutSummaryPostModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<CheckoutSummaryPostModelSuccess>(`/internal/ModelOnly/CheckoutSummaryPostModel`)
        }
    
    
        

        

        export type GetGroupedBasketSuccess = paths['/internal/checkout/Summary/GetGroupedBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetGroupedBasketUrl = '/internal/checkout/Summary/GetGroupedBasket';

        
        export function GetGroupedBasket(): Promise<GetGroupedBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetGroupedBasketSuccess>(`/internal/checkout/Summary/GetGroupedBasket`)
        }
    
    
        

        export type UpdateHasUserCommentParams = paths['/internal/checkout/Summary/UpdateHasUserComment']['post']['parameters']['query'];

        

        export const UpdateHasUserCommentUrl = '/internal/checkout/Summary/UpdateHasUserComment';

        
        export function UpdateHasUserComment(params: UpdateHasUserCommentParams): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<unknown>(`/internal/checkout/Summary/UpdateHasUserComment${formatParams(params)}`)
        }
    
    
        

        export type ActivateGiftCardParams = paths['/internal/checkout/Summary/ActivateGiftCard']['post']['parameters']['query'];

        export type ActivateGiftCardSuccess = paths['/internal/checkout/Summary/ActivateGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const ActivateGiftCardUrl = '/internal/checkout/Summary/ActivateGiftCard';

        
        export function ActivateGiftCard(params: ActivateGiftCardParams): Promise<ActivateGiftCardSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ActivateGiftCardSuccess>(`/internal/checkout/Summary/ActivateGiftCard${formatParams(params)}`)
        }
    
    
        

        export type RemoveGiftCardLineParams = paths['/internal/checkout/Summary/RemoveGiftCardLine']['post']['parameters']['query'];

        export type RemoveGiftCardLineSuccess = paths['/internal/checkout/Summary/RemoveGiftCardLine']['post']['responses']['200']['content']['text/plain'];

        export const RemoveGiftCardLineUrl = '/internal/checkout/Summary/RemoveGiftCardLine';

        
        export function RemoveGiftCardLine(params: RemoveGiftCardLineParams): Promise<RemoveGiftCardLineSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<RemoveGiftCardLineSuccess>(`/internal/checkout/Summary/RemoveGiftCardLine${formatParams(params)}`)
        }
    
    
        

        

        export type ActivatePaymentByGiftCardSuccess = paths['/internal/checkout/Summary/ActivatePaymentByGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const ActivatePaymentByGiftCardUrl = '/internal/checkout/Summary/ActivatePaymentByGiftCard';

        
        export function ActivatePaymentByGiftCard(): Promise<ActivatePaymentByGiftCardSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ActivatePaymentByGiftCardSuccess>(`/internal/checkout/Summary/ActivatePaymentByGiftCard`)
        }
    
    
        

        

        export type DeactivatePaymentByGiftCardSuccess = paths['/internal/checkout/Summary/DeactivatePaymentByGiftCard']['post']['responses']['200']['content']['text/plain'];

        export const DeactivatePaymentByGiftCardUrl = '/internal/checkout/Summary/DeactivatePaymentByGiftCard';

        
        export function DeactivatePaymentByGiftCard(): Promise<DeactivatePaymentByGiftCardSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeactivatePaymentByGiftCardSuccess>(`/internal/checkout/Summary/DeactivatePaymentByGiftCard`)
        }
    
    
        

        

        export type AddGiftCardLineSuccess = paths['/internal/checkout/Summary/AddGiftCardLine']['post']['responses']['200']['content']['text/plain'];

        export const AddGiftCardLineUrl = '/internal/checkout/Summary/AddGiftCardLine';

        
        export function AddGiftCardLine(): Promise<AddGiftCardLineSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddGiftCardLineSuccess>(`/internal/checkout/Summary/AddGiftCardLine`)
        }
    
    
        

        

        export type GetGiftCardInfoSuccess = paths['/internal/checkout/Summary/GetGiftCardInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetGiftCardInfoUrl = '/internal/checkout/Summary/GetGiftCardInfo';

        
        export function GetGiftCardInfo(): Promise<GetGiftCardInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetGiftCardInfoSuccess>(`/internal/checkout/Summary/GetGiftCardInfo`)
        }
    
    
        

        

        export type GetUserCommentInfoSuccess = paths['/internal/checkout/Summary/GetUserCommentInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetUserCommentInfoUrl = '/internal/checkout/Summary/GetUserCommentInfo';

        
        export function GetUserCommentInfo(): Promise<GetUserCommentInfoSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetUserCommentInfoSuccess>(`/internal/checkout/Summary/GetUserCommentInfo`)
        }
    
    

        export * from './discriminators';
    