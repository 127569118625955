import { type PropsWithChildren, createContext, useContext } from 'react';
import type { UATrackingProps } from '../types';

type TrackingContext = Pick<UATrackingProps, 'context' | 'category' | 'eventParams'>;
type TrackingContextProps = PropsWithChildren & TrackingContext;

export const Context = createContext<TrackingContext>(null);

export default function TrackingContext(props: TrackingContextProps) {
    const { children, ...restProps } = props;
    const parentContext = useContext(Context);

    return <Context.Provider value={{ ...parentContext, ...restProps }}>{children}</Context.Provider>;
}
