import {
    ColorNeutralBlack,
    FontOverlineFamily,
    FontOverlineLetterSpacing,
    FontOverlineLineHeight,
    FontOverlineTextTransform,
    FontOverlineWeight
} from 'autogen/design-tokens/tokens';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type sizes = 'sm' | 'md' | 'lg';

interface IHeading {
    size?: sizes;
    children: ReactNode;
}

const overlineSizes = {
    sm: css`
        font-size: 8px;
        line-height: ${FontOverlineLineHeight};
    `,
    md: css`
        font-size: 12px;
        line-height: ${FontOverlineLineHeight};
    `,
    lg: css`
        font-size: 14px;
        line-height: ${FontOverlineLineHeight};
    `
};

const StyledOverline = styled.span<{ size: sizes }>`
    ${({ size }) => overlineSizes[size]};
    font-weight: ${FontOverlineWeight};
    text-transform: ${FontOverlineTextTransform};
    font-family: ${FontOverlineFamily};
    letter-spacing: ${FontOverlineLetterSpacing};
    color: ${ColorNeutralBlack};
`;

export default function Overline({ size = 'md', children, ...restProps }: IHeading) {
    return (
        <StyledOverline size={size} {...restProps}>
            {children}
        </StyledOverline>
    );
}
