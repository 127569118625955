import { drawerReferenceAtom } from 'components/generic/sideDrawer/SideDrawer';
import ReactDOM from 'react-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { smartviewInstanceAtom } from '../../shared/recoil/atoms';
import type { ISmartSideDrawerView } from '../shared/types';
import AnimatedDrawerView from './AnimatedDrawerView';

export const DrawerViewComponent = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
`;

/**
 *
 * @param name - name of the drawer view is required, will be used as an identifier when trying to access the view through the smartviewManager
 * @returns React.ReactPortal
 */
export default function SmartSideDrawerView({
    name,
    children,
    style,
    animate = true,
    ...restProps
}: ISmartSideDrawerView) {
    const { isOpen, position } = useRecoilValue(smartviewInstanceAtom(name));

    const drawerContainerReference = useRecoilValue(drawerReferenceAtom); // Drawer container reference

    if (!drawerContainerReference) return null;

    if (!animate) {
        const positionStyle = position === 'right' ? { right: 0 } : { left: 0 };

        return (
            isOpen &&
            ReactDOM.createPortal(
                <DrawerViewComponent style={{ ...style, ...positionStyle }} {...restProps}>
                    {children}
                </DrawerViewComponent>,
                drawerContainerReference
            )
        );
    }

    return ReactDOM.createPortal(
        <AnimatedDrawerView name={name} style={style} {...restProps}>
            {children}
        </AnimatedDrawerView>,
        drawerContainerReference
    );
}
