
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './minibasket';

        export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLineModel = components['schemas']['ProductNameLineModel'];
                    export type ProductNameViewModel = components['schemas']['ProductNameViewModel'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type BasketLinePriceInfoViewModel = components['schemas']['BasketLinePriceInfoViewModel'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type UATrackingAction = components['schemas']['UATrackingAction'];
                    export type ModalLink = components['schemas']['ModalLink'];
                    export type ModalLinkMaybe = components['schemas']['ModalLinkMaybe'];
                    export type LoginDirectLink = components['schemas']['LoginDirectLink'];
                    export type DiscountTextType = components['schemas']['DiscountTextType'];
                    export type TriggeredDiscountViewModel = components['schemas']['TriggeredDiscountViewModel'];
                    export type PotentialDiscountViewModel = components['schemas']['PotentialDiscountViewModel'];
                    export type DiscountText = components['schemas']['DiscountText'];
                    export type ProgressiveDiscountBasketStepTextViewModel = components['schemas']['ProgressiveDiscountBasketStepTextViewModel'];
                    export type ProgressiveDiscountBasketStepTextViewModelMaybe = components['schemas']['ProgressiveDiscountBasketStepTextViewModelMaybe'];
                    export type BasketProgressiveDiscountViewModel = components['schemas']['BasketProgressiveDiscountViewModel'];
                    export type BasketProgressiveDiscountViewModelMaybe = components['schemas']['BasketProgressiveDiscountViewModelMaybe'];
                    export type PointDiscountViewModel = components['schemas']['PointDiscountViewModel'];
                    export type DiscountInfoViewModel = components['schemas']['DiscountInfoViewModel'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type BasketSubscriptionInfoViewModel = components['schemas']['BasketSubscriptionInfoViewModel'];
                    export type BasketSubscriptionInfoViewModelMaybe = components['schemas']['BasketSubscriptionInfoViewModelMaybe'];
                    export type MiniBasketLineViewModel = components['schemas']['MiniBasketLineViewModel'];
                    export type MiniBasketGiftLineViewModel = components['schemas']['MiniBasketGiftLineViewModel'];
                    export type MiniBasketSampleLineViewModel = components['schemas']['MiniBasketSampleLineViewModel'];
                    export type BasketSubTotalSummary = components['schemas']['BasketSubTotalSummary'];
                    export type BasketTotalSummary = components['schemas']['BasketTotalSummary'];
                    export type BasketShippingPriceSummary = components['schemas']['BasketShippingPriceSummary'];
                    export type BasketShippingPriceSummaryMaybe = components['schemas']['BasketShippingPriceSummaryMaybe'];
                    export type BasketDiscountSummary = components['schemas']['BasketDiscountSummary'];
                    export type BasketDiscountSummaryMaybe = components['schemas']['BasketDiscountSummaryMaybe'];
                    export type AdditionalSummaryLine = components['schemas']['AdditionalSummaryLine'];
                    export type BasketAdditionalSummary = components['schemas']['BasketAdditionalSummary'];
                    export type BasketAdditionalSummaryMaybe = components['schemas']['BasketAdditionalSummaryMaybe'];
                    export type VoucherSummaryLine = components['schemas']['VoucherSummaryLine'];
                    export type BasketVoucherSummary = components['schemas']['BasketVoucherSummary'];
                    export type BasketVoucherSummaryMaybe = components['schemas']['BasketVoucherSummaryMaybe'];
                    export type PrizeCertificateModel = components['schemas']['PrizeCertificateModel'];
                    export type BasketPrizeCertificateSummary = components['schemas']['BasketPrizeCertificateSummary'];
                    export type BasketPrizeCertificateSummaryMaybe = components['schemas']['BasketPrizeCertificateSummaryMaybe'];
                    export type MiniBasketSummaryViewModel = components['schemas']['MiniBasketSummaryViewModel'];
                    export type LoginDirectLinkMaybe = components['schemas']['LoginDirectLinkMaybe'];
                    export type BasketMessageDiscountViewModel = components['schemas']['BasketMessageDiscountViewModel'];
                    export type BasketMessageType = components['schemas']['BasketMessageType'];
                    export type BasketMessageGwpViewModel = components['schemas']['BasketMessageGwpViewModel'];
                    export type BasketMessageMatasPlusViewModel = components['schemas']['BasketMessageMatasPlusViewModel'];
                    export type BasketMessageSameDayDeliveryViewModel = components['schemas']['BasketMessageSameDayDeliveryViewModel'];
                    export type BasketMessageSampleGroupViewModel = components['schemas']['BasketMessageSampleGroupViewModel'];
                    export type BasketMessageShippingViewModel = components['schemas']['BasketMessageShippingViewModel'];
                    export type BasketMessageWrapper = components['schemas']['BasketMessageWrapper'];
                    export type BasketMessageWrapperMaybe = components['schemas']['BasketMessageWrapperMaybe'];
                    export type MiniBasketViewModel = components['schemas']['MiniBasketViewModel'];
                    

        
        

        

        export type _MiniBasketSuccess = paths['/internal/MiniBasket/_MiniBasket']['get']['responses']['200']['content']['text/html'];

        export const _MiniBasketUrl = '/internal/MiniBasket/_MiniBasket';

        
        export function _MiniBasket(): Promise<_MiniBasketSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchHTML(`/internal/MiniBasket/_MiniBasket`)
        }
    
    
        

        

        export type MiniBasketIndexSuccess = paths['/internal/MiniBasket/Index']['get']['responses']['200']['content']['text/plain'];

        export const MiniBasketIndexUrl = '/internal/MiniBasket/Index';

        
        export function MiniBasketIndex(): Promise<MiniBasketIndexSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<MiniBasketIndexSuccess>(`/internal/MiniBasket/Index`)
        }
    
    

        export * from './discriminators';
    