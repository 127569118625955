import GetScalingAttr, { type GetScalingAttrProps } from 'helpers/imageScalingHelper';
import type { ResponsiveImageBreakPoint } from 'autogen/swagger/page';
import type { PictureSourceProps } from './shared/types';

function createMediaCondition(breakPoint: ResponsiveImageBreakPoint): string {
    const { minWidth, maxWidth } = breakPoint;
    const hasMinWidth = !!minWidth;
    const hasMaxWidth = !!maxWidth;

    const minWidthQuery = `${hasMinWidth ? `(min-width: ${minWidth}px)` : ''}`;
    const maxWidthQuery = `${hasMaxWidth ? `(max-width: ${maxWidth}px)` : ''}`;
    const separator = `${hasMinWidth && hasMaxWidth ? 'and' : ''}`;

    return `${minWidthQuery} ${separator} ${maxWidthQuery}`.trim();
}

export default function PictureSource({
    source,
    transformType,
    imageType
}: {
    source: PictureSourceProps;
    transformType: GetScalingAttrProps['transformType'];
    imageType?: GetScalingAttrProps['imageType'];
}) {
    const {
        breakpoint: { minWidth, maxWidth },
        src,
        sizes,
        height,
        width
    } = source;
    const { srcSet, sizes: sizeAttr } = GetScalingAttr({ src, sizes, transformType, imageType });

    return (
        <source
            media={createMediaCondition({ minWidth, maxWidth })}
            srcSet={srcSet}
            sizes={sizeAttr}
            height={height}
            width={width}
        />
    );
}
