import React, { HTMLAttributes, useContext } from 'react';
import styled from 'styled-components';
import { EmblaContext } from './emblaCarousel';

const ViewportElement = styled.div`
    overflow: hidden;
`;

const Viewport = (props: HTMLAttributes<HTMLDivElement>): JSX.Element => {
    const [emblaRef] = useContext(EmblaContext);
    return <ViewportElement ref={emblaRef} {...props} />;
};

export default Viewport;
