import { selectorFamily, DefaultValue, ResetRecoilState, SetRecoilState, GetRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { SmartviewSerializableParams, ISmartview } from '../types';
import {
    smartviewInstancesIdsAtom,
    smartviewInstanceAtom,
    latestSmartviewActionAtom,
    SmartviewInstanceDetails
} from './atoms';

/* --------- Helpers  -------- */

const buildSmartview = ({ name, isOpen, position, instanceType }: ISmartview) => ({
    name,
    isOpen,
    position,
    instanceType,
    instanceId: uuidv4()
});

const getAction = (current: SmartviewInstanceDetails[], prev: SmartviewInstanceDetails[]) => {
    if (!prev.length) return 'initialized';
    if (!current.length && prev.length > 0) return 'terminated';
    if (current.length > prev.length) return 'add';
    if (current.length < prev.length) return 'remove';
    if (current.length === prev.length) return 'replace';

    return undefined;
};

/**
 * Resets a smartview to it's default value
 * @param name - the smartview to reset
 * @param reset - recoil resetter
 * @param set - recoil setter
 * @returns void
 */
const resetSmartview = (name: ISmartview['name'], reset: ResetRecoilState, set: SetRecoilState) => {
    set(smartviewInstancesIdsAtom, (prevSmartviewInstances) => {
        const positionBeingUpdated = prevSmartviewInstances.find((smartview) => smartview.name === name)?.position;

        const currentSmartviewInstances = prevSmartviewInstances.filter(
            (prevSmartviewInstance) => prevSmartviewInstance.name !== name
        );

        // filter the current instances based on their container/position
        const currentSmartviewInstancesInPositionContainer = currentSmartviewInstances.filter(
            (smartviewInstance) => positionBeingUpdated === smartviewInstance.position
        );

        // filter the previous instances based on their container/position
        const prevSmartviewInstancesInPositionContainer = prevSmartviewInstances.filter(
            (smartviewInstance) => positionBeingUpdated === smartviewInstance.position
        );

        set(
            latestSmartviewActionAtom,
            getAction(currentSmartviewInstancesInPositionContainer, prevSmartviewInstancesInPositionContainer)
        );

        return currentSmartviewInstances;
    });

    return reset(smartviewInstanceAtom(name));
};

/**
 * Updates a smartview and closes all other smartviews
 * @param smartview - the smartview to update
 * @param set - recoil setter
 * @param get - recoil getter
 * @param reset - recoil resetter
 * @returns void
 */
const singleInstanceUpdater = (
    smartview: ISmartview,
    set: SetRecoilState,
    get: GetRecoilValue,
    reset: ResetRecoilState
) => {
    // reset every other smartview to their default value (closed)
    const ids = get(smartviewInstancesIdsAtom);
    ids.forEach((id) => reset(smartviewInstanceAtom(id.name)));

    // Update the array containing all active smartviews
    set(smartviewInstancesIdsAtom, (prevSmartviewInstances) => {
        const currentSmartviewInstances: SmartviewInstanceDetails[] = [
            { name: smartview.name, position: smartview.position }
        ]; // set the current instances to only contain a single item, since this is a single Instance updater aka. singleton

        set(latestSmartviewActionAtom, getAction(currentSmartviewInstances, prevSmartviewInstances));

        return currentSmartviewInstances;
    });

    // Set the individual smartview instance atom to whatever new values passed.
    return set(smartviewInstanceAtom(smartview.name), buildSmartview(smartview));
};

/**
 * Updates a smartview and pushes it to the top of the stack of other active smartviews
 * @param smartview - the smartview you want to update
 * @param set - recoil setter
 * @returns void
 */
const multiInstanceUpdater = (smartview: ISmartview, set: SetRecoilState) => {
    // Update the array containing all active smartviews
    set(smartviewInstancesIdsAtom, (prevSmartviewInstances) => {
        const positionBeingUpdated = smartview.position; // get current container being updated

        // get the array of all instances
        const currentSmartviewInstances: SmartviewInstanceDetails[] = [...prevSmartviewInstances, smartview];

        // filter the current instances based on their container/position
        const currentSmartviewInstancesInPositionContainer = currentSmartviewInstances.filter(
            (smartviewInstance) => positionBeingUpdated === smartviewInstance.position
        );

        // filter the previous instances based on their container/position
        const prevSmartviewInstancesInPositionContainer = prevSmartviewInstances.filter(
            (smartviewInstance) => positionBeingUpdated === smartviewInstance.position
        );

        set(
            latestSmartviewActionAtom,
            getAction(currentSmartviewInstancesInPositionContainer, prevSmartviewInstancesInPositionContainer) // get the action
        );

        return currentSmartviewInstances;
    });

    // Set the individual smartview instance atom to whatever new values passed.
    return set(smartviewInstanceAtom(smartview.name), buildSmartview(smartview));
};

/**
 * Selector for checking if smartview is visible (on top of the stack)
 */
export const isSmartviewVisible = selectorFamily<boolean, SmartviewSerializableParams>({
    key: 'is-smartview-visible',
    get:
        (name) =>
        ({ get }) => {
            const smartviewInstances = get(smartviewInstancesIdsAtom);

            return smartviewInstances[smartviewInstances.length - 1].name === name;
        }
});

/**
 * Selector for checking if smartview is open
 */
export const isSmartviewOpen = selectorFamily<boolean, SmartviewSerializableParams>({
    key: 'is-smartview-open',
    get:
        (name) =>
        ({ get }) =>
            get(smartviewInstanceAtom(name)).isOpen
});

export const guardRecoilDefaultValue = (candidate: any): candidate is DefaultValue => {
    if (candidate instanceof DefaultValue) return true;
    return false;
};

export const smartviewManager = selectorFamily<ISmartview, SmartviewSerializableParams>({
    key: 'smartview-manager-access',
    get:
        (name) =>
        ({ get }) =>
            get(smartviewInstanceAtom(name)),
    set:
        (name) =>
        ({ set, get, reset }, newValue) => {
            if (guardRecoilDefaultValue(newValue)) return resetSmartview(name, reset, set);

            // If action is close
            if (!newValue.isOpen) return resetSmartview(name, reset, set);

            // If singleton instance
            if (newValue.instanceType === 'single') {
                return singleInstanceUpdater(newValue, set, get, reset);
            }

            // If multi instance
            return multiInstanceUpdater(newValue, set);
        }
});
