import React from 'react';
import styled, { keyframes } from 'styled-components';

const DefaultSpinnerAnimation1 = keyframes`
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
`;

const DefaultSpinnerAnimation2 = keyframes`
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
`;

const DefaultSpinnerAnimation3 = keyframes`
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
`;

const DefaultSpinner = styled.div`
    display: inline-block;
    position: relative;
    width: 64px;
    height: 11px;

    div {
        position: absolute;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: ${props => (props.color ? props.color : '#fff')};
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
        left: 6px;
        animation: ${DefaultSpinnerAnimation1} 0.6s infinite;
    }
    div:nth-child(2) {
        left: 6px;
        animation: ${DefaultSpinnerAnimation2} 0.6s infinite;
    }
    div:nth-child(3) {
        left: 26px;
        animation: ${DefaultSpinnerAnimation2} 0.6s infinite;
    }
    div:nth-child(4) {
        left: 45px;
        animation: ${DefaultSpinnerAnimation3} 0.6s infinite;
    }
`;

export default props => {
    const { className, color } = props;

    return (
        <DefaultSpinner className={className} color={color}>
            <div />
            <div />
            <div />
            <div />
        </DefaultSpinner>
    );
};
