import mediator from 'modules/mediator';
import { AcceptTerms } from 'autogen/swagger/consent';

function setError(consentBoxes, error) {
    consentBoxes.forEach(consentBox => {
        consentBox.checked = false;
    });

    mediator.publish('growl', { message: error });
}

async function saveConsent(consents, consentBoxes, error) {
    try {
        await AcceptTerms({ terms: consents });
        return true;
    } catch {
        setError(consentBoxes, error);
        return false;
    }
}

async function handleSkintestConsent(event, node) {
    event.preventDefault();

    const consents = node.dataset.consents.split(',');
    const consentBoxes = [];

    consents.forEach(consent => {
        const checkbox = document.querySelector(`.js-${consent}`);

        if (checkbox) {
            consentBoxes.push(checkbox);
        }
    });

    const consentGiven = consentBoxes.every(x => x.checked);

    if (!consentGiven) {
        const validationText = document.querySelector('.js-questionnaireConsent');
        validationText.classList.remove('hidden');
    } else if (await saveConsent(consents, consentBoxes, node.dataset.error)) {
        window.location.href = node.href;
    }
}

export default node => {
    node.addEventListener('click', event => handleSkintestConsent(event, node));
};
