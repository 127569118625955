import { InputWPlaceholder } from 'modules/input-w-placeholder';
import { _LoginInfoUrl } from 'autogen/swagger/login';
import ContentToggle from './contentToggle';
import MatchPermissionCheckboxes from './matchPermissionCheckboxes';

export default class UpdateCheckoutContent {
    constructor() {
        this.checkoutContent = $('[data-js-updatecheckoutcontent]');
        this.updateContentAction = this.checkoutContent.attr('data-js-updatecheckoutcontent');

        this.userLogin = $('[data-js="update-userlogin"]');
    }

    init() {}

    updateUserInfo() {
        // Currently only used by prizeCertificate
        $.ajax({
            type: 'GET',
            url: _LoginInfoUrl,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            cache: false
        }).done(response => {
            this.userLogin.replaceWith($(response));
        });
    }

    updateCheckoutContent(item) {
        $.ajax({
            type: 'GET',
            url: this.updateContentAction,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            cache: false
        })
            .done(response => {
                this.checkoutContent.html(response);

                this.updateUserInfo();

                this.rebindMatchPermissionCheckboxes();
                this.rebindInputWPlaceholder();
                this.rebindSelection();
                this.commentToggle();

                (async () => {
                    const { default: Tooltip } = await import(/* webpackChunkName: "tooltip" */ 'modules/tooltip');
                    Tooltip.init();
                })();

                return;
            })
            .fail(response => {
                return;
            });
    }

    rebindMatchPermissionCheckboxes() {
        let matchPermissionCheckboxes = new MatchPermissionCheckboxes();
        matchPermissionCheckboxes.init();
    }

    rebindInputWPlaceholder() {
        this.checkoutContent.find('[data-js="InputWithLabel"]').each((i, item) => {
            let input_w_placeholder = new InputWPlaceholder($(item));
            input_w_placeholder.init();
        });
    }

    rebindSelection() {
        const selections = this.checkoutContent.find('[data-js="selection"]');
        if (selections.length) {
            (async () => {
                const { default: Selection } = await import(/* webpackChunkName: "selection" */ 'modules/selection');
                selections.each((i, item) => {
                    const selection = new Selection($(item));
                    selection.init();
                });
            })();
        }
    }

    contentToggle() {
        let contentToggle = new ContentToggle();
        contentToggle.init();
    }
}
