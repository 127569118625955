import React from 'react';
import styled, { css } from 'styled-components';
import { fontWeightStyling, type inlineButtonFontWeight } from './shared/inlineButtonFontWeight';
import { type inlineButtonSize, sizeStyling } from './shared/inlineButtonSize';
import { type inlineButtonVariantKeys, variantStyling } from './shared/inlineButtonVariants';
import { defaultStyling } from './shared/styling';
import type IInlineButton from './shared/types/inlineButton';

const StyledInlineButton = styled.button<{
    $fontWeight: inlineButtonFontWeight;
    variant?: inlineButtonVariantKeys;
    size?: inlineButtonSize;
    disable?: boolean;
}>`
    ${defaultStyling}

    ${({ variant }) => variant && variantStyling};

    ${({ size }) =>
        size
            ? sizeStyling
            : css`
                  font-size: inherit;
              `};

    ${fontWeightStyling}
`;

const InlineButton = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IInlineButton>(
    (
        {
            as,
            variant = 'dark',
            size,
            fontWeight = 'regular',
            disabled,
            onClick,
            onClickDisabled,
            tabIndex,
            children,
            ...restProps
        },
        ref
    ) => (
        <StyledInlineButton
            ref={ref}
            as={as}
            variant={variant}
            size={size}
            $fontWeight={fontWeight}
            $disabled={disabled}
            tabIndex={disabled ? '-1' : tabIndex}
            onClick={(event) => {
                if (disabled) {
                    event.preventDefault(); // prevents anchor navigation
                    if (onClickDisabled) onClickDisabled(event);
                    return; // prevents usual onClick events
                }
                if (onClick) onClick(event);
            }}
            {...restProps}
        >
            {children}
        </StyledInlineButton>
    )
);

export default InlineButton;
