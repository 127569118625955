import styled from 'styled-components';
import format from 'helpers/translations';
import { Typography } from 'components/generic/typography';
import WithIcon from 'components/hoc/withIcon/WithIcon';
import dynamic from 'helpers/dynamic';
import { type BasketMessageShippingViewModel } from 'autogen/swagger/basket';
import { ColorHealth5, ColorNordicGrey4 } from 'autogen/design-tokens/tokens';
import BasketMessageContainer from '../shared/styles';

const StyledBasketMessageContainer = styled(BasketMessageContainer)<{ $achievement?: boolean }>`
    background-color: ${({ $achievement }) => ($achievement ? ColorHealth5 : ColorNordicGrey4)};
`;

const Checkmark = dynamic(() => import('icons/Checkmark.svg?react'));

export default function ShippingMessage({ model: { text, intro } }: { model: BasketMessageShippingViewModel }) {
    if (intro) {
        return (
            <StyledBasketMessageContainer $achievement>
                <WithIcon icon={<Checkmark />}>
                    <Typography.Body as="span" themeTypes="small">
                        {format(intro)} {format(text)}
                    </Typography.Body>
                </WithIcon>
            </StyledBasketMessageContainer>
        );
    }

    return (
        <StyledBasketMessageContainer>
            <Typography.Body as="span" themeTypes="small">
                {format(text)}
            </Typography.Body>
        </StyledBasketMessageContainer>
    );
}
