import { TrackAdformClickUrl, type TrackAdformClickRequestBody } from 'autogen/swagger/tracking';

export function postAdformClickTracking(trackingUrl: string) {
    const uid = window.Adform?._uid ?? '';
    const body: TrackAdformClickRequestBody = { trackingUrls: [trackingUrl], uid };

    fetch(TrackAdformClickUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        credentials: 'same-origin',
        keepalive: true
    }).catch((error) => {
        throw error;
    });
}

export default function adformClickTracking(targets: HTMLElement[]) {
    targets.forEach((target) => {
        target.addEventListener('click', () => {
            const trackingUrl = target.dataset.jsAdformClickTrackingUrl;

            postAdformClickTracking(trackingUrl);
        });
    });
}
