import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { popup, popdown } from 'modules/helpers/animations';
import breakpoint from 'modules/breakpoint';
import useIsomorphicLayoutEffect from 'hooks/globals/useIsomorphicLayoutEffect';
import DefaultHeader from '../components/header';
import CloseButton from '../components/closeButton';

const animationDuration = 300;
let popupAnimationHasRun = false;

export const DefaultBox = styled.div<{ open: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.backgroundColor || '#fff'};
    width: 100%;
    height: ${(props) => (props.theme.placement === 'center' ? 'auto' : '100%')};
    min-height: ${(props) => props.theme.minHeight || 'var(--modal-min-height)'};
    max-height: ${(props) => props.theme.maxHeight || '100%'};
    padding: ${(props) => props.theme.padding || '0'};
    animation: ${({ open }) => (open ? !popupAnimationHasRun && popup : popdown)} ${animationDuration}ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.29, 0.97, 0.46, 1);
    overflow: auto;
    overflow-x: hidden;

    ${(props) =>
        props.theme.placement === 'top' &&
        css`
            align-self: flex-start;
            height: auto;
        `};

    ${(props) =>
        props.theme.placement === 'bottom' &&
        css`
            align-self: flex-end;
            height: auto;
        `};

    @media (min-width: ${breakpoint.sizes.md}px) {
        width: ${(props) => props.theme.width || 'auto'};
        height: ${(props) => props.theme.height || 'auto'};
        min-width: ${(props) => props.theme.minWidth || '600px'};
        max-width: ${(props) => props.theme.maxWidth || '90%'};
        max-height: ${(props) => props.theme.maxHeight || '90%'};

        ${(props) => props.theme.placement && 'align-self: auto;'}
    }
`;

const ContentWrapper = styled.div`
    flex: 1 1 auto;
    height: inherit; /* iOS fix, auto doesn't work */
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow-x: ${(props) => props.theme.overflowX || 'auto'};
    overflow-y: ${(props) => props.theme.overflowY || 'auto'};
`;

interface ITheme {
    color?: string;
    fill?: string;
    width?: string;
    height?: string;
    minWidth?: string;
    minHeight?: string;
    maxWidth?: string;
    maxHeight?: string;
    backgroundColor?: string;
    placement?: string;
    overflowX?: string;
    overflowY?: string;
    padding?: string;
}

export interface IDefaultLayout {
    component?: JSX.Element;
    customTheme?: ITheme;
    header?: any;
    className?: any;
    open?: any;
    close?: any;
    userCanClose?: any;
    unmount?: any;
    content?: any;
    lockScroll?: any;
    Box?: any;
    trackingCategory?: any;
    theme?: any;
}

export default (props: IDefaultLayout) => {
    const {
        customTheme = {},
        header,
        className,
        open,
        close,
        userCanClose,
        unmount,
        content,
        Box = DefaultBox,
        trackingCategory
    } = props;
    const scrollRef = React.createRef<any>();

    // Only run this effect if props.open change
    useIsomorphicLayoutEffect(() => {
        if (open) {
            popupAnimationHasRun = true;
        } else {
            unmount(animationDuration);
            popupAnimationHasRun = false;
        }
    }, [open, scrollRef, unmount]);

    const HeaderProp = (headerProps) => (header !== null ? React.cloneElement(header, headerProps) : null);

    const closeClassName = trackingCategory ? `tracking_${trackingCategory}_closeModal` : null;

    return (
        <ThemeProvider theme={customTheme}>
            <Box className={className} open={open}>
                {header !== undefined ? (
                    <HeaderProp userCanClose={userCanClose} close={close} />
                ) : (
                    <DefaultHeader>
                        {userCanClose && <CloseButton onClick={close} className={closeClassName} />}
                    </DefaultHeader>
                )}
                <ContentWrapper ref={scrollRef}>{content}</ContentWrapper>
            </Box>
        </ThemeProvider>
    );
};
