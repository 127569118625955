import mediator from 'modules/mediator';
import isIntersecting from 'helpers/isIntersecting';

const attribute = 'data-dataLayer';

export function trackEvent(event) {
    if (typeof window === 'undefined' || !window.dataLayer || !Array.isArray(window.dataLayer)) {
        return;
    }

    mediator.publish('trackEvent', event);
    window.dataLayer.push(event);
}

function filterByEvent(events, eventName) {
    const flattened = events ? events.flat(Number.POSITIVE_INFINITY) : [];

    return flattened.filter((event) => event !== undefined && event.event === eventName);
}

function trackPromoImpressions(viewEvents) {
    if (!viewEvents?.length) return;

    const event = viewEvents[0];

    trackEvent(event);
}

let productImpressionsTemplate;
const productImpressions = [];
let productBatchTimer;

const scheduleProductImpressionsBatch = () => {
    const shouldScheduleBatch = productImpressions.length > 0 && !productBatchTimer;

    if (shouldScheduleBatch) {
        const batchSize = 10;
        // Schedule the leftover batch
        productBatchTimer = setTimeout(() => {
            const batch = productImpressions.splice(0, batchSize);
            const impressions = [];
            batch.forEach((item) => {
                const impression = item.ecommerce.impressions;
                impressions.push(...impression);
            });

            if (!productImpressionsTemplate) {
                // eslint-disable-next-line prefer-destructuring
                productImpressionsTemplate = batch[0];
            }

            productImpressionsTemplate.ecommerce.impressions = impressions;

            trackEvent(productImpressionsTemplate);
            productBatchTimer = null;
            // if there are still events left schedule new batch
            if (productImpressions.length) {
                scheduleProductImpressionsBatch();
            }
        }, 1000);
    }
};

function trackProductImpressions(viewEvents) {
    if (!viewEvents?.length) return;

    const impressions = viewEvents.map((event) => event.ecommerce.impressions);
    const merged = impressions.flat(Number.POSITIVE_INFINITY);

    const impression = viewEvents[0];
    impression.ecommerce.impressions = merged;

    productImpressions.push(impression);
}

function intersectionCallback(entries, observer) {
    const intersectingEntries = entries.filter((entry) => entry.isIntersecting);

    intersectingEntries.forEach((entry) => {
        const { target } = entry;
        const dataLayerDto = JSON.parse(target.getAttribute(attribute));
        const { viewEvents } = dataLayerDto;

        setTimeout(async () => {
            isIntersecting(target, 0.5).then((intersecting) => {
                if (intersecting) {
                    trackPromoImpressions(filterByEvent(viewEvents, 'view_promotion'));
                    trackProductImpressions(filterByEvent(viewEvents, 'productImpressions'));

                    observer.unobserve(target);

                    scheduleProductImpressionsBatch();
                }
            });
        }, 1000);
    });
}

let intersectionObserver;
if (typeof window !== 'undefined') {
    intersectionObserver = new IntersectionObserver(intersectionCallback, { threshold: 0.5 });
}

function mutationCallback(mutationsList) {
    mutationsList.forEach((mutation) => {
        // ChildList/SubTree mutation is observed for the whole body element
        if (mutation.type === 'childList' && mutation.addedNodes.length) {
            // Find new elements to track within the addedNodes
            const newElementsToTrack = Array.from(mutation.addedNodes).reduce((acc, node) => {
                if (!(node instanceof Element)) {
                    return acc;
                }

                const trackingElements = Array.from(node.querySelectorAll(`[${attribute}]`));

                if (node.attributes[attribute]) {
                    trackingElements.push(node);
                }

                return trackingElements.length ? [...acc, ...trackingElements] : acc;
            }, []);

            newElementsToTrack.forEach((element) => {
                intersectionObserver.observe(element);
            });
        }
    });
}

function inlineClickEvent(event, clickEvents) {
    const { target } = event;
    const link = target.closest('a[href]');
    clickEvents.forEach((clickEvent) => {
        const isFindStore = clickEvent.eventAction === 'Find Store';

        // Buttons which redirect
        if (link && !isFindStore) {
            if (window.google_tag_manager) {
                event.preventDefault();
                let hasReturned = false;
                // eslint-disable-next-line consistent-return
                const redirect = () => {
                    if (!hasReturned) {
                        hasReturned = true;
                        if (event.ctrlKey) {
                            return window.open(link.getAttribute('href'), '_blank');
                        }

                        window.open(link.getAttribute('href'), link.getAttribute('target') || '_self');
                    }
                };
                trackEvent({
                    ...clickEvent,
                    eventCallback: redirect,
                    eventTimeout: 800
                });
                setTimeout(redirect, 1000);
            }

            // Find in store button which does not redirect
        } else if (isFindStore) {
            trackEvent(clickEvent);

            // Payment button which submits
        } else if (target.closest('.js-payment-btn')) {
            if (window.google_tag_manager) {
                event.preventDefault();
                let hasReturned = false;
                const formSubmit = () => {
                    if (!hasReturned) {
                        hasReturned = true;
                        target.closest('form').submit();
                    }
                };
                trackEvent({
                    ...clickEvent,
                    eventCallback: formSubmit,
                    eventTimeout: 800
                });
                setTimeout(formSubmit, 1000);
            }
        } else {
            trackEvent({ ...clickEvent });
        }
    });
}

const dataLayer = {
    setupTracking() {
        dataLayer.inlineTracking();
    },

    inlineTracking() {
        /*
                This method checks for tags with a data-dataLayer attribute that contains json (dataLayerDto).
                The scope parameter is optional, but if we pass one along, we only search within that scope.
            */
        const elementsToTrack = document.querySelectorAll(`[${attribute}]`);
        elementsToTrack.forEach((element) => {
            intersectionObserver.observe(element);
        });
        const mutationObserver = new MutationObserver(mutationCallback);
        mutationObserver.observe(document.querySelector('body'), { childList: true, subtree: true });
        document.addEventListener('click', (event) => {
            const { target } = event;
            const dataLayerElement = target.closest(`[${attribute}]`);
            if (dataLayerElement) {
                const dataLayerDto = dataLayerElement.getAttribute(attribute);
                const dataLayerJSON = JSON.parse(dataLayerDto);
                const { clickEvents } = dataLayerJSON;
                inlineClickEvent(event, clickEvents);
            }
        });
    },

    /*
        Sends a virtual pageview - used fx. by QuickSearch buttons
    */
    trackVirtualPageView(urlEncodePageUrl) {
        if (urlEncodePageUrl) {
            const event = {
                event: 'virtualPageview',
                virtualPagePath: urlEncodePageUrl
            };
            trackEvent(event);
        }
    }
};

const track = (dataLayerDto) => {
    const { viewEvents, clickEvents } = dataLayerDto;
    if (viewEvents) {
        viewEvents.forEach((viewEvent) => {
            trackEvent(viewEvent);
        });
    }
    if (clickEvents) {
        clickEvents.forEach((clickEvent) => {
            trackEvent(clickEvent);
        });
    }
};

export function trackArray(array) {
    if (array) {
        array.forEach((event) => {
            trackEvent(event);
        });
    }
}

export { track as default, dataLayer };
