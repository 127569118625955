import useDropdown from 'components/dropdown/hooks/useDropdown';
import React from 'react';
import type IOption from '../shared/types/option';
import type { OptionType } from '../../SelectWithHitCount';

export interface ISelectContext {
    name?: string;
    selectOption(value: IOption | OptionType): void;
    selectedOption?: IOption | OptionType;
    isSelected(value: IOption | OptionType): boolean;
}

const SelectContext = React.createContext({} as ISelectContext);

export function useSelect() {
    return React.useContext(SelectContext);
}

const SelectProvider: React.FC<
    React.PropsWithChildren<{
        name?: string;
        value?: IOption;
        onChange;
    }>
> = ({ name, value: selectedOption, onChange, children }) => {
    const { hide } = useDropdown();

    const selectOption = (option: IOption) => {
        if (option.disabled) return;

        onChange(option);
        hide();
    };
    const isSelected = (option: IOption) => {
        if (!selectedOption) return false;
        return selectedOption.value === option.value;
    };

    const value = {
        name,
        selectedOption,
        selectOption,
        isSelected
    };

    return (
        <SelectContext.Provider value={value}>
            {/* hidden input so this custom input works if wrapped in a form */}
            <input type="hidden" name={name} readOnly value={selectedOption ? selectedOption.value : ''} />
            {children}
        </SelectContext.Provider>
    );
};

export default SelectProvider;
