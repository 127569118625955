/* eslint-disable */
/// This file is auto generated - do not edit manually
export const Card = 'Website.Basket.GiveAsGift.Card';
export const WrappingHeaderSectionText_Prewrapped = 'Website.Basket.GiveAsGift.WrappingHeaderSectionText_Prewrapped';
export const WrappingHeaderSectionText_Simple = 'Website.Basket.GiveAsGift.WrappingHeaderSectionText_Simple';
export const GiftWrapQuestionText_Single = 'Website.Basket.GiveAsGift.GiftWrapQuestionText_Single';
export const GiftWrapSelectedMessage = 'Website.Basket.GiveAsGift.GiftWrapSelectedMessage';
export const NewSwapRulesHeading = 'Website.Basket.GiveAsGift.NewSwapRulesHeading';
export const NewSwapRulesInfo = 'Website.Basket.GiveAsGift.NewSwapRulesInfo';
export const NewSwapRulesLink = 'Website.Basket.GiveAsGift.NewSwapRulesLink';
export const NoSwapCertificateHeading = 'Website.Basket.GiveAsGift.NoSwapCertificateHeading';
export const NoSwapCertificateInfo = 'Website.Basket.GiveAsGift.NoSwapCertificateInfo';
export const EasyExchangeHeading = 'Website.Basket.GiveAsGift.EasyExchangeHeading';
export const EasyExchangeInfo = 'Website.Basket.GiveAsGift.EasyExchangeInfo';
export const SwapCertificateToReceiverHeading = 'Website.Basket.GiveAsGift.SwapCertificateToReceiverHeading';
export const SwapCertificateToReceiverInfo = 'Website.Basket.GiveAsGift.SwapCertificateToReceiverInfo';
export const NoSealingHeading = 'Website.Basket.GiveAsGift.NoSealingHeading';
export const NoSealingInfo = 'Website.Basket.GiveAsGift.NoSealingInfo';
export const InvalidDate = 'Website.Basket.GiveAsGift.InvalidDate';
export const SetLatestDateInfo = 'Website.Basket.GiveAsGift.SetLatestDateInfo';
export const GiveAsGiftTotalKr = 'Website.Basket.GiveAsGift.GiveAsGiftTotalKr';
