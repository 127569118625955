import { useRecoilCallback } from 'recoil';
import { smartviewManager } from '../recoil/selectors';
import type { ISmartview } from '../types';

/**
 * Open smartviews in a specific order
 * @param batchUpdates disable React state batching. (Primarily used if smartviews are nested within each other)
 * @returns a function to opens smartviews
 */
export default function useOpenSmartviews(batchUpdates = true, position: ISmartview['position'] = 'left') {
    const openSmartviews = useRecoilCallback(
        ({ set }) =>
            (names: string[]) => {
                names.forEach((name) => {
                    if (!batchUpdates) {
                        /* 
                        Since react renders child first we can't render the smartviews when nested under a parent smartview,
                        we can't recursively render the menu in one single render this is why we need to setTimeout to prevent render batching. 
                        
                        so to open nested smartviews in a specific order we disable batching, the render cycle will look like this
                        The first setState (RootMenu renders first) -> the second setState (NestedMenu inside RootMenu Renders) -> and the cycle repeats.

                        if batching was enabled react would try and batch the state update in one single render and that would look like this
                        NestedMenu inside RootMenu tries to render but fails since it hasn't been mounted -> the RootMenu renders afterwards since react renders children first. 
                        */
                        setTimeout(() => {
                            set(smartviewManager(name), { name, isOpen: true, instanceType: 'multi', position });
                        }, 0);
                    } else {
                        set(smartviewManager(name), { name, isOpen: true, instanceType: 'multi', position });
                    }
                });
            },
        []
    );

    return openSmartviews;
}
