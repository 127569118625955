import { HeaderButton, type HeaderButtonProps } from 'components/quickView/shared/style';
import styled from 'styled-components';
import type { PropsWithChildren } from 'react';
import { Spacing8 } from 'autogen/design-tokens/tokens';
import Icon from 'components/Icon';

const BackButton = styled(HeaderButton)`
    display: flex;
    gap: ${Spacing8};
    align-items: center;
`;

const DefaltBackButton = (props: PropsWithChildren<HeaderButtonProps>) => {
    const { children, ...restProps } = props;
    return (
        <BackButton {...restProps}>
            <Icon type="GoBack" size={16} /> {children}
        </BackButton>
    );
};

export default DefaltBackButton;
