const rootClass = 'print-modal';

function onPrintClickClick(event) {
    const root = document.documentElement;
    event.preventDefault();
    root.classList.add(rootClass);
    // window.onbeforeprint = () => { this.root.addClass(this.rootClass); }; // onbeforeprint is not supported by safari
    window.onafterprint = () => {
        root.classList.remove(rootClass);
    }; // onafterprint is not supported by safari
    window.print();
}

export default wrapper => {
    wrapper.querySelectorAll('.js-print-modal').forEach(item => {
        item.removeEventListener('click', onPrintClickClick);
        item.addEventListener('click', onPrintClickClick);
    });
};
