import { InputWPlaceholder } from './input-w-placeholder';

export default class GiftCardBalance {
    constructor(form) {
        this.doc = $(document);
        this.form = $(form);
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.doc.on('submit', this.form, e => this.postForm(e));
    }

    postForm(e) {
        e.preventDefault();
        const $form = $(e.target);
        const $action = $form.attr('action');

        $.ajax({
            type: 'POST',
            url: $action,
            data: $form.serialize()
        }).done(response => {
            $form.replaceWith(response);
            this.rebindInputWPlaceholder();
        });
    }

    rebindInputWPlaceholder() {
        let input_w_placeholder = new InputWPlaceholder(this.form.find('[data-js*="InputWithLabel"]'));
        input_w_placeholder.init();
    }
}
