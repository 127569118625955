/* eslint-disable */
/// This file is auto generated - do not edit manually
export const MenuTab = 'Website.Filter.MenuTab';
export const Sorting = 'Website.Filter.Sorting';
export const ResetFilter = 'Website.Filter.ResetFilter';
export const ProductPageSizeText = 'Website.Filter.ProductPageSizeText';
export const ShowFilters = 'Website.Filter.ShowFilters';
export const SelectFilters = 'Website.Filter.SelectFilters';
export const SelectedFilters = 'Website.Filter.SelectedFilters';
export const SeeAll = 'Website.Filter.SeeAll';
export const ResetFiltersDescription = 'Website.Filter.ResetFiltersDescription';
export const ZeroResultFilters = 'Website.Filter.ZeroResultFilters';
export const ClearAll = 'Website.Filter.ClearAll';
export const ShowXResults = 'Website.Filter.ShowXResults';
export const Show = 'Website.Filter.Show';
export const PriceIntervalLabel = 'Website.Filter.PriceIntervalLabel';
export const PriceIntervalClear = 'Website.Filter.PriceIntervalClear';
export const PriceIntervalMin = 'Website.Filter.PriceIntervalMin';
export const PriceIntervalMax = 'Website.Filter.PriceIntervalMax';
