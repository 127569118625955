import { BorderRadiusSmall } from 'autogen/design-tokens/tokens';
import { em } from 'modules/helpers/style';
import { css } from 'styled-components';

export const outlineImitatorStyling = css`
    position: absolute;
    border: 1px solid currentColor;
    top: 0;
    left: ${em(-4)};
    right: ${em(-4)};
    bottom: ${em(-2)};
    border-radius: ${BorderRadiusSmall};
`;

export const linkOutlineStyling = css`
    &:focus {
        position: relative;
        outline: none;
    }
    &:focus-visible {
        &:before {
            content: '';
            ${outlineImitatorStyling}
        }
    }
`;

export const defaultStyling = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    text-underline-offset: ${em(4)};

    ${linkOutlineStyling}
`;
