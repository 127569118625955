import dynamic from 'helpers/dynamic';
import Tippy from '@tippyjs/react';
import { ColorNeutralDarkGrey } from 'autogen/design-tokens/tokens';
import { PossibleAdditionalDeliveryCost } from 'autogen/translation-keys/trans-website-shared-productitem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// wrapper is needed for tooltip to work on items in product recommendations
const Wrapper = styled.span`
    display: inline-flex;
    vertical-align: text-top;

    // if item is covered by an anchor (productItem)
    position: relative;
    z-index: 2;
`;

const ExclamationMarkCircle = styled(dynamic(() => import('icons/InfoCircle.svg?react')))`
    width: 16px;
    height: 16px;
    fill: ${ColorNeutralDarkGrey};
`;

export default function AdditionalDeliveryTooltip() {
    const { t } = useTranslation();

    return (
        <Tippy content={t(PossibleAdditionalDeliveryCost)} theme="plain-tooltip">
            <Wrapper>
                <ExclamationMarkCircle />
            </Wrapper>
        </Tippy>
    );
}
//
