import { v4 as uuidv4 } from 'uuid';
import debounce from 'debounce';

const subscriptions = [];
const getScrollTop = () => window.scrollY || window.pageYOffset;
let prevScrollTop = window.scrollY || window.pageYOffset;
let prevDirection = '';
let programmaticScrollBool = false;

const scrollStopedHandler = debounce(() => {
    programmaticScrollBool = false;
}, 50);

function getScrollObject() {
    const scrollTop = getScrollTop();

    const direction = prevScrollTop < scrollTop ? 'down' : 'up';

    return { direction, prevDirection, scrollTop, prevScrollTop, programmaticScroll: programmaticScrollBool };
}

async function scrollHandler() {
    const scrollTop = getScrollTop();
    if (scrollTop === prevScrollTop || window.getComputedStyle(document.body).overflow === 'hidden') {
        return;
    }
    const direction = prevScrollTop < scrollTop ? 'down' : 'up';
    subscriptions.forEach(subscription => {
        const fn = subscription[direction];

        if (fn) {
            fn({ direction, prevDirection, scrollTop, prevScrollTop, programmaticScroll: programmaticScrollBool });
        }
    });

    prevScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    prevDirection = direction;
    scrollStopedHandler();
}

export const onDirectionChangeOnly = fn => obj => {
    if (obj.direction === obj.prevDirection) {
        return;
    }
    fn(obj);
};

export function scrollDirection(options) {
    let id;

    function subscribe() {
        const { up, down, predefinedID } = options;
        id = predefinedID || uuidv4();
        subscriptions.push({ id, up, down });

        if (subscriptions.length === 1) {
            requestAnimationFrame(() => {
                prevScrollTop = getScrollTop();
                document.addEventListener('scroll', scrollHandler, { passive: true });
            });
        }
    }

    function unsubscribe() {
        const indexArr = subscriptions.reduce((acc, curr, index) => {
            const { id: subscriptionId } = curr;

            return subscriptionId === id ? [...acc, index] : acc;
        }, []);

        if (indexArr.length) {
            indexArr.forEach(index => {
                subscriptions.splice(index, 1);
            });
        }

        if (subscriptions.length === 0) {
            document.removeEventListener('scroll', scrollHandler);
        }
    }

    return {
        get: getScrollObject,
        subscribe,
        unsubscribe
    };
}

export function programmaticScroll(fn) {
    if (!fn) return;
    programmaticScrollBool = true;
    fn();
}

export default scrollDirection;
