import {
    FontHeading3Size,
    FontHeading3WeightSemiBold,
    FontHeading3LineHeightSansSerifSemiBold,
    FontHeading3FamilySerif,
    ColorNeutralBlack
} from 'autogen/design-tokens/tokens';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type sizes = 'sm' | 'lg';

interface IHeading {
    size?: sizes;
    children: ReactNode;
}

const headingSizes = {
    sm: css`
        font-size: 20px;
        line-height: ${FontHeading3LineHeightSansSerifSemiBold};
    `,
    lg: css`
        font-size: 28px;
        line-height: ${FontHeading3LineHeightSansSerifSemiBold};
    `
};

const StyledHeading3 = styled.h3<{ size: sizes }>`
    ${({ size }) => headingSizes[size]}; //todo bodyfontchange ${FontHeading3Size}
    font-weight: ${FontHeading3WeightSemiBold};
    line-height: ${FontHeading3LineHeightSansSerifSemiBold};
    font-family: ${FontHeading3FamilySerif};
    color: ${ColorNeutralBlack};
`;

export default function Heading3({ size = 'sm', children, ...restProps }: IHeading) {
    return (
        <StyledHeading3 size={size} {...restProps}>
            {children}
        </StyledHeading3>
    );
}
