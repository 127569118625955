import usePageContext from './usePageContext';

export default function useIsBrand(brand: string) {
    const { brandContext } = usePageContext();
    const isBrand = brandContext === brand;

    return isBrand;
}

export function useIsChanel() {
    const isChanel = useIsBrand('chanel');

    return isChanel;
}
