import React, { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
import type { Placement } from 'tippy.js';
import DropdownContext from '../context/dropdownContext';
import { IContext } from '../interface/dropdownContext';
import { IDropdown } from '../interface/dropdown';

export default function DropdownProvider({ disabled, trigger, children, onOutsideClickCallback }: IDropdown) {
    const [visible, setVisible] = useState(false);
    const [placement, setPlacement] = useState<Placement>('bottom-start');
    // we want to have our dropdown-content min-width based on the width of the trigger, therefore we need to keep a state of current width of the trigger.
    const [minWidth, setMinWidth] = useState<number>();

    const hide = () => {
        setVisible(false);
    };

    const show = () => {
        setVisible(true);
    };

    const handleOutsideClick = async () => {
        if (typeof onOutsideClickCallback === 'function') {
            await onOutsideClickCallback();
        }
        hide();
    };

    const toggle = () => setVisible((prevState) => !prevState);

    const value: IContext = useMemo(
        () => ({
            isVisible: visible,
            hide,
            show,
            toggle,
            disabled,
            placement,
            setMinWidth,
            minWidth
        }),
        [visible, disabled, placement, minWidth]
    );

    return (
        <DropdownContext.Provider value={value}>
            <Tippy
                interactive
                visible={visible}
                onClickOutside={handleOutsideClick}
                content={children}
                placement={placement}
                duration={0}
                offset={[0, 0]}
                arrow={false}
                theme="dropdown"
                maxWidth="none"
                appendTo={typeof window !== 'undefined' ? document.body : undefined}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'updatePlacement',
                            enabled: true,
                            phase: 'main',
                            fn({ state }) {
                                if (state.placement !== placement) {
                                    setPlacement(state.placement);
                                }
                            }
                        }
                    ]
                }}
            >
                {trigger}
            </Tippy>
        </DropdownContext.Provider>
    );
}
