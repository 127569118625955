function updateValue(subscriptionValue: HTMLInputElement, value: string): void {
    const val = value;
    subscriptionValue.value = val;
    subscriptionValue.dispatchEvent(new window.Event('change', { bubbles: true }));
}

export default function initRadioSelect(
    selectContainer: HTMLElement,
    subscriptionValue: HTMLInputElement,
    track: (action: string, label: string) => void
): void {
    const radioButtons = selectContainer.querySelectorAll(
        '.js-subscriptionRadioSelect'
    ) as NodeListOf<HTMLInputElement>;

    if (!radioButtons.length) return;

    radioButtons.forEach((button) => {
        button.addEventListener('change', (e) => {
            const target = e.target as HTMLInputElement;

            if (target.checked) {
                const targetLabel =
                    (target.parentElement.querySelector('.custom-radio-button__label') as HTMLElement)?.innerText || '';
                selectContainer.classList.remove('subscription-frequency--error');
                updateValue(subscriptionValue, target.value);

                track('change frequency', targetLabel);
            }
        });

        if (button.checked) {
            updateValue(subscriptionValue, button.value);
        }
    });
}
