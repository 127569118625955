import type { UserContextViewModel } from 'autogen/swagger/recoil';

const userContextObj: UserContextViewModel = {
    isAuthenticated: false,
    isClubMatasMember: false,
    isGa4Debug: false,
    isMatasPlusMember: false,
    loginDirectLink: {},
    permissionRequired: false,
    splitTestAssignments: [],
    userHasSeenProductPage: false
};

export const setUserContext = (obj): void => {
    Object.entries(obj).forEach(([key, item]) => {
        userContextObj[key] = item;
    });
};

const UserContext = (): UserContextViewModel => userContextObj;

export default UserContext();
