import { provideProductData as provideProductDataFunction } from './productData';
import { IBambuserPlayer } from './types';
import { addItemToCart, updateItemInCart, syncCartState } from './basket';

export default (): void => {
    window.onBambuserLiveShoppingReady = (player: IBambuserPlayer) => {
        player.configure({
            buttons: {
                dismiss: player.BUTTON.CLOSE
            },
            currency: 'DKK',
            locale: 'da-DK'
        });

        player.on(player.EVENT.CHECKOUT, () => {
            player.showCheckout(`${window.location.origin}${window.AddAndRemoveFromBasket.getBasketUrl()}`);
        });
        player.on(player.EVENT.PROVIDE_PRODUCT_DATA, (event) => {
            provideProductDataFunction(event, player);
        });
        player.on(player.EVENT.ADD_TO_CART, (item, callback) => {
            addItemToCart(item, 'Bambuser', callback);
        });
        player.on(player.EVENT.UPDATE_ITEM_IN_CART, (item, callback) => {
            updateItemInCart(item, 'Bambuser', callback);
        });
        player.on(player.EVENT.SYNC_CART_STATE, () => {
            syncCartState(player);
        });
    };
};
