import idleValue from 'modules/helpers/idle';
import { DOM_READY, MODULE_START, MODULE_END } from 'performance';

export async function fetcher(importFn) {
    const modulePromise = await importFn();
    const module = modulePromise.default || Object.values(modulePromise)[0];

    if (typeof module !== 'function') {
        throw new Error("The module you are trying to load, doesn't have an export");
    }

    return module;
}

const clickHandler = (() => {
    if (typeof window === 'undefined') return { register: () => undefined };

    const hooks = [];

    function register(hook, cb) {
        hook.split(',')
            .map(selector => selector.trim())
            .forEach(selector => {
                const selectorAlreadyRegistered = hooks.find(obj => obj.selector === selector);
                if (selectorAlreadyRegistered) {
                    throw new Error(`JS hook '${selector}' is already registered`);
                }
                hooks.push({ selector, cb });
            });
    }

    document.addEventListener('click', event => {
        const { target } = event;

        hooks.forEach(({ selector, cb }) => {
            const delegateTarget = target.closest(selector);
            if (delegateTarget) {
                event.delegateTarget = delegateTarget;
                cb(event);
            }
        });
    });

    return {
        register
    };
})();

export async function loadModule(hook, importFn, options = {}) {
    const { onClick, prefetch, idle, classFn, jQuery, resolve } = options;

    function measurePerformance(name, callback) {
        callback();
    }

    function runModule(Module, arg) {
        measurePerformance(Module.name, () => {
            if (classFn) {
                const classModule = new Module(jQuery ? $(arg) : arg);
                classModule.init();
            } else {
                Module(arg);
            }
        });
    }

    if (prefetch) {
        requestIdleCallback(() => {
            fetcher(importFn);
        });
    }

    if (onClick) {
        clickHandler.register(hook, async event => {
            const Module = await fetcher(importFn);

            if (resolve) {
                measurePerformance(Module.name, () => resolve({ Module, event, hook }));
            } else {
                runModule(Module, event, hook);
            }
        });
    } else {
        const nodes = document.querySelectorAll(hook);

        if (!nodes.length) {
            return;
        }

        const Module = idle ? idleValue(() => fetcher(importFn)) : await fetcher(importFn);

        // Observer Callback is only called when module is defined as idle
        const observer = new IntersectionObserver((entries, observerInstance) => {
            entries.forEach(async entry => {
                const { isIntersecting, target } = entry;
                if (isIntersecting) {
                    observerInstance.unobserve(entry.target);

                    if (resolve) {
                        measurePerformance(Module.name, async () => resolve({ Module: await Module.value, nodes }));
                    } else {
                        runModule(await Module.value, target);
                    }
                }
            });
        });

        if (resolve) {
            if (idle) {
                nodes.forEach(node => {
                    observer.observe(node);
                });
            } else {
                measurePerformance(Module.name, () => resolve({ Module, nodes }));
            }
        } else {
            nodes.forEach(node => {
                if (idle) {
                    observer.observe(node);
                } else {
                    runModule(Module, node);
                }
            });
        }
    }
}

export function fetchModule(hook, importFn, options = {}) {
    return new Promise(resolve => {
        loadModule(hook, importFn, { ...options, resolve });
    });
}

export function loadClass(hook, importFn, options = {}) {
    loadModule(hook, importFn, { ...options, classFn: true });
}

export function $loadClass(hook, importFn, options = {}) {
    loadModule(hook, importFn, { ...options, classFn: true, jQuery: true });
}
