import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import HeadlineStyling from '../styles/headline';
import { fontSizeTypes } from '../types';
import Text from './Text';

const StyledText = styled(Text)`
    ${HeadlineStyling};

    ${({ theme }) => theme.Headline}
`;

type HeadingTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'div';

export interface IHeadline extends HTMLAttributes<HTMLHeadingElement> {
    as?: HeadingTypes;
    size: fontSizeTypes<18 | 20 | 24 | 28 | 32 | 40 | 48 | 56>;
}

export default function Headline(props: IHeadline): JSX.Element {
    const { as = 'div', size, children, ...restProps } = props;

    return (
        <StyledText forwardedAs={as as any} size={size} {...restProps}>
            {children}
        </StyledText>
    );
}
