import { PriceLabelInfoModel } from 'autogen/swagger/pricelabelinfo';
import useMediatorState, { mediatorAtom } from 'hooks/globals/useMediatorAtom';
import { useGetForFixedLowPriceQuery } from 'autogen/swagger/pricelabelinfo/hooks';
import { growlGeneralExceptionError } from 'modules/growl';

type PriceLabelInfoState = { isOpen: boolean; priceLabelInfo?: PriceLabelInfoModel };

export const priceLabelInfoState = mediatorAtom<PriceLabelInfoState>({
    isOpen: false
});

export default function usePriceLabelInfo() {
    const { refetch: fetchFixedLowPriceInfo } = useGetForFixedLowPriceQuery({ enabled: false });
    const [, toggle] = useMediatorState(priceLabelInfoState);

    function openInfo(type) {
        switch (type) {
            case 'FixedLowPrice':
                fetchFixedLowPriceInfo({ throwOnError: true })
                    .then((data) => toggle({ isOpen: true, priceLabelInfo: data.data }))
                    .catch(() => {
                        growlGeneralExceptionError();
                    });
                break;
            default:
                // Default
                break;
        }
    }

    return openInfo;
}
