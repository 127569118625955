import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import {
    FontBody1WeightSemiBold,
    FontBody1Family,
    FontBody1LineHeight,
    FontBody1WeightRegular
} from 'autogen/design-tokens/tokens';

type sizes = 'sm' | 'md' | 'lg';
type weights = 'regular' | 'semibold';

interface IHeading extends React.AnchorHTMLAttributes<HTMLParagraphElement> {
    size?: sizes;
    weight?: weights;
    children?: ReactNode;
}

const bodySizes = {
    sm: css`
        font-size: 14px;
        line-height: ${FontBody1LineHeight};
    `,
    md: css`
        font-size: 16px;
        line-height: ${FontBody1LineHeight};
    `,
    lg: css`
        font-size: 18px;
        line-height: ${FontBody1LineHeight};
    `
};

const bodyWeights = {
    regular: FontBody1WeightRegular,
    semibold: FontBody1WeightSemiBold
};

const StyledBody = styled.p<{ size: sizes; weight: weights }>`
    ${({ size }) => bodySizes[size]}; //todo bodyfontchange
    font-family: ${FontBody1Family};
    font-weight: ${({ weight }) => bodyWeights[weight]};
    line-height: ${FontBody1LineHeight};
    margin: 0;
`;

export default function Body({ size = 'md', weight = 'regular', children, ...restProps }: IHeading) {
    return (
        <StyledBody size={size} weight={weight} {...restProps}>
            {children}
        </StyledBody>
    );
}
