// eslint-disable-next-line import/prefer-default-export
import Headline from './components/Headline';
import Title from './components/Title';
import Body from './components/Body';
import Overline from './components/Overline';

export { default as headlineStyling } from './styles/headline';
export { default as buttonTextStyling } from './styles/buttonText';
export { default as linkTextStyling } from './styles/linkText';
export { default as titleStyling } from './styles/title';
export { default as overlineStyling } from './styles/overline';

export {
    BodyThemeLarge,
    BodyThemeLargeSemiBold,
    BodyThemeMedium,
    BodyThemeMediumSemiBold,
    BodyThemeSmall,
    BodyThemeSmallSemiBold
} from './styles/body';

export { Headline };
export { Title };
export { Body };
export { Overline };

export const Typography = {
    Headline,
    Title,
    Body,
    Overline
};
