import styled from 'styled-components';
import Section, { type ISection } from '../Section';

const StyledSection = styled(Section)`
    max-width: 984px;
`;

export default function TightSection(props: ISection) {
    return <StyledSection {...props} />;
}
