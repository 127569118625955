import { BoxShadowLarge, ColorNordicGrey1, ColorNordicGrey5 } from 'autogen/design-tokens/tokens';
import SmartSideDrawerView from 'components/generic/smartview/smartviewSideDrawer/components/SmartSideDrawerView';
import { backgroundStripes } from 'modules/helpers/style';
import styled from 'styled-components';

export const MenuStripes = styled.div`
    display: flex;
    min-height: 16px;
    width: 100%;
    ${backgroundStripes({ color1: ColorNordicGrey1, color2: ColorNordicGrey5 })};
    position: absolute;
    bottom: 0;
`;

export const StyledSmartSideDrawerView = styled(SmartSideDrawerView)`
    box-shadow: ${BoxShadowLarge};
    flex-direction: column;
`;
