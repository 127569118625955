
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './bambuser';

        export type BambuserProductVariantModel = components['schemas']['BambuserProductVariantModel'];
                    export type BambuserProductModel = components['schemas']['BambuserProductModel'];
                    export type BambuserBasketLineModel = components['schemas']['BambuserBasketLineModel'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type UrlKey = components['schemas']['UrlKey'];
                    

        
        

        export type BambuserGetProductsParams = paths['/internal/Bambuser/GetProducts']['get']['parameters']['query'];

        export type BambuserGetProductsSuccess = paths['/internal/Bambuser/GetProducts']['get']['responses']['200']['content']['text/plain'];

        export const BambuserGetProductsUrl = '/internal/Bambuser/GetProducts';

        
        export function BambuserGetProducts(params: BambuserGetProductsParams): Promise<BambuserGetProductsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<BambuserGetProductsSuccess>(`/internal/Bambuser/GetProducts${formatParams(params)}`)
        }
    
    
        

        

        export type GetBasketContentSuccess = paths['/internal/Bambuser/GetBasketContent']['get']['responses']['200']['content']['text/plain'];

        export const GetBasketContentUrl = '/internal/Bambuser/GetBasketContent';

        
        export function GetBasketContent(): Promise<GetBasketContentSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBasketContentSuccess>(`/internal/Bambuser/GetBasketContent`)
        }
    
    
        

        export type LookupUrlParams = paths['/internal/Bambuser/LookupUrl']['get']['parameters']['query'];

        export type LookupUrlSuccess = paths['/internal/Bambuser/LookupUrl']['get']['responses']['200']['content']['text/plain'];

        export const LookupUrlUrl = '/internal/Bambuser/LookupUrl';

        
        export function LookupUrl(params: LookupUrlParams): Promise<LookupUrlSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<LookupUrlSuccess>(`/internal/Bambuser/LookupUrl${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    