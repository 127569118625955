import { ColorBrandBlue5 } from 'autogen/design-tokens/tokens';
import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import MenuStyleRenderer from './MenuStyleRenderer';

const Container = styled.div`
    height: 100%;
`;

export default function MenuSkeleton() {
    return (
        <MenuStyleRenderer styling="Default">
            <Container>
                <ContentLoader
                    speed={2}
                    preserveAspectRatio="none"
                    viewBox="0 0 480 800"
                    backgroundColor="#f3f3f3"
                    foregroundColor={ColorBrandBlue5}
                >
                    <path d="M 1 50 h 479 v 2 H 1 z M 1 100 h 479 v 2 H 1 z M 1 150 h 479 v 2 H 1 z M 1 200 h 479 v 2 H 1 z M 1 250 h 479 v 2 H 1 z M 1 300 h 479 v 2 H 1 z M 1 350 h 479 v 2 H 1 z M 1 400 h 479 v 2 H 1 z M 1 450 h 479 v 2 H 1 z M 1 500 h 479 v 2 H 1 z M 1 550 h 479 v 2 H 1 z M 1 600 h 479 v 2 H 1 z M 1 650 h 479 v 2 H 1 z M 1 700 h 479 v 2 H 1 z M 0 749 h 479 v 2 H 0 z" />
                    <circle cx="31" cy="26" r="12" />
                    <circle cx="31" cy="76" r="12" />
                    <circle cx="31" cy="126" r="12" />
                    <circle cx="31" cy="176" r="12" />
                    <circle cx="31" cy="226" r="12" />
                    <circle cx="31" cy="276" r="12" />
                    <circle cx="31" cy="326" r="12" />
                    <circle cx="31" cy="376" r="12" />
                    <circle cx="31" cy="426" r="12" />
                    <circle cx="31" cy="476" r="12" />
                    <circle cx="31" cy="526" r="12" />
                    <circle cx="31" cy="576" r="12" />
                    <circle cx="31" cy="626" r="12" />
                    <circle cx="31" cy="675" r="12" />
                    <circle cx="31" cy="725" r="12" />
                    <circle cx="31" cy="775" r="12" />
                    <path d="M 55 22 h 379 v 8 H 55 z M 55 72 h 268 v 8 H 55 z M 55 122 h 352 v 8 H 55 z M 55 172 h 240 v 8 H 55 z M 55 222 h 322 v 8 H 55 z M 55 272 h 373 v 8 H 55 z M 55 322 h 175 v 8 H 55 z M 55 372 h 299 v 8 H 55 z M 55 422 h 348 v 8 H 55 z M 55 472 h 288 v 8 H 55 z M 55 522 h 384 v 8 H 55 z M 55 571 h 248 v 8 H 55 z M 55 621 h 352 v 8 H 55 z M 55 671 h 352 v 8 H 55 z M 55 721 h 299 v 8 H 55 z M 55 770 h 384 v 8 H 55 z M 443 70 h 18 v 12 h -18 z M 443 320 h 18 v 12 h -18 z M 443 470 h 18 v 12 h -18 z M 443 720 h 18 v 12 h -18 z" />
                </ContentLoader>
            </Container>
        </MenuStyleRenderer>
    );
}
