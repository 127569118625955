export class DebugProduct {
    constructor(item) {
        this.item = $(item);
        this.content = this.item.find('.product-item__debug-content');
        this.productList = this.item.closest('.js-productListPage');
        this.rows = this.content.find('tbody > tr');
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.item.on('mouseenter', () => {
            this.content.show();
            this.placeContent();
        });
        this.item.on('mouseleave', () => this.content.hide());
        this.bindRows();
    }

    placeContent() {
        if (!this.productList) return;

        this.content.css({ left: '0px' });

        const overflow =
            this.content.offset().left +
            this.content.width() -
            (this.productList.offset().left + this.productList.width());

        if (overflow > 0) {
            this.content.css({ left: -overflow + 'px' });
        }
    }

    bindRows() {
        const self = this;
        this.rows.hide();
        this.rows
            .filter(function () {
                return $(this).data('js-level') == '0';
            })
            .show();
        $('[data-js-toggle-rows]', this.rows).change(function () {
            const $row = $(this).closest('[data-js-score-row]');
            self.toggleRow($row, true);
        });
    }

    toggleRow($row, show) {
        const self = this;
        $row.toggle(show);
        const rowId = $row.data('js-row-id');
        const $checkbox = $('#' + rowId, $row);
        const showSubRows = $checkbox.is(':checked') && show;
        const subRows = this.rows.filter(function () {
            return $(this).data('js-parent-row-id') === rowId;
        });
        subRows.each(function () {
            self.toggleRow($(this), showSubRows);
        });
    }
}
