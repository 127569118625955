import type { MiniBasketSampleLineViewModel } from 'autogen/swagger/minibasket';
import { Spacing8, ColorNeutralBlack } from 'autogen/design-tokens/tokens';
import { useRemoveSampleFromBasketMutation } from 'autogen/swagger/basket/hooks';
import { Free } from 'autogen/translation-keys/trans-website-basket';
import { ButtonWithIcon } from 'components/Button';
import Flex from 'components/Layout/components/Flex';
import { updateBasket } from 'components/basket/shared/basket';
import Card from 'components/generic/card';
import ProductImage from 'components/generic/productImage';
import ProductName from 'components/generic/productName';
import dynamic from 'helpers/dynamic';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { breakpoint, sizes } from 'variables';
import { ProductImageContainer } from 'components/generic/productImage/shared/styles';

/* #region styles */
export const Grid = styled.div`
    display: grid;
    gap: ${Spacing8};
    grid-template-columns: auto 64px 1fr;
    align-items: center;

    ${breakpoint.up(sizes.lg)} {
        grid-template-columns: auto 64px 1fr;
    }
`;

/* #endregion */
const Cross = dynamic(() => import('icons/Cross.svg?react'));

export default function MiniBasketSampleItem({ model }: { model: MiniBasketSampleLineViewModel }) {
    const { productName, primaryImage, productId } = model;
    const { t } = useTranslation();
    const { mutateAsync: removeSampleFromBasket } = useRemoveSampleFromBasketMutation({ onSuccess: updateBasket });

    return (
        <Card size={null}>
            <Card.Content>
                <Grid>
                    <ButtonWithIcon
                        css={css`
                            align-self: center;
                        `}
                        size="sm"
                        variant="ghostOnLight"
                        icon={<Cross />}
                        onClick={() => removeSampleFromBasket({ params: { productId } })}
                    />

                    <ProductImageContainer>
                        <ProductImage width={64} image={primaryImage} transformType="SquareScale" />
                    </ProductImageContainer>

                    <Flex.Row justifyContent="space-between" alignItems="center">
                        <Flex.Column gap={Spacing8}>
                            <ProductName
                                productName={productName}
                                size="sm"
                                customTheme={{
                                    ProductTitle: css`
                                        color: ${ColorNeutralBlack};
                                    `
                                }}
                            />
                        </Flex.Column>

                        <Flex.Column alignItems="end">{t(Free)}</Flex.Column>
                    </Flex.Row>
                </Grid>
            </Card.Content>
        </Card>
    );
}
