import React, { type ReactNode } from 'react';
import styled from 'styled-components';
import { ColorNeutralGrey3 } from 'autogen/design-tokens/tokens';
import type { BasketSummaryViewModel } from 'autogen/swagger/basket';
import SummaryLine from './SummaryLines';
import { AdditionalGiftCardSummaryWithHeading } from './SummaryLines/AdditionalGiftCardSummaryRenderer';
import { useRecoilValue } from 'recoil';
import { requestDataState } from 'recoilState/atoms/requestDataState';
import type {
    BasketAdditionalSummary,
    BasketDiscountSummary,
    BasketGiftCardSummary,
    BasketPrizeCertificateSummary,
    BasketShippingPriceSummary,
    BasketSubTotalSummary,
    BasketTotalSummary,
    BasketVoucherSummary
} from 'autogen/swagger/all';

export const BasketSummaryListItem = styled.li`
    border-bottom: 1px solid ${ColorNeutralGrey3};
    margin-bottom: 16px;
    padding-bottom: 16px;
    list-style: none;
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    ${BasketSummaryListItem}:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;

export default function BasketSummary({
    model,
    activePrizeCertificateRenderer = (prizeCertificateModel) => (
        <BasketSummaryListItem>
            <SummaryLine.ActivePrizeCertificateRenderer model={prizeCertificateModel} />
        </BasketSummaryListItem>
    ),
    subTotalSummaryRenderer = (subTotalSummaryModel) => (
        <BasketSummaryListItem>
            <SummaryLine.SubTotalSummary model={subTotalSummaryModel} />
        </BasketSummaryListItem>
    ),
    totalDiscountSummaryRenderer = (discountSummaryModel) => (
        <BasketSummaryListItem>
            <SummaryLine.TotalDiscountSummary model={discountSummaryModel} />
        </BasketSummaryListItem>
    ),
    additionalSummariesRenderer = (additionalSummaryModel) => (
        <BasketSummaryListItem>
            <SummaryLine.AdditionalSummaryRenderer model={additionalSummaryModel} />
        </BasketSummaryListItem>
    ),
    shippingSummaryRenderer = (shippingPriceSummaryModel) => (
        <BasketSummaryListItem>
            <SummaryLine.ShippingSummary model={shippingPriceSummaryModel} />
        </BasketSummaryListItem>
    ),
    voucherSummaryRenderer = (voucherSummaryModel) => (
        <BasketSummaryListItem>
            <SummaryLine.VoucherSummaryRenderer model={voucherSummaryModel} />
        </BasketSummaryListItem>
    ),
    totalPriceSummaryRenderer = (totalSummaryModel) => (
        <BasketSummaryListItem>
            <SummaryLine.TotalPriceSummary model={totalSummaryModel} />
        </BasketSummaryListItem>
    ),
    additionalGiftCardSummaryRenderer = (giftCardSummaryModel) => (
        <BasketSummaryListItem>
            <AdditionalGiftCardSummaryWithHeading model={giftCardSummaryModel} />
        </BasketSummaryListItem>
    )
}: {
    model: BasketSummaryViewModel;
    activePrizeCertificateRenderer?: (prizeCertificateSummaryModel: BasketPrizeCertificateSummary) => ReactNode;
    subTotalSummaryRenderer?: (subTotalSummaryModel: BasketSubTotalSummary) => ReactNode;
    totalDiscountSummaryRenderer?: (discountSummaryModel: BasketDiscountSummary) => ReactNode;
    additionalSummariesRenderer?: (additionalSummaryModel: BasketAdditionalSummary) => ReactNode;
    shippingSummaryRenderer?: (shippingPriceSummaryModel: BasketShippingPriceSummary) => ReactNode;
    voucherSummaryRenderer?: (voucherSummaryModel: BasketVoucherSummary) => ReactNode;
    totalPriceSummaryRenderer?: (totalSummaryModel: BasketTotalSummary) => ReactNode;
    additionalGiftCardSummaryRenderer?: (giftCardSummaryModel: BasketGiftCardSummary) => ReactNode;
}) {
    const { voucher, shipping, prizeCertificate, giftCard, discount, additional, total, subTotal } = model;

    const {
        featureToggles: { pointAsDiscountEnabled }
    } = useRecoilValue(requestDataState);

    return (
        <List>
            {pointAsDiscountEnabled && prizeCertificate && activePrizeCertificateRenderer?.(prizeCertificate)}
            {subTotalSummaryRenderer?.(subTotal)}
            {discount && totalDiscountSummaryRenderer?.(discount)}
            {additional && additionalSummariesRenderer?.(additional)}
            {shipping && shippingSummaryRenderer?.(shipping)}
            {voucher && voucherSummaryRenderer?.(voucher)}
            {!pointAsDiscountEnabled && prizeCertificate && activePrizeCertificateRenderer?.(prizeCertificate)}
            {totalPriceSummaryRenderer?.(total)}
            {giftCard && additionalGiftCardSummaryRenderer?.(giftCard)}
        </List>
    );
}
