import { ColorSystemPositive1, Spacing4 } from 'autogen/design-tokens/tokens';
import type { DiscountInfoViewModel } from 'autogen/swagger/minibasket';
import Flex from 'components/Layout/components/Flex';
import { Typography } from 'components/generic/typography';
import WithIcon from 'components/hoc/withIcon/WithIcon';
import dynamic from 'helpers/dynamic';
import React from 'react';
import styled from 'styled-components';

const Checkmark = styled(dynamic(() => import('icons/Checkmark.svg?react')))`
    fill: ${ColorSystemPositive1};
`;

export default function MiniBasketDiscountInfo({
    model: { visibleTriggeredDiscountTexts }
}: {
    model: DiscountInfoViewModel;
}) {
    if (!visibleTriggeredDiscountTexts.length) return null;

    const discountsTexts = visibleTriggeredDiscountTexts.filter(
        (visibleTriggeredDiscountText) => visibleTriggeredDiscountText.type === 'Discount'
    );

    return (
        <Flex.Column alignItems="start" gap={Spacing4}>
            {discountsTexts.map((discountText, index) =>
                discountText.showCheckmark ? (
                    <WithIcon icon={<Checkmark />} key={index}>
                        <Typography.Body as="span" themeTypes="smallSemiBold">
                            {discountText.text}
                        </Typography.Body>
                    </WithIcon>
                ) : (
                    <Typography.Body as="span" themeTypes="smallSemiBold" key={index}>
                        {discountText.text}
                    </Typography.Body>
                )
            )}
        </Flex.Column>
    );
}
