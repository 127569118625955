/* eslint-disable */
/// This file is auto generated - do not edit manually
export const ButtonText = 'Website.Product.Findation.ButtonText';
export const IntroductionText = 'Website.Product.Findation.IntroductionText';
export const StartButtonText = 'Website.Product.Findation.StartButtonText';
export const NextStepButtonText = 'Website.Product.Findation.NextStepButtonText';
export const BackButtonText = 'Website.Product.Findation.BackButtonText';
export const SeeResultText = 'Website.Product.Findation.SeeResultText';
export const RetryButtonText = 'Website.Product.Findation.RetryButtonText';
export const IntroductionHeadline = 'Website.Product.Findation.IntroductionHeadline';
export const BrandSelectionHeadline = 'Website.Product.Findation.BrandSelectionHeadline';
export const ProductSelectionHeadline = 'Website.Product.Findation.ProductSelectionHeadline';
export const ShadeSelectionHeadline = 'Website.Product.Findation.ShadeSelectionHeadline';
export const ProductSuggestionHeadline = 'Website.Product.Findation.ProductSuggestionHeadline';
export const NoProductFoundHeadline = 'Website.Product.Findation.NoProductFoundHeadline';
export const NoBrandSelectedError = 'Website.Product.Findation.NoBrandSelectedError';
export const NoProductSelectedError = 'Website.Product.Findation.NoProductSelectedError';
export const NoShadeSelectedError = 'Website.Product.Findation.NoShadeSelectedError';
export const SoldOutOnlineError = 'Website.Product.Findation.SoldOutOnlineError';
export const ChooseBrandLabel = 'Website.Product.Findation.ChooseBrandLabel';
export const BrandSelectedLabel = 'Website.Product.Findation.BrandSelectedLabel';
export const ChooseProductLabel = 'Website.Product.Findation.ChooseProductLabel';
export const ProductSelectedLabel = 'Website.Product.Findation.ProductSelectedLabel';
export const ChooseShadeLabel = 'Website.Product.Findation.ChooseShadeLabel';
export const ShadeSelectedLabel = 'Website.Product.Findation.ShadeSelectedLabel';
export const StepOneLegend = 'Website.Product.Findation.StepOneLegend';
export const StepTwoLegend = 'Website.Product.Findation.StepTwoLegend';
export const StepThreeLegend = 'Website.Product.Findation.StepThreeLegend';
export const ProductSuggestionText = 'Website.Product.Findation.ProductSuggestionText';
export const ProductSuggestionDisclaimer = 'Website.Product.Findation.ProductSuggestionDisclaimer';
export const NoProductFoundText = 'Website.Product.Findation.NoProductFoundText';
export const NoProductFoundBookingNudge = 'Website.Product.Findation.NoProductFoundBookingNudge';
export const NoProductFoundBookingLinkText = 'Website.Product.Findation.NoProductFoundBookingLinkText';
