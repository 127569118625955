import { UserContextViewModel } from 'autogen/swagger/recoil';
import { useRecoilValue } from 'recoil';

import { requestDataState } from 'recoilState/atoms/requestDataState';

export default function useUserContext(defaultValue = {}): UserContextViewModel {
    const state = useRecoilValue(requestDataState);

    return state?.userContextModel || (defaultValue as UserContextViewModel);
}
