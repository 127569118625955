import Basket from './Basket';
import Booking from './Booking';
import Favorite from './Favorite';
import FindStore from './FindStore';
import MyProfile from './MyProfile';
import Rebuy from './Rebuy';

const SessionMenuItem = {
    Booking,
    MyProfile,
    Basket,
    FindStore,
    Favorite,
    Rebuy
};

export default SessionMenuItem;
