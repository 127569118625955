import { useCallback, useEffect, useRef, useState } from 'react';

type scrollDirection = 'up' | 'down';

interface ScrollEffect {
    direction: scrollDirection;
    prevDirection: scrollDirection;
    scrollTop: number;
    prevScrollTop: number;
    programmaticScroll: boolean;
}

export default function useScrollEffect(scrollEffectCallback: (ScrollEffect: ScrollEffect) => void, deps: any[] = []) {
    const [scrollModule, setScrollModule] =
        useState<(options: any) => { subscribe: () => void; unsubscribe: () => void }>();
    const scrollInstanceRef = useRef<{ subscribe: () => void; unsubscribe: () => void } | null>(null);

    const importScrollModule = async () => {
        const { default: scrollDirection } = await import('modules/helpers/scroll');
        setScrollModule(() => scrollDirection);
    };

    const initializeScrollModule = useCallback(() => {
        if (scrollModule) {
            scrollInstanceRef.current = scrollModule({
                up: (scrollEffect: ScrollEffect) => scrollEffectCallback(scrollEffect),
                down: (scrollEffectObj: ScrollEffect) => scrollEffectCallback(scrollEffectObj)
            });

            scrollInstanceRef.current.subscribe();
        }
    }, [scrollModule, ...deps]);

    const destroyInstance = () => {
        scrollInstanceRef.current?.unsubscribe();
        scrollInstanceRef.current = null;
    };

    useEffect(() => {
        // We have to dynamically import the scroll module to ensure the components that consume the hook can ssr.
        if (!scrollModule) {
            importScrollModule();
        }

        if (scrollModule && !scrollInstanceRef.current) {
            initializeScrollModule();
        }

        return () => {
            if (scrollModule && scrollInstanceRef.current) {
                destroyInstance();
            }
        };
    }, [initializeScrollModule, scrollModule]);

    return null;
}
