import { atom, atomFamily } from 'recoil';
import { ISmartview, SmartviewSerializableParams } from '../types';

/**
 * Atom for getting all current ids/names of active smartviews instances
 */
export type SmartviewInstanceDetails = {
    name: ISmartview['name'];
    position: ISmartview['position'];
};

export const smartviewInstancesIdsAtom = atom<SmartviewInstanceDetails[]>({
    key: 'smartview-instances-ids-atom',
    default: []
});

/**
 * AtomFamily for getting the instance of a specific smartview
 */
export const smartviewInstanceAtom = atomFamily<ISmartview, SmartviewSerializableParams>({
    key: 'smartview-instance-atom',
    default: (name) => ({
        name,
        position: 'left',
        isOpen: false
    })
});

/**
 * Atom for getting the latest action performed on the smartview
 */
export const latestSmartviewActionAtom = atom<'initialized' | 'terminated' | 'add' | 'remove' | 'replace' | undefined>({
    key: 'smartview-action-atom',
    default: undefined
});
