import {
    FontHeading2WeightSemiBold,
    FontHeading2LineHeight,
    FontHeading2FamilySerif
} from 'autogen/design-tokens/tokens';
import { rem } from 'modules/helpers/style';
import React, { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type sizes = 'sm' | 'lg';

interface IHeading extends HTMLAttributes<HTMLHeadingElement> {
    size?: sizes;
    children: ReactNode;
}

const headingSizes = {
    sm: css`
        font-size: ${rem(24)};
        line-height: ${rem(32)};
    `,
    lg: css`
        font-size: ${rem(32)};
        line-height: ${rem(40)};
    `
};

const StyledHeading2 = styled.h2<{ size: sizes }>`
    ${({ size }) => headingSizes[size]};
    font-weight: ${FontHeading2WeightSemiBold};
    line-height: ${FontHeading2LineHeight};
    font-family: ${FontHeading2FamilySerif};
`;

export default function Heading2({ size = 'sm', children, ...restProps }: IHeading) {
    return (
        <StyledHeading2 size={size} {...restProps}>
            {children}
        </StyledHeading2>
    );
}
