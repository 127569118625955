import React from 'react';
import Text from 'components/generic/typography/components/Text';
import styled from 'styled-components';
import PriceStyling from './shared/styles';
import type { IPrice } from './shared/types';

const StyledText = styled(Text)`
    ${PriceStyling}
`;

export default function Price({ price, pricePrefix, size = 14, ...restProps }: IPrice) {
    const priceText = pricePrefix ? `${pricePrefix} ${price}` : price;

    return (
        <StyledText {...restProps} size={size}>
            {priceText}
        </StyledText>
    );
}
