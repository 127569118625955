import { BorderRadiusMedium, BoxShadowLarge, FontLineHeightNormal } from 'autogen/design-tokens/tokens';
import React, { type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import * as Typography from 'components/__deprecated/typography';
import type { ButtonSizeKeys } from 'components/Button/shared/buttonSizes';
import SelectButton from 'components/shared/inputs/select/components/SelectButton';
import dynamic from 'helpers/dynamic';
import ArrowDown from 'icons/ArrowDown.svg?react';
import useDropdown from '../hooks/useDropdown';

export type DropdownButtonElement = {
    isOpen: boolean;
    placement: string;
};

const StyledDropdownButton = styled(SelectButton)<DropdownButtonElement>`
    border-radius: ${({ isOpen, placement }): string => {
        if (!isOpen) return BorderRadiusMedium;
        switch (placement) {
            case 'bottom-start':
            case 'bottom-end':
                return `${BorderRadiusMedium} ${BorderRadiusMedium} 0 0`;
            case 'top-start':
            case 'top-end':
                return `0 0 ${BorderRadiusMedium} ${BorderRadiusMedium}`;
            default:
                return BorderRadiusMedium;
        }
    }};

    ${({ isOpen }) =>
        isOpen &&
        css`
            box-shadow: ${BoxShadowLarge};
        `};

    &:focus {
        ${({ isOpen }) =>
            isOpen &&
            css`
                border-color: transparent;
            `};
    }
`;

const Arrow = styled(ArrowDown)<{ $isOpen: boolean }>`
    width: 12px;
    height: 12px;
    min-width: 12px;
    margin-left: 8px;
    transition: transform 100ms ease;
    transform: ${({ $isOpen }): string => ($isOpen ? 'rotate(180deg)' : ' rotate(0deg)')};
`;

const StyledLabel = styled(Typography.Label)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: ${FontLineHeightNormal};
`;
interface IDropdownButton {
    size?: ButtonSizeKeys;
    className?: string;
    children: ReactNode;
    onClick?: any;
    ariaLabel?: string;
}

/* The Component implicitly recieves its ref from the Dropdown component (Which internally uses Tippy.js)  */
const DropdownButton = React.forwardRef<HTMLButtonElement, IDropdownButton>(
    ({ children, onClick, size, ariaLabel, ...restProps }, ref: any): JSX.Element => {
        const { isVisible, toggle, placement, disabled, setMinWidth } = useDropdown();

        // https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
        const attachRef = (node: HTMLButtonElement) => {
            ref(node);

            if (node) {
                setMinWidth(node.offsetWidth);
            }
        };

        return (
            <StyledDropdownButton
                {...restProps}
                variant={null}
                disabled={disabled}
                ref={attachRef}
                onClick={onClick || toggle}
                placement={placement}
                isOpen={isVisible}
                size={size}
                aria-label={ariaLabel || ''}
            >
                <StyledLabel size={size === 'lg' ? 'lg' : 'sm'}>{children}</StyledLabel>
                <Arrow $isOpen={isVisible} />
            </StyledDropdownButton>
        );
    }
);

export default DropdownButton;
