import React from 'react';
import { IDropdown } from '../interface/dropdown';
import DropdownProvider from '../partials/DropdownProvider';

const Dropdown: React.FC<React.PropsWithChildren<IDropdown>> = ({ trigger, children, ...restProps }) => (
    <DropdownProvider trigger={trigger} {...restProps}>
        {children}
    </DropdownProvider>
);

export default Dropdown;
