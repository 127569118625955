import type { SearchSuggestion as SearchSuggestionProps } from 'autogen/swagger/product';
import InlineButton from 'components/generic/InlineButton';
import InlineLinkActionButton from 'components/generic/InlineButton/InlineLinkActionButton';
import { Typography } from 'components/generic/typography';
import { GA4Tracking } from 'modules/tracking/GA4';

export default function SearchSuggestion(props: SearchSuggestionProps) {
    const { text, suggestedPhrase, suggestedSearchLinkAction } = props;

    return (
        <Typography.Body as="div">
            {text}
            {': '}
            <InlineLinkActionButton
                action={suggestedSearchLinkAction}
                fontWeight="semibold"
                onClick={() =>
                    GA4Tracking({
                        eventName: 'did_you_mean_search_suggestion',
                        context: 'search',
                        category: 'ecommerce',
                        eventParams: [{ param_name: 'suggested_term', param_value: suggestedPhrase }]
                    })
                }
            >
                {suggestedPhrase}
            </InlineLinkActionButton>
        </Typography.Body>
    );
}
