import { ColorNordicGrey2, ColorNordicGrey5, Spacing16, Spacing24, Spacing8 } from 'autogen/design-tokens/tokens';
import HighlightedText from 'components/generic/HighlightedText';
import { Typography } from 'components/generic/typography';
import { handleSizesMediaQueryStyling } from 'helpers/mediaQueryStyling';
import type { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { type BreakPointsType, breakpoint, sizes } from 'variables';

type ActionFooterSizesKeys = 'md' | 'lg';
type ActionFooterSize = ActionFooterSizesKeys | BreakPointsType<ActionFooterSizesKeys>;

const sizeStyling = {
    md: css`
        padding: ${Spacing16} ${Spacing16} ${Spacing24};

        ${breakpoint.up(sizes.md)} {
            padding: ${Spacing16};
        }
    `,
    lg: css`
        padding: ${Spacing24};
    `
};

export type ActionFooterProps = {
    ctaText?: string;
    onClick?: () => void;
    size?: ActionFooterSize;
    errorMessage?: string;
};

const Wrapper = styled.div<{ $size: ActionFooterProps['size'] }>`
    display: flex;
    flex: none;
    flex-direction: column;
    gap: ${Spacing8};
    width: 100%;
    padding: ${Spacing16};
    background-color: ${ColorNordicGrey5};
    border-top: 1px solid ${ColorNordicGrey2};

    ${({ $size }) =>
        $size && (typeof $size === 'object' ? handleSizesMediaQueryStyling($size, sizeStyling) : sizeStyling[$size])}
`;

export default function ActionFooter(props: ActionFooterProps & HTMLAttributes<HTMLDivElement>) {
    const { size = 'md', errorMessage, children, ...restProps } = props;

    return (
        <Wrapper $size={size} {...restProps}>
            {errorMessage && (
                <HighlightedText color="ColorSystemNegative2" withBorder={false}>
                    <Typography.Body themeTypes="small" as="span">
                        {errorMessage}
                    </Typography.Body>
                </HighlightedText>
            )}
            {children}
        </Wrapper>
    );
}
