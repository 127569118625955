export default class PersonalOffer {
    constructor(item) {
        this.item = $(item);
        this.closeBtn = this.item.find('[data-js-hide-personal-offer]');
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.closeBtn.on('click', () => this.post());
    }

    post() {
        const action = this.closeBtn.attr('data-js-hide-personal-offer');
        $.ajax({
            type: 'POST',
            url: action,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType: 'JSON',
            cache: false
        }).done(response => {
            if (response.success === true) {
                this.item.remove();
            }
        });
    }
}
