
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // noinspection JSUnusedGlobalSymbols
        // noinspection ES6UnusedImports
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import type {components, paths} from './product';

        export type DefaultDataLayerTrackingEvent = components['schemas']['DefaultDataLayerTrackingEvent'];
                    export type DefaultDataLayerTrackingEventMaybe = components['schemas']['DefaultDataLayerTrackingEventMaybe'];
                    export type CheckoutErrorImpressionEvent = components['schemas']['CheckoutErrorImpressionEvent'];
                    export type EcommerceBasketSize = components['schemas']['EcommerceBasketSize'];
                    export type ActionField = components['schemas']['ActionField'];
                    export type Product = components['schemas']['Product'];
                    export type Checkout = components['schemas']['Checkout'];
                    export type EcommerceCheckoutStep = components['schemas']['EcommerceCheckoutStep'];
                    export type AddRemove = components['schemas']['AddRemove'];
                    export type EcommerceProductAddToCartClick = components['schemas']['EcommerceProductAddToCartClick'];
                    export type Click = components['schemas']['Click'];
                    export type EcommerceProductClick = components['schemas']['EcommerceProductClick'];
                    export type Detail = components['schemas']['Detail'];
                    export type EcommerceProductDetail = components['schemas']['EcommerceProductDetail'];
                    export type EcommerceProductImpression = components['schemas']['EcommerceProductImpression'];
                    export type EcommerceProductRemoveFromCartClick = components['schemas']['EcommerceProductRemoveFromCartClick'];
                    export type Promotion = components['schemas']['Promotion'];
                    export type PromoView = components['schemas']['PromoView'];
                    export type EcommercePromotionClick = components['schemas']['EcommercePromotionClick'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type Purchase = components['schemas']['Purchase'];
                    export type EcommercePurchase = components['schemas']['EcommercePurchase'];
                    export type CmsObjectTracking = components['schemas']['CmsObjectTracking'];
                    export type EnhancedEcommerceTrackingEvent = components['schemas']['EnhancedEcommerceTrackingEvent'];
                    export type VwoCampaignAssignmentTrackingEvent = components['schemas']['VwoCampaignAssignmentTrackingEvent'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceBasketSize = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductDetail = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionClick = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePurchase = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase'];
                    export type EnhancedEcommerceTrackingEventOfCmsObjectTracking = components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking'];
                    export type DataLayerDto = components['schemas']['DataLayerDto'];
                    export type TrackingOperationResult = components['schemas']['TrackingOperationResult'];
                    export type FindationBrandListItem = components['schemas']['FindationBrandListItem'];
                    export type FindationBrandListModel = components['schemas']['FindationBrandListModel'];
                    export type FindationProductListItem = components['schemas']['FindationProductListItem'];
                    export type FindationProductListModel = components['schemas']['FindationProductListModel'];
                    export type FindationShadeListItem = components['schemas']['FindationShadeListItem'];
                    export type FindationShadeListModel = components['schemas']['FindationShadeListModel'];
                    export type FindationSearchRequest = components['schemas']['FindationSearchRequest'];
                    export type ProductImageType = components['schemas']['ProductImageType'];
                    export type ProductImage = components['schemas']['ProductImage'];
                    export type ProductFavoriteStatus = components['schemas']['ProductFavoriteStatus'];
                    export type FavoriteStatusModel = components['schemas']['FavoriteStatusModel'];
                    export type ProductAction = components['schemas']['ProductAction'];
                    export type AddToBasketType = components['schemas']['AddToBasketType'];
                    export type ProductFindationSearchResultViewModel = components['schemas']['ProductFindationSearchResultViewModel'];
                    export type ProductCustomerNotificationCreateModel = components['schemas']['ProductCustomerNotificationCreateModel'];
                    export type ProductReviewHeaderModel = components['schemas']['ProductReviewHeaderModel'];
                    export type AdvisorItemType = components['schemas']['AdvisorItemType'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type AdvisorToolboxQuickView = components['schemas']['AdvisorToolboxQuickView'];
                    export type AdvisorToolboxQuickViewMaybe = components['schemas']['AdvisorToolboxQuickViewMaybe'];
                    export type AdvisorToolboxItem = components['schemas']['AdvisorToolboxItem'];
                    export type AdvisorToolboxModel = components['schemas']['AdvisorToolboxModel'];
                    export type ConnectedContentFullwidthLocationType = components['schemas']['ConnectedContentFullwidthLocationType'];
                    export type ConnectedContentAdformTrackingModel = components['schemas']['ConnectedContentAdformTrackingModel'];
                    export type ConnectedContentAdformTrackingModelMaybe = components['schemas']['ConnectedContentAdformTrackingModelMaybe'];
                    export type CertificationCustomAction = components['schemas']['CertificationCustomAction'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type JavascriptHookCustomAction = components['schemas']['JavascriptHookCustomAction'];
                    export type LiveEventCustomAction = components['schemas']['LiveEventCustomAction'];
                    export type SurveyCustomAction = components['schemas']['SurveyCustomAction'];
                    export type VimeoCustomAction = components['schemas']['VimeoCustomAction'];
                    export type CustomActionType = components['schemas']['CustomActionType'];
                    export type LinkActionType = components['schemas']['LinkActionType'];
                    export type PageActionMaybe = components['schemas']['PageActionMaybe'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type ProductLiveEventModelMaybe = components['schemas']['ProductLiveEventModelMaybe'];
                    export type ConnectedContentLinkModel = components['schemas']['ConnectedContentLinkModel'];
                    export type ConnectedContentLinkModelMaybe = components['schemas']['ConnectedContentLinkModelMaybe'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ConnectedContentLayoutType = components['schemas']['ConnectedContentLayoutType'];
                    export type TextAlignmentType = components['schemas']['TextAlignmentType'];
                    export type ConnectedContentDesignTemplateModel = components['schemas']['ConnectedContentDesignTemplateModel'];
                    export type ConnectedContentAdvisorModel = components['schemas']['ConnectedContentAdvisorModel'];
                    export type ConnectedContentAdvisorModelMaybe = components['schemas']['ConnectedContentAdvisorModelMaybe'];
                    export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
                    export type IActiveTimeSpanMaybe = components['schemas']['IActiveTimeSpanMaybe'];
                    export type ConnectedContentDebugInfo = components['schemas']['ConnectedContentDebugInfo'];
                    export type ConnectedContentDebugInfoMaybe = components['schemas']['ConnectedContentDebugInfoMaybe'];
                    export type ConnectedContentFullwidthModel = components['schemas']['ConnectedContentFullwidthModel'];
                    export type ConnectedContentWidthType = components['schemas']['ConnectedContentWidthType'];
                    export type ConnectedContentInlineModel = components['schemas']['ConnectedContentInlineModel'];
                    export type ConnectedContentInventoryLaneBlockModel = components['schemas']['ConnectedContentInventoryLaneBlockModel'];
                    export type ConnectedContentMenuItemModel = components['schemas']['ConnectedContentMenuItemModel'];
                    export type ConnectedContentProductDisplayModel = components['schemas']['ConnectedContentProductDisplayModel'];
                    export type ListItem = components['schemas']['ListItem'];
                    export type ListRowAlignment = components['schemas']['ListRowAlignment'];
                    export type ListRowItem = components['schemas']['ListRowItem'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type IProductImage = components['schemas']['IProductImage'];
                    export type PriceLabelType = components['schemas']['PriceLabelType'];
                    export type PriceLabelModel = components['schemas']['PriceLabelModel'];
                    export type PriceLabelModelMaybe = components['schemas']['PriceLabelModelMaybe'];
                    export type ProductListPriceInfo = components['schemas']['ProductListPriceInfo'];
                    export type SplashPlacement = components['schemas']['SplashPlacement'];
                    export type SplashDisplayType = components['schemas']['SplashDisplayType'];
                    export type SplashType = components['schemas']['SplashType'];
                    export type Splash = components['schemas']['Splash'];
                    export type SplashMaybe = components['schemas']['SplashMaybe'];
                    export type SplashModel = components['schemas']['SplashModel'];
                    export type VariantDisplayType = components['schemas']['VariantDisplayType'];
                    export type VariantListItem = components['schemas']['VariantListItem'];
                    export type ProductListVariantsModel = components['schemas']['ProductListVariantsModel'];
                    export type RecommendationType = components['schemas']['RecommendationType'];
                    export type RecommendationTrackingModelDTO = components['schemas']['RecommendationTrackingModelDTO'];
                    export type SearchEventModelDTO = components['schemas']['SearchEventModelDTO'];
                    export type SearchOrigin = components['schemas']['SearchOrigin'];
                    export type SuggestedSearchEventModelDTO = components['schemas']['SuggestedSearchEventModelDTO'];
                    export type SearchTrackingModelDTO = components['schemas']['SearchTrackingModelDTO'];
                    export type PowerstepTrackingModelDTO = components['schemas']['PowerstepTrackingModelDTO'];
                    export type AdformProductTrackingModelDTO = components['schemas']['AdformProductTrackingModelDTO'];
                    export type TrackingModelDTO = components['schemas']['TrackingModelDTO'];
                    export type TrackingModelDTOMaybe = components['schemas']['TrackingModelDTOMaybe'];
                    export type IExplainedScore = components['schemas']['IExplainedScore'];
                    export type IExplainedScoreMaybe = components['schemas']['IExplainedScoreMaybe'];
                    export type BundleType = components['schemas']['BundleType'];
                    export type IBundleInfo = components['schemas']['IBundleInfo'];
                    export type IBundleInfoMaybe = components['schemas']['IBundleInfoMaybe'];
                    export type UATrackingCategory = components['schemas']['UATrackingCategory'];
                    export type WrappingFlags = components['schemas']['WrappingFlags'];
                    export type ProductListItemModel = components['schemas']['ProductListItemModel'];
                    export type ProductSliderStyling = components['schemas']['ProductSliderStyling'];
                    export type ProductSliderBlockDTO = components['schemas']['ProductSliderBlockDTO'];
                    export type ReviewRating = components['schemas']['ReviewRating'];
                    export type ProductReviewSource = components['schemas']['ProductReviewSource'];
                    export type ProductReviewCreateViewModel = components['schemas']['ProductReviewCreateViewModel'];
                    export type VariantLayoutType = components['schemas']['VariantLayoutType'];
                    export type VariantCategory = components['schemas']['VariantCategory'];
                    export type VariantPickerListItem = components['schemas']['VariantPickerListItem'];
                    export type VariantSectionModel = components['schemas']['VariantSectionModel'];
                    export type ProductCustomerNotificationModel = components['schemas']['ProductCustomerNotificationModel'];
                    export type ProductCustomerNotificationModelMaybe = components['schemas']['ProductCustomerNotificationModelMaybe'];
                    export type VariantPickerModel = components['schemas']['VariantPickerModel'];
                    export type ProductVariantsPdpModel = components['schemas']['ProductVariantsPdpModel'];
                    export type ProductUrlSource = components['schemas']['ProductUrlSource'];
                    export type ProductDetailedImageModel = components['schemas']['ProductDetailedImageModel'];
                    export type ProductJsonModel = components['schemas']['ProductJsonModel'];
                    export type ProductReviewListSort = components['schemas']['ProductReviewListSort'];
                    export type ProductReviewRatingStatisticModel = components['schemas']['ProductReviewRatingStatisticModel'];
                    export type ProductReviewStatisticsModel = components['schemas']['ProductReviewStatisticsModel'];
                    export type Hyperlink = components['schemas']['Hyperlink'];
                    export type TextHyperlink = components['schemas']['TextHyperlink'];
                    export type ProductVariantSimpleModel = components['schemas']['ProductVariantSimpleModel'];
                    export type ProductVariantSimpleModelMaybe = components['schemas']['ProductVariantSimpleModelMaybe'];
                    export type ProductReviewModel = components['schemas']['ProductReviewModel'];
                    export type LoginInfoType = components['schemas']['LoginInfoType'];
                    export type LoginMode = components['schemas']['LoginMode'];
                    export type LoginState = components['schemas']['LoginState'];
                    export type LoginModalRequest = components['schemas']['LoginModalRequest'];
                    export type WriteReviewButton = components['schemas']['WriteReviewButton'];
                    export type TooltipType = components['schemas']['TooltipType'];
                    export type TooltipModel = components['schemas']['TooltipModel'];
                    export type TooltipModelMaybe = components['schemas']['TooltipModelMaybe'];
                    export type ProductReviewListModel = components['schemas']['ProductReviewListModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type StandardPointInfoModel = components['schemas']['StandardPointInfoModel'];
                    export type MatasPlusPointInfoModel = components['schemas']['MatasPlusPointInfoModel'];
                    export type PointInfoViewModel = components['schemas']['PointInfoViewModel'];
                    export type PointInfoViewModelMaybe = components['schemas']['PointInfoViewModelMaybe'];
                    export type PriceLabelViewModel = components['schemas']['PriceLabelViewModel'];
                    export type PriceLabelViewModelMaybe = components['schemas']['PriceLabelViewModelMaybe'];
                    export type PriceInfoViewModel = components['schemas']['PriceInfoViewModel'];
                    export type PriceInfoVariant = components['schemas']['PriceInfoVariant'];
                    export type RegimePriceInfoViewModel = components['schemas']['RegimePriceInfoViewModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type ProductRegimeAdvisorModel = components['schemas']['ProductRegimeAdvisorModel'];
                    export type ProductRegimeSectionModel = components['schemas']['ProductRegimeSectionModel'];
                    export type ProductRegimeProductModel = components['schemas']['ProductRegimeProductModel'];
                    export type SplitTestVariant = components['schemas']['SplitTestVariant'];
                    export type ProductRegimeModel = components['schemas']['ProductRegimeModel'];
                    export type CampaignInfo = components['schemas']['CampaignInfo'];
                    export type CampaignInfoMaybe = components['schemas']['CampaignInfoMaybe'];
                    export type ProductGwpOfferViewModel = components['schemas']['ProductGwpOfferViewModel'];
                    export type ProductGwpOfferViewModelMaybe = components['schemas']['ProductGwpOfferViewModelMaybe'];
                    export type CounterSplashRenderType = components['schemas']['CounterSplashRenderType'];
                    export type CounterSplashItem = components['schemas']['CounterSplashItem'];
                    export type CounterSplash = components['schemas']['CounterSplash'];
                    export type CounterSplashMaybe = components['schemas']['CounterSplashMaybe'];
                    export type ProductOfferModel = components['schemas']['ProductOfferModel'];
                    export type ProductOfferModelMaybe = components['schemas']['ProductOfferModelMaybe'];
                    export type ProductVariantsPdpModelMaybe = components['schemas']['ProductVariantsPdpModelMaybe'];
                    export type ListDisplayType = components['schemas']['ListDisplayType'];
                    export type IMedium = components['schemas']['IMedium'];
                    export type IMediumMaybe = components['schemas']['IMediumMaybe'];
                    export type IBrandSimple = components['schemas']['IBrandSimple'];
                    export type ProductNameRenderer = components['schemas']['ProductNameRenderer'];
                    export type ProductReviewListModelMaybe = components['schemas']['ProductReviewListModelMaybe'];
                    export type ProductTabType = components['schemas']['ProductTabType'];
                    export type ProductTabBlock = components['schemas']['ProductTabBlock'];
                    export type ProductTabBlockListModel = components['schemas']['ProductTabBlockListModel'];
                    export type ProductTabContentBrandTeaserModel = components['schemas']['ProductTabContentBrandTeaserModel'];
                    export type ProductTabDeliveryAndReturnModel = components['schemas']['ProductTabDeliveryAndReturnModel'];
                    export type ProductTabTableItem = components['schemas']['ProductTabTableItem'];
                    export type ProductTabTableModel = components['schemas']['ProductTabTableModel'];
                    export type ProductTabTableModelMaybe = components['schemas']['ProductTabTableModelMaybe'];
                    export type ProductHighlightType = components['schemas']['ProductHighlightType'];
                    export type IProductHighlight = components['schemas']['IProductHighlight'];
                    export type ProductCertificationHighlight = components['schemas']['ProductCertificationHighlight'];
                    export type ProductTabHighlightsModel = components['schemas']['ProductTabHighlightsModel'];
                    export type ProductTabHighlightsModelMaybe = components['schemas']['ProductTabHighlightsModelMaybe'];
                    export type ProductTabInstructionsModel = components['schemas']['ProductTabInstructionsModel'];
                    export type ProductTabDescriptionModel = components['schemas']['ProductTabDescriptionModel'];
                    export type WebsiteIcon = components['schemas']['WebsiteIcon'];
                    export type ProductTabIconItemModel = components['schemas']['ProductTabIconItemModel'];
                    export type ProductTabIconListModel = components['schemas']['ProductTabIconListModel'];
                    export type ProductTabPropertyListItem = components['schemas']['ProductTabPropertyListItem'];
                    export type PropertyListStylingType = components['schemas']['PropertyListStylingType'];
                    export type ProductTabPropertyListModel = components['schemas']['ProductTabPropertyListModel'];
                    export type ProductTabTableListModel = components['schemas']['ProductTabTableListModel'];
                    export type VideoPlatform = components['schemas']['VideoPlatform'];
                    export type ProductVideoType = components['schemas']['ProductVideoType'];
                    export type IProductVideo = components['schemas']['IProductVideo'];
                    export type IProductVideoMaybe = components['schemas']['IProductVideoMaybe'];
                    export type ProductTabUsageModel = components['schemas']['ProductTabUsageModel'];
                    export type ProductTabModel = components['schemas']['ProductTabModel'];
                    export type HyperlinkMaybe = components['schemas']['HyperlinkMaybe'];
                    export type ResponsiveImageLinkModel = components['schemas']['ResponsiveImageLinkModel'];
                    export type ResponsiveImageLinkModelMaybe = components['schemas']['ResponsiveImageLinkModelMaybe'];
                    export type ProductReviewModelMaybe = components['schemas']['ProductReviewModelMaybe'];
                    export type ProductVideoModel = components['schemas']['ProductVideoModel'];
                    export type Product360Directory = components['schemas']['Product360Directory'];
                    export type ProgressiveDiscountViewModel = components['schemas']['ProgressiveDiscountViewModel'];
                    export type ProgressiveDiscountViewModelMaybe = components['schemas']['ProgressiveDiscountViewModelMaybe'];
                    export type ProductButtonContext = components['schemas']['ProductButtonContext'];
                    export type OtcColor = components['schemas']['OtcColor'];
                    export type OtcWarningModel = components['schemas']['OtcWarningModel'];
                    export type OtcWarningModelMaybe = components['schemas']['OtcWarningModelMaybe'];
                    export type ProductStoreStockStatus = components['schemas']['ProductStoreStockStatus'];
                    export type ProductPosStockViewModel = components['schemas']['ProductPosStockViewModel'];
                    export type ProductPosStockViewModelMaybe = components['schemas']['ProductPosStockViewModelMaybe'];
                    export type ProductReviewSummaryViewModel = components['schemas']['ProductReviewSummaryViewModel'];
                    export type ProductReviewSummaryOverviewViewModel = components['schemas']['ProductReviewSummaryOverviewViewModel'];
                    export type ProductReviewSummaryOverviewViewModelMaybe = components['schemas']['ProductReviewSummaryOverviewViewModelMaybe'];
                    export type ImageContent = components['schemas']['ImageContent'];
                    export type VideoContent = components['schemas']['VideoContent'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type StockStatusViewModel = components['schemas']['StockStatusViewModel'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type StockStatusWithPreciseDeliveryAndModalViewModel = components['schemas']['StockStatusWithPreciseDeliveryAndModalViewModel'];
                    export type StockStatusWithPreciseDeliveryViewModel = components['schemas']['StockStatusWithPreciseDeliveryViewModel'];
                    export type IStockStatusViewModelMaybe = components['schemas']['IStockStatusViewModelMaybe'];
                    export type IPointInfo = components['schemas']['IPointInfo'];
                    export type SingleSubscriptionPrice = components['schemas']['SingleSubscriptionPrice'];
                    export type SingleSubscriptionPriceMaybe = components['schemas']['SingleSubscriptionPriceMaybe'];
                    export type DiscountType = components['schemas']['DiscountType'];
                    export type ISubscriptionIntroPriceInfo = components['schemas']['ISubscriptionIntroPriceInfo'];
                    export type ISubscriptionIntroPriceInfoMaybe = components['schemas']['ISubscriptionIntroPriceInfoMaybe'];
                    export type SubscriptionIntroPriceState = components['schemas']['SubscriptionIntroPriceState'];
                    export type SubscriptionPriceInfo = components['schemas']['SubscriptionPriceInfo'];
                    export type ChoosableSubscriptionFrequency = components['schemas']['ChoosableSubscriptionFrequency'];
                    export type ChoosableSubscriptionFrequencyGroup = components['schemas']['ChoosableSubscriptionFrequencyGroup'];
                    export type ChoosableSubscriptionFrequencyMaybe = components['schemas']['ChoosableSubscriptionFrequencyMaybe'];
                    export type SubscriptionFrequencyInfo = components['schemas']['SubscriptionFrequencyInfo'];
                    export type SubscriptionInfoModel = components['schemas']['SubscriptionInfoModel'];
                    export type ProductSubscriptionPriceInfoViewModel = components['schemas']['ProductSubscriptionPriceInfoViewModel'];
                    export type ProductSubscriptionInfoViewModel = components['schemas']['ProductSubscriptionInfoViewModel'];
                    export type ProductSubscriptionInfoViewModelMaybe = components['schemas']['ProductSubscriptionInfoViewModelMaybe'];
                    export type ProductStoreStockRequest = components['schemas']['ProductStoreStockRequest'];
                    export type ProductStoreStockViewModel = components['schemas']['ProductStoreStockViewModel'];
                    export type ProductStoreStockViewModelMaybe = components['schemas']['ProductStoreStockViewModelMaybe'];
                    export type ProductExpectedDeliveryToStoreInfoViewModel = components['schemas']['ProductExpectedDeliveryToStoreInfoViewModel'];
                    export type ProductExpectedDeliveryToStoreInfoViewModelMaybe = components['schemas']['ProductExpectedDeliveryToStoreInfoViewModelMaybe'];
                    export type ProductExpectedDeliveryInfoViewModel = components['schemas']['ProductExpectedDeliveryInfoViewModel'];
                    export type ProductExpectedDeliveryInfoViewModelMaybe = components['schemas']['ProductExpectedDeliveryInfoViewModelMaybe'];
                    export type ProductExpectedDeliveryViewModel = components['schemas']['ProductExpectedDeliveryViewModel'];
                    export type ProductExpectedDeliveryViewModelMaybe = components['schemas']['ProductExpectedDeliveryViewModelMaybe'];
                    export type RevieveVirtualTryOnConfig = components['schemas']['RevieveVirtualTryOnConfig'];
                    export type RevieveVirtualTryOnConfigMaybe = components['schemas']['RevieveVirtualTryOnConfigMaybe'];
                    export type RevieveVirtualTryOnViewModel = components['schemas']['RevieveVirtualTryOnViewModel'];
                    export type IProductMakeupInfo = components['schemas']['IProductMakeupInfo'];
                    export type ProductInformationViewModel = components['schemas']['ProductInformationViewModel'];
                    export type ProductReviewListViewModel = components['schemas']['ProductReviewListViewModel'];
                    export type EmaLogoViewModel = components['schemas']['EmaLogoViewModel'];
                    export type EmaLogoViewModelMaybe = components['schemas']['EmaLogoViewModelMaybe'];
                    export type AdvisorDisplayApplication = components['schemas']['AdvisorDisplayApplication'];
                    export type AdvisorExpertiseType = components['schemas']['AdvisorExpertiseType'];
                    export type TextContent = components['schemas']['TextContent'];
                    export type MediumRatio = components['schemas']['MediumRatio'];
                    export type MediumDisplay = components['schemas']['MediumDisplay'];
                    export type MediumDisplayMaybe = components['schemas']['MediumDisplayMaybe'];
                    export type Advisor = components['schemas']['Advisor'];
                    export type AdvisorDetailedModel = components['schemas']['AdvisorDetailedModel'];
                    export type ProductAdvisorModel = components['schemas']['ProductAdvisorModel'];
                    export type ProductAdvisorModelMaybe = components['schemas']['ProductAdvisorModelMaybe'];
                    export type AdvisorToolboxModelMaybe = components['schemas']['AdvisorToolboxModelMaybe'];
                    export type ProductRegimeModelMaybe = components['schemas']['ProductRegimeModelMaybe'];
                    export type ProductRecommendationRequest = components['schemas']['ProductRecommendationRequest'];
                    export type BasketRecommendationRequest = components['schemas']['BasketRecommendationRequest'];
                    export type ProductGroupRecommendationRequest = components['schemas']['ProductGroupRecommendationRequest'];
                    export type SearchResultRecommendationRequest = components['schemas']['SearchResultRecommendationRequest'];
                    export type ZeroSearchResultRecommendationRequest = components['schemas']['ZeroSearchResultRecommendationRequest'];
                    export type HomePageRecommendationRequest = components['schemas']['HomePageRecommendationRequest'];
                    export type PowerstepRecommendationRequest = components['schemas']['PowerstepRecommendationRequest'];
                    export type PowerstepTwoRecommendationRequest = components['schemas']['PowerstepTwoRecommendationRequest'];
                    export type ReceiptPageRecommendationRequest = components['schemas']['ReceiptPageRecommendationRequest'];
                    export type RecommendationProductSliderCustomModuleType = components['schemas']['RecommendationProductSliderCustomModuleType'];
                    export type CustomRecommendationRequest = components['schemas']['CustomRecommendationRequest'];
                    export type RecommendationRequestWrapper = components['schemas']['RecommendationRequestWrapper'];
                    export type RecommendationRequestWrapperMaybe = components['schemas']['RecommendationRequestWrapperMaybe'];
                    export type ProductReviewHeaderModelMaybe = components['schemas']['ProductReviewHeaderModelMaybe'];
                    export type MerchantReturnPolicyStructuredData = components['schemas']['MerchantReturnPolicyStructuredData'];
                    export type BrandStructuredData = components['schemas']['BrandStructuredData'];
                    export type AggregateRatingStructuredData = components['schemas']['AggregateRatingStructuredData'];
                    export type AggregateRatingStructuredDataMaybe = components['schemas']['AggregateRatingStructuredDataMaybe'];
                    export type QuantitativeValueStructuredData = components['schemas']['QuantitativeValueStructuredData'];
                    export type UnitPriceSpecificationStructuredData = components['schemas']['UnitPriceSpecificationStructuredData'];
                    export type OfferStructuredData = components['schemas']['OfferStructuredData'];
                    export type PersonStructuredData = components['schemas']['PersonStructuredData'];
                    export type RatingStructuredData = components['schemas']['RatingStructuredData'];
                    export type ReviewStructuredData = components['schemas']['ReviewStructuredData'];
                    export type ProductStructuredData = components['schemas']['ProductStructuredData'];
                    export type GoogleTagParams = components['schemas']['GoogleTagParams'];
                    export type RemarketingData = components['schemas']['RemarketingData'];
                    export type ProductPrelaunchBannerModel = components['schemas']['ProductPrelaunchBannerModel'];
                    export type ProductPrelaunchBannerModelMaybe = components['schemas']['ProductPrelaunchBannerModelMaybe'];
                    export type ProductPageViewModel = components['schemas']['ProductPageViewModel'];
                    export type CertificationModel = components['schemas']['CertificationModel'];
                    export type ProductExpectedDeliveryToStoreInfoModel = components['schemas']['ProductExpectedDeliveryToStoreInfoModel'];
                    export type ProductExpectedDeliveryToStoreInfoModelMaybe = components['schemas']['ProductExpectedDeliveryToStoreInfoModelMaybe'];
                    export type ProductExpectedDeliveryInfoModel = components['schemas']['ProductExpectedDeliveryInfoModel'];
                    export type ProductExpectedDeliveryInfoModelMaybe = components['schemas']['ProductExpectedDeliveryInfoModelMaybe'];
                    export type ProductListBonusGiftHeaderModel = components['schemas']['ProductListBonusGiftHeaderModel'];
                    export type ProductListBrandHeaderModel = components['schemas']['ProductListBrandHeaderModel'];
                    export type CountdownSizeType = components['schemas']['CountdownSizeType'];
                    export type CountdownModel = components['schemas']['CountdownModel'];
                    export type CountdownModelMaybe = components['schemas']['CountdownModelMaybe'];
                    export type ExtendedPlaceholderHeaderModel = components['schemas']['ExtendedPlaceholderHeaderModel'];
                    export type ExtendedPlaceholderHeaderModelMaybe = components['schemas']['ExtendedPlaceholderHeaderModelMaybe'];
                    export type ExtendedPlaceholderContentItemModel = components['schemas']['ExtendedPlaceholderContentItemModel'];
                    export type ExtendedPlaceholderContentModel = components['schemas']['ExtendedPlaceholderContentModel'];
                    export type ExtendedPlaceholderContentModelMaybe = components['schemas']['ExtendedPlaceholderContentModelMaybe'];
                    export type ExtendedPlaceholderShopByItemModel = components['schemas']['ExtendedPlaceholderShopByItemModel'];
                    export type ExtendedPlaceholderShopByModel = components['schemas']['ExtendedPlaceholderShopByModel'];
                    export type ExtendedPlaceholderShopByModelMaybe = components['schemas']['ExtendedPlaceholderShopByModelMaybe'];
                    export type ExtendedPlaceholderProductsModel = components['schemas']['ExtendedPlaceholderProductsModel'];
                    export type ExtendedPlaceholderProductsModelMaybe = components['schemas']['ExtendedPlaceholderProductsModelMaybe'];
                    export type InventoryLaneModel = components['schemas']['InventoryLaneModel'];
                    export type InventoryLaneModelMaybe = components['schemas']['InventoryLaneModelMaybe'];
                    export type ExtendedPlaceholderInventoryModel = components['schemas']['ExtendedPlaceholderInventoryModel'];
                    export type ExtendedPlaceholderInventoryModelMaybe = components['schemas']['ExtendedPlaceholderInventoryModelMaybe'];
                    export type ExtendedPlaceholderModel = components['schemas']['ExtendedPlaceholderModel'];
                    export type ProductListOfferHeaderModel = components['schemas']['ProductListOfferHeaderModel'];
                    export type ProductListRebuyHeaderModel = components['schemas']['ProductListRebuyHeaderModel'];
                    export type ProductListTextHeaderModel = components['schemas']['ProductListTextHeaderModel'];
                    export type AttributeLocation = components['schemas']['AttributeLocation'];
                    export type AttributeViewModel = components['schemas']['AttributeViewModel'];
                    export type RangeFilterViewModel = components['schemas']['RangeFilterViewModel'];
                    export type BoolBaseFilterOption = components['schemas']['BoolBaseFilterOption'];
                    export type BoolFilterViewModel = components['schemas']['BoolFilterViewModel'];
                    export type PriceFilterViewModel = components['schemas']['PriceFilterViewModel'];
                    export type BoolStarBaseFilterOption = components['schemas']['BoolStarBaseFilterOption'];
                    export type BoolStarFilterViewModel = components['schemas']['BoolStarFilterViewModel'];
                    export type ColorBaseFilterOption = components['schemas']['ColorBaseFilterOption'];
                    export type ColorFilterViewModel = components['schemas']['ColorFilterViewModel'];
                    export type LinkBaseFilterOption = components['schemas']['LinkBaseFilterOption'];
                    export type LinkFilterViewModel = components['schemas']['LinkFilterViewModel'];
                    export type SimpleActiveFilter = components['schemas']['SimpleActiveFilter'];
                    export type ColorActiveFilter = components['schemas']['ColorActiveFilter'];
                    export type TextElementViewModel = components['schemas']['TextElementViewModel'];
                    export type PlaceholderLinkType = components['schemas']['PlaceholderLinkType'];
                    export type LinkKey = components['schemas']['LinkKey'];
                    export type LinkElementViewModel = components['schemas']['LinkElementViewModel'];
                    export type ProductListMenuItemViewModel = components['schemas']['ProductListMenuItemViewModel'];
                    export type MenuRootViewModel = components['schemas']['MenuRootViewModel'];
                    export type MenuRootViewModelMaybe = components['schemas']['MenuRootViewModelMaybe'];
                    export type PageViewModel = components['schemas']['PageViewModel'];
                    export type PageViewModelMaybe = components['schemas']['PageViewModelMaybe'];
                    export type PagingViewModel = components['schemas']['PagingViewModel'];
                    export type FilterOptionViewModel = components['schemas']['FilterOptionViewModel'];
                    export type ProductListResultViewModel = components['schemas']['ProductListResultViewModel'];
                    export type ProductGroupViewModel = components['schemas']['ProductGroupViewModel'];
                    export type ProductListType = components['schemas']['ProductListType'];
                    export type ListMenuKeysViewModel = components['schemas']['ListMenuKeysViewModel'];
                    export type BreadcrumbItemViewModel = components['schemas']['BreadcrumbItemViewModel'];
                    export type BreadcrumbViewModel = components['schemas']['BreadcrumbViewModel'];
                    export type Subsite = components['schemas']['Subsite'];
                    export type ProductListPartialViewModel = components['schemas']['ProductListPartialViewModel'];
                    export type IProductGroupSimple = components['schemas']['IProductGroupSimple'];
                    export type IProductGroupSimpleMaybe = components['schemas']['IProductGroupSimpleMaybe'];
                    export type ProductGroupRecommendationRequestMaybe = components['schemas']['ProductGroupRecommendationRequestMaybe'];
                    export type RevieveMakeupAdvisorViewModel = components['schemas']['RevieveMakeupAdvisorViewModel'];
                    export type RevieveMakeupAdvisorViewModelMaybe = components['schemas']['RevieveMakeupAdvisorViewModelMaybe'];
                    export type ProductListViewModel = components['schemas']['ProductListViewModel'];
                    export type ProductReviewField = components['schemas']['ProductReviewField'];
                    export type ValidatedOperationResult = components['schemas']['ValidatedOperationResult'];
                    export type OperationResult = components['schemas']['OperationResult'];
                    export type IProductReviewLikeResult = components['schemas']['IProductReviewLikeResult'];
                    export type QuickSearchScopedSuggestionViewModel = components['schemas']['QuickSearchScopedSuggestionViewModel'];
                    export type QuickSearchSuggestionViewModel = components['schemas']['QuickSearchSuggestionViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchSuggestionViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchSuggestionViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchSuggestionViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchSuggestionViewModelMaybe'];
                    export type QuickSearchArticleViewModel = components['schemas']['QuickSearchArticleViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchArticleViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchArticleViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchArticleViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchArticleViewModelMaybe'];
                    export type SimplePriceInfoViewModel = components['schemas']['SimplePriceInfoViewModel'];
                    export type QuickSearchProductViewModel = components['schemas']['QuickSearchProductViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchProductViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchProductViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchProductViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchProductViewModelMaybe'];
                    export type QuickSearchBrandViewModel = components['schemas']['QuickSearchBrandViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchBrandViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchBrandViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchBrandViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchBrandViewModelMaybe'];
                    export type QuickSearchNextQueryViewModel = components['schemas']['QuickSearchNextQueryViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchNextQueryViewModel = components['schemas']['QuickSearchSectionViewModelOfQuickSearchNextQueryViewModel'];
                    export type QuickSearchSectionViewModelOfQuickSearchNextQueryViewModelMaybe = components['schemas']['QuickSearchSectionViewModelOfQuickSearchNextQueryViewModelMaybe'];
                    export type QuickSearchViewModel = components['schemas']['QuickSearchViewModel'];
                    export type MetaRobotSetting = components['schemas']['MetaRobotSetting'];
                    export type LocalizedPageUrlModel = components['schemas']['LocalizedPageUrlModel'];
                    export type PageMetadataModel = components['schemas']['PageMetadataModel'];
                    export type ISupplier = components['schemas']['ISupplier'];
                    export type ISupplierGroup = components['schemas']['ISupplierGroup'];
                    export type IPrimaryProductGroupInfo = components['schemas']['IPrimaryProductGroupInfo'];
                    export type IPrimaryProductGroupInfoMaybe = components['schemas']['IPrimaryProductGroupInfoMaybe'];
                    export type DeliveryMinMax = components['schemas']['DeliveryMinMax'];
                    export type ISystemProductStock = components['schemas']['ISystemProductStock'];
                    export type SynonymGroup = components['schemas']['SynonymGroup'];
                    export type ISynonym = components['schemas']['ISynonym'];
                    export type ISerieSimple = components['schemas']['ISerieSimple'];
                    export type ISerieSimpleMaybe = components['schemas']['ISerieSimpleMaybe'];
                    export type IPageMetadata = components['schemas']['IPageMetadata'];
                    export type ProductType = components['schemas']['ProductType'];
                    export type ProductBundleType = components['schemas']['ProductBundleType'];
                    export type DigitalProductType = components['schemas']['DigitalProductType'];
                    export type PriceLabelVisibilityFlags = components['schemas']['PriceLabelVisibilityFlags'];
                    export type IPriceLabel = components['schemas']['IPriceLabel'];
                    export type IPriceLabelMaybe = components['schemas']['IPriceLabelMaybe'];
                    export type IProgressiveDiscountStep = components['schemas']['IProgressiveDiscountStep'];
                    export type IProgressiveDiscountPriceInfo = components['schemas']['IProgressiveDiscountPriceInfo'];
                    export type IProgressiveDiscountPriceInfoMaybe = components['schemas']['IProgressiveDiscountPriceInfoMaybe'];
                    export type IPriceInfo = components['schemas']['IPriceInfo'];
                    export type IDiscountInfo = components['schemas']['IDiscountInfo'];
                    export type ISubscriptionInfo = components['schemas']['ISubscriptionInfo'];
                    export type ISubscriptionInfoMaybe = components['schemas']['ISubscriptionInfoMaybe'];
                    export type IUnitInfo = components['schemas']['IUnitInfo'];
                    export type IProductReviewRatingStatistic = components['schemas']['IProductReviewRatingStatistic'];
                    export type IProductReviewStatistics = components['schemas']['IProductReviewStatistics'];
                    export type IOtcInfo = components['schemas']['IOtcInfo'];
                    export type IProductVariant = components['schemas']['IProductVariant'];
                    export type ProductActions = components['schemas']['ProductActions'];
                    export type ProductShopStatus = components['schemas']['ProductShopStatus'];
                    export type GwpOfferType = components['schemas']['GwpOfferType'];
                    export type IGwpOffer = components['schemas']['IGwpOffer'];
                    export type IGwpOfferMaybe = components['schemas']['IGwpOfferMaybe'];
                    export type IAttributeString = components['schemas']['IAttributeString'];
                    export type Dimensions = components['schemas']['Dimensions'];
                    export type IProductPhysicalProperties = components['schemas']['IProductPhysicalProperties'];
                    export type HazardSymbolTypes = components['schemas']['HazardSymbolTypes'];
                    export type SampleType = components['schemas']['SampleType'];
                    export type ISampleInfo = components['schemas']['ISampleInfo'];
                    export type ISampleInfoMaybe = components['schemas']['ISampleInfoMaybe'];
                    export type IProductKeyIngredient = components['schemas']['IProductKeyIngredient'];
                    export type IProductTip = components['schemas']['IProductTip'];
                    export type IProductTipMaybe = components['schemas']['IProductTipMaybe'];
                    export type ProductFragranceType = components['schemas']['ProductFragranceType'];
                    export type IProductFragrance = components['schemas']['IProductFragrance'];
                    export type ProductDoseType = components['schemas']['ProductDoseType'];
                    export type QuantityInterval = components['schemas']['QuantityInterval'];
                    export type IProductDose = components['schemas']['IProductDose'];
                    export type ProductMicronutrientType = components['schemas']['ProductMicronutrientType'];
                    export type IProductMicronutrient = components['schemas']['IProductMicronutrient'];
                    export type IProductNutrient = components['schemas']['IProductNutrient'];
                    export type IProductNutrientMaybe = components['schemas']['IProductNutrientMaybe'];
                    export type BlacklistFlags = components['schemas']['BlacklistFlags'];
                    export type MediaType = components['schemas']['MediaType'];
                    export type IProductCustomMediaContent = components['schemas']['IProductCustomMediaContent'];
                    export type IProductDetailed = components['schemas']['IProductDetailed'];
                    export type IProductDetailedMaybe = components['schemas']['IProductDetailedMaybe'];
                    export type SearchSuggestion = components['schemas']['SearchSuggestion'];
                    export type SearchSuggestionMaybe = components['schemas']['SearchSuggestionMaybe'];
                    export type RelatedTagModel = components['schemas']['RelatedTagModel'];
                    export type AttributeType = components['schemas']['AttributeType'];
                    export type AttributeSource = components['schemas']['AttributeSource'];
                    export type AttributeBehavior = components['schemas']['AttributeBehavior'];
                    export type SortDirection = components['schemas']['SortDirection'];
                    export type AttributeKey = components['schemas']['AttributeKey'];
                    export type FilterType = components['schemas']['FilterType'];
                    export type IFilterModel = components['schemas']['IFilterModel'];
                    export type IFilterContainer = components['schemas']['IFilterContainer'];
                    export type ActiveFilterModel = components['schemas']['ActiveFilterModel'];
                    export type IMenuItemElementModel = components['schemas']['IMenuItemElementModel'];
                    export type IIdentifiableMenuItem = components['schemas']['IIdentifiableMenuItem'];
                    export type IMenuItemModel = components['schemas']['IMenuItemModel'];
                    export type IMenuRootModel = components['schemas']['IMenuRootModel'];
                    export type IMenuRootModelMaybe = components['schemas']['IMenuRootModelMaybe'];
                    export type PersonalFiltersModel = components['schemas']['PersonalFiltersModel'];
                    export type PersonalFiltersModelMaybe = components['schemas']['PersonalFiltersModelMaybe'];
                    export type PageModel = components['schemas']['PageModel'];
                    export type PageModelMaybe = components['schemas']['PageModelMaybe'];
                    export type PagingModel = components['schemas']['PagingModel'];
                    export type FilterOption = components['schemas']['FilterOption'];
                    export type ProductListResult = components['schemas']['ProductListResult'];
                    export type ProductGroupModel = components['schemas']['ProductGroupModel'];
                    export type ListMenuKeys = components['schemas']['ListMenuKeys'];
                    export type BreadcrumbItemModel = components['schemas']['BreadcrumbItemModel'];
                    export type IProductGroupBreadcrumb = components['schemas']['IProductGroupBreadcrumb'];
                    export type IProductGroupBreadcrumbMaybe = components['schemas']['IProductGroupBreadcrumbMaybe'];
                    export type BreadcrumbModel = components['schemas']['BreadcrumbModel'];
                    export type ProductListPartialModel = components['schemas']['ProductListPartialModel'];
                    export type SearchModel = components['schemas']['SearchModel'];
                    export type SearchResultRecommendationRequestMaybe = components['schemas']['SearchResultRecommendationRequestMaybe'];
                    export type SearchViewModel = components['schemas']['SearchViewModel'];
                    export type SponsoredProductDisclaimerViewModel = components['schemas']['SponsoredProductDisclaimerViewModel'];
                    

        
        

        export type UpdateStatusParams = paths['/internal/Favorite/UpdateStatus']['post']['parameters']['query'];

        export type UpdateStatusSuccess = paths['/internal/Favorite/UpdateStatus']['post']['responses']['200']['content']['text/plain'];

        export const UpdateStatusUrl = '/internal/Favorite/UpdateStatus';

        
        export function UpdateStatus(params: UpdateStatusParams): Promise<UpdateStatusSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<UpdateStatusSuccess>(`/internal/Favorite/UpdateStatus${formatParams(params)}`)
        }
    
    
        

        

        export type GetBrandsSuccess = paths['/internal/Findation/GetBrands']['get']['responses']['200']['content']['text/plain'];

        export const GetBrandsUrl = '/internal/Findation/GetBrands';

        
        export function GetBrands(): Promise<GetBrandsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetBrandsSuccess>(`/internal/Findation/GetBrands`)
        }
    
    
        

        export type FindationGetProductsParams = paths['/internal/Findation/GetProducts']['get']['parameters']['query'];

        export type FindationGetProductsSuccess = paths['/internal/Findation/GetProducts']['get']['responses']['200']['content']['text/plain'];

        export const FindationGetProductsUrl = '/internal/Findation/GetProducts';

        
        export function FindationGetProducts(params: FindationGetProductsParams): Promise<FindationGetProductsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<FindationGetProductsSuccess>(`/internal/Findation/GetProducts${formatParams(params)}`)
        }
    
    
        

        export type GetShadesParams = paths['/internal/Findation/GetShades']['get']['parameters']['query'];

        export type GetShadesSuccess = paths['/internal/Findation/GetShades']['get']['responses']['200']['content']['text/plain'];

        export const GetShadesUrl = '/internal/Findation/GetShades';

        
        export function GetShades(params: GetShadesParams): Promise<GetShadesSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetShadesSuccess>(`/internal/Findation/GetShades${formatParams(params)}`)
        }
    
    
        export type FindationSearchRequestBody = paths['/internal/Findation/Search']['post']['requestBody']['content']['application/json'];

        

        export type FindationSearchSuccess = paths['/internal/Findation/Search']['post']['responses']['200']['content']['text/plain'];

        export const FindationSearchUrl = '/internal/Findation/Search';

        
        export function FindationSearch(requestBody: FindationSearchRequestBody): Promise<FindationSearchSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<FindationSearchSuccess>(`/internal/Findation/Search`, requestBody)
        }
    
    
        

        

        export type ProductCustomerNotificationCreateModelSuccess = paths['/internal/ModelOnly/ProductCustomerNotificationCreateModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductCustomerNotificationCreateModelUrl = '/internal/ModelOnly/ProductCustomerNotificationCreateModel';

        
        export function ProductCustomerNotificationCreateModel(): Promise<ProductCustomerNotificationCreateModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductCustomerNotificationCreateModelSuccess>(`/internal/ModelOnly/ProductCustomerNotificationCreateModel`)
        }
    
    
        

        

        export type ProductReviewHeaderModelSuccess = paths['/internal/ModelOnly/ProductReviewHeaderModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductReviewHeaderModelUrl = '/internal/ModelOnly/ProductReviewHeaderModel';

        
        export function ProductReviewHeaderModel(): Promise<ProductReviewHeaderModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductReviewHeaderModelSuccess>(`/internal/ModelOnly/ProductReviewHeaderModel`)
        }
    
    
        

        

        export type AdvisorToolboxModelSuccess = paths['/internal/ModelOnly/AdvisorToolboxModel']['get']['responses']['200']['content']['text/plain'];

        export const AdvisorToolboxModelUrl = '/internal/ModelOnly/AdvisorToolboxModel';

        
        export function AdvisorToolboxModel(): Promise<AdvisorToolboxModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<AdvisorToolboxModelSuccess>(`/internal/ModelOnly/AdvisorToolboxModel`)
        }
    
    
        

        

        export type ProductSliderBlockDTOModelSuccess = paths['/internal/ModelOnly/ProductSliderBlockDTOModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductSliderBlockDTOModelUrl = '/internal/ModelOnly/ProductSliderBlockDTOModel';

        
        export function ProductSliderBlockDTOModel(): Promise<ProductSliderBlockDTOModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductSliderBlockDTOModelSuccess>(`/internal/ModelOnly/ProductSliderBlockDTOModel`)
        }
    
    
        

        

        export type ProductReviewCreateViewModelSuccess = paths['/internal/ModelOnly/ProductReviewCreateViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductReviewCreateViewModelUrl = '/internal/ModelOnly/ProductReviewCreateViewModel';

        
        export function ProductReviewCreateViewModel(): Promise<ProductReviewCreateViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductReviewCreateViewModelSuccess>(`/internal/ModelOnly/ProductReviewCreateViewModel`)
        }
    
    
        

        export type GetVariantColorPickerForPdpParams = paths['/internal/Product/GetVariantColorPickerForPdp']['get']['parameters']['query'];

        export type GetVariantColorPickerForPdpSuccess = paths['/internal/Product/GetVariantColorPickerForPdp']['get']['responses']['200']['content']['text/plain'];

        export const GetVariantColorPickerForPdpUrl = '/internal/Product/GetVariantColorPickerForPdp';

        
        export function GetVariantColorPickerForPdp(params: GetVariantColorPickerForPdpParams): Promise<GetVariantColorPickerForPdpSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetVariantColorPickerForPdpSuccess>(`/internal/Product/GetVariantColorPickerForPdp${formatParams(params)}`)
        }
    
    
        

        export type GetVariantPickerForPlpParams = paths['/internal/Product/GetVariantPickerForPlp']['get']['parameters']['query'];

        export type GetVariantPickerForPlpSuccess = paths['/internal/Product/GetVariantPickerForPlp']['get']['responses']['200']['content']['text/plain'];

        export const GetVariantPickerForPlpUrl = '/internal/Product/GetVariantPickerForPlp';

        
        export function GetVariantPickerForPlp(params: GetVariantPickerForPlpParams): Promise<GetVariantPickerForPlpSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetVariantPickerForPlpSuccess>(`/internal/Product/GetVariantPickerForPlp${formatParams(params)}`)
        }
    
    
        

        

        export type ProductVariantsPdpModelSuccess = paths['/internal/Product/ProductVariantsPdpModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductVariantsPdpModelUrl = '/internal/Product/ProductVariantsPdpModel';

        
        export function ProductVariantsPdpModel(): Promise<ProductVariantsPdpModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductVariantsPdpModelSuccess>(`/internal/Product/ProductVariantsPdpModel`)
        }
    
    
        

        export type GetProductJsonParams = paths['/internal/Product/GetProductJson']['get']['parameters']['query'];

        export type GetProductJsonSuccess = paths['/internal/Product/GetProductJson']['get']['responses']['200']['content']['text/plain'];

        export const GetProductJsonUrl = '/internal/Product/GetProductJson';

        
        export function GetProductJson(params: GetProductJsonParams): Promise<GetProductJsonSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductJsonSuccess>(`/internal/Product/GetProductJson${formatParams(params)}`)
        }
    
    
        

        export type _GetAllProductReviewsParams = paths['/internal/Product/_GetAllProductReviews']['get']['parameters']['query'];

        export type _GetAllProductReviewsSuccess = paths['/internal/Product/_GetAllProductReviews']['get']['responses']['200']['content']['text/plain'];

        export const _GetAllProductReviewsUrl = '/internal/Product/_GetAllProductReviews';

        
        export function _GetAllProductReviews(params: _GetAllProductReviewsParams): Promise<_GetAllProductReviewsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<_GetAllProductReviewsSuccess>(`/internal/Product/_GetAllProductReviews${formatParams(params)}`)
        }
    
    
        export type CreateCustomerNotificationRequestBody = paths['/internal/Product/CreateCustomerNotification']['post']['requestBody']['content']['application/json'];

        

        export type CreateCustomerNotificationSuccess = paths['/internal/Product/CreateCustomerNotification']['post']['responses']['200']['content']['text/plain'];

        export const CreateCustomerNotificationUrl = '/internal/Product/CreateCustomerNotification';

        
        export function CreateCustomerNotification(requestBody: CreateCustomerNotificationRequestBody): Promise<CreateCustomerNotificationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postJSON<CreateCustomerNotificationSuccess>(`/internal/Product/CreateCustomerNotification`, requestBody)
        }
    
    
        

        export type GetRegimePriceParams = paths['/internal/Product/GetRegimePrice']['get']['parameters']['query'];

        export type GetRegimePriceSuccess = paths['/internal/Product/GetRegimePrice']['get']['responses']['200']['content']['text/plain'];

        export const GetRegimePriceUrl = '/internal/Product/GetRegimePrice';

        
        export function GetRegimePrice(params: GetRegimePriceParams): Promise<GetRegimePriceSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetRegimePriceSuccess>(`/internal/Product/GetRegimePrice${formatParams(params)}`)
        }
    
    
        

        export type GetProductRegimeParams = paths['/internal/Product/GetProductRegime']['get']['parameters']['query'];

        export type GetProductRegimeSuccess = paths['/internal/Product/GetProductRegime']['get']['responses']['200']['content']['text/plain'];

        export const GetProductRegimeUrl = '/internal/Product/GetProductRegime';

        
        export function GetProductRegime(params: GetProductRegimeParams): Promise<GetProductRegimeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductRegimeSuccess>(`/internal/Product/GetProductRegime${formatParams(params)}`)
        }
    
    
        

        

        export type ProductInformationViewModelSuccess = paths['/internal/Product/ProductInformationViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductInformationViewModelUrl = '/internal/Product/ProductInformationViewModel';

        
        export function ProductInformationViewModel(): Promise<ProductInformationViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductInformationViewModelSuccess>(`/internal/Product/ProductInformationViewModel`)
        }
    
    
        

        export type ProductPageViewModelParams = paths['/internal/Product/ProductPageViewModel']['get']['parameters']['query'];

        export type ProductPageViewModelSuccess = paths['/internal/Product/ProductPageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductPageViewModelUrl = '/internal/Product/ProductPageViewModel';

        
        export function ProductPageViewModel(params: ProductPageViewModelParams): Promise<ProductPageViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductPageViewModelSuccess>(`/internal/Product/ProductPageViewModel${formatParams(params)}`)
        }
    
    
        

        

        

        export const _GetProductReturnInformationUrl = '/internal/Product/_GetProductReturnInformation';

        
        export function _GetProductReturnInformation(): Promise<unknown> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<unknown>(`/internal/Product/_GetProductReturnInformation`)
        }
    
    
        

        export type GetCertificationParams = paths['/internal/Product/GetCertification']['get']['parameters']['query'];

        export type GetCertificationSuccess = paths['/internal/Product/GetCertification']['get']['responses']['200']['content']['text/plain'];

        export const GetCertificationUrl = '/internal/Product/GetCertification';

        
        export function GetCertification(params: GetCertificationParams): Promise<GetCertificationSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetCertificationSuccess>(`/internal/Product/GetCertification${formatParams(params)}`)
        }
    
    
        

        

        export type PageActionsSuccess = paths['/internal/Product/PageActions']['get']['responses']['200']['content']['text/plain'];

        export const PageActionsUrl = '/internal/Product/PageActions';

        
        export function PageActions(): Promise<PageActionsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<PageActionsSuccess>(`/internal/Product/PageActions`)
        }
    
    
        

        export type ProductDeliveryToStoreInfoViewModelParams = paths['/internal/Product/ProductDeliveryToStoreInfoViewModel']['get']['parameters']['query'];

        export type ProductDeliveryToStoreInfoViewModelSuccess = paths['/internal/Product/ProductDeliveryToStoreInfoViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductDeliveryToStoreInfoViewModelUrl = '/internal/Product/ProductDeliveryToStoreInfoViewModel';

        
        export function ProductDeliveryToStoreInfoViewModel(params: ProductDeliveryToStoreInfoViewModelParams): Promise<ProductDeliveryToStoreInfoViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductDeliveryToStoreInfoViewModelSuccess>(`/internal/Product/ProductDeliveryToStoreInfoViewModel${formatParams(params)}`)
        }
    
    
        

        export type ProductDeliveryInfoViewModelParams = paths['/internal/Product/ProductDeliveryInfoViewModel']['get']['parameters']['query'];

        export type ProductDeliveryInfoViewModelSuccess = paths['/internal/Product/ProductDeliveryInfoViewModel']['get']['responses']['200']['content']['text/plain'];

        export const ProductDeliveryInfoViewModelUrl = '/internal/Product/ProductDeliveryInfoViewModel';

        
        export function ProductDeliveryInfoViewModel(params: ProductDeliveryInfoViewModelParams): Promise<ProductDeliveryInfoViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductDeliveryInfoViewModelSuccess>(`/internal/Product/ProductDeliveryInfoViewModel${formatParams(params)}`)
        }
    
    
        

        export type ProductListItemsParams = paths['/internal/ProductList/ProductListItems']['get']['parameters']['query'];

        export type ProductListItemsSuccess = paths['/internal/ProductList/ProductListItems']['get']['responses']['200']['content']['text/plain'];

        export const ProductListItemsUrl = '/internal/ProductList/ProductListItems';

        
        export function ProductListItems(params: ProductListItemsParams): Promise<ProductListItemsSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<ProductListItemsSuccess>(`/internal/ProductList/ProductListItems${formatParams(params)}`)
        }
    
    
        

        

        export type GetProductListModelSuccess = paths['/internal/ProductList/GetProductListModel']['get']['responses']['200']['content']['text/plain'];

        export const GetProductListModelUrl = '/internal/ProductList/GetProductListModel';

        
        export function GetProductListModel(): Promise<GetProductListModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetProductListModelSuccess>(`/internal/ProductList/GetProductListModel`)
        }
    
    
        

        export type ValidateFieldParams = paths['/internal/ProductReview/ValidateField']['post']['parameters']['query'];

        export type ValidateFieldSuccess = paths['/internal/ProductReview/ValidateField']['post']['responses']['200']['content']['text/plain'];

        export const ValidateFieldUrl = '/internal/ProductReview/ValidateField';

        
        export function ValidateField(params: ValidateFieldParams): Promise<ValidateFieldSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ValidateFieldSuccess>(`/internal/ProductReview/ValidateField${formatParams(params)}`)
        }
    
    
        

        export type ProductReviewCreateParams = paths['/internal/ProductReview/Create']['post']['parameters']['query'];

        export type ProductReviewCreateSuccess = paths['/internal/ProductReview/Create']['post']['responses']['200']['content']['text/plain'];

        export const ProductReviewCreateUrl = '/internal/ProductReview/Create';

        
        export function ProductReviewCreate(params: ProductReviewCreateParams): Promise<ProductReviewCreateSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<ProductReviewCreateSuccess>(`/internal/ProductReview/Create${formatParams(params)}`)
        }
    
    
        

        export type DeleteReviewParams = paths['/internal/ProductReview/DeleteReview']['post']['parameters']['query'];

        export type DeleteReviewSuccess = paths['/internal/ProductReview/DeleteReview']['post']['responses']['200']['content']['text/plain'];

        export const DeleteReviewUrl = '/internal/ProductReview/DeleteReview';

        
        export function DeleteReview(params: DeleteReviewParams): Promise<DeleteReviewSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<DeleteReviewSuccess>(`/internal/ProductReview/DeleteReview${formatParams(params)}`)
        }
    
    
        

        export type AddReviewLikeParams = paths['/internal/ProductReviewLike/AddReviewLike']['post']['parameters']['query'];

        export type AddReviewLikeSuccess = paths['/internal/ProductReviewLike/AddReviewLike']['post']['responses']['200']['content']['text/plain'];

        export const AddReviewLikeUrl = '/internal/ProductReviewLike/AddReviewLike';

        
        export function AddReviewLike(params: AddReviewLikeParams): Promise<AddReviewLikeSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return postQuery<AddReviewLikeSuccess>(`/internal/ProductReviewLike/AddReviewLike${formatParams(params)}`)
        }
    
    
        

        export type QuickSearchParams = paths['/internal/Search/QuickSearch']['get']['parameters']['query'];

        export type QuickSearchSuccess = paths['/internal/Search/QuickSearch']['get']['responses']['200']['content']['text/plain'];

        export const QuickSearchUrl = '/internal/Search/QuickSearch';

        
        export function QuickSearch(params: QuickSearchParams): Promise<QuickSearchSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<QuickSearchSuccess>(`/internal/Search/QuickSearch${formatParams(params)}`)
        }
    
    
        

        

        export type GetSearchViewModelSuccess = paths['/internal/Search/GetSearchViewModel']['get']['responses']['200']['content']['text/plain'];

        export const GetSearchViewModelUrl = '/internal/Search/GetSearchViewModel';

        
        export function GetSearchViewModel(): Promise<GetSearchViewModelSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<GetSearchViewModelSuccess>(`/internal/Search/GetSearchViewModel`)
        }
    
    
        

        export type DisclaimerParams = paths['/internal/SponsoredProduct/Disclaimer']['get']['parameters']['query'];

        export type DisclaimerSuccess = paths['/internal/SponsoredProduct/Disclaimer']['get']['responses']['200']['content']['text/plain'];

        export const DisclaimerUrl = '/internal/SponsoredProduct/Disclaimer';

        
        export function Disclaimer(params: DisclaimerParams): Promise<DisclaimerSuccess> {
            // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
            return fetchJSON<DisclaimerSuccess>(`/internal/SponsoredProduct/Disclaimer${formatParams(params)}`)
        }
    
    

        export * from './discriminators';
    