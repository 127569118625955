import type { WithAsyncStatusProps } from 'components/Button/shared/types/buttonWithStatus';
import useAsync from 'hooks/globals/useAsync';
import React, { type HTMLAttributes } from 'react';

const withAsyncStatus = <T extends HTMLElement, P>(Component: React.ComponentType<P>) =>
    React.forwardRef<T, P & WithAsyncStatusProps & HTMLAttributes<T>>(
        (
            {
                onClick: asyncFunction,
                onIdle,
                onPending,
                onSuccess,
                onError,
                status: explicitStatus,
                children,
                ...restProps
            },
            ref
        ) => {
            const { execute, status } = useAsync(asyncFunction, false);

            const statuses = {
                idle: onIdle,
                pending: onPending || onIdle,
                success: onSuccess || onIdle,
                error: onError || onIdle
            };

            return (
                <Component ref={ref} onClick={execute} {...(restProps as P)}>
                    {statuses[explicitStatus || status]} {children}
                </Component>
            );
        }
    );

export default withAsyncStatus;
