import Translations from 'modules/translations';
import WebsiteTime from 'modules/websiteTime';

interface ITime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

enum timeNames {
    days = 'days',
    hours = 'hours',
    minutes = 'minutes',
    seconds = 'seconds'
}

function getCountdownSubLabel(timeObj: ITime): string {
    const { days, hours, minutes } = timeObj;
    let stringToReturn = Translations.Website.TimeDefinition.SecondShorthand;

    if (days) {
        stringToReturn = Translations.Website.TimeDefinition.DayPlural;
    } else if (hours) {
        stringToReturn = Translations.Website.TimeDefinition.HourPlural;
    } else if (minutes) {
        stringToReturn = Translations.Website.TimeDefinition.MinuteShorthand;
    }

    return stringToReturn;
}

function makeTwoDigests(number): string {
    if (number.length === 2) return number;
    return `0${number}`.slice(-2);
}

function createCountdown(timeObj: ITime): string {
    let timeString = '';
    const subLabel = getCountdownSubLabel(timeObj);
    let int = 0;

    if (timeObj.days) {
        // biome-ignore lint/performance/noDelete: <explanation>
        delete timeObj.minutes;
        // biome-ignore lint/performance/noDelete: <explanation>
        delete timeObj.seconds;
    } else if (timeObj.hours) {
        // biome-ignore lint/performance/noDelete: <explanation>
        delete timeObj.seconds;
    }

    Object.entries(timeObj).forEach(([key, val], index) => {
        const isLastKey = index === Object.entries(timeObj).length - 1;

        if (val !== 0) {
            int += 1;
        } else if (val === 0 && int === 0 && key !== timeNames.seconds) {
            return;
        }

        if (int > 2) {
            return;
        }

        timeString += `${makeTwoDigests(val)}${!isLastKey ? ':' : ''}`;
    });

    return `${timeString} ${subLabel}`;
}

function calculateTimeLeft(millisecondsLeft): ITime {
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (Number.isNaN(millisecondsLeft)) {
        console.error('Time is not a valid date');
    } else if (millisecondsLeft > 0) {
        timeLeft = {
            days: Math.floor(millisecondsLeft / (1000 * 60 * 60 * 24)),
            hours: Math.floor((millisecondsLeft / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((millisecondsLeft / 1000 / 60) % 60),
            seconds: Math.max(Math.floor((millisecondsLeft / 1000) % 60), 0)
        };
    }

    return timeLeft;
}

function startTimer(initTime, endTime, countdownElement): boolean {
    let currentString;
    let difference = +new Date(endTime) - +new Date(initTime);

    if (Number.isNaN(difference)) {
        console.error('Time is not a valid date');
        return false;
    }

    countdownElement.textContent = createCountdown(calculateTimeLeft(difference));

    const interval = setInterval((): void => {
        const getCountDownString = createCountdown(calculateTimeLeft(difference));

        if (getCountDownString !== currentString) {
            countdownElement.textContent = getCountDownString;
            currentString = getCountDownString;
        }

        if (difference < 0) {
            clearInterval(interval);
        } else {
            difference -= 1000;
        }
    }, 1000);

    return true;
}

export default function HighlightCountdown(containerElement): void {
    const countdownElement = containerElement.getElementsByClassName('js-highlight-countdown')[0];
    const initTime = WebsiteTime?.load;
    const endTime = countdownElement?.dataset?.countdownEnd;

    if (startTimer(initTime, endTime, countdownElement)) {
        requestAnimationFrame(() => {
            containerElement.classList.remove('d-none');
        });
    }
}
