
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedGlobalSymbols
import type {components, paths} from './product';

export function EcommerceDiscriminator<T>(
    model: components['schemas']['EnhancedEcommerceTrackingEvent']['ecommerce'],
    funcEcommerceBasketSize: (m: components['schemas']['EcommerceBasketSize']) => T,
    funcEcommerceCheckoutStep: (m: components['schemas']['EcommerceCheckoutStep']) => T,
    funcEcommerceProductAddToCartClick: (m: components['schemas']['EcommerceProductAddToCartClick']) => T,
    funcEcommerceProductClick: (m: components['schemas']['EcommerceProductClick']) => T,
    funcEcommerceProductDetail: (m: components['schemas']['EcommerceProductDetail']) => T,
    funcEcommerceProductImpression: (m: components['schemas']['EcommerceProductImpression']) => T,
    funcEcommerceProductRemoveFromCartClick: (m: components['schemas']['EcommerceProductRemoveFromCartClick']) => T,
    funcEcommercePromotionClick: (m: components['schemas']['EcommercePromotionClick']) => T,
    funcEcommercePromotionImpression: (m: components['schemas']['EcommercePromotionImpression']) => T,
    funcEcommercePurchase: (m: components['schemas']['EcommercePurchase']) => T,
    funcCmsObjectTracking: (m: components['schemas']['CmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'EcommerceBasketSize': return funcEcommerceBasketSize(model as components['schemas']['EcommerceBasketSize']);
        case 'EcommerceCheckoutStep': return funcEcommerceCheckoutStep(model as components['schemas']['EcommerceCheckoutStep']);
        case 'EcommerceProductAddToCartClick': return funcEcommerceProductAddToCartClick(model as components['schemas']['EcommerceProductAddToCartClick']);
        case 'EcommerceProductClick': return funcEcommerceProductClick(model as components['schemas']['EcommerceProductClick']);
        case 'EcommerceProductDetail': return funcEcommerceProductDetail(model as components['schemas']['EcommerceProductDetail']);
        case 'EcommerceProductImpression': return funcEcommerceProductImpression(model as components['schemas']['EcommerceProductImpression']);
        case 'EcommerceProductRemoveFromCartClick': return funcEcommerceProductRemoveFromCartClick(model as components['schemas']['EcommerceProductRemoveFromCartClick']);
        case 'EcommercePromotionClick': return funcEcommercePromotionClick(model as components['schemas']['EcommercePromotionClick']);
        case 'EcommercePromotionImpression': return funcEcommercePromotionImpression(model as components['schemas']['EcommercePromotionImpression']);
        case 'EcommercePurchase': return funcEcommercePurchase(model as components['schemas']['EcommercePurchase']);
        case 'CmsObjectTracking': return funcCmsObjectTracking(model as components['schemas']['CmsObjectTracking']);

        default:
            return defaultFunc();
    }
}
export function DataLayerTrackingEventDiscriminator<T>(
    model: components['schemas']['QuickSearchViewModel']['dataLayerEvents'][0],
    funcCheckoutErrorImpressionEvent: (m: components['schemas']['CheckoutErrorImpressionEvent']) => T,
    funcDefaultDataLayerTrackingEvent: (m: components['schemas']['DefaultDataLayerTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEvent: (m: components['schemas']['EnhancedEcommerceTrackingEvent']) => T,
    funcVwoCampaignAssignmentTrackingEvent: (m: components['schemas']['VwoCampaignAssignmentTrackingEvent']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']) => T,
    funcEnhancedEcommerceTrackingEventOfEcommercePurchase: (m: components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']) => T,
    funcEnhancedEcommerceTrackingEventOfCmsObjectTracking: (m: components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CheckoutErrorImpressionEvent': return funcCheckoutErrorImpressionEvent(model as components['schemas']['CheckoutErrorImpressionEvent']);
        case 'DefaultDataLayerTrackingEvent': return funcDefaultDataLayerTrackingEvent(model as components['schemas']['DefaultDataLayerTrackingEvent']);
        case 'EnhancedEcommerceTrackingEvent': return funcEnhancedEcommerceTrackingEvent(model as components['schemas']['EnhancedEcommerceTrackingEvent']);
        case 'VwoCampaignAssignmentTrackingEvent': return funcVwoCampaignAssignmentTrackingEvent(model as components['schemas']['VwoCampaignAssignmentTrackingEvent']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceBasketSize': return funcEnhancedEcommerceTrackingEventOfEcommerceBasketSize(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceBasketSize']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep': return funcEnhancedEcommerceTrackingEventOfEcommerceCheckoutStep(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceCheckoutStep']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductAddToCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductDetail': return funcEnhancedEcommerceTrackingEventOfEcommerceProductDetail(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductDetail']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductImpression': return funcEnhancedEcommerceTrackingEventOfEcommerceProductImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick': return funcEnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommerceProductRemoveFromCartClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionClick': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionClick(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionClick']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePromotionImpression': return funcEnhancedEcommerceTrackingEventOfEcommercePromotionImpression(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression']);
        case 'EnhancedEcommerceTrackingEventOfEcommercePurchase': return funcEnhancedEcommerceTrackingEventOfEcommercePurchase(model as components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePurchase']);
        case 'EnhancedEcommerceTrackingEventOfCmsObjectTracking': return funcEnhancedEcommerceTrackingEventOfCmsObjectTracking(model as components['schemas']['EnhancedEcommerceTrackingEventOfCmsObjectTracking']);

        default:
            return defaultFunc();
    }
}
export function CustomActionDiscriminator<T>(
    model: components['schemas']['CustomActionType']['action'],
    funcCertificationCustomAction: (m: components['schemas']['CertificationCustomAction']) => T,
    funcJavascriptHookCustomAction: (m: components['schemas']['JavascriptHookCustomAction']) => T,
    funcLiveEventCustomAction: (m: components['schemas']['LiveEventCustomAction']) => T,
    funcSurveyCustomAction: (m: components['schemas']['SurveyCustomAction']) => T,
    funcVimeoCustomAction: (m: components['schemas']['VimeoCustomAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CertificationCustomAction': return funcCertificationCustomAction(model as components['schemas']['CertificationCustomAction']);
        case 'JavascriptHookCustomAction': return funcJavascriptHookCustomAction(model as components['schemas']['JavascriptHookCustomAction']);
        case 'LiveEventCustomAction': return funcLiveEventCustomAction(model as components['schemas']['LiveEventCustomAction']);
        case 'SurveyCustomAction': return funcSurveyCustomAction(model as components['schemas']['SurveyCustomAction']);
        case 'VimeoCustomAction': return funcVimeoCustomAction(model as components['schemas']['VimeoCustomAction']);

        default:
            return defaultFunc();
    }
}
export function LinkActionDiscriminator<T>(
    model: components['schemas']['SponsoredProductDisclaimerViewModel']['productLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}
export function CustomListItemDiscriminator<T>(
    model: components['schemas']['ListRowItem']['item'],
    funcConnectedContentFullwidthModel: (m: components['schemas']['ConnectedContentFullwidthModel']) => T,
    funcConnectedContentInlineModel: (m: components['schemas']['ConnectedContentInlineModel']) => T,
    funcConnectedContentInventoryLaneBlockModel: (m: components['schemas']['ConnectedContentInventoryLaneBlockModel']) => T,
    funcConnectedContentMenuItemModel: (m: components['schemas']['ConnectedContentMenuItemModel']) => T,
    funcConnectedContentProductDisplayModel: (m: components['schemas']['ConnectedContentProductDisplayModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ConnectedContentFullwidthModel': return funcConnectedContentFullwidthModel(model as components['schemas']['ConnectedContentFullwidthModel']);
        case 'ConnectedContentInlineModel': return funcConnectedContentInlineModel(model as components['schemas']['ConnectedContentInlineModel']);
        case 'ConnectedContentInventoryLaneBlockModel': return funcConnectedContentInventoryLaneBlockModel(model as components['schemas']['ConnectedContentInventoryLaneBlockModel']);
        case 'ConnectedContentMenuItemModel': return funcConnectedContentMenuItemModel(model as components['schemas']['ConnectedContentMenuItemModel']);
        case 'ConnectedContentProductDisplayModel': return funcConnectedContentProductDisplayModel(model as components['schemas']['ConnectedContentProductDisplayModel']);

        default:
            return defaultFunc();
    }
}
export function ListItemDiscriminator<T>(
    model: paths['/internal/ProductList/ProductListItems']['get']['responses']['200']['content']['text/plain'][0],
    funcListItem: (m: components['schemas']['ListItem']) => T,
    funcListRowItem: (m: components['schemas']['ListRowItem']) => T,
    funcProductListItemModel: (m: components['schemas']['ProductListItemModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ListItem': return funcListItem(model as components['schemas']['ListItem']);
        case 'ListRowItem': return funcListRowItem(model as components['schemas']['ListRowItem']);
        case 'ProductListItemModel': return funcProductListItemModel(model as components['schemas']['ProductListItemModel']);

        default:
            return defaultFunc();
    }
}
export function PointInfoModelDiscriminator<T>(
    model: components['schemas']['PointInfoViewModelMaybe']['pointInfo'],
    funcStandardPointInfoModel: (m: components['schemas']['StandardPointInfoModel']) => T,
    funcMatasPlusPointInfoModel: (m: components['schemas']['MatasPlusPointInfoModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'StandardPointInfoModel': return funcStandardPointInfoModel(model as components['schemas']['StandardPointInfoModel']);
        case 'MatasPlusPointInfoModel': return funcMatasPlusPointInfoModel(model as components['schemas']['MatasPlusPointInfoModel']);

        default:
            return defaultFunc();
    }
}
export function ProductTabContentDiscriminator<T>(
    model: components['schemas']['ProductTabModel']['content'],
    funcProductTabBlockListModel: (m: components['schemas']['ProductTabBlockListModel']) => T,
    funcProductTabContentBrandTeaserModel: (m: components['schemas']['ProductTabContentBrandTeaserModel']) => T,
    funcProductTabDeliveryAndReturnModel: (m: components['schemas']['ProductTabDeliveryAndReturnModel']) => T,
    funcProductTabDescriptionModel: (m: components['schemas']['ProductTabDescriptionModel']) => T,
    funcProductTabHighlightsModel: (m: components['schemas']['ProductTabHighlightsModel']) => T,
    funcProductTabIconListModel: (m: components['schemas']['ProductTabIconListModel']) => T,
    funcProductTabPropertyListModel: (m: components['schemas']['ProductTabPropertyListModel']) => T,
    funcProductTabTableListModel: (m: components['schemas']['ProductTabTableListModel']) => T,
    funcProductTabTableModel: (m: components['schemas']['ProductTabTableModel']) => T,
    funcProductTabUsageModel: (m: components['schemas']['ProductTabUsageModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductTabBlockListModel': return funcProductTabBlockListModel(model as components['schemas']['ProductTabBlockListModel']);
        case 'ProductTabContentBrandTeaserModel': return funcProductTabContentBrandTeaserModel(model as components['schemas']['ProductTabContentBrandTeaserModel']);
        case 'ProductTabDeliveryAndReturnModel': return funcProductTabDeliveryAndReturnModel(model as components['schemas']['ProductTabDeliveryAndReturnModel']);
        case 'ProductTabDescriptionModel': return funcProductTabDescriptionModel(model as components['schemas']['ProductTabDescriptionModel']);
        case 'ProductTabHighlightsModel': return funcProductTabHighlightsModel(model as components['schemas']['ProductTabHighlightsModel']);
        case 'ProductTabIconListModel': return funcProductTabIconListModel(model as components['schemas']['ProductTabIconListModel']);
        case 'ProductTabPropertyListModel': return funcProductTabPropertyListModel(model as components['schemas']['ProductTabPropertyListModel']);
        case 'ProductTabTableListModel': return funcProductTabTableListModel(model as components['schemas']['ProductTabTableListModel']);
        case 'ProductTabTableModel': return funcProductTabTableModel(model as components['schemas']['ProductTabTableModel']);
        case 'ProductTabUsageModel': return funcProductTabUsageModel(model as components['schemas']['ProductTabUsageModel']);

        default:
            return defaultFunc();
    }
}
export function ProductMediaContentDiscriminator<T>(
    model: components['schemas']['ProductInformationViewModel']['productMedia'][0],
    funcProductVideoModel: (m: components['schemas']['ProductVideoModel']) => T,
    funcProduct360Directory: (m: components['schemas']['Product360Directory']) => T,
    funcProductImage: (m: components['schemas']['ProductImage']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductVideoModel': return funcProductVideoModel(model as components['schemas']['ProductVideoModel']);
        case 'Product360Directory': return funcProduct360Directory(model as components['schemas']['Product360Directory']);
        case 'ProductImage': return funcProductImage(model as components['schemas']['ProductImage']);

        default:
            return defaultFunc();
    }
}
export function MediaContentDiscriminator<T>(
    model: components['schemas']['ProductInformationViewModel']['customMediaContent'][0],
    funcImageContent: (m: components['schemas']['ImageContent']) => T,
    funcVideoContent: (m: components['schemas']['VideoContent']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ImageContent': return funcImageContent(model as components['schemas']['ImageContent']);
        case 'VideoContent': return funcVideoContent(model as components['schemas']['VideoContent']);

        default:
            return defaultFunc();
    }
}
export function RecommendationRequestDiscriminator<T>(
    model: components['schemas']['RecommendationRequestWrapperMaybe']['recommendationRequest'],
    funcProductRecommendationRequest: (m: components['schemas']['ProductRecommendationRequest']) => T,
    funcBasketRecommendationRequest: (m: components['schemas']['BasketRecommendationRequest']) => T,
    funcProductGroupRecommendationRequest: (m: components['schemas']['ProductGroupRecommendationRequest']) => T,
    funcSearchResultRecommendationRequest: (m: components['schemas']['SearchResultRecommendationRequest']) => T,
    funcZeroSearchResultRecommendationRequest: (m: components['schemas']['ZeroSearchResultRecommendationRequest']) => T,
    funcHomePageRecommendationRequest: (m: components['schemas']['HomePageRecommendationRequest']) => T,
    funcPowerstepRecommendationRequest: (m: components['schemas']['PowerstepRecommendationRequest']) => T,
    funcPowerstepTwoRecommendationRequest: (m: components['schemas']['PowerstepTwoRecommendationRequest']) => T,
    funcReceiptPageRecommendationRequest: (m: components['schemas']['ReceiptPageRecommendationRequest']) => T,
    funcCustomRecommendationRequest: (m: components['schemas']['CustomRecommendationRequest']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductRecommendationRequest': return funcProductRecommendationRequest(model as components['schemas']['ProductRecommendationRequest']);
        case 'BasketRecommendationRequest': return funcBasketRecommendationRequest(model as components['schemas']['BasketRecommendationRequest']);
        case 'ProductGroupRecommendationRequest': return funcProductGroupRecommendationRequest(model as components['schemas']['ProductGroupRecommendationRequest']);
        case 'SearchResultRecommendationRequest': return funcSearchResultRecommendationRequest(model as components['schemas']['SearchResultRecommendationRequest']);
        case 'ZeroSearchResultRecommendationRequest': return funcZeroSearchResultRecommendationRequest(model as components['schemas']['ZeroSearchResultRecommendationRequest']);
        case 'HomePageRecommendationRequest': return funcHomePageRecommendationRequest(model as components['schemas']['HomePageRecommendationRequest']);
        case 'PowerstepRecommendationRequest': return funcPowerstepRecommendationRequest(model as components['schemas']['PowerstepRecommendationRequest']);
        case 'PowerstepTwoRecommendationRequest': return funcPowerstepTwoRecommendationRequest(model as components['schemas']['PowerstepTwoRecommendationRequest']);
        case 'ReceiptPageRecommendationRequest': return funcReceiptPageRecommendationRequest(model as components['schemas']['ReceiptPageRecommendationRequest']);
        case 'CustomRecommendationRequest': return funcCustomRecommendationRequest(model as components['schemas']['CustomRecommendationRequest']);

        default:
            return defaultFunc();
    }
}
export function PageActionDiscriminator<T>(
    model: components['schemas']['LinkElementViewModel']['link'],
    funcCustomActionType: (m: components['schemas']['CustomActionType']) => T,
    funcLinkActionType: (m: components['schemas']['LinkActionType']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CustomActionType': return funcCustomActionType(model as components['schemas']['CustomActionType']);
        case 'LinkActionType': return funcLinkActionType(model as components['schemas']['LinkActionType']);

        default:
            return defaultFunc();
    }
}
export function MenuItemElementViewModelDiscriminator<T>(
    model: components['schemas']['ProductListMenuItemViewModel']['itemElement'],
    funcTextElementViewModel: (m: components['schemas']['TextElementViewModel']) => T,
    funcLinkElementViewModel: (m: components['schemas']['LinkElementViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'TextElementViewModel': return funcTextElementViewModel(model as components['schemas']['TextElementViewModel']);
        case 'LinkElementViewModel': return funcLinkElementViewModel(model as components['schemas']['LinkElementViewModel']);

        default:
            return defaultFunc();
    }
}
export function FilterViewModelDiscriminator<T>(
    model: components['schemas']['ProductListResultViewModel']['filters'][0],
    funcPriceFilterViewModel: (m: components['schemas']['PriceFilterViewModel']) => T,
    funcBoolFilterViewModel: (m: components['schemas']['BoolFilterViewModel']) => T,
    funcBoolStarFilterViewModel: (m: components['schemas']['BoolStarFilterViewModel']) => T,
    funcRangeFilterViewModel: (m: components['schemas']['RangeFilterViewModel']) => T,
    funcColorFilterViewModel: (m: components['schemas']['ColorFilterViewModel']) => T,
    funcLinkFilterViewModel: (m: components['schemas']['LinkFilterViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'PriceFilterViewModel': return funcPriceFilterViewModel(model as components['schemas']['PriceFilterViewModel']);
        case 'BoolFilterViewModel': return funcBoolFilterViewModel(model as components['schemas']['BoolFilterViewModel']);
        case 'BoolStarFilterViewModel': return funcBoolStarFilterViewModel(model as components['schemas']['BoolStarFilterViewModel']);
        case 'RangeFilterViewModel': return funcRangeFilterViewModel(model as components['schemas']['RangeFilterViewModel']);
        case 'ColorFilterViewModel': return funcColorFilterViewModel(model as components['schemas']['ColorFilterViewModel']);
        case 'LinkFilterViewModel': return funcLinkFilterViewModel(model as components['schemas']['LinkFilterViewModel']);

        default:
            return defaultFunc();
    }
}
export function ActiveFilterViewModelDiscriminator<T>(
    model: components['schemas']['ProductListResultViewModel']['activeFilterValues'][0],
    funcSimpleActiveFilter: (m: components['schemas']['SimpleActiveFilter']) => T,
    funcColorActiveFilter: (m: components['schemas']['ColorActiveFilter']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'SimpleActiveFilter': return funcSimpleActiveFilter(model as components['schemas']['SimpleActiveFilter']);
        case 'ColorActiveFilter': return funcColorActiveFilter(model as components['schemas']['ColorActiveFilter']);

        default:
            return defaultFunc();
    }
}
export function ProductListHeaderDiscriminator<T>(
    model: components['schemas']['ProductListViewModel']['pageHeader'],
    funcProductListBonusGiftHeaderModel: (m: components['schemas']['ProductListBonusGiftHeaderModel']) => T,
    funcProductListBrandHeaderModel: (m: components['schemas']['ProductListBrandHeaderModel']) => T,
    funcProductListOfferHeaderModel: (m: components['schemas']['ProductListOfferHeaderModel']) => T,
    funcProductListRebuyHeaderModel: (m: components['schemas']['ProductListRebuyHeaderModel']) => T,
    funcProductListTextHeaderModel: (m: components['schemas']['ProductListTextHeaderModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'ProductListBonusGiftHeaderModel': return funcProductListBonusGiftHeaderModel(model as components['schemas']['ProductListBonusGiftHeaderModel']);
        case 'ProductListBrandHeaderModel': return funcProductListBrandHeaderModel(model as components['schemas']['ProductListBrandHeaderModel']);
        case 'ProductListOfferHeaderModel': return funcProductListOfferHeaderModel(model as components['schemas']['ProductListOfferHeaderModel']);
        case 'ProductListRebuyHeaderModel': return funcProductListRebuyHeaderModel(model as components['schemas']['ProductListRebuyHeaderModel']);
        case 'ProductListTextHeaderModel': return funcProductListTextHeaderModel(model as components['schemas']['ProductListTextHeaderModel']);

        default:
            return defaultFunc();
    }
}
