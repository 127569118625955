import { Spacing16, ColorNordicGrey3 } from 'autogen/design-tokens/tokens';
import type { MiniBasketViewModel } from 'autogen/swagger/minibasket';
import styled from 'styled-components';
import MiniBasketGiftItem from './MiniBasketGiftItem';
import MiniBasketItem from './MiniBasketItem';
import MiniBasketSampleItem from './MiniBasketSampleItem';

const Item = styled.div`
    padding-bottom: ${Spacing16};
    border-bottom: 1px solid ${ColorNordicGrey3};
    &:last-child {
        border: none;
    }
    &:first-child {
        margin-top: ${Spacing16};
    }
`;

export default function MiniBasketItemsRenderer({
    model: { basketLines, giftLines, sampleLines }
}: {
    model: MiniBasketViewModel;
}) {
    return (
        <>
            {basketLines.map((model) => (
                <Item key={model.productId}>
                    <MiniBasketItem model={model} />
                </Item>
            ))}

            {giftLines.map((model) => (
                <Item key={model.productId}>
                    <MiniBasketGiftItem model={model} />
                </Item>
            ))}

            {sampleLines.map((model) => (
                <Item key={model.productId}>
                    <MiniBasketSampleItem model={model} />
                </Item>
            ))}
        </>
    );
}
