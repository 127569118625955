export default class PaymentOverviewToggle {
    constructor() {
        this.scope = $('body');
        this.toggle = this.toggle.bind(this);
    }

    init() {
        this.bindings();
    }

    bindings() {
        this.scope.on('click', '[data-js="paymentToggleOrder:buttonOpen"]', this.toggle);
        this.scope.on('click', '[data-js="paymentToggleOrder:buttonClose"]', this.toggle);
    }

    toggle() {
        this.scope.find('[data-js="paymentToggleOrder:container"]').toggle();
        this.scope.find('[data-js="paymentToggleOrder:total"]').toggle();
        this.scope.find('[data-js="paymentToggleOrder:buttonOpen"]').toggle();
        this.scope.find('[data-js="paymentToggleOrder:buttonClose"]').toggle();
    }
}
