/* eslint-disable */
/// This file is auto generated - do not edit manually
export const SeeSavingsDetails = 'Website.MatasPlus.Savings.SeeSavingsDetails';
export const PlusHasGivenMe = 'Website.MatasPlus.Savings.PlusHasGivenMe';
export const PlusSavingsForOrder = 'Website.MatasPlus.Savings.PlusSavingsForOrder';
export const PlusSavingsForOrderDisclaimer = 'Website.MatasPlus.Savings.PlusSavingsForOrderDisclaimer';
export const Currency = 'Website.MatasPlus.Savings.Currency';
export const Disclaimer = 'Website.MatasPlus.Savings.Disclaimer';
export const SeeDisclaimerButton = 'Website.MatasPlus.Savings.SeeDisclaimerButton';
export const FreeShippingTooltip = 'Website.MatasPlus.Savings.FreeShippingTooltip';
export const DisclaimerReadMore = 'Website.MatasPlus.Savings.DisclaimerReadMore';
