import React, { useContext } from 'react';
import styled from 'styled-components';
import { Spacing8, Spacing16, Spacing12 } from 'autogen/design-tokens/tokens';
import { ProductNameWithoutLinks } from 'components/generic/productName';
import * as transKey from 'autogen/translation-keys/trans-root-website';
import { useTranslation } from 'react-i18next';
import ProductImage from 'components/generic/productImage';
import InlineButton from 'components/generic/InlineButton';
import { ReadMoreCallbackContext, SelectedProductIdsContext } from '../hooks/contexts';
import ChooseButton from './ChooseButton';
import type { IPickerCard } from '../shared/types';
import getItemState from '../shared/getItemState';
import { ProductImageContainer } from 'components/generic/productImage/shared/styles';

const HorizontalLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledImageContainer = styled(ProductImageContainer)`
    width: 100%;
    margin-bottom: ${Spacing8};
`;

const StyledProductName = styled(ProductNameWithoutLinks)`
    display: -webkit-box;
    height: 75px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    margin-bottom: ${Spacing8};
`;

const ReadMoreButton = styled(InlineButton)`
    margin-bottom: ${Spacing16};
    padding: ${Spacing12};
`;

export default function HorizontalContentLayout(props: IPickerCard): JSX.Element {
    const { productName, image, id, locked, isRecommended } = props;
    const selectedProductIds = useContext(SelectedProductIdsContext);
    const isSelected = selectedProductIds.includes(id);
    const { ShowMoreButton } = transKey.Basket.Samples.SamplesPicker;
    const { t } = useTranslation();
    const readMoreCallback = useContext(ReadMoreCallbackContext);

    return (
        <HorizontalLayout>
            <StyledImageContainer>
                <ProductImage image={image} maxWidth="192px" width={192} transformType="SquareScale" />
            </StyledImageContainer>
            <StyledProductName productName={productName} size="md" />
            <ReadMoreButton variant="dark" fontWeight="semibold" onClick={() => readMoreCallback(props)}>
                {t(ShowMoreButton)}
            </ReadMoreButton>
            <ChooseButton
                productId={id}
                isRecommended={isRecommended}
                state={getItemState(locked, isSelected)}
                size="lg"
            />
        </HorizontalLayout>
    );
}
