import { Spacing16 } from 'autogen/design-tokens/tokens';
import { GeneralError, TryAgain } from 'autogen/translation-keys/trans-website-shared-error';
import { Button } from 'components/Button';
import { Typography } from 'components/generic/typography';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MenuStyleRenderer from './MenuStyleRenderer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    grid-row-gap: ${Spacing16};
    padding: ${Spacing16};
    justify-content: center;
    text-align: center;
    height: 100%;
`;

export default function MenuError({ retryFunction }) {
    const { t } = useTranslation();

    return (
        <MenuStyleRenderer styling="Default">
            <Container>
                <Typography.Headline size={24}>{t(GeneralError)}</Typography.Headline>
                <Button onClick={retryFunction}>{t(TryAgain)}</Button>
            </Container>
        </MenuStyleRenderer>
    );
}
