import { HIDE_QUICKSEARCH, SET_VISUAL_SEARCH_ACTIVE } from '../actions/quickSearchActions';

const initialState = {
    hide: false,
    visualSearchActive: true,
    visualSearchErrorGrowl: undefined
};

const actions = {};

actions[HIDE_QUICKSEARCH] = (state, action) => ({
    ...state,
    hide: action.bool
});

actions[SET_VISUAL_SEARCH_ACTIVE] = (state, action) => ({
    ...state,
    visualSearchActive: action.bool
});

export default function reducer(state = initialState, action) {
    const handler = actions[action.type];
    return handler ? handler(state, action) : state;
}
