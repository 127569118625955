import React from 'react';
import { Parser, ProcessNodeDefinitions } from 'html-to-react';

const processNodeDefinitions = ProcessNodeDefinitions();
const isValidNode = () => true;
const placeholderRegex = /\{\d\}/g;

function format(htmlInput, ...args) {
    const htmlToReactParser = Parser();

    const processingInstructions = [
        {
            // Custom processing if node contains a placeholder in form of curly braces with a digit inside
            shouldProcessNode: (node) => node.data?.match(placeholderRegex),
            processNode: (node, children, index) => {
                const { data } = node;
                const placeholderIndexArray = data
                    .match(placeholderRegex)
                    .map((x) => Number.parseInt(x.replace('{', '')));
                const stringArray = data.split(placeholderRegex);
                const childArray = stringArray.reduce(
                    (acc, curr, i) => [
                        ...acc,
                        curr,
                        // eslint-disable-next-line react/no-array-index-key
                        React.createElement(React.Fragment, { key: i }, args[placeholderIndexArray[i]])
                    ],
                    []
                );

                return React.createElement(React.Fragment, { key: index }, ...childArray);
            }
        },
        {
            // Anything else
            shouldProcessNode: () => true,
            processNode: processNodeDefinitions.processDefaultNode
        }
    ];

    return args.length
        ? htmlToReactParser.parseWithInstructions(htmlInput, isValidNode, processingInstructions)
        : htmlToReactParser.parse(htmlInput);
}

export default format;
