import useMediatorState, { mediatorAtom } from 'hooks/globals/useMediatorAtom';

export type ExpectedDeliveryParams =
    | { type: 'productPage'; productId: number; isSubscription: boolean; zipCode?: string }
    | { type: 'basket'; zipCode?: string };

export const ExpectedDeliveryQuickViewState = mediatorAtom<{
    isOpen: boolean;
    params?: ExpectedDeliveryParams;
}>({ isOpen: false });

export default function useExpectedDeliveryQuickView() {
    return useMediatorState(ExpectedDeliveryQuickViewState);
}
