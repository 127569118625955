export const HIDE_QUICKSEARCH = 'HIDE_QUICKSEARCH';
export const SET_VISUAL_SEARCH_ACTIVE = 'SET_VISUAL_SEARCH_ACTIVE';

export function hideQuickSearch(bool) {
    return {
        type: HIDE_QUICKSEARCH,
        bool
    };
}

export function setVisualSearchActive(bool) {
    return {
        type: SET_VISUAL_SEARCH_ACTIVE,
        bool
    };
}
