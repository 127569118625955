import type { VimeoCustomAction } from 'autogen/swagger/page';
import useVimeoQuickView from 'components/video/hooks/useVimeoQuickView';
import { forwardRef } from 'react';
import type { CustomActionProps } from '../CustomAction';
import { CustomActionButton } from '../shared/styles';

type VimeoAction = { action: VimeoCustomAction } & CustomActionProps;

const VimeoAction = forwardRef<HTMLButtonElement, VimeoAction>(({ action, onClick, ...restProps }, ref) => {
    const [, openVimeoQuickView] = useVimeoQuickView();

    return (
        <CustomActionButton
            ref={ref}
            type="button"
            onClick={(e) => {
                if (onClick) {
                    onClick(e);
                }
                openVimeoQuickView({
                    isOpen: true,
                    video: { id: action.vimeoId, platform: 'Vimeo', url: action.videoUrl }
                });
            }}
            {...restProps}
        />
    );
});

export default VimeoAction;
