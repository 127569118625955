import React from 'react';
import styled from 'styled-components';

const RectImg = styled.div`
    display: block;
    position: relative;
    padding-top: 100%;
    height: 0;
`;

export const DefaultPosition = styled.div`
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default props => {
    const { styles: { Position = DefaultPosition } = {} } = props;
    return (
        <RectImg>
            <Position>{props.children}</Position>
        </RectImg>
    );
};
