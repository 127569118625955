import React from 'react';
import { ColorSpot1 } from 'autogen/design-tokens/tokens';
import styled from 'styled-components';
import { availableFontSizeArray } from 'components/generic/typography/types';
import Price from './Price';
import { IPriceDiscount, PriceDiscount } from './PriceDiscount';

/* #region  styles */
const StyledPrice = styled(Price)`
    color: ${ColorSpot1};
`;

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

/* #endregion */

export interface IPriceWithDiscount extends IPriceDiscount {
    priceBefore: string;
}

export default function PriceWithDiscount({
    price,
    priceBefore,
    priceSaving,
    size = 16,
    ...restProps
}: IPriceWithDiscount) {
    // Discount size should always be one size smaller than the primary Price size
    const getDiscountFontsize = (primarySize) => {
        if (typeof primarySize === 'object') {
            const discountSizes = {};

            Object.keys(primarySize).forEach((objKey) => {
                const index = availableFontSizeArray.indexOf(primarySize[objKey]);

                discountSizes[objKey] = availableFontSizeArray[index ? index - 1 : index];
            });

            return discountSizes;
        }

        const index = availableFontSizeArray.indexOf(primarySize);
        return availableFontSizeArray[index ? index - 1 : index];
    };

    return (
        <Container {...restProps}>
            <StyledPrice price={price} size={size} />
            <PriceDiscount price={priceBefore} priceSaving={priceSaving} size={getDiscountFontsize(size)} />
        </Container>
    );
}
