import ActionWrapper from 'components/List/components/ActionWrapper';
import AnchorWrapper from 'components/List/components/AnchorWrapper';
import ButtonWrapper from 'components/List/components/ButtonWrapper';
import ListItem from 'components/List/components/ListItem';
import type { ListItemType } from 'components/List/shared/types';
import type { LinkActionProps } from 'components/shared/Routing/components/LinkAction/shared/types';
import type { PageActionModel } from 'components/shared/Routing/PageAction';
import type { ButtonHTMLAttributes } from 'react';

export interface IAnchorMenuItem extends Omit<ListItemType, 'wrapperSlot'> {
    anchorProps: LinkActionProps;
}

export function AnchorMenuItem({ anchorProps, size, children, ...restProps }: IAnchorMenuItem) {
    return (
        <ListItem
            size={size}
            {...restProps}
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            wrapperSlot={<AnchorWrapper size={size} {...anchorProps} />}
        >
            {children}
        </ListItem>
    );
}

export interface IButtonMenuItem extends Omit<ListItemType, 'wrapperSlot'> {
    buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

export function ButtonMenuItem({ buttonProps, size, children, ...restProps }: IButtonMenuItem) {
    return (
        <ListItem
            size={size}
            {...restProps}
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            wrapperSlot={<ButtonWrapper size={size} {...buttonProps} />}
        >
            {children}
        </ListItem>
    );
}

export interface IActionMenuItem extends Omit<ListItemType, 'wrapperSlot'> {
    action: PageActionModel;
}

export function ActionMenuItem({ action, size, children, ...restProps }: IActionMenuItem) {
    return (
        <ListItem
            size={size}
            {...restProps}
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            wrapperSlot={<ActionWrapper size={size} action={action} />}
        >
            {children}
        </ListItem>
    );
}

export default Object.assign(ListItem, {
    Button: ButtonMenuItem,
    Anchor: AnchorMenuItem,
    Action: ActionMenuItem
});
