import { Typography } from 'components/generic/typography';
import { TotalPrice as TotalPriceTrans, VATOfTotal, WithVAT } from 'autogen/translation-keys/trans-website-basket';
import { useTranslation } from 'react-i18next';
import { type BasketTotalSummary } from 'autogen/swagger/checkout';
import { Price } from 'components/generic/productPrice';
import Layout from 'components/Layout';

type TotalPrice = Record<string, any> & { model: BasketTotalSummary };

export default function TotalPrice({ model, ...restProps }: TotalPrice) {
    const { totalVat, total } = model;
    const { t } = useTranslation();

    return (
        <Layout.Flex.Row justifyContent="space-between" alignItems="center" {...restProps}>
            <Typography.Body as="span">
                <b>{t(TotalPriceTrans)}</b> {`(${t(WithVAT)})`} <br />
                {`(${t(VATOfTotal)} ${totalVat})`}
            </Typography.Body>
            <Price price={total} />
        </Layout.Flex.Row>
    );
}
