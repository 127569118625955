import { RefObject, useEffect } from 'react';

let currentElementWithFocus;

export default function useSetDialogFocusEffect(ref: RefObject<HTMLElement>, shouldFocus: boolean) {
    useEffect(() => {
        if (shouldFocus && ref?.current) {
            if (!currentElementWithFocus || currentElementWithFocus !== document.activeElement) {
                // Saves last focused element
                currentElementWithFocus = document.activeElement;
            }

            // set focus in quickView
            (ref.current as HTMLElement).focus();
        }

        return () => {
            if (currentElementWithFocus) {
                // set focus back on the previous element before the quickView opened
                (currentElementWithFocus as HTMLElement).focus();
                currentElementWithFocus = undefined;
            }
        };
    }, [shouldFocus, ref]);
}
