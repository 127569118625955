import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { FontWeightNormal, FontWeightSemiBold } from 'autogen/design-tokens/tokens';
import OverlineStyling from '../styles/overline';
import type { OverlineFontSizes } from '../types';
import Text from './Text';

function setFontWeight(textSize: OverlineFontSizes) {
    switch (textSize) {
        case 8:
        case 10:
            return FontWeightSemiBold;
        case 14:
        default:
            return FontWeightNormal;
    }
}

const StyledText = styled(Text)<{ $size: OverlineFontSizes }>`
    ${({ $size }) =>
        css`
            ${OverlineStyling};
            font-weight: ${setFontWeight($size)};
        `}
`;

type OverlineTypes = 'div' | 'span' | 'a';

export interface IOverline extends HTMLAttributes<HTMLSpanElement> {
    as?: OverlineTypes;
    size: OverlineFontSizes;
}

export default function Overline(props: IOverline): JSX.Element {
    const { as = 'div', size, children, ...restProps } = props;

    return (
        <StyledText forwardedAs={as} $size={size} size={size} {...restProps}>
            {children}
        </StyledText>
    );
}
