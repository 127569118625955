import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FontSize10, FontSize16 } from 'autogen/design-tokens/tokens';
import { handleSizesMediaQueryStyling } from 'helpers/mediaQueryStyling';
import type { ISplash, SplashSizesObject } from './shared/types';
import { handleColor } from './shared/helpers';

const sizeStyling = {
    sm: css`
        width: 24px;
        height: 24px;
        font-size: ${FontSize10};
    `,
    md: css`
        width: 32px;
        height: 32px;
        font-size: ${FontSize10};
    `,
    xl: css`
        width: 64px;
        height: 64px;
        font-size: ${FontSize16};
    `
};

export const RoundSplashComponent = styled.div<{
    $backgroundColor: ISplash['backgroundColor'];
    $textColor: ISplash['textColor'];
    $size: ISplash['size'];
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    border-radius: 100%;
    line-height: 1;

    ${({ $backgroundColor }) =>
        $backgroundColor &&
        css`
            background-color: ${$backgroundColor};
        `}

    ${({ $textColor }) =>
        $textColor &&
        css`
            color: ${$textColor};
        `}

    ${({ $size }) =>
        $size && (typeof $size === 'object' ? handleSizesMediaQueryStyling($size, sizeStyling) : sizeStyling[$size])}
`;

export default function RoundSplash(splash: ISplash): JSX.Element {
    const { lines, backgroundColor, textColor, size = 'md' } = splash;
    const formattedBackgroundColor = handleColor(backgroundColor);
    const formattedTextColor = handleColor(textColor);

    return (
        <RoundSplashComponent $backgroundColor={formattedBackgroundColor} $textColor={formattedTextColor} $size={size}>
            {lines.map((line) => (
                <span key={line}>{line}</span>
            ))}
        </RoundSplashComponent>
    );
}
