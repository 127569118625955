import RequestContext from 'modules/requestContext';

export enum CookieCategory {
    necessary = 'cookie_cat_necessary',
    functional = 'cookie_cat_functional',
    statistic = 'cookie_cat_statistic',
    marketing = 'cookie_cat_marketing',
    unclassified = 'cookie_cat_unclassified'
}

export function onCookieConsent(category: CookieCategory): Promise<boolean> {
    return new Promise((resolve) => {
        if (!window.CookieInformation) {
            const acceptedCookies = RequestContext.acceptedCookieCategories;

            const cookieMap = {
                // this map should not be necessary if backend and frontend values are aligned.
                [CookieCategory.functional]: 'Functional',
                [CookieCategory.marketing]: 'Marketing',
                [CookieCategory.necessary]: 'Necessary',
                [CookieCategory.statistic]: 'Statistic'
            };

            resolve(acceptedCookies.includes(cookieMap[category]));
        }
        if (window.CookieInformation.getConsentGivenFor(category.toString())) {
            resolve(true);
        } else {
            window.addEventListener(
                'CookieInformationConsentGiven',
                () => {
                    if (window.CookieInformation.getConsentGivenFor(category.toString())) {
                        resolve(true);
                    }
                },
                false
            );
        }
    });
}

export function getCookieConsent(category: CookieCategory): Promise<boolean> {
    return new Promise((resolve) => {
        if (!window.CookieInformation) {
            resolve(true);
        } else {
            resolve(window.CookieInformation.getConsentGivenFor(category.toString()));
        }
    });
}

export function acceptCookieCategory(category: CookieCategory): void {
    window.CookieInformation.changeCategoryConsentDecision(category.toString(), true);
    window.CookieInformation.submitConsent();
}

export function removeCookieCategory(category: CookieCategory): void {
    window.CookieInformation.changeCategoryConsentDecision(category.toString(), false);
    window.CookieInformation.submitConsent();
}
